import { type KeyboardEvent } from 'react'

import { Prose } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './MarkdownTextViewer.module.scss'

const REACT_FLOW_NO_SCROLL_CLASS_NAME = 'nowheel'

export interface MarkdownTextViewerProps {
  onClick?: () => void
  content: string
}

export const MarkdownTextViewer = ({
  onClick,
  content
}: MarkdownTextViewerProps) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick?.()
    }
  }

  return (
    <div
      data-testid="etl-note-prose"
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className={classNames(
        classes.MarkdownTextViewer__Markdown,
        REACT_FLOW_NO_SCROLL_CLASS_NAME
      )}
    >
      <Prose>{content}</Prose>
    </div>
  )
}
