import { createContext, type ReactNode } from 'react'

import { type ModalProps } from '@matillion/component-library'

export type StackingModalProps = Omit<ModalProps, 'children' | 'onCancel'> &
  Partial<{
    'data-testid': string
    'data-tracker-id': string
  }>

export interface StackingModalContextInterface {
  /**
   * @description When using the pushNewModalContent function exported from useStackingModal be sure that
   * the content you are pushing need to be dynamic based on hooks used in the component you
   * are pushing from. If it does, move this data and the modal content into its own component
   * and push that. This is due to the fact that once you push the modal content it is no longer
   * part of the same render cycle at the component, so if some state updates from within the
   * component, the modal as already been pushed and so will not update as it has already been computed
   */
  pushNewModalContent: (
    modalProps: StackingModalProps,
    content: ReactNode
  ) => void
  closeAll: () => void
}

const StackingModalContext = createContext<
  StackingModalContextInterface | undefined
>(undefined)
export default StackingModalContext
