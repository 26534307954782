import React from 'react'

import { Typography } from '@matillion/component-library'

import { type JobConversionReport } from 'api/hooks/useMigrationMetlJob'

import ConversionReportSection from './ConversionReportSection'

interface JobsConversionReportProps {
  jobConversionReport: JobConversionReport
  jobsNotConverted: string[]
}

const pageStyle: React.CSSProperties = {
  display: 'flex',
  marginTop: 40,
  flexDirection: 'column'
}

const subtitleStyle: React.CSSProperties = {
  fontSize: '1.2rem',
  fontWeight: 'bold'
}

const JobsConversionReport = ({
  jobConversionReport,
  jobsNotConverted
}: JobsConversionReportProps) => {
  return (
    <div style={pageStyle}>
      <div>
        <Typography as="span" style={subtitleStyle}>
          Jobs
        </Typography>
      </div>
      <ConversionReportSection
        entityType="job"
        conversionType="converted"
        conversionReport={jobConversionReport.convertedJobs}
      />
      <ConversionReportSection
        entityType="job"
        conversionType="autoconverted"
        conversionReport={jobConversionReport.autoconvertedJobs}
      />
      <ConversionReportSection
        entityType="job"
        conversionType="manualRefactor"
        conversionReport={jobConversionReport.refactorJobs}
      />
      <ConversionReportSection
        entityType="job"
        conversionType="notConverted"
        conversionReport={[]}
        jobsNotConverted={jobsNotConverted}
      />
    </div>
  )
}

export default JobsConversionReport
