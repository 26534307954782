import { useTranslation } from 'react-i18next'

import { ReactComponent as FailIcon } from 'assets/connector-tool-bar-fail.svg'
import { ReactComponent as FalseIcon } from 'assets/connector-tool-bar-false.svg'
import { ReactComponent as SuccessIcon } from 'assets/connector-tool-bar-success.svg'
import { ReactComponent as TrueIcon } from 'assets/connector-tool-bar-true.svg'
import { ReactComponent as UnconditionalIcon } from 'assets/connector-tool-bar-unditional.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { OutputPortType, type Port } from 'job-lib/types/Components'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { type ComponentNodeData } from '../../../hooks/useCanvasModel/useCanvasModel'
import { useSwitchConnectionType } from '../hooks/useSwitchConnectionType/useSwitchConnectionType'
import classes from './ConnectorToolbar.module.scss'

interface ConnectorToolbarProps {
  sourceNodeId: string
  targetNodeId: string
  sourceComponent: ComponentNodeData
  targetComponent: ComponentNodeData
  pipeline: TransformationJob | OrchestrationJob
}

export const ConnectorToolbar = ({
  sourceNodeId,
  targetNodeId,
  sourceComponent,
  targetComponent,
  pipeline
}: ConnectorToolbarProps) => {
  const { t } = useTranslation()
  const { switchConnectionType } = useSwitchConnectionType(pipeline)

  const getPortDetails = (portId: OutputPortType) => {
    switch (portId) {
      case OutputPortType.SUCCESS:
        return { Icon: SuccessIcon, className: classes.SuccessIcon }
      case OutputPortType.FAILURE:
        return { Icon: FailIcon, className: classes.FailIcon }
      case OutputPortType.TRUE:
        return { Icon: TrueIcon, className: classes.TrueIcon }
      case OutputPortType.FALSE:
        return { Icon: FalseIcon, className: classes.FalseIcon }
      case OutputPortType.UNCONDITIONAL:
        return { Icon: UnconditionalIcon, className: classes.UnconditionalIcon }
      default:
        return { Icon: null, className: '' }
    }
  }

  const sortOutputPorts = (a: Port, b: Port) => {
    if (a.portId === OutputPortType.UNCONDITIONAL) return -1
    return 0
  }

  let sortedOutputPorts
  if (sourceComponent?.outputPorts) {
    sortedOutputPorts = [...sourceComponent.outputPorts].sort(sortOutputPorts)
  }

  return (
    <div className={classes.Toolbar} data-testid="connector-toolbar">
      {sortedOutputPorts?.map((port, index) => {
        const { Icon, className } = getPortDetails(
          port.portId as OutputPortType
        )
        return (
          <IconButton
            key={port.portId}
            onClick={() => {
              switchConnectionType(
                sourceNodeId,
                targetNodeId,
                sourceComponent,
                targetComponent,
                port.portId as OutputPortType
              )
            }}
            className={`${classes.Toolbar__Button} ${className}`}
            label={t(`connectorToolbox.${port.portId.toLowerCase()}`)}
          >
            {Icon && <Icon />}
          </IconButton>
        )
      })}
    </div>
  )
}
