import classNames from 'classnames'

import { ReactComponent as FolderIcon } from 'assets/Folder.svg'

import classes from './Folder.module.scss'

export interface FolderProps {
  className?: string
}

const Folder = ({ className }: FolderProps) => {
  return (
    <FolderIcon
      aria-hidden
      className={classNames(classes.Folder__Icon, className)}
    />
  )
}

export default Folder
