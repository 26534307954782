export const USER_ID = 'google-oauth2|115944871367053482250'
export const PROJECT_ID = '6f8bc2f3-96d5-449d-9551-15d0cdaaf413'
export const PROJECT_ID_WITH_NO_ENVS = '27355dca-a015-4dff-bcf8-8e3c8e984b64'
export const BRANCH_ID = '4aaf06b1-7313-4ff7-8f8c-6158c8f9633a'
export const ENVIRONMENT_ID = '951992d7-cd89-46e0-9a9f-c2b5b0d22f3d'
export const AGENT_ID = 'e35ffdfa-ea44-43af-a9a7-6754ae3556b9'
export const AGENT_VERSION = '10.46.8'
export const AGENT_FLAKY_ID = 'c9b4efc7-1fc6-47b0-a414-dd05cfd541b9'
export const BRANCH_NAME = 'test-branch'
export const WAREHOUSE = 'SNOWFLAKE'

export const MOCK_ORCHESTRATION_JOB_SUMMARY_ID = 'Orchestration Job.orch.yaml'
export const MOCK_ORCHESTRATION_JOB_NAME = 'Orchestration Job'
export const MOCK_ORCHESTRATION_PIPELINE_SUMMARY_ID =
  'Orchestration Pipeline.orch.yaml'

export const MOCK_TRANSFORMATION_JOB_SUMMARY_ID = 'Transformation Job.tran.yaml'
export const MOCK_TRANSFORMATION_JOB_NAME = 'Transformation Job'

export const MOCK_PYTHON_FILE_NAME_WITH_EXTENSION = 'Python script.py'
export const MOCK_PYTHON_FILE_NAME = 'Python script'

export const MOCK_SQL_FILE_NAME_WITH_EXTENSION = 'SQL script.sql'
export const MOCK_SQL_FILE_NAME = 'SQL script'

export const MOCK_UNKNOWN_COMPONENT_ID = 99999999999999

export const MOCK_NEW_CREATED_JOB_SUMMARY_ID = 'MY_ONE_OFF_NEW_JOB'

export const COMPONENT_ID = 14360
export const COMPONENT_NAME = 'I am a testing component name'

export const MOCK_TASK_DETAILS_ID = '1234567'
