import { startImplementationId } from 'job-lib/cisIds/knownComponentParameters'
import type {
  ComponentInstance,
  OrchestrationComponentInstanceCollection,
  TransformationComponentInstanceCollection
} from 'job-lib/types/Job'

import { type ConnectorsCollection } from 'modules/core/EtlDesigner/selectors/getConnectorsSelector'

const getConnection = (
  connectors: ConnectorsCollection | null,
  inputConnectorID: number
) => {
  return (
    connectors?.unconditionalConnectors?.[inputConnectorID] ||
    connectors?.successConnectors?.[inputConnectorID] ||
    connectors?.failureConnectors?.[inputConnectorID] ||
    connectors?.trueConnectors?.[inputConnectorID] ||
    connectors?.falseConnectors?.[inputConnectorID] ||
    connectors?.iterationConnectors?.[inputConnectorID]
  )
}

export const isConnectedToStart = (
  component: ComponentInstance | null,
  components:
    | OrchestrationComponentInstanceCollection
    | TransformationComponentInstanceCollection
    | null,
  connectors: ConnectorsCollection | null
) => {
  if (!component) {
    return false
  }

  if (component.implementationID === startImplementationId) {
    return true
  }

  const inputConnectorIDs = [
    ...component.inputConnectorIDs,
    ...('inputIterationConnectorIDs' in component
      ? component.inputIterationConnectorIDs
      : [])
  ]

  if (inputConnectorIDs.length === 0) {
    return false
  }

  for (const inputConnectorID of inputConnectorIDs) {
    const connection = getConnection(connectors, inputConnectorID)
    if (!connection) {
      return false
    }

    const sourceComponent = components?.[connection.sourceID]
    if (!sourceComponent) {
      return false
    }

    if (isConnectedToStart(sourceComponent, components, connectors)) {
      return true
    }
  }

  return false
}
