import { type ChangeEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  Field,
  TreeItem,
  Typography
} from '@matillion/component-library'

import { ActionsMenu } from './ActionsMenu'
import classes from './SemiStructuredNestedDataPickerEditor.module.scss'
import {
  type ChildDataStructure,
  type DataStructure,
  type SemiStructuredNestedDataPickerConfig
} from './types'
import { composeJsonPath, modifyElement, stopPropagation } from './utils'

interface SchemaElementProps {
  path: string[]
  element: DataStructure | ChildDataStructure
  parentElement?: DataStructure | ChildDataStructure
  root: DataStructure
  editorConfig: SemiStructuredNestedDataPickerConfig
  onChange: (newRoot: DataStructure) => void
}

export const SchemaElement: FC<SchemaElementProps> = ({
  path,
  element,
  parentElement,
  editorConfig,
  root,
  onChange
}) => {
  const { t } = useTranslation()
  const nodeId = composeJsonPath(path)
  const isRoot = !('size' in element)
  const { requireSelected, requireAlias } = editorConfig
  return (
    <TreeItem
      root={isRoot}
      nodeId={nodeId}
      label={element.key}
      leaf={
        <div className={classes.Leaf}>
          {!isRoot && requireSelected && (
            <Field
              aria-label={t('common.select')}
              checked={element.selected}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(
                  modifyElement(root, path, { selected: e.target.checked }, [
                    root.key
                  ])
                )
              }}
              inputComponent={Checkbox}
            />
          )}
          <Typography as="span" format="bcm">
            {element.key}
          </Typography>
          <Typography as="span" format="bcm" className={classes.DataType}>
            {element.type + (!isRoot && element.array ? '[]' : '')}
          </Typography>
          {!isRoot && requireAlias && (
            <span>
              <Typography as="span" format="bcs">
                {`${t('parameterEditor.NESTED_DATA_PICKER_EDITOR.as')} `}
              </Typography>
              <Typography as="span" format="bcs" className={classes.DataType}>
                {element.alias}
              </Typography>
            </span>
          )}
          <span onClick={stopPropagation}>
            <ActionsMenu
              editorConfig={editorConfig}
              path={path}
              element={element}
              parentElement={parentElement}
              root={root}
              onChange={onChange}
            />
          </span>
        </div>
      }
    >
      {isRoot || element.children.length > 0
        ? element.children.map((childElement) => (
            <SchemaElement
              editorConfig={editorConfig}
              key={childElement.key}
              path={[...path, childElement.key]}
              element={childElement}
              parentElement={element}
              root={root}
              onChange={onChange}
            />
          ))
        : undefined}
    </TreeItem>
  )
}
