import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type CellContext
} from '@tanstack/react-table'

import { type NestingAwareStep } from 'api/hooks/useEosApi/types/eos'
import { useGetPollableStepsInfinite } from 'api/hooks/useGetPollableStepsInfinite/useGetPollableStepsInfinite'

import classes from './PipelineLogging.module.scss'

interface PipelineLoggingProps {
  pipelineRunId: string
  pollInterval: number
}

interface LoggingData {
  component: string
  message: string
  startedAt: string
}

const columnHelper = createColumnHelper<LoggingData>()

export const extractTableData = (
  steps: NestingAwareStep[] | undefined,
  accumulator: LoggingData[] = []
) => {
  if (!steps) {
    return accumulator
  }

  steps.forEach((step) => {
    const data: LoggingData = {
      component: step.name,
      message: step.result.message ?? '-',
      startedAt: step.result.startedAt
    }

    accumulator.push(data)

    extractTableData(step.nestedSteps, accumulator)
  })

  return accumulator
}

export const sortSteps = (steps: LoggingData[]) =>
  [...steps].sort(
    (a, b) => new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime()
  )

export const PipelineLogging = ({
  pipelineRunId,
  pollInterval
}: PipelineLoggingProps) => {
  const { t } = useTranslation()
  const { steps } = useGetPollableStepsInfinite(pollInterval, pipelineRunId)

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('component', {
        header: () => t('pipelineLogging.component'),
        cell: (info) => info.getValue()
      }),
      {
        id: 'lineNumber',
        header: () => t('pipelineLogging.lineNumber'),
        cell: (info: CellContext<LoggingData, unknown>) => info.row.index + 1
      },
      columnHelper.accessor('message', {
        header: () => t('pipelineLogging.message'),
        cell: (info) => info.renderValue()
      })
    ]
  }, [t])

  const tableData = useMemo(() => {
    const unsortedTableData = extractTableData(steps)
    const sortedBaseSteps = sortSteps(unsortedTableData)

    return sortedBaseSteps
  }, [steps])

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className={classes.PipelineLogging}>
      <table
        className={classes.PipelineLoggingTable}
        data-testid="pipeline-log-table"
      >
        <colgroup className={classes.columnSizing}>
          <col span={1} className={classes.ColumnSizing__Component} />
          <col span={1} className={classes.ColumnSizing__LineNumber} />
          <col span={1} className={classes.ColumnSizing__Message} />
        </colgroup>
        <thead className="u-visually-hidden">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={classes.PipelineLoggingTable__Body}>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              data-testid="pipeline-log-row"
              className={classes.PipelineLoggingTable__Row}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  data-testid="pipeline-log-cell"
                  className={classes.PipelineLoggingTable__Cell}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
