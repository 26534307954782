import { useTranslation, type TFunction } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { TaskbarTabKeys } from 'modules/core/EtlDesigner/Layouts'
import { useLayoutProvider } from 'modules/core/EtlDesigner/Layouts/LayoutProvider'

import { type ProblemDetails } from '../../types/http-problem-details'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import classes from './Toast.module.scss'

const useRunJob = ({
  jobId,
  warehouse = '',
  componentName,
  executeAsSingleComponent
}: {
  jobId: string
  warehouse?: string
  componentName?: string
  executeAsSingleComponent?: boolean
}) => {
  const { t } = useTranslation()
  const { runJob } = useDesignerApi()
  const queryClient = useQueryClient()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { projectId, branchId, agentId, environmentId } = useProjectInfo()
  const { setActiveTabKey } = useLayoutProvider()
  const launchDarkly = useLDClient()

  return useMutation(
    async () => {
      const { data } = await runJob(warehouse, {
        environmentId,
        agentId,
        projectId,
        branchId,
        jobId,
        componentName,
        executeAsSingleComponent
      })

      return data
    },
    {
      onError: (error: AxiosError<ProblemDetails>) => {
        clearToasts()

        const title = getTitle(t, componentName, executeAsSingleComponent)
        const message =
          error.response?.data.detail ??
          getDefaultMessage(t, componentName, executeAsSingleComponent)

        makeToast({
          type: 'error',
          title,
          message,
          className: classes.PublishToast
        })
        launchDarkly.track('designer-canvas-run-pipeline')
      },
      onSuccess: async () => {
        setActiveTabKey(TaskbarTabKeys.TASK_HISTORY)
        await Promise.all([
          queryClient.invalidateQueries([queryKeys.taskStatus]),
          queryClient.invalidateQueries([queryKeys.activity])
        ])
        launchDarkly.track('designer-canvas-run-pipeline')
      }
    }
  )
}

const getTitle = (
  t: TFunction,
  componentName?: string,
  executableAsSingleComponent?: boolean
) => {
  if (componentName && executableAsSingleComponent) {
    return t('canvasToolbox.runOnlyComponent')
  }

  if (componentName) {
    return t('canvasToolbox.runFromComponent')
  }

  return t('canvasToolbox.runJob')
}

const getDefaultMessage = (
  t: TFunction,
  componentName?: string,
  executableAsSingleComponent?: boolean
) => {
  if (componentName && executableAsSingleComponent) {
    return t('canvas.runningOnlyComponentError')
  }

  if (componentName) {
    return t('canvas.runningFromComponentError')
  }

  return t('canvas.runningJobError')
}

export default useRunJob
export * from './types'
