import { useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  DataGrid,
  Icon,
  MicroCta,
  Typography,
  type DataGridRow,
  type SortOpts
} from '@matillion/component-library'
import { cloneDeep, orderBy } from 'lodash'

import { type ProblemDetails } from 'api/types/http-problem-details'

import { AutoComplete } from 'components/AutoComplete'
import {
  type AutoCompleteItemId,
  type AutoCompleteItemStringId
} from 'components/AutoComplete/types'
import {
  ParameterOverlayButton,
  ParameterOverlayErrors,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { type ElementCollection } from 'job-lib/types/Parameters'
import { type GridVariableDefinition } from 'job-lib/types/Variables'

import { GridVariableEditor } from '../GridVariables/GridVariableEditor/GridVariableEditor'
import { SetValues } from './components/SetValues'
import classes from './SetGridVariablesEditor.module.scss'
import {
  getColumnDataForSetValues,
  getHelperDataColumnNames,
  getInitialChildRows,
  getVariableName,
  mapRowsToElementCollection,
  type ChildGridRow
} from './utils'

export interface HelperData {
  [gridVariableName: string]: GridVariableDefinition
}

export interface SetGridVariablesEditorProps {
  parameterName: string
  elements: ElementCollection
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
  helperData?: HelperData
}

export type SetGridVariableMode = 'defaults' | 'values' | 'grid'

type SortOrder = 'asc' | 'desc'

const Name = ({ name }: { name: string }) => (
  <Typography format="bcs" data-testid={'child-variable-name'}>
    {name}
  </Typography>
)

const Status = ({ status }: { status: string }) => (
  <Typography format="bcs" data-testid={'child-variable-status'}>
    {status}
  </Typography>
)

interface EditProps {
  name: string
  selected: boolean
  onEdit: () => unknown
}

const Edit = ({ name, onEdit, selected }: EditProps) => {
  const { t } = useTranslation()
  return (
    <MicroCta
      alt="transparent"
      aria-label={t('parameterEditor.SET_GRID_VARIABLES_EDITOR.edit')}
      aria-pressed={selected}
      onClick={onEdit}
      className={
        classes.SetGridVariablesEditor__GridVariablesTable__ActionsColumn__EditButton
      }
      data-testid={'action-edit-variable-status'}
    >
      <span className="u-visually-hidden" aria-hidden="false">
        {t('parameterEditor.SET_GRID_VARIABLES_EDITOR.editVariableName', {
          name
        })}
      </span>
      <Icon.Pencil width="18" height="18" />
    </MicroCta>
  )
}

export const SetGridVariablesEditor: FunctionComponent<
  SetGridVariablesEditorProps
> = ({ helperData, parameterName, problemDetail, elements, onDone }) => {
  const { t } = useTranslation()
  const [rows, setRows] = useState<ChildGridRow[]>([])
  const [selectedVariable, setSelectedVariable] = useState<ChildGridRow | null>(
    null
  )
  const isEditing = !!selectedVariable
  const hasVariables = !!helperData && Object.keys(helperData).length > 0

  const [sortedColumn, setSortedColumn] = useState<Record<string, SortOpts>>({
    name: 'ASC'
  })

  const sortedRows = useMemo(() => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    const column = selectedColumn as keyof DataGridRow
    const order = sortOrder.toLowerCase() as SortOrder

    return orderBy(rows, column, order)
  }, [rows, sortedColumn])

  useEffect(() => {
    if (helperData === undefined) {
      return
    }
    const childGridRows = Object.values(
      getInitialChildRows(elements, helperData)
    )
    if (childGridRows.length > 0) {
      setRows(childGridRows)
    }
  }, [elements, helperData])

  const onSelectVariable = (row: ChildGridRow) => {
    selectedVariable?.name === row.name
      ? setSelectedVariable(null)
      : setSelectedVariable(row)
  }

  const columns = [
    {
      title: t('parameterEditor.SET_GRID_VARIABLES_EDITOR.nameColumnTitle'),
      key: 'name',
      sortable: true,
      as: Name,
      mapValues: (row: ChildGridRow) => ({
        name: row.name
      })
    },
    {
      title: t('parameterEditor.SET_GRID_VARIABLES_EDITOR.statusColumnTitle'),
      key: 'status',
      sortable: false,
      as: Status,
      mapValues: (row: ChildGridRow) => ({
        status: t(`parameterEditor.SET_GRID_VARIABLES_EDITOR.${row.status}`)
      })
    },
    {
      key: 'actions',
      title: '',
      className:
        classes.SetGridVariablesEditor__GridVariablesTable__ActionsColumn,
      sortable: false,
      as: Edit,
      mapValues: (row: ChildGridRow) => ({
        onEdit: () => {
          onSelectVariable(row)
        },
        selected: selectedVariable?.name === row.name,
        name: row.name
      })
    }
  ]

  const dropdownOptions: AutoCompleteItemId[] = [
    {
      id: 'defaults',
      name: t('parameterEditor.SET_GRID_VARIABLES_EDITOR.defaults')
    },
    {
      id: 'values',
      name: t('parameterEditor.SET_GRID_VARIABLES_EDITOR.values')
    },
    {
      id: 'grid',
      name: t('parameterEditor.SET_GRID_VARIABLES_EDITOR.grid')
    }
  ]

  const updateRow = (newRowValue: ChildGridRow) => {
    setRows((prevRows) => {
      const newRows = [...prevRows]
      const indexOfVariable = prevRows.findIndex(
        (row) => row.name === newRowValue.name
      )

      newRows[indexOfVariable] = newRowValue
      setSelectedVariable(newRowValue)

      return newRows
    })
  }

  return (
    <div data-testid="set-grid-variables-editor">
      <ParameterOverlayHeader title={parameterName} />
      {problemDetail?.detail && (
        <Alert
          aria-label={problemDetail?.detail}
          className={classes.Alert}
          type="error"
          message={problemDetail?.detail}
        />
      )}
      {!hasVariables && (
        <Alert
          aria-label={t(
            'parameterEditor.SET_GRID_VARIABLES_EDITOR.noVariablesAlert'
          )}
          className={classes.Alert}
          type="error"
          message={t(
            'parameterEditor.SET_GRID_VARIABLES_EDITOR.noVariablesAlert'
          )}
        />
      )}
      <ParameterOverlayErrors />
      <ParameterOverlayContent>
        <Typography
          format="bcm"
          className={classes.SetGridVariablesEditor__GridVariablesTitle}
        >
          {t('parameterEditor.SET_GRID_VARIABLES_EDITOR.tableTitle')}
        </Typography>
        <DataGrid
          columns={columns}
          rows={sortedRows}
          className={classes.SetGridVariablesEditor__GridVariablesTable}
          data-testid="child-grid-variables-table"
          rowClassName={classes.SetGridVariablesEditor__GridVariablesTable__Row}
          onSort={setSortedColumn}
          defaultSort={{ name: 'ASC' }}
          compact
          hasFixedHeader
        />
        {isEditing ? (
          <Typography
            format="bcm"
            className={classes.SetGridVariablesEditor__SetValuesText}
            data-testid={'set-values-header-label'}
          >
            {t('parameterEditor.SET_GRID_VARIABLES_EDITOR.setValuesPrefix')}{' '}
            <strong>{selectedVariable.name}</strong>:
          </Typography>
        ) : (
          <Typography
            format="bcm"
            className={classes.SetGridVariablesEditor__SetValuesText}
          >
            {t(
              'parameterEditor.SET_GRID_VARIABLES_EDITOR.setValuesNoneSelected'
            )}
          </Typography>
        )}
        {isEditing && helperData && (
          <AutoComplete
            aria-label={t(
              'parameterEditor.SET_GRID_VARIABLES_EDITOR.setValuesPrefix'
            )}
            data-testid="set-variable-values-dropdown"
            onChange={({ target: { value } }) => {
              /* istanbul ignore if */
              if (value === null) {
                return
              }

              let newVariable: ChildGridRow | null = null
              if (value.id === 'grid') {
                newVariable = {
                  id: selectedVariable.id,
                  name: selectedVariable.name,
                  status: 'grid',

                  columns: getHelperDataColumnNames(
                    helperData,
                    selectedVariable.name
                  ),
                  values: { columnMapping: [], selectedGridVariable: '' }
                }
              } else if (value.id === 'values') {
                const matchingElement = Object.values(elements).find((v) => {
                  return getVariableName(v) === value.name
                })

                newVariable = {
                  id: selectedVariable.id,
                  name: selectedVariable.name,
                  status: 'values',
                  values: getColumnDataForSetValues(matchingElement)
                }
              } else {
                newVariable = {
                  id: selectedVariable.id,
                  name: selectedVariable.name,
                  status: 'defaults'
                }
              }

              updateRow(newVariable)
            }}
            availableItems={dropdownOptions}
            value={isEditing ? selectedVariable.status : null}
            className={classes.SetGridVariablesEditor__SetValuesData}
            disabled={!isEditing}
          />
        )}
        {isEditing && selectedVariable.status === 'grid' && (
          <GridVariableEditor
            columnNames={selectedVariable.columns}
            columnValues={selectedVariable.columns.reduce<
              Record<string, AutoCompleteItemStringId>
            >((accum, column, index) => {
              const parentValue =
                selectedVariable.values.columnMapping[index]?.value ?? ''
              return {
                ...accum,
                [column]: { id: parentValue, name: parentValue }
              }
            }, {})}
            setColumnValues={(newColumnValues) => {
              const newVariable = cloneDeep(selectedVariable)
              newVariable.values.columnMapping = Object.values(
                newColumnValues
              ).map(({ id }, index) => {
                return {
                  slot: index + 1,
                  type: 'GRID',
                  value: id
                }
              })

              updateRow(newVariable)
            }}
            selectedGridVariable={{
              id: selectedVariable.values.selectedGridVariable,
              name: selectedVariable.values.selectedGridVariable
            }}
            setSelectedGridVariable={(newSelectedGridVariable) => {
              const newVariable = cloneDeep(selectedVariable)
              newVariable.values.selectedGridVariable =
                newSelectedGridVariable.id
              newVariable.values.columnMapping = []
              updateRow(newVariable)
            }}
          />
        )}
        {isEditing && selectedVariable.status === 'values' && (
          <SetValues
            helperData={helperData}
            rows={selectedVariable.values}
            variableName={selectedVariable.name}
            updateRow={updateRow}
          />
        )}
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          data-testid="set-grid-variables-save-button"
          onClick={() => {
            const collection = mapRowsToElementCollection(rows)
            onDone(collection)
          }}
          text={t('common.save')}
          disabled={!hasVariables}
        />
      </ParameterOverlayFooter>
    </div>
  )
}
