import type {
  GridValue,
  GridValueFromVariable,
  ListValue,
  ParameterValue,
  ScalarValue,
  StructList,
  StructValue
} from 'types/Pipeline'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import {
  elementsUseGridVariable,
  getGridVariableName,
  getGridVariableValues,
  isElementCollection
} from 'job-lib/job-functions/elements'
import { type ElementCollection } from 'job-lib/types/Parameters'

const convertElementsToList = (elements: ElementCollection): ListValue =>
  Object.values(elements).map((element) => element.values[1].value)

const convertElementsToGrid = (elements: ElementCollection): GridValue => {
  return Object.values(elements).map((element) =>
    Object.values(element.values).map(({ value }) => value)
  )
}

const convertElementsToStruct = (
  elements: ElementCollection,
  editorColumns?: EditorColumn[]
): StructValue => {
  if (!editorColumns) {
    return {}
  }

  return editorColumns.reduce((struct, column, index) => {
    const element = elements?.[index + 1]
    const structKey = column.resourceID as string

    return {
      ...struct,
      [structKey]: element?.values[1].value ?? null
    }
  }, {})
}

const convertElementsToStructList = (
  elements: ElementCollection,
  editorColumns?: EditorColumn[]
): StructList => {
  if (!editorColumns) {
    return []
  }

  return Object.values(elements).map((element) => {
    return editorColumns.reduce((struct, column, index) => {
      const structKey = column.resourceID as string

      return {
        ...struct,
        [structKey]: element?.values[index + 1].value ?? null
      }
    }, {})
  })
}

const convertElementsToGridValueFromVariable = (
  elements: ElementCollection
): GridValueFromVariable => {
  const elementName = getGridVariableName(elements)
  const values = getGridVariableValues(elements)

  return {
    fromGrid: {
      variable: elementName,
      columns: values
    }
  }
}

const convertElementsToScalarValue = (
  elements: ElementCollection
): ScalarValue => elements[1].values[1].value

export const convertElementCollectionToValue = (
  collection: ElementCollection | ParameterValue,
  dataType: ParameterDataType,
  editorColumns?: EditorColumn[]
): ParameterValue => {
  if (!isElementCollection(collection)) {
    return collection
  }

  const isGridVariable = elementsUseGridVariable(collection)
  if (isGridVariable) {
    return convertElementsToGridValueFromVariable(collection)
  }
  switch (dataType) {
    case ParameterDataType.LIST:
      return convertElementsToList(collection)
    case ParameterDataType.GRID:
      return convertElementsToGrid(collection)
    case ParameterDataType.STRUCT:
      return convertElementsToStruct(collection, editorColumns)
    case ParameterDataType.STRUCT_LIST:
      return convertElementsToStructList(collection, editorColumns)
    default:
      return convertElementsToScalarValue(collection)
  }
}
