import { type FC } from 'react'
import { type NodeProps } from 'reactflow'

import { getIdFromReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { EtlNote } from 'components/EtlNote/EtlNote'

import { type NoteNodeProps } from './models'

export const NoteNode: FC<NodeProps<NoteNodeProps>> = ({
  id,
  data,
  selected
}) => {
  // Remove this once cypress tests for notes are added.
  /* istanbul ignore next */
  return (
    <EtlNote
      id={Number(getIdFromReactFlowId(id))}
      data={data}
      isSelected={selected}
    />
  )
}
