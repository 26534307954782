import { useState, type ChangeEvent, type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Modal,
  Radio,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { AutoComplete } from 'components/AutoComplete'

import { useIsPipelineOpen } from 'hooks/useIsPipelineOpen'

import { JobVariableType, VariableScope } from 'job-lib/types/Variables'

import { type ActiveStageState } from 'modules/ManageVariables/ManageVariables'
import { Stages, type ProjectVariableType } from 'modules/ManageVariables/types'

import FormClasses from '../Form/Form.module.scss'
import classes from './CreateModal.module.scss'

interface CreateModalProps {
  setStage: Dispatch<ActiveStageState>
  variableScope: VariableScope
  onClose: () => void
}

type CreateVariableTypes = ProjectVariableType | JobVariableType | null

export const CreateModal = ({
  setStage,
  variableScope,
  onClose
}: CreateModalProps) => {
  const { t } = useTranslation()
  const [isTouched, setIsTouched] = useState(false)
  const [scope, setScope] = useState<VariableScope>(variableScope)
  const [type, setType] = useState<CreateVariableTypes>(JobVariableType.TEXT)
  const isValid = !!type
  const isPipelineOpen = useIsPipelineOpen()

  const allowGridVariableCreation = scope === VariableScope.JOB_VARIABLE

  const onNext = () => {
    setIsTouched(true)

    if (!isValid) {
      return
    }

    if (type === JobVariableType.GRID) {
      setStage({
        stage: Stages.CREATE_GRID,
        variableScope: scope,
        variableType: type
      })
    } else {
      setStage({
        stage: Stages.CREATE,
        variableScope: scope,
        variableType: type
      })
    }

    onClose()
  }

  return (
    <Modal
      ariaLabelledBy="modal-title"
      onCancel={onClose}
      className={FormClasses.Form__CreateModal}
    >
      <div className={FormClasses.Form__Header}>
        <Typography
          id="modal-title"
          format="ts"
          as="h2"
          className={FormClasses.Form__Title}
        >
          {t('manageVariables.create.title')}
        </Typography>
      </div>

      <fieldset className={classNames(FormClasses.Form__ScopeFieldset)}>
        <legend className="u-visually-hidden">
          {t('manageVariables.jobVariables.fields.variableScopes')}
        </legend>

        <Typography
          format="bcs"
          weight="bold"
          className={FormClasses.Form__Scope}
        >
          {t('manageVariables.manage.projectOrPipeline')}
        </Typography>
        <Field
          data-testid="project-variable-radio-button"
          className={classNames(FormClasses.Field, FormClasses['Field--Radio'])}
          inputComponent={Radio}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setScope(VariableScope.PROJECT_VARIABLE)

            if (type === JobVariableType.GRID) {
              setType(null)
            }
          }}
          text={t('manageVariables.jobVariables.fields.projectVariable')}
          supportText={t(
            'manageVariables.jobVariables.fields.projectRadioSupportText'
          )}
          value={VariableScope.PROJECT_VARIABLE}
          checked={scope === VariableScope.PROJECT_VARIABLE}
        />
        <Field
          data-testid="job-variable-radio-button"
          className={classNames(
            FormClasses.Field,
            FormClasses['Field--Radio'],
            !isPipelineOpen && classes['Section--Disabled']
          )}
          disabled={!isPipelineOpen}
          inputComponent={Radio}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setScope(VariableScope.JOB_VARIABLE)
          }}
          text={t('manageVariables.jobVariables.fields.jobVariable')}
          supportText={t(
            'manageVariables.jobVariables.fields.jobRadioSupportText'
          )}
          value={VariableScope.JOB_VARIABLE}
          checked={scope === VariableScope.JOB_VARIABLE}
        />
      </fieldset>
      <Field
        name={t('manageVariables.jobVariables.fields.type')}
        className={classNames(
          FormClasses.Field,
          FormClasses['Field--Type'],
          classes.FieldType
        )}
        title={t('manageVariables.jobVariables.fields.type')}
        errorText={
          !isValid &&
          isTouched &&
          t('manageVariables.jobVariables.fields.errors.pleaseSelectAnOption')
        }
        value={type ?? ''}
      >
        <AutoComplete
          placeholder={t(
            'manageVariables.jobVariables.fields.dropdownPlaceholder'
          )}
          onChange={(event) => {
            const typeValue = event.target.value?.id as JobVariableType

            setType(typeValue)
          }}
          onBlur={() => {
            setIsTouched(true)
          }}
          availableItems={[
            {
              id: JobVariableType.TEXT,
              name: t('manageVariables.jobVariables.fields.variableType.text')
            },
            {
              id: JobVariableType.NUMBER,
              name: t('manageVariables.jobVariables.fields.variableType.number')
            },
            ...(allowGridVariableCreation
              ? [
                  {
                    id: JobVariableType.GRID,
                    name: t(
                      'manageVariables.jobVariables.fields.variableType.grid'
                    )
                  }
                ]
              : [])
          ]}
          value={type ?? ''}
        />
      </Field>

      <div className={classes.FooterButtons}>
        <Button
          size="lg"
          data-testid="cancel-button"
          alt="secondary"
          onClick={onClose}
          text={t('common.cancel')}
        />
        <Button
          size="lg"
          data-testid={'next-button'}
          type="button"
          text={t('manageVariables.create.next')}
          onClick={onNext}
        />
      </div>
    </Modal>
  )
}
