import { type ReactNode } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './ComponentParameterGroup.module.scss'

interface ComponentParameterGroupProps {
  children: ReactNode
  label: string
  showLabel: boolean
}

export const ComponentParameterGroup = ({
  children,
  label,
  showLabel
}: ComponentParameterGroupProps) => (
  <fieldset
    data-testid="component-parameter-group"
    className={classes.ComponentParameterGroup}
  >
    {showLabel && (
      <legend className={classes.ComponentParameterGroup__Label}>
        <Typography format="mc" weight="bold">
          {label}
        </Typography>
      </legend>
    )}
    {children}
  </fieldset>
)
