import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FC,
  type ReactNode
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  useQueries,
  useQueryClient,
  type UseQueryResult
} from '@tanstack/react-query'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type JobSummaryId } from 'api/hooks/useGetJobSummaries'
import { type ValidationTaskStatus } from 'api/hooks/useGetRunTasks/types'
import {
  type Failure,
  type ValidateComponentGenericFailResponse,
  type ValidateComponentParsedResponse
} from 'api/hooks/useValidateComponent/types'

import { useShortcut } from 'components/ShortcutProvider'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { type JobState } from 'job-lib/store/jobSlice/job.types'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { isMacOs } from 'utils/isMacOs'

import { useFlaggedWorkingCopy } from '../WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { ComponentValidationContext } from './context'
import { useClientSideValidation } from './hooks/useClientSideValidation/useClientSideValidation'
import { useGenerateJobQueryKeys } from './hooks/useGenerateJobQueryKeys'
import { useGetValidationQueries } from './hooks/useGetValidationQueries'
import { type ValidationContextBag, type ValidationQueryCache } from './types'
import { convertMetlSlotsToDplIds } from './utils/convertMetlSlotsToDplIds'
import { getDefaultQueryKeys } from './utils/getDefaultQueryKeys'
import { getDependentComponentNames } from './utils/getDependentComponentNames'
import {
  newValidationStateWith,
  updateValidationStateFrom
} from './utils/history.updater'
import { mergeClientAndServerValidationFailures } from './utils/mergeClientAndServerValidationFailures'

export interface ComponentValidationProviderProps {
  children: ReactNode
}

interface JobLastValidated {
  jobSummaryId: JobSummaryId
  lastValidated: number
}

export const ComponentValidationProvider: FC<
  ComponentValidationProviderProps
> = ({ children }) => {
  const queryClient = useQueryClient()
  const [jobLastValidated, setJobLastValidated] = useState<JobLastValidated[]>(
    []
  )
  const { hasPermission, isLoading: isLoadingPermissions } =
    useProjectPermission('validate_pipelines', PermissionType.ENVIRONMENT)
  const jobState = useFlaggedWorkingCopy()
  const { job, jobType } = jobState
  const { jobSummaryId } = useProjectInfo()
  const { environmentName } = useProjectNames()
  const componentQueryKeys = useGenerateJobQueryKeys(jobState as JobState)
  const { pipelineSummary } = useActivePipelineSummary()
  const { t } = useTranslation()
  const lastValidated =
    jobLastValidated.find((i) => i.jobSummaryId === jobSummaryId)
      ?.lastValidated ?? 0

  const { registerShortcut, unRegisterShortcut } = useShortcut()

  const queries = useGetValidationQueries({
    jobState: jobState as JobState,
    lastValidated,
    componentQueryKeys
  })

  const { getInvalidParameters } = useClientSideValidation({
    job
  })
  const [validationsHistory, setValidationsHistory] = useState<
    ValidationTaskStatus[]
  >([])
  const validationResults = useQueries({
    queries: queries ?? []
  })
  const isValidatingJob = validationResults.some(({ isFetching }) => isFetching)
  const isUnvalidated = lastValidated === 0
  const canValidate = !isLoadingPermissions && hasPermission

  const updateJobLastValidatedState = useCallback(
    (time: number) => {
      setJobLastValidated((prevState) => {
        const newState = prevState.filter(
          (i) => i.jobSummaryId !== jobSummaryId
        )

        newState.push({ jobSummaryId, lastValidated: time })

        return newState
      })
    },
    [jobSummaryId]
  )

  const validationQueryCache: ValidationQueryCache = useMemo(() => {
    const validatedComponents = validationResults.filter(
      (result) => result.data !== undefined
    )
    const validationCache: ValidationQueryCache = validatedComponents.reduce(
      (cache, component) => {
        /* istanbul ignore next */
        if (!component.data) {
          return cache
        }

        return {
          ...cache,
          [component.data.componentName]: component.data
        }
      },
      {}
    )

    return validationCache
  }, [validationResults])

  const hasFinishedValidating = useMemo(() => {
    return (
      lastValidated > 0 &&
      validationResults.every((result) => result.fetchStatus === 'idle')
    )
  }, [lastValidated, validationResults])

  const getValidationResult = useCallback<
    ValidationContextBag['getValidationResult']
  >(
    ({ componentInstanceId, componentMetadata, componentSummaryId } = {}) => {
      if (
        componentInstanceId == null ||
        componentMetadata == null ||
        componentSummaryId == null
      ) {
        return {
          isError: false,
          isSuccess: false,
          isLoading: false,
          failures: null,
          failureMessage: null,
          sql: null,
          componentCache: null
        }
      }

      const componentName: string | undefined =
        job?.components[componentInstanceId]?.parameters?.[1].elements[1]
          .values[1].value

      const invalidRequiredFields: Failure[] = getInvalidParameters({
        componentInstanceId,
        componentMetadata,
        componentSummaryId
      })

      const hasInvalidFields = invalidRequiredFields.length > 0
      const hasFailedClientValidation =
        hasFinishedValidating && hasInvalidFields

      const validationResult:
        | UseQueryResult<ValidateComponentParsedResponse | undefined, unknown>
        | undefined = validationResults.find(
        (result) => result.data && result.data.componentName === componentName
      )

      if (!validationResult) {
        const queryIndex =
          validationResults[
            Object.keys(componentQueryKeys ?? []).findIndex(
              (_componentName) => _componentName === componentName
            )
          ]

        const queryError =
          queryIndex?.error as ValidateComponentGenericFailResponse

        const isIncorrectlyLinked = !isUnvalidated && !queryIndex

        const hasValidationFailedUpstream =
          hasFinishedValidating &&
          (validationResults.some((result) => result.data !== undefined) ||
            queryIndex?.isLoading)

        let failureMessage: string | null = null

        if (queryError?.detail) {
          failureMessage = queryError.detail
        } else if (queryIndex?.isError) {
          failureMessage = t('statuses.componentFailedToValidate')
        } else if (hasValidationFailedUpstream) {
          failureMessage = t('statuses.validationUpstreamError')
        } else if (isIncorrectlyLinked) {
          failureMessage = t('statuses.validationIncorrectlyLinked')
        }

        return {
          isError:
            isIncorrectlyLinked ||
            queryIndex?.isError ||
            hasValidationFailedUpstream ||
            hasFailedClientValidation,
          isSuccess: false,
          isLoading: queryIndex?.isFetching ?? false,
          failures: hasFailedClientValidation ? invalidRequiredFields : null,
          failureMessage,
          sql: null,
          componentCache: null
        }
      }

      const serverValidationErrors = convertMetlSlotsToDplIds(
        validationResult.data?.failures ?? [],
        componentMetadata
      )

      const validationFailures = mergeClientAndServerValidationFailures(
        invalidRequiredFields,
        serverValidationErrors
      )

      return {
        isError:
          validationResult.isError ||
          validationResult.data?.status === 'INVALID' ||
          hasInvalidFields,
        isSuccess:
          validationResult.isSuccess &&
          validationResult.data?.status === 'VALID' &&
          !hasInvalidFields,
        isLoading: validationResult.isFetching,
        failures: validationFailures,
        failureMessage: validationResult.data?.failureMessage ?? null,
        sql: validationResult.data?.onSuccess?.sql,
        componentCache: validationResult.data?.onSuccess
      }
    },
    [
      validationResults,
      hasFinishedValidating,
      job?.components,
      getInvalidParameters,
      componentQueryKeys,
      isUnvalidated,
      t
    ]
  )

  const resetValidation = useCallback(() => {
    updateJobLastValidatedState(0)
    queryClient.removeQueries(getDefaultQueryKeys(jobSummaryId))
  }, [jobSummaryId, queryClient, updateJobLastValidatedState])

  const setValidationEnabled = useCallback(() => {
    if (!canValidate) {
      return
    }

    const now = Date.now()
    queryClient.removeQueries(getDefaultQueryKeys(jobSummaryId))
    updateJobLastValidatedState(now)
    setValidationsHistory(
      newValidationStateWith(
        now,
        pipelineSummary?.runtimeName ?? '',
        environmentName
      )
    )
  }, [
    jobSummaryId,
    queryClient,
    environmentName,
    pipelineSummary?.runtimeName,
    updateJobLastValidatedState,
    canValidate
  ])
  const jobLoaded = Boolean(job)

  useEffect(() => {
    if (hasFinishedValidating && jobLoaded) {
      setValidationsHistory(updateValidationStateFrom(validationResults))
    }
  }, [lastValidated, hasFinishedValidating, jobLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isValidatingJob) {
      return
    }
    const isMac = isMacOs()
    registerShortcut({
      id: 'validateJob',
      key: 'Enter',
      metaKey: isMac,
      ctrlKey: !isMac,
      shiftKey: true,
      callback: setValidationEnabled,
      heap: {
        context: 'canvas',
        action: 'validateJob'
      }
    })

    return () => {
      unRegisterShortcut('validateJob')
    }
  }, [
    registerShortcut,
    unRegisterShortcut,
    setValidationEnabled,
    isValidatingJob
  ])

  const invalidateComponent = useCallback(
    (componentId: ComponentInstanceId) => {
      if (!job || !jobType) {
        return
      }

      if (isUnvalidated) {
        setValidationEnabled()
        return
      }

      const componentName = getComponentName(job.components[componentId])

      const defaultQueryKeys = [...getDefaultQueryKeys(jobSummaryId)]
      queryClient.removeQueries({
        queryKey: defaultQueryKeys,
        predicate: ({ queryKey }) => {
          const requiredComponentArrays = getDependentComponentNames(
            queryKey,
            jobSummaryId
          )

          return requiredComponentArrays.some((input) =>
            input.includes(componentName)
          )
        }
      })
    },
    [
      job,
      jobType,
      isUnvalidated,
      jobSummaryId,
      queryClient,
      setValidationEnabled
    ]
  )

  const invalidateTransformationComponent = useCallback(
    (componentId: ComponentInstanceId) => {
      if (jobState.jobType === JobType.Transformation) {
        invalidateComponent(componentId)
      }
    },
    [invalidateComponent, jobState.jobType]
  )

  return (
    <ComponentValidationContext.Provider
      value={{
        isValidatingJob,
        isUnvalidated,
        hasFinishedValidating,
        validationResults,
        validationQueryCache,
        setValidationEnabled,
        getValidationResult,
        invalidateComponent,
        invalidateTransformationComponent,
        validationsHistory,
        resetValidation
      }}
    >
      {children}
    </ComponentValidationContext.Provider>
  )
}
