import { getComponentName } from 'job-lib/job-functions/getComponentName'
import {
  type ComponentInstanceId,
  type TransformationJob
} from 'job-lib/types/Job'

export const checkForTransformationCircularDeps = (job: TransformationJob) => {
  let isCircular = false

  const checkForCircularDependency = (
    componentId: ComponentInstanceId,
    keys: string[]
  ) => {
    const componentName = getComponentName(job.components[componentId])
    const currentKeys = [...keys, componentName]

    if (keys.includes(componentName)) {
      isCircular = true

      return
    }

    job.components[componentId].outputConnectorIDs.forEach((connectorId) => {
      const targetID = job.connectors[connectorId].targetID

      checkForCircularDependency(targetID, currentKeys)
    })
  }

  const componentIds: number[] = Object.keys(job?.components).map((id) =>
    parseInt(id)
  )
  const inputConnections: ComponentInstanceId[] = Object.values(
    job?.connectors
  ).map((connector) => Number(connector.targetID))

  const inputComponents = componentIds.filter(
    (componentId) => !inputConnections.includes(componentId)
  )

  inputComponents.forEach((component) => {
    checkForCircularDependency(component, [])
  })

  return isCircular
}
