import { type ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'

import { type NameParameter } from 'job-lib/types/Parameters'

import { createHomogeneousElements } from '../createElementsCollection'

export const nameParameterId: ComponentMetadataParameterId = 'component-name'

export const createNameParameter = (name: string): NameParameter => ({
  slot: 1,
  name: 'Name',
  elements: createHomogeneousElements(name) as NameParameter['elements'],
  visible: true
})
