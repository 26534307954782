import { type ComponentInstanceId } from 'job-lib/types/Job'

import { type LookUpType } from './hooks/useGetComponentMetadata/types'

const queryKeys = {
  activity: 'activity',
  connectorComponentMetadata: 'connectorComponentMetadata',
  componentMetadata: 'componentMetadata',
  componentSummaries: 'componentSummaries',
  connectorComponentSummaries: 'connectorComponentSummaries',
  jobSummaries: 'jobSummaries',
  job: 'job',
  project: 'project',
  taskStatus: 'taskStatus',
  taskDetails: 'taskDetails',
  taskStatusUpdate: 'flow-update',
  parameterOptions: 'parameterOptions',
  branches: 'branches',
  validateComponent: 'validateComponent',
  listEnv: 'listEnvironments',
  listSchemas: 'listSchemas',
  listSchemaTables: 'listSchemaTables',
  listSchemaViews: 'listSchemaViews',
  listProjects: 'listProjects',
  listProjectVariables: 'listProjectVariables',
  agentDetails: 'agentDetails',
  convertMetlJobToDpcPipeline: 'convertMetlJobToDpcPipeline',
  getFile: 'getFile',
  secretReferences: 'secretReferences'
} as const

export type ParameterOptionsQueryKey = [
  typeof queryKeys.parameterOptions,
  string,
  string,
  string,
  LookUpType | null,
  ComponentInstanceId | null,
  string | undefined,
  string,
  Record<string, unknown>
]

export { queryKeys }
