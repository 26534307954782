import { useContext } from 'react'

import {
  FileBrowserCommandContext,
  FileBrowserContext
} from '../context/context'

export const usePipelineBrowserProvider = () => useContext(FileBrowserContext)

export const usePipelineBrowserCommandProvider = () =>
  useContext(FileBrowserCommandContext)
