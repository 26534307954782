import { LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import {
  useGetParameterOptions,
  type UseGetParameterOptionsRequest
} from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import {
  type GridVariableCollection,
  type JobVariableCollection
} from 'job-lib/types/Variables'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import {
  StorageEditorType,
  type FullPath
} from 'modules/ParameterEditors/types'

interface ParameterOptionConfigRequest {
  fullPath: FullPath
  storageEditorType?: StorageEditorType
  fileType?: string | null
  lookupType?: LookUpType | null
  jobVars: JobVariableCollection
  gridVars: GridVariableCollection
}
const generateParameters = (
  paramKey: string,
  pathKey: string,
  fullPath: FullPath,
  fileType: string | null | undefined
) => ({
  [paramKey]: fullPath.path[0] || '',
  [pathKey]: fullPath.path.slice(1).join('/') || '',
  type: fileType ?? ''
})

const generateS3GetParametersPayload = ({
  fullPath,
  storageEditorType,
  fileType,
  lookupType,
  jobVars: variables,
  gridVars: gridVariables
}: ParameterOptionConfigRequest): UseGetParameterOptionsRequest<'parameter-options'> => {
  const paramKey =
    lookupType === LookUpType.DYNAMIC_S3_FOLDERS_AND_OBJECTS
      ? 'bucket'
      : 'param.bucket'
  const pathKey =
    lookupType === LookUpType.DYNAMIC_S3_FOLDERS_AND_OBJECTS
      ? 'path'
      : 'param.path'
  const resolvedLookupType =
    lookupType === LookUpType.DYNAMIC_S3_FOLDERS_AND_OBJECTS
      ? LookUpType.DYNAMIC_S3_FOLDERS_AND_OBJECTS
      : LookUpType.DYNAMIC_S3_FOLDERS
  return {
    lookupDefinition: {
      parameters: generateParameters(paramKey, pathKey, fullPath, fileType)
    },
    variables,
    gridVariables,
    lookupType: resolvedLookupType,
    isEnabled: Boolean(
      storageEditorType && Boolean(fullPath.path[0]) && fullPath.isFolder
    ),
    requestType: 'parameter-options'
  }
}

const generateGcsGetParametersPayload = ({
  fullPath,
  storageEditorType,
  lookupType,
  jobVars: variables,
  gridVars: gridVariables
}: ParameterOptionConfigRequest): UseGetParameterOptionsRequest<'parameter-options'> => {
  let path = fullPath.path.slice(1).join('/')

  if (path !== '' && !path.endsWith('/')) {
    path = path.concat('/')
  }

  const resolvedLookupType =
    lookupType === LookUpType.DYNAMIC_GCS_FOLDERS
      ? LookUpType.DYNAMIC_GCS_FOLDERS
      : LookUpType.DYNAMIC_GCS_FOLDERS_AND_OBJECTS

  const bucketKey =
    resolvedLookupType === LookUpType.DYNAMIC_GCS_FOLDERS_AND_OBJECTS
      ? 'param.bucket'
      : 'bucket'
  const pathKey =
    resolvedLookupType === LookUpType.DYNAMIC_GCS_FOLDERS_AND_OBJECTS
      ? 'param.path'
      : 'path'

  return {
    lookupDefinition: {
      parameters: {
        [bucketKey]: fullPath.path[0] || '',
        [pathKey]: path || ''
      }
    },
    variables,
    gridVariables,
    lookupType: resolvedLookupType,
    isEnabled: Boolean(
      storageEditorType && Boolean(fullPath.path[0]) && fullPath.isFolder
    ),
    requestType: 'parameter-options'
  }
}

const generateAzureGetParametersPayload = ({
  fullPath,
  storageEditorType,
  fileType,
  lookupType,
  jobVars: variables,
  gridVars: gridVariables
}: ParameterOptionConfigRequest): UseGetParameterOptionsRequest<'parameter-options'> => {
  const paramKey =
    lookupType === LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS
      ? 'storageAccount'
      : 'param.storageAccount'
  const pathKey =
    lookupType === LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS
      ? 'path'
      : 'param.path'
  const resolvedLookupType =
    lookupType === LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS
      ? LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS
      : LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS
  return {
    lookupDefinition: {
      parameters: generateParameters(paramKey, pathKey, fullPath, fileType)
    },
    variables,
    gridVariables,
    lookupType: resolvedLookupType,
    isEnabled: Boolean(
      storageEditorType && Boolean(fullPath.path[0]) && fullPath.isFolder
    ),
    requestType: 'parameter-options'
  }
}

const generateGetParametersPayload = (
  config: ParameterOptionConfigRequest
): UseGetParameterOptionsRequest<'parameter-options'> => {
  const { storageEditorType } = config
  switch (storageEditorType) {
    case StorageEditorType.GCS:
      return generateGcsGetParametersPayload(config)
    case StorageEditorType.S3:
      return generateS3GetParametersPayload(config)
    case StorageEditorType.AZURE:
    default: // Previous implementation defaulted to Azure.
      return generateAzureGetParametersPayload(config)
  }
}

export interface UseGetFoldersConfig {
  fullPath: FullPath
  storageEditorType?: StorageEditorType
  lookupType?: LookUpType | null
  fileType?: string | null
}

/**
 * Hook to retrieve the list of folders and objects (depending on configuration) for the given storage editor type.
 */
export const useGetFoldersAndObjects = ({
  fullPath,
  storageEditorType,
  fileType,
  lookupType
}: UseGetFoldersConfig) => {
  const { job } = useFlaggedWorkingCopy()
  const jobVars = job?.variables ?? {}
  const gridVars = job?.grids ?? {}
  return useGetParameterOptions(
    generateGetParametersPayload({
      fullPath,
      storageEditorType,
      fileType,
      lookupType,
      jobVars,
      gridVars
    })
  )
}
