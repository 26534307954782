import { lazy, Suspense, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { debounce } from 'lodash'

import { Loading } from 'components/Loading/Loading'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import classes from './FreeformTextareaEditor.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface FreeformTextareaProps {
  title: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
  language?: string
  displayInline?: boolean
}

const FREEFORM_TEXTAREA_DEBOUNCE = 1000

export const FreeformTextareaEditor: FC<FreeformTextareaProps> = ({
  value,
  title,
  language,
  displayInline,
  onDone
}) => {
  const [updatedValue, setUpdatedValue] = useState(value[0] || '')

  return displayInline ? (
    <InlineFreeformTextEditor
      onDone={onDone}
      setUpdatedValue={setUpdatedValue}
      value={updatedValue}
      language={language}
    />
  ) : (
    <ModalFreeformTextEditor
      onDone={onDone}
      setUpdatedValue={setUpdatedValue}
      title={title}
      value={updatedValue}
      language={language}
    />
  )
}

interface ModalFreeformTextEditorProps {
  title: string
  language?: string
  onDone: (editedValue: string[]) => unknown
  setUpdatedValue: (updatedValue: string) => void
  value: string
}

const ModalFreeformTextEditor = ({
  title,
  language,
  onDone,
  setUpdatedValue,
  value
}: ModalFreeformTextEditorProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ParameterOverlayHeader title={title} />
      <ParameterOverlayContent>
        <div className={classes.FreeformTextarea}>
          <Suspense fallback={<Loading />}>
            <CodeEditor
              value={value}
              onChange={setUpdatedValue}
              language={language}
            />
          </Suspense>
        </div>
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          text={t('common.save')}
          onClick={() => {
            onDone([value])
          }}
        />
      </ParameterOverlayFooter>
    </>
  )
}
interface InlineFreeformTextEditorProps {
  value: string
  setUpdatedValue: (updatedValue: string) => void
  language?: string
  onDone: (editedValue: string[]) => unknown
}

const InlineFreeformTextEditor = ({
  value,
  language,
  onDone,
  setUpdatedValue
}: InlineFreeformTextEditorProps) => {
  const debouncedOnDone = useMemo(
    () =>
      debounce((v: string) => {
        onDone([v])
      }, FREEFORM_TEXTAREA_DEBOUNCE),
    [onDone]
  )

  const handleUpdateValue = (v: string) => {
    setUpdatedValue(v)
    debouncedOnDone(v)
  }

  return (
    <div className={classes.FreeformTextarea__Inline}>
      <Suspense fallback={<Loading />}>
        <CodeEditor
          value={value}
          onChange={handleUpdateValue}
          language={language}
        />
      </Suspense>
    </div>
  )
}
