import {
  MELTtoDPLTransition,
  type Component,
  type ComponentDesign,
  type ComponentId,
  type ComponentName,
  type Parameters,
  type Pipeline,
  type Transitions
} from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { componentNameParameter } from 'job-lib/cisIds/knownComponentParameters'
import { type SourceComponentConnection } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { OutputPortType } from 'job-lib/types/Components'

import {
  getParameterDefaultValue,
  isParameterDefaultVisible
} from 'utils/isParameterVisible'

import { getNextComponentName } from '../../utils/getNextComponentName'

export interface AddComponentPayload {
  componentId: ComponentId
  componentName: ComponentName
  componentMetadata?: ComponentMetadata
  componentDesign: ComponentDesign
  sourceComponentConnection?: SourceComponentConnection
  initialParameters?: Record<string, string>
}

export const updateTransitionName = ({
  component,
  currentTransitionName,
  newTransitionName
}: {
  component: Component
  currentTransitionName: string
  newTransitionName: string
}) => {
  const transitionTypes = Object.keys(component.transitions ?? {})

  transitionTypes.forEach((key) => {
    const transitionType = key as keyof Transitions
    const componentTransitions = component?.transitions?.[transitionType]

    if (!componentTransitions || !component.transitions) {
      return
    }

    component.transitions[transitionType] = componentTransitions.map(
      (name: string) => {
        if (name === currentTransitionName) {
          return newTransitionName
        }

        return name
      }
    )
  })
}

export const createParameters = (
  componentName: ComponentName,
  componentMetadata: ComponentMetadata,
  initialParameters?: Record<string, string>
): Parameters => {
  const createParameter = (
    componentParameters: Parameters,
    parameter: ComponentParameter,
    parameterPath: string[]
  ) => {
    if (
      !isParameterDefaultVisible(
        componentMetadata,
        parameter.visibleWhen,
        parameterPath
      )
    ) {
      return componentParameters
    }

    const parameterId = parameter.dplID

    if (
      parameter.childProperties &&
      parameter.dataType !== ParameterDataType.STRUCT_LIST
    ) {
      componentParameters[parameterId] = {}

      parameter.childProperties.forEach((childProperty) => {
        createParameter(
          componentParameters[parameterId] as Parameters,
          childProperty,
          [...parameterPath, parameter.dplID]
        )
      })
    } else {
      componentParameters[parameterId] = getParameterDefaultValue(
        parameter,
        initialParameters
      )
    }

    return componentParameters
  }

  // the componentName parameter is dynamically generated so can be ignored
  const componentParameters = componentMetadata.parameters.filter(
    ({ dplID }: ComponentParameter) => dplID !== componentNameParameter
  )

  return componentParameters.reduce(
    (createdParameters: Parameters, parameter: ComponentParameter) => {
      return createParameter(createdParameters, parameter, [])
    },
    { componentName }
  )
}

export const addComponent =
  (state: Pipeline) =>
  ({
    componentId,
    componentName,
    componentMetadata,
    componentDesign,
    sourceComponentConnection,
    initialParameters
  }: AddComponentPayload) => {
    if (!componentMetadata) {
      console.error('Component metadata not found:', componentName)
      return
    }

    const nextComponentName = getNextComponentName(
      state.pipeline.components,
      componentName
    )

    state.pipeline.components[nextComponentName] = {
      type: componentId,
      parameters: createParameters(
        nextComponentName,
        componentMetadata,
        initialParameters
      )
    }

    state.design.components[nextComponentName] = componentDesign

    if (!sourceComponentConnection) {
      return
    }

    const isExistingComponentSource =
      'sourceComponentName' in sourceComponentConnection

    if (!isExistingComponentSource) {
      Object.keys(state.pipeline.components).forEach((componentKey) => {
        const component = state.pipeline.components[componentKey]

        // When adding a component going in to another we could be inserting it between two existing components
        // We therefore need to update the transitions of the components either side of the iterator
        updateTransitionName({
          component,
          newTransitionName: nextComponentName,
          currentTransitionName: sourceComponentConnection.targetComponentName
        })
      })
    }

    if (
      !isExistingComponentSource &&
      sourceComponentConnection?.sourceType === OutputPortType.ITERATION
    ) {
      state.pipeline.components[nextComponentName].iterationTarget =
        sourceComponentConnection.targetComponentName

      return
    }

    const transitionSourceComponent = isExistingComponentSource
      ? sourceComponentConnection.sourceComponentName
      : nextComponentName
    const transitionValue = isExistingComponentSource
      ? nextComponentName
      : sourceComponentConnection.targetComponentName

    const transitionType =
      MELTtoDPLTransition[sourceComponentConnection.sourceType]
    const sourceComponent = state.pipeline.components[transitionSourceComponent]

    sourceComponent.transitions = sourceComponent.transitions ?? {}
    sourceComponent.transitions[transitionType] = [
      ...(sourceComponent.transitions[transitionType] ?? []),
      transitionValue
    ]
  }
