import { lazy, Suspense, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { useGetStepMessages } from 'api/hooks/useGetStepMessages/useGetStepMessages'

import { DisplayType } from 'components/CodeEditor/types'
import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { Loading } from 'components/Loading/Loading'

import { useFlags } from 'hooks/useFlags'

import { AgentStackTrace } from './AgentStackTrace'
import classes from './CellMessage.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface MessageModalProps {
  setIsOpen: (isOpen: boolean) => void
  value: string
  stepId: string
}

const MessageModal: FC<MessageModalProps> = ({ setIsOpen, value, stepId }) => {
  const { t } = useTranslation()
  const { enableAgentStackTrace } = useFlags()
  const { data, isLoading, isError } = useGetStepMessages(stepId)

  return (
    <DesignerModal
      onCancel={() => {
        setIsOpen(false)
      }}
      ariaLabelledBy="modal-title"
      size="large"
    >
      <Typography
        as="h2"
        format="tm"
        id="modal-title"
        className={classes.CellMessageModal__Title}
      >
        {t('taskExplorer.cellMessage.modalTitle')}
      </Typography>
      <Suspense fallback={<Loading />}>
        <div className={classes.CodeEditorContainer}>
          <CodeEditor
            value={value}
            readOnly
            displayType={DisplayType.Message}
          />
        </div>
        {enableAgentStackTrace &&
        !isError &&
        data?.messages &&
        data.messages.length > 0 ? (
          <AgentStackTrace messages={data.messages} />
        ) : (
          <></>
        )}
        {isLoading && <Loading className={classes.CellMessageModal__Loading} />}
      </Suspense>
      <div className={classes.CellMessageModal__Controls}>
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
          text={t('common.close')}
        />
      </div>
    </DesignerModal>
  )
}

export default MessageModal
