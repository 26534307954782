import { useMemo } from 'react'

import type { ComponentName, Pipeline } from 'types/Pipeline'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { useGetBulkComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { ProjectType } from 'api/hooks/useGetProject/types'
import { useGetProject } from 'api/hooks/useGetProject/useGetProject'

import { type ComponentInstanceId } from 'job-lib/types/Job'

import { removeUnsupportedParametersAndReplaceSlot } from 'utils/removeUnsupportedParametersAndReplaceSlot'

import { idGenerator } from '../utils/idGenerator'

interface MetlConversionMetadataBag {
  isLoading: boolean
  isError: boolean
  metadata: MetlConversionMetadata
}

export interface MetlConversionMetadata {
  warehouseType: string
  metlComponentIdsByName: Record<ComponentName, ComponentInstanceId>
  componentMetadata: Record<ComponentSummaryId, ComponentMetadata>
}

export const getMetlComponentIdsByName = (pipeline?: Pipeline) => {
  if (!pipeline?.design?.components) {
    return {}
  }

  const tempComponentIdsByName: Record<ComponentName, ComponentInstanceId> = {}
  let pipelineHasTempMetlIds = true

  for (const [componentName, component] of Object.entries(
    pipeline.design.components
  )) {
    /*
     * in order for tempMetlIds to be valid, and usable in DPL->METL conversion,
     * every single component in the pipeline needs to have one. If one is missing,
     * we know we don't need to finish iterating over the entire set
     */
    if (!component.tempMetlId) {
      pipelineHasTempMetlIds = false
      break
    }

    tempComponentIdsByName[componentName] = component.tempMetlId
  }

  return pipelineHasTempMetlIds ? tempComponentIdsByName : undefined
}

export const generateComponentIdsByName = (pipeline?: Pipeline) => {
  const sequentialComponentIdsByName: Record<
    ComponentName,
    ComponentInstanceId
  > = {}
  const componentIdGenerator = idGenerator()

  Object.keys(
    pipeline?.pipeline?.components ?? /* istanbul ignore next */ []
  ).forEach((componentName) => {
    sequentialComponentIdsByName[componentName] = Number(
      componentIdGenerator.next().value
    )
  })

  return sequentialComponentIdsByName
}

export const useMetlConversionMetadata = (
  pipeline?: Pipeline
): MetlConversionMetadataBag => {
  /* to convert the pipeline we need to fetch metadata for all component
   * types within it */
  const componentTypes = Object.values(
    pipeline?.pipeline?.components ?? []
  ).map((component) => component.type)

  const pipelineComponentTypes = [...new Set(componentTypes)]

  const project = useGetProject()

  const componentMetadataQueryResponses = useGetBulkComponentMetadata(
    pipelineComponentTypes
  )

  return useMemo(() => {
    /* metl components are referenced by unique id; we need to create a map
     * of dpl component name to melt id in order to convert the entire pipeline */
    const metlComponentIdsByName =
      getMetlComponentIdsByName(pipeline) ??
      generateComponentIdsByName(pipeline)

    let isLoading = false
    let isError = false
    const componentMetadata: Record<ComponentSummaryId, ComponentMetadata> = {}

    componentMetadataQueryResponses.forEach((response) => {
      if (response.isLoading || project.isLoading) {
        isLoading = true
        return
      }

      if (
        !response.data ||
        response.isError ||
        !project.data ||
        project.error
      ) {
        isError = true
        return
      }

      componentMetadata[response.data.componentId] =
        removeUnsupportedParametersAndReplaceSlot(
          project.data.warehouse,
          response.data.metadata
        )
    })

    return {
      isLoading,
      isError,
      metadata: {
        warehouseType: project.data?.warehouse ?? ProjectType.SNOWFLAKE,
        metlComponentIdsByName,
        componentMetadata
      }
    }
  }, [
    pipeline,
    componentMetadataQueryResponses,
    project.data,
    project.error,
    project.isLoading
  ])
}
