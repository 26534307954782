import { useEffect, useMemo, useState, type FC } from 'react'

import { DataGrid } from '@matillion/component-library'
import { t } from 'i18next'
import { type StructList } from 'types/Pipeline'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { DataGridAddRemoveButtons } from 'components/DataGridAddRemoveButtons/DataGridAddRemoveButtons'

import { type GridCell } from 'modules/ParameterEditors/components/GridEditor/components/Grid/types'

import classes from './GridEditor.module.scss'
import { getCols, makeEmptyRow } from './utils'

interface GridEditorProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  replaceLookupParamId?: string
  replaceLookupParamValue?: string
  filterEditorColumns: EditorColumn[]
  gridValues: StructList
  onEdit: (grid: StructList) => void
}

export const GridEditor: FC<GridEditorProps> = ({
  componentSummaryId,
  componentMetaData,
  replaceLookupParamId,
  replaceLookupParamValue,
  filterEditorColumns,
  gridValues,
  onEdit
}) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [shouldAddInitialRow, setShouldAddInitialRow] = useState(
    gridValues.length === 0
  )

  const updateDataSourceFilterRow = (cell: GridCell) => {
    const newGrid = [...gridValues]
    const rowToUpdate = newGrid[cell.slot]
    rowToUpdate[cell.rowSlot] = cell.value
    onEdit(newGrid)
  }

  const cols = getCols(
    filterEditorColumns,
    componentSummaryId,
    componentMetaData,
    updateDataSourceFilterRow,
    t,
    replaceLookupParamId,
    replaceLookupParamValue
  )

  const rows = useMemo(() => {
    return gridValues.map((filterRow, id) => {
      return cols.reduce(
        (acc, col) => {
          return {
            ...acc,
            [col.key]: filterRow[col.key]
          }
        },
        { id }
      )
    })
  }, [cols, gridValues])

  useEffect(() => {
    if (cols.length > 0 && gridValues.length === 0 && shouldAddInitialRow) {
      setShouldAddInitialRow(false)
      onEdit([makeEmptyRow(cols)])
    }
  }, [cols, gridValues.length, onEdit, shouldAddInitialRow])

  const handleSelectRow = (id: string | number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedRows([...selectedRows, id as number])
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== id))
    }
  }

  const handleSelectAllRows = (checked: boolean) => {
    setSelectedRows(checked ? [...Array(gridValues.length).keys()] : [])
  }

  const isAllSelected =
    gridValues.length > 0 && selectedRows.length === gridValues.length

  return (
    <div className={classes.GridEditor}>
      <div className={classes.GridEditor__Container}>
        <DataGrid
          className={classes.GridEditor__DataGrid}
          isSelectable
          allSelectable
          selectedRows={selectedRows}
          isSelectAll={isAllSelected}
          onSelectAllChange={handleSelectAllRows}
          onSelectedChange={handleSelectRow}
          selectRowLabelName={(id: string) => `Select to delete row ${id}`}
          compact
          columns={cols}
          rows={rows}
        />
      </div>
      <div className={classes.GridEditor__AddAndRemoveContainer}>
        <DataGridAddRemoveButtons
          add={{
            disabled: false,
            onClick: () => {
              const newGrid = [...gridValues, makeEmptyRow(cols)]
              onEdit(newGrid)
            }
          }}
          remove={{
            disabled: selectedRows.length === 0,
            onClick: () => {
              const newGrid = gridValues.filter(
                (_, idx) => !selectedRows.includes(idx)
              )
              onEdit(newGrid)
              setSelectedRows([])
            }
          }}
        />
      </div>
    </div>
  )
}
