import {
  useDeleteFile as useDeleteFileEffect,
  type DeleteFileProps
} from '@matillion/git-component-library'

import { useProjectInfo } from '../useProjectInfo/useProjectInfo'
import { useRemoveParameterOptionsCache } from '../useRemoveParameterOptionsCache/useRemoveParameterOptionsCache'

export const useDeleteFile = () => {
  const { projectId, branchId } = useProjectInfo()
  const { removeQueryCache } = useRemoveParameterOptionsCache()
  const { deleteFile: deleteFileEffect, ...effectHook } = useDeleteFileEffect()

  const deleteFile = async ({ name }: DeleteFileProps) => {
    await deleteFileEffect(
      { name },
      {
        onSuccess: () => {
          removeQueryCache({ projectId, branchId, jobSummaryId: name })
        }
      }
    )
  }

  return {
    deleteFile,
    ...effectHook
  }
}
