import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tree, Typography } from '@matillion/component-library'

import { useSchemas } from 'api/hooks/useSchemas'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { SchemaRow } from './components/SchemaRow'
import classes from './SchemaBrowser.module.scss'

const SchemaBrowser = () => {
  const { warehouse } = useProjectNames()
  const {
    isLoading,
    isError,
    data: schemas,
    refetch,
    isRefetching,
    isRefetchError
  } = useSchemas(warehouse)
  const { t } = useTranslation(['translation', 'components'])

  const validSchemas = useMemo(() => {
    if (!schemas) return []

    return schemas.results.filter(
      (schema) => schema.name !== '[Environment Default]'
    )
  }, [schemas])

  if (isLoading || isRefetching) {
    return <Loading className={classes.Browser__Loading} />
  }

  if (isError || isRefetchError) {
    return (
      <ErrorPanel
        includeLink={false}
        text={t('sideBar.schemaPanel.networkError')}
      />
    )
  }

  if (!validSchemas.length) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.schemaPanel.noEnvironmentsInProject')}
      </EmptyPanel>
    )
  }

  return (
    <div
      className={classes.SchemaBrowser}
      tabIndex={0}
      data-testid="schema-browser"
    >
      <div className={classes.SchemaBrowser__Header}>
        <Typography as="span" weight="bold">
          {t('translation:sideBar.schemaPanel.title')}
        </Typography>
        <Button
          alt="secondary"
          onClick={() => {
            refetch()
          }}
          text={t('translation:sideBar.schemaPanel.refresh')}
          className={classes.SchemaBrowser__RefreshButton}
          data-testid="schema-browser-refresh-button"
          size="sm"
        />
      </div>

      <div className={classes.BrowserTree}>
        <Tree expandedItems={{ Orchestration: true, Data: true }} compact>
          {validSchemas.map((schema) => (
            <SchemaRow key={schema.name} schema={schema} />
          ))}
        </Tree>
      </div>
    </div>
  )
}

export default SchemaBrowser
