import React from 'react'

import { Typography } from '@matillion/component-library'

interface ConversionReportDescriptionProps {
  accountId: string
  projectName: string
  dataPlatform: string
  exportFileName: string
  dryRunDate: string
}

const subtitleStyle: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: '1.3rem'
}

const sectionStyle: React.CSSProperties = {
  display: 'flex'
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const tableRowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const cellRightStyle: React.CSSProperties = {
  padding: 5,
  fontSize: '1rem',
  textAlign: 'left',
  flexWrap: 'wrap',
  width: '50%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const cellLeftStyle: React.CSSProperties = {
  ...cellRightStyle,
  fontWeight: 'bold'
}

const ConversionReportDescription = ({
  accountId,
  projectName,
  dataPlatform,
  exportFileName,
  dryRunDate
}: ConversionReportDescriptionProps) => {
  return (
    <>
      <div style={subtitleStyle}>
        <Typography>Public Preview</Typography>
      </div>
      <div style={sectionStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr style={tableRowStyle}>
              <th style={cellLeftStyle}>Account</th>
              <td style={cellRightStyle}>{accountId}</td>
            </tr>
            <tr style={tableRowStyle}>
              <th style={cellLeftStyle}>Project</th>
              <td style={cellRightStyle}>{projectName}</td>
            </tr>
            <tr style={tableRowStyle}>
              <th style={cellLeftStyle}>Data Platform</th>
              <td style={cellRightStyle}>{dataPlatform}</td>
            </tr>
            <tr style={tableRowStyle}>
              <th style={cellLeftStyle}>METL Export File Name</th>
              <td style={cellRightStyle}>{exportFileName}</td>
            </tr>
            <tr style={tableRowStyle}>
              <th style={cellLeftStyle}>Dry Run time</th>
              <td style={cellRightStyle}>{dryRunDate}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ConversionReportDescription
