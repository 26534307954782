import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  DataGrid,
  Modal,
  SearchBar,
  Typography
} from '@matillion/component-library'
import { cloneDeep } from 'lodash'

import { ReactComponent as FilterIcon } from 'assets/filter.svg'

import { IconButton } from 'components/IconButton/IconButton'

import {
  ApplyToAllCTA,
  EditableEditorHeader,
  MultiTableCheckbox,
  RadioButton,
  TableName
} from '../../components'
import { ApplyAll } from '../../screens'
import {
  type ColumnName,
  type ConfigData,
  type DataSelectionConfig,
  type LoadStrategy,
  type MultiTableDataGridColumnProps,
  type MultiTableGridRow,
  type TableDef,
  type UpdateTableConfigHandler
} from '../../types'
import { isTableConfigured } from '../../utils/isTableConfigured'
import {
  APPLY_TO_ALL_LOAD_STRATEGIES,
  loadStrategyColumns
} from '../../utils/loadStrategyUtils'
import classes from './ColumnConfigEditor.module.scss'

interface ColumnsGridRow extends MultiTableGridRow {
  dataElement: string
  isPrimaryKeyColumn: boolean
  isIncrementalColumn: boolean
  disableInputs: boolean
}

type ColumnsDataGridColumnProps = MultiTableDataGridColumnProps<
  ColumnName,
  ColumnsGridRow
>

interface ColumnConfigEditorProps {
  activeTable: TableDef
  activeTableConfig: DataSelectionConfig
  loadStrategy?: LoadStrategy
  internalData: ConfigData
  fetchCompletion: number
  onSelectAllColumnsChange: (
    isAllSelectedChecked: boolean,
    selectedColumnsList: string[]
  ) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onBack: () => void
  onOpenAdvancedFiltering: () => void
  onApplyToAll: (saveData: ConfigData) => void
}

export const ColumnConfigEditor: FC<ColumnConfigEditorProps> = ({
  activeTable,
  activeTableConfig,
  loadStrategy,
  internalData,
  fetchCompletion,
  onSelectAllColumnsChange,
  onUpdateTableConfig,
  onBack,
  onOpenAdvancedFiltering,
  onApplyToAll
}) => {
  const { t } = useTranslation()

  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isCTADismissed, setIsCTADismissed] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isTableRenameConflict, setIsTableRenameConflict] =
    useState<boolean>(false)

  /**
   * Handles updating table name & validating the new target table name is unique.
   * Checks the active source's new target table name against existing selected sources to see
   * whether there are any conflicting names.
   * A flag is set which is an indication to display an error message.
   * @param dataSourceName the data source name
   * @param newTargetTableName the new target table name
   */
  const handleUpdateTableName = (
    dataSourceName: string,
    newTargetTableName: string
  ) => {
    const newTargetTableNameLower = newTargetTableName.toLowerCase()
    const dataSourceNameLower = dataSourceName.toLowerCase()
    const targetTableNames = getSelectedTargetTableNames()
    const tableAlreadyExists =
      targetTableNames.includes(newTargetTableNameLower) &&
      newTargetTableNameLower !== dataSourceNameLower && // No error if renaming to original data source name
      newTargetTableNameLower !==
        activeTableConfig?.targetTableName.toLowerCase() // No error if renaming to custom name
    if (tableAlreadyExists) {
      setIsTableRenameConflict(true)
    } else {
      const newTableConfig = cloneDeep(activeTableConfig)
      newTableConfig.targetTableName = newTargetTableName
      onUpdateTableConfig(newTableConfig)
      setIsTableRenameConflict(false)
    }

    function getSelectedTargetTableNames() {
      return internalData
        .filter((tc) => tc.targetTableName !== null && tc.isSelected)
        .map((config) => config.targetTableName?.toLowerCase())
    }
  }

  const handleSelectedChange = (
    columnId: string | number,
    isSelected: boolean
  ) => {
    const newTableConfig = cloneDeep(activeTableConfig)
    const changedColumn = newTableConfig.dataSelection.find(
      (column) => column.name === columnId
    )
    if (changedColumn) {
      changedColumn.isSelected = isSelected

      /**
       * Reset the incremental load value if the changed column
       * is now deselected but is also the incremental column.
       */
      if (
        !changedColumn.isSelected &&
        changedColumn.name === newTableConfig.incrementalColumn
      ) {
        newTableConfig.incrementalColumn = null
      }

      /**
       * Reset the primary key value if the changed column
       * is now deselected but is also a primary key column.
       */
      if (
        !changedColumn.isSelected &&
        newTableConfig.primaryKeys.includes(changedColumn.name)
      ) {
        newTableConfig.primaryKeys = newTableConfig.primaryKeys.filter(
          (key: string) => key !== columnId
        )
      }

      onUpdateTableConfig(newTableConfig)
    }
  }

  const handlePrimaryKeyColumnChange = (columnId: string, checked: boolean) => {
    const newTableConfig = cloneDeep(activeTableConfig)
    if (checked) {
      newTableConfig.primaryKeys.push(columnId)
    } else {
      newTableConfig.primaryKeys = newTableConfig.primaryKeys.filter(
        (key: string) => key !== columnId
      )
    }
    onUpdateTableConfig(newTableConfig)
    setIsDirty(true)
    setIsCTADismissed(false)
  }

  const handleIncrementalColumnChange = (columnId: string) => {
    const newTableConfig = cloneDeep(activeTableConfig)
    newTableConfig.incrementalColumn = columnId
    onUpdateTableConfig(newTableConfig)
    setIsDirty(true)
    setIsCTADismissed(false)
  }

  const columns: ColumnsDataGridColumnProps[] = [
    {
      key: 'dataElement',
      sortable: false,
      title: t('multiTableConfig.configureColumns.column'),
      as: TableName,
      mapValues: (value) => ({ name: value.dataElement })
    },
    {
      key: 'primaryKeys',
      sortable: false,
      title: t('multiTableConfig.configureColumns.primaryKey'),
      tooltip: t('multiTableConfig.configureColumns.primaryKeyTooltip'),
      as: MultiTableCheckbox,
      mapValues: (columnRow) => ({
        id: columnRow.id,
        column: 'primary-keys',
        checked: columnRow.isPrimaryKeyColumn,
        disabled: columnRow.disableInputs,
        onChange: handlePrimaryKeyColumnChange
      })
    },
    {
      key: 'incrementalColumn',
      sortable: false,
      title: t('multiTableConfig.configureColumns.incrementalKey'),
      tooltip: t('multiTableConfig.configureColumns.incrementalKeyTooltip'),
      as: RadioButton,
      mapValues: (columnRow) => ({
        id: columnRow.id,
        column: 'incremental-column',
        checked: columnRow.isIncrementalColumn,
        disabled: columnRow.disableInputs,
        onChange: handleIncrementalColumnChange
      })
    }
  ]

  const selectedRowNames = activeTableConfig.dataSelection
    .filter((column) => column.isSelected)
    .map((column) => column.name)

  const selectedRowCountSummary =
    activeTable.columns && activeTable.columns.length > 0
      ? `(${selectedRowNames.length}/${activeTable.columns?.length})`
      : ''

  const rows = activeTableConfig.dataSelection
    .map(
      (column): ColumnsGridRow => ({
        id: column.name,
        dataElement: column.name,
        isPrimaryKeyColumn: activeTableConfig.primaryKeys.includes(column.name),
        isIncrementalColumn:
          column.name === activeTableConfig.incrementalColumn,
        disableInputs: !column.isSelected
      })
    )
    .filter((row) => {
      if (!searchValue) {
        return true
      }
      return row.dataElement.toLowerCase().includes(searchValue.toLowerCase())
    })

  const isSelectAll =
    selectedRowNames.length ===
    (searchValue ? rows.length : activeTable.columns?.length)

  return (
    <div
      className={classes.ColumnConfigEditor}
      data-testid="column-config-editor"
    >
      <div className={classes.ColumnConfigEditor__Title}>
        <EditableEditorHeader
          title={activeTableConfig.targetTableName}
          subtitle={activeTable.name}
          onBack={() => {
            setIsTableRenameConflict(false)
            onBack()
          }}
          onUpdateTableName={handleUpdateTableName}
          isTableRenameConflict={isTableRenameConflict}
        />
      </div>
      {rows.length === 0 && (
        <Alert
          className={classes.ColumnConfigEditor__Alert}
          type="warning"
          title={t('multiTableConfig.common.emptyResponse.title')}
          message={
            <Typography>
              {t('multiTableConfig.common.emptyResponse.message')}
            </Typography>
          }
          action={{
            text: t('multiTableConfig.common.back'),
            onClick: onBack
          }}
        />
      )}
      {rows.length > 0 && (
        <>
          {showApplyToAllModal && loadStrategy && (
            <Modal
              className={classes.ColumnConfigEditor__ApplyToAllModal}
              drawerModePosition="right"
              size="mid-large"
              onCancel={() => {
                setShowApplyToAllModal(false)
              }}
              ariaLabelledBy={'Apply to all'}
            >
              <ApplyAll
                activeTableConfig={activeTableConfig}
                data={internalData}
                fetchCompletion={fetchCompletion}
                loadStrategy={loadStrategy}
                onSave={(saveData: ConfigData) => {
                  setShowApplyToAllModal(false)

                  onApplyToAll(saveData)
                }}
                onCancel={() => {
                  setShowApplyToAllModal(false)
                }}
              />
            </Modal>
          )}
          <div className={classes.ColumnConfigEditor__Toolbar}>
            <div className={classes.ColumnConfigEditor__ToolbarCount}>
              <Typography format="bcs" data-testid="selected-row-count">
                {selectedRowCountSummary}
              </Typography>
            </div>
            <div>
              <SearchBar
                className={classes.ColumnConfigEditor__SearchBar}
                data-testid="column-config-editor-search-bar"
                placeholder="Search"
                value={searchValue}
                onChange={(e: { target: { value: string } }) => {
                  setSearchValue(e.target.value)
                }}
              />
            </div>

            <div className={classes.ColumnConfigEditor__ToolbarButtons}>
              <IconButton
                className={classes.ColumnConfigEditor__ToolbarButton}
                data-testid={'multi-table-advanced-filtering'}
                label="Apply data filters"
                align="end"
                onClick={onOpenAdvancedFiltering}
              >
                <FilterIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.ColumnConfigEditor__DataGridContainer}>
            <DataGrid
              className={classes.ColumnConfigEditor__DataGrid}
              rowClassName={classes.ColumnConfigEditor__DataGridRow}
              selectRowLabelName={(id) =>
                t('multiTableConfig.configureColumns.rowLabel', {
                  columnName: id
                })
              }
              allSelectable
              isSelectable
              isSelectAll={isSelectAll}
              selectedRows={selectedRowNames}
              onSelectedChange={(
                columnId: string | number,
                isSelected: boolean
              ) => {
                handleSelectedChange(columnId, isSelected)
              }}
              onSelectAllChange={(selected: boolean) => {
                onSelectAllColumnsChange(
                  selected,
                  rows.map((row) => row.dataElement)
                )
              }}
              hasFixedHeader
              columns={columns.filter((column) => {
                if (column.key === 'dataElement') {
                  return true
                }
                if (!loadStrategy) {
                  return false
                }
                return loadStrategyColumns[loadStrategy]?.includes(column.key)
              })}
              rows={rows}
            />
          </div>
          {loadStrategy &&
            APPLY_TO_ALL_LOAD_STRATEGIES.includes(loadStrategy) && (
              <div className={classes.ColumnConfigEditor__ApplyToAllContainer}>
                {isDirty &&
                  !isCTADismissed &&
                  isTableConfigured(loadStrategy, activeTableConfig) && (
                    <ApplyToAllCTA
                      onAction={() => {
                        setShowApplyToAllModal(true)
                      }}
                      onCancel={() => {
                        setIsCTADismissed(true)
                      }}
                    />
                  )}
              </div>
            )}
        </>
      )}
    </div>
  )
}
