import { LoadingSpinner } from '@matillion/component-library'

import './FullPageLoader.scss'

interface Props {
  /**
   * An optional data-testid attribute to be added to the FullPageLoader. Defaults to 'full-page-loader' if not provided.
   */
  'data-testid'?: string
}

export function FullPageLoader({
  'data-testid': dataTestId = 'full-page-loader'
}: Props) {
  return (
    <div className="FullPageLoader" data-testid={dataTestId}>
      <LoadingSpinner />
    </div>
  )
}
