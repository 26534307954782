import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { QuitButton, Skeleton, Typography } from '@matillion/component-library'
import { heap, useServiceUrl } from '@matillion/hub-client'

import type { ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useAvailableComponents } from 'hooks/useAvailableComponents'

import { dataSourcesList } from './dataSourcesList'
import classes from './TopSourcesPanel.module.scss'

interface TopSourcesPanelProps {
  handleClose: () => void
  onSelectComponent: (id: ComponentSummaryId) => Promise<void>
  topSources: string[]
}

interface DataSource {
  id: string
  displayName: string | undefined
  targetConnectorId: string | undefined
  icon: string
  supported: boolean
}

const TopSourcesPanel = ({
  handleClose,
  onSelectComponent,
  topSources
}: TopSourcesPanelProps) => {
  const { t } = useTranslation()
  const { components, isLoading, isError } = useAvailableComponents()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse

  const joinedSources: DataSource[] = useMemo(() => {
    return topSources.map((source) => {
      const targetConnectorId = dataSourcesList.find(
        (listSource) => listSource.id === source
      )?.targetConnectorId
      const targetConnector = components?.find(
        (component) => component.componentId === targetConnectorId
      )

      return {
        id: source,
        displayName:
          dataSourcesList.find((listSource) => listSource.id === source)
            ?.name ?? source,
        targetConnectorId,
        icon: targetConnector?.icon ?? '/components/icons/custom-connector.svg',
        supported:
          !!targetConnectorId &&
          !!targetConnector?.dwhTypes.some(
            (wh) => wh.toLowerCase() === warehouse?.toLowerCase()
          ) &&
          !!targetConnector?.isAvailableForAgent
      }
    })
  }, [topSources, components, warehouse])

  const customConnectorUrl = useServiceUrl('cyoc-frontend', true)

  const handleSourceClick = (source: DataSource) => {
    heap.track(
      'Designer - Add Next Component Modal - Top Sources Panel - Source Clicked',
      {
        componentName: source.displayName,
        supported: source.supported
      }
    )
    if (source.supported && source.targetConnectorId) {
      onSelectComponent(source.targetConnectorId)
    } else {
      window.open(customConnectorUrl, '_blank')
    }
  }

  if (isError) {
    return <></>
  }

  return (
    <div className={classes.TopSourcesPanel} data-testid={'top-sources-panel'}>
      <div className={classes.TopSourcesPanel__Upper}>
        <Typography format={'mc'}>
          {t('addNextComponent.topSourcesPanel.title')}
        </Typography>
        <QuitButton
          onClick={handleClose}
          data-testid="top-sources-panel-close-button"
        />
      </div>
      <div className={classes.TopSourcesPanel__Lower}>
        {joinedSources?.map((source) =>
          isLoading ? (
            <Skeleton
              key={source.id}
              data-testid="top-sources-panel-source-skeleton"
              height={80}
              width={120}
            />
          ) : (
            <div
              key={source.id}
              className={classes.TopSourcesPanel__Source}
              data-testid="top-sources-panel-source"
            >
              <button
                type="button"
                onClick={() => {
                  handleSourceClick(source)
                }}
                className={classes.TopSourcesPanel__IconButton}
                data-testid="top-sources-panel-source-icon-button"
              >
                <img
                  src={source.icon}
                  alt={`${source.displayName} source icon`}
                  className={classes.TopSourcesPanel__Icon}
                  data-testid="top-sources-panel-source-icon"
                />
              </button>
              <Typography
                format={'mc'}
                className={classes.TopSourcesPanel__Name}
                data-testid="top-sources-panel-source-name"
              >
                {source.supported
                  ? source.displayName
                  : t('addNextComponent.topSourcesPanel.sourceLabel', {
                      sourceName: source.displayName,
                      restApi: source.id !== 'rest-api' ? ' REST API' : ''
                    })}
              </Typography>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export { TopSourcesPanel }
