import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { type JobVariable } from 'job-lib/types/Variables'

import { type SelectedJobVariable } from 'modules/ManageVariables/types'

import classes from '../Manage.module.scss'
import { JobVariablesList } from './JobVariablesList'

interface JobVariablesProps {
  jobVariables: JobVariable[]
  onDelete: (variable: SelectedJobVariable) => unknown
  onEdit: (variable: SelectedJobVariable) => unknown
}

export const JobVariables: FC<JobVariablesProps> = ({
  onDelete,
  onEdit,
  jobVariables
}) => {
  const { t } = useTranslation()

  if (jobVariables.length === 0) {
    return (
      <EmptyPanel className={classes.ManageVariables__EmptyPanel}>
        {t('manageVariables.empty.infoTextJobVariable')}
      </EmptyPanel>
    )
  }

  return (
    <JobVariablesList
      jobVariables={jobVariables}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  )
}
