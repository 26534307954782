import { type ServiceKey } from '../effects/useServiceUrl'

/**
 * Unique identifier for each frontend project within Microverse
 * It's currently used as
 * 1. project's namespace prefix for shared resources (e.g. browser storage, cookies, etc.)
 * 2. `baseRouteId` for routing to other MFEs via micro-router.
 * 3. Project's Module Federation "name" value
 */
export enum MicroverseProjectId {
  AgentManager = 'agent_manager',
  Billing = 'billing',
  Cyoc = 'cyoc',
  Designer = 'designer',
  Hub = 'hub',
  Mdl = 'mdl',
  ObservabilityDashboard = 'observability_dashboard',
  ProjectExplorer = 'project_explorer',
  Scheduler = 'scheduler',
  Start = 'start'
}

/**
  Note, that Webpack Module Federation names don't support dashes "-" in the name, so they're replaced with underscores "_".
 * Example service key observability-dashboard-frontend should map to observability_dashboard
 *
 * @param serviceKey
 * @returns Microverse project ID
 */

export function toMicroverseProjectId(
  serviceKey: ServiceKey
): MicroverseProjectId {
  return serviceKey
    .replace('-frontend', '')
    .replace('-', '_') as MicroverseProjectId
}
