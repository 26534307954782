import { Typography } from '@matillion/component-library'

import {
  SelectableCard,
  SelectableCardBody,
  SelectableCardHeader,
  type SelectableCardProps
} from 'components/SelectableCard'

import classes from './CreateCard.module.scss'

export interface CreateCardProps {
  children: React.ReactNode
  positiveTag?: string
  onClick: SelectableCardProps['onSelection']
  ariaLabel: string
}

export function CreateCard({
  children,
  positiveTag,
  onClick,
  ariaLabel,
  ...otherProps
}: CreateCardProps) {
  return (
    <button
      {...otherProps}
      aria-label={ariaLabel}
      className={classes.CreateCard}
      onClick={onClick}
      type="button"
    >
      <SelectableCard onSelection={onClick}>
        <SelectableCardHeader>
          {!!positiveTag && <PositiveTag>{positiveTag}</PositiveTag>}
        </SelectableCardHeader>
        <SelectableCardBody>{children}</SelectableCardBody>
      </SelectableCard>
    </button>
  )
}

interface PositiveTagProps {
  children: string
}

function PositiveTag({ children }: PositiveTagProps) {
  return (
    <div className={classes.CreateCard__PositiveTag}>
      <Typography format="bcs">{children}</Typography>
    </div>
  )
}
