import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import componentIcons from 'config/component-icons.json'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'

import { singleJoiningSlash } from 'utils/singleJoiningSlash'

export type KnownComponentIcons = typeof componentIcons

export const getComponentIcon = (id: ComponentSummaryId) => {
  const imagePath = componentIcons[id as keyof KnownComponentIcons]

  if (!imagePath) {
    if (
      process.env.NODE_ENV === 'development' &&
      !unknownComponentIds.includes(id)
    ) {
      console.log(`No icon found for component: "${id}".`)
    }
    return singleJoiningSlash(process.env.PUBLIC_URL, componentIcons.unknown)
  }

  return singleJoiningSlash(process.env.PUBLIC_URL, imagePath)
}
