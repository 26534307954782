import { useTranslation } from 'react-i18next'

import { ReactComponent as UpgradeIcon } from 'assets/upgrade-icon.svg'

import { StatusBadge } from './StatusBadge'

export type StatusLabelType = 'preview' | 'upgrade' | 'limited'

export interface StatusLabelProps {
  type: StatusLabelType
  extended?: boolean
}

const ReleaseStatusBadge = ({ type, extended }: StatusLabelProps) => {
  const { t } = useTranslation()
  switch (type) {
    case 'preview':
      return <StatusBadge labelText={t('statusLabel.preview')} colour="blue" />
    case 'upgrade':
      return (
        <StatusBadge
          labelText={t('statusLabel.upgrade')}
          colour="green"
          icon={<UpgradeIcon />}
        />
      )
    case 'limited':
      // This badge has an extended form for use where space is not a concern
      return (
        <StatusBadge
          labelText={
            extended ? t('statusLabel.limitedUsage') : t('statusLabel.limited')
          }
          colour="green"
          icon={<UpgradeIcon />}
        />
      )
  }
}

export default ReleaseStatusBadge
