import { type Dispatch, type MouseEvent, type SetStateAction } from 'react'

import classNames from 'classnames'

import classes from './VirtualisedSampleComponent.module.scss'
import { type ColumnToResize } from './VirtualisedSampleTable'

export const DragHandle = ({
  position,
  setColumnToResize,
  columnId,
  columnIndex
}: {
  position: 'right' | 'left'
  setColumnToResize: Dispatch<SetStateAction<ColumnToResize | null>>
  columnId: string
  columnIndex: number
}) => {
  return (
    <div
      data-testid={`sample-column-drag-handle-${position}`}
      className={classNames(
        classes.DragHandle,
        classes[`DragHandle--${position}`]
      )}
      onMouseDown={(e: MouseEvent) => {
        setColumnToResize({
          columnId,
          columnIndex
        })
      }}
    />
  )
}
