import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import {
  isGridVariable,
  isScalarVariable,
  type JobVariable
} from 'job-lib/types/Variables'

import { type JobState } from '../../job.types'

export const addJobVariable: CaseReducer<
  JobState,
  PayloadAction<JobVariable>
> = (state, { payload: variable }) => {
  const { jobType, job } = state

  if (jobType === null || job === null) {
    return
  }

  if (isScalarVariable(variable)) {
    job.variables = {
      ...job.variables,
      [variable.definition.name]: { ...variable }
    }
  }

  if (isGridVariable(variable)) {
    job.grids = {
      ...job.grids,
      [variable.definition.name]: { ...variable }
    }
  }
}
