import { Typography } from '@matillion/component-library'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import { useGetEnvironmentDefaultValue } from 'hooks/useGetEnvironmentDefaultValue/useGetEnvironmentDefaultValue'

import classes from './PropertyLabel.module.scss'

interface PropertyLabelProps {
  parameter: ComponentParameter | undefined
  value: string | undefined
}

export const PropertyLabel = ({ parameter, value }: PropertyLabelProps) => {
  const propertyText = useGetEnvironmentDefaultValue({
    componentParameter: parameter,
    value
  })

  if (!propertyText) return null

  return (
    <Typography className={classes.PropertyLabel} format="bcs">
      {propertyText}
    </Typography>
  )
}
