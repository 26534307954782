import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import { PencilTextInput } from 'components/PencilTextInput/PencilTextInput'

import { useComponentName } from 'hooks/useComponentName/useComponentName'
import { useGetComponentsNameInCurrentPipeline } from 'hooks/useGetComponentsNameInCurrentPipeline/useGetComponentsNameInCurrentPipeline'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import { componentNameParameter as componentNameParameterId } from 'job-lib/cisIds/knownComponentParameters'
import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import {
  useComponentParameterValidationError,
  useSupersededDetails
} from 'modules/ComponentParameters/hooks'

import ComponentDisplayName from '../ComponentDisplayName/ComponentDisplayName'
import { ComponentIcon } from '../ComponentIcon'
import classes from './ComponentNameParameter.module.scss'

interface ComponentNameParameterProps {
  componentInstance: ComponentInstance
  componentSummaryId: string
  componentMetadata: ComponentMetadata
  onEdit: (params: EditedParameter) => void
  onBlur: (params: EditedParameter) => void
}

export const ComponentNameParameter: FC<ComponentNameParameterProps> = ({
  componentInstance,
  componentSummaryId,
  componentMetadata,
  onEdit,
  onBlur
}) => {
  const { t } = useTranslation()
  const { componentName, componentNameParameter } = useComponentName(
    componentInstance,
    componentMetadata
  )
  const currentComponentNames = useGetComponentsNameInCurrentPipeline()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const validationError = useComponentParameterValidationError({
    componentInstanceId: componentInstance.id,
    parameterSlot: componentNameParameter?.metlSlot ?? -1
  })
  const { isSuperseded } = useSupersededDetails({ componentMetadata })

  if (!componentNameParameter) return null

  const hasValidName = (name: string) => {
    return name.length > 0 && !currentComponentNames.includes(name)
  }

  const handleEdit = (value: string) => {
    onEdit({
      parameterSlot: componentNameParameter.metlSlot,
      parameterDataType: componentNameParameter.dataType,
      editedValue: createHomogeneousElements(
        value,
        componentNameParameter.dataType
      ),
      parameterPath: [componentNameParameterId]
    })
  }

  const onChange = (value: string) => {
    if (currentComponentNames.includes(value) && value !== componentName) {
      setErrorMessage(
        t('translation:componentProperties.status.duplicateNameError')
      )
    } else {
      setErrorMessage(undefined)
    }
  }

  return (
    <div
      data-testid="component-name-parameter"
      className={classes.ComponentNameParameter}
    >
      <ComponentIcon
        componentSummaryId={componentSummaryId}
        componentInstance={componentInstance}
        isDisabled={isSuperseded}
      />
      <div className={classes.ComponentNameParameter__ComponentNameContainer}>
        <PencilTextInput
          value={componentName ?? ''}
          inputId={`param-input-${componentNameParameterId}`}
          onEdit={handleEdit}
          onChange={onChange}
          isEditValid={hasValidName}
          onCloseEditMode={() => {
            setErrorMessage(undefined)
          }}
          buttonLabel={t('componentProperties.nameParameter.buttonLabel')}
          inputPlaceholder={t(
            'componentProperties.nameParameter.inputPlaceholder'
          )}
          hasError={Boolean(validationError)}
          errorText={errorMessage ?? validationError?.message}
        />
        <ComponentDisplayName summaryId={componentSummaryId} />
      </div>
    </div>
  )
}
