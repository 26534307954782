import {
  type GridVariable,
  type ScalarVariable,
  type VariableName
} from 'types/Pipeline'

import {
  JobVariableBehaviour,
  JobVariableType,
  JobVariableVisibility,
  type GridVariable as MetlGridVariable,
  type ScalarVariable as MetlScalarVariable
} from 'job-lib/types/Variables'

import { isGridVariable } from '../../utils/variables'

const convertVariablesToMetl = (
  variables?: Record<VariableName, ScalarVariable | GridVariable>
) => {
  const metlVariables: Record<string, MetlScalarVariable> = {}
  const metlGridVariables: Record<string, MetlGridVariable> = {}

  Object.entries(variables ?? {}).forEach(([variableName, variable]) => {
    if (isGridVariable(variable)) {
      metlGridVariables[variableName] = {
        definition: {
          name: variableName,
          description: variable.metadata.description,
          definitions: Object.entries(variable.metadata.columns).map(
            ([name, column]) => ({
              name,
              type: JobVariableType[
                column.columnType as keyof typeof JobVariableType
              ]
            })
          ),
          scope:
            JobVariableBehaviour[
              variable.metadata.scope as keyof typeof JobVariableBehaviour
            ],
          visibility:
            JobVariableVisibility[
              variable.metadata.visibility as keyof typeof JobVariableVisibility
            ]
        },
        values: variable.defaultValue.map((value) => ({
          values: value
        }))
      }
    } else {
      metlVariables[variableName] = {
        value: variable.defaultValue,
        definition: {
          name: variableName,
          description: variable.metadata.description,
          type: JobVariableType[
            variable.metadata.type as keyof typeof JobVariableType
          ],
          behaviour:
            JobVariableBehaviour[
              variable.metadata.scope as keyof typeof JobVariableBehaviour
            ],
          visibility:
            JobVariableVisibility[
              variable.metadata.visibility as keyof typeof JobVariableVisibility
            ]
        }
      }
    }
  })

  return { metlVariables, metlGridVariables }
}

export default convertVariablesToMetl
