import { type FC } from 'react'
import { NodeToolbar, type NodeProps } from 'reactflow'

import { getIdFromReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'

import { ComponentNodeToolbar } from '../../ComponentNodeToolbar/ComponentNodeToolbar'
import { FlowNodeWrapper } from '../components/FlowNodeWrapper'
import { ComponentNode } from './ComponentNode'
import { type EtlNodeProps } from './models'

export const IteratorNode: FC<NodeProps<EtlNodeProps>> = ({
  id,
  selected: nodeSelected,
  data: {
    imageUrl,
    label,
    inputPorts,
    outputPorts,
    iteratorPorts,
    hasOutputConnection,
    attachedNodes,
    summaryId,
    outputPortsConnected,
    skipped
  }
}) => {
  const { componentId } = useProjectInfo()
  const iteratorNodeSelected = id === `component-${componentId?.toString()}`
  const attachedNodesSelectedIndex = attachedNodes?.findIndex(
    (n) => n.id === `component-${componentId?.toString()}`
  )

  const attachedNodeSelected =
    attachedNodesSelectedIndex !== undefined && attachedNodesSelectedIndex > -1

  const selectedInUrl = iteratorNodeSelected || attachedNodeSelected

  const isSelected = selectedInUrl || nodeSelected
  const iteratorId = Number(getIdFromReactFlowId(id))

  return (
    <FlowNodeWrapper
      id={getIdFromReactFlowId(id)}
      showPorts={isSelected}
      inputPorts={inputPorts}
      outputPorts={outputPorts}
      isSelected={isSelected}
      iteratorPorts={attachedNodes ? [] : iteratorPorts}
      hasOutputConnection={hasOutputConnection}
      outputPortsConnected={outputPortsConnected}
      showOnlyConnectedOutputPorts={unknownComponentIds.includes(summaryId)}
      isUnattachedIterator={!attachedNodes}
    >
      <NodeToolbar isVisible={selectedInUrl}>
        <ComponentNodeToolbar
          summaryId={summaryId}
          selectedComponentInstanceId={
            attachedNodeSelected
              ? Number(
                  getIdFromReactFlowId(
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-non-null-assertion
                    attachedNodes![attachedNodesSelectedIndex].id
                  )
                )
              : iteratorId
          }
          topLevelIterator={
            attachedNodes
              ? {
                  instanceId: iteratorId
                }
              : undefined
          }
        />
      </NodeToolbar>
      <ComponentNode
        id={getIdFromReactFlowId(id)}
        isIterator
        isAttached={!!attachedNodes}
        isSelected={isSelected}
        imageUrl={imageUrl}
        summaryId={summaryId}
        isSkipped={skipped}
      >
        {label}
      </ComponentNode>

      {attachedNodes?.map((attachedNode) => {
        const attachedNodeId = getIdFromReactFlowId(attachedNode.id)

        return (
          <ComponentNode
            key={attachedNodeId}
            id={attachedNodeId}
            imageUrl={attachedNode.data.imageUrl}
            isAttached
            // this is just for display purposes, the label
            // needs to act as if it's selected for the base node as well
            // as preventing z-index issues with the validation badges
            isSelected={isSelected}
            summaryId={attachedNode.data.summaryId}
            isIterator={attachedNode.data.iteratorPorts.length !== 0}
            isSkipped={skipped}
          >
            {attachedNode.data.label}
          </ComponentNode>
        )
      })}
    </FlowNodeWrapper>
  )
}
