import { useCallback } from 'react'
import { type XYPosition } from 'reactflow'

import { useMakeNote } from '../useMakeNote/useMakeNote'

export const useGeneratePipelineDocsForSelection = () => {
  const { makeNote } = useMakeNote()

  const generatePipelineDocs = useCallback(
    async (position: XYPosition, selection: string[]) => {
      const noteWidth = 300
      const noteHeight = 420

      makeNote(
        {
          x: position.x,
          y: position.y
        },
        undefined,
        noteWidth,
        noteHeight,
        true,
        selection
      )
    },
    [makeNote]
  )

  return { generatePipelineDocs }
}
