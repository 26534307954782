import React, { type FC } from 'react'
import { type FieldValues } from 'react-hook-form'

import { type FileType } from '@matillion/git-component-library'

import { useCreateNonPipelineFile } from '../../hooks/useCreateNonPipelineFile'
import { CreateFileBaseModal } from './CreateFileBaseModal'

export interface CreateNonPipelineFileModalProps {
  type: FileType
  folderPath: string | null
  onClose: () => void
}

const CreateNonPipelineFileModal: FC<CreateNonPipelineFileModalProps> = ({
  type,
  onClose,
  folderPath
}) => {
  const { isLoading, createNonPipelineFile } = useCreateNonPipelineFile({
    type,
    onComplete: onClose
  })

  const onSubmit = (data: FieldValues) => {
    const jobName: string = data.jobName.trim()
    const runtimeName = folderPath ? `${folderPath}/${jobName}` : jobName
    createNonPipelineFile(runtimeName)
  }

  return (
    <CreateFileBaseModal
      onSubmit={onSubmit}
      onClose={onClose}
      type={type}
      folderPath={folderPath}
      isLoading={isLoading}
    />
  )
}

export default CreateNonPipelineFileModal
