import { Fragment, useState, type FunctionComponent } from 'react'

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Tooltip, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { PopOverMenu } from 'components/PopOverMenu'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import classes from './InteractiveTabs.module.scss'
import { ScrollContainer } from './ScrollContainer'
import { TabCloseButton } from './TabCloseButton'
import { TabContextMenu } from './TabContextMenu'
import { type TabsListProps } from './types'

export const TabList: FunctionComponent<
  React.PropsWithChildren<TabsListProps>
> = ({
  listClassName,
  onChange,
  currentTabIndex,
  setInternalTabIndexValue,
  children,
  onCloseAll
}) => {
  const { reorderTabs, selectedTabs } = useSelectedJobs()
  const [dragging, setDragging] = useState(false)
  return (
    <DragDropContext
      onDragStart={() => {
        setDragging(true)
      }}
      onDragEnd={(dropResult) => {
        setDragging(false)
        /* istanbul ignore if */
        if (!dropResult.destination) {
          return
        }
        const sourceIndex = dropResult.source.index
        const destinationIndex = dropResult.destination.index
        reorderTabs(sourceIndex, destinationIndex)
      }}
    >
      <Droppable direction="horizontal" droppableId="file-tab-list">
        {(droppableProvided) => {
          return (
            <ScrollContainer droppableProvided={droppableProvided}>
              <div
                role="tablist"
                data-dragging={dragging}
                data-testid="tab-list"
                className={classnames(
                  classes.InteractiveTabs__TabList,
                  listClassName
                )}
              >
                {children.map((tab, index) => (
                  <Fragment key={tab.props.internalKey}>
                    <PopOverMenu
                      className={classes.InteractiveTabs__PopOver}
                      positionAtMouse
                      content={
                        <TabContextMenu
                          title={tab.props.title}
                          index={index}
                          selectedTabCount={selectedTabs.length}
                          onTabClose={tab.props.onTabClose}
                          onCloseAll={onCloseAll}
                          onCloseOthers={tab.props.onCloseOthers}
                        />
                      }
                    >
                      {({ onContextMenu }) => (
                        <Draggable
                          disableInteractiveElementBlocking
                          draggableId={tab.props.internalKey}
                          index={index}
                        >
                          {(draggableProvided) => {
                            return (
                              <button
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                type="button"
                                aria-selected={
                                  index === currentTabIndex ? 'true' : 'false'
                                }
                                data-testid={tab.props['data-testid']}
                                ref={draggableProvided.innerRef}
                                role="tab"
                                className={classnames(
                                  classes.InteractiveTabs__TabContainer,
                                  tab.props.className,
                                  {
                                    [classes[
                                      'InteractiveTabs__TabContainer--active'
                                    ]]: index === currentTabIndex
                                  }
                                )}
                                onClick={() => {
                                  setInternalTabIndexValue(index)
                                  if (onChange) {
                                    onChange({ value: index, tab: tab.props })
                                  }
                                }}
                                onContextMenu={onContextMenu}
                                disabled={tab.props.disabled}
                              >
                                <div
                                  className={classnames(
                                    classes.InteractiveTabs__TabInnerContainer
                                  )}
                                >
                                  {tab.props.iconBefore && (
                                    <span
                                      className={
                                        classes.InteractiveTabs__TabIconBefore
                                      }
                                    >
                                      {tab.props.iconBefore}
                                    </span>
                                  )}
                                  <Tooltip
                                    content={tab.props.tooltip}
                                    onlyShowOnOverflow
                                  >
                                    <div
                                      className={
                                        classes.InteractiveTabs__TabTitle
                                      }
                                    >
                                      <Typography format="bcs" as="span">
                                        {tab.props.title}
                                      </Typography>
                                    </div>
                                  </Tooltip>
                                </div>
                                {tab.props.onTabClose && (
                                  <TabCloseButton
                                    testId={`close-${
                                      tab.props['data-testid'] ?? `tab-${index}`
                                    }`}
                                    title={tab.props.title}
                                    onClick={tab.props.onTabClose}
                                  />
                                )}
                              </button>
                            )
                          }}
                        </Draggable>
                      )}
                    </PopOverMenu>
                  </Fragment>
                ))}
                {droppableProvided.placeholder}
              </div>
            </ScrollContainer>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
