import { type TFunction } from 'react-i18next'

import {
  EditorType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'

const shouldHideGroupName = (
  componentId: string,
  parameter: ComponentParameter
) => {
  return (
    (isModularFlexOrCustom(componentId) &&
      (parameter.editorType === EditorType.PARAMETER_GROUP ||
        parameter.editorType === EditorType.CONNECTION_EDITOR)) ||
    parameter.editorType === EditorType.SHARED_PIPELINE_SELECTOR
  )
}

export const getParameterName = (
  componentId: string,
  parameter: ComponentParameter,
  parameterPath: string[],
  t: TFunction,
  warehouse?: string
): {
  parameterName: string
  shouldHideParameterGroupName: boolean
} => {
  const parameterName = isModularFlexOrCustom(componentId)
    ? parameter.displayName ?? ''
    : t([
        ...(parameterPath.length > 0
          ? [
              `${componentId}.componentProperties.parameterIds.${parameterPath.join(
                '.'
              )}.${parameter.resourceID as string}`
            ]
          : []),
        `${componentId}.componentProperties.parameterIds.${
          parameter.resourceID as string
        }`,
        `${warehouse}.componentProperties.parameterIds.${
          parameter.resourceID as string
        }`,
        `componentProperties.parameterIds.${parameter.resourceID as string}`,
        parameter.displayName ??
          `componentProperties.parameterIds.${parameter.dplID}`
      ])
  const shouldHideParameterGroupName = shouldHideGroupName(
    componentId,
    parameter
  )
  return {
    parameterName,
    shouldHideParameterGroupName
  }
}
