import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { createNameParameter } from 'job-lib/builders/createComponent/createNameParameter'
import { generateNextComponentId } from 'job-lib/job-functions/generateNextSequenceId'
import { generateUniqueName } from 'job-lib/job-functions/generateUniqueName'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import {
  type ComponentInstance,
  type OrchestrationComponentInstance,
  type TransformationComponentInstance
} from 'job-lib/types/Job'
import { type JobType } from 'job-lib/types/JobType'

import { type JobState, type UpdateLinks } from '../../job.types'
import { isDPLParameterCollection } from '../../utils/isDPLParameterCollection'
import { setDPLComponentNameOverride } from '../../utils/setDPLComponentNameOverride'
import { updateConnectors } from '../updateConnectors/updateConnectors'

type Connection =
  | Omit<UpdateLinks, 'targetComponentId'>
  | Omit<UpdateLinks, 'sourceComponentId'>

export type AddComponentPayload =
  | {
      component: Omit<OrchestrationComponentInstance, 'id'>
      componentType: JobType.Orchestration
      connection?: Connection
    }
  | {
      component: Omit<TransformationComponentInstance, 'id'>
      componentType: JobType.Transformation
      connection?: Connection
    }

export const addComponent: CaseReducer<
  JobState,
  PayloadAction<AddComponentPayload>
> = (state, { payload: { component, componentType, connection } }) => {
  if (!state.job) {
    return state
  }
  if (componentType !== state.jobType) {
    throw new TypeError(
      `Cannot use component type: ${componentType} on a${
        state.jobType.match(/^[aeiou]/i) ? 'n' : ''
      } ${state.jobType} job`
    )
  }

  const newKey = generateNextComponentId(state.job)

  let newComponent: ComponentInstance = {
    ...component,
    id: newKey
  }

  // generate a unique name for this addition
  const oldName = getComponentName(newComponent)
  const uniqueName = generateUniqueName(oldName, state.job.components)
  const isNewDPLParameterCollection = isDPLParameterCollection(
    newComponent.parameters
  )
  if (uniqueName !== oldName) {
    newComponent = {
      ...newComponent,
      parameters: {
        ...newComponent.parameters,
        1: createNameParameter(uniqueName)
      }
    }

    if (isNewDPLParameterCollection) {
      newComponent.parameters[2] = setDPLComponentNameOverride(
        newComponent,
        uniqueName
      )
    }
  }

  state.job.components[newKey] = newComponent

  if (!connection) {
    return state
  }

  if ('sourceComponentId' in connection) {
    return updateConnectors(state, {
      type: '',
      payload: {
        ...connection,
        targetComponentId: newKey
      }
    })
  }

  return updateConnectors(state, {
    type: '',
    payload: {
      ...connection,
      sourceComponentId: newKey
    }
  })
}
