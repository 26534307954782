import { cloneDeep } from 'lodash'
import { create } from 'zustand'

import { type SharedPipelinesConfig } from './types'

interface SharedPipelinesConfigStore {
  sharedPipelinesConfig: SharedPipelinesConfig | null
  setSharedPipelinesConfig: (
    sharedPipelinesConfig: SharedPipelinesConfig
  ) => void
  getSharedPipelinesConfig: () => SharedPipelinesConfig | null
  deleteSharedPipelinesConfig: () => void
}

export const useSharePipelineStore = create<SharedPipelinesConfigStore>()(
  (set, get) => ({
    sharedPipelinesConfig: null,
    setSharedPipelinesConfig: (sharedPipelinesConfig) => {
      set({
        sharedPipelinesConfig
      })
    },
    getSharedPipelinesConfig: () => {
      return cloneDeep(get().sharedPipelinesConfig)
    },
    deleteSharedPipelinesConfig: () => {
      set({
        sharedPipelinesConfig: null
      })
    }
  })
)
