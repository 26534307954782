import { isEmpty } from 'lodash'

import type { ComponentSummary } from 'api/hooks/useGetComponentSummaries'
import type { ProjectType } from 'api/hooks/useGetProject/types'

interface IsCustomFlexComponentSupersededProps {
  supersededBy?: string
  flagEnabled?: boolean
  components?: ComponentSummary[]
  projectType?: ProjectType
}

export const isCustomFlexComponentSuperseded = ({
  supersededBy,
  flagEnabled,
  components,
  projectType
}: IsCustomFlexComponentSupersededProps) => {
  // if required information is not present, or if the feature flag is disabled, then we return early
  if (isEmpty(supersededBy) || !flagEnabled || !components || !projectType) {
    return false
  }

  // @ts-expect-error - we know that supersededBy is not empty here
  return components.map(({ componentId }) => componentId).includes(supersededBy)
}
