import { type FC } from 'react'

import { AddAll, AddOne, RemoveAll, RemoveOne, Reset } from '../svg'
import { type SVGIconProps } from '../svg/types'
import { type ControlProps } from '../types'
import classes from './Controls.module.scss'
import { IconButton } from './IconButton'

const MoveUp: FC<SVGIconProps> = (props) => (
  <AddOne {...props} className={classes.MoveUp} />
)
const MoveDown: FC<SVGIconProps> = (props) => (
  <AddOne {...props} className={classes.MoveDown} />
)

export const Controls: FC<ControlProps> = ({
  leftColumn,
  rightColumn,
  selectedItems,
  isClean,
  handleReset,
  handleMoveUp,
  handleMoveDown,
  handleMoveRight,
  handleMoveAllRight,
  handleMoveLeft,
  handleMoveAllLeft,
  options
}) => {
  const isMoveColumnsButtonDisabled = (column: string): boolean => {
    if (column === 'leftColumn') {
      return !selectedItems.some((item) => leftColumn.includes(item))
    }

    return !selectedItems.some((item) => rightColumn.includes(item))
  }
  const isMoveRowsButtonDisabled = (direction: string): boolean => {
    if (selectedItems.length === 0) {
      // No items selected.
      return true
    }

    if (selectedItems.some((item) => leftColumn.includes(item))) {
      // Items selected in left column.
      return true
    }

    if (direction === 'up') {
      return selectedItems.includes(rightColumn[0])
    } else {
      // direction === 'down'
      return selectedItems.includes(rightColumn[rightColumn.length - 1])
    }
  }

  const customControls =
    options.customControls?.({
      leftColumn,
      rightColumn,
      selectedItems,
      isClean,
      handleReset,
      handleMoveUp,
      handleMoveDown,
      handleMoveRight,
      handleMoveAllRight,
      handleMoveLeft,
      handleMoveAllLeft,
      options
    }) ?? []

  return (
    <div className={classes.Controls}>
      <IconButton
        icon={AddAll}
        aria-label={options.labels.controlAddAll}
        data-testid="move-all-right"
        disabled={leftColumn.filter(Boolean).length <= 0}
        onClick={() => {
          handleMoveAllRight()
        }}
      />
      <IconButton
        icon={AddOne}
        aria-label={options.labels.controlAddOne}
        disabled={isMoveColumnsButtonDisabled('leftColumn')}
        onClick={() => {
          handleMoveRight()
        }}
      />
      <IconButton
        icon={RemoveOne}
        aria-label={options.labels.controlRemoveOne}
        disabled={isMoveColumnsButtonDisabled('rightColumn')}
        onClick={() => {
          handleMoveLeft()
        }}
      />
      <IconButton
        icon={RemoveAll}
        aria-label={options.labels.controlRemoveAll}
        data-testid="move-all-left"
        disabled={rightColumn.filter(Boolean).length <= 0}
        onClick={() => {
          handleMoveAllLeft()
        }}
      />
      <IconButton
        icon={MoveUp}
        aria-label={options.labels.controlMoveUp}
        data-testid="move-up-left"
        disabled={isMoveRowsButtonDisabled('up')}
        onClick={() => {
          handleMoveUp()
        }}
      />
      <IconButton
        icon={MoveDown}
        aria-label={options.labels.controlMoveDown}
        data-testid="move-down-left"
        disabled={isMoveRowsButtonDisabled('down')}
        onClick={() => {
          handleMoveDown()
        }}
      />
      <IconButton
        icon={Reset}
        aria-label={options.labels.controlReset}
        disabled={isClean}
        onClick={() => {
          handleReset()
        }}
      />

      {customControls.map((iconProps, index) => {
        return (
          <IconButton
            key={`custom-control-${index}`}
            {...iconProps}
            {...(!index ? { className: classes.Controls__Grouped } : {})}
          />
        )
      })}
    </div>
  )
}
