import { type LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import { type APIError } from 'api/hooks/useGetParameterOptions/types'

import {
  type FullPath,
  type StorageEditorType
} from 'modules/ParameterEditors/types'

import { useGetBuckets } from './useGetBuckets'
import { useGetFoldersAndObjects } from './useGetFoldersAndObjects'

interface UseGetStorageLocationsResponse {
  isBucketsLoading: boolean
  isFoldersLoading: boolean
  objects: string[] | null
  folders: string[] | null
  buckets: string[] | null
  foldersError: APIError | null
  bucketsError: APIError | null
}

/**
 * Hook to retrieve remote objects (buckets, containers,
 *  files, directories etc.) the given cloud storage location and configuration.
 */
export const useGetContainers = (
  fullPath: FullPath,
  storageEditorType?: StorageEditorType,
  lookupType?: LookUpType | null,
  fileType?: string | null
): UseGetStorageLocationsResponse => {
  const bucketsQuery = useGetBuckets({ storageEditorType })
  const foldersQuery = useGetFoldersAndObjects({
    storageEditorType,
    fullPath,
    fileType,
    lookupType
  })

  const buckets = bucketsQuery.data?.editorColumns?.[0]?.options ?? null
  const folders = foldersQuery.data?.editorColumns?.[0]?.options ?? null
  const objects = foldersQuery.data?.editorColumns?.[1]?.options ?? null

  const bucketsError = bucketsQuery.isError ? bucketsQuery.error : null
  const foldersError = foldersQuery.isError ? foldersQuery.error : null

  const isBucketsLoading =
    bucketsQuery.isFetching ||
    bucketsQuery.isInitialLoading ||
    bucketsQuery.isRefetching
  const isFoldersLoading =
    foldersQuery.isFetching ||
    foldersQuery.isInitialLoading ||
    foldersQuery.isRefetching

  return {
    isBucketsLoading,
    isFoldersLoading,
    objects,
    folders,
    buckets,
    foldersError,
    bucketsError
  }
}
