import {
  useCallback,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
  type ReactNode
} from 'react'

import { Modal } from '@matillion/component-library'

import StackingModalContext, {
  type StackingModalContextInterface,
  type StackingModalProps
} from './StackingModalContext'

interface ModalStack {
  modalContents: ReactNode
  modalProps: StackingModalProps
}

const StackingModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalStack, setModalStack] = useState<ModalStack[]>([])

  const value = useMemo<StackingModalContextInterface>(
    () => ({
      pushNewModalContent(modalProps, newModalContents) {
        setModalStack((p) => [
          ...p,
          { modalProps, modalContents: newModalContents }
        ])
      },
      closeAll: () => {
        setModalStack([])
      }
    }),
    []
  )

  const popStack = useCallback(() => {
    setModalStack((p) => p.slice(0, -1))
  }, [])

  const currentModal = useMemo(() => {
    return modalStack.at(-1)
  }, [modalStack])

  return (
    <StackingModalContext.Provider value={value}>
      {currentModal && (
        <Modal
          {...currentModal.modalProps}
          onCancel={popStack}
          ariaLabelledBy={currentModal.modalProps.ariaLabelledBy}
        >
          {currentModal.modalContents}
        </Modal>
      )}
      {children}
    </StackingModalContext.Provider>
  )
}

export default StackingModalProvider
