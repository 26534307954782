import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import { type APIError } from 'api/hooks/useGetParameterOptions/types'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import classes from './SemiStructuredNestedDataPickerEditor.module.scss'

export interface AlertsProps {
  isPipelineValidated: boolean
  numberOfColumnsSelected: number
  autofillError: APIError | null
  isEmptyAutofillResponse: boolean
}

export const Alerts = ({
  isPipelineValidated,
  numberOfColumnsSelected,
  autofillError,
  isEmptyAutofillResponse
}: AlertsProps) => {
  const { t } = useTranslation()

  const { setValidationEnabled } = useComponentValidationProvider()

  if (!isPipelineValidated) {
    return (
      <Alert
        aria-label={t('parameterEditor.warnings.validationWarning')}
        className={classes.Alert}
        type="warning"
        title={t('parameterEditor.warnings.validationWarning')}
        message=""
        action={{
          text: t('parameterEditor.warnings.validationAction'),
          onClick: setValidationEnabled
        }}
      />
    )
  }

  if (numberOfColumnsSelected === 0) {
    return (
      <Alert
        aria-label={t(
          'parameterEditor.NESTED_DATA_PICKER_EDITOR.noColumnsSelectedError'
        )}
        className={classes.Alert}
        type="error"
        title={t(
          'parameterEditor.NESTED_DATA_PICKER_EDITOR.noColumnsSelectedError'
        )}
        message=""
      />
    )
  }

  if (autofillError) {
    return (
      <Alert
        aria-label={autofillError.detail}
        className={classes.Alert}
        type="error"
        message={autofillError.detail}
      />
    )
  }

  if (isEmptyAutofillResponse) {
    return (
      <Alert
        aria-label={t(
          'parameterEditor.NESTED_DATA_PICKER_EDITOR.autofillNoDataError'
        )}
        className={classes.Alert}
        type="error"
        title={t(
          'parameterEditor.NESTED_DATA_PICKER_EDITOR.autofillNoDataError'
        )}
        message=""
      />
    )
  }

  return null
}
