import { startImplementationId } from 'job-lib/cisIds/knownComponentParameters'
import { type OrchestrationJob } from 'job-lib/types/Job'

export const canDeleteComponents = (
  idsToRemove: number[],
  job: OrchestrationJob
) => {
  const componentsArray = Object.values(job.components)

  let numberOfStartComponents = componentsArray.filter(
    (component) => component.implementationID === startImplementationId
  ).length

  idsToRemove.forEach((id) => {
    if (job.components[id].implementationID === startImplementationId) {
      numberOfStartComponents -= 1
    }
  })

  if (numberOfStartComponents > 0) {
    return true
  }

  return false
}
