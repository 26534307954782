/* eslint-disable @typescript-eslint/no-dynamic-delete */
import type { ComponentName, Pipeline } from 'types/Pipeline'

import { deleteLink } from '../deleteLink/deleteLink'

export const deleteComponents =
  (state: Pipeline) => (namesToRemove: ComponentName[]) => {
    namesToRemove.forEach((nameToRemove) => {
      delete state.pipeline.components[nameToRemove]
      delete state.design.components[nameToRemove]

      deleteDanglingLinks(state, nameToRemove)
    })
  }

export const deleteDanglingLinks = (
  state: Pipeline,
  oldComponentName: string
) => {
  /* remove any dangling links to the component */
  Object.keys(state.pipeline.components).forEach((componentName) => {
    deleteLink(state)({
      sourceName: componentName,
      targetName: oldComponentName
    })
  })
}
