import { Textarea } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './TextMode.module.scss'

interface TextModeTextAreaProps {
  textModeValue: string
  setTextModeValue: (newValue: string) => void
  className: string | undefined
}
export const TextModeTextarea = ({
  textModeValue,
  setTextModeValue,
  className
}: TextModeTextAreaProps) => {
  return (
    <Textarea
      autoFocus
      name="text-mode-textarea"
      className={classNames(classes.Textarea, className)}
      value={textModeValue}
      onChange={(e) => {
        setTextModeValue(e.target.value)
      }}
      onKeyDown={(event) => {
        if (event.key === 'Tab') {
          event.preventDefault()
          // Allow the literal \t character to be entered
          const start = event.currentTarget.selectionStart
          const end = event.currentTarget.selectionEnd
          event.currentTarget.value =
            event.currentTarget.value.substring(0, start) +
            '\t' +
            event.currentTarget.value.substring(end)
          event.currentTarget.selectionStart = start + 1
          event.currentTarget.selectionEnd = start + 1
        }
      }}
    />
  )
}
