import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useConnectorComponentSummary } from 'api/hooks/useGetComponentSummaries'

import { isFlexConnector } from 'job-lib/cisIds/idType'

import { getComponentIcon } from 'utils/component-icons/component-icons'

import { type Parameters } from '../../job-lib/types/Job'
import { type ParameterCollection } from '../../job-lib/types/Parameters'
import { useGetDynamicIconFromParameters } from '../useGetDynamicIconFromParameters/useGetDynamicIconFromParameters'

export const useComponentInfo = () => {
  const { t } = useTranslation('componentSummaries')
  const { getSummary } = useConnectorComponentSummary()
  const { getDynamicIcon } = useGetDynamicIconFromParameters()

  const getDisplayName = useCallback(
    (componentId: string): string => {
      return (
        getSummary(componentId)?.displayName ??
        t([`components.${componentId}.displayName`, 'unknownComponent'])
      )
    },
    [getSummary, t]
  )

  const getDescription = useCallback(
    (componentId: string): string => {
      const componentSummary = getSummary(componentId)
      if (componentSummary) {
        const desc = isFlexConnector(componentId)
          ? t('flexConnectorDescription', {
              connector: componentSummary.displayName
            })
          : t('customConnectorDescription')
        return desc
      }

      return t([`components.${componentId}.description`, '-'])
    },
    [getSummary, t]
  )

  const getIcon = useCallback(
    (componentId: string, parameters?: ParameterCollection | Parameters) => {
      const dynamicIcon = parameters && getDynamicIcon(componentId, parameters)
      return (
        dynamicIcon ||
        getSummary(componentId)?.icon ||
        getComponentIcon(componentId)
      )
    },
    [getDynamicIcon, getSummary]
  )

  const getTags = useCallback(
    (componentId: string): string[] => {
      if (getSummary(componentId)) {
        return isFlexConnector(componentId)
          ? [t(['tags.source'])]
          : [t(['tags.custom'])]
      }

      return t(`components.${componentId}.tags`, {
        returnObjects: true,
        defaultValue: []
      })
    },
    [getSummary, t]
  )

  const getSynonyms = useCallback(
    (componentId: string): string[] => {
      if (getSummary(componentId)) {
        return [t(['synonyms.connector'])]
      }

      return t(`components.${componentId}.synonyms`, {
        returnObjects: true,
        defaultValue: []
      })
    },
    [getSummary, t]
  )

  return {
    getTags,
    getDisplayName,
    getIcon,
    getSynonyms,
    getDescription
  }
}
