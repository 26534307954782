import type { AutoCompleteItemId } from 'components/AutoComplete/types'

import i18next from 'locales/i18n'

const defaultSampleLimitItem = 25

const sampleLimitItems = [1, 10, 25, 50, 100, 250, 500, 1000]

export const calculateInitialDropdownValue = (
  userPreference: AutoCompleteItemId,
  maxSampleSize?: number
) => {
  const preference =
    userPreference ?? toAutoCompleteItemId(defaultSampleLimitItem)

  if (maxSampleSize && (preference.id as number) > maxSampleSize) {
    return toAutoCompleteItemId(maxSampleSize)
  }

  return preference
}

export const toAutoCompleteItemId = (item: number) => {
  return {
    id: item,
    name: i18next.t('sampleComponent.sampleLimit', {
      count: item
    })
  }
}

export const getDefaultSampleLimitItem = () => {
  return toAutoCompleteItemId(defaultSampleLimitItem)
}

export const getSampleLimitItems = (maxSampleSize?: number) => {
  const available = sampleLimitItems.map(toAutoCompleteItemId)
  if (maxSampleSize) {
    return available
      .filter((item) => item.id <= maxSampleSize && item.id !== maxSampleSize)
      .concat(toAutoCompleteItemId(maxSampleSize))
  } else {
    return available
  }
}
