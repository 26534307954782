import { useId, useRef, useState, type RefObject } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { IconButton } from 'components/IconButton/IconButton'
import { useUserPreference } from 'components/UserPreferenceProvider'

import classes from './FloatingPopover.module.scss'
import { type FloatingPopoverPosition, type FloatingPopoverSize } from './types'
import { useDraggableAndResizable } from './useDraggableAndResizable/useDraggableAndResizable'

interface FloatingPopoverProps {
  title: string
  open: boolean
  setOpen: (open: boolean) => void
  display: {
    defaultSize: {
      width: number
      height: number
    }
    defaultPosition: {
      left: number
      top: number
    }
  }
  boundaries: {
    minWidth: number
    minHeight: number
    maxWidth: number
    maxHeight: number
  }
  /**
   * A ref to the boundary outside of which the floating popover cannot be moved
   */
  dragBoundaryRef?: RefObject<HTMLElement>
  /** Contents of the popover */
  children: React.ReactNode
}

export const FloatingPopover = ({
  display,
  title,
  open,
  setOpen,
  dragBoundaryRef,
  boundaries,
  children
}: FloatingPopoverProps) => {
  const { minWidth, minHeight, maxHeight, maxWidth } = boundaries
  const { userPreference, setSpecificUserPreference } = useUserPreference()
  const containerRef = useRef<HTMLDialogElement | null>(null)
  const [position, setPosition] = useState(
    userPreference.filesPopoverPosition ?? display.defaultPosition
  )
  const [size, setSize] = useState(
    userPreference.filesPopoverSize ?? display.defaultSize
  )

  const popoverId = useId()

  const updatePosition = (newPosition: FloatingPopoverPosition) => {
    setPosition(newPosition)
    setSpecificUserPreference('filesPopoverPosition', newPosition)
  }

  const updateSize = (newSize: FloatingPopoverSize) => {
    setSize(newSize)
    setSpecificUserPreference('filesPopoverSize', newSize)
  }

  const { startDrag, startResize } = useDraggableAndResizable({
    updatePosition,
    updateSize,
    containerRef,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    dragBoundaryRef
  })
  if (!open) {
    return null
  }
  return (
    <dialog
      aria-labelledby={`${popoverId}-title`}
      tabIndex={-1}
      ref={containerRef}
      style={{
        width: size.width,
        height: size.height,
        transform: `translate(${position.left}px, ${position.top}px)`
      }}
      className={classes.Container}
      data-testid="floating-popover-container"
    >
      <div
        data-testid="floating-popover-title-container"
        onMouseDown={startDrag}
        className={classNames(classes.TitleContainer)}
      >
        <Typography id={`${popoverId}-title`} format="bold">
          {title}
        </Typography>
        <IconButton className={classes.DragHandle} label="Drag">
          <Icon.DragHandle />
        </IconButton>
        <IconButton
          className={classes.CloseButton}
          onClick={(e) => {
            e.stopPropagation()
            setOpen(false)
          }}
          label="Close"
        >
          <Icon.Cross />
        </IconButton>
      </div>
      <div className={classes.ContentContainer}>{children}</div>
      <div
        data-testid="floating-popover-resize-n"
        className={classNames(classes.NResize, classes.Resize)}
        onMouseDown={startResize('n')}
      />
      <div
        data-testid="floating-popover-resize-s"
        className={classNames(classes.SResize, classes.Resize)}
        onMouseDown={startResize('s')}
      />
      <div
        data-testid="floating-popover-resize-e"
        className={classNames(classes.EResize, classes.Resize)}
        onMouseDown={startResize('e')}
      />
      <div
        data-testid="floating-popover-resize-w"
        className={classNames(classes.WResize, classes.Resize)}
        onMouseDown={startResize('w')}
      />
      <div
        data-testid="floating-popover-resize-nw"
        className={classNames(classes.NwResize, classes.Resize)}
        onMouseDown={startResize('nw')}
      />
      <div
        data-testid="floating-popover-resize-ne"
        className={classNames(classes.NeResize, classes.Resize)}
        onMouseDown={startResize('ne')}
      />
      <div
        data-testid="floating-popover-resize-sw"
        className={classNames(classes.SwResize, classes.Resize)}
        onMouseDown={startResize('sw')}
      />
      <div
        data-testid="floating-popover-resize-se"
        className={classNames(classes.SeResize, classes.Resize)}
        onMouseDown={startResize('se')}
      />
    </dialog>
  )
}
