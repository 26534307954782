import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  Popover,
  Tooltip,
  Typography
} from '@matillion/component-library'

import {
  DocsVerbosity,
  useGeneratePipelineDocs
} from '../../../api/hooks/sidekick/useGeneratePipelineDocs/useGeneratePipelineDocs'
import { useFlags } from '../../../hooks/useFlags'
import { AiLoadingScreen } from '../AiLoadingScreen/AiLoadingScreen'
import { MarkdownTextViewer } from '../MarkdownTextViewer/MarkdownTextViewer'
import classes from './AiNoteGenerator.module.scss'
import { LengthenText } from './svg/LengthenText'
import { ReloadDocs } from './svg/ReloadDocs'
import { ShortenText } from './svg/ShortenText'
import { decreaseVerbosity, increaseVerbosity } from './util/VerbosityHelper'

export interface AiNoteGeneratorProps {
  id: number
  selection: string[]
  onComplete: (generatedText: string) => void
}

export const AiNoteGenerator = ({
  id,
  selection,
  onComplete
}: AiNoteGeneratorProps) => {
  const { t } = useTranslation()
  const [note, setNote] = useState<string>('')
  const [verbosity, setVerbosity] = useState<DocsVerbosity>(
    DocsVerbosity.MEDIUM
  )
  const [isReviseOpen, setIsReviseOpen] = useState(false)
  const { enableRefineAiAutoDocs } = useFlags()
  const {
    refetch: regenerateDoc,
    isLoading: isLoadingDoc,
    isFetching: isFetchingDoc,
    isError: isErrorDoc
  } = useGeneratePipelineDocs(id, selection, verbosity)

  const regenerateDocHandler = useCallback(() => {
    regenerateDoc().then((r) => {
      if (r.data) {
        setNote(r.data.description)
      }
    })
  }, [regenerateDoc])

  useEffect(() => {
    // Regenerate docs on mount and when verbosity changes
    regenerateDocHandler()
  }, [verbosity, regenerateDocHandler])

  const elaborateDocHandler = () => {
    setVerbosity(increaseVerbosity(verbosity))
  }

  const shortenDocHandler = () => {
    setVerbosity(decreaseVerbosity(verbosity))
  }

  const getTextDisplay = () => {
    if (isFetchingDoc) {
      return <AiLoadingScreen message={t('note.aiGenerator.message')} />
    } else if (isErrorDoc || selection.length === 0) {
      return (
        <div className={classes.AiNoteGenerator__ErrorContainer}>
          <Icon.Error className={classes.AiNoteGenerator__ErrorIcon} />
          <Typography>{t('note.aiGenerator.error.message')}</Typography>
        </div>
      )
    } else {
      return <MarkdownTextViewer content={note} />
    }
  }

  return (
    <>
      {getTextDisplay()}
      {!isLoadingDoc && (
        <div className={classes.AiNoteGenerator__ButtonGroup}>
          {enableRefineAiAutoDocs ? (
            <Popover
              className={classes.AiNoteGenerator__RevisePopover}
              isOpen={isReviseOpen}
              align="center"
              anchor={
                <Button
                  waiting={isFetchingDoc}
                  onClick={function noRefCheck() {
                    setIsReviseOpen(!isReviseOpen)
                  }}
                  alt={'secondary'}
                >
                  <Typography
                    className={classes.AiNoteGenerator__ReviseChevron}
                  >
                    {t('note.aiGenerator.refine')}
                  </Typography>
                  <Icon.ChevronDown />
                </Button>
              }
              onClickOutside={function noRefCheck() {
                setIsReviseOpen(false)
              }}
              padding={10}
              position="bottom"
            >
              <div className={classes.AiNoteGenerator__ReviseButtonGroup}>
                <Tooltip
                  content={
                    verbosity === DocsVerbosity.HIGH
                      ? 'Cannot elaborate further'
                      : 'Elaborate to add more detail to the note'
                  }
                >
                  <Button
                    className={classes.AiNoteGenerator__ReviseButton}
                    data-testid="elaborate-ai-note"
                    alt="text"
                    disabled={verbosity === DocsVerbosity.HIGH}
                    waiting={isFetchingDoc}
                    onClick={elaborateDocHandler}
                  >
                    <LengthenText />
                    <Typography format={'bcs'}>
                      {t('note.aiGenerator.elaborate')}
                    </Typography>
                  </Button>
                </Tooltip>
                <Tooltip
                  content={
                    verbosity === DocsVerbosity.LOW
                      ? 'Cannot shorten further'
                      : 'Shorten to reduce the size of the note'
                  }
                >
                  <Button
                    className={classes.AiNoteGenerator__RegenerateButton}
                    data-testid="regenerate-ai-note"
                    alt="text"
                    disabled={verbosity === DocsVerbosity.LOW}
                    waiting={isFetchingDoc}
                    onClick={shortenDocHandler}
                  >
                    <ShortenText />
                    <Typography format={'bcs'}>
                      {t('note.aiGenerator.shorten')}
                    </Typography>
                  </Button>
                </Tooltip>
                <Button
                  className={classes.AiNoteGenerator__RegenerateButton}
                  data-testid="regenerate-ai-note"
                  alt="text"
                  waiting={isFetchingDoc}
                  onClick={regenerateDocHandler}
                >
                  <ReloadDocs />
                  <Typography format={'bcs'}>
                    {t('note.aiGenerator.regenerate')}
                  </Typography>
                </Button>
              </div>
            </Popover>
          ) : (
            <Button
              data-testid="regenerate-ai-note"
              alt="secondary"
              waiting={isFetchingDoc}
              onClick={regenerateDocHandler}
            >
              <Typography>{t('note.aiGenerator.regenerate')}</Typography>
            </Button>
          )}

          <Button
            data-testid="add-ai-note"
            alt="primary"
            disabled={isFetchingDoc}
            onClick={() => {
              onComplete(note)
            }}
          >
            <Typography>{t('note.aiGenerator.add')}</Typography>
          </Button>
        </div>
      )}
    </>
  )
}
