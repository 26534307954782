import { type StructValue } from 'types/Pipeline'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

export interface MultiTableValidationProps {
  param: ComponentParameter
  structValue: StructValue
}

export const useClientChildStructParameterValidation = () => {
  return ({
    param,
    structValue
  }: MultiTableValidationProps): string | undefined => {
    const lastDelimiter = param.dplID.lastIndexOf('/')
    const subParameterName = param.dplID.substring(lastDelimiter + 1)
    const displayName = param.displayName ?? subParameterName

    const childValue = structValue[param.dplID]

    if (!param.optional && !childValue) {
      return `${displayName} is required`
    }
  }
}
