import { useTranslation, type TFunction } from 'react-i18next'

import {
  type CellContext,
  type ColumnHelper,
  type DisplayColumnDef,
  type HeaderContext
} from '@tanstack/react-table'

import { LabelledCheckbox } from 'components/LabelledCheckbox/LabelledCheckbox'

const CheckboxHeader = <TCol,>(info: HeaderContext<TCol, unknown>) => {
  const { t } = info.column.columnDef.meta as {
    t: TFunction
  }
  return (
    <LabelledCheckbox
      hiddenLabel={t('common.selectAll')}
      checked={info.table.getIsAllRowsSelected()}
      onChange={info.table.getToggleAllRowsSelectedHandler()}
      disabled={false}
    />
  )
}

const CheckboxCell = <TCol,>({ row }: CellContext<TCol, unknown>) => {
  return (
    <LabelledCheckbox
      hiddenLabel={`Select to delete row ${row.index + 1}`}
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      onChange={row.getToggleSelectedHandler()}
    />
  )
}

export const checkboxColumnId = 'checkbox'

export const useCheckboxColumn = <TCol,>(
  columnHelper: ColumnHelper<TCol>
): DisplayColumnDef<TCol, unknown> => {
  const { t } = useTranslation()
  return columnHelper.display({
    id: checkboxColumnId,
    header: CheckboxHeader,
    cell: CheckboxCell,
    meta: {
      t
    }
  })
}
