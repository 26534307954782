import { type Pipeline } from 'types/Pipeline'

import { Cardinality } from 'job-lib/types/Components'

import { deleteLink } from '../deleteLink/deleteLink'
import { type UpdateLinksPayload } from './updateLinks'

export const updateSources = (
  state: Pipeline,
  {
    sourceComponent,
    targetComponent,
    sourceCardinality,
    targetCardinality
  }: UpdateLinksPayload
) => {
  const target = state.pipeline.components[targetComponent]

  if (targetCardinality === Cardinality.ONE) {
    /* When the target component can only accept one link
     * replace an existing link with the new link
     */
    target.sources = [sourceComponent]
  }

  if (sourceCardinality === Cardinality.ONE) {
    /* when the source can only have one link
     * go through all components in the pipeline
     * and remove any references to the component as a source
     */
    Object.keys(state.pipeline.components).forEach((componentName) => {
      deleteLink(state)({
        sourceName: componentName,
        targetName: sourceComponent,
        linkType: 'source'
      })
    })
  }

  if (!target.sources?.includes(sourceComponent)) {
    target.sources ??= []
    target.sources.push(sourceComponent)
  }
}
