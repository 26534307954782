import { type TFunction } from 'i18next'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

export const getParameterValue = (
  option: string,
  parameter: ComponentParameter,
  tf: TFunction
): string => {
  if (parameter.resourceID) {
    const resourceIdParts = parameter.resourceID.split('/')
    return tf(`${resourceIdParts[resourceIdParts.length - 1]}.${option}`, {
      ns: 'parametersValues',
      defaultValue: option
    })
  }
  return option
}
