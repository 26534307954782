import { JobVariableType } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  type FormState,
  type ProjectDefaultOverrides,
  type ReducerActions
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { manageVariableNameErrors } from '../errorMessages'
import { isFormValid } from './isFormValid'

export const updateVariableType = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_VARIABLE_TYPE
    value: JobVariableType | null
  }
): FormState => {
  const { value } = action

  const isNumberType = value === JobVariableType.NUMBER
  const isDefaultValueValid =
    !isNumberType ||
    state.DEFAULT_VALUE.value === '' ||
    (isNumberType && isNumeric(state.DEFAULT_VALUE.value))

  const defaultValueUpdated = {
    [Fields.DEFAULT_VALUE]: {
      ...state.DEFAULT_VALUE,
      isValid: isDefaultValueValid,
      error: isDefaultValueValid
        ? ''
        : manageVariableNameErrors[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
    }
  }

  const projectOverridesUpdated: ProjectDefaultOverrides = Object.values(
    state.PROJECT_DEFAULT_OVERRIDES.value
  ).reduce((updatedState, environment) => {
    const isValid =
      isNumberType && environment.value
        ? isNumeric(environment.value.toString())
        : true
    return {
      ...updatedState,
      [environment.environmentId]: {
        ...environment,
        isValid,
        error: isValid
          ? ''
          : manageVariableNameErrors[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
      }
    }
  }, {})

  const isProjectOverrideValid = Object.values(projectOverridesUpdated).every(
    (override) => override.isValid ?? /* istanbul ignore next */ true
  )

  const updatedState = {
    ...state,
    ...defaultValueUpdated,
    [Fields.VARIABLE_TYPE]: {
      value,
      isValid: Boolean(value),
      error: value ? '' : manageVariableNameErrors[ErrorStatuses.SELECT_OPTION]
    },
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      value: projectOverridesUpdated,
      isValid: isProjectOverrideValid,
      error: ''
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
