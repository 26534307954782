import React from 'react'
import { useTranslation } from 'react-i18next'

const pageStyle: React.CSSProperties = {
  marginTop: 30,
  marginBottom: 30,
  flexDirection: 'column'
}

const textStyle: React.CSSProperties = {
  fontSize: '1rem'
}

const ConversionReportDocs = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <div style={pageStyle}>
      <div>
        <span style={textStyle}>{t('docsList')}</span>
        <br />
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-considerations/"
          target="_blank"
          rel="noopener, noreferrer"
        >
          <span style={textStyle}>Migration considerations</span>
        </a>
        <br />
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-feature-parity/"
          target="_blank"
          rel="noopener, noreferrer"
        >
          <span style={textStyle}>Migration feature parity</span>
        </a>
        <br />
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-process/"
          target="_blank"
          rel="noopener, noreferrer"
        >
          <span style={textStyle}>Migration process</span>
        </a>
        <br />
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-process/#save-report-to-pdf"
          target="_blank"
          rel="noopener, noreferrer"
        >
          <span style={textStyle}>How to save this report as PDF</span>
        </a>
      </div>
    </div>
  )
}

export default ConversionReportDocs
