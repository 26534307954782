import type { NoteId, Pipeline } from 'types/Pipeline'

export const INITIAL_ID = 1

export const getNextNoteId = (state: Pipeline): NoteId => {
  // istanbul ignore next
  const existingIds = Object.keys(state.design.notes ?? {})
  const newId =
    existingIds.length > 0
      ? Math.max(...existingIds.map(Number)) + 1
      : INITIAL_ID
  return newId.toString()
}
