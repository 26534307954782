import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import type { CopilotChatMessage } from 'modules/core/Copilot/types'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import useGetProject from '../../useGetProject/useGetProject'
import type { ComponentWithMetadata } from '../model'
import { useChatWithSse } from '../useChatWithSse/useChatWithSse'
import { useUpdateComponents } from '../useUpdateComponents/useUpdateComponents'

interface UseSendAgenticCopilotChatMessageProps {
  addInfoMessage: (message: CopilotChatMessage) => void
  setStatus: (value: string) => void
}

export const useSendAgenticCopilotChatMessage = ({
  addInfoMessage,
  setStatus
}: UseSendAgenticCopilotChatMessageProps) => {
  const { job, jobType } = useWorkingCopy()
  const { data } = useGetProject()
  const { jobSummaryId: jobId, projectId, branchId } = useProjectInfo()
  const { updateComponents } = useUpdateComponents()
  const { performChat, isLoading: isLoadingSseChat } = useChatWithSse(
    setStatus,
    addInfoMessage,
    updateComponents
  )

  const sendMessageV2 = async (
    query: CopilotChatMessage,
    requestId: string,
    selectedComponents?: ComponentWithMetadata[]
  ): Promise<void> => {
    const warehouse = data?.warehouse.toUpperCase() || 'UNKNOWN'

    const pipeline = {
      projectId,
      branchId,
      jobId,
      type: jobType,
      source: JSON.stringify(job),
      selectedComponentNames: selectedComponents?.map(
        (component) => component.componentName
      ),
      selectedComponents
    }
    addInfoMessage(query)
    await performChat({
      requestId,
      pipeline,
      warehouse,
      message: query.content
    })
  }

  return { sendMessageV2, isLoadingSseChat }
}
