import { useEffect, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Typography,
  type ButtonProps
} from '@matillion/component-library'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { Loading } from 'components/Loading/Loading'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { useParameterOptionsAutofill } from 'hooks/useParameterOptions/useParameterOptionsAutofill'

import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { type ParameterOptions } from 'modules/ParameterEditors/types'

import { Alerts } from './components/Alerts'
import { SchemaTree } from './components/SchemaTree'
import classes from './StructuredNestedDataPickerEditor.module.scss'
import {
  autoFillResponseToTreeNode,
  elementCollectionToSelectedPaths,
  selectAllElements,
  selectElement,
  selectElements,
  selectNoElements,
  selectOnly,
  toOnlySelectedElementsTree,
  transformToElementCollection,
  type TreeNode
} from './utils'

export interface NestedDataPickerEditorProps extends ParameterOptions {
  parameterName: string
  metadata: ComponentMetadata
  componentId: string
  parameter: ComponentParameter
  elements: ElementCollection
  onDone: (editedValue: ElementCollection) => void
}

/**
 * The {@link StructuredNestedDataPickerEditor} supports Databricks (and will subsequently
 * support Bigquery), the editor allows the user to select nodes in a tree representing
 * the nested data present in a result set, it differs from the
 * {@link StructuredNestedDataPickerEditor} in the following ways
 * * The user does not define the fields, they are calculated from metadata
 * * The Autofill is invoked when opening the editor
 * * The payloads differ
 */
export const StructuredNestedDataPickerEditor: FC<
  NestedDataPickerEditorProps
> = ({ parameterName, metadata, componentId, parameter, elements, onDone }) => {
  const { t } = useTranslation()
  const [treeState, setTreeState] = useState<TreeNode[]>([])
  const autoFill = useParameterOptionsAutofill({
    componentMetaData: metadata,
    componentSummaryId: componentId,
    parameter,
    isEnabled: false,
    requestType: 'autofill-nested-read-only'
  })

  const { setValidationEnabled, isUnvalidated } =
    useComponentValidationProvider()

  const selectedElements = useMemo(
    () => elementCollectionToSelectedPaths(elements),
    [elements]
  )
  const {
    isFetched: autofillIsFetched,
    refetch: autofillRefetch,
    data: autoFillData
  } = autoFill

  useEffect(() => {
    if (!isUnvalidated) {
      const setStateFormAutoFill = async () => {
        const data = autofillIsFetched
          ? autoFillData
          : (await autofillRefetch()).data
        let tree = autoFillResponseToTreeNode(data ?? [])
        tree = selectElements(tree, selectedElements, true)
        setTreeState(tree)
      }
      setStateFormAutoFill()
    }
  }, [
    autoFillData,
    autofillRefetch,
    autofillIsFetched,
    selectedElements,
    isUnvalidated
  ])

  const updateState = (subject: TreeNode, selected: boolean) => {
    const newState = selectElement(treeState, subject.path, selected)
    setTreeState(newState)
  }

  const save = () => {
    const selectedTree = toOnlySelectedElementsTree(treeState)
    const elementCollection = transformToElementCollection(selectedTree)
    onDone(elementCollection)
  }

  const reset = () => {
    setTreeState(selectOnly(treeState, selectedElements))
  }

  const selectAll = () => {
    setTreeState(selectAllElements(treeState))
  }

  const selectNone = () => {
    setTreeState(selectNoElements(treeState))
  }

  const treeReady = !isUnvalidated && !autoFill.isLoading && !autoFill.error

  const shouldShowLoading = !isUnvalidated && autoFill.isLoading

  const btn: ButtonProps = {
    size: 'sm',
    alt: 'secondary',
    disabled: !treeReady
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <ParameterOverlayContent>
        <Alerts
          autofillError={autoFill.error}
          isUnvalidated={isUnvalidated}
          setValidationEnabled={setValidationEnabled}
        />
        <div className={classes.Box}>
          <div className={classes.Box__Title}>
            <Typography weight="bold">
              {t('parameterEditor.NESTED_DATA_PICKER_EDITOR.tagline')}
            </Typography>
          </div>
          <div className={classes.Box__Content}>
            {shouldShowLoading && <Loading />}
            {treeReady && (
              <SchemaTree nodes={treeState} onSelectionChange={updateState} />
            )}
          </div>
          <div className={classes.Box__Actions}>
            <Button onClick={reset} text={t('common.reset')} {...btn} />
            <Button onClick={selectAll} text={t('common.selectAll')} {...btn} />
            <Button onClick={selectNone} text={t('common.clearAll')} {...btn} />
          </div>
        </div>
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          text={t('common.save')}
          onClick={save}
          disabled={!treeReady}
        />
      </ParameterOverlayFooter>
    </>
  )
}
