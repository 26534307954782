import { type FC, type PropsWithChildren } from 'react'

import classNames from 'classnames'

import classes from './Taskbar.module.scss'

interface TaskbarProps {
  collapsed?: boolean
}

export const Taskbar: FC<PropsWithChildren<TaskbarProps>> = ({
  children,
  collapsed
}) => {
  return (
    <div
      className={classNames(classes.Taskbar, {
        [classes['Taskbar--Collapsed']]: collapsed
      })}
      data-testid="taskbar-group"
    >
      {children}
    </div>
  )
}
