import { type ColumnMode } from '../types'

export const translateValueFromTextMode = (
  columnMode: ColumnMode,
  value: string
): string => {
  let translatedValue = value
  if (columnMode.type === 'dropdown') {
    const item = columnMode.options.find((opt) => opt.name === value)
    if (!item) {
      translatedValue = ''
    } else {
      translatedValue = item.id
    }
  }

  return translatedValue
}

export const translateValueToTextMode = (
  columnMode: ColumnMode,
  value: string
): string => {
  let translatedValue = value
  if (columnMode.type === 'dropdown') {
    const item = columnMode.options.find((opt) => opt.id === value)
    if (item) {
      translatedValue = item.name
    }
  }

  return translatedValue
}
