import { forwardRef, type HTMLProps, type Ref } from 'react'

import classNames from 'classnames'

import classes from './PipelineTable.module.scss'

export interface Props extends HTMLProps<HTMLTableSectionElement> {
  ref?: Ref<HTMLTableSectionElement>
  sticky?: boolean
}

export const PipelineTableHeader = forwardRef<HTMLTableSectionElement, Props>(
  ({ className, sticky, ...props }, ref) => {
    return (
      <thead
        {...props}
        ref={ref}
        className={classNames(
          classes.PipelineTableHeader,
          sticky && classes['PipelineTableHeader--sticky'],
          className
        )}
      />
    )
  }
)

PipelineTableHeader.displayName = 'PipelineTableHeader'
