import { Icon } from '@matillion/component-library'

import { ProjectType } from 'api/hooks/useGetProject/types'

export const getProjectIcon = (type?: ProjectType) => {
  if (type === ProjectType.SNOWFLAKE) {
    return Icon.Snowflake
  } else if (type === ProjectType.REDSHIFT) {
    return Icon.Redshift
  } else if (type === ProjectType.DATABRICKS) {
    return Icon.Databricks
  }

  return Icon.WarningNoBG
}
