import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import { useFlags } from 'hooks/useFlags'

import classes from '../ConversionToolModal.module.scss'
import { getConversionError } from './errors'

export interface ConversionErrorProps {
  error: unknown
  showMappings: () => void
}

const ConversionError = ({ error, showMappings }: ConversionErrorProps) => {
  const { enableMigrationToolMappings } = useFlags()
  const { t } = useTranslation('translation', { keyPrefix: 'conversion.modal' })

  const err = useMemo(() => getConversionError(error), [error])

  return (
    <Alert
      className={classes.Error}
      action={
        enableMigrationToolMappings && err?.showEditMappings
          ? {
              text: t('editMappings'),
              onClick: () => {
                showMappings()
              }
            }
          : undefined
      }
      type="error"
      title={t('error.api.title')}
      message={err?.error || t('error.api.message')}
      data-testid="conversion-tool-api-error-alert"
    />
  )
}

export default ConversionError
