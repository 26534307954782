import {
  type FloatingPopoverPosition,
  type FloatingPopoverSize
} from '../types'
import { DragManager } from './DragManager'
import { ResizeManager } from './ResizeManager'

export interface DraggableAndResizableProps {
  updatePosition: (position: FloatingPopoverPosition) => void
  updateSize: (size: FloatingPopoverSize) => void
  containerRef: React.RefObject<HTMLElement>
  dragBoundaryRef?: React.RefObject<HTMLElement>
  minWidth: number
  minHeight: number
  maxWidth: number
  maxHeight: number
}

export class DraggableAndResizable {
  private readonly dragManager: DragManager
  private readonly resizeManager: ResizeManager

  constructor({
    updatePosition,
    updateSize,
    containerRef,
    dragBoundaryRef,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight
  }: DraggableAndResizableProps) {
    this.dragManager = new DragManager({
      updatePosition,
      containerRef,
      dragBoundaryRef
    })
    this.resizeManager = new ResizeManager({
      updateSize,
      updatePosition,
      containerRef,
      dragBoundaryRef,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight
    })
  }

  startDrag(e: React.MouseEvent<HTMLElement>) {
    this.dragManager.startDrag(e)
  }

  startResize(e: React.MouseEvent<HTMLElement>, direction: string) {
    this.resizeManager.startResize(e, direction)
  }
}
