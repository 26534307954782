import { useTranslation } from 'react-i18next'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import { getSelectedComponentSkippedStatus } from 'file-editors/canvas/util/componentSelection'

import { ReactComponent as SkipRunIcon } from 'assets/skip-run.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useSkipComponent } from 'job-lib/hooks/useSkipComponent/useSkipComponent'

import classes from '../ComponentNodeToolbar.module.scss'

interface SkipRunButtonProps {
  componentInstanceId: number
}

const SkipRunButton: React.FC<SkipRunButtonProps> = ({
  componentInstanceId
}) => {
  const { t } = useTranslation()
  const reactFlow = useEtlFlow()
  const skipped = getSelectedComponentSkippedStatus(reactFlow)

  const { skipComponent } = useSkipComponent()
  return (
    <IconButton
      className={`${classes.Toolbar__Button} ${
        skipped ? classes['Toolbar__Button--skipped'] : ''
      }`}
      label={t('componentToolbox.skipComponent')}
      onClick={() => {
        skipComponent(componentInstanceId, !skipped)
      }}
    >
      <SkipRunIcon />
    </IconButton>
  )
}

export default SkipRunButton
