import type { ComponentName, Pipeline, Position } from 'types/Pipeline'

export interface UpdateComponentPositionPayload {
  name: ComponentName
  position: Position
}

export const updateComponentPosition =
  (state: Pipeline) =>
  ({ name, position }: UpdateComponentPositionPayload) => {
    if (!state.design.components[name]) {
      return
    }

    state.design.components[name].position = position
  }
