import { useMemo } from 'react'

import { type ParameterValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { supportedIncrementalLoadersIds } from 'job-lib/cisIds/knownComponentParameters'
import { type ComponentInstance } from 'job-lib/types/Job'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { isStructValueParameterValue } from 'modules/core/WorkingCopyProvider/utils/parameters'

export interface IncrementalLoaderParameter {
  parameter: ComponentParameter
  name: string
  module: string | null
  value?: ParameterValue
}

export type IncrementalLoaderParameters = Record<
  string,
  IncrementalLoaderParameter[] | undefined
>

/**
 * This is hook intended for use within Multi-table configuration. It provides
 * easier access to the parameters and parameter values inside the available
 * incremental loader
 */
export const useIncrementalLoaderParameters = (
  componentMetadata: ComponentMetadata,
  componentInstance: ComponentInstance
): IncrementalLoaderParameters => {
  return useMemo(() => {
    const result: IncrementalLoaderParameters = {}
    const incrementalLoaderParameter = componentMetadata.parameters.find(
      (parameter) => supportedIncrementalLoadersIds.includes(parameter.dplID)
    )

    if (!incrementalLoaderParameter?.childProperties) return result

    const parameterValues = getParameterValue(componentInstance.parameters, [
      ...supportedIncrementalLoadersIds
    ])

    if (isStructValueParameterValue(parameterValues)) {
      return incrementalLoaderParameter.childProperties.reduce<IncrementalLoaderParameters>(
        (acc, parameter) => {
          const name = parameter.dplID

          if (!Array.isArray(acc[name])) {
            acc[name] = []
          }

          acc[name]?.push({
            parameter,
            name,
            module: incrementalLoaderParameter.dplID,
            value: parameterValues[parameter.dplID]
          })

          return acc
        },
        result
      )
    }

    return result
  }, [componentInstance.parameters, componentMetadata.parameters])
}
