import { useCallback, type Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { type AnyAction } from 'redux'
import { type ParameterValue } from 'types/Pipeline'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { generateUniqueName } from 'job-lib/job-functions/generateUniqueName'
import { jobActions, type RootState } from 'job-lib/store'
import {
  type ComponentInstance,
  type TransformationComponentInstanceCollection
} from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'

import { getActualEditedValue } from '../utils/getActualEditedValue'
import { getTableInputName } from '../utils/getTableInputName'

export const useComponentParameterUpdateEffects = () => {
  const allComponents = useSelector<RootState>(
    (state) => state.job.job?.components
  )

  const dispatch = useDispatch()

  const runComponentUpdateEffectsOnBlur = useCallback(
    (
      componentInstance: ComponentInstance,
      componentMetadata: ComponentMetadata,
      componentSummaryId: ComponentSummaryId,
      { parameterPath, editedValue }: EditedParameter
    ) => {
      const actualEditedValue = getActualEditedValue(editedValue)

      if (actualEditedValue !== undefined) {
        updateComponentNameToTargetTable(
          componentSummaryId,
          parameterPath,
          actualEditedValue,
          componentMetadata,
          componentInstance,
          allComponents,
          editedValue,
          dispatch
        )
      }
    },
    [allComponents, dispatch]
  )

  return { runComponentUpdateEffectsOnBlur }
}

const updateComponentNameToTargetTable = (
  componentSummaryId: string,
  parameterPath: string[],
  actualEditedValue: string,
  componentMetadata: ComponentMetadata,
  componentInstance: ComponentInstance,
  allComponents: unknown,
  editedValue: ElementCollection | ParameterValue,
  dispatch: Dispatch<AnyAction>
) => {
  // For 'Table Input', 'Table Update', 'Table Output', etc components, update their component name to table name
  const componentToRename = ['table-input', 'table-update', 'table-output']

  if (
    componentToRename.includes(componentSummaryId) &&
    parameterPath[0] === 'targetTable' &&
    actualEditedValue?.trim() &&
    !actualEditedValue?.includes('$')
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const componentNameParameter = componentMetadata.parameters.find(
      (x) => x.dplID === 'componentName'
    )!
    const previousValue =
      componentInstance.parameters[1]?.elements[1]?.values[1]?.value

    const originalName = componentSummaryId.replace('-', ' ')
    const matcher = new RegExp(originalName, 'i')
    if (matcher.test(previousValue)) {
      const uniqueName = generateUniqueName(
        actualEditedValue,
        allComponents as TransformationComponentInstanceCollection
      )
      const tableInputNameValue = getTableInputName(uniqueName, editedValue)

      if (tableInputNameValue !== undefined) {
        dispatch(
          jobActions.overrideComponentParameter({
            componentMetadata,
            parameterSlot: componentNameParameter.metlSlot,
            parameterPath: [componentNameParameter.dplID],
            componentInstanceId: componentInstance.id,
            value: tableInputNameValue
          })
        )
      }
    }
  }
}
