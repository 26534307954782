import { forwardRef } from 'react'

import Editor, {
  loader,
  type OnChange,
  type OnMount
} from '@monaco-editor/react'
import classnames from 'classnames'
import * as monaco from 'monaco-editor'
import { type editor } from 'monaco-editor'

import { type DisplayType } from 'components/CodeEditor/types'

import classes from './CodeEditor.module.scss'

loader.config({ monaco })

export const monacoCustomTheme: monaco.editor.IStandaloneThemeData = {
  base: 'hc-light',
  inherit: true,
  rules: [
    { token: 'comment', foreground: '2d8825' },
    { token: 'keyword', foreground: 'A030AA' },
    { token: 'string', foreground: 'CA1C2F' },
    { token: 'string.sql', foreground: 'CA1C2F' },
    { token: 'number', foreground: '2DA3BA' },
    { token: 'type', foreground: 'CA1C2F' },
    { token: 'class', foreground: '222222' },
    { token: 'function', foreground: '222222' },
    { token: 'function.declaration', foreground: '222222' },
    { token: 'variable', foreground: 'CA1C2F' },
    { token: 'property', foreground: 'CA1C2F' },
    { token: 'operator', foreground: '777777' },
    { token: 'operator.sql', foreground: '777777' },
    { token: 'identifier', foreground: '222222' },
    { token: 'identifier.sql', foreground: '222222' },
    { token: 'punctuation', foreground: '222222' }
  ],
  colors: {
    'editor.foreground': '#222222',
    'editor.background': '#ffffff',
    'editorCursor.foreground': '#222222',
    'editor.lineHighlightBorder': '#FFFFFF00',
    'editorLineNumber.foreground': '#777777',
    'editorLineNumber.activeForeground': '#222222',
    'editor.LineNumber.background': 'f8f8f8f8',
    'editor.selectionBackground': '#dfedd3',
    'editor.selectionForeground': '#222222',
    'scrollbarSlider.background': '#f8f8f8',
    'scrollbarSlider.hoverBackground': '#f8f8f8',
    'scrollbarSlider.activeBackground': '#f8f8f8'
  }
}

interface MonacoEditorProps {
  autoFocus?: boolean
  className?: string
  readOnly?: boolean
  onChange?: (newValue: string) => unknown
  value?: string
  language?: string
  displayType: DisplayType
  options?: editor.IStandaloneEditorConstructionOptions
  defaultValue?: string
}

export const MonacoEditor = forwardRef<
  monaco.editor.IStandaloneCodeEditor,
  MonacoEditorProps
>(
  (
    {
      autoFocus,
      readOnly,
      value,
      onChange,
      className,
      language,
      displayType,
      options,
      defaultValue
    },
    ref
  ) => {
    const handleEditorDidMount: OnMount = (monacoEditor, monacoInstance) => {
      /* istanbul ignore next */
      if (typeof ref === 'function') {
        ref(monacoEditor)
      } else if (ref) {
        ref.current = monacoEditor
      }

      monacoInstance.editor.defineTheme('customTheme', monacoCustomTheme)
      monacoInstance.editor.setTheme('customTheme')

      /* istanbul ignore next */
      if (autoFocus) {
        monacoEditor.focus()
      }
    }

    return (
      <Editor
        language={language}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange as OnChange}
        onMount={handleEditorDidMount}
        wrapperProps={{
          'data-testid': 'code-editor'
        }}
        className={classnames(
          classes.CodeEditor,
          classes[`CodeEditor--${displayType}`],
          {
            [classes['CodeEditor--Disabled']]: !value && readOnly
          }
        )}
        options={{
          ...options,
          bracketPairColorization: { enabled: true },
          lineDecorationsWidth: 0,
          minimap: { enabled: false },
          wordWrap: 'on',
          readOnly,
          scrollBeyondLastLine: false,
          padding: { top: 10 },
          scrollbar: {
            horizontal: 'auto',
            useShadows: false,
            verticalScrollbarSize: 0,
            verticalSliderSize: 10
          }
        }}
      />
    )
  }
)

MonacoEditor.displayName = 'MonacoEditor'
