import {
  ParameterDataType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { tableConfig } from 'job-lib/cisIds/knownComponentParameters'

export const isChildStructListParameter = (
  parameter: ComponentParameter | undefined
) => {
  return (
    !!parameter &&
    parameter.dataType === ParameterDataType.STRUCT_LIST &&
    !!parameter.childProperties &&
    parameter.dplID === tableConfig
  )
}
