import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { ChevronButton } from 'components/ChevronButton'
import { PopOverMenu } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'

import classes from '../../FileBrowser.module.scss'
import FilePanelContextMenu from './FilePanelContextMenu'

const FileBrowserToolBar: FC = () => {
  const { t } = useTranslation()
  const { enableHighCode } = useFlags()

  return (
    <div className={classes.PipelineBrowser__CTAContainer}>
      <Typography as="span" weight="bold">
        {t(
          `translation:sideBar.jobPanel.${
            enableHighCode ? 'title-high-code' : 'title'
          }`
        )}
      </Typography>
      <PopOverMenu
        className={classes.PipelineBrowser__CTAPopoverMenu}
        position="bottom"
        content={<FilePanelContextMenu />}
      >
        {({ onClick, open }) => (
          <ChevronButton
            direction={open ? 'up' : 'down'}
            data-testid={'jobs-browser-actions'}
            onClick={onClick}
            size="sm"
            alt="secondary"
            aria-label={t('translation:sideBar.jobPanel.panelActionMenu')}
            className={classes.PipelineBrowser__AddButton}
          >
            <Typography as="span" format="mc" weight="bold">
              {t('translation:sideBar.jobPanel.addBtnLabel')}{' '}
            </Typography>
          </ChevronButton>
        )}
      </PopOverMenu>
    </div>
  )
}

export default FileBrowserToolBar
