import {
  FileOperation,
  FileStatus,
  useRenameFile as useRenameFolderEffect,
  type FileInfo,
  type RenameFileProps
} from '@matillion/git-component-library'

import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { useFileBrowserState } from 'modules/core/EtlDesigner/hooks/useFileBrowserState/useFileBrowserState'

import { useFlags } from '../useFlags'

export const useRenameFolder = () => {
  const { renameSharedPipelineFolders } = useSharePipeline()
  const { updateFileTreeState } = useFileBrowserState()

  const { enableSharedPipelines } = useFlags()

  const { renameFile: renameFolderEffect, ...effectHook } =
    useRenameFolderEffect({
      meta: {
        disableErrorToasts: true
      }
    })

  const renameFolder = async ({
    currentFilePath,
    newFilePath
  }: RenameFileProps) => {
    await renameFolderEffect(
      { currentFilePath, newFilePath, operation: FileOperation.MOVE },
      {
        onSuccess: ({ data }) => {
          updateFileTreeState(currentFilePath, newFilePath)

          /* istanbul ignore else */
          if (enableSharedPipelines) {
            /**
             * The working tree store gives us back a list of files that have either been deleted or removed from the file store.
             * If the file had a new status and was changed it will be in the list of files with a DELETED status.
             * Otherwise the file will be in the removedFromWorkingTree list.
             * Combining these two lists gives us a list of files that have been removed from the working tree.
             */
            const deletedFiles = data.files.filter(
              (file: FileInfo) => file.status === FileStatus.DELETED
            )
            const updatedPipelines = deletedFiles
              .concat(data.removedFromWorkingTree)
              .map((file: FileInfo) => file.name)

            renameSharedPipelineFolders(
              updatedPipelines,
              currentFilePath,
              newFilePath
            )
          }
        }
      }
    )
  }

  return {
    renameFolder,
    ...effectHook
  }
}
