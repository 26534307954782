import { useMemo } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import type { StepsResponse } from '../useEosApi/types/eos'
import { useEosApi } from '../useEosApi/useEosApi'
import { collateSteps } from './utils/collateSteps'

const getNextPage = (lastPage: StepsResponse) => lastPage

export const useGetStepsInfinite = (
  pipelineId: string,
  refetchInterval?: number,
  refetchOnMount?: boolean | 'always'
) => {
  const { getSteps } = useEosApi()
  const { data, isFetching, fetchNextPage, isFetchingNextPage, isError } =
    useInfiniteQuery(
      ['steps', pipelineId],
      async ({ pageParam }) => {
        const prevPage = pageParam

        const offset =
          typeof prevPage?.offset === 'number'
            ? prevPage.offset + prevPage.limit
            : 0
        const limit = prevPage?.limit ?? undefined

        return (await getSteps(pipelineId, offset, limit)).data
      },
      {
        getNextPageParam: getNextPage,
        refetchInterval,
        refetchOnMount
      }
    )

  const collatedSteps = useMemo(() => {
    const allSteps = data?.pages.flatMap((page) => page?.results)

    return allSteps ? collateSteps(allSteps) : undefined
  }, [data?.pages])

  const hasMore = Boolean(data?.pages[data.pages.length - 1]?._links.next)

  const fetchNext = () => {
    if (hasMore && !isFetching) {
      void fetchNextPage()
    }
  }

  return {
    steps: collatedSteps,
    isFetching,
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    hasMore,
    isError,
    fetchNextPage: fetchNext
  }
}
