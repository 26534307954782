import { Alert } from '@matillion/component-library'

import { useParameterOverlayErrors } from '../hooks/useParameterOverlayErrors'
import classes from '../ParameterOverlay.module.scss'

export const Errors = () => {
  const { errors } = useParameterOverlayErrors()

  return (
    <div aria-live="polite">
      {errors.map((error) => (
        <Alert
          key={error.id}
          className={classes.Error}
          message={error.message}
          type={error.type}
        />
      ))}
    </div>
  )
}
