import { useTranslation } from 'react-i18next'

import { ReactComponent as AiCopilotExampleImg } from 'assets/ai-copilot-example.svg'

import UpgradeModal from './UpgradeModal'

export interface CopilotUpgradeModalProps {
  onClose: () => void
}

const CopilotUpgradeModal = ({ onClose }: CopilotUpgradeModalProps) => {
  const { t } = useTranslation()
  const bodyContents = [
    { key: 0, content: t('upgradeModal.copilot.description') },
    { key: 1, content: t('upgradeModal.copilot.cta') }
  ]

  return (
    <UpgradeModal
      onClose={onClose}
      title={t('upgradeModal.copilot.title')}
      subtitle={t('upgradeModal.copilot.subtitle')}
      bodyContents={bodyContents}
      image={<AiCopilotExampleImg data-testid={'ai-copiot-upgrade-img'} />}
    />
  )
}

export default CopilotUpgradeModal
