import { type FC, type HTMLProps } from 'react'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import * as heap from 'utils/heap'
import { isMacOs } from 'utils/isMacOs'

import { useWorkingCopy } from './hooks/useWorkingCopy'

export const UndoRedoCapture: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const { undoManager } = useWorkingCopy()
  const { resetValidation } = useComponentValidationProvider()

  return (
    <div
      {...props}
      tabIndex={-1}
      onKeyDown={(evt) => {
        const isModified = isMacOs() ? evt.metaKey : evt.ctrlKey

        if (isModified) {
          if (evt.key === 'y') {
            evt.preventDefault()
            heap.trackKeyboardShortcut('canvas', 'redo')
            undoManager?.redo()
            resetValidation()
          }

          if (evt.key === 'z') {
            evt.preventDefault()

            if (evt.shiftKey) {
              heap.trackKeyboardShortcut('canvas', 'redo')
              undoManager?.redo()
            } else {
              heap.trackKeyboardShortcut('canvas', 'undo')
              undoManager?.undo()
            }

            resetValidation()
          }
        }
      }}
    >
      {children}
    </div>
  )
}
