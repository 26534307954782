import { useEffect } from 'react'
import { Controller, useForm, type FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, Field, Input, Typography } from '@matillion/component-library'
import {
  getFileTypeTranslationKeySuffix,
  type FileType
} from '@matillion/git-component-library'
import classnames from 'classnames'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { usePipelines } from 'hooks/usePipelines/usePipelines'

import {
  isFileExistingFile,
  VALID_NAME_REGEX
} from '../../utils/validateFileName'
import classes from '../Modal.module.scss'

interface CreateFileBaseModalProps {
  onSubmit: (data: FieldValues) => void
  onClose: () => void
  type: FileType
  folderPath: string | null
  isLoading: boolean
}

export const CreateFileBaseModal = ({
  onSubmit,
  onClose,
  type,
  folderPath,
  isLoading
}: CreateFileBaseModalProps) => {
  const { t } = useTranslation()
  const { files: existingPipelines, isLoading: isLoadingExistingPipelines } =
    usePipelines()

  const fileType = getFileTypeTranslationKeySuffix(type)

  const {
    handleSubmit,
    setFocus,
    control,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setFocus('jobName')
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [setFocus])

  return (
    <DesignerModal
      id="newJob-dialog"
      onCancel={onClose}
      ariaLabelledBy="newJob-title"
      size="mid"
      disableBackdropClick
      className={classes.CreateJobModal}
      setFocus={false}
    >
      <div className={classes.Modal__Heading}>
        <Typography as="h2" format="tl" id="newJob-title">
          {t('translation:createPipeline.title', { context: type })}
        </Typography>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
        }}
      >
        <div
          className={classnames(
            classes.Modal__Content,
            classes['Modal__Content--Input']
          )}
        >
          <Controller
            name="jobName"
            defaultValue=""
            control={control}
            shouldUnregister={true}
            rules={{
              required: t('translation:managePipeline.error.noEmptyString'),
              pattern: {
                value: VALID_NAME_REGEX,
                message: t('translation:managePipeline.error.noRegex')
              },
              validate: async (name: string) => {
                const path = folderPath ? `${folderPath}/` : ''
                const isDuplicatePipeline = isFileExistingFile(
                  `${path}${name}`,
                  type,
                  existingPipelines
                )

                if (isDuplicatePipeline) {
                  return t(
                    `translation:managePipeline.error.noDuplicates.${fileType}`
                  )
                }
              }
            }}
            render={({
              field: { value, onChange, name, ref },
              fieldState: { error }
            }) => {
              return (
                <Field
                  id={name}
                  data-testid="modal-newJob-name-input"
                  title={t(`translation:createPipeline.name.${fileType}`)}
                  aria-label={t(`translation:createPipeline.name.${fileType}`)}
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    `translation:createPipeline.namePlaceholder.${fileType}`
                  )}
                  hasError={!!error}
                  error={!!error}
                  errorText={error?.message}
                  disabled={isLoading || isLoadingExistingPipelines}
                  inputComponent={Input}
                  inputRef={ref}
                />
              )
            }}
          />
        </div>
        <div className={classes.Modal__Actions}>
          <Button
            id="newJob-cancel"
            data-testid="modal-create-cancel"
            text={t('translation:managePipeline.cancel')}
            alt="secondary"
            onClick={onClose}
          />
          <Button
            id="newJob-create"
            data-testid="modal-create-submit"
            data-tracker-id="add-pipeline-btn"
            text={t('translation:createPipeline.create')}
            waiting={isLoading}
            disabled={
              isLoading || isLoadingExistingPipelines || !isDirty || !isValid
            }
            type="submit"
            alt="primary"
          />
        </div>
      </form>
    </DesignerModal>
  )
}
