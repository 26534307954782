import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { FileType, useCreateFile } from '@matillion/git-component-library'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

interface CreateNonPipelineFileProps {
  type: FileType
  onComplete: () => void
}

export const useCreateNonPipelineFile = ({
  type,
  onComplete
}: CreateNonPipelineFileProps) => {
  const { t } = useTranslation()
  const { createFile, isLoading } = useCreateFile()
  const { makeToast } = Toaster.useToaster()
  const { navigateToJob } = useSelectedJobs()

  const createNonPipelineFile = useCallback(
    async (name: string) => {
      if (isLoading) return

      const extension = type === FileType.PYTHON ? 'py' : 'sql'
      const fileName = `${name}.${extension}`

      try {
        await createFile({ path: fileName, contents: '' })
        makeToast({
          title: t('translation:createPipeline.success.file'),
          type: 'success',
          message: ''
        })

        navigateToJob(fileName)
      } catch (e) {
        console.error(e)
      }

      onComplete()
    },
    [createFile, isLoading, makeToast, navigateToJob, onComplete, type, t]
  )

  return {
    isLoading,
    createNonPipelineFile
  }
}
