import { createContext } from 'react'

/**
 * This type exists because of a mismatch between the react onContextMenu event and the reactflow one.
 */
export interface OnContextMenuEvent {
  stopPropagation: () => void
  preventDefault: () => void
  clientX: number
  clientY: number
  pageX: number
  pageY: number
}
interface ContextInterface {
  setIsOpen: (isOpen: boolean) => void
  onContextMenu: (e: OnContextMenuEvent, data: unknown) => void
}

export const PopOverContext = createContext<ContextInterface | undefined>(
  undefined
)
