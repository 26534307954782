import { type ChangeEvent, type FC } from 'react'

import { Checkbox, Field } from '@matillion/component-library'

interface CheckboxProps {
  id: string
  column: string
  checked: boolean
  disabled: boolean
  onChange: (column: string, checked: boolean) => void
}

export const MultiTableCheckbox: FC<CheckboxProps> = ({
  id,
  column,
  checked,
  disabled,
  onChange
}) => {
  return (
    <Field
      inputComponent={Checkbox}
      data-testid={`checkbox-${id}-${column}`}
      checked={checked}
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(id, e.target.checked)
      }}
    />
  )
}
