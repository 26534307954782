import { useAuth, useUser } from '@matillion/hub-client'
import { type AxiosInstance } from 'axios'

import config from 'config'

const useAuthInterceptor = (api: AxiosInstance) => {
  const { getToken } = useAuth()
  const { organisation } = useUser()

  api.interceptors.request.use(async (request) => {
    const token = await getToken({
      audience: config.apiTokenAudience
    })

    request.headers.set('Authorization', `Bearer ${token}`)
    request.headers.set('account-id', organisation.id)

    return request
  })
}

export default useAuthInterceptor
