import { type ConnectorComponentMetadataResponse } from 'api/hooks/useGetComponentMetadata/types'

import { type Parameters } from 'job-lib/types/Job'

import { getCustomConnectorProfile } from './getCustomConnectorProfile'
import { parseLookupDependencyKey } from './parseLookupDependencyKey'

export const getCustomConnectorDependencies = (
  cCLookupDepProfiles: string[] | undefined,
  parameters: Parameters | undefined,
  getConnectorMetadata: (
    profile: string
  ) => ConnectorComponentMetadataResponse | undefined
): Record<string, ConnectorComponentMetadataResponse | undefined> => {
  return (
    (cCLookupDepProfiles &&
      parameters &&
      cCLookupDepProfiles.reduce((ccPreviousParam, ccParam: string) => {
        const parsedDependencyKey = parseLookupDependencyKey(
          'cc_profile',
          ccParam
        )
        if (!parsedDependencyKey) {
          return ccPreviousParam
        }
        const { alias, parameterPath } = parsedDependencyKey
        return {
          ...ccPreviousParam,
          [alias]: getCustomConnectorProfile(
            parameterPath,
            parameters,
            getConnectorMetadata
          )
        }
      }, {})) ||
    {}
  )
}
