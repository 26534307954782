import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Key
} from 'react'
import { type ImperativePanelHandle } from 'react-resizable-panels'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useLayoutProvider } from '../Layouts/LayoutProvider'

const DEFAULT_HEIGHT_PX = 250
const TASKBAR_HEIGHT_PX = 30
export const MAX_PANEL_HEIGHT_PCT = 85
export const MIN_PANEL_HEIGHT_PCT = 10

const useHandleBottomPanel = () => {
  const { activeTabKey, setActiveTabKey } = useLayoutProvider()
  const { componentId } = useProjectInfo()
  const lastPanelSize = useRef(0)
  const bottomPanelRef = useRef<ImperativePanelHandle>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [containerHeight, setContainerHeight] = useState(0)

  useEffect(() => {
    const height =
      containerRef.current?.getBoundingClientRect().height ?? window.innerHeight
    setContainerHeight(height - TASKBAR_HEIGHT_PX)
  }, [])

  const defaultSize = useMemo(() => {
    if (containerHeight > 0) {
      return Math.min(
        (DEFAULT_HEIGHT_PX / containerHeight) * 100,
        MAX_PANEL_HEIGHT_PCT
      )
    }
    return MIN_PANEL_HEIGHT_PCT
  }, [containerHeight])

  useEffect(() => {
    if (isCollapsed) {
      bottomPanelRef.current?.resize(0)
    } else {
      const newPanelSize = Math.max(lastPanelSize.current, defaultSize)
      bottomPanelRef.current?.resize(newPanelSize)
      lastPanelSize.current = newPanelSize
    }
  }, [defaultSize, isCollapsed])

  useEffect(() => {
    if (activeTabKey) {
      setIsCollapsed(false)
    }
  }, [activeTabKey, componentId])

  const handleSelectionChange = useCallback(
    (key: Key | null) => {
      setActiveTabKey(key)

      if (isCollapsed) {
        setIsCollapsed(false)
        return
      }

      if (!isCollapsed && key === null) {
        setIsCollapsed(true)
      }
    },
    [isCollapsed, setActiveTabKey]
  )

  const handleResize = (size: number) => {
    if (size >= defaultSize) {
      lastPanelSize.current = size
    }
  }

  return {
    bottomPanelRef,
    containerRef,
    activeTabKey,
    isCollapsed,
    defaultSize,
    handleSelectionChange,
    handleResize
  }
}

export default useHandleBottomPanel
