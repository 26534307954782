import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type ProjectType } from './types'

export const useListProjects = () => {
  const { projectId } = useProjectInfo()
  const { getProjects } = useDesignerApi()

  return useQuery([projectId, queryKeys.listProjects], async () => {
    const { data } = await getProjects()

    return {
      results: data.results.map((project) => ({
        ...project,
        /*
          the PE API currently returns mixed case (Snowflake vs snowflake) for this key;
          eventually it will be migrated to all lowercase and this can be removed
        */
        warehouse: project.warehouse.toLowerCase() as ProjectType
      }))
    }
  })
}
