import { type FC } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './TaskbarTabs.module.scss'
import { type TaskbarTabsListProps } from './types'

export const TaskbarTabsList: FC<TaskbarTabsListProps> = ({
  children,
  activeTabId,
  onChange
}: TaskbarTabsListProps) => {
  return (
    <div className={classes.TaskbarTabsList}>
      {children.map((tab) => {
        const active = activeTabId === tab.key

        return (
          <button
            key={tab.key}
            type="button"
            data-testid={tab.props['data-testid']}
            className={classNames(classes.TaskbarTabsList__Button, {
              [classes['TaskbarTabsList__Button--Active']]: active
            })}
            onClick={() => {
              onChange(active ? null : tab.key)
            }}
          >
            <Typography
              className={classes.TaskbarTabsList__Button__Text}
              format="bcs"
            >
              {tab.props.title}
            </Typography>
            <Icon.ChevronDown
              className={classNames(classes.TaskbarTabsList__Button__Chevron, {
                [classes['TaskbarTabsList__Button__Chevron--Active']]: active
              })}
            />
          </button>
        )
      })}
    </div>
  )
}
