import { createContext } from 'react'

import { noop } from 'lodash'

import { getDefaultSampleLimitItem } from 'modules/ComponentProperties/components/SampleComponent/utils/utils'

import {
  type UserPreference,
  type UserPreferenceProviderContextInterface
} from './types'

export const userPreferenceInitialValue: UserPreference = {
  snapToGridEnabled: true,
  sampleLimit: getDefaultSampleLimitItem(),
  filesPopoverPosition: null,
  filesPopoverSize: null
}

export const UserPreferenceProviderContext =
  createContext<UserPreferenceProviderContextInterface>({
    userPreference: userPreferenceInitialValue,
    setSpecificUserPreference: noop
  })
