import { type AxiosInstance } from 'axios'

export interface ImportPipelinesApi {
  client: AxiosInstance
  projectId: string
  branchName: string
  path: string
  file: File
}

export enum ImportFailureType {
  FILE_ALREADY_EXISTS = 'FILE_ALREADY_EXISTS',
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  FILE_EXCLUDED = 'FILE_EXCLUDED'
}

export interface ImportSuccessDescription {
  name: string
  objectId: string
}

export interface ImportFailureDescription {
  fileName: string
  type: ImportFailureType
}

export interface ImportPipelinesResponse {
  importedFiles: ImportSuccessDescription[]
  failures: ImportFailureDescription[]
}

export const importPipelinesApi = async ({
  client,
  projectId,
  branchName,
  path,
  file
}: ImportPipelinesApi): Promise<ImportPipelinesResponse> => {
  const response = await client.post(
    `/projects/${projectId}/branches/${branchName}/import/${path}`,
    file,
    {
      headers: {
        'Content-Type': 'application/zip'
      },
      responseType: 'json'
    }
  )

  return response.data
}
