import { type FC, type HTMLProps } from 'react'

import { Icon, Typography } from '@matillion/component-library'

import classes from './RowEmpty.module.scss'

const { InfoNoBg } = Icon

interface Props extends HTMLProps<HTMLDivElement> {
  text: string
}

export const RowEmpty: FC<Props> = ({ text, ...props }) => {
  return (
    <div className={classes.RowEmpty} {...props}>
      <InfoNoBg className={classes.RowEmpty__Icon} />
      <Typography className={classes.RowEmpty__Text} format="mc">
        {text}
      </Typography>
    </div>
  )
}
