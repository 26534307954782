import { LDEvaluationReason } from 'launchdarkly-js-sdk-common'
import { heap } from '../utils/heap'
import { useLDClient as useClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'

interface Experiment {
  /**
   * A unique identifier for the experiment, e.g. 'EXP1234'.
   */
  experimentId: string
  /**
   * The key of the flag that the experiment is associated with, e.g. 'enable-my-flag'.
   */
  flagKey: string
  /**
   * A short label for the variation, e.g. 'Control' or 'Variation'.
   */
  variationName: string
}

export interface EvaluationReason extends LDEvaluationReason {
  inExperiment?: boolean
}

export const useSendLDExperimentAnalytics = (experiments: Experiment[]) => {
  const LDClient = useClient()

  const [heapHasRun, setHeapHasRun] = useState(false)

  let experimentPayload = {}

  experiments.forEach((experiment) => {
    const { experimentId, flagKey, variationName } = experiment

    const flagVariationDetail = LDClient?.variationDetail(flagKey)
    const isExperimentEnabled =
      (flagVariationDetail?.reason as EvaluationReason)?.inExperiment ?? false
    const flagVariation = flagVariationDetail?.variationIndex
    const flagValue = flagVariationDetail?.value

    const experimentProperties = {
      [`${experimentId} Variation`]: `${variationName} (${
        flagVariation ?? ''
      }/${(flagValue as string) ?? ''})`,
      ...(isExperimentEnabled !== undefined && {
        [`${experimentId} In Experiment`]: isExperimentEnabled
      })
    }

    experimentPayload = { ...experimentPayload, ...experimentProperties }
  })

  useEffect(() => {
    const sendToHeap = () => {
      if (!heapHasRun && heap.getHeapInstance() && LDClient) {
        heap.addEventProperties(experimentPayload)
        setHeapHasRun(true)
      }
    }

    sendToHeap()

    document.addEventListener('heapTagHasRun', sendToHeap)

    return () => {
      document.removeEventListener('heapTagHasRun', sendToHeap)
    }
  }, [LDClient])
}
