import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { type Failure } from 'api/hooks/useValidateComponent/types'

import { ReadonlyModal } from 'components/ReadonlyModal/ReadonlyModal'

import { FailureMessage } from './FailureMessage'
import classes from './ValidationFailures.module.scss'

const MULTITABLE_PATH = ['parameters', 'multiTableConfig', 'tableConfig']

interface ValidationFailuresProps {
  componentFailures: Failure[]
  parameterFailures: Failure[]
}

export const ValidationFailures: FC<ValidationFailuresProps> = ({
  componentFailures,
  parameterFailures
}) => {
  const { t } = useTranslation()

  const parameterFailureCount = parameterFailures.length
  const multiTableFailureCount = parameterFailures.reduce((acc, failure) => {
    const isTableConfig = MULTITABLE_PATH.every(
      (item, idx) => item === failure.path[idx]
    )
    return isTableConfig ? acc + 1 : acc
  }, 0)
  const otherParameterFailureCount =
    parameterFailureCount - multiTableFailureCount
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  )

  return (
    <ul className={classes.ValidationFailures}>
      <ReadonlyModal
        open={validationMessage !== null}
        closeModal={() => {
          setValidationMessage(null)
        }}
        title={t('componentProperties.status.errorModalTitle')}
        value={validationMessage}
      />
      {otherParameterFailureCount > 0 && (
        <li>
          <Typography format="bcs">
            {t('componentProperties.status.parameterError', {
              count: otherParameterFailureCount
            })}
          </Typography>
        </li>
      )}
      {multiTableFailureCount > 0 && (
        <li>
          <Typography format="bcs">
            {t('componentProperties.status.tableConfigError', {
              count: multiTableFailureCount
            })}
          </Typography>
        </li>
      )}
      {componentFailures.map((failure) => {
        return (
          <FailureMessage
            key={failure.message + failure.path.join('.')}
            failure={failure}
            setValidationMessage={setValidationMessage}
          />
        )
      })}
    </ul>
  )
}
