import { useSubmitFeedback } from 'api/hooks/copilot/useSubmitFeedback/useSubmitFeedback'

import { type CopilotChatMessage } from 'modules/core/Copilot/types'

import { type FeedbackSentiment } from '../types'

export const useSubmitCopilotChatFeedback = () => {
  const {
    mutateAsync: submitFeedbackRequest,
    isLoading: isLoadingSubmitFeedback
  } = useSubmitFeedback()

  const submitFeedback = async (
    message: CopilotChatMessage,
    sentiment: FeedbackSentiment,
    content: string
  ) => {
    if (!message.requestId || !message.service) {
      throw new Error('An unexpected error occurred')
    }
    await submitFeedbackRequest({
      requestId: message.requestId,
      response: sentiment,
      type: message.service,
      comments: content
    })
  }

  return { submitFeedback, isLoadingSubmitFeedback }
}
