import { type FC } from 'react'

import { type SVGIconProps } from '../../../ItemSelect/svg/types'

export const ReloadDocs: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Lengthen text"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9414 12.9135C18.7761 14.1736 18.271 15.365 17.4801 16.3598C16.6892 17.3546 15.6424 18.1154 14.452 18.5606C13.2616 19.0057 11.9725 19.1184 10.7229 18.8866C9.47335 18.6548 8.31044 18.0872 7.35893 17.2447C6.40742 16.4022 5.70319 15.3165 5.32179 14.1042C4.94038 12.8919 4.89618 11.5986 5.19392 10.3631C5.49166 9.12753 6.12011 7.99634 7.01188 7.09083C7.90365 6.18532 9.02509 5.53964 10.2559 5.22304C13.668 4.34794 17.1999 6.10428 18.5038 9.37718"
        stroke="#081D4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5.00162V9.37715H14.6245"
        stroke="#081D4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
