import {
  StrictMode,
  type FunctionComponent,
  type PropsWithChildren
} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { Toaster } from '@matillion/component-library'
import { GitOAuthProvider } from '@matillion/git-component-library'
import {
  AuthProvider,
  FlagProvider,
  RegionGuard,
  ServiceRegistryProvider
} from '@matillion/hub-client'

import { ApiClientProvider } from 'api/hooks/useApiClientContext/ApiClientProvider'

import { ShortcutProvider } from 'components/ShortcutProvider/ShortcutProvider'
import { UserPreferenceProvider } from 'components/UserPreferenceProvider'

import { getFlags } from 'config/flags/getFlags'

import DesignerQueryClientProvider from './api/DesignerQueryClientProvider'
import config from './config'

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <StrictMode>
    <ReactFlowProvider>
      <AuthProvider environment={config.authEnvironment}>
        <FlagProvider
          waitForFlags
          launchDarklyClientId={config.launchDarkly.clientId}
          flags={getFlags(config.environment)}
          useCamelCaseFlagKeys={false}
          offline={
            config.environment === 'local' ||
            config.environment === 'local-proxy' ||
            config.environment === 'test'
          }
        >
          <RegionGuard strictAccountRegion>
            <Toaster.Provider
              withFade={3}
              align="right"
              position="top"
              theme="dark"
            >
              <GitOAuthProvider environment={config.environment}>
                <DesignerQueryClientProvider>
                  <ApiClientProvider>
                    <ServiceRegistryProvider>
                      <ShortcutProvider>
                        <UserPreferenceProvider>
                          <BrowserRouter>{children}</BrowserRouter>
                        </UserPreferenceProvider>
                      </ShortcutProvider>
                    </ServiceRegistryProvider>
                  </ApiClientProvider>
                </DesignerQueryClientProvider>
              </GitOAuthProvider>
            </Toaster.Provider>
          </RegionGuard>
        </FlagProvider>
      </AuthProvider>
    </ReactFlowProvider>
  </StrictMode>
)

export default Providers
