import { type FC } from 'react'

import classnames from 'classnames'

import { TextInput } from 'components/TextInput'

import classes from './FreeTextInputEditor.module.scss'

interface FreeTextInputEditorProps {
  inputId: string
  labelId: string
  placeholder?: string
  value: string
  hasError?: boolean
  required?: boolean
  onEdit: (str: string) => unknown
  onBlur?: (str: string) => unknown
  autocompleteDisabled?: boolean
  containerClassName?: string
  dataTestId?: string
}

export const FreeTextInputEditor: FC<FreeTextInputEditorProps> = ({
  inputId,
  labelId,
  placeholder,
  value,
  hasError,
  onEdit,
  onBlur,
  autocompleteDisabled,
  required = false,
  containerClassName = '',
  dataTestId
}) => {
  return (
    <TextInput
      id={inputId}
      aria-labelledby={labelId}
      className={classnames(classes.FreeTextInputEditor__Input, {
        [classes['FreeTextInputEditor__Input--Error']]: hasError
      })}
      containerClassName={containerClassName}
      value={value}
      placeholder={placeholder}
      error={hasError}
      onChange={onEdit}
      onBlur={onBlur}
      autocompleteDisabled={autocompleteDisabled}
      required={required}
      data-testid={dataTestId}
    />
  )
}
