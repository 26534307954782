import type { ComponentName, Pipeline } from 'types/Pipeline'

export interface SkipComponentPayload {
  componentName: ComponentName
  skipped: boolean
}

export const skipComponent =
  (state: Pipeline) =>
  ({ componentName, skipped }: SkipComponentPayload) => {
    if (!state.pipeline.components[componentName]) {
      return
    }

    state.pipeline.components[componentName].skipped = skipped
  }
