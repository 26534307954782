import { type FC } from 'react'

import { Table as T, Typography } from '@matillion/component-library'

import {
  type SampleComponentResponse,
  type SampleComponentResponseColumns
} from 'api/hooks/useSampleComponent/types'

import classes from './SampleComponent.module.scss'
import SampleTableDataCell from './SampleTableDataCell'

const { Table, TableHead, TableRow, TableHeaderCell, TableBody } = T

interface SampleTableProps {
  title: string
  data: SampleComponentResponse
}

export const SampleTable: FC<SampleTableProps> = ({ title, data }) => {
  const getRowData = () => {
    return data.rows.map((row, rowIndex) => {
      const values = row.values ?? []
      if (!values.length) {
        return null
      }
      return (
        <TableRow
          key={`row-${String(rowIndex)}`}
          className={classes.SampleComponentResults__Row}
          id={rowIndex}
        >
          {values.map((value: string, cellIndex: number) => (
            <SampleTableDataCell
              key={`cell-${String(rowIndex)}-${String(cellIndex)}`}
              value={value}
            />
          ))}
        </TableRow>
      )
    })
  }

  return (
    <div
      data-testid="sample-table-scroll-container"
      className={classes.SampleComponentResultsWizard}
    >
      <Table
        data-testid="sample-table-data"
        className={classes.SampleComponentResults__Table}
        aria-label={title}
      >
        <TableHead>
          <TableRow className={classes.SampleComponentResults__Row}>
            {data.metadata.map((column: SampleComponentResponseColumns) => (
              <TableHeaderCell
                key={column.name}
                className={classes.SampleComponentResults__HeaderCell}
              >
                <Typography format="bcs" weight="bold">
                  {column.name}
                </Typography>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{getRowData()}</TableBody>
      </Table>
    </div>
  )
}
