import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  type JobConversionRecord,
  type VariableConversionRecord
} from 'api/hooks/useMigrationMetlJob'

import ConversionReportDetails from './ConversionReportDetails'

interface ConversionReportSectionProps {
  entityType: string
  conversionType: string
  conversionReport: JobConversionRecord[] | VariableConversionRecord[]
  jobsNotConverted?: string[]
}

const sectionStyle: React.CSSProperties = {
  marginTop: 10
}

const detailsTitleStyle: React.CSSProperties = {
  fontSize: '1.1rem',
  fontWeight: 'bold'
}

const noDetailsStyle: React.CSSProperties = {
  fontSize: '1.1rem',
  paddingTop: 5,
  paddingBottom: 5
}

const ConversionReportSection = ({
  entityType,
  conversionType,
  conversionReport,
  jobsNotConverted
}: ConversionReportSectionProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  const getRecordCount = () => {
    if (entityType === 'job' && conversionReport.length === 0) {
      return jobsNotConverted?.length || 0
    }
    return conversionReport.length
  }

  return (
    <div style={sectionStyle}>
      <span style={detailsTitleStyle}>
        {t(conversionType)} ({getRecordCount()})
      </span>
      {conversionReport.length > 0 || jobsNotConverted?.length ? (
        <div>
          <ConversionReportDetails
            entityType={entityType}
            conversionType={conversionType}
            conversionRecords={conversionReport}
            jobsNotConverted={jobsNotConverted}
          />
        </div>
      ) : (
        <span style={noDetailsStyle}>
          <br />
          {t(`noDetails.${conversionType}`, { entityType })}
        </span>
      )}
    </div>
  )
}

export default ConversionReportSection
