import { useMemo } from 'react'

import { datadogLogs } from '@datadog/browser-logs'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'

export const useAgentHost = () => {
  const { data, isInitialLoading, isError } = useAgentDetails()

  return useMemo(() => {
    if (!data?.host) {
      if (!isInitialLoading) {
        datadogLogs.logger.error('Agent host type not found', data)
      }
      return {
        agentHost: null,
        isInitialLoading,
        isError
      }
    } else {
      return {
        agentHost: data.host,
        isInitialLoading,
        isError
      }
    }
  }, [data, isError, isInitialLoading])
}
