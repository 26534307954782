import React from 'react'

import {
  type JobConversionRecord,
  type VariableConversionRecord
} from 'api/hooks/useMigrationMetlJob'

import ActionableRecordDetails from './ActionableRecordDetails'
import ConvertedRecordDetails from './ConvertedRecordDetails'
import JobNotConvertedRecordDetails from './JobNotConvertedRecordDetails'

interface ConversionReportDetailsProps {
  entityType: string
  conversionType: string
  conversionRecords: JobConversionRecord[] | VariableConversionRecord[]
  jobsNotConverted?: string[]
}

const ConversionReportDetails = ({
  entityType,
  conversionType,
  conversionRecords,
  jobsNotConverted = []
}: ConversionReportDetailsProps) => {
  return (
    <>
      {conversionType === 'converted' && (
        <ConvertedRecordDetails
          conversionRecords={conversionRecords}
          entityType={entityType}
        />
      )}
      {conversionType === 'autoconverted' && (
        <ActionableRecordDetails
          conversionRecords={conversionRecords}
          entityType={entityType}
          conversionType={conversionType}
        />
      )}
      {conversionType === 'manualRefactor' && (
        <ActionableRecordDetails
          conversionRecords={conversionRecords}
          entityType={entityType}
          conversionType={conversionType}
        />
      )}
      {conversionType === 'notConverted' && entityType === 'variable' && (
        <ActionableRecordDetails
          conversionRecords={conversionRecords}
          entityType={entityType}
          conversionType={conversionType}
        />
      )}
      {Boolean(jobsNotConverted?.length) &&
        conversionType === 'notConverted' &&
        entityType === 'job' && (
          <JobNotConvertedRecordDetails jobsNotConverted={jobsNotConverted} />
        )}
    </>
  )
}

export default ConversionReportDetails
