import { useFlags } from 'hooks/useFlags'

import { useEntitlementsContextProvider } from 'modules/core/Entitlements/useEntitlementsContextProvider'

import {
  type ContextMenuItem,
  type ContextMenuSection
} from '../CanvasContextMenu'

interface UseNotesSectionProps {
  canMakeAiNote: boolean
}

export const useNotesSection = ({
  canMakeAiNote
}: UseNotesSectionProps): ContextMenuSection => {
  const { enableExperimentalAiAutoDocs } = useFlags()
  const { hasCopilot } = useEntitlementsContextProvider()
  const items: ContextMenuItem[] = [
    {
      id: 'addNote'
    }
  ]

  if (enableExperimentalAiAutoDocs) {
    items.push({
      id: 'generateDocumentation',
      disabled: !canMakeAiNote,
      requiresUpgrade: !hasCopilot,
      upgradeModalType: 'aiNotesUpgrade'
    })
  }
  return {
    id: 'notes',
    items
  }
}
