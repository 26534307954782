import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useFlags } from 'hooks/useFlags'

import { type JobType } from 'job-lib/types/JobType'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type ProjectType } from '../useGetProject/types'
import useGetProject from '../useGetProject/useGetProject'
import {
  type ComponentSummaryResult,
  type ConnectorComponentSummary
} from './types'

export type ComponentContext = 'ORCHESTRATION' | 'TRANSFORMATION'

export const getComponentSummariesQueryKey = (context?: ComponentContext) => [
  queryKeys.componentSummaries,
  context
]

export const getConnectorComponentSummariesQueryKey = (
  context?: ComponentContext
) => [queryKeys.connectorComponentSummaries, context]

export const useConnectorComponentSummaries = (context?: ComponentContext) => {
  const { getComponentSummaries } = useDesignerApi()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''
  const { enableCustomConnectors } = useFlags()

  return useQuery(
    getConnectorComponentSummariesQueryKey(context),
    async () => {
      try {
        const { data } = await getComponentSummaries(agentVersion, 'CUSTOM')
        return getFilteredSummaries(
          data,
          warehouse,
          context
        ) as ConnectorComponentSummary[]
      } catch (err) {
        console.error(err)
        return []
      }
    },
    { enabled: !!warehouse && enableCustomConnectors && !!agentVersion }
  )
}

export const useConnectorComponentSummary = () => {
  const summaries = useConnectorComponentSummaries()?.data

  return {
    getSummary: useCallback(
      (componentId: string) =>
        summaries?.find((summary) => summary.componentId === componentId),
      [summaries]
    )
  }
}

/**
 * returns an object containing the component summaries indexed by emeraldId
 */
export const useStandardComponentSummaries = (context?: ComponentContext) => {
  const { getComponentSummaries } = useDesignerApi()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useQuery(
    getComponentSummariesQueryKey(context),
    async () => {
      const { data } = await getComponentSummaries(agentVersion, 'STATIC')

      const filteredComponentSummaries = getFilteredSummaries(
        data,
        warehouse,
        context
      )

      return filteredComponentSummaries
    },
    { enabled: !!warehouse && !!agentVersion }
  )
}

export const useComponentSummaries = (jobType?: JobType) => {
  const {
    data: standardSummaries = [],
    isLoading: isStandardLoading,
    isError: isStandardError,
    isSuccess: isStandardSuccess
  } = useStandardComponentSummaries(jobType)
  const {
    data: customSummaries = [],
    isLoading: isCustomLoading,
    isError: isCustomError,
    isSuccess: isCustomSuccess
  } = useConnectorComponentSummaries(jobType)

  const { enableCustomConnectors } = useFlags()

  return enableCustomConnectors
    ? {
        isLoading: isCustomLoading || isStandardLoading,
        isError: isStandardError || isCustomError,
        isSuccess: isStandardSuccess && isCustomSuccess,
        data: [...standardSummaries, ...customSummaries]
      }
    : {
        isLoading: isStandardLoading,
        isError: isStandardError,
        isSuccess: isStandardSuccess,
        data: standardSummaries
      }
}

function getFilteredSummaries(
  data: ComponentSummaryResult,
  warehouse: ProjectType | undefined,
  context?: ComponentContext
) {
  return data.results.filter((component) => {
    const isSupportedByWarehouse =
      warehouse && component.dwhTypes
        ? component.dwhTypes.some(
            (wh) => wh.toLowerCase() === warehouse.toLowerCase()
          )
        : true

    const isCorrectJobType = context
      ? component.componentType === context
      : true

    return isCorrectJobType && isSupportedByWarehouse
  })
}

export * from './types'
