import { useEffect, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { AutoComplete } from 'components/AutoComplete'
import { type AutoCompleteItem } from 'components/AutoComplete/types'
import { useParameterOverlayErrors } from 'components/ParameterOverlay'

import { type GridCell } from 'modules/ParameterEditors/components/GridEditor/components/Grid/types'

import { useMultiTableDataSelectionOptions } from '../../hooks/useMultiTableDataSelectionOptions'
import classes from './GridDropdown.module.scss'
import { getParameterValue } from './utils'

interface GridDropdownProps extends GridCell {
  componentSummaryId: ComponentSummaryId
  componentMetadata: ComponentMetadata
  parameter: ComponentParameter
  editorColumn: EditorColumn
  dataSourceDplID: string
  dataSourceValue: string
  value: string
  parameterName: string
  onChange: (gc: GridCell) => void
}

export const GridDropdown: FC<GridDropdownProps> = ({
  componentSummaryId,
  componentMetadata,
  parameter,
  editorColumn,
  dataSourceDplID,
  dataSourceValue,
  type,
  rowSlot,
  slot,
  dataType,
  value,
  parameterName,
  onChange
}) => {
  const { t } = useTranslation()
  const [dropdownValue, setDropdownValue] = useState<string>(value)
  const { errors, setErrors, clearError } = useParameterOverlayErrors()
  const { data, isLoading, isError, error } = useMultiTableDataSelectionOptions(
    {
      componentSummaryId,
      componentMetaData: componentMetadata,
      parameter,
      isEnabled: !parameter.options,
      dataSourceDplID,
      dataSourceValue
    }
  )

  useEffect(() => {
    setDropdownValue(value)
  }, [value])

  useEffect(() => {
    return () => {
      clearError(`GridDropdown-${slot}-${rowSlot}`)
    }
  }, [slot, rowSlot, clearError])

  useEffect(() => {
    const msg = `Column: "${rowSlot}" - ${error?.detail}`
    if (isError && error) {
      const isErrorShown = errors.find(({ message }) => message === msg)

      if (!isErrorShown) {
        setErrors([
          ...errors,
          {
            id: `GridDropdown-${slot}-${rowSlot}`,
            message: msg,
            type: 'error'
          }
        ])
      }
    }
  }, [error, errors, isError, rowSlot, setErrors, slot])

  const selectedValue = useMemo(
    () => ({
      id: dropdownValue,
      name: getParameterValue(dropdownValue, parameter, t)
    }),
    [dropdownValue, parameter, t]
  )

  const options = useMemo(() => {
    if (isLoading) {
      return []
    }

    if (data && data.length > 0 && data[0].options) {
      return data[0].options?.map((option) => ({
        id: option,
        name: getParameterValue(option, parameter, t)
      }))
    }

    return [
      {
        id: 'no_options_available',
        name: t('parameterEditor.DROPDOWN.noItemsFound', {
          parameter: parameter.displayName
        }),
        disabled: true
      }
    ]
  }, [data, isLoading, parameter, t])

  const showError = useMemo(() => {
    return errors.find(({ id }) => id === `GridDropdown-${slot}-${rowSlot}`)
  }, [errors, rowSlot, slot])

  const otherProps = {
    'data-testid': `dynamic-dropdown-row-${slot}-slot-${rowSlot}`,
    'aria-label': parameterName
  }

  return (
    <AutoComplete
      {...otherProps}
      className={classNames(classes.GridDropdown, {
        [classes['GridDropdown--Error']]: showError
      })}
      inputClassName={classes.GridDropdown__Input}
      optionClassName={classes.GridDropdown__Option}
      allowFreetext={true}
      placeholder="Choose value"
      loading={isLoading}
      availableItems={options}
      value={selectedValue}
      onChange={(selected: { target: AutoCompleteItem }) => {
        const selectedVal = (selected.target.value?.id as string) ?? ''
        setDropdownValue(selectedVal)
        onChange({
          rowSlot,
          slot,
          type,
          value: selectedVal,
          dataType
        })
      }}
    />
  )
}
