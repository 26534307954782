import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { updateOrchestrationLinks } from 'job-lib/job-functions/updateOrchestrationLinks'
import { updateTransformationLinks } from 'job-lib/job-functions/updateTransformationLinks'
import { JobType } from 'job-lib/types/JobType'

import { type JobState, type UpdateLinks } from '../../job.types'

export const updateConnectors: CaseReducer<
  JobState,
  PayloadAction<UpdateLinks>
> = (
  jobState,
  {
    payload: {
      sourceComponentId,
      targetComponentId,
      sourceCardinality,
      targetCardinality,
      sourceType
    }
  }
) => {
  if (jobState.jobType === JobType.Transformation) {
    updateTransformationLinks({
      job: jobState.job,
      sourceComponentId,
      targetComponentId,
      sourceCardinality,
      targetCardinality,
      sourceType
    })
  }

  if (jobState.jobType === JobType.Orchestration) {
    updateOrchestrationLinks({
      job: jobState.job,
      sourceComponentId,
      targetComponentId,
      sourceCardinality,
      targetCardinality,
      sourceType
    })
  }

  return jobState
}
