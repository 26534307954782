import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const SapOdpConfig: ComponentLayout = {
  componentId: 'modular-sap-odp-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'connection'
          },
          {
            dplId: 'serverConnection'
          },
          {
            dplId: 'encryption'
          },
          {
            dplId: 'host'
          },
          {
            dplId: 'systemNumber'
          },
          {
            dplId: 'messageServerHost'
          },
          {
            dplId: 'messageServerService'
          },
          {
            dplId: 'groupServer'
          },
          {
            dplId: 'webSocketHost'
          },
          {
            dplId: 'webSocketPort'
          },
          {
            dplId: 'certificate'
          },
          {
            dplId: 'client'
          },
          {
            dplId: 'language'
          },
          {
            dplId: 'connectionOptions'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'sap-odp-input-v1',
            omitParameters: [
              'connection',
              'serverConnection',
              'encryption',
              'host',
              'systemNumber',
              'messageServerHost',
              'messageServerService',
              'groupServer',
              'webSocketHost',
              'webSocketPort',
              'certificate',
              'client',
              'language',
              'connectionOptions',
              'maxPackageSize'
            ]
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null),
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [
          {
            dplId: 'maxPackageSize'
          }
        ]
      }
    ]
  }
}
