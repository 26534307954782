import { useCallback, useMemo } from 'react'

import { type PseudoComponent } from '../../api/hooks/useGetPseudoComponents/types'
import { getPseudoComponents } from '../../api/hooks/useGetPseudoComponents/useGetPseudoComponents'
import { isDPLParameterCollection } from '../../job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { type ParameterCollection } from '../../job-lib/types/Parameters'
import {
  getDPLParameters,
  getParameterDPLValue
} from '../../modules/ComponentParameters/utils/getParameterValue'
import { type ParameterValue } from '../../types/Pipeline'

function isExactMatch(valueAtMatchPath: ParameterValue, expectedValue: string) {
  if (typeof valueAtMatchPath === 'string') {
    return valueAtMatchPath === expectedValue
  } else {
    return JSON.stringify(valueAtMatchPath) === expectedValue
  }
}

function isPartialMatch(
  valueAtMatchPath: ParameterValue,
  expectedValue: string
) {
  if (typeof valueAtMatchPath === 'string') {
    return valueAtMatchPath.includes(expectedValue)
  } else {
    return JSON.stringify(valueAtMatchPath).includes(expectedValue)
  }
}

function getMatchedIconFromParams(
  parameters: ParameterCollection,
  matchingPseudoComponents: PseudoComponent[]
) {
  if (!isDPLParameterCollection(parameters)) {
    return undefined
  }

  const dplParams = getDPLParameters(null, parameters)

  const matchedIcon = matchingPseudoComponents.find((m) => {
    const valueAtMatchPath = getParameterDPLValue(
      m.iconMatcher.paramPath.split('.'),
      dplParams
    )
    // There is no value at path provided - no match
    if (!valueAtMatchPath) {
      return false
    }
    // Otherwise compare value
    if (m.iconMatcher.exact ?? true) {
      return isExactMatch(valueAtMatchPath, m.iconMatcher.expectedValue)
    } else {
      return isPartialMatch(valueAtMatchPath, m.iconMatcher.expectedValue)
    }
  })

  return matchedIcon?.icon
}

/**
 * If there are any matching pseudo components, gets the dynamic icon based on parameter selection, using matchers
 * found in config/pseudo-components.json This currently only supports DPL param based pseudo components
 */
export const useGetDynamicIconFromParameters = () => {
  const pseudoComponents = useMemo(() => getPseudoComponents(), [])
  const getDynamicIcon = useCallback(
    (
      componentId: string,
      parameters: ParameterCollection
    ): string | undefined => {
      const matchingPseudoComponents = pseudoComponents.find(
        (c) => c.componentId === componentId
      )?.pseudoComponents
      if (matchingPseudoComponents) {
        return getMatchedIconFromParams(parameters, matchingPseudoComponents)
      }
      return undefined
    },
    [pseudoComponents]
  )
  return { getDynamicIcon }
}
