import { useTranslation } from 'react-i18next'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type SampleComponentFailResponse } from 'api/hooks/useSampleComponent/types'

import { Loading } from 'components/Loading/Loading'

import { useFlags } from 'hooks/useFlags'

import { NoContentMessage } from '../NoContentMessage/NoContentMessage'
import classes from './SampleComponent.module.scss'
import { useSampleProvider } from './SampleProvider'
import { SampleTable } from './SampleTable'
import { VirtualisedSampleTable } from './VirtualisedSampleTable'

interface SampleContentProps {
  componentName: string
  isLoading: boolean
  isComponentValidated: boolean
  isValidationError: boolean
  isSampleError: boolean
  sampleErrorDetail: SampleComponentFailResponse
}

export const SampleContent = ({
  componentName,
  isLoading,
  isComponentValidated,
  isValidationError,
  isSampleError,
  sampleErrorDetail
}: SampleContentProps) => {
  const { t } = useTranslation()
  const { enableVirtualisedTable } = useFlags()
  const { hasPermission } = useProjectPermission(
    'sampling_pipelines',
    PermissionType.ENVIRONMENT
  )
  const { currentSample } = useSampleProvider()

  if (!hasPermission) {
    return <NoContentMessage text={t('sampleComponent.sampleNoPermission')} />
  }

  if (!isComponentValidated) {
    return (
      <NoContentMessage text={t('sampleComponent.validationNotTriggeredYet')} />
    )
  }

  if (isValidationError) {
    return <NoContentMessage text={t('sampleComponent.validationError')} />
  }

  if (isLoading) {
    return (
      <Loading
        data-testid="fetching-sample-loading-spinner"
        className={classes.SampleLoading}
        text={t('sampleComponent.fetchingSample')}
      />
    )
  }

  if (isSampleError) {
    return (
      <NoContentMessage
        text={sampleErrorDetail?.detail ?? t('sampleComponent.sampleError')}
      />
    )
  }

  if (!currentSample) {
    return (
      <NoContentMessage text={t('sampleComponent.sampleNotTriggeredYet')} />
    )
  }

  if (currentSample.rows.length === 0) {
    return <NoContentMessage text={t('sampleComponent.noResultsFound')} />
  }

  return enableVirtualisedTable ? (
    <VirtualisedSampleTable
      title={t('sampleComponent.tableTitle', { componentName })}
      data={currentSample}
    />
  ) : (
    <SampleTable
      title={t('sampleComponent.tableTitle', { componentName })}
      data={currentSample}
    />
  )
}
