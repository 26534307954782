import { type Failure } from 'api/hooks/useValidateComponent/types'

export const mergeClientAndServerValidationFailures = (
  clientFailures: Failure[],
  serverFailures: Failure[]
): Failure[] => {
  const failures: Record<string, Failure> = serverFailures
    .concat(clientFailures)
    .reduce((allFailures, currentFailure) => {
      const [failureType, failureId] = currentFailure.path

      if (failureType && failureId) {
        return {
          ...allFailures,
          [currentFailure.path.join('.')]: currentFailure
        }
      }

      if (currentFailure.message) {
        return {
          ...allFailures,
          [currentFailure.message]: currentFailure
        }
      }

      return allFailures
    }, {})

  return Object.values(failures)
}
