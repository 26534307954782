import { useUser } from '@matillion/hub-client'
import axios from 'axios'

import config from 'config'

import useAuthInterceptor from '../useAuthInterceptor/useAuthInterceptor'

export const useDesignerApiClient = () => {
  const { organisation } = useUser()

  const client = axios.create({
    baseURL: `${config.getApiBaseUrl(organisation.region)}`
  })

  useAuthInterceptor(client)

  return client
}
