import { type AxiosInstance } from 'axios'

export interface ExportPipelinesAPI {
  client: AxiosInstance
  projectId: string
  branchName: string
  paths: string[]
  fileName: string
  includeTypes?: string[]
}

const defaultFileTypes = [
  '.orch.yaml',
  '.tran.yaml',
  '.ORCHESTRATION',
  '.TRANSFORMATION'
]

export const exportPipelinesApi = async ({
  client,
  projectId,
  branchName,
  paths,
  fileName,
  includeTypes = defaultFileTypes
}: ExportPipelinesAPI) => {
  const response = await client.post(
    `/projects/${projectId}/branches/${branchName}/export`,
    {
      fileName,
      paths,
      includeTypes
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }
  )

  return response
}
