import { useCallback, useMemo, useState, type FC, type ReactNode } from 'react'

import AINotesUpgradeModal from 'modules/core/EtlDesigner/components/UpgradeModal/AINotesUpgradeModal'

import { ContextMenuUpgradeModalContext } from './context'
import { type UpgradeModalType } from './types'

export interface UpgradeModalProviderProps {
  children: ReactNode
}

/**
 * Provides a function to show the AI notes upgrade modal.
 */
export const ContextMenuUpgradeModalProvider: FC<UpgradeModalProviderProps> = ({
  children
}) => {
  const [activeModalType, setActiveModalType] = useState<UpgradeModalType>()

  // Get the modal content based on the activeModalType, or none if undefined
  const getActiveUpgradeModal = () => {
    if (activeModalType === 'aiNotesUpgrade') {
      return (
        <AINotesUpgradeModal
          data-testid="upgrade-modal"
          onClose={() => {
            setActiveModalType(undefined)
          }}
        />
      )
    }
  }

  const showUpgradeModal = useCallback((type?: UpgradeModalType) => {
    setActiveModalType(type)
  }, [])

  const value = useMemo(
    () => ({
      showUpgradeModal
    }),
    [showUpgradeModal]
  )

  return (
    <ContextMenuUpgradeModalContext.Provider value={value}>
      {getActiveUpgradeModal()}
      {children}
    </ContextMenuUpgradeModalContext.Provider>
  )
}
