import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters
} from '@tanstack/react-query'

import type {
  JobConversionInfo,
  MetlJobMigrationResponse,
  VariableConversionInfo
} from 'api/hooks/useMigrationMetlJob'
import useGetMetlJobMigrationReport from 'api/hooks/useMigrationMetlJob/useGetMetlJobMigrationReport'

export interface UseMetlJobConversionInfoProps {
  file: File
  path: string
  mappings: string
}

export interface UseMetlJobConversionInfoResponse {
  isDryRunLoading: boolean
  hasJobsThatCannotBeConverted: boolean
  hasVariablesThatCannotBeConverted: boolean
  hasNothingToImport: boolean
  dryRunError: unknown
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<MetlJobMigrationResponse>>
  jobs?: JobConversionInfo
  variables?: VariableConversionInfo
}

const useMetlJobConversionInfo = ({
  file,
  path,
  mappings
}: UseMetlJobConversionInfoProps): UseMetlJobConversionInfoResponse => {
  const {
    data,
    isFetching: isLoading,
    error,
    refetch
  } = useGetMetlJobMigrationReport({
    file,
    path,
    mappings
  })
  const jobs = data?.jobs
  const variables = data?.projectVariables

  const hasConvertibleJobs = !!jobs && jobs.converted.length > 0
  const hasJobsThatCannotBeConverted = !!jobs && jobs.notConverted.length > 0

  const hasConvertibleVariables = !!variables && variables.converted.length > 0
  const hasVariablesThatCannotBeConverted =
    !!variables && variables.notConverted.length > 0

  const hasNothingToImport =
    !hasConvertibleJobs &&
    !hasConvertibleVariables &&
    !isLoading &&
    !error &&
    !hasJobsThatCannotBeConverted &&
    !hasVariablesThatCannotBeConverted

  return {
    isDryRunLoading: isLoading,
    hasJobsThatCannotBeConverted,
    hasVariablesThatCannotBeConverted,
    hasNothingToImport,
    jobs,
    variables,
    dryRunError: error,
    refetch
  }
}
export default useMetlJobConversionInfo
