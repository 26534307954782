import { useContext, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Typography } from '@matillion/component-library'

import { TextLink } from 'components/TextLink/TextLink'

import { isAiComponent } from 'utils/componentSummaryFilter'

import { getDocumentationLink } from '../../../ComponentProperties/getDocumentationLink'
import { EntitlementsContext } from '../../../core/Entitlements/context'
import classes from './LimitedUseComponentWarning.module.scss'

interface LimitedUseComponentWarningProps {
  componentSummaryId: string
}

export const LimitedUseComponentWarning: FC<
  LimitedUseComponentWarningProps
> = ({ componentSummaryId }) => {
  const { isLimitedUseComponent } = useContext(EntitlementsContext)
  const { t } = useTranslation()

  if (
    !isLimitedUseComponent(componentSummaryId) ||
    !isAiComponent(componentSummaryId)
  ) {
    return null
  }

  return (
    <Alert
      className={classes.AIComponentUpgradeWarning__Alert}
      title={t('aiUpgradeRequiredWarning.title')}
      data-testid="component-ai-upgrade-warning"
      message={
        <div className={classes.AIComponentUpgradeWarning__Alert__Content}>
          <Typography type="bcs">
            {t('aiUpgradeRequiredWarning.message')}
          </Typography>
          <TextLink
            text={t('aiUpgradeRequiredWarning.findOutMore')}
            link={getDocumentationLink(componentSummaryId)}
            className={classes.AIComponentUpgradeWarning__Alert__Content__Link}
            dataTestId={'component-ai-upgrade-warning-link'}
          />
        </div>
      }
      type="warning"
    />
  )
}
