/**
 * The status of a file in the working tree store (WTS)
 * as a local git repository would label it.
 */
export enum FileStatus {
  /**
   * A file that has been created in the WTS,
   * but not committed to the remote repository.
   */
  NEW = 'NEW',

  /**
   * A file that has previously been committed and is
   * tracked in the remote repository, but has been
   * modified since that commit.
   */
  MODIFIED = 'MODIFIED',

  /**
   * A file that has previously been committed and is
   * tracked in the remote repository and has not been
   * modified since.
   */
  UNMODIFIED = 'UNMODIFIED',

  /**
   * A file that has previously been committed and is
   * tracked in the remote repository, but has been marked
   * for deletion since that commit.
   */
  DELETED = 'DELETED'
}
