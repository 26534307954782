export const insertItems = <T extends { id: string }>(
  rows: T[],
  sourceIds: string[],
  targetIndex: number,
  droppedItemId: string
): T[] => {
  const sourceItems = sourceIds
    .map((sourceId) => rows.find((r) => r.id === sourceId))
    .filter((item): item is T => item !== undefined)

  const droppedItemIndex = rows.findIndex((r) => r.id === droppedItemId)
  const offset = targetIndex > droppedItemIndex ? 1 : 0

  const itemsBefore: T[] = []
  const itemsAfter: T[] = []

  rows.forEach((r, index) => {
    if (sourceIds.includes(r.id)) return
    if (index < targetIndex + offset) {
      itemsBefore.push(r)
    } else {
      itemsAfter.push(r)
    }
  })

  return [...itemsBefore, ...sourceItems, ...itemsAfter]
}
