import { useState } from 'react'

import type {
  VariableConversionInfo,
  VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import { FilterGroup } from 'components/FilterGroup/FilterGroup'

import VariableImportReport from '../VariableImportReport/VariableImportReport'
import classes from './VariableConversionDetails.module.scss'

interface VariableConversionDetailsProps {
  variableConversionReport: VariableConversionReport
  variables: VariableConversionInfo | undefined
  hasVariablesThatCannotBeConverted: boolean
}

const VariableConversionDetails = ({
  variableConversionReport,
  variables,
  hasVariablesThatCannotBeConverted
}: VariableConversionDetailsProps) => {
  const successfulVariables =
    variableConversionReport.convertedVariables.length +
    variableConversionReport.autoconvertedVariables.length
  const warningVariables = variableConversionReport.refactorVariables.length
  const unsuccessfulVariables = variables?.notConverted.length
  const [variablesSelectedOptions, setVariablesSelectedOptions] = useState<
    string[]
  >([])

  return (
    <>
      <div
        className={classes.filterGroupWrapper}
        data-testid="conversion-tool-variables-filter"
      >
        <FilterGroup
          options={[
            `Successful (${successfulVariables})`,
            `Warning (${warningVariables})`,
            `Unsuccessful (${unsuccessfulVariables})`
          ]}
          selectedOptions={variablesSelectedOptions}
          onChange={(option) => {
            const newSelectedOptions = variablesSelectedOptions.includes(option)
              ? variablesSelectedOptions.filter((o) => o !== option)
              : [...variablesSelectedOptions, option]
            setVariablesSelectedOptions(newSelectedOptions)
          }}
          data-testid="conversion-variables-filter"
        />
      </div>

      <div className={classes.contentContainer}>
        {variables &&
          variableConversionReport?.convertedVariables.length > 0 &&
          (variablesSelectedOptions.length === 0 ||
            variablesSelectedOptions.includes(
              `Successful (${successfulVariables})`
            )) && (
            <VariableImportReport
              importType="Success"
              data={variableConversionReport.convertedVariables}
              translationId="variables.converted"
              id="conversion-tool-variables-to-convert"
            />
          )}

        {variables &&
          variableConversionReport?.autoconvertedVariables.length > 0 &&
          (variablesSelectedOptions.length === 0 ||
            variablesSelectedOptions.includes(
              `Successful (${successfulVariables})`
            )) && (
            <VariableImportReport
              importType="Success"
              data={variableConversionReport.autoconvertedVariables}
              translationId="variables.autoconverted"
              id="conversion-tool-variables-to-autoconvert"
            />
          )}

        {variables &&
          variableConversionReport?.refactorVariables.length > 0 &&
          (variablesSelectedOptions.length === 0 ||
            variablesSelectedOptions.includes(
              `Warning (${warningVariables})`
            )) && (
            <VariableImportReport
              importType="Warning"
              data={variableConversionReport.refactorVariables}
              translationId="variables.manualRefactor"
              id="conversion-tool-variables-to-refactor"
            />
          )}

        {variables &&
          hasVariablesThatCannotBeConverted &&
          variables.notConverted &&
          (variablesSelectedOptions.length === 0 ||
            variablesSelectedOptions.includes(
              `Unsuccessful (${unsuccessfulVariables})`
            )) && (
            <VariableImportReport
              importType="Error"
              data={variables.notConverted}
              translationId="variables.notConverted"
              id="conversion-tool-variables-not-to-convert"
            />
          )}
      </div>
    </>
  )
}

export default VariableConversionDetails
