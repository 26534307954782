import {
  type FunctionComponent,
  type PropsWithChildren,
  type ReactNode
} from 'react'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ErrorPage.module.scss'

interface ErrorPageProps {
  icon?: ReactNode
  title: ReactNode
  actions?: ReactNode
  overlay?: boolean
}

export const ErrorPage: FunctionComponent<
  PropsWithChildren<ErrorPageProps>
> = ({ icon, title, actions, children, overlay }) => (
  <div
    className={classnames(classes.ErrorPage, {
      [classes['ErrorPage--Overlay']]: overlay
    })}
    role="alert"
  >
    <div className={classes.ErrorPage__Container}>
      {icon && <div className={classes.ErrorPage__Icon}>{icon}</div>}

      <Typography as="h2" format="tl" className={classes.ErrorPage__Title}>
        {title}
      </Typography>

      <Typography as="div" format="bcm" className={classes.ErrorPage__Content}>
        {children}
      </Typography>

      {actions && <div className={classes.ErrorPage__Actions}>{actions}</div>}
    </div>
  </div>
)
