import { type ComponentLayout } from './types'

export const PineconeVectorQuery: ComponentLayout = {
  componentId: 'pinecone-vector-query',
  wizard: {
    steps: [
      {
        stepId: 'querySource',
        displayName: 'Source',
        previousStepId: null,
        nextStepId: 'embeddingGenerator',
        parameters: [
          {
            dplId: 'querySource'
          }
        ]
      },
      {
        stepId: 'embeddingGenerator',
        displayName: 'Embeddings API',
        previousStepId: 'querySource',
        nextStepId: 'pinecone',
        parameters: [
          {
            dplId: 'embeddingGenerator'
          }
        ]
      },
      {
        stepId: 'pinecone',
        displayName: 'Connect',
        previousStepId: 'embeddingGenerator',
        nextStepId: 'lookupSource',
        parameters: [
          {
            dplId: 'pinecone'
          }
        ]
      },
      {
        stepId: 'lookupSource',
        displayName: 'Configure',
        previousStepId: 'pinecone',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'lookupSource'
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'lookupSource',
        nextStepId: null,
        parameters: [
          {
            dplId: 'destination'
          }
        ]
      }
    ]
  }
}
