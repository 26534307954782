import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, MicroCta, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './CellMessage.module.scss'
import MessageModal from './MessageModal'

export interface CellMessageProps {
  value?: string
  stepId: string
}

const CellMessage: FC<CellMessageProps> = ({ value, stepId }) => {
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className={classes.CellMessage}>
      <div
        className={classNames(
          classes.CellMessage__Text,
          'u-truncate-text-2-lines'
        )}
      >
        <Typography format="bcs" as="span">
          {value}
        </Typography>
      </div>
      {value && (
        <MicroCta
          aria-label={t('taskExplorer.cellMessage.cta')}
          alt="transparent"
          onClick={() => {
            setMessageModalOpen(true)
          }}
          className={classNames(classes.CellMessage__CTA)}
          data-testid="cell-message-cta"
          tabIndex={-1}
        >
          <Icon.Export height={18} width={18} />
        </MicroCta>
      )}
      {messageModalOpen && value && (
        <MessageModal
          setIsOpen={setMessageModalOpen}
          value={value}
          stepId={stepId}
        />
      )}
    </div>
  )
}

export default CellMessage
