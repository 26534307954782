import { useTranslation } from 'react-i18next'

import { Icon, MicroCta } from '@matillion/component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

interface ActionsCellProps {
  name: string
  onDelete: () => unknown
  onEdit: () => unknown
}

export const ActionsCell = ({ name, onDelete, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()
  const { hasPermission: canEditEnvars } = useProjectPermission(
    'edit_environments',
    PermissionType.PROJECT
  )

  return (
    <>
      {canEditEnvars ? (
        <>
          <MicroCta
            alt="transparent"
            aria-label={t('manageVariables.edit.edit')}
            onClick={onEdit}
          >
            <span className="u-visually-hidden" aria-hidden="false">
              {t('manageVariables.edit.editVariableName', { name })}
            </span>
            <Icon.Pencil width="18" height="18" />
          </MicroCta>
          <MicroCta
            alt="transparent"
            aria-label={t('manageVariables.delete.delete')}
            onClick={onDelete}
          >
            <span className="u-visually-hidden" aria-hidden="false">
              {t('manageVariables.delete.deleteVariableName', { name })}
            </span>
            <Icon.Trash width="18" height="18" />
          </MicroCta>
        </>
      ) : (
        <>
          <MicroCta
            alt="transparent"
            aria-label={t('manageVariables.disable.view')}
            onClick={onEdit}
          >
            <span className="u-visually-hidden" aria-hidden="false">
              {t('manageVariables.disable.viewVariableName', { name })}
            </span>
            <Icon.Eye width="18" height="18" />
          </MicroCta>
        </>
      )}
    </>
  )
}
