import { isAxiosError, type AxiosError } from 'axios'

import type { ProblemDetailsExtended } from 'api/types/http-problem-details'

interface Error {
  error: string
  showEditMappings: boolean
}

interface Violation {
  field: string
  message: string
}

const hasClientError = (err: AxiosError<unknown, unknown>) =>
  err.response?.status &&
  err.response?.status > 399 &&
  err.response?.status < 500

/**
 * Extracts migration conversion error from the API response. If the error is not a conversion error, returns null.
 * @param err - The error object to extract the conversion error from.
 */
export const getConversionError = (err: unknown): Error | null => {
  if (isAxiosError(err) && hasClientError(err) && err.response) {
    const pd = err.response.data as ProblemDetailsExtended
    if (pd?.detail) {
      const violations = (pd.violations as Violation[]) || []
      const mappingViolations = violations.filter((v) =>
        v.field.startsWith('mappings')
      )
      return {
        error: `${pd.detail}\n${violations
          .map((v) => `- ${v.field}: ${v.message}`)
          .join('\n')}`,
        showEditMappings: mappingViolations?.length > 0
      }
    }
  }

  return null
}
