import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import classes from './CopilotIntro.module.scss'
import CopilotIntroHeader from './CopilotIntroHeader/CopilotIntroHeader'

const CopilotIntro = () => {
  const { t } = useTranslation()
  const tips = [
    { id: 1, text: t('copilot.intro.tips.t1') },
    { id: 2, text: t('copilot.intro.tips.t2') },
    { id: 3, text: t('copilot.intro.tips.t3') }
  ]
  return (
    <div className={classes.Intro}>
      <CopilotIntroHeader />
      <div className={classes.Intro__Tips}>
        <div className={classes.Intro__Tips__Header}>
          <Icon.Info className={classes.Intro__Tips__Header__Icon} />
          <Typography
            format="bcs"
            weight="bold"
            className={classes.Intro__Tips__Header__Text}
          >
            {t('copilot.intro.tips.header')}
          </Typography>
        </div>
        <ul className={classes.Intro__Tips__List}>
          {tips.map(({ id, text }) => (
            <li key={id} className={classes.Intro__Tips__List__Item}>
              <Typography format="bcs">{text}</Typography>
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.Intro__SecurityNotice}>
        <Typography format="mc">
          {t('copilot.intro.securityNotice.prefix')}{' '}
          <a
            href="https://www.matillion.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('copilot.intro.securityNotice.linkText')}
          </a>
        </Typography>
      </div>
    </div>
  )
}

export default CopilotIntro
