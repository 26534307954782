import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import type { GetStepMessagesResponse } from '../useEosApi/types/eos'
import { useEosApi } from '../useEosApi/useEosApi'

export const useGetStepMessages = (stepId: string) => {
  const { getStepMessages } = useEosApi()

  return useQuery<GetStepMessagesResponse, AxiosError>(
    ['stepMessages', stepId],
    async () => (await getStepMessages(stepId)).data
  )
}
