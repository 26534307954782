import {
  useCallback,
  useReducer,
  type ChangeEvent,
  type FC,
  type MouseEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  Button,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import classes from '../SemiStructuredNestedDataPickerEditor.module.scss'
import {
  type ChildDataStructure,
  type DataStructure,
  type SharedDataStructure
} from '../types'
import {
  createInitialState,
  elementFormReducer,
  ReducerActions
} from './elementFormReducer'
import { type FormStructureKeys, type InitialValues } from './types'

interface ElementFormProps {
  cta: string
  existingKeys: Array<SharedDataStructure['key']>
  initialValues: InitialValues
  element: DataStructure | ChildDataStructure
  disabledFields: Partial<Record<FormStructureKeys, boolean>>
  semiStructuredType: string
  types: string[]
  onSubmit: (values: Omit<ChildDataStructure, 'column'>) => void
  onCancel: () => void
}

export const ElementForm: FC<ElementFormProps> = ({
  cta,
  existingKeys,
  element,
  initialValues,
  disabledFields,
  semiStructuredType,
  types,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useReducer(
    elementFormReducer,
    { initialValues, disabledFields, t },
    createInitialState
  )
  const isValid = Object.values(formValues).every((field) => field.isValid)

  const handleSubmit = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      if (!isValid) {
        setFormValues({ type: ReducerActions.VALIDATE_ALL_FIELDS })
        return
      }

      const elementType =
        'array' in element && element.array ? 'ARRAY' : element.type

      const type =
        formValues.type.value === 'ARRAY'
          ? semiStructuredType
          : formValues.type.value

      const semiStructured = type === semiStructuredType

      const hasTypeChanged = formValues.type.value !== elementType

      const changes = {
        key: formValues.key.value,
        size: parseInt(formValues.size.value.toString()),
        decimalPlaces: parseFloat(formValues.decimalPlaces.value.toString()),
        alias: formValues.alias.value,
        array: formValues.array.value.toString() === 'true',
        selected: formValues.selected.value.toString() === 'true',
        children: hasTypeChanged ? [] : element.children,
        semiStructured,
        type
      }

      onSubmit(changes)
    },
    [semiStructuredType, element, formValues, onSubmit, isValid]
  )

  const toAutoCompleteItemId = (type: string): AutoCompleteItemId => ({
    name: type,
    id: type
  })

  return (
    <form className={classes.ElementForm}>
      <Field
        name="key"
        description={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.key')}
        value={formValues.key.value}
        onBlur={() => {
          setFormValues({
            type: ReducerActions.SHOW_FIELD_VALIDATION,
            field: 'key'
          })
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFormValues({
            type: ReducerActions.UPDATE_KEY,
            field: 'key',
            value: e.target.value.trim(),
            existingKeys,
            t
          })
        }}
        disabled={disabledFields.key}
        hasError={Boolean(
          formValues.key.displayFieldError && formValues.key.errorText
        )}
        errorText={formValues.key.displayFieldError && formValues.key.errorText}
      />
      <Field
        name="type"
        description={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.type')}
        value={toAutoCompleteItemId(formValues.type.value)}
        onBlur={() => {
          setFormValues({
            type: ReducerActions.SHOW_FIELD_VALIDATION,
            field: 'type'
          })
        }}
        onChange={(e: ChangeEvent<{ value?: AutoCompleteItemId }>) => {
          setFormValues({
            type: ReducerActions.UPDATE_TYPE,
            field: 'type',
            value: String(e.target.value?.id ?? ''),
            t
          })
        }}
        disabled={disabledFields.type}
        inputComponent={AutoComplete}
        availableItems={types.map(toAutoCompleteItemId)}
        hasError={Boolean(
          formValues.type.displayFieldError && formValues.type.errorText
        )}
        errorText={
          formValues.type.displayFieldError && formValues.type.errorText
        }
      />
      {!(
        formValues.type.value === semiStructuredType ||
        formValues.type.value === 'ARRAY' ||
        formValues.type.value === '' ||
        formValues.size.disabled
      ) && (
        <Field
          name="size"
          description={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.size')}
          value={formValues.size.value}
          onBlur={() => {
            setFormValues({
              type: ReducerActions.SHOW_FIELD_VALIDATION,
              field: 'size'
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFormValues({
              type: ReducerActions.UPDATE_FIELD,
              field: 'size',
              value: e.target.value.trim(),
              t
            })
          }}
          disabled={disabledFields.size}
          hasError={Boolean(
            formValues.size.displayFieldError && formValues.size.errorText
          )}
          errorText={
            formValues.size.displayFieldError && formValues.size.errorText
          }
        />
      )}
      {['NUMBER', 'FLOAT', 'NUMERIC'].includes(formValues.type.value) &&
        !formValues.decimalPlaces.disabled && (
          <Field
            name="decimalPlaces"
            description={t(
              'parameterEditor.NESTED_DATA_PICKER_EDITOR.decimalPlaces'
            )}
            value={formValues.decimalPlaces.value}
            onBlur={() => {
              setFormValues({
                type: ReducerActions.SHOW_FIELD_VALIDATION,
                field: 'decimalPlaces'
              })
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                type: ReducerActions.UPDATE_FIELD,
                field: 'decimalPlaces',
                value: e.target.value.trim(),
                t
              })
            }}
            disabled={disabledFields.decimalPlaces}
            hasError={Boolean(
              formValues.decimalPlaces.displayFieldError &&
                formValues.decimalPlaces.errorText
            )}
            errorText={
              formValues.decimalPlaces.displayFieldError &&
              formValues.decimalPlaces.errorText
            }
          />
        )}
      {!formValues.alias.disabled && (
        <Field
          name="alias"
          description={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.alias')}
          value={formValues.alias.value}
          onBlur={() => {
            setFormValues({
              type: ReducerActions.SHOW_FIELD_VALIDATION,
              field: 'alias'
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFormValues({
              type: ReducerActions.UPDATE_FIELD,
              field: 'alias',
              value: e.target.value.trim(),
              t
            })
          }}
          disabled={disabledFields.alias}
          hasError={Boolean(
            formValues.alias.displayFieldError && formValues.alias.errorText
          )}
          errorText={
            formValues.alias.displayFieldError && formValues.alias.errorText
          }
        />
      )}
      <div className={classes.ElementFormButtons}>
        <Button
          type="button"
          alt="secondary"
          text={t('common.cancel')}
          onClick={onCancel}
        />
        <Button
          type="submit"
          text={cta}
          onClick={handleSubmit}
          alt="positive"
        />
      </div>
    </form>
  )
}
