import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { componentNameParameter as componentNameParameterId } from 'job-lib/cisIds/knownComponentParameters'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { type ComponentInstance } from 'job-lib/types/Job'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { convertParameterValueToMetl } from 'modules/core/WorkingCopyProvider/effects/convertDPLPipelineToMETL/convertParametersToMetl'

interface UseComponentName {
  componentName?: string
  componentNameParameter?: ComponentParameter
}

export const useComponentName = (
  componentInstance: ComponentInstance,
  componentMetadata: ComponentMetadata
): UseComponentName => {
  const componentNameParameter = componentMetadata.parameters.find(
    (parameter) => parameter.dplID === componentNameParameterId
  )

  if (!componentNameParameter) return {}

  const parameterElements = isDPLParameterCollection(
    componentInstance.parameters
  )
    ? convertParameterValueToMetl(
        componentNameParameter,
        getParameterValue(componentInstance.parameters, [
          componentNameParameterId
        ])
      )
    : componentInstance.parameters?.[componentNameParameter.metlSlot]
        ?.elements ?? {}

  const parameterValues = Object.values(parameterElements).map(
    (el) => el.values[1]?.value
  )

  return {
    componentName: parameterValues[0],
    componentNameParameter
  }
}
