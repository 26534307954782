import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  SearchBar,
  type SortOpts
} from '@matillion/component-library'
import classNames from 'classnames'
import { debounce, orderBy } from 'lodash'

import {
  JobVariableType,
  type GridVariable,
  type JobVariable
} from 'job-lib/types/Variables'

import { SEARCH_DEBOUNCE_TIME_MS } from 'modules/ManageVariables/ManageVariables'
import { type SelectedJobVariable } from 'modules/ManageVariables/types'

import classes from '../Manage.module.scss'
import { TruncatedCell } from '../TruncatedCell'
import { ActionsCell } from './ActionsCell'

interface JobVariablesListProps {
  jobVariables: JobVariable[]
  onDelete: (arg: SelectedJobVariable) => void
  onEdit: (arg: SelectedJobVariable) => void
}

interface DataGridRow {
  id: string
  name: string
  description?: string
  dataType: string
  type: JobVariableType
  visibility: string
  defaultValue: string
}

type SortOrder = 'asc' | 'desc'
const isGridVariable = (variable: JobVariable): variable is GridVariable => {
  return Object.hasOwn(variable, 'values')
}
export const JobVariablesList = ({
  jobVariables,
  onDelete,
  onEdit
}: JobVariablesListProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortedColumn, setSortedColumn] = useState<Record<string, SortOpts>>({
    name: 'ASC'
  })

  const columns = [
    {
      key: 'name',
      title: t('manageVariables.jobVariables.fields.name'),
      sortable: true,
      as: TruncatedCell,
      mapValues: (row: DataGridRow) => ({
        text: row.name
      }),
      style: {
        paddingLeft: '8px'
      }
    },
    {
      key: 'dataType',
      title: t('manageVariables.jobVariables.fields.dataType'),
      sortable: true,
      style: {
        maxWidth: '108px'
      }
    },
    {
      key: 'visibility',
      title: t('manageVariables.jobVariables.fields.visibility'),
      sortable: true,
      style: {
        maxWidth: '50px'
      }
    },
    {
      key: 'defaultValue',
      title: t('manageVariables.jobVariables.fields.defaultValueColumn'),
      sortable: true,
      as: TruncatedCell,
      mapValues: (row: DataGridRow) => ({
        text: row.defaultValue
      }),
      style: {
        maxWidth: '48px'
      }
    },
    {
      key: 'actions',
      title: t('manageVariables.manage.actions'),
      className: classes.ManageVariables__ActionsColumn,
      as: ActionsCell,
      mapValues: (row: DataGridRow) => ({
        name: row.name,
        onDelete: () => {
          onDelete({ name: row.name, type: row.type })
        },
        onEdit: () => {
          onEdit({ name: row.name, type: row.type })
        }
      })
    }
  ]

  const rows = useMemo(() => {
    const allRows: DataGridRow[] = jobVariables.map((variable) => {
      const isGrid = isGridVariable(variable)
      const { definition } = variable

      const type = isGrid ? JobVariableType.GRID : variable.definition.type
      const defaultValue = isGrid
        ? variable.definition.definitions.map((x) => x.name).join(',')
        : variable.value

      return {
        id: `variable-${definition.name}`,
        name: definition.name,
        type,
        description: definition.description,
        dataType: t(`manageVariables.types.${type}`),
        visibility: t(`manageVariables.types.${definition.visibility}`),
        defaultValue
      }
    })

    return allRows.filter((row) => {
      return Object.values(row).some((content) =>
        content?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })
  }, [jobVariables, searchTerm, t])

  const sortedRows = useMemo(() => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    const column = selectedColumn as keyof DataGridRow
    const order = sortOrder.toLowerCase() as SortOrder

    return orderBy(rows, column, order)
  }, [rows, sortedColumn])

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearchTerm(e.target.value)
    }, SEARCH_DEBOUNCE_TIME_MS)
  }, [])

  return (
    <>
      <p className="u-visually-hidden" id="job-variables-table-caption">
        {t('manageVariables.manage.jobVariables')}
      </p>
      <div className={classes.ManageVariables__Search}>
        <SearchBar
          data-testid="search-for-job-variable-input"
          placeholder={t('common.search')}
          onChange={debouncedSearch}
        />
      </div>
      <DataGrid
        data-testid="job-variables-list"
        hasFixedHeader
        className={classNames(classes.ManageVariables__List, {
          [classes['ManageVariables__List--NoItems']]: rows.length === 0
        })}
        rowClassName={classes.ManageVariables__Row}
        columns={columns}
        rows={sortedRows}
        aria-labelledby="job-variables-table-caption"
        defaultSort={{ name: 'ASC' }}
        onSort={setSortedColumn}
        compact
      />
    </>
  )
}
