export const dataSourcesList = [
  { id: '15five', name: '15Five', targetConnectorId: '' },
  { id: '360learning', name: '360Learning', targetConnectorId: '' },
  { id: '7shifts', name: '7shifts', targetConnectorId: '' },
  { id: 'absorb-lms', name: 'Absorb LMS', targetConnectorId: '' },
  {
    id: 'activecampaign',
    name: 'ActiveCampaign',
    targetConnectorId: 'flex-activecampaign'
  },
  { id: 'ada', name: 'Ada', targetConnectorId: '' },
  { id: 'adjust', name: 'Adjust', targetConnectorId: '' },
  { id: 'adobe-analytics', name: 'Adobe Analytics', targetConnectorId: '' },
  { id: 'adobe-workfront', name: 'Adobe Workfront', targetConnectorId: '' },
  { id: 'adp-workforce-now', name: 'ADP Workforce Now', targetConnectorId: '' },
  { id: 'adroll', name: 'AdRoll', targetConnectorId: '' },
  { id: 'adyen', name: 'Adyen', targetConnectorId: '' },
  { id: 'affinity', name: 'Affinity', targetConnectorId: '' },
  { id: 'afterpay', name: 'Afterpay', targetConnectorId: '' },
  { id: 'aha', name: 'Aha!', targetConnectorId: '' },
  { id: 'aircall', name: 'Aircall', targetConnectorId: '' },
  { id: 'airtable', name: 'Airtable', targetConnectorId: '' },
  { id: 'alchemer', name: 'Alchemer', targetConnectorId: '' },
  { id: 'alpha-vantage', name: 'Alpha Vantage', targetConnectorId: '' },
  { id: 'amazon-ads', name: 'Amazon Ads', targetConnectorId: '' },
  {
    id: 'amazon-attribution',
    name: 'Amazon Attribution',
    targetConnectorId: ''
  },
  { id: 'amazon-cloudfront', name: 'Amazon CloudFront', targetConnectorId: '' },
  {
    id: 'amazon-documentdb',
    name: 'Amazon DocumentDB',
    targetConnectorId: 'mongodb-query'
  },
  {
    id: 'amazon-dynamodb',
    name: 'Amazon DynamoDB',
    targetConnectorId: 'dynamodb-query'
  },
  {
    id: 'amazon-kinesis-firehose',
    name: 'Amazon Kinesis Firehose',
    targetConnectorId: ''
  },
  {
    id: 'amazon-redshift',
    name: 'Amazon Redshift',
    targetConnectorId: 'database-query'
  },
  { id: 'amazon-s3', name: 'Amazon S3', targetConnectorId: 's3-load' },
  {
    id: 'amazon-selling-partner',
    name: 'Amazon Selling Partner',
    targetConnectorId: ''
  },
  { id: 'amazon-sqs', name: 'Amazon SQS', targetConnectorId: 'sqs-message' },
  { id: 'amplitude', name: 'Amplitude', targetConnectorId: 'flex-amplitude' },
  {
    id: 'anaplan',
    name: 'Anaplan',
    targetConnectorId: 'modular-anaplan-input-v1'
  },
  { id: 'anvyl', name: 'Anvyl', targetConnectorId: '' },
  { id: 'apache-kafka', name: 'Apache Kafka', targetConnectorId: '' },
  { id: 'apify-dataset', name: 'Apify Dataset', targetConnectorId: '' },
  { id: 'appcues', name: 'Appcues', targetConnectorId: '' },
  { id: 'appfigures', name: 'Appfigures', targetConnectorId: '' },
  { id: 'appfollow', name: 'Appfollow', targetConnectorId: '' },
  { id: 'apple-app-store', name: 'Apple App Store', targetConnectorId: '' },
  { id: 'apple-search-ads', name: 'Apple Search Ads', targetConnectorId: '' },
  { id: 'appsflyer', name: 'AppsFlyer', targetConnectorId: '' },
  { id: 'asana', name: 'Asana', targetConnectorId: 'flex-asana' },
  { id: 'ashby', name: 'Ashby', targetConnectorId: '' },
  { id: 'assembled', name: 'Assembled', targetConnectorId: '' },
  { id: 'attentive', name: 'Attentive', targetConnectorId: '' },
  { id: 'aumni', name: 'Aumni', targetConnectorId: '' },
  { id: 'aurora-mysql', name: 'Aurora MySQL', targetConnectorId: '' },
  { id: 'aurora-postgresql', name: 'Aurora PostgreSQL', targetConnectorId: '' },
  { id: 'auth0', name: 'Auth0', targetConnectorId: '' },
  { id: 'avni', name: 'Avni', targetConnectorId: '' },
  { id: 'awin', name: 'Awin', targetConnectorId: '' },
  { id: 'aws-cloudtrail', name: 'AWS CloudTrail', targetConnectorId: '' },
  { id: 'aws-inventory', name: 'AWS Inventory', targetConnectorId: '' },
  { id: 'aws-lambda', name: 'AWS Lambda', targetConnectorId: '' },
  { id: 'aws-msk', name: 'AWS MSK', targetConnectorId: '' },
  {
    id: 'azure-blob-storage',
    name: 'Azure Blob Storage',
    targetConnectorId: 'azure-blob-storage-load'
  },
  {
    id: 'azure-cosmos-db-for-nosql',
    name: 'Azure Cosmos DB for NoSQL',
    targetConnectorId: 'flex-azure-cosmos-db-for-nosql'
  },
  { id: 'azure-devops', name: 'Azure DevOps', targetConnectorId: '' },
  {
    id: 'azure-sql',
    name: 'Azure SQL',
    targetConnectorId: 'modular-azure-sql-input-v0'
  },
  { id: 'babelforce', name: 'Babelforce', targetConnectorId: '' },
  { id: 'ballotready', name: 'BallotReady', targetConnectorId: '' },
  { id: 'bamboo-hr', name: 'Bamboo HR', targetConnectorId: '' },
  { id: 'bigcommerce', name: 'BigCommerce', targetConnectorId: '' },
  { id: 'bing-ads', name: 'Bing Ads', targetConnectorId: 'bing-ads-query' },
  { id: 'birdeye', name: 'Birdeye', targetConnectorId: '' },
  { id: 'bitly', name: 'Bitly', targetConnectorId: '' },
  { id: 'boostr', name: 'Boostr', targetConnectorId: '' },
  { id: 'box', name: 'Box', targetConnectorId: 'flex-box' },
  { id: 'braintree', name: 'Braintree', targetConnectorId: '' },
  { id: 'braintree-sandbox', name: 'Braintree Sandbox', targetConnectorId: '' },
  { id: 'branch', name: 'Branch', targetConnectorId: '' },
  { id: 'brave-ads', name: 'Brave Ads', targetConnectorId: '' },
  { id: 'braze', name: 'Braze', targetConnectorId: 'flex-braze' },
  { id: 'breezometer', name: 'Breezometer', targetConnectorId: '' },
  { id: 'brevo', name: 'Brevo', targetConnectorId: 'flex-brevo' },
  { id: 'brex', name: 'Brex', targetConnectorId: '' },
  { id: 'brightcove', name: 'Brightcove', targetConnectorId: '' },
  { id: 'bugcrowd', name: 'Bugcrowd', targetConnectorId: 'flex-bugcrowd' },
  { id: 'buildkite', name: 'Buildkite', targetConnectorId: '' },
  { id: 'buzzsprout', name: 'Buzzsprout', targetConnectorId: '' },
  { id: 'calabrio', name: 'Calabrio', targetConnectorId: '' },
  { id: 'calendly', name: 'Calendly', targetConnectorId: '' },
  { id: 'callrail', name: 'CallRail', targetConnectorId: '' },
  { id: 'campaign-monitor', name: 'Campaign Manager', targetConnectorId: '' },
  { id: 'canny', name: 'Canny', targetConnectorId: '' },
  { id: 'captain-data', name: 'Captain Data', targetConnectorId: '' },
  {
    id: 'care-quality-commission',
    name: 'Care Quality Commission',
    targetConnectorId: ''
  },
  { id: 'cartcom', name: 'Cart.com', targetConnectorId: '' },
  { id: 'chameleon', name: 'Chameleon', targetConnectorId: '' },
  { id: 'chargebee', name: 'Chargebee', targetConnectorId: 'flex-chargebee' },
  { id: 'chargedesk', name: 'ChargeDesk', targetConnectorId: '' },
  { id: 'chargify', name: 'Chargify', targetConnectorId: '' },
  { id: 'chartmogul', name: 'ChartMogul', targetConnectorId: '' },
  { id: 'checkoutcom', name: 'Checkout.com', targetConnectorId: '' },
  { id: 'checkr', name: 'Checkr', targetConnectorId: '' },
  { id: 'chorus', name: 'Chorus', targetConnectorId: '' },
  { id: 'churnkey', name: 'Churnkey', targetConnectorId: '' },
  { id: 'churnzero', name: 'ChurnZero', targetConnectorId: '' },
  { id: 'cin7', name: 'Cin7', targetConnectorId: '' },
  { id: 'circleci', name: 'CircleCI', targetConnectorId: 'flex-circleci' },
  { id: 'clari', name: 'Clari', targetConnectorId: '' },
  { id: 'clazar', name: 'Clazar', targetConnectorId: '' },
  { id: 'clickhouse', name: 'ClickHouse', targetConnectorId: '' },
  { id: 'clickup', name: 'ClickUp', targetConnectorId: 'flex-clickup' },
  { id: 'clockify', name: 'Clockify', targetConnectorId: '' },
  { id: 'close', name: 'Close', targetConnectorId: '' },
  { id: 'closecom', name: 'Close.com', targetConnectorId: '' },
  { id: 'cockroachdb', name: 'Cockroachdb', targetConnectorId: '' },
  { id: 'coda', name: 'Coda', targetConnectorId: '' },
  { id: 'codefresh', name: 'Codefresh', targetConnectorId: '' },
  { id: 'coin-api', name: 'Coin API', targetConnectorId: '' },
  { id: 'coingecko-coins', name: 'CoinGecko Coins', targetConnectorId: '' },
  { id: 'coinmarketcap', name: 'CoinMarketCap', targetConnectorId: '' },
  { id: 'collibra', name: 'Collibra', targetConnectorId: '' },
  { id: 'column', name: 'Column', targetConnectorId: '' },
  { id: 'commcare', name: 'Commcare', targetConnectorId: '' },
  { id: 'commercetools', name: 'Commercetools', targetConnectorId: '' },
  { id: 'concord', name: 'Concord', targetConnectorId: 'flex-concord' },
  { id: 'configcat', name: 'ConfigCat', targetConnectorId: '' },
  {
    id: 'confluence',
    name: 'Confluence',
    targetConnectorId: 'flex-confluence'
  },
  { id: 'confluent-cloud', name: 'Confluent Cloud', targetConnectorId: '' },
  { id: 'convertkit', name: 'ConvertKit', targetConnectorId: '' },
  { id: 'convex', name: 'Convex', targetConnectorId: '' },
  { id: 'copper', name: 'Copper', targetConnectorId: '' },
  { id: 'coupa', name: 'Coupa', targetConnectorId: '' },
  { id: 'criteo', name: 'Criteo', targetConnectorId: '' },
  { id: 'crossbeam', name: 'Crossbeam', targetConnectorId: '' },
  { id: 'crowddev', name: 'crowd.dev', targetConnectorId: '' },
  { id: 'culture-amp', name: 'Culture Amp', targetConnectorId: '' },
  { id: 'customerio', name: 'Customer.io', targetConnectorId: '' },
  { id: 'cvent', name: 'Cvent', targetConnectorId: '' },
  {
    id: 'databricks',
    name: 'Databricks',
    targetConnectorId: 'modular-databricks-input-v0'
  },
  { id: 'datadog', name: 'Datadog', targetConnectorId: 'flex-datadog' },
  { id: 'datascope', name: 'Datascope', targetConnectorId: '' },
  { id: 'dbt-cloud', name: 'dbt Cloud', targetConnectorId: 'flex-dbt-cloud' },
  { id: 'dcl-logistics', name: 'DCL Logistics', targetConnectorId: '' },
  { id: 'delighted', name: 'Delighted', targetConnectorId: 'flex-delighted' },
  { id: 'deputy', name: 'Deputy', targetConnectorId: '' },
  { id: 'destini', name: 'Destini', targetConnectorId: '' },
  { id: 'dixa', name: 'Dixa', targetConnectorId: 'flex-dixa' },
  { id: 'docebo', name: 'Docebo', targetConnectorId: '' },
  { id: 'dockerhub', name: 'Dockerhub', targetConnectorId: '' },
  { id: 'doorloop', name: 'DoorLoop', targetConnectorId: '' },
  { id: 'dremio', name: 'Dremio', targetConnectorId: '' },
  { id: 'drift', name: 'Drift', targetConnectorId: '' },
  { id: 'drip', name: 'Drip', targetConnectorId: '' },
  { id: 'dropbox', name: 'Dropbox', targetConnectorId: 'flex-dropbox' },
  { id: 'dsv', name: 'DSV', targetConnectorId: '' },
  { id: 'duoplane', name: 'Duoplane', targetConnectorId: '' },
  {
    id: 'dynamics-365',
    name: 'Dynamics 365',
    targetConnectorId: 'dynamics-365-query'
  },
  { id: 'dynamodb', name: 'DynamoDB', targetConnectorId: 'dynamodb-query' },
  { id: 'e2e-testing', name: 'E2E Testing', targetConnectorId: '' },
  { id: 'easypost', name: 'EasyPost', targetConnectorId: '' },
  { id: 'ebay', name: 'eBay', targetConnectorId: '' },
  { id: 'elastic-cloud', name: 'Elastic Cloud', targetConnectorId: '' },
  { id: 'elasticsearch', name: 'Elasticsearch', targetConnectorId: '' },
  {
    id: 'electronic-tenant-solutions',
    name: 'Electronic Tenant Solutions',
    targetConnectorId: ''
  },
  { id: 'eloqua', name: 'Eloqua', targetConnectorId: '' },
  { id: 'email', name: 'Email', targetConnectorId: 'email-query' },
  { id: 'emailoctopus', name: 'EmailOctopus', targetConnectorId: '' },
  { id: 'employment-hero', name: 'Employment Hero', targetConnectorId: '' },
  {
    id: 'eventbrite',
    name: 'Eventbrite',
    targetConnectorId: 'flex-eventbrite'
  },
  { id: 'eventsforce', name: 'Eventsforce', targetConnectorId: '' },
  { id: 'everhour', name: 'Everhour', targetConnectorId: '' },
  { id: 'excel', name: 'Excel', targetConnectorId: 'excel-query' },
  {
    id: 'exchange-rates-api',
    name: 'Exchange Rates API',
    targetConnectorId: ''
  },
  { id: 'expensify', name: 'Expensify', targetConnectorId: '' },
  { id: 'ezofficeinventory', name: 'EZOfficeInventory', targetConnectorId: '' },
  {
    id: 'facebook-ads',
    name: 'Facebook Ads',
    targetConnectorId: 'facebook-ads-query'
  },
  { id: 'facebook', name: 'Facebook', targetConnectorId: 'facebook-query' },
  { id: 'factorial', name: 'Factorial', targetConnectorId: '' },
  { id: 'fastbill', name: 'Fastbill', targetConnectorId: '' },
  { id: 'fauna', name: 'Fauna', targetConnectorId: '' },
  { id: 'firebase', name: 'Firebase', targetConnectorId: '' },
  { id: 'firebolt', name: 'Firebolt', targetConnectorId: '' },
  {
    id: 'firehydrant',
    name: 'FireHydrant',
    targetConnectorId: 'flex-firehydrant'
  },
  { id: 'fleetio', name: 'Fleetio', targetConnectorId: '' },
  { id: 'flexport', name: 'Flexport', targetConnectorId: '' },
  { id: 'float', name: 'Float', targetConnectorId: 'flex-float' },
  { id: 'fone-dynamics', name: 'Fone Dynamics', targetConnectorId: '' },
  { id: 'forj-community', name: 'Forj Community', targetConnectorId: '' },
  { id: 'fountain', name: 'Fountain', targetConnectorId: '' },
  { id: 'freightview', name: 'Freightview', targetConnectorId: '' },
  { id: 'freshbooks', name: 'FreshBooks', targetConnectorId: '' },
  { id: 'freshcaller', name: 'Freshcaller', targetConnectorId: '' },
  { id: 'freshchat', name: 'Freshchat', targetConnectorId: '' },
  { id: 'freshdesk', name: 'Freshdesk', targetConnectorId: 'flex-freshdesk' },
  { id: 'freshsales', name: 'Freshsales', targetConnectorId: '' },
  { id: 'freshservice', name: 'Freshservice', targetConnectorId: '' },
  { id: 'freshteam', name: 'FreshTeam', targetConnectorId: '' },
  { id: 'friendbuy', name: 'Friendbuy', targetConnectorId: '' },
  { id: 'front', name: 'Front', targetConnectorId: '' },
  { id: 'ftp', name: 'FTP', targetConnectorId: 'data-transfer-object' },
  { id: 'fullstory', name: 'FullStory', targetConnectorId: '' },
  { id: 'gainsight', name: 'Gainsight', targetConnectorId: '' },
  { id: 'gem', name: 'Gem', targetConnectorId: '' },
  { id: 'genesys', name: 'Genesys', targetConnectorId: '' },
  { id: 'getfeedback', name: 'GetFeedback', targetConnectorId: '' },
  { id: 'github', name: 'GitHub', targetConnectorId: 'flex-github' },
  { id: 'gitlab', name: 'GitLab', targetConnectorId: '' },
  { id: 'gladly', name: 'Gladly', targetConnectorId: '' },
  { id: 'glassfrog', name: 'Glassfrog', targetConnectorId: '' },
  { id: 'globalmeet', name: 'GlobalMeet', targetConnectorId: '' },
  { id: 'gmail', name: 'Gmail', targetConnectorId: 'modular-gmail-input-v0' },
  { id: 'gnews', name: 'GNews', targetConnectorId: '' },
  { id: 'gocardless', name: 'GoCardless', targetConnectorId: '' },
  { id: 'goldcast', name: 'Goldcast', targetConnectorId: '' },
  { id: 'gong', name: 'Gong', targetConnectorId: 'flex-gong' },
  { id: 'google-ads', name: 'Google Ads', targetConnectorId: 'google-ads-qry' },
  {
    id: 'google-analytics-4',
    name: 'Google Analytics 4',
    targetConnectorId: 'modular-google-analytics-input-v0'
  },
  {
    id: 'google-bigquery',
    name: 'Google BigQuery',
    targetConnectorId: 'google-bigquery'
  },
  {
    id: 'google-business-profile',
    name: 'Google Business Profile',
    targetConnectorId: ''
  },
  { id: 'google-calendar', name: 'Google Calendar', targetConnectorId: '' },
  {
    id: 'google-campaign-manager-360',
    name: 'Google Campaign Manager 360',
    targetConnectorId: ''
  },
  {
    id: 'google-cloud-storage',
    name: 'Google Cloud Storage',
    targetConnectorId: 'gcs-load'
  },
  { id: 'google-drive', name: 'Google Drive', targetConnectorId: '' },
  {
    id: 'google-search-console',
    name: 'Google Search Console',
    targetConnectorId: ''
  },
  {
    id: 'google-sheets',
    name: 'Google Sheets',
    targetConnectorId: 'modular-google-sheets-input-v0'
  },
  { id: 'google-webfonts', name: 'Google Webfonts', targetConnectorId: '' },
  { id: 'gorgias', name: 'Gorgias', targetConnectorId: '' },
  { id: 'greenhouse', name: 'Greenhouse', targetConnectorId: '' },
  { id: 'gridly', name: 'Gridly', targetConnectorId: '' },
  { id: 'grin', name: 'GRIN', targetConnectorId: '' },
  { id: 'guru', name: 'Guru', targetConnectorId: '' },
  { id: 'gutendex', name: 'Gutendex', targetConnectorId: '' },
  { id: 'happyfox', name: 'HappyFox', targetConnectorId: '' },
  { id: 'hardcoded-records', name: 'Hardcoded Records', targetConnectorId: '' },
  { id: 'harness', name: 'Harness', targetConnectorId: '' },
  { id: 'harvest', name: 'Harvest', targetConnectorId: '' },
  { id: 'heap', name: 'Heap', targetConnectorId: '' },
  { id: 'height', name: 'Height', targetConnectorId: '' },
  { id: 'hellobaton', name: 'Hellobaton', targetConnectorId: '' },
  { id: 'help-scout', name: 'Help Scout', targetConnectorId: '' },
  { id: 'helpshift', name: 'Helpshift', targetConnectorId: '' },
  { id: 'heroku-kafka', name: 'Heroku Kafka', targetConnectorId: '' },
  { id: 'heroku-postgres', name: 'Heroku Postgres', targetConnectorId: '' },
  { id: 'hibob', name: 'HiBob', targetConnectorId: '' },
  { id: 'hopin', name: 'Hopin', targetConnectorId: '' },
  { id: 'http', name: 'HTTP', targetConnectorId: 'data-transfer-object' },
  { id: 'https', name: 'HTTPS', targetConnectorId: 'data-transfer-object' },
  { id: 'hubplanner', name: 'Hubplanner', targetConnectorId: '' },
  { id: 'hubspot', name: 'HubSpot', targetConnectorId: 'hubspot-query' },
  { id: 'ibm-db2', name: 'IBM Db2', targetConnectorId: 'database-query' },
  { id: 'ilevel', name: 'iLevel', targetConnectorId: '' },
  { id: 'impact', name: 'Impact', targetConnectorId: '' },
  { id: 'incidentio', name: 'Incident.io', targetConnectorId: '' },
  { id: 'infobip', name: 'Infobip', targetConnectorId: 'flex-infobip' },
  { id: 'insightly', name: 'Insightly', targetConnectorId: '' },
  { id: 'instagram', name: 'Instagram', targetConnectorId: 'flex-instagram' },
  {
    id: 'instagram-business',
    name: 'Instagram Business',
    targetConnectorId: ''
  },
  { id: 'instatus', name: 'Instatus', targetConnectorId: '' },
  {
    id: 'integral-ad-science',
    name: 'Integral Ad Science',
    targetConnectorId: ''
  },
  { id: 'integrate', name: 'Integrate', targetConnectorId: '' },
  { id: 'intercom', name: 'Intercom', targetConnectorId: 'flex-intercom' },
  { id: 'intruder', name: 'Intruder', targetConnectorId: '' },
  { id: 'ip2whois', name: 'IP2Whois', targetConnectorId: '' },
  { id: 'ironclad', name: 'Ironclad', targetConnectorId: '' },
  { id: 'ironsource', name: 'ironSource', targetConnectorId: '' },
  { id: 'iterable', name: 'Iterable', targetConnectorId: '' },
  { id: 'ivanti', name: 'Ivanti', targetConnectorId: '' },
  { id: 'jenkins', name: 'Jenkins', targetConnectorId: '' },
  { id: 'jina-ai-reader', name: 'Jina AI Reader', targetConnectorId: '' },
  { id: 'jira', name: 'Jira', targetConnectorId: 'modular-jira-input-v0' },
  { id: 'jotform', name: 'Jotform', targetConnectorId: 'flex-jotform' },
  { id: 'justcall', name: 'JustCall', targetConnectorId: '' },
  { id: 'k6-cloud', name: 'K6 Cloud', targetConnectorId: '' },
  { id: 'kafka', name: 'Kafka', targetConnectorId: '' },
  { id: 'kandji', name: 'Kandji', targetConnectorId: '' },
  { id: 'kantata', name: 'Kantata', targetConnectorId: '' },
  { id: 'katana', name: 'Katana', targetConnectorId: '' },
  { id: 'kevel', name: 'Kevel', targetConnectorId: '' },
  { id: 'keypay', name: 'KeyPay', targetConnectorId: '' },
  { id: 'khoros-care', name: 'Khoros Care', targetConnectorId: '' },
  { id: 'kisi', name: 'Kisi', targetConnectorId: '' },
  { id: 'kissmetrics', name: 'Kissmetrics', targetConnectorId: '' },
  { id: 'klarna', name: 'Klarna', targetConnectorId: '' },
  { id: 'klaus-api', name: 'Klaus API', targetConnectorId: '' },
  { id: 'klaviyo', name: 'Klaviyo', targetConnectorId: 'flex-klaviyo' },
  { id: 'kustomer', name: 'Kustomer', targetConnectorId: '' },
  { id: 'kyriba', name: 'Kyriba', targetConnectorId: '' },
  { id: 'kyve', name: 'KYVE', targetConnectorId: '' },
  { id: 'lago', name: 'Lago', targetConnectorId: '' },
  { id: 'lattice', name: 'Lattice', targetConnectorId: '' },
  {
    id: 'launchdarkly',
    name: 'LaunchDarkly',
    targetConnectorId: 'flex-launchdarkly'
  },
  { id: 'ldap', name: 'LDAP', targetConnectorId: 'ldap-query' },
  { id: 'learnupon', name: 'LearnUpon', targetConnectorId: '' },
  { id: 'lemlist', name: 'Lemlist', targetConnectorId: '' },
  { id: 'lessonly', name: 'Lessonly', targetConnectorId: '' },
  { id: 'lever', name: 'Lever', targetConnectorId: '' },
  { id: 'lever-hiring', name: 'Lever Hiring', targetConnectorId: '' },
  { id: 'liftoff', name: 'Liftoff', targetConnectorId: '' },
  { id: 'lightspeed-retail', name: 'Lightspeed Retail', targetConnectorId: '' },
  { id: 'linear', name: 'Linear', targetConnectorId: '' },
  { id: 'linkedin-ads', name: 'LinkedIn Ads', targetConnectorId: '' },
  {
    id: 'linkedin-company-pages',
    name: 'LinkedIn Company Pages',
    targetConnectorId: ''
  },
  { id: 'linkedin-pages', name: 'Linkedin Pages', targetConnectorId: '' },
  { id: 'linksquares', name: 'LinkSquares', targetConnectorId: '' },
  { id: 'linnworks', name: 'Linnworks', targetConnectorId: '' },
  { id: 'livechat-partner', name: 'LiveChat Partner', targetConnectorId: '' },
  { id: 'lob', name: 'Lob', targetConnectorId: 'ldap-query' },
  { id: 'lokalise', name: 'Lokalise', targetConnectorId: '' },
  { id: 'looker', name: 'Looker', targetConnectorId: '' },
  { id: 'loop', name: 'Loop', targetConnectorId: '' },
  { id: 'loopio', name: 'Loopio', targetConnectorId: '' },
  { id: 'luma', name: 'Luma', targetConnectorId: '' },
  { id: 'magento-mysql', name: 'Magento MySQL', targetConnectorId: '' },
  {
    id: 'mailchimp',
    name: 'Mailchimp',
    targetConnectorId: 'modular-mailchimp-input-v0'
  },
  { id: 'mailer', name: 'Mailer', targetConnectorId: '' },
  { id: 'mailersend', name: 'MailerSend', targetConnectorId: '' },
  { id: 'mailgun', name: 'Mailgun', targetConnectorId: 'flex-mailgun' },
  { id: 'mailjet-mail', name: 'Mailjet Mail', targetConnectorId: '' },
  { id: 'mambu', name: 'Mambu', targetConnectorId: '' },
  { id: 'mandrill', name: 'Mandrill', targetConnectorId: 'flex-mandrill' },
  { id: 'mariadb', name: 'MariaDB', targetConnectorId: 'database-query' },
  { id: 'marin-software', name: 'Marin Software', targetConnectorId: '' },
  { id: 'marketo', name: 'Marketo', targetConnectorId: 'marketo-query' },
  { id: 'maxio-chargify', name: 'Maxio Chargify', targetConnectorId: '' },
  { id: 'medallia', name: 'Medallia', targetConnectorId: '' },
  {
    id: 'medallia-agile-research',
    name: 'Medallia Agile Research',
    targetConnectorId: ''
  },
  { id: 'megaphone', name: 'Megaphone', targetConnectorId: '' },
  { id: 'mention', name: 'Mention', targetConnectorId: '' },
  { id: 'merge', name: 'Merge', targetConnectorId: '' },
  { id: 'metabase', name: 'Metabase', targetConnectorId: '' },
  {
    id: 'microsoft-advertising',
    name: 'Microsoft Advertising',
    targetConnectorId: ''
  },
  {
    id: 'microsoft-dataverse',
    name: 'Microsoft Dataverse',
    targetConnectorId: ''
  },
  {
    id: 'microsoft-dynamics-365',
    name: 'Microsoft Dynamics 365',
    targetConnectorId: 'dynamics-365-query'
  },
  {
    id: 'microsoft-exchange',
    name: 'Microsoft Exchange',
    targetConnectorId: 'modular-microsoft-exchange-input-v0'
  },
  { id: 'microsoft-lists', name: 'Microsoft Lists', targetConnectorId: '' },
  {
    id: 'microsoft-onedrive',
    name: 'Microsoft OneDrive',
    targetConnectorId: ''
  },
  {
    id: 'microsoft-sharepoint',
    name: 'Microsoft SharePoint',
    targetConnectorId: 'sharepoint-query'
  },
  {
    id: 'microsoft-sql-server',
    name: 'Microsoft SQL Server',
    targetConnectorId: 'database-query'
  },
  {
    id: 'microsoft-sql-server-mssql',
    name: 'Microsoft SQL Server (MSSQL)',
    targetConnectorId: 'microsoft-sql-server-output'
  },
  { id: 'microsoft-teams', name: 'Microsoft Teams', targetConnectorId: '' },
  { id: 'mixmax', name: 'Mixmax', targetConnectorId: '' },
  { id: 'mixpanel', name: 'Mixpanel', targetConnectorId: 'flex-mixpanel' },
  { id: 'mntn', name: 'MNTN', targetConnectorId: '' },
  { id: 'mode', name: 'Mode', targetConnectorId: '' },
  { id: 'moloco', name: 'Moloco', targetConnectorId: '' },
  { id: 'mondaycom', name: 'Monday.com', targetConnectorId: '' },
  { id: 'mongodb', name: 'MongoDB', targetConnectorId: 'mongodb-query' },
  { id: 'mux', name: 'Mux', targetConnectorId: '' },
  { id: 'my-hours', name: 'My Hours', targetConnectorId: '' },
  { id: 'mysql', name: 'MySQL', targetConnectorId: '' },
  { id: 'mysql-rds', name: 'MySQL RDS', targetConnectorId: 'rds-query' },
  { id: 'n8n', name: 'n8n', targetConnectorId: '' },
  { id: 'namely', name: 'Namely', targetConnectorId: '' },
  { id: 'nasa', name: 'Nasa', targetConnectorId: '' },
  { id: 'navan', name: 'Navan', targetConnectorId: '' },
  { id: 'netsuite', name: 'Netsuite', targetConnectorId: 'netsuite-query' },
  { id: 'new-relic', name: 'New Relic', targetConnectorId: 'flex-new-relic' },
  { id: 'new-york-times', name: 'New York Times', targetConnectorId: '' },
  { id: 'news-api', name: 'News API', targetConnectorId: '' },
  { id: 'newsdata', name: 'Newsdata', targetConnectorId: '' },
  { id: 'northpass-lms', name: 'Northpass LMS', targetConnectorId: '' },
  { id: 'notion', name: 'Notion', targetConnectorId: '' },
  { id: 'odata', name: 'OData', targetConnectorId: 'modular-odata-input-v0' },
  { id: 'okta', name: 'Okta', targetConnectorId: '' },
  { id: 'ometria', name: 'Ometria', targetConnectorId: '' },
  { id: 'omnisend', name: 'Omnisend', targetConnectorId: '' },
  { id: 'on24', name: 'ON24', targetConnectorId: '' },
  { id: 'onedrive', name: 'OneDrive', targetConnectorId: '' },
  { id: 'onesignal', name: 'OneSignal', targetConnectorId: '' },
  {
    id: 'ongoing-wms',
    name: 'Ongoing WMS',
    targetConnectorId: 'flex-ongoing-wms'
  },
  { id: 'open-data-dc', name: 'Open Data DC', targetConnectorId: '' },
  { id: 'open-distro', name: 'Open Distro', targetConnectorId: '' },
  {
    id: 'open-exchange-rates',
    name: 'Open Exchange Rates',
    targetConnectorId: ''
  },
  { id: 'opensearch', name: 'Opensearch', targetConnectorId: '' },
  { id: 'openweather', name: 'Openweather', targetConnectorId: '' },
  { id: 'opsgenie', name: 'Opsgenie', targetConnectorId: '' },
  { id: 'optimizely', name: 'Optimizely', targetConnectorId: '' },
  { id: 'oracle', name: 'Oracle', targetConnectorId: 'database-query' },
  { id: 'orb', name: 'Orb', targetConnectorId: '' },
  { id: 'orbit', name: 'Orbit', targetConnectorId: 'flex-orbit' },
  { id: 'ortto', name: 'Ortto', targetConnectorId: 'flex-ortto' },
  { id: 'oura', name: 'Oura', targetConnectorId: '' },
  { id: 'outbrain', name: 'Outbrain', targetConnectorId: '' },
  { id: 'outreach', name: 'Outreach', targetConnectorId: '' },
  { id: 'pagerduty', name: 'PagerDuty', targetConnectorId: 'flex-pagerduty' },
  { id: 'papershift', name: 'Papershift', targetConnectorId: '' },
  { id: 'pardot', name: 'Pardot', targetConnectorId: '' },
  { id: 'partnerize', name: 'Partnerize', targetConnectorId: '' },
  { id: 'partnerstack', name: 'PartnerStack', targetConnectorId: '' },
  { id: 'paychex', name: 'Paychex', targetConnectorId: '' },
  { id: 'paypal', name: 'PayPal', targetConnectorId: 'flex-paypal' },
  { id: 'paystack', name: 'Paystack', targetConnectorId: '' },
  { id: 'pendo', name: 'Pendo', targetConnectorId: 'flex-pendo' },
  { id: 'persistiq', name: 'PersistIq', targetConnectorId: '' },
  { id: 'persona', name: 'Persona', targetConnectorId: 'flex-persona' },
  { id: 'personio', name: 'Personio', targetConnectorId: '' },
  { id: 'pexels-api', name: 'Pexels API', targetConnectorId: '' },
  { id: 'picqer', name: 'Picqer', targetConnectorId: '' },
  { id: 'pingdom', name: 'Pingdom', targetConnectorId: 'flex-pingdom' },
  { id: 'pinpoint', name: 'Pinpoint', targetConnectorId: '' },
  { id: 'pinterest', name: 'Pinterest', targetConnectorId: '' },
  { id: 'pinterest-ads', name: 'Pinterest Ads', targetConnectorId: '' },
  { id: 'pipedrive', name: 'Pipedrive', targetConnectorId: 'flex-pipedrive' },
  {
    id: 'pivotal-tracker',
    name: 'Pivotal Tracker',
    targetConnectorId: 'flex-pivotaltracker'
  },
  { id: 'piwik-pro', name: 'Piwik PRO', targetConnectorId: '' },
  { id: 'plaid', name: 'Plaid', targetConnectorId: '' },
  { id: 'plausible', name: 'Plausible', targetConnectorId: '' },
  { id: 'playvox', name: 'Playvox', targetConnectorId: '' },
  { id: 'pocket', name: 'Pocket', targetConnectorId: '' },
  { id: 'pokeapi', name: 'PokeAPI', targetConnectorId: '' },
  { id: 'polygon-stock-api', name: 'Polygon Stock API', targetConnectorId: '' },
  { id: 'postgres', name: 'Postgres', targetConnectorId: '' },
  { id: 'postgresql', name: 'PostgreSQL', targetConnectorId: 'database-query' },
  {
    id: 'postgresql-rds',
    name: 'PostgreSQL RDS',
    targetConnectorId: 'rds-query'
  },
  { id: 'posthog', name: 'PostHog', targetConnectorId: '' },
  { id: 'postmark', name: 'Postmark', targetConnectorId: '' },
  {
    id: 'powerreviews-enterprise',
    name: 'PowerReviews Enterprise',
    targetConnectorId: ''
  },
  { id: 'prestashop', name: 'PrestaShop', targetConnectorId: '' },
  { id: 'primetric', name: 'Primetric', targetConnectorId: '' },
  { id: 'prive', name: 'Prive', targetConnectorId: '' },
  {
    id: 'productboard',
    name: 'Productboard',
    targetConnectorId: 'flex-productboard'
  },
  {
    id: 'proofpoint-security-awareness',
    name: 'Proofpoint Security Awareness',
    targetConnectorId: ''
  },
  { id: 'public-apis', name: 'Public APIs', targetConnectorId: '' },
  { id: 'pypi', name: 'PyPI', targetConnectorId: '' },
  { id: 'qualaroo', name: 'Qualaroo', targetConnectorId: '' },
  { id: 'qualtrics', name: 'Qualtrics', targetConnectorId: '' },
  { id: 'quickbooks', name: 'QuickBooks', targetConnectorId: '' },
  { id: 'quora-ads', name: 'Quora Ads', targetConnectorId: '' },
  { id: 'railz', name: 'Railz', targetConnectorId: '' },
  {
    id: 'rakuten-advertising',
    name: 'Rakuten Advertising',
    targetConnectorId: ''
  },
  { id: 'ramp', name: 'Ramp', targetConnectorId: '' },
  { id: 'rarible', name: 'Rarible', targetConnectorId: '' },
  {
    id: 'rd-station-marketing',
    name: 'RD Station Marketing',
    targetConnectorId: ''
  },
  { id: 'rebound-returns', name: 'ReBound Returns', targetConnectorId: '' },
  { id: 'recharge', name: 'Recharge', targetConnectorId: '' },
  { id: 'recreation', name: 'Recreation', targetConnectorId: '' },
  { id: 'recruitee', name: 'Recruitee', targetConnectorId: '' },
  { id: 'recurly', name: 'Recurly', targetConnectorId: 'flex-recurly' },
  { id: 'reddit-ads', name: 'Reddit Ads', targetConnectorId: '' },
  {
    id: 'redshift',
    name: 'Redshift',
    targetConnectorId: 'modular-redshift-input-v0'
  },
  { id: 'referralhero', name: 'ReferralHero', targetConnectorId: '' },
  { id: 'replyio', name: 'Reply.io', targetConnectorId: '' },
  { id: 'rest-api', name: 'REST API', targetConnectorId: '' },
  { id: 'retailnext', name: 'RetailNext', targetConnectorId: '' },
  { id: 'retently', name: 'Retently', targetConnectorId: '' },
  { id: 'revel-systems', name: 'Revel Systems', targetConnectorId: '' },
  { id: 'reviewsio', name: 'REVIEWS.io', targetConnectorId: '' },
  { id: 'ricochet360', name: 'Ricochet360', targetConnectorId: '' },
  { id: 'ringcentral', name: 'RingCentral', targetConnectorId: '' },
  { id: 'ringover', name: 'Ringover', targetConnectorId: '' },
  { id: 'rippling', name: 'Rippling', targetConnectorId: '' },
  { id: 'rithum', name: 'Rithum', targetConnectorId: '' },
  { id: 'rki-covid', name: 'RKI Covid', targetConnectorId: '' },
  { id: 'rocketchat', name: 'Rocket.chat', targetConnectorId: '' },
  { id: 'rocketlane', name: 'Rocketlane', targetConnectorId: '' },
  { id: 'rollbar', name: 'Rollbar', targetConnectorId: '' },
  { id: 'rootly', name: 'Rootly', targetConnectorId: 'flex-rootly' },
  { id: 'rss', name: 'Rss', targetConnectorId: '' },
  { id: 'rtb-house', name: 'RTB House', targetConnectorId: '' },
  { id: 's3', name: 'S3', targetConnectorId: 's3-load' },
  { id: 'safetyculture', name: 'SafetyCulture', targetConnectorId: '' },
  { id: 'sage-intacct', name: 'Sage Intacct', targetConnectorId: '' },
  { id: 'sailthru', name: 'Sailthru', targetConnectorId: '' },
  {
    id: 'salesforce',
    name: 'Salesforce',
    targetConnectorId: 'modular-salesforce-input-v0'
  },
  { id: 'salesloft', name: 'Salesloft', targetConnectorId: 'flex-salesloft' },
  { id: 'salsify', name: 'Salsify', targetConnectorId: '' },
  {
    id: 'sample-data-faker',
    name: 'Sample Data (Faker)',
    targetConnectorId: ''
  },
  { id: 'samsara', name: 'Samsara', targetConnectorId: '' },
  {
    id: 'sap-business-bydesign',
    name: 'SAP Business ByDesign',
    targetConnectorId: ''
  },
  { id: 'sap-concur', name: 'SAP Concur', targetConnectorId: '' },
  { id: 'sap-erp-on-hana', name: 'SAP ERP on HANA', targetConnectorId: '' },
  { id: 'sap-fieldglass', name: 'SAP Fieldglass', targetConnectorId: '' },
  {
    id: 'sap-successfactors',
    name: 'SAP SuccessFactors',
    targetConnectorId: 'flex-sap-successfactors'
  },
  { id: 'scorm', name: 'SCORM', targetConnectorId: '' },
  { id: 'seatgeek', name: 'SeatGeek', targetConnectorId: 'flex-seatgeek' },
  { id: 'secoda', name: 'Secoda', targetConnectorId: '' },
  { id: 'security-journey', name: 'Security Journey', targetConnectorId: '' },
  { id: 'segment', name: 'Segment', targetConnectorId: '' },
  {
    id: 'self-hosted-elasticsearch',
    name: 'Self Hosted Elasticsearch',
    targetConnectorId: ''
  },
  { id: 'sendgrid', name: 'Sendgrid', targetConnectorId: 'flex-sendgrid' },
  { id: 'sendinblue', name: 'Sendinblue', targetConnectorId: '' },
  { id: 'senseforce', name: 'Senseforce', targetConnectorId: '' },
  { id: 'sentry', name: 'Sentry', targetConnectorId: '' },
  { id: 'serpstat', name: 'Serpstat', targetConnectorId: '' },
  {
    id: 'servicenow',
    name: 'ServiceNow',
    targetConnectorId: 'servicenow-query'
  },
  { id: 'servicetitan', name: 'ServiceTitan', targetConnectorId: '' },
  { id: 'sftp', name: 'SFTP', targetConnectorId: 'data-transfer-object' },
  {
    id: 'sharepoint',
    name: 'SharePoint',
    targetConnectorId: 'sharepoint-query'
  },
  { id: 'sharetribe', name: 'Sharetribe', targetConnectorId: '' },
  { id: 'shiphero', name: 'ShipHero', targetConnectorId: '' },
  { id: 'shipstation', name: 'ShipStation', targetConnectorId: '' },
  { id: 'shopify', name: 'Shopify', targetConnectorId: 'flex-shopify' },
  { id: 'shopware', name: 'Shopware', targetConnectorId: '' },
  { id: 'shortio', name: 'Short.io', targetConnectorId: '' },
  { id: 'shortcut', name: 'Shortcut', targetConnectorId: '' },
  { id: 'sigma-computing', name: 'Sigma Computing', targetConnectorId: '' },
  { id: 'simplecast', name: 'Simplecast', targetConnectorId: '' },
  { id: 'singlestore', name: 'SingleStore', targetConnectorId: '' },
  { id: 'skilljar', name: 'Skilljar', targetConnectorId: '' },
  { id: 'skillstx', name: 'SkillsTX', targetConnectorId: '' },
  { id: 'skuvault', name: 'SkuVault', targetConnectorId: '' },
  { id: 'slack', name: 'Slack', targetConnectorId: 'flex-slack' },
  { id: 'smadex', name: 'Smadex', targetConnectorId: '' },
  { id: 'smaily', name: 'Smaily', targetConnectorId: '' },
  { id: 'smartengage', name: 'SmartEngage', targetConnectorId: '' },
  { id: 'smarthr', name: 'SmartHR', targetConnectorId: '' },
  { id: 'smartrecruiters', name: 'SmartRecruiters', targetConnectorId: '' },
  {
    id: 'smartsheet',
    name: 'Smartsheet',
    targetConnectorId: 'flex-smartsheet'
  },
  { id: 'smartwaiver', name: 'Smartwaiver', targetConnectorId: '' },
  {
    id: 'snapchat-ads',
    name: 'Snapchat Ads',
    targetConnectorId: 'flex-snapchat'
  },
  { id: 'snowflake', name: 'Snowflake', targetConnectorId: 'database-query' },
  { id: 'snowplow', name: 'Snowplow', targetConnectorId: '' },
  { id: 'snyk', name: 'Snyk', targetConnectorId: 'flex-snyk' },
  {
    id: 'solarwinds-service-desk',
    name: 'SolarWinds Service Desk',
    targetConnectorId: ''
  },
  { id: 'sonar-cloud', name: 'Sonar Cloud', targetConnectorId: '' },
  { id: 'sonarqube', name: 'SonarQube', targetConnectorId: '' },
  { id: 'spacex', name: 'SpaceX', targetConnectorId: '' },
  { id: 'sparkpost', name: 'SparkPost', targetConnectorId: '' },
  { id: 'split', name: 'Split', targetConnectorId: '' },
  { id: 'splunk', name: 'Splunk', targetConnectorId: '' },
  {
    id: 'spotify-ads',
    name: 'Spotify Ads',
    targetConnectorId: 'modular-spotify-input-v1'
  },
  { id: 'sprout-social', name: 'Sprout Social', targetConnectorId: '' },
  {
    id: 'sql-server',
    name: 'SQL Server',
    targetConnectorId: 'microsoft-sql-server-output'
  },
  {
    id: 'sql-server-microsoft-driver',
    name: 'SQL Server (Microsoft Driver)',
    targetConnectorId: 'database-query'
  },
  {
    id: 'sql-server-rds',
    name: 'SQL Server RDS',
    targetConnectorId: 'rds-query'
  },
  { id: 'square', name: 'Square', targetConnectorId: 'flex-square' },
  { id: 'squarespace', name: 'Squarespace', targetConnectorId: '' },
  { id: 'stackadapt', name: 'StackAdapt', targetConnectorId: '' },
  { id: 'standard-metrics', name: 'Standard Metrics', targetConnectorId: '' },
  { id: 'starrez', name: 'StarRez', targetConnectorId: '' },
  { id: 'statsig', name: 'Statsig', targetConnectorId: '' },
  { id: 'statuspage', name: 'Statuspage', targetConnectorId: '' },
  { id: 'strava', name: 'Strava', targetConnectorId: '' },
  { id: 'stripe', name: 'Stripe', targetConnectorId: 'stripe-query' },
  { id: 'stylight', name: 'Stylight', targetConnectorId: '' },
  { id: 'subscript', name: 'Subscript', targetConnectorId: '' },
  { id: 'surveycto', name: 'SurveyCTO', targetConnectorId: '' },
  {
    id: 'surveymonkey',
    name: 'SurveyMonkey',
    targetConnectorId: 'modular-survey-monkey-input-v0'
  },
  { id: 'surveysparrow', name: 'SurveySparrow', targetConnectorId: '' },
  { id: 'survicate', name: 'Survicate', targetConnectorId: '' },
  { id: 'swoogo', name: 'Swoogo', targetConnectorId: '' },
  { id: 'sybase-ase', name: 'Sybase ASE', targetConnectorId: 'database-query' },
  { id: 'tableau', name: 'Tableau', targetConnectorId: '' },
  { id: 'taboola', name: 'Taboola', targetConnectorId: '' },
  { id: 'talkdesk', name: 'Talkdesk', targetConnectorId: '' },
  { id: 'talkwalker', name: 'Talkwalker', targetConnectorId: '' },
  { id: 'teads', name: 'Teads', targetConnectorId: '' },
  { id: 'teamtailor', name: 'Teamtailor', targetConnectorId: '' },
  { id: 'teamwork', name: 'Teamwork', targetConnectorId: '' },
  { id: 'tempo', name: 'Tempo', targetConnectorId: '' },
  { id: 'teradata', name: 'Teradata', targetConnectorId: '' },
  { id: 'testrail', name: 'TestRail', targetConnectorId: '' },
  { id: 'the-guardian-api', name: 'The Guardian API', targetConnectorId: '' },
  { id: 'the-trade-desk', name: 'The Trade Desk', targetConnectorId: '' },
  { id: 'thinkific', name: 'Thinkific', targetConnectorId: '' },
  { id: 'ticket-tailor', name: 'Ticket Tailor', targetConnectorId: '' },
  {
    id: 'ticketmaster',
    name: 'Ticketmaster',
    targetConnectorId: 'flex-ticketmaster'
  },
  { id: 'tidb', name: 'TiDB', targetConnectorId: '' },
  { id: 'tiktok', name: 'TikTok', targetConnectorId: 'flex-tiktok' },
  { id: 'tiktok-ads', name: 'Tiktok Ads', targetConnectorId: 'flex-tiktok' },
  { id: 'timely', name: 'Timely', targetConnectorId: '' },
  { id: 'tmdb', name: 'TMDb', targetConnectorId: '' },
  { id: 'todoist', name: 'Todoist', targetConnectorId: '' },
  { id: 'toggl', name: 'Toggl', targetConnectorId: 'flex-toggl' },
  { id: 'tplcentral', name: 'TPLcentral', targetConnectorId: '' },
  { id: 'transcend', name: 'Transcend', targetConnectorId: '' },
  { id: 'trelica', name: 'Trelica', targetConnectorId: '' },
  { id: 'trello', name: 'Trello', targetConnectorId: '' },
  { id: 'trisolute', name: 'Trisolute', targetConnectorId: '' },
  { id: 'trustpilot', name: 'TrustPilot', targetConnectorId: '' },
  { id: 'tvmaze-schedule', name: 'TVMaze Schedule', targetConnectorId: '' },
  { id: 'twilio', name: 'Twilio', targetConnectorId: 'flex-twilio' },
  { id: 'twilio-taskrouter', name: 'Twilio Taskrouter', targetConnectorId: '' },
  { id: 'twitter', name: 'Twitter', targetConnectorId: '' },
  { id: 'twitter-ads', name: 'Twitter Ads', targetConnectorId: '' },
  { id: 'tymeshift', name: 'Tymeshift', targetConnectorId: '' },
  { id: 'tyntec-sms', name: 'Tyntec SMS', targetConnectorId: '' },
  { id: 'typeform', name: 'Typeform', targetConnectorId: '' },
  { id: 'udemy-business', name: 'Udemy Business', targetConnectorId: '' },
  { id: 'ukg-pro', name: 'UKG Pro', targetConnectorId: '' },
  { id: 'unleash', name: 'Unleash', targetConnectorId: '' },
  { id: 'upland-software', name: 'Upland Software', targetConnectorId: '' },
  { id: 'uppromote', name: 'UpPromote', targetConnectorId: '' },
  { id: 'us-census', name: 'US Census', targetConnectorId: '' },
  { id: 'uservoice', name: 'Uservoice', targetConnectorId: '' },
  { id: 'vantage', name: 'Vantage', targetConnectorId: '' },
  { id: 'veeqo', name: 'Veeqo', targetConnectorId: '' },
  { id: 'victorops', name: 'VictorOps', targetConnectorId: '' },
  { id: 'visma-economic', name: 'Visma Economic', targetConnectorId: '' },
  { id: 'vitally', name: 'Vitally', targetConnectorId: '' },
  {
    id: 'vonage-contact-center',
    name: 'Vonage Contact Center',
    targetConnectorId: ''
  },
  { id: 'vts', name: 'VTS', targetConnectorId: '' },
  { id: 'vwo', name: 'VWO', targetConnectorId: '' },
  { id: 'waiteraid', name: 'Waiteraid', targetConnectorId: '' },
  { id: 'weatherstack', name: 'Weatherstack', targetConnectorId: '' },
  { id: 'webconnex', name: 'Webconnex', targetConnectorId: '' },
  { id: 'webflow', name: 'Webflow', targetConnectorId: '' },
  { id: 'webhooks', name: 'Webhooks', targetConnectorId: 'webhook-post' },
  { id: 'when-i-work', name: 'When I Work', targetConnectorId: '' },
  { id: 'wherefour', name: 'Wherefour', targetConnectorId: '' },
  { id: 'whisky-hunter', name: 'Whisky Hunter', targetConnectorId: '' },
  {
    id: 'wikipedia-pageviews',
    name: 'Wikipedia Pageviews',
    targetConnectorId: ''
  },
  {
    id: 'windows-fileshare',
    name: 'Windows Fileshare',
    targetConnectorId: 'database-query'
  },
  { id: 'woocommerce', name: 'WooCommerce', targetConnectorId: '' },
  { id: 'wordpress', name: 'WordPress', targetConnectorId: '' },
  { id: 'workable', name: 'Workable', targetConnectorId: '' },
  {
    id: 'workday',
    name: 'Workday',
    targetConnectorId: 'modular-workday-input-v1'
  },
  {
    id: 'workday-custom-reports',
    name: 'Workday Custom Reports',
    targetConnectorId: 'modular-workday-custom-reports-input-v1'
  },
  {
    id: 'workday-financial-management',
    name: 'Workday Financial Management',
    targetConnectorId: ''
  },
  { id: 'workday-hcm', name: 'Workday HCM', targetConnectorId: '' },
  { id: 'workday-raas', name: 'Workday RaaS', targetConnectorId: '' },
  { id: 'workramp', name: 'WorkRamp', targetConnectorId: '' },
  { id: 'wrike', name: 'Wrike', targetConnectorId: '' },
  { id: 'xero', name: 'Xero', targetConnectorId: '' },
  { id: 'xkcd', name: 'Xkcd', targetConnectorId: '' },
  { id: 'xray', name: 'Xray', targetConnectorId: '' },
  {
    id: 'yahoo-finance-price',
    name: 'Yahoo Finance Price',
    targetConnectorId: ''
  },
  {
    id: 'yahoo-search-ads-on-yahoo-japan',
    name: 'Yahoo Search Ads on Yahoo Japan',
    targetConnectorId: ''
  },
  { id: 'yandex-metrica', name: 'Yandex Metrica', targetConnectorId: '' },
  { id: 'yelp', name: 'Yelp', targetConnectorId: 'flex-yelp' },
  { id: 'yotpo', name: 'Yotpo', targetConnectorId: '' },
  { id: 'yougov-sport', name: 'YouGov Sport', targetConnectorId: '' },
  { id: 'younium', name: 'Younium', targetConnectorId: '' },
  { id: 'youtube-analytics', name: 'YouTube Analytics', targetConnectorId: '' },
  {
    id: 'zapier-supported-storage',
    name: 'Zapier Supported Storage',
    targetConnectorId: ''
  },
  {
    id: 'zendesk',
    name: 'Zendesk',
    targetConnectorId: 'flex-zendesk-ticketing'
  },
  { id: 'zenefits', name: 'Zenefits', targetConnectorId: '' },
  { id: 'zenloop', name: 'Zenloop', targetConnectorId: '' },
  { id: 'zoho', name: 'Zoho', targetConnectorId: '' },
  { id: 'zoom', name: 'Zoom', targetConnectorId: '' },
  { id: 'zuora', name: 'Zuora', targetConnectorId: '' }
]
