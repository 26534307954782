import { useReducer, useState, type FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import type { VariableVisibility } from 'types/Pipeline'

import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'
import {
  JobVariableBehaviour,
  VariableScope,
  type GridVariable,
  type JobVariableVisibility
} from 'job-lib/types/Variables'

import { useJobVariables } from 'modules/core/EtlDesigner/hooks/useJobVariables'
import {
  ReducerActions,
  Stages,
  type ContentProps,
  type GridFormProps
} from 'modules/ManageVariables/types'

import { Footer } from '../Footer/Footer'
import formClasses from '../Form/Form.module.scss'
import {
  formReducer,
  getInitialState,
  type OnNextStepAction
} from '../Form/formReducer/formReducer'
import { StageHeader } from '../StageHeader/StageHeader'
import classes from './GridContent.module.scss'
import { useDefaultValueStep } from './Steps/DefaultValueStep/useDefaultValueStep'
import { useGridColumnsStep } from './Steps/GridColumnsStep/useGridColumnsStep'
import { type StepDetail } from './Steps/types'
import { useVariableInfoStep } from './Steps/VariableInfoStep/useVariableInfoStep'

export const GridContent = ({
  variable,
  variableScope,
  variableType,
  setStage
}: ContentProps<GridFormProps>) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const jobVariables = useJobVariables()
  const { updateVariableInSharedPipeline } = useSharePipeline()
  const { enableSharedPipelines } = useFlags()
  const { jobSummaryId } = useProjectInfo()
  const variableToEdit = jobVariables.find(
    (jv) => jv.definition.name === variable?.name
  ) as GridVariable

  const [state, dispatch] = useReducer(
    formReducer,
    getInitialState({
      variableScope,
      variableType,
      variableToEdit,
      variableBehaviour: JobVariableBehaviour.SHARED
    })
  )
  const jobDispatch = useDispatch()
  const isEditing = Boolean(variableToEdit)
  const steps: Record<number, StepDetail> = {
    1: useVariableInfoStep(dispatch, state, isEditing, variableToEdit),
    2: useGridColumnsStep(dispatch, state, isEditing),
    3: useDefaultValueStep(dispatch, state, isEditing)
  }
  const isLastStep = step === Object.keys(steps).length
  const nextDisabled = steps[step].isInvalid(state)

  const buildColumns = () => {
    const columns = state.COLUMN_GRID.value.rows.map((r) => {
      return {
        name: r.columns[0],
        type: r.columns[1] as 'TEXT' | 'NUMBER'
      }
    })
    return columns
  }

  const buildGridVariable = () => {
    const definitions = buildColumns()
    const names = state.COLUMN_GRID.value.rows.map((r) => r.columns[0])
    const defaultValues = state.DEFAULT_VALUE_GRID.value.rows.map((row) => {
      return names.reduce<{ values: string[] }>(
        (accum, _, namesIndex) => {
          accum.values.push(row.columns[namesIndex] ?? '')

          return accum
        },
        { values: [] }
      )
    })

    const newVariable: GridVariable = {
      definition: {
        definitions,
        name: state.NAME.value,
        description: state.DESCRIPTION.value,
        scope: state.BEHAVIOUR.value as JobVariableBehaviour,
        visibility: state.VISIBILITY.value as JobVariableVisibility
      },
      values: defaultValues
    }

    return newVariable
  }

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newStep = step + 1
    const action: OnNextStepAction = {
      newStep,
      type: ReducerActions.ON_NEXT_STEP
    }
    dispatch(action)

    if (!isLastStep) {
      setStep(newStep)
    } else {
      if (isEditing) {
        jobDispatch(
          jobActions.updateJobVariable({
            oldName: variableToEdit.definition.name,
            updatedVariable: buildGridVariable()
          })
        )
        enableSharedPipelines &&
          updateVariableInSharedPipeline({
            pipelineName: jobSummaryId,
            variable: {
              oldName: variableToEdit.definition.name,
              name: state.NAME.value,
              type: variableType,
              scope: state.BEHAVIOUR.value as JobVariableBehaviour,
              description: state.DESCRIPTION.value,
              visibility: state.VISIBILITY.value as VariableVisibility,
              columns: buildColumns()
            }
          })
      } else {
        jobDispatch(jobActions.addJobVariable(buildGridVariable()))
        enableSharedPipelines &&
          updateVariableInSharedPipeline({
            pipelineName: jobSummaryId,
            variable: {
              name: state.NAME.value,
              type: variableType,
              scope: state.BEHAVIOUR.value as JobVariableBehaviour,
              description: state.DESCRIPTION.value,
              visibility: state.VISIBILITY.value as VariableVisibility,
              columns: buildColumns()
            }
          })
      }

      setStage({
        stage: Stages.MANAGE,
        variableScope: VariableScope.JOB_VARIABLE
      })
    }
  }

  const stepProgressText = t('manageVariables.createGridVar.stepProgress', {
    step,
    totalSteps: Object.keys(steps).length
  })

  return (
    <div
      className={classNames(classes.GridContent, classes.Wizard)}
      aria-live="polite"
    >
      <div className={classes.StepContent}>
        <Typography
          format="mc"
          aria-hidden
          className={classes.StepContent_Progress}
          data-testid="step-progress-indicator"
        >
          {stepProgressText}
        </Typography>
        <form
          id="step-form"
          className={formClasses.Form}
          onSubmit={onSubmitHandler}
        >
          <StageHeader
            title={steps[step].title}
            switcher={steps[step].switcher}
          />
          <p className={'u-visually-hidden'}>{stepProgressText}</p>
          <div className={classNames(formClasses.Form__Content)}>
            {steps[step].content}
          </div>
        </form>
      </div>
      <Footer className={classes.StepContent_Footer}>
        <Button
          size="md"
          alt="secondary"
          data-testid="cancel-button"
          onClick={() => {
            setStage({
              stage: Stages.MANAGE,
              variableScope: VariableScope.JOB_VARIABLE
            })
          }}
          text={t('common.cancel')}
        />
        <div className={classes.GridContent_StepControls}>
          {step > 1 && (
            <Button
              size="md"
              alt="secondary"
              data-testid={'previous-button'}
              text={t('manageVariables.createGridVar.previous')}
              onClick={() => {
                setStep(step - 1)
              }}
            />
          )}
          <Button
            size="md"
            // @ts-expect-error Button extends HTMLElement instead of HTMLButtonElement as it allows you to use 'as' to render as another component
            form="step-form"
            disabled={nextDisabled}
            data-testid={'next-button'}
            type="submit"
            text={t(`manageVariables.create.${isLastStep ? 'finish' : 'next'}`)}
          />
        </div>
      </Footer>
    </div>
  )
}
