import { useMutation } from '@tanstack/react-query'

import {
  type OptimisedQueryTask,
  type PipelineContext
} from 'api/hooks/copilot/model'

import { type CopilotComponentUpdateConfig } from '../useChatWithSse/useChatWithSse'
import { useCopilotApi } from '../useCopilotApi/useCopilotApi'

export interface PipelineUpdateInput {
  requestId: string
  pipeline: PipelineContext
  warehouse: string
  tasks: OptimisedQueryTask[]
  message: string
}

export interface CopilotResponse {
  requestId: string
  choices: CopilotComponentUpdateConfig[]
}

export const useUpdatePipeline = () => {
  const { updatePipeline } = useCopilotApi()

  return useMutation(async (input: PipelineUpdateInput) => {
    const response = await updatePipeline(input)

    return response.data
  })
}
