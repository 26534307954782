import { type FC } from 'react'

import { type SVGIconProps } from '../../../ItemSelect/svg/types'

export const ShortenText: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Shorten text"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 15V13H14V15H4ZM4 11V9H20V11H4Z" fill="#081D4C" />
    </svg>
  )
}
