import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import { useEosApi } from '../useEosApi/useEosApi'

interface CancelTaskParams {
  flowInstanceId: string
  force: boolean
}

export const useCancelTask = () => {
  const { cancelTask } = useEosApi()
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, CancelTaskParams>(
    async ({ flowInstanceId, force }) => cancelTask(flowInstanceId, force),
    {
      // for either success or fail, refresh the task history
      onSettled: async () =>
        Promise.all([
          queryClient.invalidateQueries([queryKeys.taskStatus]),
          queryClient.invalidateQueries([queryKeys.activity])
        ])
    }
  )
}
