import {
  useRenameFile as useRenameFileEffect,
  type RenameFileProps
} from '@matillion/git-component-library'

import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

import { useFlags } from '../useFlags'

export const useRenameFile = () => {
  const { renameSharedPipeline } = useSharePipeline()
  const { updateRecentlyAccessedPipeline } = useRecentlyAccessedPipelines()
  const { enableRecentlyAccessedPipelines, enableSharedPipelines } = useFlags()

  const { renameFile: renameFileEffect, ...effectHook } = useRenameFileEffect()

  const renameFile = async ({
    currentFilePath,
    newFilePath
  }: RenameFileProps) => {
    await renameFileEffect(
      { currentFilePath, newFilePath },
      {
        onSuccess: () => {
          if (enableRecentlyAccessedPipelines) {
            updateRecentlyAccessedPipeline({
              previousPipelineId: currentFilePath,
              newPipelineId: newFilePath
            })
          }

          if (enableSharedPipelines) {
            renameSharedPipeline(currentFilePath, newFilePath)
          }
        }
      }
    )
  }

  return {
    renameFile,
    ...effectHook
  }
}
