import classes from './EtlNoteThemePicker.module.scss'
import { EtlNoteThemePickerIcon } from './EtlNoteThemePickerIcon'

const topRowThemes = ['green', 'yellow', 'red', 'white']
const bottomRowThemes = [
  'light-green',
  'light-yellow',
  'light-red',
  'light-blue'
]

interface EtlNoteThemePickerProps {
  selectedTheme?: string
  selectTheme: (theme: string) => void
  selectTemporaryTheme: (theme?: string) => void
}

export const EtlNoteThemePicker = ({
  selectedTheme,
  selectTheme,
  selectTemporaryTheme
}: EtlNoteThemePickerProps) => {
  const getThemeIcon = (theme: string) => (
    <EtlNoteThemePickerIcon
      key={theme}
      theme={theme}
      selectedTheme={selectedTheme}
      selectTheme={selectTheme}
      selectTemporaryTheme={selectTemporaryTheme}
    />
  )
  return (
    <div
      data-testid="etl-note-theme-picker"
      className={classes.EtlNoteThemePicker}
      onMouseLeave={() => {
        selectTemporaryTheme()
      }}
    >
      <div>{topRowThemes.map(getThemeIcon)}</div>
      <div>{bottomRowThemes.map(getThemeIcon)}</div>
    </div>
  )
}
