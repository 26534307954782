import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { usePendo } from '@matillion/hub-client'
import { type PipelineType } from 'types/Pipeline'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { getExtension, type JobType } from 'job-lib/types/JobType'

import { useCreatePipeline } from 'modules/FileBrowser/effects/useCreatePipeline/useCreatePipeline'

import { track } from 'utils/heap'

interface CreateJobActionProps {
  type: JobType
  onComplete: () => void
}

interface CreateJobActionResult {
  isLoading: boolean
  create: (jobName: string) => unknown
}

export function useCreateJobAction({
  type,
  onComplete
}: CreateJobActionProps): CreateJobActionResult {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync: createPipeline, isLoading } = useCreatePipeline()
  const { navigateToJob } = useSelectedJobs()
  const pendo = usePendo()

  const create = useCallback(
    async (runtimeName: string) => {
      if (isLoading) return

      try {
        track('etld_create_job_click', { jobType: type })
        pendo.track('etld_create_job_click', { jobType: type })

        const fileName = `${runtimeName}.${getExtension(type)}`
        await createPipeline({
          type: type.toLowerCase() as PipelineType,
          name: fileName
        })

        makeToast({
          title: t('translation:createPipeline.success.pipeline'),
          type: 'success',
          message: ''
        })

        navigateToJob(fileName)
      } catch (e) {
        console.error(e)
      }
      onComplete()
    },
    [
      isLoading,
      type,
      pendo,
      onComplete,
      makeToast,
      navigateToJob,
      createPipeline,
      t
    ]
  )

  return {
    isLoading,
    create
  }
}
