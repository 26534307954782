import { type TFunction } from 'i18next'

import {
  ParameterDataType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'

import {
  type ElementCollection,
  type ParameterCollection
} from 'job-lib/types/Parameters'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { createHomogeneousElements } from '../createElementsCollection'
import { type InitialParameterValues } from './types'

export const createParameters = (
  t: TFunction,
  metadata: ComponentMetadata,
  initialValues: InitialParameterValues = {}
) => {
  const output: ParameterCollection = {}

  metadata.parameters.forEach(
    ({ metlSlot, dplID, defaultValue, dataType, staticOptions }) => {
      const parameterDefaultValue: ElementCollection | undefined = defaultValue
        ? createHomogeneousElements(defaultValue, dataType)
        : undefined
      const isScalarParameter = dataType !== ParameterDataType.GRID

      // we only store default values in the working copy for scalar parameters
      // default values for grid type parameters will be displayed in the relevant grids when they load
      const staticOptionsDefaultValue: ElementCollection | undefined =
        isScalarParameter && staticOptions?.[0].defaultValue
          ? createHomogeneousElements(
              staticOptions?.[0].defaultValue,
              staticOptions?.[0].type as ParameterDataType
            )
          : undefined
      const defaultSelectedValue: ElementCollection | undefined =
        parameterDefaultValue ?? staticOptionsDefaultValue
      const elements: ElementCollection = defaultSelectedValue ?? {}

      output[metlSlot] = {
        slot: metlSlot,
        elements: initialValues[dplID] ?? elements,
        name: t(`componentProperties.parameterIds.${dplID}`),
        visible: false
      }
    }
  )

  // Create all the parameters with default values first and set the default visibility after.
  Object.values(output).forEach((value) => {
    value.visible = isMetlParameterVisible(value.slot, output, metadata)
  })

  return output
}
