import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  useReactFlow,
  type Connection,
  type Edge,
  type Node,
  type OnSelectionChangeParams
} from 'reactflow'

import {
  type ComponentNode,
  type EtlCanvasNode,
  type EtlCanvasNodeId
} from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import {
  getIdFromReactFlowId,
  getNodeInfo,
  getSelectedNodes,
  isComponentNode
} from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'
import { useUpdateLinks } from 'job-lib/store/jobSlice/hooks/useUpdateLinks/useUpdateLinks'
import {
  ConnectionPortType,
  type OutputPortType
} from 'job-lib/types/Components'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import { useDeleteEdge } from './useDeleteEdge/useDeleteEdge'

const isValidConnection = (connection: Connection) => {
  if (!connection.source || !connection.target) {
    return false
  }

  /* prevents a node from being connected to itself */
  if (connection.source === connection.target) {
    return false
  }

  return true
}

export const useCanvasHandlers = (
  canvasNodes: Map<EtlCanvasNodeId, EtlCanvasNode>,
  syncCanvasModel: () => void
) => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const dispatch = useDispatch()
  const updateLinks = useUpdateLinks()
  const { componentId: selectedComponentId } = useProjectInfo()
  const { invalidateComponent } = useComponentValidationProvider()
  const { navigateToComponent } = useSelectedJobs()
  const { deleteNodes } = useDeleteNodes()
  const reactFlow = useReactFlow()
  const [isSelecting, setIsSelecting] = useState(false)
  const selectedNodeCount = useRef(0)
  const update = useDPLWorkingCopy((state) => state.update)
  const { job } = useFlaggedWorkingCopy()
  const { deleteEdge } = useDeleteEdge()
  const onSelectionStart = () => {
    setIsSelecting(true)
  }

  const onSelectionEnd = () => {
    setIsSelecting(false)
  }

  const onNodeDragStop = useCallback(
    (_: React.MouseEvent, movedNode: Node) => {
      const selectedNodes = getSelectedNodes(reactFlow)

      const moveNode = (node: Node) => {
        const { id, type } = getNodeInfo(node)
        if (rolloutEnableWorkingCopyProvider) {
          if (!job) return

          if (type === 'component') {
            update((state) => {
              state.updateComponentPosition({
                name: getComponentName(job.components[id]),
                position: {
                  x: Math.round(node.position.x),
                  y: Math.round(node.position.y)
                }
              })
            })
          } else {
            update((state) => {
              state.updateNotePosition({
                noteId: String(id),
                position: {
                  x: Math.round(node.position.x),
                  y: Math.round(node.position.y)
                }
              })
            })
          }
        } else {
          dispatch(
            jobActions.updateNodePosition({
              type,
              id,
              x: Math.round(node.position.x),
              y: Math.round(node.position.y)
            })
          )
        }
      }

      if (selectedNodes.length === 0) {
        moveNode(movedNode)

        return
      }

      selectedNodes.forEach((selectedNode) => {
        moveNode(selectedNode)
      })
    },
    [dispatch, reactFlow, rolloutEnableWorkingCopyProvider, update, job]
  )

  const onSelectionDragStop = useCallback(
    (_: React.MouseEvent, movedNodes: Node[]) => {
      movedNodes.forEach((movedNode) => {
        const { id, type } = getNodeInfo(movedNode)

        if (rolloutEnableWorkingCopyProvider) {
          if (!job) return

          if (type === 'component') {
            update((state) => {
              state.updateComponentPosition({
                name: getComponentName(job?.components[id]),
                position: {
                  x: Math.round(movedNode.position.x),
                  y: Math.round(movedNode.position.y)
                }
              })
            })
          } else if (type === 'note') {
            update((state) => {
              state.updateNotePosition({
                noteId: String(id),
                position: {
                  x: Math.round(movedNode.position.x),
                  y: Math.round(movedNode.position.y)
                }
              })
            })
          }
        } else {
          dispatch(
            jobActions.updateNodePosition({
              type,
              id,
              x: Math.round(movedNode.position.x),
              y: Math.round(movedNode.position.y)
            })
          )
        }
      })
    },
    [dispatch, rolloutEnableWorkingCopyProvider, update, job]
  )

  const onNodesDelete = useCallback(
    (deletedNodes: Node[]) => {
      deleteNodes(deletedNodes.map(getNodeInfo))
    },
    [deleteNodes]
  )

  const onEdgesDelete = useCallback(
    (deletedEdges: Edge[]) => {
      deletedEdges.forEach((deletedEdge) => {
        deleteEdge(
          deletedEdge.source,
          deletedEdge.target,
          deletedEdge.id,
          deletedEdge.sourceHandle as string
        )
      })
    },
    [deleteEdge]
  )

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!connection.source || !connection.target) {
        return
      }
      const sourceId = Number.parseInt(getIdFromReactFlowId(connection.source))
      const targetId = Number.parseInt(getIdFromReactFlowId(connection.target))
      const sourceType = connection.sourceHandle as OutputPortType | null

      const sourceNode = canvasNodes.get(connection.source as EtlCanvasNodeId)
      const targetNode = canvasNodes.get(connection.target as EtlCanvasNodeId)

      if (!sourceType || !sourceNode || !targetNode) {
        return
      }

      if (!isComponentNode(sourceNode)) {
        console.warn('source node does not support connections')
        return
      }

      if (!isComponentNode(targetNode)) {
        console.warn('target node does not support connections')
        return
      }

      const sourcePort = (
        sourceType === ConnectionPortType.ITERATION
          ? sourceNode.data.iteratorPorts
          : sourceNode.data.outputPorts
      ).find(({ portId }) => portId === sourceType)

      const targetPort = targetNode.data.inputPorts.find(
        ({ portId }) => portId === ConnectionPortType.INPUT
      )

      if (!sourcePort || !targetPort) {
        return
      }

      updateLinks({
        sourceComponentId: sourceId,
        targetComponentId: targetId,
        sourceCardinality: sourcePort.cardinality,
        targetCardinality: targetPort.cardinality,
        sourceType
      })

      // As we are using React-Flow in a controlled manner, we are manually syncing updates.
      // Where updateLinks will conditionally update state, we must always re-sync the job with
      // React-Flow after updating the connectors. If we don't do this then a connection will be
      // made by React-Flow, but not stored in the job model.  The next time the job updates this will be lost
      syncCanvasModel()

      invalidateComponent(targetId)
    },
    [canvasNodes, updateLinks, syncCanvasModel, invalidateComponent]
  )

  const onSelectionChange = useCallback(
    ({ nodes }: OnSelectionChangeParams) => {
      if (isSelecting) {
        return
      }

      const visibleNodes = nodes.filter(
        (node) => node.hidden === false || node.hidden === undefined
      )

      const prevSelectedNodeCount = selectedNodeCount.current
      const hasSelectedNodeCountChanged = prevSelectedNodeCount !== nodes.length
      selectedNodeCount.current = visibleNodes.length

      if (visibleNodes.length > 1 && selectedComponentId) {
        /*
         * when multiple nodes are selected and the url is pointed
         * at a single component, we don't want any component to be selected
         */
        navigateToComponent()
        return
      }

      if (nodes.length === 1 && isComponentNode(nodes[0])) {
        /*
         * if there is only one selected node on the canvas, the selected component
         * should match it. this allows us to programmatically set the selected state
         * on canvas nodes, and have the rest of the UI match it
         */
        const nodeId = getIdFromReactFlowId(nodes[0].id)

        /**
         * Get the latest version of the visibleNode from canvasNodes
         * Since the nodes list provided by onSelectionChange is outdated when the component is initially selected
         */
        const node = canvasNodes.get(
          `component-${nodeId}` as EtlCanvasNodeId
        ) as ComponentNode

        /*
         * if the currently selected canvas node is our selected component,
         * we don't need to re-navigate to it
         */
        if (selectedComponentId === Number(nodeId)) {
          return
        }

        const nestedNodeIds = node?.data.attachedNodes?.map(({ id }) =>
          getIdFromReactFlowId(id)
        )
        /*
         * if a child of the currently selected canvas node is our selected component,
         * we shouldn't navigate away, as this could override an explicit action by users;
         * canvas nodes contain a `navigateToComponent` call on click.
         * this can't be changed until attached nodes are real canvas nodes instead of nested
         * react elements
         */
        if (nestedNodeIds?.some((id) => Number(id) === selectedComponentId)) {
          return
        }

        navigateToComponent(nodeId)

        return
      }

      if (
        hasSelectedNodeCountChanged &&
        nodes.length === 0 &&
        selectedComponentId
      ) {
        /*
         * when a user deselects all nodes, we want to clear the currently selected component.
         * we need to make sure we don't do this on every change, though, as change events
         * can get fired on canvas re-render or job updates--if we didn't check for a selected
         * component id here, we could get into an infinite redirect loop
         */
        navigateToComponent()
      }
    },
    [isSelecting, navigateToComponent, selectedComponentId, canvasNodes]
  )

  return {
    onNodesDelete,
    onNodeDragStop,
    onSelectionStart,
    onSelectionEnd,
    onSelectionDragStop,
    onEdgesDelete,
    onConnect,
    onSelectionChange,
    isValidConnection
  }
}
