import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Toaster } from '@matillion/component-library'
import { cloneDeep } from 'lodash'
import { MELTtoDPLTransition } from 'types/Pipeline'

import { useFlags } from 'hooks/useFlags'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { updateTransformationLinks } from 'job-lib/job-functions/updateTransformationLinks'
import { jobActions } from 'job-lib/store/jobSlice/job.slice'
import { type UpdateLinks } from 'job-lib/store/jobSlice/job.types'
import { checkForTransformationCircularDeps } from 'job-lib/store/jobSlice/utils/checkForTransformationCircularDeps'
import {
  type ComponentInstance,
  type TransformationJob
} from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const useUpdateLinks = () => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const dispatch = useDispatch()
  const jobState = useFlaggedWorkingCopy()
  const update = useDPLWorkingCopy((state) => state.update)
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  return ({
    sourceComponentId,
    targetComponentId,
    sourceCardinality,
    targetCardinality,
    sourceType
  }: UpdateLinks) => {
    if (!jobState.job) return

    const createErrorToast = (targetComponent: ComponentInstance) => {
      const targetComponentName = getComponentName(targetComponent)

      makeToast({
        message: t('alerts.cannotCreateCircularLink.message'),
        title: t('alerts.cannotCreateCircularLink.actionText', {
          targetComponentName
        }),
        type: 'error'
      })
    }

    const updateLinks = () => {
      if (rolloutEnableWorkingCopyProvider) {
        update((state) => {
          // istanbul ignore if
          if (!jobState.job) return
          state.updateLinks({
            sourceComponent: getComponentName(
              jobState.job.components[sourceComponentId]
            ),
            targetComponent: getComponentName(
              jobState.job.components[targetComponentId]
            ),
            sourceCardinality,
            targetCardinality,
            linkType: MELTtoDPLTransition[sourceType]
          })
        })
      } else {
        dispatch(
          jobActions.updateConnectors({
            sourceComponentId,
            targetComponentId,
            sourceCardinality,
            targetCardinality,
            sourceType
          })
        )
      }
    }

    if (jobState.jobType === JobType.Transformation) {
      const newJobState = updateTransformationLinks({
        job: cloneDeep(jobState.job) as TransformationJob,
        sourceComponentId,
        targetComponentId,
        sourceCardinality,
        targetCardinality,
        sourceType
      })
      checkForTransformationCircularDeps(newJobState)
        ? createErrorToast(newJobState.components[targetComponentId])
        : updateLinks()
    }

    if (jobState.jobType === JobType.Orchestration) {
      updateLinks()
    }
  }
}
