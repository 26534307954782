import React from 'react'

import type {
  JobConversionReport,
  VariableConversionRecord,
  VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import ConversionReportDescription from './ConversionReportDescription'
import ConversionReportDocs from './ConversionReportDocs'
import ConversionReportFooter from './ConversionReportFooter'
import ConversionReportHeader from './ConversionReportHeader'
import ConversionReportSummaryDetails from './ConversionReportSummaryDetails'
import JobsConversionReport from './JobsConversionReport'
import VariablesConversionReport from './VariablesConversionReport'

interface ConversionReportSummaryProps {
  accountId: string
  projectName: string
  dataPlatform: string
  exportFileName: string
  dryRunDate: string
  jobConversionReport: JobConversionReport
  jobsNotConverted: string[]
  variableConversionReport: VariableConversionReport
  variablesNotConverted: VariableConversionRecord[]
}

const pageStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  wordWrap: 'break-word'
}

const reportStyles: React.CSSProperties = {
  margin: 50,
  maxWidth: 1800
}

const contentStyles: React.CSSProperties = {
  flexDirection: 'column'
}

const ConversionReportSummary = ({
  accountId,
  projectName,
  dataPlatform,
  exportFileName,
  dryRunDate,
  jobConversionReport,
  jobsNotConverted,
  variableConversionReport,
  variablesNotConverted
}: ConversionReportSummaryProps) => {
  return (
    <div style={pageStyles}>
      <div style={reportStyles}>
        <div style={contentStyles}>
          <ConversionReportHeader />

          <ConversionReportDescription
            accountId={accountId}
            projectName={projectName}
            dataPlatform={dataPlatform}
            exportFileName={exportFileName}
            dryRunDate={dryRunDate}
          />

          <ConversionReportSummaryDetails
            jobConversionReport={jobConversionReport}
            jobsNotConverted={jobsNotConverted}
            variableConversionReport={variableConversionReport}
            variablesNotConverted={variablesNotConverted}
          />

          <JobsConversionReport
            jobConversionReport={jobConversionReport}
            jobsNotConverted={jobsNotConverted}
          />

          <VariablesConversionReport
            variableConversionReport={variableConversionReport}
            variablesNotConverted={variablesNotConverted}
          />

          <ConversionReportDocs />

          <ConversionReportFooter />
        </div>
      </div>
    </div>
  )
}

export default ConversionReportSummary
