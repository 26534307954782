import { useLayoutEffect, type RefObject } from 'react'

import { useTree, useTreeItem } from '@matillion/component-library'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export const useScrollToFile = (
  filename: string,
  fileItemRef: RefObject<HTMLDivElement>
) => {
  const { jobSummaryId: selectedFile } = useProjectInfo()
  const { toggle } = useTree()
  const { isFocused } = useTreeItem(filename)

  /**
   * useLayoutEffect applies the scroll and toggle before the browser paints so there's no flicker
   * when the file is focused
   */
  useLayoutEffect(() => {
    /** only toggle the file if the selected file in the URL is the same as the file we are rendering
     * and the file is not already focused e.g has been clicked on in the file browser tree already
     * typically this will only happen when the user clicks on a tab or chooses a file from the command palette or by some other external means
     */
    if (selectedFile === filename && fileItemRef.current && !isFocused) {
      toggle(filename)
      fileItemRef.current.scrollIntoView({ block: 'center' })
    }
    // toggle is not stable so we need to ignore it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, filename])
}
