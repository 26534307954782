import { ErrorBoundary } from 'react-error-boundary'

import { datadogLogs } from '@datadog/browser-logs'
import {
  FileType,
  useFile,
  useFiles,
  useUpdateFileContents
} from '@matillion/git-component-library'
import { type editor } from 'monaco-editor'

import { MonacoEditor } from 'components/CodeEditor/MonacoEditor'
import { DisplayType } from 'components/CodeEditor/types'
import { Loading } from 'components/Loading/Loading'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { EditorErrorBoundaryFallback } from './EditorErrorBoundaryFallback'
import { EditorErrorLoadingFile } from './EditorErrorLoadingFile'
import classes from './FileTextEditor.module.scss'

const languageMap: Record<FileType, string> = {
  [FileType.ORCHESTRATION_PIPELINE]: 'yaml',
  [FileType.TRANSFORMATION_PIPELINE]: 'yaml',
  [FileType.PYTHON]: 'python',
  [FileType.SQL]: 'sql',
  [FileType.MARKDOWN]: 'markdown',
  [FileType.YAML]: 'yaml',
  [FileType.JSON]: 'json',
  [FileType.TEXT]: 'text',
  [FileType.CSV]: 'csv',
  [FileType.SCALA]: 'scala',
  [FileType.JAVASCRIPT]: 'javascript',
  [FileType.CSS]: 'css',
  [FileType.XML]: 'xml',
  [FileType.HTML]: 'html',
  [FileType.SOURCE]: 'yaml',
  [FileType.UNKNOWN]: 'unknown'
} as const

const editorOptions: editor.IStandaloneEditorConstructionOptions = {
  automaticLayout: true,
  tabSize: 2,
  bracketPairColorization: {
    enabled: true
  },
  minimap: {
    enabled: true
  },
  wordWrap: 'on',
  scrollBeyondLastLine: true,
  padding: {
    top: 10
  },
  scrollbar: {
    horizontal: 'auto',
    verticalScrollbarSize: 0,
    verticalSliderSize: 10
  }
}

export const FileTextEditor = () => {
  const { jobSummaryId } = useProjectInfo()
  const { files } = useFiles()
  const { data, isLoading, isError } = useFile({ path: jobSummaryId })

  const { updateFile } = useUpdateFileContents()

  const handleOnChange = async (newFileContents: string) => {
    await updateFile({
      name: jobSummaryId,
      contents: newFileContents
    })
  }

  const language = languageMap[files[jobSummaryId]?.type]

  if (isError) {
    return <EditorErrorLoadingFile />
  }

  // File contents can be an empty string, so only undefined is invalid
  if (isLoading || data === undefined) {
    return (
      <div className={classes.loading}>
        <Loading data-testid="file-text-editor-loading" />
      </div>
    )
  }

  const handleError = ({ name, message, stack }: Error) => {
    const errorMessage = `Error caught by FileTextEditor error boundary (${message})`
    datadogLogs.logger.error(errorMessage, { message, stack, name })
  }

  return (
    <ErrorBoundary
      onError={handleError}
      fallback={
        <EditorErrorBoundaryFallback
          language={language}
          fileName={jobSummaryId}
        />
      }
    >
      <MonacoEditor
        key={`monaco-editor-${jobSummaryId}`}
        defaultValue={data}
        language={language}
        options={editorOptions}
        onChange={handleOnChange}
        data-testid="file-text-editor"
        displayType={DisplayType.Default}
      />
    </ErrorBoundary>
  )
}
