import { type FC } from 'react'

import { type SVGIconProps } from '../../../ItemSelect/svg/types'

export const LengthenText: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Lengthen text"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 5H20V7H4V5ZM4 9H20V11H4V9ZM4 13H20V15H4V13ZM4 17H14V19H4V17Z"
        fill="#081D4C"
      />
    </svg>
  )
}
