import { useCallback, useContext } from 'react'
import { getRectOfNodes, type XYPosition } from 'reactflow'

import { cloneDeep, partition } from 'lodash'
import { MELTtoDPLTransition } from 'types/Pipeline'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { type OrchestrationJob } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import { useEtlFlow } from '../../hooks/useEtlFlow'
import {
  getSelectedNodeInfo,
  getSelectedNodes
} from '../Canvas/hooks/useCanvasModel/utils'
import { CopyPasteContext, useCopyPasteContext } from './CopyPasteContext'
import { getFilteredConnectors } from './getFilteredConnectors'
import { addConnectedComponents } from './useCopyComponent.utils'

export const useCopyComponent = () => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()

  const { setCopiedComponentContent, setCopiedComponentOffsets } =
    useContext(CopyPasteContext)

  const { setCopiedComponents } = useCopyPasteContext()

  const reactFlowInstance = useEtlFlow()
  const { job, jobType } = useFlaggedWorkingCopy()
  const pipeline = useDPLWorkingCopy((state) => state.workingCopy)
  const { jobSummaryId } = useProjectInfo()

  const copyComponent = useCallback(() => {
    if (!job || !jobType) {
      return
    }

    // TODO: support copy/paste for notes
    const [selectedComponentInfos] = partition(
      getSelectedNodeInfo(reactFlowInstance),
      (x) => x.type === 'component'
    )

    const selectedComponentInstances = selectedComponentInfos
      .map((selectedComponent) =>
        cloneDeep(job?.components[selectedComponent.id])
      )
      .filter(Boolean)

    if (jobType === JobType.Orchestration) {
      const selectedComponentIds = selectedComponentInstances.map(
        (component) => component.id
      )

      const iterationConnectors = (job as OrchestrationJob)?.iterationConnectors
      selectedComponentIds.forEach((sourceId) => {
        addConnectedComponents(
          sourceId,
          iterationConnectors,
          job,
          selectedComponentInstances
        )
      })
    }

    const selectedNodes = getSelectedNodes(reactFlowInstance)

    const selectedComponentBounds = getRectOfNodes(selectedNodes)

    const selectedComponentOffsets: XYPosition[] = []

    selectedComponentInstances.forEach((componentInstance) => {
      const componentOffset = {
        x: componentInstance.x - selectedComponentBounds.x,
        y: componentInstance.y - selectedComponentBounds.y
      }
      selectedComponentOffsets.push(componentOffset)
    })

    setCopiedComponentOffsets(selectedComponentOffsets)

    if (selectedComponentInstances.length > 0) {
      const connectors = getFilteredConnectors(
        job,
        selectedComponentInstances.map((component) => component.id)
      )

      // istanbul ignore if
      if (rolloutEnableWorkingCopyProvider) {
        const copiedComponents = selectedComponentInstances.map((instance) => {
          const componentName = getComponentName(instance)

          const component = pipeline.pipeline.components[componentName]
          const componentDesign = pipeline.design.components[componentName]

          return {
            component,
            componentDesign
          }
        })

        const links = connectors.map((connector) => {
          const sourceComponentName = getComponentName(
            job.components[connector.sourceID]
          )
          const targetComponentName = getComponentName(
            job.components[connector.targetID]
          )

          return {
            sourceComponentName,
            targetComponentName,
            linkType: MELTtoDPLTransition[connector.outputType]
          }
        })

        const componentOffsets = copiedComponents.map((component) => {
          const componentDesign = component.componentDesign

          return {
            componentName: component.component.parameters
              .componentName as string,
            x: componentDesign.position.x - selectedComponentBounds.x,
            y: componentDesign.position.y - selectedComponentBounds.y
          }
        })

        setCopiedComponents(
          {
            components: copiedComponents,
            sourcePipelineType: pipeline.type,
            links,
            componentOffsets
          },
          jobSummaryId
        )
      } else {
        setCopiedComponentContent(jobSummaryId, {
          // @ts-expect-error the union is collapsed when the selectedComponentInstances variable is defined
          componentInstances: selectedComponentInstances,
          componentType: jobType,
          currentConnectors: connectors
        })
      }
    }
  }, [
    job,
    jobType,
    reactFlowInstance,
    setCopiedComponentOffsets,
    rolloutEnableWorkingCopyProvider,
    pipeline,
    setCopiedComponents,
    setCopiedComponentContent,
    jobSummaryId
  ])

  return { copyComponent }
}
