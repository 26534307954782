import { useDispatch } from 'react-redux'

import type { VariableVisibility } from 'types/Pipeline'

import { type CreateProjectVariablesRequest } from 'api/hooks/useCreateProjectVariables/types'
import { useCreateProjectVariables } from 'api/hooks/useCreateProjectVariables/useCreateProjectVariables'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'
import {
  JobVariableType,
  VariableScope,
  type JobVariableBehaviour,
  type JobVariableVisibility
} from 'job-lib/types/Variables'

import {
  Stages,
  type ContentProps,
  type CreateFormProps,
  type FormState,
  type ProjectVariableBehaviour
} from 'modules/ManageVariables/types'

import { Form } from '../Form/Form'

export const Create = ({
  setStage,
  variableScope,
  variableType
}: ContentProps<CreateFormProps>) => {
  const jobDispatch = useDispatch()
  const { jobSummaryId } = useProjectInfo()
  const { enableSharedPipelines } = useFlags()
  const { updateVariableInSharedPipeline } = useSharePipeline()
  const { mutateAsync: createProjectVariables, isLoading } =
    useCreateProjectVariables()

  const onSubmit = (state: FormState) => {
    if (
      variableScope === VariableScope.PROJECT_VARIABLE &&
      variableType !== JobVariableType.GRID
    ) {
      const postData: CreateProjectVariablesRequest = {
        name: state.NAME.value,
        type: variableType,
        behaviour: state.BEHAVIOUR.value as ProjectVariableBehaviour,
        description: state.DESCRIPTION.value,
        value:
          state.DEFAULT_VALUE.value !== '' ? state.DEFAULT_VALUE.value : null,
        overrides: Object.values(state.PROJECT_DEFAULT_OVERRIDES.value).map(
          ({ environmentId, value }) => ({
            environmentId,
            value: value !== '' ? value : null
          })
        )
      }

      createProjectVariables(postData).then(() => {
        setStage({ stage: Stages.MANAGE, variableScope })
      })
    } else {
      jobDispatch(
        jobActions.addJobVariable({
          definition: {
            name: state.NAME.value,
            description: state.DESCRIPTION.value,
            type: variableType,
            behaviour: state.BEHAVIOUR.value as JobVariableBehaviour,
            visibility: state.VISIBILITY.value as JobVariableVisibility
          },
          value: state.DEFAULT_VALUE.value
        })
      )

      enableSharedPipelines &&
        updateVariableInSharedPipeline({
          pipelineName: jobSummaryId,
          variable: {
            name: state.NAME.value,
            type: variableType,
            scope: state.BEHAVIOUR.value as JobVariableBehaviour,
            description: state.DESCRIPTION.value,
            visibility: state.VISIBILITY.value as VariableVisibility
          }
        })

      setStage({
        stage: Stages.MANAGE,
        variableScope
      })
    }
  }

  const onCancel = () => {
    setStage({ stage: Stages.MANAGE, variableScope })
  }

  return (
    <Form
      variableScope={variableScope}
      variableType={variableType}
      submitting={isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
