/**
 * Join 2 strings ensuring that they only have a single slash between them.
 *
 * @param a first string to join
 * @param b second string to join
 */
export const singleJoiningSlash = (a: string, b: string) => {
  const aSlash = a.endsWith('/')
  const bSlash = b.startsWith('/')

  if (!aSlash && !bSlash) {
    return `${a}/${b}`
  }

  if (aSlash && bSlash) {
    return a + b.slice(1)
  }

  return a + b
}
