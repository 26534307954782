import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { type XYPosition } from 'reactflow'

import { useFlags } from 'hooks/useFlags'

import { jobActions } from 'job-lib/store'

import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const DEFAULT_NOTE_WIDTH = 150
export const DEFAULT_NOTE_HEIGHT = 58

export const useMakeNote = () => {
  const dispatch = useDispatch()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)

  const makeNote = useCallback(
    (
      position: XYPosition | null,
      content?: string,
      width?: number,
      height?: number,
      isAIGenerated?: boolean,
      selection?: string[]
    ) => {
      if (!position) return

      if (rolloutEnableWorkingCopyProvider) {
        update((state) => {
          state.addNote({
            position,
            content,
            width,
            height
          })
        })
      } else {
        dispatch(
          jobActions.addNote({
            position: {
              x: position.x,
              y: position.y
            },
            content: content ?? '',
            size: {
              width: width ?? DEFAULT_NOTE_WIDTH * 2,
              height: height ?? DEFAULT_NOTE_HEIGHT
            },
            theme: 'white',
            isAIGenerated,
            selection
          })
        )
      }
    },
    [dispatch, rolloutEnableWorkingCopyProvider, update]
  )

  return { makeNote }
}
