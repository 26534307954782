import { useEffect, type FC } from 'react'

import { DataGrid } from '@matillion/component-library'

import classes from '../../UrlEditor.module.scss'
import { SortOrder, type GridColumns, type GridRow } from '../hooks/useGrid'

interface ContainerGridProps {
  columns: GridColumns
  rows: GridRow[]
  setSort: (sort: SortOrder) => void
}

const ContainerGrid: FC<ContainerGridProps> = ({ columns, rows, setSort }) => {
  useEffect(() => {
    return () => {
      setSort(SortOrder.ASC)
    }
  }, [setSort])

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      className={classes.Buckets__Table}
      defaultSort={{ container: SortOrder.ASC }}
      onSort={(state) => {
        setSort(state.container as SortOrder)
      }}
      hasFixedHeader
      compact
    />
  )
}

export default ContainerGrid
