import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  getFileTypeTranslationKeySuffix,
  type FileType
} from '@matillion/git-component-library'

import { useFlags } from 'hooks/useFlags'
import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useRenameFile } from 'hooks/useRenameFile'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

import { useProjectInfo } from '../../../hooks/useProjectInfo/useProjectInfo'
import { type FileDropDetails } from '../components/FileFolderDropDestination/FileFolderDropDestination'
import { usePipelineBrowserCommandProvider } from '../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../reducers/fileTreeReducer/types'
import { isFileExistingFile } from '../utils/validateFileName'

export interface UseMoveFileResponse {
  showWarningModal: boolean
  setShowWarningModal: (show: boolean) => void
  onConfirmMove: (hideWarning: boolean) => void
  moveFile: (details: FileDropDetails) => void
  fileType?: FileType
}

const WARNING_KEY = 'hide-move-pipeline-warning'

const useMoveFile = (): UseMoveFileResponse => {
  const { makeToast } = Toaster.useToaster()
  const { updateRecentlyAccessedPipeline } = useRecentlyAccessedPipelines()
  const { enableRecentlyAccessedPipelines } = useFlags()
  const { t } = useTranslation('translation', { keyPrefix: 'move-pipeline' })
  const { projectId, branchId } = useProjectInfo()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()

  const { files: pipelineSummaries } = usePipelines()
  const { renameFile } = useRenameFile()
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [fileDetails, setFileDetails] = useState<FileDropDetails>()

  const buildNewRuntimeName = (
    existingPipelineName: string,
    targetDirectory?: string
  ) => {
    if (targetDirectory) {
      return `${targetDirectory}/${existingPipelineName}`
    }

    return existingPipelineName
  }

  const buildNewFileDetails = (details: FileDropDetails) => {
    const { existingPipelineName, targetDirectory, extension } = details

    const newRuntimeName = buildNewRuntimeName(
      existingPipelineName,
      targetDirectory
    )

    const newFilePath = `${newRuntimeName}${extension}`

    return { newFilePath, newRuntimeName }
  }

  const moveFile = (details?: FileDropDetails) => {
    if (!details) {
      return
    }

    const {
      existingPipelineId,
      existingPipelineType,
      existingPipelineName,
      sourceDirectory,
      targetDirectory,
      extension
    } = details
    const existingFile = pipelineSummaries[existingPipelineId]

    const { newFilePath, newRuntimeName } = buildNewFileDetails(details)

    const fileAlreadyExists = isFileExistingFile(
      newRuntimeName,
      existingFile.type,
      pipelineSummaries
    )

    if (!fileAlreadyExists) {
      renameFile({ currentFilePath: existingPipelineId, newFilePath }).then(
        () => {
          enableRecentlyAccessedPipelines &&
            updateRecentlyAccessedPipeline({
              previousPipelineId: existingPipelineId,
              newPipelineId: newFilePath
            })
          onFileTreeCommand({
            targetDirectory,
            branch: branchId,
            project: projectId,
            fileDetails: {
              path: sourceDirectory,
              name: existingPipelineName,
              fileType: existingPipelineType,
              extension
            },
            type: FileTreeReducerType.MOVE_FILE
          })
        }
      )
    } else {
      const translationKeySuffix =
        getFileTypeTranslationKeySuffix(existingPipelineType)

      makeToast({
        type: 'error',
        title: t(`toast.uniqueness-error.${translationKeySuffix}.title`),
        message: t(`toast.uniqueness-error.${translationKeySuffix}.message`)
      })
    }
  }

  const handleDropFile = (details: FileDropDetails) => {
    const { sourceDirectory, targetDirectory } = details

    if (sourceDirectory === (targetDirectory ?? '')) {
      return
    }

    setFileDetails(details)

    const shouldHideWarningModal = localStorage.getItem(WARNING_KEY) === 'true'

    if (!shouldHideWarningModal) {
      setShowWarningModal(true)
    } else {
      moveFile(details)
    }
  }

  const handleConfirmMove = (hideWarning: boolean) => {
    localStorage.setItem(WARNING_KEY, String(hideWarning))
    setShowWarningModal(false)
    moveFile(fileDetails)
  }

  return {
    moveFile: handleDropFile,
    showWarningModal,
    setShowWarningModal,
    onConfirmMove: handleConfirmMove,
    fileType: fileDetails?.existingPipelineType
  }
}

export default useMoveFile
