import { type ReactNode } from 'react'

import { type DroppableProvided } from '@hello-pangea/dnd'

import classes from './InteractiveTabs.module.scss'

interface ScrollContainerProps {
  children: ReactNode
  droppableProvided: DroppableProvided
}

export const ScrollContainer = ({
  children,
  droppableProvided
}: ScrollContainerProps) => (
  <div className={classes.InteractiveTabs__Scrollable}>
    <div
      ref={droppableProvided.innerRef}
      {...droppableProvided.droppableProps}
      className={classes.InteractiveTabs__ScrollArea}
      data-testid="tabs-scroll-container"
    >
      {children}
    </div>
  </div>
)
