import { useState } from 'react'
import { useOnSelectionChange } from 'reactflow'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import {
  getSelectedComponentNames,
  mapNodesToComponentNames
} from 'file-editors/canvas/util/componentSelection'

export const useGetSelectedComponentNames = () => {
  const etlFlow = useEtlFlow()
  const [selectedComponentNames, setSelectedComponentNames] = useState<
    string[]
  >(() => getSelectedComponentNames(etlFlow))

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      setSelectedComponentNames(mapNodesToComponentNames(nodes))
    }
  })
  return {
    selectedComponentNames
  }
}
