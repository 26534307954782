import { LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { StorageEditorType } from 'modules/ParameterEditors/types'

const bucketsLookup: Record<StorageEditorType, LookUpType> = {
  [StorageEditorType.S3]: LookUpType.DYNAMIC_S3_BUCKETS,
  [StorageEditorType.AZURE]: LookUpType.DYNAMIC_BLOB_STORAGE_ACCOUNTS,
  [StorageEditorType.GCS]: LookUpType.DYNAMIC_GCS_BUCKETS
}

const getLookup = (storageEditorType?: StorageEditorType) => {
  // Legacy default behavior, included for backwards compatibility
  if (!storageEditorType) return LookUpType.DYNAMIC_BLOB_STORAGE_ACCOUNTS
  return bucketsLookup[storageEditorType]
}
export interface UseGetBucketsOptions {
  storageEditorType?: StorageEditorType
}
/**
 * Hook to retrieve the list of buckets (or containers, for Azure) for the given storage editor type.
 */
export const useGetBuckets = ({ storageEditorType }: UseGetBucketsOptions) => {
  const { job } = useFlaggedWorkingCopy()
  const variables = job?.variables ?? {}
  const gridVariables = job?.grids ?? {}
  return useGetParameterOptions({
    lookupType: getLookup(storageEditorType),
    isEnabled: !!storageEditorType,
    variables,
    gridVariables,
    requestType: 'parameter-options'
  })
}
