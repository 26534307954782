import { useTranslation } from 'react-i18next'

import { Tree, TreeItem, Typography } from '@matillion/component-library'
import { type FileType } from '@matillion/git-component-library'

import { type JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

import CleanJobRecord from './CleanJobRecord'
import classes from './JobRecordWithIssues.module.scss'

interface JobRecordWithIssuesProps {
  jobRecord: JobConversionRecord
  pipelineName: string
  pipelineType: FileType
  filePath: string
}

const JobRecordWithIssues = ({
  jobRecord,
  pipelineName,
  pipelineType,
  filePath
}: JobRecordWithIssuesProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.modal.results.jobs'
  })

  return (
    <Tree compact>
      <TreeItem
        leaf={
          <div
            className={classes.jobRecord}
            data-testid={`unclean-job-${pipelineName}`}
          >
            <CleanJobRecord
              pipelineName={pipelineName}
              pipelineType={pipelineType}
              filePath={filePath}
            />
          </div>
        }
        key={jobRecord.name}
        label={jobRecord.name}
        nodeId={jobRecord.name}
      >
        <div className={classes.treeItem}>
          <span className={classes.issuesInfo}>
            <span className={classes.issuesTitle}>
              <Typography format="mc" weight="bold">
                {t('variablesNotConverted.title')}
              </Typography>
            </span>

            <ul className={classes.issuesList}>
              {jobRecord.variablesNotConverted.map(({ name, type }) => (
                <li key={`${name}-${type}`}>
                  <span className={classes.issueVariableName}>
                    <Typography format="mc" as="span">
                      {name}
                    </Typography>
                  </span>
                  <span className={classes.issueVariableType}>
                    <Typography format="mc" as="span">
                      ({type})
                    </Typography>
                  </span>
                </li>
              ))}
            </ul>
          </span>
        </div>
      </TreeItem>
    </Tree>
  )
}

export default JobRecordWithIssues
