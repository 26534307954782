import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type JobSummaryId } from '../useGetJobSummaries'
import useGetProject from '../useGetProject/useGetProject'

export const getJobQueryKey = (
  projectId: string,
  branchId: string,
  jobId: JobSummaryId
) => [queryKeys.job, projectId, branchId, jobId]

export const useGetJob = (jobId: JobSummaryId) => {
  const { projectId, branchId } = useProjectInfo()
  const { getJob } = useDesignerApi()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  const getAJob = useCallback(async () => {
    const { data } = await getJob(
      warehouse,
      agentVersion,
      projectId,
      branchId,
      jobId
    )

    return data
  }, [getJob, projectId, branchId, agentVersion, jobId, warehouse])

  return useQuery(
    [queryKeys.job, projectId, branchId, jobId, warehouse],
    getAJob,
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: Boolean(jobId) && Boolean(warehouse) && Boolean(agentVersion)
    }
  )
}
