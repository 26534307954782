import { isString } from 'lodash'

import { type ComponentInstance } from 'job-lib/types/Job'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'

import { type WizardStepParameter } from '../types'
import { snowflakeAdvancedParametersConfig } from './outputs/modular/Snowflake'

const conditionalAdvancedParameters: Record<string, WizardStepParameter[]> = {
  'snowflake-output-connector-v0': snowflakeAdvancedParametersConfig
}

export const getConditionalAdvancedParameters = (
  componentInstance: ComponentInstance
) => {
  const outputId = getParameterValue(componentInstance.parameters, ['outputId'])
  return isString(outputId) ? conditionalAdvancedParameters[outputId] ?? [] : []
}
