import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'

export const useSchemaTables = (warehouse: string, schemaName: string) => {
  const { projectId, environmentId } = useProjectInfo()
  const { getSchemaTables } = useDesignerApi()

  return useQuery(
    [projectId, environmentId, queryKeys.listSchemaTables, schemaName],
    async () => {
      const { data } = await getSchemaTables(
        projectId,
        environmentId,
        warehouse,
        schemaName
      )
      return data.results
    },
    {
      enabled: !!warehouse
    }
  )
}
