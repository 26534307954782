import { type Dispatch } from 'react'

import { type DataGridRow } from '@matillion/component-library'

import {
  type JobVariableBehaviour,
  type JobVariableType,
  type JobVariableVisibility,
  type VariableScope
} from 'job-lib/types/Variables'

import { type ActiveStageState } from './ManageVariables'

export enum Stages {
  MANAGE = 'MANAGE',
  EDIT_JOB_VARIABLE = 'EDIT_JOB_VARIABLE',
  EDIT_PROJECT_VARIABLE = 'EDIT_PROJECT_VARIABLE',
  CREATE = 'CREATE',
  CREATE_GRID = 'CREATE_GRID',
  EDIT_GRID = 'EDIT_GRID'
}

export enum ReducerActions {
  UPDATE_FIELD = 'UPDATE_FIELD',
  VALIDATE_FORM = 'VALIDATE_FORM',
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_PROJECT_OVERRIDE = 'UPDATE_PROJECT_OVERRIDE',
  UPDATE_DEFAULT_VALUE = 'UPDATE_DEFAULT_VALUE',
  UPDATE_VARIABLE_TYPE = 'UPDATE_VARIABLE_TYPE',
  EDIT_DATA_GRID_VALUE = 'EDIT_DATA_GRID_ROW',
  ON_CHANGE_SELECTED_DATA_GRID = 'ON_CHANGE_SELECTED_DATA_GRID',
  ON_CHANGE_SELECT_ALL_DATA_GRID = 'ON_CHANGE_SELECT_ALL_DATA_GRID',
  ON_ADD_ROW_DATA_GRID_ACTION = 'ON_ADD_ROW_DATA_GRID_ACTION',
  ON_REMOVE_ROW_DATA_GRID_ACTION = 'ON_REMOVE_ROW_DATA_GRID_ACTION',
  ON_NEXT_STEP = 'ON_NEXT_STEP',
  ON_EXIT_TEXT_MODE = 'ON_EXIT_TEXT_MODE'
}

export enum Fields {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  VISIBILITY = 'VISIBILITY',
  BEHAVIOUR = 'BEHAVIOUR',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  PROJECT_DEFAULT_OVERRIDES = 'PROJECT_DEFAULT_OVERRIDES',
  VARIABLE_TYPE = 'VARIABLE_TYPE',
  COLUMN_GRID = 'COLUMN_GRID',
  DEFAULT_VALUE_GRID = 'DEFAULT_VALUE_GRID'
}

export enum ErrorStatuses {
  'RESERVED_WORD' = 'RESERVED_WORD',
  'DUPLICATE' = 'DUPLICATE',
  'EMPTY' = 'EMPTY',
  'NO_ERROR' = 'NO_ERROR',
  'SELECT_OPTION' = 'SELECT_OPTION',
  'INCORRECT_NAME_FORMAT' = 'INCORRECT_NAME_FORMAT',
  'INCORRECT_NUMBER_FORMAT' = 'INCORRECT_NUMBER_FORMAT',
  'SINGLE_$' = 'SINGLE_S',
  'TOO_LONG' = 'TOO_LONG'
}

export enum ProjectVariableBehaviour {
  COPIED = 'COPIED',
  SHARED = 'SHARED'
}

interface ContentWrapperProps {
  setStage: Dispatch<ActiveStageState>
}

export type ContentProps<T = unknown> = T extends object
  ? T & ContentWrapperProps
  : ContentWrapperProps

export interface CreateFormProps {
  variableScope: VariableScope
  variableType: JobVariableType
}

export interface SelectedProjectVariable {
  id: string
  name: string
  type: JobVariableType
}

export interface SelectedJobVariable {
  name: string
  type: JobVariableType
}

export interface EditFormProps extends CreateFormProps {
  variable: SelectedProjectVariable | SelectedJobVariable
}

export interface GridFormProps extends CreateFormProps {
  variable?: SelectedJobVariable
}

export interface FieldStatus<T> {
  value: T
  isValid: null | boolean
  error: string
}

export interface GridError {
  rowId: string
  columnName: string
  errorType: string
}

export interface GridValues {
  rows: GridVariableDataGridRow[]
  selectedRows: Array<string | number>
  errors: GridError[]
}

export type VariableBehaviour = JobVariableBehaviour | ProjectVariableBehaviour

export interface FieldState {
  [Fields.DESCRIPTION]: FieldStatus<string>
  [Fields.NAME]: FieldStatus<string>
  [Fields.DEFAULT_VALUE]: FieldStatus<string>
  [Fields.VISIBILITY]: FieldStatus<JobVariableVisibility | null>
  [Fields.BEHAVIOUR]: FieldStatus<VariableBehaviour | null>
  [Fields.PROJECT_DEFAULT_OVERRIDES]: FieldStatus<ProjectDefaultOverrides>
  [Fields.VARIABLE_TYPE]: FieldStatus<
    JobVariableType | ProjectVariableType | null
  >
  [Fields.COLUMN_GRID]: FieldStatus<GridValues>
  [Fields.DEFAULT_VALUE_GRID]: FieldStatus<GridValues>
}

export interface FormState extends FieldState {
  isFormValid: boolean
  variableScope: VariableScope
}

export type ProjectDefaultOverrides = Record<
  ProjectVariableOverride['environmentId'],
  ProjectVariableOverride
>

export interface ProjectVariableOverride {
  value: string | number | null
  environmentId: string
  isValid?: boolean
  error?: string
}

export type ProjectVariableType = JobVariableType.NUMBER | JobVariableType.TEXT

export interface ProjectVariable {
  id: string
  name: string
  type: ProjectVariableType
  behaviour: ProjectVariableBehaviour
  description: string
  value: string | number | null
  overrides: ProjectVariableOverride[]
}

export interface GridVariableDataGridRow extends DataGridRow {
  id: string
  expandedComponent?: never
  expandedComponentProps?: never
  columns: string[]
}
