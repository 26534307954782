import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'
import { ProjectType } from 'api/hooks/useGetProject/types'
import {
  type DatabricksConnectionResponse,
  type RedshiftConnectionResponse,
  type SnowflakeConnectionResponse
} from 'api/hooks/useGetProjectEnvironmentInfo/types'
import { useGetProjectEnvironmentInfo } from 'api/hooks/useGetProjectEnvironmentInfo/useGetProjectEnvironmentInfo'

const getSnowflakeDefaultValue = (
  connection: SnowflakeConnectionResponse,
  resourceID: string
): string | null => {
  switch (resourceID) {
    case 'warehouse':
      return connection.defaultWarehouse
    case 'database':
      return connection.defaultDatabase
    case 'schema':
      return connection.defaultSchema
    default:
      return null
  }
}
const getDatabricksDefaultValue = (
  connection: DatabricksConnectionResponse,
  resourceID: string
): string | null => {
  switch (resourceID) {
    case 'warehouse':
      return connection.clusterName
    case 'schema':
      return connection.defaultSchema
    case 'catalog':
      return connection.defaultCatalog
    default:
      return null
  }
}
const getRedshiftDefaultValue = (
  connection: RedshiftConnectionResponse,
  resourceID: string
): string | null => {
  switch (resourceID) {
    case 'database':
      return connection.defaultDatabase
    case 'databaseRs':
      return connection.defaultDatabase
    case 'schema':
      return connection.defaultSchema
    default:
      return null
  }
}

export const useGetEnvironmentDefaultValue = ({
  componentParameter,
  value
}: {
  componentParameter: ComponentParameter | undefined
  value: string | undefined
}): string | null => {
  const { data } = useGetProjectEnvironmentInfo()

  if (
    !data ||
    !componentParameter?.resourceID ||
    value !== '[Environment Default]' ||
    componentParameter === undefined
  ) {
    return null
  }

  if (data.warehouseConnection.type === ProjectType.DATABRICKS) {
    return getDatabricksDefaultValue(
      data.warehouseConnection,
      componentParameter.resourceID
    )
  } else if (data.warehouseConnection.type === ProjectType.SNOWFLAKE) {
    return getSnowflakeDefaultValue(
      data.warehouseConnection,
      componentParameter.resourceID
    )
  } else if (data.warehouseConnection.type === ProjectType.REDSHIFT) {
    return getRedshiftDefaultValue(
      data.warehouseConnection,
      componentParameter.resourceID
    )
  }
  return null
}
