import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar } from '@matillion/component-library'
import { Command } from 'cmdk'

import * as heap from 'utils/heap'
import { isMacOs } from 'utils/isMacOs'

import { Backdrop } from './Backdrop'
import classes from './CommandPalette.module.scss'
import { FileExplorer } from './FileExplorer'
import { Footer } from './Footer'
import { useCommandPaletteStore } from './store'

const COMMAND_KEY = 'k'

export const CommandPalette = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'commandPalette' })

  const [searchValue, setSearchValue] = useState<string>('')
  const searchRef = useRef<HTMLInputElement>(null)

  const { isOpen, setIsOpen } = useCommandPaletteStore()

  useEffect(() => {
    const handleOpen = (event: KeyboardEvent) => {
      /*
       * Target the modal element to check if it is open, we don't display the command palette if another modal is open
       */
      const isModalOpen = document.querySelector('[role="dialog"]') && !isOpen

      if (
        !isModalOpen &&
        event.key === COMMAND_KEY &&
        (isMacOs() ? event.metaKey : event.ctrlKey)
      ) {
        event.preventDefault()
        setIsOpen(!isOpen)
        heap.trackKeyboardShortcut('canvas', 'openCommandPalette')
      }
    }

    document.addEventListener('keydown', handleOpen, true)
    return () => {
      document.removeEventListener('keydown', handleOpen, true)
    }
  }, [isOpen, setIsOpen])

  useEffect(() => {
    // autoFocus the search input when the value is cleared using the clear button inside the input
    if (searchRef.current && searchValue === '') {
      searchRef.current?.focus()
    }
  }, [searchValue])

  const handleClose = () => {
    setIsOpen(false)
    setSearchValue('')
  }

  return (
    <Command.Dialog
      data-testid="command-palette-dialog"
      data-tracker-id="command-palette-dialog"
      open={isOpen}
      onOpenChange={(open: boolean) => {
        setIsOpen(open)
        setSearchValue('')
      }}
      className={classes.Dialog}
      // this needs to be false to prevent cmdk from trying to filter the list internally
      // we are handling filtering ourselves in the FileExplorer component because we are using virtualisation for performance.
      shouldFilter={false}
    >
      <Backdrop handleClose={handleClose}>
        <div className={classes.Search}>
          <SearchBar
            autoFocus
            data-testid="command-palette-search"
            placeholder={t('search.placeholder')}
            ref={searchRef}
            value={searchValue}
            onChange={({ target: { value } }) => {
              setSearchValue(value)
            }}
          />
        </div>
        <FileExplorer searchValue={searchValue} onFileSelection={handleClose} />
        <Footer />
      </Backdrop>
    </Command.Dialog>
  )
}
