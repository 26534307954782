import { useEffect, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  DataGrid,
  LoadingSpinner,
  SearchBar,
  Toaster,
  Typography,
  type DataGridColumnProps,
  type SortOpts
} from '@matillion/component-library'
import { orderBy } from 'lodash'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { ComponentUiConfigKey } from 'config/componentUiConfigs'

import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'

import {
  outputIdParameter,
  redshiftOutputConnectorParameterVal
} from 'job-lib/cisIds/knownComponentParameters'
import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { SortOrder } from 'modules/ParameterEditors/components/UrlEditor/components/hooks/useGrid'

import { getComponentUiConfigValue } from 'utils/getComponentUiConfigValue'

import {
  Actions,
  ConfigureTable,
  Elements,
  ExpandedRow,
  LoadStatus,
  TableName,
  type ExpandedRowTableProps
} from '../../components'
import {
  type ColumnConfig,
  type ConfigData,
  type DataSelectionConfig,
  type LoadStrategy,
  type MultiTableGridRow,
  type TableDef,
  type TableDefMap,
  type UpdateTableConfigHandler
} from '../../types'
import { isTableConfigured } from '../../utils/isTableConfigured'
import classes from './SelectTables.module.scss'

interface TablesGridRow extends MultiTableGridRow<ExpandedRowTableProps> {
  table: string
  isConfigured: boolean
  isSelected: boolean
  hasColumns: boolean
  isError: boolean
  tableDef?: TableDef
  tableConfig?: DataSelectionConfig
}

interface SelectTablesEditorProps {
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  componentInstance: ComponentInstance
  parameter: ComponentParameter
  data: ConfigData
  allTables: TableDefMap
  loadStrategy?: LoadStrategy
  onFetchTables: (data: TableDefMap) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onResetTableError: (tableName: string) => void
  onCancel: () => void
  onSave: () => void
  onConfigureTable: (tableName: string) => void
}

export const SelectTablesEditor: FC<SelectTablesEditorProps> = ({
  componentInstanceId,
  componentSummaryId,
  componentMetaData,
  componentInstance,
  parameter,
  data,
  loadStrategy,
  allTables,
  onFetchTables,
  onUpdateTableConfig,
  onResetTableError,
  onCancel,
  onSave,
  onConfigureTable
}) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  const [isQueryEnabled, setIsQueryEnabled] = useState(
    !allTables || allTables?.size === 0
  )
  const {
    isSuccess: isParameterListSuccess,
    isError: isParameterListError,
    data: parameterList,
    error: parameterListError
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: isQueryEnabled
  })

  const [searchTerm, setSearchTerm] = useState('')
  const [expandedRows, setExpandedRows] = useState<Map<string, boolean>>(
    new Map()
  )
  const [isTableNameSelectionConflict, setIsTableNameSelectionConflict] =
    useState<string | null>(null)
  const defaultSort: Record<string, SortOpts> = {
    loadStatus: 'DESC'
  }
  const [sortedColumn, setSortedColumn] =
    useState<Record<string, SortOpts>>(defaultSort)

  const isLookupDone = isQueryEnabled && isParameterListSuccess

  const getTablesFromParameterList = (
    columns: EditorColumn[]
  ): string[] | undefined => {
    if (columns[0]?.options && columns[0].options.length > 0) {
      return columns[0].options
    }
  }

  /**
   * Handles updating table config & validating table names are unique for a selected source.
   * Checks the selected source's target table name against existing selected sources to see
   * whether there are any conflicting names.
   * A flag is set which is an indication to display an error tooltip.
   * @param tableConfig the selected table config
   */
  const handleUpdateTableConfigWithValidateTableNameSelection = (
    tableConfig: DataSelectionConfig
  ) => {
    const targetTableNames = getSelectedTargetTableNames()
    const tableAlreadyExists =
      targetTableNames.includes(tableConfig.targetTableName.toLowerCase()) &&
      // isSelected represents the state after selection (will be selected) instead of before selection.
      // Check if the source will be selected or not. If it will be selected, then we know there is a conflict.
      // If it will not be selected, then we know the user may be toggling the same source repeatedly.
      tableConfig.isSelected
    if (tableAlreadyExists) {
      setIsTableNameSelectionConflict(tableConfig.targetTableName)
    } else {
      onUpdateTableConfig(tableConfig)
      setIsTableNameSelectionConflict(null)
    }

    function getSelectedTargetTableNames() {
      return data
        .filter((tc) => tc.targetTableName !== null && tc.isSelected)
        .map((config) => config.targetTableName?.toLowerCase())
    }
  }

  useEffect(() => {
    if (isLookupDone) {
      const options = getTablesFromParameterList(parameterList)
      if (allTables?.size === 0 && options) {
        const tables: TableDefMap = new Map()
        options.forEach((table) =>
          tables.set(table, { name: table, columns: undefined })
        )
        onFetchTables(tables)
      }

      setIsQueryEnabled(false)
    }
  }, [
    allTables?.size,
    isLookupDone,
    isParameterListError,
    onFetchTables,
    parameterList
  ])

  const selectedTableNames = useMemo(
    () =>
      data.filter((table) => table.isSelected).map((table) => table.dataSource),
    [data]
  )

  const columns: Array<DataGridColumnProps<TablesGridRow>> = [
    {
      key: 'table',
      sortable: true,
      title: t('multiTableConfig.tableSelection.table'),
      as: TableName,
      mapValues: (value) => ({ name: value.table })
    },
    {
      key: 'elements',
      sortable: false,
      title: t('multiTableConfig.tableSelection.elements'),
      as: Elements,
      mapValues: (table) => ({
        isSelected: table.isSelected,
        tableDef: table.tableDef,
        tableConfig: table.tableConfig,
        isTableNameSelectionConflict
      })
    },
    {
      key: 'loadStatus',
      sortable: true,
      title: t('multiTableConfig.tableSelection.loadStatus'),
      as: LoadStatus,
      mapValues: (table) => ({
        isConfigured: table.isConfigured,
        isSelected: table.isSelected,
        tableDef: table.tableDef
      })
    },
    {
      key: 'configure',
      sortable: false,
      title: '',
      as: ConfigureTable,
      mapValues: (table) => ({
        tableName: table.table,
        isExpanded: table.isExpanded,
        isSelected: table.isSelected,
        hasColumns: table.hasColumns,
        isError: table.isError,
        onEdit: () => {
          onConfigureTable(table.id)
        },
        onExpand: () => {
          handleExpand(table.id)
        }
      })
    }
  ]
  const getTableName = (tableConfig: DataSelectionConfig): string => {
    const outputConnectorVal = getParameterValue(componentInstance.parameters, [
      outputIdParameter
    ]) as string
    if (outputConnectorVal === redshiftOutputConnectorParameterVal) {
      return tableConfig.targetTableName.toLowerCase()
    }
    return tableConfig.targetTableName
  }

  function getTableConfigPriority(
    tableConfig: TablesGridRow,
    sortOrder: SortOpts
  ) {
    if (!tableConfig.isSelected || !tableConfig.tableDef?.columns) {
      return 0
    }

    if (tableConfig.isConfigured) {
      return sortOrder === 'ASC' ? 2 : 1
    }

    return sortOrder === 'ASC' ? 1 : 2
  }

  function sortTableAsc(
    tableConfigA: TablesGridRow,
    tableConfigB: TablesGridRow
  ) {
    // If priorities are equal, compare alphabetically
    const aTable = tableConfigA.table.toUpperCase()
    const bTable = tableConfigB.table.toUpperCase()
    return aTable.localeCompare(bTable)
  }

  function sortLoadStatusAsc(
    tableConfigA: TablesGridRow,
    tableConfigB: TablesGridRow
  ) {
    const aPriority = getTableConfigPriority(tableConfigA, 'ASC')
    const bPriority = getTableConfigPriority(tableConfigB, 'ASC')

    if (aPriority === bPriority) {
      return sortTableAsc(tableConfigA, tableConfigB)
    }

    return bPriority - aPriority
  }

  function sortLoadStatusDesc(
    tableConfigA: TablesGridRow,
    tableConfigB: TablesGridRow
  ) {
    const aPriority = getTableConfigPriority(tableConfigA, 'DESC')
    const bPriority = getTableConfigPriority(tableConfigB, 'DESC')

    if (aPriority === bPriority) {
      return sortTableAsc(tableConfigA, tableConfigB)
    }

    return bPriority - aPriority
  }

  const rows = data
    .filter(
      (tableConfig) =>
        tableConfig.dataSource
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        tableConfig.targetTableName
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
    )
    .map(
      (tableConfig): TablesGridRow => ({
        id: tableConfig.dataSource,
        table: getTableName(tableConfig),
        isConfigured: isTableConfigured(loadStrategy, tableConfig),
        isSelected: tableConfig.isSelected,
        isError: !!allTables?.get(tableConfig.dataSource)?.isError,
        hasColumns: !!allTables?.get(tableConfig.dataSource)?.columns,
        tableDef: allTables?.get(tableConfig.dataSource),
        tableConfig: data.find(
          (tc) => tc.dataSource === tableConfig.dataSource
        ),
        isExpanded: expandedRows.has(tableConfig.dataSource),
        expandedComponent: ExpandedRow,
        expandedComponentProps: {
          data: tableConfig,
          editorType: parameter.editorType
        }
      })
    )

  const sortedRows = () => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    if (selectedColumn === 'loadStatus') {
      if (sortOrder === 'ASC') {
        return rows.sort(sortLoadStatusAsc)
      }

      return rows.sort(sortLoadStatusDesc)
    }

    if (selectedColumn === 'table') {
      return orderBy(
        rows,
        [(row) => row.table.toLowerCase()],
        sortOrder === SortOrder.ASC ? 'asc' : 'desc'
      )
    }

    return orderBy(
      rows,
      selectedColumn,
      sortOrder === SortOrder.ASC ? 'asc' : 'desc'
    )
  }

  const maxSelections = getComponentUiConfigValue(
    componentSummaryId,
    ComponentUiConfigKey.MULTI_TABLE_SELECTION_MAX_SELECTIONS
  )
  const restrictSelectionCount = maxSelections && maxSelections < rows.length

  const handleExpand = (tableId: string) => {
    const newExpandedRows = new Map(expandedRows)

    if (expandedRows.has(tableId)) {
      newExpandedRows.delete(tableId)
      setExpandedRows(newExpandedRows)
    } else {
      newExpandedRows.set(tableId, true)
      setExpandedRows(newExpandedRows)
    }
  }

  const handleSelectedChange = (
    tableId: string | number,
    isSelected: boolean
  ) => {
    if (
      restrictSelectionCount &&
      isSelected &&
      selectedTableNames.length >= maxSelections
    ) {
      // the user has the max number of rows, so we show a message and return early so this row isn't selected
      makeToast({
        type: 'error',
        title: t('multiTableConfig.tableSelection.selectionLimitToast.title'),
        message: t(
          'multiTableConfig.tableSelection.selectionLimitToast.message',
          { maxSelections }
        )
      })
      return
    }

    const tableConfig = data.find((table) => table.dataSource === tableId)
    if (tableConfig) {
      handleUpdateTableConfigWithValidateTableNameSelection({
        ...tableConfig,
        isSelected
      })

      const tableDef = allTables?.get(tableConfig.dataSource)
      if (isSelected && tableDef && tableConfig?.dataSelection.length === 0) {
        tableConfig.dataSelection =
          tableDef?.columns?.map<ColumnConfig>((col) => ({
            name: col,
            isSelected: true
          })) ?? []
      }

      if (tableDef?.isError && isSelected) {
        onResetTableError(tableConfig.dataSource)
      }
    }
  }

  return (
    <>
      <div
        data-testid="multi-table-table-selection"
        className={classes.SelectTables}
      >
        {isQueryEnabled && !isParameterListError && (
          <div className={classes.SelectTables__Spinner}>
            <div>
              <LoadingSpinner />
            </div>
          </div>
        )}
        {isParameterListError && (
          <Alert
            className={classes.SelectTables__Alert}
            type="error"
            title={parameterListError?.title}
            message={<Typography>{parameterListError?.detail}</Typography>}
          />
        )}
        {!isQueryEnabled && !isParameterListError && allTables?.size === 0 && (
          <Alert
            className={classes.SelectTables__Alert}
            type="warning"
            title={t('multiTableConfig.common.emptyResponse.title')}
            message={
              <Typography>
                {t('multiTableConfig.common.emptyResponse.message')}
              </Typography>
            }
          />
        )}
        {!isQueryEnabled && !isParameterListError && (
          <>
            <div className={classes.SelectTables__Toolbar}>
              <div className={classes.SelectTables__SelectionCount}>
                {restrictSelectionCount && (
                  <Typography format="bcs" data-testid="table-selection-count">
                    {t(
                      'multiTableConfig.tableSelection.toolbar.selectionCount',
                      {
                        selected: selectedTableNames.length,
                        maxSelections,
                        totalAvailable: rows.length
                      }
                    )}
                  </Typography>
                )}
              </div>
              <div className={classes.SelectTables__SearchBar}>
                <SearchBar
                  data-testid="multi-table-config-search"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  placeholder={t(
                    'multiTableConfig.tableSelection.toolbar.searchPlaceholder'
                  )}
                />
              </div>
            </div>
            <DataGrid
              className={classes.SelectTables__DataGrid}
              rowClassName={classes.SelectTables__DataGridRow}
              isSelectable
              hasFixedHeader
              selectRowLabelName={(id) =>
                t('multiTableConfig.tableSelection.rowLabel', {
                  tableName: id
                })
              }
              selectedRows={selectedTableNames}
              onSelectedChange={handleSelectedChange}
              columns={columns}
              rows={sortedRows()}
              onSort={setSortedColumn}
              defaultSort={defaultSort}
            />
          </>
        )}
      </div>
      <Actions
        onSave={onSave}
        onCancel={onCancel}
        disableSave={isParameterListError || isQueryEnabled}
      />
    </>
  )
}
