import {
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type FC,
  type FocusEvent,
  type KeyboardEvent
} from 'react'

import { Field, Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { IconButton } from 'components/IconButton/IconButton'

import classes from './PencilTextInput.module.scss'

interface PencilTextInputProps {
  inputId: string
  value: string
  onEdit: (value: string) => void
  onChange: (value: string) => void
  isEditValid: (value: string) => boolean
  onCloseEditMode: () => void
  buttonLabel: string
  inputPlaceholder?: string
  hasError?: boolean
  errorText?: string
}

export const PencilTextInput: FC<PencilTextInputProps> = ({
  inputId,
  value,
  onEdit,
  onChange,
  isEditValid,
  onCloseEditMode,
  buttonLabel,
  inputPlaceholder,
  hasError,
  errorText
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(value)

  useEffect(() => {
    if (isInEditMode && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isInEditMode])

  const handleOnEdit = (newValue: string) => {
    if (isEditValid(newValue)) {
      onEdit(newValue)
    } else {
      setInputValue(value)
    }
    onCloseEditMode()
    setIsInEditMode(false)
  }

  return (
    <div className={classes.PencilTextInput}>
      <div
        className={classNames(classes.PencilTextInput__StaticView, {
          [classes['PencilTextInput__StaticView--Hidden']]: isInEditMode
        })}
      >
        <Typography
          data-testid="component-name-edit-text"
          className={classNames(classes.PencilTextInput__StaticView__Name, {
            [classes['PencilTextInput__StaticView__Name--Error']]: hasError
          })}
          weight="bold"
        >
          {value}
        </Typography>
        <IconButton
          data-testid="component-name-edit-button"
          className={classes.PencilTextInput__StaticView__PencilButton}
          label={buttonLabel}
          onClick={() => {
            setIsInEditMode(true)
          }}
        >
          <Icon.Pencil />
        </IconButton>
      </div>
      <div>
        <Field
          data-testid="component-name-edit-input"
          id={inputId}
          hasError={hasError}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
            onChange(e.target.value)
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleOnEdit(inputValue)
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
              handleOnEdit(value)
              setInputValue(value)
            } else if (e.key === 'Enter') {
              handleOnEdit(inputValue)
            }
          }}
          errorText={errorText}
          placeholder={inputPlaceholder}
          className={classNames(
            {
              [classes['PencilTextInput__EditView__Field--Error']]: !!errorText,
              [classes['PencilTextInput__EditView__Field--Hidden']]:
                !isInEditMode
            },
            classes.PencilTextInput__EditView__Field
          )}
          inputRef={inputRef}
          compact={true}
        />
      </div>
    </div>
  )
}
