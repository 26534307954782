import React, { FunctionComponent, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { AuthHelpersContext } from '../context'
import encodeLocation from '../utils/encodeLocation'
import useEnvironment from '../effects/useEnvironment'
import { modifyUtmParams } from './modifyUtmParams'
import { toAccountAuthScope } from './selectedAccountUtils'
import { useAccountId } from './AccountIdProvider'

import type { RedirectLoginOptions } from '@auth0/auth0-react'

const useAuthHelpers = () => {
  const { hubUrl } = useEnvironment()
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()
  const accountId = useAccountId()

  const getToken = useCallback(
    async ({ scope, ...options } = {}) => {
      return getAccessTokenSilently({
        ...options,
        scope: toAuthScope({ accountId, scope })
      })
    },
    [getAccessTokenSilently, accountId]
  ) as typeof getAccessTokenSilently

  const logoutHandler = useCallback(
    ({ returnTo = window.location.origin, ...options } = {}) => {
      return logout({ returnTo, federated: true, ...options })
    },
    [logout]
  )

  const loginHandler = useCallback(
    async (redirectLoginOptions: RedirectLoginOptions = {}) => {
      const { origin, pathname, search, hash } = window.location

      const searchParams = Object.fromEntries(
        new URLSearchParams(search).entries()
      )

      const {
        /* eslint-disable @typescript-eslint/naming-convention */
        utm_source,
        utm_campaign,
        utm_medium,
        utm_content,
        utm_term,
        login_hint,
        sign_up,
        partner_connect,
        hmac,
        /* eslint-enable @typescript-eslint/naming-convention */
        legacySubdomain,
        ...redirectSafeParams
      } = searchParams

      const redirectSearch = toLocationSearch(redirectSafeParams)
      const returnTo = {
        origin,
        pathname,
        search: redirectSearch
      }

      function getRedirectUri() {
        if (origin === hubUrl) {
          return origin
        } else if (window.__MICROVERSE__) {
          const searchSuffix = legacySubdomain
            ? `&legacySubdomain=${legacySubdomain}`
            : ''
          return `${origin}?returnTo=${encodeLocation(returnTo)}${searchSuffix}`
        }

        return `${hubUrl}?returnTo=${encodeLocation(returnTo)}`
      }

      const { signUp, ...options } = redirectLoginOptions

      return loginWithRedirect({
        login_hint,
        signUp: signUp || sign_up === 'true' ? true : undefined,
        ...options,
        ...modifyUtmParams({
          utm_source,
          utm_campaign,
          utm_medium,
          utm_content,
          utm_term
        }),
        partner_connect,
        hmac,
        // The appState is passed in Auth0Provider's onRedirectCallback function as a parameter.
        appState: {
          // Due to public routes in legacy apps, the loginHandler will still be invoked by MFEs.
          // AuthProvider will live in the app shell - the shape of the appState needs to be recognisable by the shell.
          redirectPath: `${pathname}${redirectSearch}${hash}`
        },
        redirectUri: getRedirectUri()
      })
    },
    [loginWithRedirect, hubUrl]
  )

  return {
    getToken,
    login: loginHandler,
    logout: logoutHandler
  }
}

function toLocationSearch(params: Record<string, string>) {
  if (Object.keys(params).length === 0) {
    return ''
  }

  return `?${new URLSearchParams(params).toString()}`
}

interface AuthHelpersProviderProps {
  children: React.ReactNode
}

const AuthHelpersProvider: FunctionComponent<AuthHelpersProviderProps> = ({
  children
}) => {
  const authHelpers = useAuthHelpers()

  return (
    <AuthHelpersContext.Provider value={authHelpers}>
      {children}
    </AuthHelpersContext.Provider>
  )
}

interface ToAuthScopeProps {
  accountId?: string
  scope?: string
}

function toAuthScope({ accountId, scope }: ToAuthScopeProps) {
  const accountScope = toAccountAuthScope(accountId)
  if (accountScope) {
    return scope ? `${accountScope} ${scope}` : accountScope
  }

  return scope
}

export { useAuthHelpers }
export default AuthHelpersProvider
