import { useEffect, useState, type FC } from 'react'

import {
  DragDropContext,
  type DropResult,
  type OnDragEndResponder,
  type OnDragStartResponder
} from '@hello-pangea/dnd'
import { merge } from 'lodash'
import { type DeepPartial } from 'types/DeepPartial'

import { Column, ColumnLabel } from './components/Column'
import { Controls } from './components/Controls'
import { useWindowClick } from './hooks/useWindowClick'
import classes from './ItemSelect.module.scss'
import { type OptionProps } from './types'
import { DOWN, moveItemVertical, UP } from './utils/contols'
import {
  dragLeftToRight,
  dragRightToLeft,
  reorderRightColumn,
  unselectOppositeColumn
} from './utils/dragndrop'
import { calculateColumns, multiSelect } from './utils/utils'

interface ItemSelectProps {
  invalidItems: string[]
  items: string[]
  selected: string[]
  onSelect: (items: string[]) => void
  options?: DeepPartial<OptionProps>
}

const defaultOptions: OptionProps = {
  // must match Column__TableList height
  listHeight: 354,
  // must match ListItem height
  itemSize: 48,
  showDragHandle: true,
  labels: {
    // extracted from the UI
    allItemsLabel: 'All tables',
    selectedItemsLabel: 'Tables to extract and load',
    searchLabel: 'Filter',
    noDataAvailable: 'No items available',
    // extracted from the SVGs
    controlAddAll: 'Add all',
    controlAddOne: 'Add one',
    controlRemoveAll: 'Remove all',
    controlRemoveOne: 'Remove one',
    controlReset: 'Reset',
    // new options added
    controlMoveUp: 'Move up',
    controlMoveDown: 'Move down'
  },
  customControls: undefined
}

export const ItemSelect: FC<ItemSelectProps> = ({
  invalidItems,
  items,
  selected,
  onSelect,
  options: initOptions
}) => {
  const [originalSelected] = useState(selected)
  const [allItemsFilter, setAllItemsFilter] = useState('')
  const [selectedItemsFilter, setSelectedItemsFilter] = useState('')
  const [leftColumn, setLeftColumn] = useState<string[]>([])
  const [rightColumn, setRightColumn] = useState<string[]>([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [draggingItem, setDraggingItem] = useState<string | null>(null)
  const [isClean, setIsClean] = useState(true)

  const options = merge(defaultOptions, initOptions)

  const unSelectAll = () => {
    setSelectedItems([])
  }

  useWindowClick(unSelectAll)

  useEffect(() => {
    const result = calculateColumns(items, selected)

    setLeftColumn(result.leftColumn)
    setRightColumn(result.rightColumn)
  }, [items, selected])

  const allItemsFiltered = leftColumn.filter((item) =>
    item?.toLowerCase().includes(allItemsFilter.toLowerCase())
  )

  const selectedItemsFiltered = rightColumn.filter((item) =>
    item?.toLowerCase().includes(selectedItemsFilter.toLowerCase())
  )

  /* istanbul ignore next */
  const onDragStart: OnDragStartResponder = (start) => {
    const rightColumnWithFilter = selectedItems.filter((item) =>
      selectedItemsFiltered.includes(item)
    )
    const leftColumnWithFilter = selectedItems.filter((item) =>
      allItemsFiltered.includes(item)
    )

    unselectOppositeColumn({
      droppableId: start.source.droppableId,
      draggableId: start.draggableId,
      rightColumn,
      leftColumn,
      source: start.source,
      leftColumnSelected: leftColumnWithFilter,
      rightColumnSelected: rightColumnWithFilter,
      setSelectedItems
    })

    setDraggingItem(start.draggableId)
  }

  /* istanbul ignore next */
  const onDragEnd: OnDragEndResponder = (result: DropResult): void => {
    const { reason, source, destination } = result

    if (!destination || reason === 'CANCEL') {
      setDraggingItem(null)
      return
    }

    if (
      source.droppableId === destination.droppableId &&
      source.droppableId === 'rightColumn'
    ) {
      reorderRightColumn({
        rightColumn,
        source,
        destination,
        filteredItems: selectedItemsFiltered,
        selectedItems,
        setRightColumn,
        onSelect
      })
    } else if (
      source.droppableId === 'leftColumn' &&
      destination.droppableId === 'rightColumn'
    ) {
      dragLeftToRight({
        rightColumn,
        leftColumn,
        destination,
        filteredItems: allItemsFiltered,
        selectedItems,
        setLeftColumn,
        setRightColumn,
        onSelect
      })
    } else if (
      source.droppableId === 'rightColumn' &&
      destination.droppableId === 'leftColumn'
    ) {
      dragRightToLeft({
        items,
        rightColumn,
        leftColumn,
        filteredItems: selectedItemsFiltered,
        selectedItems,
        setLeftColumn,
        setRightColumn,
        onSelect
      })
    }
    setDraggingItem(null)
    setIsClean(false)
  }

  const handleReset = () => {
    const result = calculateColumns(items, originalSelected)

    setLeftColumn(result.leftColumn)
    setRightColumn(result.rightColumn)
    setIsClean(true)
    onSelect(result.rightColumn.filter(Boolean))
  }

  const handleMoveUp = () => {
    const newRightColumn = moveItemVertical({
      rightColumn,
      selectedItems,
      direction: UP
    })

    setRightColumn(newRightColumn)
    setDraggingItem(null)
    setIsClean(false)
    onSelect(newRightColumn.filter(Boolean))
  }

  const handleMoveDown = () => {
    const newRightColumn = moveItemVertical({
      rightColumn,
      selectedItems,
      direction: DOWN
    })

    setRightColumn(newRightColumn)
    setDraggingItem(null)
    setIsClean(false)
    onSelect(newRightColumn.filter(Boolean))
  }

  const handleMoveAllRight = () => {
    const newColumns = moveItemHorizontal('right', true)

    setRightColumn(newColumns.rightColumn)
    setLeftColumn(newColumns.leftColumn)
    setIsClean(false)
    onSelect(newColumns.rightColumn.filter(Boolean))
  }

  const handleMoveRight = () => {
    const newColumns = moveItemHorizontal('right', false)

    setRightColumn(newColumns.rightColumn)
    setLeftColumn(newColumns.leftColumn)
    setIsClean(false)
    onSelect(newColumns.rightColumn.filter(Boolean))
  }

  const handleMoveLeft = () => {
    const newColumns = moveItemHorizontal('left', false)

    setRightColumn(newColumns.rightColumn)
    setLeftColumn(newColumns.leftColumn)
    setIsClean(false)
    onSelect(newColumns.rightColumn.filter(Boolean))
  }

  const handleMoveAllLeft = () => {
    const newColumns = moveItemHorizontal('left', true)

    setRightColumn(newColumns.rightColumn)
    setLeftColumn(newColumns.leftColumn)
    setIsClean(false)
    onSelect(newColumns.rightColumn.filter(Boolean))
  }

  const toggleSelection = (item: string) => {
    const wasSelected = selectedItems.includes(item)

    /* istanbul ignore next */
    const newSelectedItems =
      !wasSelected || selectedItems.length > 1 ? [item] : []

    setSelectedItems(newSelectedItems)
  }

  const toggleSelectionInGroup = (item: string) => {
    const index = selectedItems.indexOf(item)

    if (index === -1) {
      setSelectedItems((previouslySelected) => [...previouslySelected, item])
      return
    }

    const selectedItemsCopy = [...selectedItems]
    selectedItemsCopy.splice(index, 1)
    setSelectedItems(selectedItemsCopy)
  }

  const multiSelectTo = (item: string) => {
    const itemList = allItemsFiltered.includes(item)
      ? allItemsFiltered.filter(Boolean)
      : selectedItemsFiltered.filter(Boolean)

    const updated = multiSelect(itemList, selectedItems, item)
    setSelectedItems(updated)
  }

  const moveItemHorizontal = (direction: string, all: boolean) => {
    if (direction === 'right') {
      return moveItemHorizontalRight(all)
    }
    // direction === 'left'
    return moveItemHorizontalLeft(all)
  }

  const moveItemHorizontalRight = (all: boolean) => {
    const result = {
      leftColumn: [...leftColumn],
      rightColumn: [...rightColumn]
    }
    result.rightColumn.push(
      ...leftColumn.filter(
        (item) =>
          (all || selectedItems.includes(item)) &&
          allItemsFiltered.includes(item)
      )
    )
    result.leftColumn = result.leftColumn.filter(
      (element) => !result.rightColumn.includes(element)
    )
    return result
  }

  const moveItemHorizontalLeft = (all: boolean) => {
    const result = {
      leftColumn: [...leftColumn],
      rightColumn: [...rightColumn]
    }

    const shouldMoveToLeft = (item: string) => {
      return (
        leftColumn.includes(item) ||
        (selectedItems.includes(item) &&
          selectedItemsFiltered.includes(item)) ||
        (all && selectedItemsFiltered.includes(item))
      )
    }
    const newLeftColumn = items.filter(shouldMoveToLeft)

    result.leftColumn = newLeftColumn
    result.rightColumn = result.rightColumn.filter(
      (element) =>
        !(newLeftColumn.includes(element) || !items.includes(element))
    )
    return result
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className={classes.ItemSelect}>
        <Column
          invalidItems={invalidItems}
          columnData={leftColumn}
          columnLabel={ColumnLabel.leftColumn}
          description={options.labels.allItemsLabel}
          filter={allItemsFilter}
          setFilter={setAllItemsFilter}
          items={allItemsFiltered}
          selectedItems={selectedItems}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          multiSelectTo={multiSelectTo}
          draggingItem={draggingItem}
          options={options}
        />

        <Controls
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          selectedItems={selectedItems}
          handleReset={handleReset}
          handleMoveUp={handleMoveUp}
          handleMoveDown={handleMoveDown}
          handleMoveRight={handleMoveRight}
          handleMoveAllRight={handleMoveAllRight}
          handleMoveLeft={handleMoveLeft}
          handleMoveAllLeft={handleMoveAllLeft}
          isClean={isClean}
          options={options}
        />

        <Column
          invalidItems={invalidItems}
          columnData={rightColumn}
          columnLabel={ColumnLabel.rightColumn}
          description={options.labels.selectedItemsLabel}
          filter={selectedItemsFilter}
          setFilter={setSelectedItemsFilter}
          items={selectedItemsFiltered}
          selectedItems={selectedItems}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          multiSelectTo={multiSelectTo}
          draggingItem={draggingItem}
          options={options}
        />
      </div>
    </DragDropContext>
  )
}
