import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  useGetFileSummariesQueryKey,
  useGitQueryClient
} from '@matillion/git-component-library'
import { useMutation } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import { ImportInputField } from 'components/ImportInputField/ImportInputField'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import useRefreshPipelines from 'modules/core/EtlDesigner/hooks/useRefreshPipelines'
import {
  FileBrowserCommand,
  usePipelineBrowserCommandProvider
} from 'modules/FileBrowser'
import { FileTreeReducerType } from 'modules/FileBrowser/reducers/fileTreeReducer/types'

import { useWorkingTreeStoreClient } from '../useWorkingTreeStoreClient/useWorkingTreeStoreClient'
import {
  importPipelinesApi,
  type ImportPipelinesResponse
} from './importPipelinesApi'

interface ImportPipelineMutation {
  path: string
  file: File
}

const showAlertOnUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = true
}

export const useImportPipelines = (id: string) => {
  const { t } = useTranslation()
  const { refreshPipelines } = useRefreshPipelines()
  const importInputRef = useRef<HTMLInputElement>(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [importResponse, setImportResponse] = useState<ImportPipelinesResponse>(
    {
      importedFiles: [],
      failures: []
    }
  )
  const { onCommand } = usePipelineBrowserCommandProvider()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { projectId, branchId } = useProjectInfo()
  const client = useWorkingTreeStoreClient()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const gitQueryClient = useGitQueryClient()
  const fileSummariesQueryKey = useGetFileSummariesQueryKey()

  const importPipelines = useMutation(
    async ({ path, file }: ImportPipelineMutation) => {
      window.addEventListener('beforeunload', showAlertOnUnload)

      makeToast({
        type: 'info',
        title: t('import-to-folder.import-in-progress'),
        message: ''
      })

      const response = await importPipelinesApi({
        client,
        projectId,
        branchName: branchId,
        path,
        file
      })

      clearToasts()

      setImportResponse(response)

      if (response.failures.length > 0) {
        makeToast({
          type: 'error',
          title:
            response.importedFiles.length > 0
              ? t('import-to-folder.errors.import-partially-successful.title')
              : t('import-to-folder.errors.import-failed.title'),
          message:
            response.importedFiles.length > 0
              ? t('import-to-folder.errors.import-partially-successful.message')
              : t('import-to-folder.errors.import-failed.message'),
          action: {
            text: t('import-to-folder.errors.view-files'),
            onClick: () => {
              setIsErrorModalOpen(true)
              clearToasts()
            }
          }
        })

        return
      }

      if (response.failures.length === 0 && response.importedFiles.length > 0) {
        makeToast({
          type: 'success',
          title: t('import-to-folder.success'),
          message: ''
        })
      }
    },
    {
      onSettled() {
        refreshPipelines({
          refreshFileContents: true
        })

        gitQueryClient.invalidateQueries(fileSummariesQueryKey)

        window.removeEventListener('beforeunload', showAlertOnUnload)
      },
      onError(e: AxiosError) {
        makeToast({
          type: 'error',
          title: t('import-to-folder.errors.import-failed.title'),
          message: e.message
        })
      }
    }
  )

  const handleImportPipelines = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    importInputRef.current?.click()
  }

  const handleImportInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

    if (file && file.size > MAX_FILE_SIZE) {
      makeToast({
        type: 'error',
        title: t('import-to-folder.errors.zip-file-too-big'),
        message: ''
      })
      return
    }

    /* istanbul ignore else */
    if (file) {
      switch (file.type) {
        case 'application/zip':
        case 'application/zip-compressed':
        case 'application/x-zip':
        case 'application/x-zip-compressed':
          importPipelines.mutate({ path: id, file })
          break
        case 'application/json':
          onCommand({
            payload: file,
            type: FileBrowserCommand.IMPORT_METL_PIPELINES
          })
          onFileTreeCommand({
            fileDetails: { path: id },
            type: FileTreeReducerType.SET_FOLDER_PATH
          })
          break
        default:
          makeToast({
            type: 'error',
            title: t('import-to-folder.errors.invalid-file-type', {
              fileType: file.type
            }),
            message: ''
          })
      }
    }
  }

  return {
    ...importPipelines,
    handleImportPipelines,
    ImportInputField: () => (
      <ImportInputField
        id={id}
        importInputRef={importInputRef}
        handleImportInputChange={handleImportInputChange}
        isErrorModalOpen={isErrorModalOpen}
        closeErrorModal={() => {
          setIsErrorModalOpen(false)
        }}
        response={importResponse}
      />
    )
  }
}
