import { useEffect, type FC } from 'react'

import classNames from 'classnames'

import { useParameterEditorState } from 'hooks/useParameterEditorState/useParameterEditorState'

import { ComponentToolbar } from 'modules/ComponentParameters/components/ComponentToolbar'

import classes from './ComponentParameters.module.scss'
import ComponentParametersContent from './ComponentParametersContent'
import { type ComponentParametersWizardProps } from './types'

export const ComponentParametersWizard: FC<ComponentParametersWizardProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  const { isInlineEditorOpen, closeInlineEditor } = useParameterEditorState()

  useEffect(() => {
    return () => {
      if (isInlineEditorOpen) {
        closeInlineEditor()
      }
    }
  }, [closeInlineEditor, isInlineEditorOpen])

  return (
    <div className={classes.ComponentParametersWizard}>
      <ComponentToolbar
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
      />
      <div
        className={classNames(classes.ComponentParametersWizard__Content, {
          [classes.ComponentParametersWizard__Hidden]: isInlineEditorOpen
        })}
      >
        <ComponentParametersContent
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          onEdit={onEdit}
          onBlur={onBlur}
        />
      </div>
      <div
        id="component-parameters-editor-container"
        data-testid="component-parameters-editor-container"
        className={classNames(
          classes.ComponentParametersWizard__InlineEditorContainer,
          {
            [classes.ComponentParametersWizard__Hidden]: !isInlineEditorOpen
          }
        )}
      />
    </div>
  )
}
