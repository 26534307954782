import { type JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

import CleanJobRecord from './CleanJobRecord'
import getFileDetails from './getFileDetails'
import JobRecordWithIssues from './JobRecordWithIssues'

export interface RecordContentsProps {
  jobRecord: JobConversionRecord
}

const RecordContents = ({ jobRecord }: RecordContentsProps) => {
  const { pipelineType, pipelineName, filePath } = getFileDetails(jobRecord)

  if (jobRecord.variablesNotConverted.length > 0) {
    return (
      <JobRecordWithIssues
        jobRecord={jobRecord}
        pipelineName={pipelineName}
        pipelineType={pipelineType}
        filePath={filePath}
      />
    )
  }

  return (
    <CleanJobRecord
      pipelineName={pipelineName}
      pipelineType={pipelineType}
      filePath={filePath}
    />
  )
}

export default RecordContents
