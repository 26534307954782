let tableStyleElement: HTMLStyleElement | null = null
let cursorStyleElement: HTMLStyleElement | null = null

const createStyleElements = () => {
  if (!tableStyleElement) {
    tableStyleElement = document.createElement('style')
    tableStyleElement.innerHTML = ''

    document.head.appendChild(tableStyleElement)
  }

  if (!cursorStyleElement) {
    cursorStyleElement = document.createElement('style')
    cursorStyleElement.innerHTML = ''

    document.head.appendChild(cursorStyleElement)
  }
}

export type StyleUpdateType = 'table' | 'cursor'

export const cleanupStyle = () => {
  if (tableStyleElement) {
    tableStyleElement.remove()
    tableStyleElement = null
  }

  if (cursorStyleElement) {
    cursorStyleElement.remove()
    cursorStyleElement = null
  }
}

export const updateStyle = (element: StyleUpdateType, style: string) => {
  createStyleElements()

  if (element === 'table' && tableStyleElement) {
    tableStyleElement.innerHTML = style
  }

  if (element === 'cursor' && cursorStyleElement) {
    cursorStyleElement.innerHTML = style
  }
}
