import { useTranslation } from 'react-i18next'

import {
  Typography,
  type AutoCompleteItem,
  type MultiSelectOnChangeProps
} from '@matillion/component-library'
import {
  type Cell,
  type CellContext,
  type HeaderContext
} from '@tanstack/react-table'
import classNames from 'classnames'
import { uniq } from 'lodash'

import {
  type ConflictGridCell,
  type ConflictGridRow,
  type ConflictsColumnName,
  type ConflictsGridMeta,
  type SetConflictRow
} from '../../../types'
import { isConflictsMetaData } from '../../../utils'
import { ColumnSelectionDropdown } from '../../ColumnSelectionDropdown/ColumnSelectionDropdown'
import classes from './ConflictsGridColumn.module.scss'

const onDropdownChange = (
  newValue: { target: AutoCompleteItem } | MultiSelectOnChangeProps,
  columnName: ConflictsColumnName,
  rowId: string,
  setRowData: SetConflictRow
) => {
  const value = newValue.target.value
  if (!value) return

  if (Array.isArray(value)) {
    setRowData(columnName, parseInt(rowId), uniq(value.map((v) => v.id)))
  } else if (value.id === null || typeof value.id === 'string') {
    setRowData(columnName, parseInt(rowId), value.id)
  }
}

const getTableNameFromRow = (cells: Array<Cell<ConflictGridRow, unknown>>) => {
  if (cells.length) {
    const tableCell = cells[0]
    const tableCellValue = tableCell.getValue() as ConflictGridCell
    if (typeof tableCellValue.value === 'string') {
      return tableCellValue.value
    }
  }
}

export const ConflictsGridHeader = (
  info: HeaderContext<ConflictGridRow, ConflictGridCell>
) => {
  const meta = info.column.columnDef.meta as ConflictsGridMeta
  return (
    <div
      className={classNames(classes.TextCell, classes['TextCell--Truncate'])}
    >
      <Typography format="bcs">{meta.columnTitle}</Typography>
    </div>
  )
}

export const ConflictsGridText = (
  info: CellContext<ConflictGridRow, ConflictGridCell>
) => {
  const cell = info.cell.getValue()

  return (
    <div className={classNames(classes.TextCell, classes['TextCell--Wrap'])}>
      <Typography format="bcs">{cell.value}</Typography>
    </div>
  )
}

export const DynamicConflictsGridDropdown = (
  info: CellContext<ConflictGridRow, ConflictGridCell>
) => {
  const { t } = useTranslation()

  if (!isConflictsMetaData(info.column.columnDef.meta)) {
    return null
  }

  const { columnName, placeholder, columnTitle, setRowData } =
    info.column.columnDef.meta
  const { options, value, readonly } = info.cell.getValue()
  const tableName = getTableNameFromRow(info.cell.row.getAllCells())

  if (!tableName) return

  return (
    <ColumnSelectionDropdown
      title={t('multiTableConfig.applyToAll.conflictsGrid.cellTitle', {
        columnTitle,
        tableName
      })}
      tableName={tableName}
      availableItems={options ?? []}
      value={value}
      readonly={readonly}
      compact
      noBorders
      onChange={(_, newValue) => {
        if (setRowData) {
          onDropdownChange(newValue, columnName, info.row.id, setRowData)
        }
      }}
      placeholder={placeholder}
    />
  )
}
