import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { type Edge, type Node } from 'reactflow'

import { FileType } from '@matillion/git-component-library'

import {
  type ComponentSummary,
  type ComponentSummaryId
} from 'api/hooks/useGetComponentSummaries'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useFlags } from 'hooks/useFlags'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import {
  getJobConnectors,
  getOutputPortTypeFromPipelineField
} from 'job-lib/job-functions/job-functions'
import type { RootState } from 'job-lib/store'
import { type OrchestrationJobConnectors } from 'job-lib/store/jobSlice/job.types'
import {
  ComponentActivationStatus,
  type ConnectionPortTypeT,
  type Port
} from 'job-lib/types/Components'
import {
  type ComponentInstanceId,
  type ConnectorId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import {
  getComponentPorts,
  getComponentReactFlowId,
  getIdFromReactFlowId,
  getNoteReactFlowId,
  isComponentNode,
  isNodeSupported,
  parseConnectorName
} from './utils'

export type Job = OrchestrationJob | TransformationJob | null

export enum EtlCanvasNodeType {
  NODE = 'node',
  ITERATOR = 'iterator',
  NOTE = 'note'
}

export type EtlCanvasNodeId = `component-${number}` | `note-${number}`

export type NoteNode = Node<NoteNodeData>
export type ComponentNode = Node<ComponentNodeData>
export type EtlCanvasNode = NoteNode | ComponentNode

export interface ComponentNodeData {
  imageUrl: string
  label: string
  inputPorts: Port[]
  outputPorts: Port[]
  iteratorPorts: Port[]
  hasInputConnection?: boolean
  hasOutputConnection?: boolean
  outputPortsConnected: ConnectionPortTypeT[]
  attachedNodes?: ComponentNode[]
  summaryId: ComponentSummaryId
  skipped?: boolean
}

export interface NoteNodeData {
  content: string
  width: number
  height: number
  theme: string
  isAIGenerated?: boolean
  selection?: string[]
}

export type EtlCanvasEdge = Edge<EtlCanvasEdgeData>

export interface EtlCanvasEdgeData {
  sourceHandle: string
  sourceComponent: ComponentNodeData
  targetComponent: ComponentNodeData
  job: TransformationJob | OrchestrationJob
}

export interface EtlCanvasModel {
  nodes: Map<EtlCanvasNodeId, EtlCanvasNode>
  edges: Map<ConnectorId, EtlCanvasEdge>
}

export type NodeType = 'note' | 'component'
export interface NodeInfo {
  type: NodeType
  id: number
  data: ComponentNodeData | NoteNodeData
}

const START_NODE_ID = 'start'

export const useCanvasModel = (
  pipeline: TransformationJob | OrchestrationJob
): EtlCanvasModel => {
  const { getIcon } = useComponentInfo()
  const { getByImplementationId } = useGetComponentSummary()
  const pipelineType = useSelector<RootState>(
    (state) => state.job.jobType
  ) as FileType
  const { enableRelativeStartPositionAndZoom } = useFlags()

  return useMemo(() => {
    /* maps are used to preserve the order of keys in the returned collections */
    const nodes = new Map<EtlCanvasNodeId, EtlCanvasNode>()
    const edges = new Map<ConnectorId, EtlCanvasEdge>()
    const model = { nodes, edges }

    addNotesToModel(model, pipeline)
    addComponentsToModel(
      model,
      pipeline,
      getByImplementationId,
      getIcon,
      pipelineType,
      enableRelativeStartPositionAndZoom
    )

    return model
  }, [
    pipeline,
    getByImplementationId,
    getIcon,
    pipelineType,
    enableRelativeStartPositionAndZoom
  ])
}

function addComponentsToModel(
  { nodes, edges }: EtlCanvasModel,
  pipeline: TransformationJob | OrchestrationJob,
  getByImplementationId: (
    implementationID: string,
    parameters: ParameterCollection,
    job: TransformationJob | OrchestrationJob | null
  ) => ComponentSummary | undefined,
  getIcon: (componentId: string, parameters?: ParameterCollection) => string,
  pipelineType: FileType | undefined,
  flag: boolean
): void {
  const startNodes: ComponentInstanceId[] = []

  Object.entries(pipeline.components).forEach(([, component]) => {
    if (!isNodeSupported(component)) {
      console.warn('useCanvasModel: component is not supported', component)
      return
    }

    const componentSummary = getByImplementationId(
      component.implementationID.toString(),
      component.parameters,
      pipeline
    ) as ComponentSummary

    const componentId = componentSummary.componentId
    const imageUrl = getIcon(componentId, component.parameters)
    const label = getComponentName(component)
    const isSkipped =
      component.activationStatus === ComponentActivationStatus.DISABLED

    if (componentId === START_NODE_ID) {
      startNodes.push(component.id)
    }

    const { inputPorts, outputPorts, iteratorPorts } =
      getComponentPorts(componentSummary)

    const componentInstanceId: EtlCanvasNodeId = getComponentReactFlowId(
      component.id
    )

    nodes.set(componentInstanceId, {
      id: componentInstanceId,
      type:
        iteratorPorts.length > 0 && !unknownComponentIds.includes(componentId)
          ? EtlCanvasNodeType.ITERATOR
          : EtlCanvasNodeType.NODE,
      position: {
        x: component.x,
        y: component.y
      },
      data: {
        imageUrl,
        label,
        inputPorts,
        outputPorts,
        outputPortsConnected: [],
        iteratorPorts,
        summaryId: componentId,
        skipped: isSkipped
      }
    })
  })

  /*
   * if there is only one *added* component node on the canvas,
   * we want it to be selected by default
   */
  if (flag) {
    if (pipelineType === FileType.TRANSFORMATION_PIPELINE && nodes.size === 1) {
      const onlyNode = nodes.values().next().value
      onlyNode.selected = true
    }

    if (pipelineType === FileType.ORCHESTRATION_PIPELINE && nodes.size === 2) {
      const iterator = nodes.values()
      iterator.next()
      const secondNode = iterator.next().value
      secondNode.selected = true
    }
  } else if (nodes.size === 1) {
    const onlyNode = nodes.values().next().value
    onlyNode.selected = true
  }

  /*
   * the last start component in the pipeline should not be able to be deleted,
   * so we mark it as such to prevent the canvas library from processing
   * delete events on it
   */
  if (startNodes.length === 1) {
    const reactFlowId = getComponentReactFlowId(startNodes[0])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastStartNode = nodes.get(reactFlowId)!

    lastStartNode.deletable = false
  }

  const { iterationConnectors = {}, ...connectors } = getJobConnectors(pipeline)

  const iterationConnectorValues = Object.values(iterationConnectors)
  const targetIds = new Set(iterationConnectorValues.map((ic) => ic.targetID))

  iterationConnectorValues.forEach(({ sourceID, targetID }) => {
    const isPartOfIteratorStack = targetIds.has(sourceID)
    if (isPartOfIteratorStack) return

    const nodeSourceId = getComponentReactFlowId(sourceID)
    const nodeTargetId = getComponentReactFlowId(targetID)
    const attachedNode = nodes.get(nodeTargetId)
    const parentNode = nodes.get(nodeSourceId)

    if (!isComponentNode(attachedNode) || !isComponentNode(parentNode)) {
      console.warn(
        'useCanvasModel: iteration connector is attached to non-existent node',
        sourceID,
        targetID
      )
      return
    }
    // an iterator stack consists of the top level iterator, where
    // the connections exist, followed by any number of other iterators
    // and finally the component which sits at the bottom. We build the stack
    // from top to bottom
    const attachedNodes = buildIteratorStack(attachedNode)
    attachedNodes.forEach((node) => {
      node.hidden = true
    })

    parentNode.data = {
      ...parentNode.data,
      attachedNodes
    }
  })

  function buildIteratorStack(
    node: ComponentNode,
    stack: ComponentNode[] = []
  ): ComponentNode[] {
    stack.push(node)

    const nodeId = getIdFromReactFlowId(node.id)
    const nextTargetID = iterationConnectorValues.find(
      ({ sourceID }) => sourceID === Number.parseInt(nodeId)
    )?.targetID

    if (!nextTargetID) return stack

    const nextNode = nodes.get(getComponentReactFlowId(nextTargetID))
    return nextNode
      ? buildIteratorStack(nextNode as ComponentNode, stack)
      : stack
  }

  Object.entries(connectors).forEach(([connectorType, connectorCollection]) => {
    Object.entries(connectorCollection).forEach(([, connector]) => {
      const nodeSourceId: EtlCanvasNodeId = getComponentReactFlowId(
        connector.sourceID
      )
      const nodeTargetId: EtlCanvasNodeId = getComponentReactFlowId(
        connector.targetID
      )

      const source = nodes.get(nodeSourceId)
      const target = nodes.get(nodeTargetId)

      if (!isComponentNode(source) || !isComponentNode(target)) {
        console.warn(
          'useCanvasModel: connector is attached to non-existent node',
          connector
        )
        return
      }

      source.data.outputPortsConnected.push(
        getOutputPortTypeFromPipelineField(
          connectorType as unknown as OrchestrationJobConnectors
        )
      )
      source.data.hasOutputConnection = true
      target.data.hasInputConnection = true

      edges.set(connector.id, {
        id: connector.id.toString(),
        source: nodeSourceId,
        target: nodeTargetId,
        data: {
          sourceHandle: parseConnectorName(connectorType),
          sourceComponent: source.data,
          targetComponent: target.data,
          job: pipeline
        }
      })
    })
  })
}

function addNotesToModel(
  { nodes }: EtlCanvasModel,
  pipeline: TransformationJob | OrchestrationJob
) {
  if (!pipeline.notes) {
    return
  }

  Object.entries(pipeline.notes).forEach(([key, note]) => {
    const noteId = getNoteReactFlowId(Number.parseInt(key))

    nodes.set(noteId, {
      id: noteId,
      type: EtlCanvasNodeType.NOTE,
      position: {
        x: note.position.x,
        y: note.position.y
      },
      data: {
        content: note.content,
        width: note.size.width,
        height: note.size.height,
        theme: note.theme,
        isAIGenerated: note.isAIGenerated,
        selection: note.selection
      }
    })
  })
}
