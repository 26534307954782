import { Tooltip, Typography } from '@matillion/component-library'
import { FileIcon, type FileType } from '@matillion/git-component-library'
import classNames from 'classnames'

import classes from './CleanJobRecord.module.scss'

export interface CleanJobRecordProps {
  pipelineName: string
  pipelineType: FileType
  filePath: string
}

const CleanJobRecord = ({
  pipelineName,
  pipelineType,
  filePath
}: CleanJobRecordProps) => {
  return (
    <>
      {pipelineType && (
        <span className={classes.pipelineIcon}>
          <FileIcon type={pipelineType} />
        </span>
      )}

      <div className={classes.pipelineInfo}>
        <Tooltip content={pipelineName} onlyShowOnOverflow>
          <div className="u-truncate-text" data-testid={`${pipelineName}`}>
            <Typography
              format="bcs"
              as="span"
              className={classes.pipelineName}
              data-testid={`pipeline-${pipelineName}`}
            >
              {pipelineName}
            </Typography>
          </div>
        </Tooltip>

        <Tooltip content={filePath} onlyShowOnOverflow>
          <div
            className={classNames(classes.filePathWrapper, 'u-truncate-text')}
          >
            <Typography
              format="mc"
              as="span"
              className={classes.filePath}
              data-testid={`file-path-${pipelineName}`}
            >
              {filePath}
            </Typography>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default CleanJobRecord
