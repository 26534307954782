import { type FC } from 'react'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { ErrorContextWrapper } from 'components/ParameterOverlay/components/ErrorContextWrapper'

import { AdvancedFilteringEditor } from '../../editors'
import {
  type DataSelectionConfig,
  type UpdateTableConfigHandler
} from '../../types'
import {
  getAdvancedFilteringParameter,
  getCombineFiltersParameter,
  getTableSelectionParameter
} from '../../utils/getEditorParameter'

interface AdvancedFilteringProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  childProperties: ComponentParameter[]
  filterEditorColumns: EditorColumn[]
  activeTableConfig: DataSelectionConfig
  replaceLookupParamValue: string
  onFetchFilterColumns: (editorColumns: EditorColumn[]) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onBack: () => void
}

export const AdvancedFiltering: FC<AdvancedFilteringProps> = ({
  componentSummaryId,
  componentMetaData,
  childProperties,
  filterEditorColumns,
  replaceLookupParamValue,
  activeTableConfig,
  onFetchFilterColumns,
  onUpdateTableConfig,
  onBack
}) => {
  const { parameter } = getAdvancedFilteringParameter(childProperties)

  const { parameter: tableSelectionParameter } =
    getTableSelectionParameter(childProperties)

  const { parameter: combineFiltersParameter } =
    getCombineFiltersParameter(childProperties)

  return (
    <>
      {parameter && tableSelectionParameter && combineFiltersParameter && (
        <ErrorContextWrapper>
          <AdvancedFilteringEditor
            componentSummaryId={componentSummaryId}
            componentMetaData={componentMetaData}
            parameter={parameter}
            combineFiltersParameter={combineFiltersParameter}
            replaceLookupParamId={tableSelectionParameter.dplID}
            replaceLookupParamValue={replaceLookupParamValue}
            activeTableConfig={activeTableConfig}
            filterEditorColumns={filterEditorColumns}
            onFetchFilterColumns={onFetchFilterColumns}
            onUpdateTableConfig={onUpdateTableConfig}
            onBack={onBack}
          />
        </ErrorContextWrapper>
      )}
    </>
  )
}
