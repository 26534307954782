import { combineReducers } from '@reduxjs/toolkit'

import { enhanceReducer } from 'modules/redux-yjs-bindings/src'

import { jobInitialState, jobSlice } from './jobSlice/job.slice'

const rootReducer = combineReducers({
  // enhanceReducer creates a noop for SET_STATE_FROM_YJS_ACTION
  // the action is a redux-yjs-bindings internal action from touching our state
  job: enhanceReducer(jobSlice.reducer)
})

export type RootReducerState = ReturnType<typeof rootReducer>

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>

export const initialStates = {
  job: jobInitialState
}
