import { type ChangeEvent, type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Radio, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useListEnvironments } from 'api/hooks/useListEnvironments'

import {
  Fields,
  ProjectVariableBehaviour,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'

import classes from '../Form.module.scss'
import { type ReducerAction } from '../formReducer/formReducer'

export const ProjectSpecificFields = ({
  state,
  dispatch,
  isDisabled
}: {
  state: FormState
  dispatch: Dispatch<ReducerAction>
  isDisabled: boolean
}) => {
  const { t } = useTranslation()
  const { data: environments } = useListEnvironments()

  return (
    <>
      <Typography
        format="bcs"
        weight="bold"
        className={classes.Form__CustomLabel}
      >
        {t('manageVariables.jobVariables.fields.behaviour')}
      </Typography>

      <Typography format="bcs" className={classes.Form__Behaviour_Message}>
        {t('manageVariables.jobVariables.fields.behaviourVisibility.message')}{' '}
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/variables/#variable-behavior-copied-or-shared"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(
            'manageVariables.jobVariables.fields.behaviourVisibility.learnMore'
          )}
        </a>
      </Typography>

      <Field
        className={classNames(classes.Field, classes.RadioField)}
        inputComponent={Radio}
        onBlur={() => {
          dispatch({
            type: ReducerActions.VALIDATE_FORM
          })
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_FIELD,
            field: Fields.BEHAVIOUR,
            value: e.target.value as ProjectVariableBehaviour
          })
        }}
        text={t(
          'manageVariables.jobVariables.fields.behaviourVisibility.shared'
        )}
        hasError={state[Fields.BEHAVIOUR].isValid === false}
        value={ProjectVariableBehaviour.SHARED}
        checked={
          state[Fields.BEHAVIOUR].value === ProjectVariableBehaviour.SHARED
        }
      />

      <Field
        className={classNames(classes.Field, classes.RadioField)}
        inputComponent={Radio}
        onBlur={() => {
          dispatch({
            type: ReducerActions.VALIDATE_FORM
          })
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_FIELD,
            field: Fields.BEHAVIOUR,
            value: e.target.value as ProjectVariableBehaviour
          })
        }}
        text={t(
          'manageVariables.jobVariables.fields.behaviourVisibility.copied'
        )}
        hasError={state[Fields.BEHAVIOUR].isValid === false}
        value={ProjectVariableBehaviour.COPIED}
        checked={
          state[Fields.BEHAVIOUR].value === ProjectVariableBehaviour.COPIED
        }
      />

      <Field
        className={classNames(classes.Field, classes['Field__Input--Compact'])}
        title={t('manageVariables.jobVariables.fields.defaultValue')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_DEFAULT_VALUE,
            field: Fields.DEFAULT_VALUE,
            value: e.target.value
          })
        }}
        value={state.DEFAULT_VALUE.value}
        hasError={state[Fields.DEFAULT_VALUE].isValid === false}
        errorText={
          state[Fields.DEFAULT_VALUE].isValid === false &&
          t(state[Fields.DEFAULT_VALUE].error, {
            field: t('manageVariables.jobVariables.fields.defaultValue')
          })
        }
        disabled={isDisabled}
      />

      <fieldset className={classes.Form__ScopeFieldset}>
        <legend className={classes.Form__Legend}>
          <Typography format="ts">
            {t(
              'manageVariables.jobVariables.fields.environmentDefaultOverride'
            )}
          </Typography>
        </legend>
        <div className={classes.Form__ProjectOverrideField}>
          <Typography
            aria-hidden
            format="bcs"
            weight="bold"
            className={classes.Form__CustomLabel}
          >
            <span>{t('manageVariables.jobVariables.fields.environment')}</span>
          </Typography>
          <Typography
            aria-hidden
            format="bcs"
            weight="bold"
            className={classes.Form__CustomLabel}
          >
            <span>{t('common.value')}</span>
          </Typography>
        </div>

        {environments?.results.map((environment) => {
          const field = state.PROJECT_DEFAULT_OVERRIDES.value?.[environment.id]

          return (
            <Field
              key={environment.id}
              className={classNames(
                classes.Field,
                classes['Field__Input--Compact'],
                classes.Form__FieldList
              )}
              onBlur={() => {
                dispatch({
                  type: ReducerActions.VALIDATE_FORM
                })
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerActions.UPDATE_PROJECT_OVERRIDE,
                  field: Fields.PROJECT_DEFAULT_OVERRIDES,
                  environmentId: environment.id,
                  value: e.target.value
                })
              }}
              disabled={isDisabled}
              title={environment.name}
              value={field?.value ?? ''}
              hasError={field && !field?.isValid}
              errorText={!field?.isValid && t(field?.error ?? '')}
            />
          )
        })}
      </fieldset>
    </>
  )
}
