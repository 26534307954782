import { JobVariableType } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  type FormState,
  type ProjectVariableOverride,
  type ReducerActions
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { manageVariableNameErrors } from '../errorMessages'
import { isFormValid } from './isFormValid'

export const updateProjectOverride = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_PROJECT_OVERRIDE
    field: Fields.PROJECT_DEFAULT_OVERRIDES
    value: ProjectVariableOverride['value']
    environmentId: ProjectVariableOverride['environmentId']
  }
): FormState => {
  const isNumberType = state.VARIABLE_TYPE.value === JobVariableType.NUMBER
  const isValidNumber = action.value === '' || isNumeric(action.value as string)
  const isValid = isNumberType ? isValidNumber : true

  const value: ProjectVariableOverride = {
    isValid,
    value: action.value,
    environmentId: action.environmentId,
    error: isValid
      ? ''
      : manageVariableNameErrors[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
  }

  const updatedState = {
    ...state,
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      ...state[Fields.PROJECT_DEFAULT_OVERRIDES],
      value: {
        ...state[Fields.PROJECT_DEFAULT_OVERRIDES].value,
        [action.environmentId]: value
      },
      isValid: !isValid
        ? false
        : state[Fields.PROJECT_DEFAULT_OVERRIDES].isValid
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
