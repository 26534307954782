import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'
import { useServiceUrl } from '@matillion/hub-client'

import classes from '../CustomConnectorCTA/CustomConnectorCTA.module.scss'

interface CustomConnectorCTAProps {
  inAddComponentContext: boolean
}

export const CustomConnectorCTA = ({
  inAddComponentContext
}: CustomConnectorCTAProps) => {
  const { t } = useTranslation()
  const customConnectorUrl = useServiceUrl('cyoc-frontend', true)

  return (
    <div
      className={classes.CustomConnectorCTA}
      data-testid="components-custom-connector-cta"
      data-cta-context={
        inAddComponentContext ? 'add-components-modal' : 'components-panel'
      }
    >
      <Typography format="bcm" className={classes.CustomConnectorCTA__Text}>
        {t('componentBrowser.customConnectorCTA.text')}
      </Typography>
      <a
        href={customConnectorUrl}
        target="_blank"
        rel="noreferrer"
        data-tracker-id="add-custom-connector-cta-link"
      >
        <Button
          size="md"
          alt="secondary"
          className={classes.CustomConnectorCTA__Button}
        >
          <Icon.Add className={classes.CustomConnectorCTA__Button__Icon} />
          <Typography
            format="bcs"
            weight="bold"
            className={classes.CustomConnectorCTA__Button__Text}
          >
            {t('componentBrowser.customConnectorCTA.buttonText')}
          </Typography>
        </Button>
      </a>
    </div>
  )
}
