import {
  DraggableAndResizable,
  type DraggableAndResizableProps
} from './DraggableAndResizable'

const isLeftClick = (e: React.MouseEvent<HTMLElement>) => {
  return e.button === 0 && !e.ctrlKey
}

export const useDraggableAndResizable = (props: DraggableAndResizableProps) => {
  const draggableInstance = new DraggableAndResizable(props)

  const startDrag = (e: React.MouseEvent<HTMLElement>) => {
    if (isLeftClick(e)) {
      draggableInstance.startDrag(e)
    }
  }

  const startResize =
    (direction: string) => (e: React.MouseEvent<HTMLElement>) => {
      if (isLeftClick(e)) {
        draggableInstance.startResize(e, direction)
      }
    }

  return { startDrag, startResize }
}
