import { useCallback, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Field, Radio, Typography } from '@matillion/component-library'
import { heap, useFlags, useLDClient, useUser } from '@matillion/hub-client'

import { useFetchComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { useStackingModal } from 'components/StackingModalProvider'

import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import {
  useMakeComponent,
  type SourceComponentConnection
} from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'
import {
  OutputPortType,
  type Cardinality,
  type Port
} from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import classes from '../../AddNextComponent.module.scss'
import { AddComponentList } from '../AddComponentList'
import { TopSourcesPanel } from '../TopSourcesPanel'

export const getDefaultPortConnection = (outputPorts: Port[]) => {
  if (outputPorts?.find((port) => port.portId === OutputPortType.SUCCESS)) {
    return OutputPortType.SUCCESS
  }

  if (outputPorts?.find((port) => port.portId === OutputPortType.TRUE)) {
    return OutputPortType.TRUE
  }

  return OutputPortType.UNCONDITIONAL
}

const AddNextComponentModal: FC<{
  sourceComponentId: number | undefined
  showTopSources?: boolean
}> = ({ sourceComponentId, showTopSources = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [makeComponent] = useMakeComponent()
  const { job, jobType } = useFlaggedWorkingCopy()
  const fetchComponentMetadata = useFetchComponentMetadata()
  const sourceComponentMetadata =
    useComponentInstanceMetadataQuery(sourceComponentId)
  const update = useDPLWorkingCopy((state) => state.update)
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const launchDarkly = useLDClient()
  const { closeAll } = useStackingModal()

  const handleTopSourcesClose = () => {
    heap.track(
      'Designer - Add Next Component Modal - Top Sources Panel - Close'
    )
    document.cookie =
      'dpcShowTopSources=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.matillion.com;'
    setShowTopSourcesPanel(false)
  }

  const [sourceType, setSourceType] = useState<OutputPortType>(() =>
    getDefaultPortConnection(
      sourceComponentMetadata.metadata?.outputPorts ?? []
    )
  )
  const { user } = useUser()
  const topSources = user[
    'https://hub.matillion.com/primary_data_sources'
  ] as unknown as string[]

  const [showTopSourcesPanel, setShowTopSourcesPanel] =
    useState<boolean>(showTopSources)

  const onSelectComponent = useCallback(
    async (id: ComponentSummaryId, componentName?: string) => {
      const position = {
        x: 0,
        y: 0
      }

      if (!job) {
        return
      }

      let sourceComponentConnection: SourceComponentConnection | undefined

      if (sourceComponentId) {
        position.x = job?.components[sourceComponentId].x + 120
        position.y = job?.components[sourceComponentId].y

        const sourceCardinality =
          sourceComponentMetadata.metadata?.outputPorts?.find(
            (port) => port.portId === sourceType
          )?.cardinality as Cardinality

        sourceComponentConnection = {
          sourceComponentId,
          sourceType,
          sourceCardinality,
          sourceComponentName: sourceComponentMetadata.componentInstance
            ? getComponentName(sourceComponentMetadata.componentInstance)
            : ''
        }
      }

      closeAll()

      launchDarkly.track('Designer - Canvas - Add Component')
      heap.track('Designer - Component Added to Canvas', {
        componentOrigin: 'Add Next Component Modal',
        componentId: id,
        componentName
      })

      if (rolloutEnableWorkingCopyProvider) {
        const { metadata } = await fetchComponentMetadata(id)

        update((state) => {
          state.addComponent({
            componentId: id,
            componentName: componentName ?? '',
            componentMetadata: metadata,
            sourceComponentConnection,
            componentDesign: {
              position
            }
          })
        })
      } else {
        const newComponent = await makeComponent({
          id,
          sourceComponentConnection,
          ...position,
          componentName
        })

        dispatch(jobActions.addComponent(newComponent))
      }
    },
    [
      job,
      sourceComponentId,
      launchDarkly,
      rolloutEnableWorkingCopyProvider,
      sourceComponentMetadata,
      sourceType,
      fetchComponentMetadata,
      update,
      makeComponent,
      dispatch,
      closeAll
    ]
  )

  const handleSourceTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSourceType(event.target.value as OutputPortType)
    },
    []
  )

  return (
    <>
      <div className={classes.ModalContainer__Title}>
        <Typography as="h2" format="tm" id="modal-title">
          {t('addNextComponent.title')}
        </Typography>
      </div>
      {jobType === JobType.Orchestration && !showTopSourcesPanel && (
        <div className={classes.AddNextComponentCTA__OutputPortSelectorWrapper}>
          <Typography
            as="h3"
            format="bcs"
            weight="bold"
            id="output-connection-title"
          >
            {t('addNextComponent.portSelectorTitle')}
          </Typography>
          {sourceComponentMetadata.metadata?.outputPorts.length === 1 ? (
            <Typography
              format="bcs"
              aria-labelledby="output-connection-title"
              className={classes.AddNextComponentCTA__OutputPortSelector}
            >
              {t(
                `addNextComponent.outputPorts.${sourceComponentMetadata.metadata?.outputPorts[0].portId}`
              )}
            </Typography>
          ) : (
            <ul
              aria-labelledby="output-connection-title"
              className={classes.AddNextComponentCTA__OutputPortSelector}
            >
              {sourceComponentMetadata.metadata?.outputPorts
                .filter((port) => port.portId !== OutputPortType.ITERATION)
                .map((port) => (
                  <li key={port.portId}>
                    <Field
                      name="portType"
                      text={t(`addNextComponent.outputPorts.${port.portId}`)}
                      value={port.portId}
                      inputComponent={Radio}
                      checked={sourceType === port.portId}
                      onChange={handleSourceTypeChange}
                    />
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
      {showTopSourcesPanel && (
        <TopSourcesPanel
          handleClose={handleTopSourcesClose}
          onSelectComponent={onSelectComponent}
          topSources={topSources}
        />
      )}
      <div className={classes.ModalContainer__Content}>
        <AddComponentList onSelectComponent={onSelectComponent} />
      </div>
    </>
  )
}
export default AddNextComponentModal
