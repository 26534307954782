import { useCallback, type FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Toaster, Typography } from '@matillion/component-library'
import { getFileTypeTranslationKeySuffix } from '@matillion/git-component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import { useSharePipelineStore } from 'api/hooks/useSharePipeline/store'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useDeleteFile } from 'hooks/useDeleteFile'
import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import classes from '../Modal.module.scss'

export interface Props {
  onClose: () => void
  jobSummary: JobSummary
}

const SHARED_PIPELINES_CONFIG_FILE = '.matillion/shared-pipelines.yaml'

export const DeletePipelineModal: FC<Props> = ({ onClose, jobSummary }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'deleteJob.modal' })
  const { closeTab } = useSelectedJobs()
  const { makeToast } = Toaster.useToaster()
  const { branchId, projectId } = useProjectInfo()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { enableSharedPipelines } = useFlags()
  const { deletePipelinesFromSharedConfig, checkPipelineShared } =
    useSharePipeline()
  const { deleteSharedPipelinesConfig } = useSharePipelineStore()

  const { deleteFile } = useDeleteFile()

  const translationKeySuffix = getFileTypeTranslationKeySuffix(
    jobSummary.fileType
  )

  const onDeleteJob = useCallback(async () => {
    try {
      await deleteFile({ name: jobSummary.jobId })

      onFileTreeCommand({
        type: FileTreeReducerType.REMOVE_FILE,
        fileDetails: {
          path: jobSummary.folder,
          fileType: jobSummary.fileType,
          name: jobSummary.name
        },
        branch: branchId,
        project: projectId
      })

      if (
        enableSharedPipelines &&
        jobSummary.jobId !== SHARED_PIPELINES_CONFIG_FILE
      ) {
        await deletePipelinesFromSharedConfig([jobSummary.jobId])
      }

      if (
        enableSharedPipelines &&
        jobSummary.jobId === SHARED_PIPELINES_CONFIG_FILE
      ) {
        deleteSharedPipelinesConfig()
      }

      makeToast({
        type: 'success',
        title: `${t('success', {
          name: jobSummary.name
        })}`,
        message: ''
      })

      closeTab({ id: jobSummary.jobId, type: 'job' })
    } catch (error) {
      makeToast({
        type: 'error',
        title: `${t('error', {
          name: jobSummary.name
        })}`,
        message: ''
      })
    }
    onClose()
  }, [
    onClose,
    onFileTreeCommand,
    jobSummary.fileType,
    jobSummary.folder,
    jobSummary.name,
    jobSummary.jobId,
    branchId,
    projectId,
    deleteFile,
    enableSharedPipelines,
    makeToast,
    t,
    closeTab,
    deletePipelinesFromSharedConfig,
    deleteSharedPipelinesConfig
  ])

  return (
    <DesignerModal
      onCancel={onClose}
      size="mid"
      ariaLabelledBy="delete-job-modal-heading"
      data-testid="delete-job-modal"
    >
      <div className={classes.Modal__Heading}>
        <Typography as="h2" format="tl" id="delete-job-modal-heading">
          {t(`heading.${translationKeySuffix}`)}
        </Typography>
      </div>
      <div className={classes.Modal__Content}>
        <Trans
          i18nKey={t(`confirmationMessage.${translationKeySuffix}`)}
          values={{ name: jobSummary.name }}
          components={{ bold: <strong /> }}
        />

        {enableSharedPipelines && checkPipelineShared(jobSummary.jobId) && (
          <>
            <Typography format="bcm" weight="bold">
              {t('warningSharedPipelineMessage')}
            </Typography>
            <Typography format="bcm">
              {t('warningSharedPipelineInfo')}
            </Typography>
          </>
        )}
        <Typography format="bcm">
          {t(`confirmationInfo.${translationKeySuffix}`)}
        </Typography>
      </div>
      <div className={classes.Modal__Actions}>
        <Button
          alt="secondary"
          text={t('cancel')}
          onClick={onClose}
          data-testid="delete-job-modal-cancel"
        />
        <Button
          alt="danger"
          text={t('validate')}
          onClick={() => {
            onDeleteJob()
          }}
          data-testid="delete-job-modal-validate"
        />
      </div>
    </DesignerModal>
  )
}
