import { useMemo } from 'react'

import { useGitErrorHandler } from '@matillion/git-component-library'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

const useDesignerQueryClient = () => {
  const { handleQueryError, handleMutationError } = useGitErrorHandler()

  return useMemo(() => {
    return new QueryClient({
      mutationCache: new MutationCache({
        onError: handleMutationError
      }),
      queryCache: new QueryCache({
        onError: handleQueryError
      }),
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 5, // 5 minutes
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: false
        }
      }
    })
  }, [handleMutationError, handleQueryError])
}

export default useDesignerQueryClient
