import { useEffect } from 'react'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useFileTreeExpandedStateStore } from './store'

export const useFileBrowserState = () => {
  const {
    fileTreeState,
    setFileTreeExpandedState,
    renameFileTreeExpandedState,
    expandFoldersToNode
  } = useFileTreeExpandedStateStore()
  const { jobSummaryId } = useProjectInfo()

  /**
   * Sets the expanded state of a node in the tree
   * @param nodeId the id of the node to toggle e.g. folder/sub-folder
   * @param isExpanded true | false
   */
  const toggleNode = (nodeId: string, isExpanded: boolean) => {
    setFileTreeExpandedState(nodeId, isExpanded)
  }

  /**
   * Updates tree state to reflect updated folder names
   * @param oldTreeState The old path to the folder
   * @param newTreeState The new path to the folder when it is renamed
   */
  const updateFileTreeState = (oldTreeState: string, newTreeState: string) => {
    renameFileTreeExpandedState(oldTreeState, newTreeState)
  }

  useEffect(() => {
    expandFoldersToNode(jobSummaryId)
  }, [jobSummaryId, expandFoldersToNode])

  return {
    pipelineTreeState: fileTreeState,
    toggleNode,
    updateFileTreeState
  }
}
