/* istanbul ignore file */
import { createContext, type Key } from 'react'

export interface LayoutContextInterface {
  isSampleFetchRequested: boolean
  setIsSampleFetchRequested: (value: boolean) => void
  isSampleRowCountFetchRequested: boolean
  setIsSampleRowCountFetchRequested: (value: boolean) => void
  activeTabKey: Key | null
  setActiveTabKey: (key: Key | null) => void
}

export const LayoutContext = createContext<LayoutContextInterface>({
  isSampleFetchRequested: false,
  setIsSampleFetchRequested: () => null,
  isSampleRowCountFetchRequested: false,
  setIsSampleRowCountFetchRequested: () => null,
  activeTabKey: null,
  setActiveTabKey: () => null
})
