import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { FileOperation, useRenameFile } from '@matillion/git-component-library'
import axios from 'axios'

import type { ProblemDetails } from 'api/types/http-problem-details'

import type { CopyFileProps } from './types'

export const useCopyFile = () => {
  const { renameFile: renameFileEffect, ...effectHook } = useRenameFile()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  const copyFile = async ({ currentFilePath, targetFolder }: CopyFileProps) => {
    await renameFileEffect(
      {
        currentFilePath,
        newFilePath: targetFolder,
        operation: FileOperation.COPY
      },
      {
        onSuccess: () => {
          makeToast({
            type: 'success',
            title: t('translation:copy-paste.toast.success.title'),
            message: t('translation:copy-paste.toast.success.message', {
              currentFilePath,
              targetFolder: targetFolder || 'root'
            })
          })
        },
        onError: (error: unknown) => {
          clearToasts()
          if (
            axios.isAxiosError<ProblemDetails>(error) &&
            error.response?.data.title &&
            error.response?.data.detail
          ) {
            makeToast({
              type: 'error',
              title: error.response.data.title,
              message: error.response.data.detail
            })
          } else {
            makeToast({
              type: 'error',
              title: t('translation:copy-paste.toast.error.title'),
              message: t('translation:copy-paste.toast.error.message')
            })
          }
        }
      }
    )
  }

  return {
    copyFile,
    ...effectHook
  }
}
