export enum ComponentUiConfigKey {
  MULTI_TABLE_SELECTION_MAX_SELECTIONS = 'multiTableSelection.maxSelections'
}

export interface ComponentUiConfig {
  multiTableSelection?: {
    maxSelections?: number
  }
}

interface ComponentUiConfigs {
  [componentId: string]: ComponentUiConfig
}

/**
 * This object is used to determine various behaviours of the UI that are applicable to specific
 * components, and is not behaviour that can/should be determined by the component metadata.
 */
export const componentUiConfigs: ComponentUiConfigs = {
  'modular-load-salesforce-input-v0': {
    multiTableSelection: {
      maxSelections: 100
    }
  }
}
