import { useTranslation } from 'react-i18next'

import { QuitButton, Typography } from '@matillion/component-library'

import classes from './StageHeader.module.scss'

export const StageHeader = ({
  title,
  onCancel,
  switcher
}: {
  title: string
  onCancel?: () => void
  switcher?: JSX.Element
}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.StageHeader}>
      <Typography
        id="variable-panel-title"
        format="ts"
        as="h2"
        className={classes.StageHeader__Title}
      >
        {t(title)}
      </Typography>
      {switcher}
      {onCancel && (
        <QuitButton
          alt="transparent"
          data-testid="btn-close-variable-panel"
          aria-label={t('manageVariables.common.closePanel')}
          onClick={onCancel}
        />
      )}
    </div>
  )
}
