import { type ReactNode } from 'react'

import classes from './InlineParameterContent.module.scss'

export const InlineParameterContent = ({
  children
}: {
  children: ReactNode
}) => {
  return (
    <div
      className={classes.InlineParameterContent}
      id="inline-parameter-content"
    >
      {children}
    </div>
  )
}
