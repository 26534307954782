import { useContext } from 'react'

import { useStore } from 'zustand'

import { WorkingCopyContext } from '../context'
import { type WorkingCopyStore } from '../types'

/**
 * Returns the temporal store for the current pipeline, which contains a
 * history of changes and associated navigation functions. For example:
 * ```
 * const { undo, pastStates } = useWorkingCopyHistory()
 * ```
 *
 * See https://github.com/charkour/zundo for documentation on how to use
 * the temporal store.
 */
export const useWorkingCopyHistory = () => {
  const store = useContext(WorkingCopyContext)

  if (!store) {
    throw new Error(
      'the Working Copy store for history has not been initialised!'
    )
  }

  return useStore(store.temporal, (state) => state)
}

/**
 * Returns the Working Copy, which is a zustand store containing
 * the user's current pipeline and a mutation function for committing changes
 * to it. Everything in the application should interface with the pipeline
 * through this hook. For example:
 * ```
 * const components = useWorkingCopy(state => state.workingCopy.pipeline.components)
 * const updateWorkingCopy = useWorkingCopy(state => state.update)
 * ```
 *
 * See https://docs.pmnd.rs/zustand/ for documentation on how to use the zustand store.
 */
export const useWorkingCopy = <U>(
  selector: (state: WorkingCopyStore) => U
): U => {
  const store = useContext(WorkingCopyContext)

  if (!store) {
    throw new Error('the Working Copy store has not been initialised!')
  }

  return useStore(store, selector)
}
