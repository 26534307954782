import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Field, Typography } from '@matillion/component-library'

import { AutoComplete } from 'components/AutoComplete'
import {
  type AutoCompleteItem,
  type AutoCompleteItemStringId
} from 'components/AutoComplete/types'

import { type RootState } from 'job-lib/store'
import { type GridVariableCollection } from 'job-lib/types/Variables'

import classes from './GridVariableEditor.module.scss'

const defaultAutocompleteItem: AutoCompleteItemStringId = { name: '', id: '' }

interface GridVariableEditorProps {
  columnNames: string[]
  columnValues: Record<string, AutoCompleteItemStringId>
  setColumnValues: (
    newColumnValues: Record<string, AutoCompleteItemStringId>
  ) => void
  selectedGridVariable: AutoCompleteItemStringId
  setSelectedGridVariable: (
    newSelectedGridVariable: AutoCompleteItemStringId
  ) => void
}

export const GridVariableEditor = (props: GridVariableEditorProps) => {
  const {
    columnNames,
    columnValues,
    selectedGridVariable,
    setColumnValues,
    setSelectedGridVariable
  } = props

  const { t } = useTranslation()

  const gridVariables = useSelector<RootState, GridVariableCollection>(
    (state) => state.job.job?.grids ?? {}
  )

  const gridVariablesList: AutoCompleteItemStringId[] = Object.values(
    gridVariables
  ).map((gv) => {
    const name = gv.definition.name
    return { id: name, name }
  })
  const getGridColumnsList = (name: string): AutoCompleteItemStringId[] => {
    if (!gridVariables[name]) {
      return []
    }
    return gridVariables[name].definition.definitions.map((def) => {
      return {
        id: def.name,
        name: def.name
      }
    })
  }

  return (
    <form className={classes.Form}>
      <Field
        inputComponent={AutoComplete}
        value={selectedGridVariable}
        title={t('parameterOverlay.gridVariable')}
        availableItems={gridVariablesList}
        onChange={(e: ChangeEvent<AutoCompleteItem>) => {
          const id = e.target.value?.id as string
          setColumnValues({})
          setSelectedGridVariable({ name: id, id })
        }}
      />

      <fieldset className={classes.Form__Fieldset}>
        <legend className={classes.Form__Legend}>
          <Typography format="bcl">
            {t('parameterOverlay.gridVariableColumnMapping')}
          </Typography>
        </legend>

        {columnNames.map((column) => {
          return (
            <Field
              className={classes.Form__Field}
              key={column}
              inputComponent={AutoComplete}
              value={columnValues[column] ?? defaultAutocompleteItem}
              title={column}
              availableItems={getGridColumnsList(selectedGridVariable.id)}
              onChange={(e: ChangeEvent<AutoCompleteItem>) => {
                setColumnValues({
                  ...columnValues,
                  [column]: {
                    name: e.target.value?.id as string,
                    id: e.target.value?.id as string
                  }
                })
              }}
            />
          )
        })}
      </fieldset>
    </form>
  )
}
