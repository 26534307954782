import { useEffect } from 'react'
import { Controller, useForm, type FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Field,
  Input,
  Typography
} from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { VALID_FOLDER_NAME_REGEX } from 'modules/FileBrowser/utils/validFolderNameRegex'

import {
  usePipelineBrowserCommandProvider,
  usePipelineBrowserProvider
} from '../../effects/usePipelineBrowserCommand'
import { folderExists } from '../../reducers/fileTreeReducer/fileTreeReducer'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import classes from './CreateFolderModal.module.scss'

export interface CreateFolderModalProps {
  path: string
  onClose: () => void
}

const CreateFolderModal = ({ onClose, path }: CreateFolderModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-folder.modal'
  })

  const isCreatingAtRoot = path === ''

  const { branchId, projectId } = useProjectInfo()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const fileTree = usePipelineBrowserProvider()

  const { enableHighCode } = useFlags()
  const translationKeySuffix = enableHighCode ? 'file' : 'pipeline'

  const {
    handleSubmit,
    control,
    setFocus,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    setFocus('folderName')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddFolder = (data: FieldValues) => {
    const folderName = data.folderName

    if (isCreatingAtRoot) {
      onFileTreeCommand({
        fileDetails: { name: folderName },
        branch: branchId,
        project: projectId,
        type: FileTreeReducerType.ADD_ROOT_FOLDER
      })
    } else {
      onFileTreeCommand({
        fileDetails: {
          path,
          name: folderName
        },
        branch: branchId,
        project: projectId,
        type: FileTreeReducerType.ADD_FOLDER
      })
    }
    onClose()
  }
  return (
    <DesignerModal
      size="mid"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick
      id="create-folder-dialog"
      data-testid="create-folder-modal"
      ariaLabelledBy="create-folder-title"
    >
      <Typography as="h2" format="tl" className={classes.Title}>
        {t('title')}
      </Typography>

      <Typography className={classes.Description}>
        {t(`description.${translationKeySuffix}`)}
      </Typography>

      <Alert
        theme="dark"
        type="warning"
        title={t('alert.title')}
        className={classes.Alert}
        message={t(`alert.message.${translationKeySuffix}`)}
        data-testid="create-pipeline-folder-alert"
      />

      <form
        onSubmit={(e) => {
          handleSubmit(handleAddFolder)(e)
        }}
      >
        <div className={classes.Body} data-testid="create-folder-form">
          <Controller
            name="folderName"
            defaultValue=""
            control={control}
            rules={{
              required: t('validation.no-empty-string'),
              pattern: {
                value: VALID_FOLDER_NAME_REGEX,
                message: t('validation.failed-regex')
              },
              validate: async (name: string) => {
                // A root folder has no path when searching the tree
                const folderPath = isCreatingAtRoot ? undefined : path

                if (name.length > 50) {
                  return t('validation.name-too-long')
                }

                if (folderExists(fileTree, name, folderPath)) {
                  return t('validation.already-exists')
                }
              }
            }}
            render={({
              field: { value, onChange, name, ref },
              fieldState: { error }
            }) => {
              return (
                <Field
                  id={name}
                  name={name}
                  value={value}
                  inputRef={ref}
                  error={!!error}
                  hasError={!!error}
                  onChange={onChange}
                  inputComponent={Input}
                  title={t('input.label')}
                  errorText={error?.message}
                  aria-label={t('aria-label')}
                  data-testid="create-folder-input"
                  placeholder={t('input.placeholder')}
                />
              )
            }}
          />

          <div className={classes.Buttons}>
            <Button
              alt="secondary"
              onClick={onClose}
              text={t('cancel')}
              id="create-folder-cancel"
              data-testid="create-folder-cancel"
            />
            <Button
              type="submit"
              alt="primary"
              text={t('submit')}
              id="create-folder-create"
              disabled={!isDirty || !isValid}
              data-testid="create-folder-submit"
            />
          </div>
        </div>
      </form>
    </DesignerModal>
  )
}

export default CreateFolderModal
