import { getAllJobConnections } from 'job-lib/job-functions/job-functions'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

export const getFilteredConnectors = (
  job: OrchestrationJob | TransformationJob,
  selectedComponentIds: number[]
) => {
  return getAllJobConnections(job).filter(({ sourceID, targetID }) => {
    if (
      selectedComponentIds.includes(sourceID) &&
      selectedComponentIds.includes(targetID)
    ) {
      return true
    }

    return false
  })
}
