import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type Failure } from 'api/hooks/useValidateComponent/types'

import { isNumber } from 'utils/isNumber'

export function convertMetlSlotsToDplIds(
  failures: Failure[],
  componentMetadata: ComponentMetadata
) {
  return failures.map((failure) => {
    const parameterId = failure.path.at(1)
    const isSlot = isNumber(parameterId)
    if (isSlot && parameterId) {
      failure.path = [
        'parameters',
        componentMetadata.parameters.find(
          (x) => x.metlSlot === Number(parameterId)
        )?.dplID ?? 'unknown'
      ]
    }

    return failure
  })
}
