import { type DataGridRow } from '@matillion/component-library'

import { createGridElementCollection } from 'job-lib/builders/createGridElementsCollection'
import {
  type Element,
  type ElementCollection,
  type Value,
  type ValueCollection
} from 'job-lib/types/Parameters'

import { type HelperData } from './SetGridVariablesEditor'

interface SetGridVariablesGridValues {
  selectedGridVariable: string
  columnMapping: Value[]
}

export interface SetValuesValueCollection {
  id: string
  cells: ValueCollection
}

export interface ValueRow extends DataGridRow {
  status: 'values'
  name: string
  values: SetValuesValueCollection[]
}

interface GridRow extends DataGridRow {
  status: 'grid'
  name: string
  values: SetGridVariablesGridValues
  columns: string[]
}

interface DefaultRow extends DataGridRow {
  status: 'defaults'
  name: string
}

export type ChildGridRow = DefaultRow | ValueRow | GridRow

export const getInitialChildRows = (
  elements: ElementCollection,
  helperData: HelperData
): Record<string, ChildGridRow> => {
  const useDefaultsValue: Record<string, ChildGridRow> =
    getHelperDataVariableNames(helperData).reduce((acc, variableName) => {
      return {
        ...acc,
        [variableName]: {
          id: variableName,
          name: variableName,
          status: 'defaults'
        }
      }
    }, {})
  const elementValues = Object.values(elements)

  const rows = Object.values(helperData).reduce(
    (acc, { name: variableName }) => {
      const matchingElement = elementValues.find((v) => {
        return getVariableName(v) === variableName
      })

      if (!matchingElement) {
        return acc
      }
      const variableStatus = getVariableStatus(matchingElement)

      if (variableStatus === 'values') {
        return {
          ...acc,
          [variableName]: {
            id: variableName,
            name: variableName,
            status: variableStatus,
            values: getColumnDataForSetValues(matchingElement)
          }
        }
      }

      return {
        ...acc,
        [variableName]: {
          status: variableStatus,
          id: variableName,
          name: variableName,
          values: {
            selectedGridVariable:
              getVariableNameForUseGridVariable(matchingElement),
            columnMapping: getColumnMappingsForUseGridVariable(matchingElement)
          },
          columns: getHelperDataColumnNames(helperData, variableName)
        }
      }
    },
    useDefaultsValue
  )

  return rows
}

export const getVariableName = (element: Element) => {
  return element.values[1].value
}

export const getVariableStatus = (element: Element): 'values' | 'grid' => {
  return element.values[2].value as 'values' | 'grid'
}

export const getHelperDataVariableNames = (
  helperData: HelperData
): string[] => {
  return Object.keys(helperData).map((key) => helperData[key].name)
}

export const getHelperDataColumnNames = (
  helperData: HelperData,
  variableName: string
): string[] => {
  return helperData[variableName].definitions.map((d) => d.name)
}

export const getColumnDataForSetValues = (element?: Element) => {
  const columnData = element?.elements

  if (!columnData || !element) {
    return []
  }

  return Object.entries(columnData).map(([key, value], index) => {
    return {
      id: key,
      cells: value.values
    }
  })
}

export const getVariableNameForUseGridVariable = (element: Element) => {
  return element.elements?.[1]?.values?.[1]?.value as string
}

export const getColumnMappingsForUseGridVariable = (element: Element) => {
  const columnData = element.elements?.[2]?.values

  if (!columnData) {
    return []
  }

  return Object.values(columnData)
}

interface GridVariableElementCollection {
  elements: ElementCollection
  values: {
    1: {
      slot: 1
      type: 'STRING'
      value: string
    }
    2: {
      slot: 2
      type: 'STRING'
      value: 'grid'
    }
  }
}
interface ValuesVariableElementCollection {
  elements: ElementCollection
  values: {
    1: {
      slot: 1
      type: 'STRING'
      value: string
    }
    2: {
      slot: 2
      type: 'STRING'
      value: 'values'
    }
  }
}

export const mapRowsToElementCollection = (
  rows: ChildGridRow[]
): ElementCollection => {
  return rows.reduce((accum, row, index) => {
    if (row.status === 'defaults') {
      return accum
    }
    let values:
      | GridVariableElementCollection
      | ValuesVariableElementCollection
      | null = null
    // TODO: remove when values is implemented
    /* istanbul ignore else */
    if (row.status === 'grid') {
      values = {
        elements: createGridElementCollection(
          row.values.selectedGridVariable,
          row.values.columnMapping.map((col) => col.value)
        ),
        values: {
          1: {
            slot: 1,
            type: 'STRING',
            value: row.name
          },
          2: {
            slot: 2,
            type: 'STRING',
            value: 'grid'
          }
        }
      }
    }

    if (row.status === 'values') {
      values = {
        elements: row.values.reduce<ElementCollection>(
          (rowAccum, rowValue, rowIndex) => {
            const rowSlot = rowIndex + 1

            return {
              ...rowAccum,
              [rowSlot]: {
                slot: rowSlot,
                values: rowValue.cells
              }
            }
          },
          {}
        ),

        values: {
          1: {
            slot: 1,
            type: 'STRING',
            value: row.name
          },
          2: {
            slot: 2,
            type: 'STRING',
            value: 'values'
          }
        }
      }
    }
    const slot = index + 1

    return {
      ...accum,
      [slot]: {
        slot,
        ...values
      }
    }
  }, {})
}
