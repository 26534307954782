import { type FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useFlags } from 'hooks/useFlags'

import classes from '../Modal.module.scss'

export interface Props {
  onClose: () => void
  jobSummary: JobSummary
}

export const UnsharePipelineModal: FC<Props> = ({ onClose, jobSummary }) => {
  const { t } = useTranslation()
  const { enableSharedPipelines } = useFlags()
  const { disableSharedPipeline } = useSharePipeline()

  const onUnshareJob = () => {
    enableSharedPipelines && disableSharedPipeline(jobSummary.jobId)

    onClose()
  }

  return (
    <DesignerModal
      onCancel={onClose}
      size="mid"
      ariaLabelledBy="unshare-job-modal-heading"
      data-testid="unshare-job-modal"
    >
      <div className={classes.Modal__Heading}>
        <Typography as="h2" format="tl" id="unshare-job-modal-heading">
          {t('translation:unshareJob.modal.heading')}
        </Typography>
      </div>
      <div className={classes.Modal__Content}>
        <Trans
          i18nKey={t('translation:unshareJob.modal.confirmationMessage')}
          values={{ name: jobSummary.name }}
          components={{ bold: <strong /> }}
        />

        <Typography format="bcm">
          {t('translation:unshareJob.modal.confirmationInfo')}
        </Typography>
      </div>
      <div className={classes.Modal__Actions}>
        <Button
          alt="secondary"
          text={t('translation:unshareJob.modal.cancel')}
          onClick={onClose}
          data-testid="unshare-job-modal-cancel"
        />
        <Button
          alt="danger"
          text={t('translation:unshareJob.modal.validate')}
          onClick={() => {
            onUnshareJob()
          }}
          data-testid="unshare-job-modal-validate"
        />
      </div>
    </DesignerModal>
  )
}
