import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  useCreateFile,
  useFile,
  useFileAsync,
  useFiles,
  useGitEventContext,
  useUpdateFileContentsApi
} from '@matillion/git-component-library'
import Ajv from 'ajv'
import { type AxiosError } from 'axios'
import { VariableType } from 'types/Pipeline'
import YAML from 'yaml'

import { useFlags } from 'hooks/useFlags'

import sharedPipelineConfigJSONSchema from './sharedPipelineConfigJSONSchema.json'
import { useSharePipelineStore } from './store'
import type { SharedPipelinesConfig, VariableAsParameter } from './types'
import {
  convertVariablesToParameters,
  generateAutoincrementedPipelineId,
  getDisplayName,
  getExistingPipelineIds,
  getNewSharedPipelinesConfig,
  getPipelineId,
  getPipelineVariables,
  isPipelineShared,
  isPipelineSharedAndEnabled,
  stringifyConfigToYamlWithHeaderComment
} from './utils'

const SHARED_PIPELINES_CONFIG_FILE = '.matillion/shared-pipelines.yaml'

export const useFetchSharedPipelinesConfig = (enabled?: boolean) => {
  const {
    setSharedPipelinesConfig,
    deleteSharedPipelinesConfig,
    getSharedPipelinesConfig
  } = useSharePipelineStore()
  const { enableSharedPipelines } = useFlags()
  const { isLoading: isFilesLoading } = useFiles()

  /* istanbul ignore next */
  const retryCount = process.env.REACT_APP_ENVIRONMENT === 'test' ? 0 : 3
  const sharedPipelinesConfig = getSharedPipelinesConfig()

  return useFile(
    {
      path: SHARED_PIPELINES_CONFIG_FILE
    },
    {
      enabled:
        enabled ??
        (!sharedPipelinesConfig &&
          enableSharedPipelines &&
          /*
           * This is a temporary solution to prevent the client requesting the file before the file summaries are available
           * It prevents a concurrency issue where the file is being requested before the repo has finished being cloned.
           * It is only present on newly created projects and when the user lands on the designer for the first time.
           * This can be removed once this ticket is resolved: https://matillion.atlassian.net/browse/DPC-20680
           */
          !isFilesLoading),
      retry: (errorCount, error) => {
        const axiosError = error as AxiosError
        if (axiosError.status === 404 || axiosError.status === 401) {
          return false
        }

        return errorCount < retryCount
      },
      meta: {
        disableErrorToasts: true
      },
      retryDelay: 1000,
      onSuccess: (data) => {
        if (data && typeof data === 'string' && !sharedPipelinesConfig) {
          try {
            const parsedData = YAML.parse(data)

            if (typeof parsedData !== 'object') {
              return
            }

            setSharedPipelinesConfig(parsedData)
          } catch {}
        }
      },
      onError: () => {
        deleteSharedPipelinesConfig()
      }
    }
  )
}

export const useSharePipeline = () => {
  const { getFileAsync } = useFileAsync()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const { refreshWorkingTreeStatus } = useGitEventContext()

  const { createFile } = useCreateFile()
  const { mutateAsync: updateFile } = useUpdateFileContentsApi({
    meta: {
      disableErrorToasts: true
    }
  })
  const { getSharedPipelinesConfig, setSharedPipelinesConfig } =
    useSharePipelineStore()

  const sharePipeline = async (pipelineName: string, pipelineId?: string) => {
    let sharedPipelinesConfigDraft: SharedPipelinesConfig = {
      version: '1.0',
      type: 'shared-pipelines-config',
      pipelines: []
    }

    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()

      if (sharedPipelinesConfig) {
        sharedPipelinesConfigDraft = sharedPipelinesConfig
      }

      const pipelineVariables = await getPipelineVariables(
        pipelineName,
        getFileAsync
      )

      const parameters = convertVariablesToParameters(pipelineVariables)

      if (
        isPipelineShared(sharedPipelinesConfigDraft.pipelines, pipelineName)
      ) {
        sharedPipelinesConfigDraft.pipelines =
          sharedPipelinesConfigDraft.pipelines.map((p) => {
            if (p.pipeline === pipelineName) {
              return {
                ...p,
                enabled: true,
                parameters
              }
            }

            return p
          })
      } else {
        sharedPipelinesConfigDraft.pipelines.push({
          pipeline: pipelineName,
          displayName: getDisplayName(pipelineName),
          id: pipelineId || getPipelineId(pipelineName),
          enabled: true,
          parameters
        })
      }

      const newSharedPipelinesConfig = stringifyConfigToYamlWithHeaderComment(
        sharedPipelinesConfigDraft
      )

      if (sharedPipelinesConfig) {
        await updateFile({
          name: SHARED_PIPELINES_CONFIG_FILE,
          contents: newSharedPipelinesConfig
        })
      } else {
        await createFile({
          path: SHARED_PIPELINES_CONFIG_FILE,
          contents: newSharedPipelinesConfig
        })
      }

      await refreshWorkingTreeStatus({
        refreshFileContents: true,
        refreshFileSummaries: true
      })
      setSharedPipelinesConfig(sharedPipelinesConfigDraft)

      makeToast({
        type: 'success',
        title: t('translation:jobContextMenu.sharePipeline.success'),
        message: ''
      })
    } catch {
      makeToast({
        type: 'error',
        title: t('translation:jobContextMenu.sharePipeline.error'),
        message: t('translation:jobContextMenu.sharePipeline.errorMessage')
      })
    }
  }

  const checkPipelineShared = (pipelinePath: string) => {
    const sharedPipelinesConfig = getSharedPipelinesConfig()
    if (!sharedPipelinesConfig) {
      return false
    }

    return isPipelineSharedAndEnabled(
      sharedPipelinesConfig.pipelines,
      pipelinePath
    )
  }

  const checkIfIdAlreadyExists = (pipelineId: string) => {
    const sharedPipelinesConfig = getSharedPipelinesConfig()
    if (!sharedPipelinesConfig) {
      return false
    }

    const existingPipelineIds = getExistingPipelineIds(sharedPipelinesConfig)

    return existingPipelineIds.includes(pipelineId)
  }

  const disableSharedPipeline = async (pipelineName: string) => {
    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()

      if (!sharedPipelinesConfig) {
        return
      }

      sharedPipelinesConfig.pipelines = sharedPipelinesConfig.pipelines.map(
        (p) => {
          if (p.pipeline === pipelineName) {
            return { ...p, enabled: false }
          } else {
            return p
          }
        }
      )

      await updateFile({
        name: SHARED_PIPELINES_CONFIG_FILE,
        contents: stringifyConfigToYamlWithHeaderComment(sharedPipelinesConfig)
      })

      await refreshWorkingTreeStatus({
        refreshFileContents: true
      })
      setSharedPipelinesConfig(sharedPipelinesConfig)

      makeToast({
        type: 'success',
        title: `${t('translation:unshareJob.modal.success', {
          name: pipelineName
        })}`,
        message: ''
      })
    } catch {
      makeToast({
        type: 'error',
        title: `${t('translation:unshareJob.modal.error', {
          name: pipelineName
        })}`,
        message: ''
      })
    }
  }

  const deletePipelinesFromSharedConfig = async (pipelines: string[]) => {
    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()

      if (!sharedPipelinesConfig) {
        return
      }

      sharedPipelinesConfig.pipelines = sharedPipelinesConfig.pipelines.filter(
        (p) => !pipelines.includes(p.pipeline)
      )

      await updateFile({
        name: SHARED_PIPELINES_CONFIG_FILE,
        contents: stringifyConfigToYamlWithHeaderComment(sharedPipelinesConfig)
      })

      await refreshWorkingTreeStatus({
        refreshFileContents: true
      })
      setSharedPipelinesConfig(sharedPipelinesConfig)
    } catch {
      makeToast({
        type: 'error',
        title: t('translation:jobContextMenu.sharePipeline.error'),
        message: ''
      })
    }
  }

  const getSharedPipelinesInFolder = (folderPath: string) => {
    const sharedPipelinesConfig = getSharedPipelinesConfig()

    if (!sharedPipelinesConfig) {
      return []
    }

    sharedPipelinesConfig.pipelines = sharedPipelinesConfig.pipelines.filter(
      (p) => {
        const normalizedFolder = folderPath.endsWith('/')
          ? folderPath
          : `${folderPath}/`
        return p.pipeline.startsWith(normalizedFolder) && p.enabled
      }
    )

    return sharedPipelinesConfig.pipelines
  }

  const updateVariableInSharedPipeline = async ({
    pipelineName,
    variable,
    removeFromConfig
  }: {
    pipelineName: string
    variable: VariableAsParameter
    removeFromConfig?: boolean
  }) => {
    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()
      if (!sharedPipelinesConfig) {
        return
      }

      const pipelineVariables = await getPipelineVariables(
        pipelineName,
        getFileAsync
      )

      if (removeFromConfig) {
        pipelineVariables[variable.name] = null
      } else {
        if (variable.oldName && pipelineVariables[variable.oldName]) {
          pipelineVariables[variable.oldName] = null
        }

        if (variable.type === VariableType.Grid) {
          const transformed = (variable.columns ?? []).reduce<
            Record<string, { columnType: string }>
          >((acc, column) => {
            acc[column.name] = { columnType: column.type }
            return acc
          }, {})

          pipelineVariables[variable.name] = {
            metadata: {
              type: variable.type,
              scope: variable.scope,
              description: variable.description,
              visibility: variable.visibility,
              columns: transformed
            }
          }
        } else {
          pipelineVariables[variable.name] = {
            metadata: {
              type: variable.type,
              scope: variable.scope,
              description: variable.description,
              visibility: variable.visibility
            }
          }
        }
      }

      const newSharedPipelinesConfig = getNewSharedPipelinesConfig(
        pipelineName,
        pipelineVariables,
        sharedPipelinesConfig
      )

      await updateFile({
        name: SHARED_PIPELINES_CONFIG_FILE,
        contents: newSharedPipelinesConfig
      })

      await refreshWorkingTreeStatus({
        refreshFileContents: true
      })
      setSharedPipelinesConfig(YAML.parse(newSharedPipelinesConfig))
    } catch {
      makeToast({
        type: 'error',
        title: t('translation:jobContextMenu.sharePipeline.error'),
        message: ''
      })
    }
  }

  const renameSharedPipeline = async (oldName: string, newName: string) => {
    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()

      if (!sharedPipelinesConfig) {
        return
      }

      sharedPipelinesConfig.pipelines = sharedPipelinesConfig.pipelines.map(
        (p) => {
          if (p.pipeline === oldName) {
            return {
              ...p,
              pipeline: newName,
              displayName: getDisplayName(newName)
            }
          }

          return p
        }
      )

      await updateFile({
        name: SHARED_PIPELINES_CONFIG_FILE,
        contents: stringifyConfigToYamlWithHeaderComment(sharedPipelinesConfig)
      })

      await refreshWorkingTreeStatus({
        refreshFileContents: true
      })
      setSharedPipelinesConfig(sharedPipelinesConfig)
    } catch {
      makeToast({
        type: 'error',
        title: t('translation:jobContextMenu.sharePipeline.error'),
        message: ''
      })
    }
  }

  /**
   * Updates existing shared pipeline folder paths when a folder is renamed
   * @param updatedPipelines A list of pipeline names that need to be updated
   * @param oldPath The old folder path
   * @param newPath The new folder path
   */
  const renameSharedPipelineFolders = async (
    updatedPipelines: string[],
    oldPath: string,
    newPath: string
  ) => {
    try {
      const sharedPipelinesConfig = getSharedPipelinesConfig()

      if (!sharedPipelinesConfig) {
        return
      }

      sharedPipelinesConfig.pipelines = sharedPipelinesConfig.pipelines.map(
        (p) => {
          if (updatedPipelines.includes(p.pipeline)) {
            return {
              ...p,
              pipeline: p.pipeline.replace(oldPath, newPath)
            }
          }

          return p
        }
      )

      await updateFile({
        name: SHARED_PIPELINES_CONFIG_FILE,
        contents: stringifyConfigToYamlWithHeaderComment(sharedPipelinesConfig)
      })

      await refreshWorkingTreeStatus({
        refreshFileContents: true
      })
      setSharedPipelinesConfig(sharedPipelinesConfig)
    } catch {
      makeToast({
        type: 'error',
        title: t('translation:jobContextMenu.sharePipeline.error'),
        message: ''
      })
    }
  }

  const canIPublish = () => {
    if (!getSharedPipelinesConfig()) {
      return {
        result: true
      }
    }

    const ajv = new Ajv()

    if (
      !ajv.validate(sharedPipelineConfigJSONSchema, getSharedPipelinesConfig())
    ) {
      return {
        result: false,
        errorMessage: t(
          'translation:publish.toast.shared-pipeline-validation-error.message'
        )
      }
    }

    return { result: true }
  }

  return {
    sharePipeline,
    updateVariableInSharedPipeline,
    checkPipelineShared,
    checkIfIdAlreadyExists,
    deletePipelinesFromSharedConfig,
    disableSharedPipeline,
    getSharedPipelinesInFolder,
    getPipelineId,
    getExistingPipelineIds,
    generateAutoincrementedPipelineId,
    renameSharedPipeline,
    renameSharedPipelineFolders,
    canIPublish
  }
}
