import componentSummaries from '../locales/en/component-summaries'

/**
 * Returns true if the component is an AI component (has the $tags.ai tag)
 */
export const isAiComponent = (componentId: string) => {
  return Object.entries(componentSummaries).some(
    ([id, component]) =>
      id === componentId && component.tags.includes('$t(tags.ai)')
  )
}
