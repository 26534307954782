import { useState, type ChangeEvent, type FC } from 'react'

import { Field, Toggle, type ToggleProps } from '@matillion/component-library'

import {
  ComponentEditorTabType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type ComponentInstance } from 'job-lib/types/Job'

import { SQLViewer } from 'modules/ComponentProperties/components/SQLViewer'

import classes from './ComponentParameters.module.scss'
import { type EditedParameter } from './ComponentParametersContainer'
import { ComponentParameterWizardContainer } from './ComponentParameterWizardContainer'

interface ComponentParametersOrSqlProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
  onBlur: (params: EditedParameter) => void
}

const SqlToggle: FC<ToggleProps> = (props) => {
  return (
    <Toggle
      {...props}
      className={classes.SqlToggle}
      data-testid="wizard-tab-component-properties-sql"
      small={true}
    />
  )
}

export const ComponentParametersOrSql: FC<ComponentParametersOrSqlProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onBlur,
  onEdit
}) => {
  const [sqlToggled, setSqlToggled] = useState(false)

  return (
    <>
      {componentMetadata.editorTabs.includes(ComponentEditorTabType.SQL) && (
        <div className={classes.sqlToggle__Container}>
          <Field
            inputComponent={SqlToggle}
            checked={sqlToggled}
            name={'sqlToggled'}
            text={'Preview SQL'}
            value={sqlToggled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSqlToggled(event.target.checked)
            }}
          />
        </div>
      )}
      {sqlToggled ? (
        <SQLViewer />
      ) : (
        <ComponentParameterWizardContainer
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          onEdit={onEdit}
          onBlur={onBlur}
        />
      )}
    </>
  )
}
