import type {
  Component,
  GridValueFromVariable,
  ParameterValue,
  StructList,
  StructValue
} from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { createGridElementCollection } from 'job-lib/builders/createGridElementsCollection'
import { type Parameters } from 'job-lib/types/Job'
import type {
  ElementCollection,
  Parameter,
  ParameterCollection,
  ValueCollection
} from 'job-lib/types/Parameters'

import {
  isGridValueFromVariableParameterValue,
  isGridValueParameterValue,
  isListValueParameterValue,
  isScalarParameterValue,
  isStructListParameterValue,
  isStructValueParameterValue
} from '../../utils/parameters'

/**
 * A list of non-scalar data types that are used to determine whether the dataType field in the METL element value is set
 * The dataType field in METL is only set for scalar data types
 * */
const nonScalarDataTypes = [
  ParameterDataType.GRID,
  ParameterDataType.LIST,
  ParameterDataType.STRUCT,
  ParameterDataType.STRUCT_LIST
]

const convertGridParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: string[][]
) => {
  const metlElements: ElementCollection = {}

  elements.forEach((element, elementIdx) => {
    const metlValues: ValueCollection = {}

    element.forEach((value, valueIdx) => {
      const slot = valueIdx + 1

      metlValues[slot] = {
        slot,
        type: 'STRING',
        value
      }
    })

    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: metlValues
    }
  })

  return metlElements
}

const convertStructParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: StructValue
) => {
  const metlElements: ElementCollection = {}

  Object.values(elements).forEach((element, elementIdx) => {
    const slot = elementIdx + 1
    metlElements[slot] = {
      slot,
      values: {
        1: {
          slot: 1,
          value: element as string,
          type: 'STRING'
        }
      }
    }

    /**
     * the dataType field should only be added if the parameter is a scalar field.
     * This is because METL doesn't support non-scalar field types.
     */
    if (!nonScalarDataTypes.includes(parameter.dataType)) {
      metlElements[slot].values[1].dataType = parameter.dataType
    }
  })

  return metlElements
}

const convertStructListParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: StructList
) => {
  const createMetlElementCollection = (
    structElements: StructList,
    metlElementCollection: ElementCollection
  ): ElementCollection => {
    /* value slot only increases with new scalar values
     * so we can't rely on the childProperty index
     * this value gets incremented each time a scalar value is appended to the element
     */
    let valueSlot = 1

    structElements.forEach((structElement, elementIdx) => {
      const elementSlot = elementIdx + 1

      const metlValues: ValueCollection = {}

      metlElementCollection[elementSlot] = {
        slot: elementSlot,
        values: metlValues
      }

      if (!parameter.childProperties) {
        Object.values(structElement).forEach((value, valueIdx) => {
          metlValues[valueIdx + 1] = {
            slot: valueIdx + 1,
            type: 'STRING',
            dataType: parameter.dataType,
            value: (value as string) ?? ''
          }
        })

        metlElementCollection[elementSlot].values = metlValues
        return metlElementCollection
      }

      /* Elements in a struct list are generated from the current parameter's childProperties
       * A childProperty can only have one non-scalar element due to a limitation in the platform,
       * and the platform conversion library enforces this.
       */
      parameter.childProperties.forEach((childProperty) => {
        const fieldName = childProperty.dplID
        const isScalar = !nonScalarDataTypes.includes(childProperty.dataType)

        if (structElement[fieldName]) {
          const field = structElement[fieldName]

          if (isScalar) {
            metlValues[valueSlot] = {
              slot: valueSlot,
              type: 'STRING',
              dataType: childProperty.dataType,
              value: field as string
            }
          } else {
            metlElementCollection[elementSlot].elements = {}
            createMetlElementCollection(
              structElement[fieldName] as StructList,
              metlElementCollection[elementSlot].elements as ElementCollection
            )
          }
        }

        if (isScalar) {
          valueSlot += 1
        }
      })

      // reset the value slot for each struct list item
      valueSlot = 1
    })

    return metlElementCollection
  }

  return createMetlElementCollection(elements, {})
}

const convertListParameterValueToMetl = (elements: string[]) => {
  const metlElements: ElementCollection = {}

  elements.forEach((element, elementIdx) => {
    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: {
        1: {
          slot: 1,
          type: 'STRING',
          value: element
        }
      }
    }
  })

  return metlElements
}

const convertGridValueFromVariableParameterValue = (
  value: GridValueFromVariable
): ElementCollection => {
  const gridVarName = value.fromGrid.variable
  const gridVarColumns = value.fromGrid.columns

  return createGridElementCollection(gridVarName, gridVarColumns)
}

export const convertParameterValueToMetl = (
  parameter: ComponentParameter,
  value: ParameterValue
): ElementCollection => {
  if (value === null) {
    return {}
  }

  if (isScalarParameterValue(value)) {
    return {
      1: {
        slot: 1,
        values: {
          1: {
            slot: 1,
            dataType: parameter.dataType,
            type: 'STRING',
            value
          }
        }
      }
    }
  }

  if (isGridValueFromVariableParameterValue(value)) {
    return convertGridValueFromVariableParameterValue(value)
  }

  if (isStructListParameterValue(value)) {
    return convertStructListParameterValueToMetl(parameter, value)
  }

  if (isStructValueParameterValue(value)) {
    return convertStructParameterValueToMetl(parameter, value)
  }

  if (isGridValueParameterValue(value)) {
    return convertGridParameterValueToMetl(parameter, value)
  }

  if (isListValueParameterValue(value)) {
    return convertListParameterValueToMetl(value)
  }

  console.debug(
    `Could not convert parameter ${parameter.dplID}: unrecognised parameter type`
  )
  return {}
}

const convertParameterToMetl = (
  parameter: ComponentParameter,
  value?: ParameterValue
): Parameter => {
  /* we need to explicitly check for undefined here,
   * as null is an acceptable value for a parameter */
  if (value === undefined) {
    return {
      slot: parameter.metlSlot,
      name: parameter.dplID,
      elements: {},
      visible: false
    }
  }

  return {
    slot: parameter.metlSlot,
    name: parameter.dplID,
    elements: convertParameterValueToMetl(parameter, value),
    visible: true
  }
}

const convertParametersToMetl = (
  componentInstance: Component,
  componentMetadata: ComponentMetadata
) => {
  const metlParameters: ParameterCollection = {}

  componentMetadata.parameters.forEach((parameter) => {
    const value = componentInstance.parameters[parameter.dplID]
    const metlParameter = convertParameterToMetl(parameter, value)

    // TODO: Multi-warehouse support
    // is this component supported in the current warehouse?
    // if not, exclude it from conversion

    // TODO: Multi-warehouse support
    // override metlSlots for other warehouses
    const metlSlot = parameter.metlSlot

    metlParameters[metlSlot] = {
      ...metlParameter,
      slot: metlSlot
    }
  })

  /* this assertion is needed as the Parameters type specifies that the first
   * slot is always the name parameter. This should be true--but is quite
   * hard to type when we're dynamically generating the entire set */
  return metlParameters as Parameters
}

export default convertParametersToMetl
