import { useTranslation } from 'react-i18next'

import { Icon, MicroCta } from '@matillion/component-library'

interface ActionsCellProps {
  name: string
  onDelete: () => unknown
  onEdit: () => unknown
}

export const ActionsCell = ({ name, onDelete, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MicroCta
        alt="transparent"
        aria-label={t('manageVariables.edit.edit')}
        onClick={onEdit}
      >
        <span className="u-visually-hidden" aria-hidden="false">
          {t('manageVariables.edit.editVariableName', { name })}
        </span>
        <Icon.Pencil width="18" height="18" />
      </MicroCta>
      <MicroCta
        alt="transparent"
        aria-label={t('manageVariables.delete.delete')}
        onClick={onDelete}
      >
        <span className="u-visually-hidden" aria-hidden="false">
          {t('manageVariables.delete.deleteVariableName', { name })}
        </span>
        <Icon.Trash width="18" height="18" />
      </MicroCta>
    </>
  )
}
