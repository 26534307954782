import React from 'react'

import styles from './ChatStatus.module.scss'

interface ChatStatusProps {
  message: string
}

const ChatStatus = ({ message }: ChatStatusProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.message}>{message}</span>
      <div className={styles.spinner}></div>
    </div>
  )
}

export default ChatStatus
