import { useCallback, useEffect, useState } from 'react'

import {
  useQueries,
  useQueryClient,
  type QueryKey
} from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

import { useDesignerApi } from 'api/hooks/useDesignerApi/useDesignerApi'
import {
  type APIError,
  type GetParameterOptionsResponse
} from 'api/hooks/useGetParameterOptions/types'
import {
  getParameterOptionsQueryKey,
  type UseGetParameterOptionsRequest
} from 'api/hooks/useGetParameterOptions/useGetParameterOptions'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import {
  type ParameterOptionsRequestType,
  type ParameterOptionsResponse
} from 'hooks/useParameterOptions/useParameterOptionsTypes'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export interface MultiQueryItemOptions {
  key: string
  isComplete: boolean
  onSuccess?: (response: ParameterOptionsResponse) => void
  onError?: () => void
}

export interface MultiQueryItemData {
  queryKey: QueryKey
  options: MultiQueryItemOptions
  dependencies: Record<string, unknown>
}

export interface UseGetMultiParameterOptionsRequest<
  T extends ParameterOptionsRequestType
> extends Omit<UseGetParameterOptionsRequest<T>, 'isEnabled'> {
  overrideDplId: string
  optionsList: MultiQueryItemOptions[]
}

const QUERY_BUFFER_SIZE = 10

export const useGetMultiParameterOptions = <
  T extends ParameterOptionsRequestType
>({
  lookupDefinition,
  lookupType,
  parameterId,
  gridVariables,
  variables,
  requestType,
  overrideDplId,
  optionsList
}: UseGetMultiParameterOptionsRequest<T>) => {
  const [queryBuffer, setQueryBuffer] = useState<MultiQueryItemData[]>([])
  const queryClient = useQueryClient()

  const {
    projectId,
    branchId,
    jobSummaryId,
    componentId,
    environmentId,
    agentId
  } = useProjectInfo()
  const { getParameterOptions } = useDesignerApi()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''

  const {
    component,
    'transform.sql': transform,
    parameters,
    parameterMetadataDependencies,
    parameterIdDependencies,
    customConnectorProfileDependencies,
    scriptsToEvaluate
  } = lookupDefinition ?? {}

  const dependencies: Record<string, unknown> = {
    ...component,
    ...parameters,
    ...parameterIdDependencies,
    ...(transform ? { 'transform.sql': transform } : {}),
    ...customConnectorProfileDependencies,
    ...(parameterMetadataDependencies ?? {})
  }

  if (scriptsToEvaluate) {
    dependencies.scripts = scriptsToEvaluate
  }

  const paramKey = `param.${overrideDplId}`

  const queries = optionsList.map<MultiQueryItemData>((options) => {
    const tmpDependencies = cloneDeep(dependencies)
    tmpDependencies[paramKey] = options.key
    return {
      queryKey: getParameterOptionsQueryKey(
        { projectId, branchId, jobSummaryId },
        lookupType,
        componentId,
        parameterId,
        requestType,
        tmpDependencies
      ),
      dependencies: tmpDependencies,
      options
    }
  })

  const result = useQueries<
    Array<
      GetParameterOptionsResponse<'parameter-options'> | APIError | undefined
    >
  >({
    queries: queries.map((query) => ({
      queryKey: query.queryKey,
      enabled: false
    }))
  })

  useEffect(() => {
    if (queries.length > 0 && queryBuffer.length < QUERY_BUFFER_SIZE) {
      const hasUnfetched = queries.some((query) => {
        return !queryBuffer.find(
          (bufferQuery) => bufferQuery.options.key === query.options.key
        )
      })

      if (hasUnfetched) {
        setQueryBuffer((qb) => {
          const newQueryBuffer = [...qb]

          queries.forEach((query) => {
            const newBufferQuery = newQueryBuffer.find(
              (bufferQuery) => bufferQuery.options.key === query.options.key
            )
            if (newQueryBuffer.length < QUERY_BUFFER_SIZE && !newBufferQuery) {
              newQueryBuffer.push(query)
            }
          })

          return newQueryBuffer
        })
      }
    }
  }, [queries, queryBuffer])

  useEffect(() => {
    const completedQueries = queryBuffer.filter(
      (bufferQuery) =>
        !queries.find((q) => q.options.key === bufferQuery.options.key)
    )

    if (completedQueries.length > 0) {
      setQueryBuffer((d) =>
        d.filter(
          (bufferQuery) =>
            !completedQueries.find(
              (query) => query.options.key === bufferQuery.options.key
            )
        )
      )
    }
  }, [queries, queryBuffer])

  const getParameterOptionsQuery = useCallback(
    async (query: MultiQueryItemData) => {
      const { data } = await getParameterOptions<'parameter-options'>({
        requestType,
        lookupType,
        projectId,
        branchId,
        warehouse,
        environmentId,
        agentId,
        requestBody: {
          ...query.dependencies,
          variables,
          gridVariables,
          dependencies: query.dependencies
        }
      })

      return data
    },
    [
      agentId,
      branchId,
      environmentId,
      getParameterOptions,
      lookupType,
      projectId,
      requestType,
      variables,
      warehouse,
      gridVariables
    ]
  )

  useEffect(() => {
    for (const query of queryBuffer) {
      const queryKey = query.queryKey

      const state = queryClient.getQueryState(queryKey)
      if (state?.fetchStatus !== 'idle') {
        continue
      }

      queryClient
        .fetchQuery({
          queryKey,
          staleTime: Infinity,
          cacheTime: Infinity,
          queryFn: async () => {
            return getParameterOptionsQuery(query)
          }
        })
        .then((value) => {
          if (value) {
            query.options.onSuccess?.(value)
          }
        })
        .catch((reason) => {
          query.options.onError?.()
        })
    }
  }, [
    getParameterOptionsQuery,
    gridVariables,
    queryBuffer,
    queryClient,
    variables
  ])

  return result
}
