import { useState, type FC } from 'react'

import { Typography } from '@matillion/component-library'
import { type ParameterValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { useComponentName } from 'hooks/useComponentName/useComponentName'

import { type ComponentInstance } from 'job-lib/types/Job'

import { ComponentIcon } from 'modules/ComponentParameters/components/ComponentIcon'

import { type MultiQueryItemOptions } from './hooks/useGetMultiParameterOptions'
import { useMultiParameterOptions } from './hooks/useMultiTableParameterOptions'
import classes from './MultiTableConfigurationEditor.module.scss'
import { AdvancedFiltering, ConfigureColumns, TableSelection } from './screens'
import {
  defaultDataSelectionConfig,
  SchemaDrift,
  Screen,
  type ColumnConfig,
  type ConfigData,
  type DataSelectionConfig,
  type LoadStrategy,
  type TableDefMap
} from './types'
import {
  getConfigureColumnsParameter,
  getTableSelectionParameter
} from './utils/getEditorParameter'
import { parseConfigDataToParameterValue } from './utils/parseConfigDataToParameterValue'
import { parseParameterValueToConfigData } from './utils/parseParameterValueToConfigData'

const setAllColumnsSelection = (
  table: DataSelectionConfig,
  isAllSelectedChecked: boolean,
  selectedColumnsList: string[]
) => {
  return table.dataSelection.map((col) => {
    if (!selectedColumnsList.includes(col.name)) {
      return col
    }

    return {
      ...col,
      isSelected: isAllSelectedChecked
    }
  })
}

const applyNewColumnsToTableConfig = (
  allColumns: string[],
  tableConfig: DataSelectionConfig,
  newColumnsAreSelected: boolean
): DataSelectionConfig => {
  const currColumns = tableConfig?.dataSelection
  const isInitialLoad = currColumns?.length === 0

  const dataSelection = allColumns.map<ColumnConfig>((columnName) => {
    const currCol = currColumns?.find((col) => col.name === columnName)
    if (currCol) {
      return { ...currCol }
    }
    return {
      name: columnName,
      isSelected: isInitialLoad || newColumnsAreSelected
    }
  })

  const primaryKeys = dataSelection
    .filter(
      (column) =>
        column.isSelected && tableConfig.primaryKeys.includes(column.name)
    )
    .map((column) => column.name)

  const incrementalColumn =
    dataSelection.find(
      (column) =>
        column.isSelected && column.name === tableConfig.incrementalColumn
    )?.name ?? null

  return {
    ...tableConfig,
    dataSelection,
    primaryKeys,
    incrementalColumn
  }
}

interface MultiTableConfigurationEditorProps {
  componentInstance: ComponentInstance
  componentSummaryId: string
  componentMetadata: ComponentMetadata
  parameterValue?: ParameterValue
  childProperties: ComponentParameter[]
  loadStrategy?: LoadStrategy
  schemaDrift?: SchemaDrift
  onClose: () => void
  onSave: (value: ParameterValue) => void
}

export const MultiTableConfigurationEditor: FC<
  MultiTableConfigurationEditorProps
> = ({
  componentInstance,
  componentSummaryId,
  componentMetadata,
  parameterValue,
  childProperties,
  loadStrategy,
  schemaDrift,
  onClose,
  onSave
}) => {
  const { componentName } = useComponentName(
    componentInstance,
    componentMetadata
  )

  const [screen, setScreen] = useState<Screen>(Screen.SelectTables)
  const [tableToConfig, setTableToConfig] = useState<string | null>(null)
  const [allTables, setAllTables] = useState<TableDefMap>(new Map())
  const [internalData, setInternalData] = useState<ConfigData>(
    parseParameterValueToConfigData(childProperties, parameterValue)
  )
  const [filterEditorColumns, setFilterEditorColumns] = useState<
    EditorColumn[]
  >([])

  const { parameter: tableSelectionParam } =
    getTableSelectionParameter(childProperties)

  const overrideDplId = tableSelectionParam?.dplID
    ? `multiTableConfig.tableConfig.${tableSelectionParam?.dplID}`
    : ''

  const elementsParameter =
    getConfigureColumnsParameter(childProperties).parameter

  const selectTables = internalData
    .filter((table) => table.isSelected)
    .map((table) => table.dataSource)

  const tablesToFetch = selectTables?.filter((tableName) => {
    const tableDef = allTables?.get(tableName)
    return !!tableDef && !tableDef.columns && !tableDef.isError
  })

  useMultiParameterOptions({
    componentSummaryId,
    componentMetaData: componentMetadata,
    parameter: elementsParameter,
    overrideDplId,
    optionsList: tablesToFetch.map<MultiQueryItemOptions>((tableName) => ({
      key: tableName,
      isComplete: !!allTables.get(tableName)?.columns,
      onSuccess: (value) => {
        if (value.editorColumns.length > 0) {
          const valueOptions = value.editorColumns[0].options
          if (valueOptions) {
            handleFetchColumns(tableName, valueOptions, false)
          }
        }
      },
      onError: () => {
        handleFetchColumns(tableName, [], true)
      }
    }))
  })

  const activeTableConfig = internalData.find(
    (table) => table.dataSource === tableToConfig
  )

  const activeTable = allTables.get(tableToConfig ?? '')

  const handleSave = () => {
    const editedValue = parseConfigDataToParameterValue(
      internalData,
      childProperties
    )

    onSave(editedValue)
    onClose()
  }

  const handleConfigureTable = (tableName: string) => {
    setTableToConfig(tableName)
    setScreen(Screen.ColumnConfiguration)
  }

  const handleFetchTables = (tables: TableDefMap) => {
    const missingTabs = internalData
      .filter((table) => !tables.has(table.dataSource))
      .map((table) => table.dataSource)

    const currTables =
      missingTabs.length > 0
        ? internalData.filter((t) => !missingTabs.includes(t.dataSource))
        : [...internalData]

    const newTabs = Array.from(tables, ([k]) => k)
      .filter(
        (newTab) => !internalData.find((table) => table.dataSource === newTab)
      )
      .map((newTab) => {
        const config = defaultDataSelectionConfig()
        config.dataSource = newTab
        config.targetTableName = newTab
        return config
      })

    setAllTables(tables)
    setInternalData([...currTables, ...newTabs])
  }

  const handleFetchColumns = (
    tableName: string,
    columns: string[],
    isError: boolean
  ) => {
    setAllTables((tables) => {
      const newTables: TableDefMap = new Map()

      tables.forEach((value, key) => {
        const newTableDef = { ...value }
        if (value.name === tableName) {
          newTableDef.columns = isError ? undefined : columns
          newTableDef.isError = isError
        }
        newTables.set(key, newTableDef)
      })

      return newTables
    })

    const selectNewCols = schemaDrift === SchemaDrift.Yes

    setInternalData((d) =>
      d.map((table) =>
        table.dataSource === tableName
          ? {
              ...applyNewColumnsToTableConfig(columns, table, selectNewCols),
              isSelected: isError ? false : table.isSelected
            }
          : { ...table }
      )
    )
  }

  const fetchedCount = selectTables.reduce((acc, tableName) => {
    const tableDefCols = !!allTables.get(tableName)?.columns
    return tableDefCols ? acc + 1 : acc
  }, 0)

  const columnFetchPercent = fetchedCount / selectTables.length

  const hanldeResetTableError = (tableName: string) => {
    setAllTables((at) => {
      const result = new Map(at)
      const tableDef = result.get(tableName)
      if (tableDef) {
        tableDef.isError = false
      }
      return result
    })
  }

  const handleUpdateTableConfig = (tableConfig: DataSelectionConfig) => {
    setInternalData((d) =>
      d.map((table) =>
        table.dataSource === tableConfig.dataSource ? tableConfig : { ...table }
      )
    )
  }

  const handleSelectAllColumnsChange = (
    isAllSelectedChecked: boolean,
    selectedColumnsList: string[]
  ) => {
    setInternalData((d) =>
      d.map((table) => {
        if (table.dataSource === activeTableConfig?.dataSource) {
          return {
            ...table,
            primaryKeys: isAllSelectedChecked ? table.primaryKeys : [],
            incrementalColumn: isAllSelectedChecked
              ? table.incrementalColumn
              : null,
            dataSelection: setAllColumnsSelection(
              table,
              isAllSelectedChecked,
              selectedColumnsList
            )
          }
        }
        return {
          ...table
        }
      })
    )
  }

  const handleFetchFilterColumns = (editorColumns: EditorColumn[]) => {
    setFilterEditorColumns(editorColumns)
  }

  const handleApplyToAll = (saveData: ConfigData) => {
    setInternalData(saveData)
    setScreen(Screen.SelectTables)
  }

  return (
    <div
      className={classes.MultiTableConfigEditor}
      data-testid="multi-table-configuration-editor"
    >
      <div className={classes.MultiTableConfigEditor__EditorHeader}>
        {componentInstance && (
          <ComponentIcon
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
          />
        )}
        <Typography format="bcm" weight="bold">
          {componentName}
        </Typography>
      </div>
      {screen === Screen.SelectTables && (
        <TableSelection
          componentInstanceId={componentInstance.id}
          componentSummaryId={componentSummaryId}
          componentMetadata={componentMetadata}
          componentInstance={componentInstance}
          childProperties={childProperties}
          data={internalData}
          allTables={allTables}
          loadStrategy={loadStrategy}
          onFetchTables={handleFetchTables}
          onUpdateTableConfig={handleUpdateTableConfig}
          onResetTableError={hanldeResetTableError}
          onCancel={onClose}
          onSave={handleSave}
          onConfigureTable={handleConfigureTable}
        />
      )}
      {screen === Screen.ColumnConfiguration &&
        activeTable &&
        activeTableConfig && (
          <ConfigureColumns
            childProperties={childProperties}
            activeTable={activeTable}
            activeTableConfig={activeTableConfig}
            internalData={internalData}
            loadStrategy={loadStrategy}
            fetchCompletion={columnFetchPercent}
            onSelectAllColumnsChange={handleSelectAllColumnsChange}
            onUpdateTableConfig={handleUpdateTableConfig}
            onBack={() => {
              setScreen(Screen.SelectTables)
            }}
            onOpenAdvancedFiltering={() => {
              setScreen(Screen.AdvancedFiltering)
            }}
            onApplyToAll={handleApplyToAll}
          />
        )}
      {screen === Screen.AdvancedFiltering &&
        activeTable &&
        activeTableConfig && (
          <AdvancedFiltering
            componentSummaryId={componentSummaryId}
            componentMetaData={componentMetadata}
            childProperties={childProperties}
            filterEditorColumns={filterEditorColumns}
            replaceLookupParamValue={activeTableConfig.dataSource}
            activeTableConfig={activeTableConfig}
            onFetchFilterColumns={handleFetchFilterColumns}
            onUpdateTableConfig={handleUpdateTableConfig}
            onBack={() => {
              setScreen(Screen.ColumnConfiguration)
            }}
          />
        )}
    </div>
  )
}
