import { type FC } from 'react'

import { type SVGIconProps } from '../types'

export const RemoveOne: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Remove one"
      {...props}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle
        cx="19"
        cy="19"
        r="19"
        transform="rotate(-180 19 19)"
        fill="#51A620"
      />
      <path
        d="M16.7174 14.25L12.0908 18.5682L16.7174 22.8864"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
      <path d="M13.0161 18.5682H25.0453" stroke="#F2F2F2" strokeWidth="2" />
    </svg>
  )
}
