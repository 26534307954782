/**
 * Takes a full file path including the filename and extension and returns the folder path
 * @param path /src/folder/file.ts
 * @returns /src/folder
 */
export const extractFolderPathFromFilePath = (path: string): string => {
  const segments = path.split('/')
  const pathSegments = segments.slice(0, segments.length - 1)
  return pathSegments.join('/')
}
