import { useCallback, useState } from 'react'

import {
  DragDropContext,
  type DragStart,
  type DropResult
} from '@hello-pangea/dnd'
import { type Table } from '@tanstack/react-table'
import classNames from 'classnames'

import { insertItems } from './drag-and-drop'
import classes from './MatillionTable.module.scss'
import { MatillionTableBody } from './MatillionTableBody'
import { MatillionTableHead } from './MatillionTableHead'

interface MatillionTableProps<T> {
  /**
   * A unique identifier for your table. This is required if rendering more than 1 table per page.
   */
  tableId?: string
  /**
    The react-table table object, created by useReactTable. Your table data should have an id property.
   */
  table: Table<T>
  containerClassName?: string
  setRows: React.Dispatch<React.SetStateAction<T[]>>
}

/**
 * A display component. All generic display logic for the table should be exposed by this component.
 * You must use react-table to use this component.
 * Import Checkbox or DragHandle columns and add to your column array to enable selection and drag and drop
 * respectively.
 * @returns The matillion styled Table
 */
export const MatillionTable = <T extends { id: string }>(
  props: MatillionTableProps<T>
) => {
  const {
    table,
    setRows,
    containerClassName: className,
    tableId = 'matillion-table'
  } = props
  const [currentlyDraggingId, setCurrentlyDraggingId] = useState<string | null>(
    null
  )
  const onDragEnd = useCallback(
    (dropResult: DropResult) => {
      /* istanbul ignore else */ // this line is impossible to hit in normal usage
      if (dropResult.destination) {
        const dropIndex = dropResult.destination.index
        const selectedRows = table.getSelectedRowModel().rows
        const isMultiSelect = selectedRows.length > 1
        const selectedIds = isMultiSelect
          ? selectedRows.map((r) => r.id)
          : [dropResult.draggableId]
        setRows((rows) =>
          insertItems(rows, selectedIds, dropIndex, dropResult.draggableId)
        )
      }

      setCurrentlyDraggingId(null)
    },
    [setRows, table]
  )

  const onDragStart = useCallback(
    (dragStart: DragStart) => {
      setCurrentlyDraggingId(dragStart.draggableId)
      if (!table.getSelectedRowModel().rowsById[dragStart.draggableId]) {
        table.setRowSelection({})
      }
    },
    [table]
  )
  return (
    <div className={classNames(classes.MatillionTable, className)}>
      <table>
        <MatillionTableHead table={table} />
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <MatillionTableBody
            table={table}
            tableId={tableId}
            currentlyDraggingId={currentlyDraggingId}
          />
        </DragDropContext>
      </table>
    </div>
  )
}
