import { type FC } from 'react'
import { PanelResizeHandle } from 'react-resizable-panels'

import { Icon, MicroCta } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ResizeableHandler.module.scss'
import { DragHandlePosition, type Props } from './types'

export const ResizeableHandler: FC<Props> = ({
  orientation,
  disabled,
  hidden,
  id,
  showCollapseButton,
  collapseButtonClassName,
  onCollapseButtonClick,
  onDragging,
  dragHandlePosition = DragHandlePosition.AFTER,
  hideDragHandle,
  showIndicatorBar
}) => {
  if (disabled) {
    return (
      <div
        className={classnames(
          classes.ResizeableHandler,
          classes['ResizeableHandler--Inactive'],
          classes[`ResizeableHandler--${orientation}`]
        )}
      >
        <div
          className={classnames(
            classes.ResizeableHandler__Indicator,
            classes[`ResizeableHandler__Indicator--${orientation}`]
          )}
        />
      </div>
    )
  }

  return (
    <PanelResizeHandle
      onDragging={onDragging}
      hitAreaMargins={{
        coarse: 15,
        fine: 0
      }}
      className={classnames(
        classes.ResizeableHandler,
        classes[`ResizeableHandler--${orientation}`],
        classes[
          `ResizeableHandler--${
            hideDragHandle ? null : `${orientation}WithDragHandle`
          }`
        ],
        {
          [classes['ResizeableHandler--Active']]: showIndicatorBar,
          [classes.ResizeableHandler__PreventDragging]: hidden
        }
      )}
      id={`resizeable-${id as string}-bar`}
    >
      {showCollapseButton && (
        <MicroCta
          alt="secondary"
          aria-label="Close"
          className={classnames(classes.ResizeableHandler__CollapseButton, {
            [collapseButtonClassName as string]: collapseButtonClassName
          })}
          onClick={onCollapseButtonClick}
          data-testid="collapse-button"
        >
          <Icon.ChevronDown />
        </MicroCta>
      )}

      {hideDragHandle ? (
        <div
          className={classnames(
            classes.ResizeableHandler__Indicator,
            classes[`ResizeableHandler__Indicator--${orientation}`]
          )}
          data-testid="resize-indicator"
        />
      ) : (
        <div
          className={classnames(
            classes.ResizeableHandler__DragHandle,
            classes[
              `ResizeableHandler__DragHandle--${orientation}${dragHandlePosition}`
            ]
          )}
          data-testid="drag-handle"
        >
          <Icon.DragHandle
            className={classnames(classes.DragHandleIcon, {
              [classes.Horizontal]: orientation === 'Horizontal'
            })}
          />
        </div>
      )}
    </PanelResizeHandle>
  )
}
