import { type FunctionComponent } from 'react'

import { type TFunction } from 'i18next'

import {
  ColumnEditorType,
  type ColumnType,
  type EditorColumn
} from 'api/hooks/useGetParameterOptions/types'

import { type AutoCompleteItemStringId } from 'components/AutoComplete/types'

import {
  type ElementCollection,
  type ElementValueType
} from 'job-lib/types/Parameters'

import { GridDropdown } from './components/GridDropdown/GridDropdown'
import { GridExpressionEditor } from './components/GridExpressionEditor/GridExpressionEditor'
import { GridFreeText } from './components/GridFreeText/GridFreeText'
import { GridFreeTextMultiLineEditor } from './components/GridFreeTextMultiLine/GridFreeTextMultiLineEditor'
import { type GridInputHandler, type GridRow } from './types'

export const getHighestRowSlot = (rows: GridRow[]): number => {
  const ids = rows.map(({ id }) => parseInt(id))

  if (!ids.length) return 1

  return Math.max(...ids) + 1
}

export const generateBlobRow = (
  newSlotId: number,
  columns: EditorColumn[],
  autofillOptions: string[] = []
) => {
  const blobRow: GridRow = { id: String(newSlotId), cells: {} }

  columns.forEach(({ type, defaultValue }, i) => {
    const newColumnSlot = i + 1
    blobRow.cells[newColumnSlot] = {
      type: parseColumnType(type),
      value: autofillOptions[i] ?? defaultValue ?? '',
      slot: newColumnSlot,
      rowSlot: newSlotId,
      dataType: type
    }
  })
  return blobRow
}

export const parseColumnType = (columnType: ColumnType): ElementValueType => {
  if (columnType === 'INTEGER') {
    return 'INTEGER'
  }

  return 'STRING'
}

export const init = (
  elements: ElementCollection,
  columns: EditorColumn[]
): GridRow[] => {
  /**
   * Parses `elements` from the job to rows that can be consumed by DataGrid
   * It also adds a blob row at the end by default
   */
  const rows = Object.entries(elements).map(([rowSlot, rowData]) => {
    const tempRow: GridRow = { id: rowSlot, cells: {} }

    columns.forEach((column, i) => {
      const columnSlot = i + 1
      const element = rowData.values[columnSlot]

      tempRow.cells[columnSlot] = {
        type: parseColumnType(column.type),
        value: element?.value ?? '',
        slot: columnSlot,
        rowSlot: parseInt(rowSlot),
        dataType: column.type
      }
    })

    return tempRow
  })

  if (!rows.length) {
    // initialise table with an empty row
    const newRowSlot = getHighestRowSlot(rows)
    const blobRow = generateBlobRow(newRowSlot, columns)
    rows.push(blobRow)
  }

  return rows
}

export const initGridColumns = (
  elements: ElementCollection,
  columns: string[]
): Record<string, AutoCompleteItemStringId> => {
  const initial: Record<string, AutoCompleteItemStringId> = {}
  return columns.reduce((accum, value, index) => {
    const slot = index + 1
    if (!elements[2].values[slot]) {
      return accum
    }
    const gridColumnName = elements[2].values[slot].value
    return {
      ...accum,
      [value]: { id: gridColumnName, name: gridColumnName }
    }
  }, initial)
}

export const initGridVariable = (
  elements: ElementCollection
): AutoCompleteItemStringId => {
  const gvName = elements[1].values[1].value

  return { id: gvName, name: gvName }
}

export const translateColumnName = (column: EditorColumn, t: TFunction) => {
  const { resourceID } = column
  const columnName = column.name ?? ''
  const resourceName = resourceID
    ? t(`componentProperties.columnIds.${resourceID}`)
    : ''
  return columnName || resourceName
}

const inputMap = {
  FREETEXT: GridFreeText,
  DROPDOWN: GridDropdown,
  EXPRESSION: GridExpressionEditor,
  FREETEXT_MULTI_LINE: GridFreeTextMultiLineEditor
} as const

export const getColumnMappingReactTable = (
  column: EditorColumn
): FunctionComponent<GridInputHandler> => {
  const editorType = column.columnEditorType as keyof object
  /* istanbul ignore next */
  return inputMap[editorType] ?? inputMap[ColumnEditorType.Freetext]
}

export const getDuplicateField = (rows: GridRow[]) => {
  const firstRowValues = rows.map(({ cells }) => cells[1]?.value)
  return firstRowValues.find(
    (value, index) => index !== firstRowValues.indexOf(value)
  )
}
