import { type FC } from 'react'

import { type SVGIconProps } from '../types'

export const AddOne: FC<SVGIconProps> = (props) => {
  return (
    <svg
      aria-label="Add one"
      {...props}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <circle r="19" transform="matrix(1 0 0 -1 19 19)" fill="#51A620" />
      <path
        d="M20.4193 14.25L25.0459 18.5682L20.4193 22.8864"
        stroke="#F2F2F2"
        strokeWidth="2"
      />
      <path d="M24.1206 18.5682H12.0914" stroke="#F2F2F2" strokeWidth="2" />
    </svg>
  )
}
