import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type CSSProperties,
  type FunctionComponent,
  type KeyboardEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Input,
  LoadingSpinner,
  Typography
} from '@matillion/component-library'
import { useVirtualizer, type Virtualizer } from '@tanstack/react-virtual'
import classnames from 'classnames'
import { useCombobox } from 'downshift'

import classes from './AutoComplete.module.scss'
import { displayDropdownAboveInput } from './displayDropdownAboveInput'
import { highlightText } from './higlightText'
import {
  type AutoCompleteActionProps,
  type AutoCompleteItemId,
  type AutoCompleteProps
} from './types'

const ITEM_HEIGHT = 48
const MAX_DROPDOWN_ITEMS = 5
const DROPDOWN_STATUS_HEIGHT = 60
const BORDER_BOTTOM_OFFSET = 1

function resolveItem(
  value: AutoCompleteItemId | string | number | null,
  availableItems: AutoCompleteItemId[]
) {
  if (typeof value === 'string' || typeof value === 'number') {
    return availableItems.find((item) => item.id === value)
  }
  return value
}

function resolveItemIndex(
  value: AutoCompleteItemId | string | number | null | undefined,
  availableItems: AutoCompleteItemId[]
) {
  if (!value) return -1

  const itemValue =
    typeof value === 'string' || typeof value === 'number' ? value : value.id

  return availableItems.findIndex((item) => item.id === itemValue)
}

function scrollToHighlightedIndex(
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>,
  highlightedIndex: number
) {
  const range = rowVirtualizer.calculateRange()

  if (!range) return

  const { startIndex, endIndex } = range
  const isItemHighlighted = highlightedIndex > -1
  const isItemHidden =
    highlightedIndex <= startIndex || highlightedIndex >= endIndex

  if (isItemHighlighted && isItemHidden) {
    rowVirtualizer.scrollToIndex(highlightedIndex)
  }
}

export const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
  name: fieldName,
  placeholder = '',
  className = '',
  error = false,
  loading = false,
  disabled = false,
  enableHighlight = true,
  availableItems,
  value,
  onChange,
  onBlur,
  onClick,
  onKeyDown,
  action,
  allowFreetext,
  colorTheme,
  inputClassName,
  inputId,
  optionClassName,
  resultsClassName,

  scrollableContainerSelectors = [
    '#parameter-overlay',
    '#parameter-overlay-grid'
  ],
  fixAutocompletePosition = false,
  ...otherProps
}) => {
  const withinScrollableContainer = Boolean(
    document.querySelector(scrollableContainerSelectors[0])
  )

  const { t } = useTranslation()
  const [shouldFilterItems, setShouldFilterItems] = useState(true)
  const [isDropdownAbove, setIsDropdownAbove] = useState<boolean>(false)
  const [internalValue, setInternalValue] = useState<AutoCompleteItemId | null>(
    null
  )

  const inputRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const selectedItem =
    typeof value !== 'undefined'
      ? resolveItem(value, availableItems)
      : internalValue
  const [text, setText] = useState<string>(selectedItem?.name ?? '')
  const inputValue = selectedItem?.name ?? text

  const filteredItems = shouldFilterItems
    ? availableItems.filter((item) =>
        item.name.toLowerCase().includes(text.toLowerCase())
      )
    : availableItems

  const initHighlightedIndex = resolveItemIndex(value, filteredItems)

  const rowVirtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => dropdownRef.current,
    estimateSize: () => ITEM_HEIGHT,
    overscan: 20
  })

  const changeSelection = (
    item: AutoCompleteItemId | null,
    isFreetext = false
  ): void => {
    if (item?.id !== selectedItem?.id) {
      if (item) {
        setText(item.name)
      }
      setInternalValue(item)
      onChange({
        target: {
          name: fieldName,
          value: item,
          isFreetext
        }
      })
    }
  }

  const onInputChange = (changedInputValue: string) => {
    setText(changedInputValue)
    setShouldFilterItems(true)
    if (!changedInputValue) {
      changeSelection(null)
      return
    }

    const item =
      availableItems.find(
        ({ name }) => name.toLowerCase() === changedInputValue.toLowerCase()
      ) ?? null

    if (item?.disabled) {
      return
    }

    if (item || !allowFreetext) {
      changeSelection(item)
      return
    }

    changeSelection(
      {
        id: changedInputValue,
        name: changedInputValue
      },
      true
    )
  }

  const {
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    isOpen,
    closeMenu,
    openMenu,
    setHighlightedIndex
  } = useCombobox({
    items: filteredItems,
    inputValue: text || (internalValue?.name ?? ''),
    selectedItem,
    itemToString: (item) => item?.name ?? '',
    onStateChange: ({ type, ...changes }) => {
      const {
        InputKeyDownEscape,
        ItemClick,
        InputKeyDownEnter,
        InputKeyDownArrowUp,
        InputKeyDownArrowDown
      } = useCombobox.stateChangeTypes

      switch (type) {
        case InputKeyDownEscape:
          if (inputValue && !selectedItem) {
            setText('')
          }
          break
        case InputKeyDownArrowUp:
        case InputKeyDownArrowDown:
          /* istanbul ignore else */
          if (changes.highlightedIndex !== undefined) {
            scrollToHighlightedIndex(rowVirtualizer, changes.highlightedIndex)
          }
          break
        case ItemClick:
        case InputKeyDownEnter:
          if (changes.selectedItem) {
            changeSelection(changes.selectedItem)
          }
          break
        default:
          break
      }
    }
  })

  useEffect(() => {
    const hasHighlightedIndex =
      filteredItems.length > 0 && initHighlightedIndex > -1

    // This will scroll to the highlighted index when the panel is first opened
    // and set the internal highlighted state inside of downshift.
    // After this the highlighted and scroll state is handled by downshift in the onStateChange.
    if (hasHighlightedIndex && isOpen) {
      rowVirtualizer.scrollToIndex(initHighlightedIndex, {
        align: 'start'
      })
      setHighlightedIndex(initHighlightedIndex)
    }
  }, [
    filteredItems.length,
    initHighlightedIndex,
    isOpen,
    rowVirtualizer,
    setHighlightedIndex
  ])

  useEffect(() => {
    if (withinScrollableContainer) {
      scrollableContainerSelectors.forEach((scrollableContainerSelector) => {
        document
          .querySelector(scrollableContainerSelector)
          ?.addEventListener('scroll', closeMenu)
      })
    }

    return () => {
      if (withinScrollableContainer) {
        scrollableContainerSelectors.forEach((scrollableContainerSelector) => {
          document
            .querySelector(scrollableContainerSelector)
            ?.removeEventListener('scroll', closeMenu)
        })
      }
    }
  }, [closeMenu, withinScrollableContainer, scrollableContainerSelectors])

  useLayoutEffect(() => {
    const displayDropdownAbove = displayDropdownAboveInput({
      componentRef: inputRef.current,
      dropdownRef: dropdownRef.current,
      isOpen
    })

    setIsDropdownAbove(displayDropdownAbove)

    // Although no used this effect needs to re-run after
    // loading has completed to ensure the dropdown is
    // positioned correctly when the loading spinner is removed
  }, [inputRef, dropdownRef, isOpen, loading])

  const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e, isOpen)
    }
  }

  const inputProps = getInputProps({
    onFocus: (e) => {
      setShouldFilterItems(false)
      e.currentTarget.select()
      openMenu()
    },
    onBlur,
    onClick,
    onKeyDown: keyDownHandler,
    onChange: (e) => {
      onInputChange(e.currentTarget.value)
    }
  })

  const getDropdownHeight = () => {
    const maxDropdownHeight = MAX_DROPDOWN_ITEMS * ITEM_HEIGHT

    if (loading) {
      return DROPDOWN_STATUS_HEIGHT
    }

    if (filteredItems.length === 0) {
      return DROPDOWN_STATUS_HEIGHT
    }

    const items = filteredItems.length
    const height =
      items > MAX_DROPDOWN_ITEMS ? maxDropdownHeight : items * ITEM_HEIGHT

    return height + BORDER_BOTTOM_OFFSET
  }

  const getDropdownStyle = () => {
    const dropdownHeight = getDropdownHeight()
    let style: CSSProperties = {
      height: `${dropdownHeight}px`
    }

    if (!inputRef.current) {
      return style
    }

    const {
      top: distanceToInputY,
      left: distanceToInputX,
      height: inputHeight,
      width: inputWidth
    } = inputRef.current.getBoundingClientRect()

    if (withinScrollableContainer || fixAutocompletePosition) {
      style = {
        ...style,
        position: 'fixed',
        width: inputWidth,
        top:
          distanceToInputY +
          inputHeight -
          (isDropdownAbove ? dropdownHeight + inputHeight : 0),
        left: distanceToInputX
      }
    } else if (isDropdownAbove) {
      style = {
        ...style,
        bottom: inputHeight
      }
    }

    return style
  }

  const noMatchingResults = isOpen && filteredItems.length === 0 && !loading

  return (
    <div
      ref={inputRef}
      className={classnames(
        classes.AutoComplete,
        { [classes['AutoComplete--Open']]: isOpen },
        className
      )}
    >
      <div
        {...getComboboxProps()}
        onFocus={() => {
          if (inputProps.onClick) {
            inputProps.onClick()
          }
        }}
      >
        <Input
          data-testid="autocomplete-input"
          autoComplete="off"
          colorTheme={colorTheme}
          {...otherProps}
          {...inputProps}
          id={inputId ?? inputProps.id}
          value={isOpen ? text : selectedItem?.name ?? ''}
          error={error}
          disabled={disabled}
          placeholder={isOpen ? 'Start typing' : placeholder}
          className={classnames(classes.AutoComplete__Input, inputClassName)}
          name={fieldName}
          iconAfter={{
            icon: (
              <button
                aria-label={isOpen ? 'Collapse dropdown' : 'Expand dropdown'}
                data-testid="autocomplete-toggle"
                type="button"
                className={classnames(classes.AutoComplete__Toggle, {
                  [classes['AutoComplete--Disabled']]: disabled
                })}
                {...getToggleButtonProps({
                  onClick
                })}
              >
                <Icon.ChevronDown />
              </button>
            ),
            clickable: !disabled
          }}
        />
      </div>
      <div
        className={classnames(classes.AutoComplete__Results, resultsClassName, {
          [classes['AutoComplete__Results--Above-Input']]: isDropdownAbove
        })}
        style={getDropdownStyle()}
        ref={dropdownRef}
      >
        <ul
          {...getMenuProps()}
          className={classes.AutoComplete__List}
          style={
            noMatchingResults
              ? {}
              : { height: `${rowVirtualizer.getTotalSize()}px` }
          }
        >
          {isOpen &&
            rowVirtualizer.getVirtualItems().map((virtualItem) => {
              const index = virtualItem.index
              const item = filteredItems[index]

              return (
                <li
                  key={virtualItem.key}
                  ref={rowVirtualizer.measureElement}
                  data-testid="autocomplete-item"
                  style={{
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`
                  }}
                  className={classnames(
                    classes.AutoComplete__Item,
                    {
                      [classes['AutoComplete__Item--Highlight']]:
                        highlightedIndex === index,
                      [classes['AutoComplete__Item--Current']]:
                        selectedItem?.id === item.id,
                      [classes['AutoComplete__Item--Disabled']]: item.disabled
                    },
                    optionClassName
                  )}
                  {...getItemProps({
                    item,
                    index,
                    onClick: (e) => {
                      e.currentTarget.click()
                    },
                    disabled: !!item.disabled
                  })}
                >
                  {enableHighlight ? (
                    highlightText(item.name, inputValue)
                  ) : (
                    <span>{item.name}</span>
                  )}
                  {typeof item.disabled === 'string' && (
                    <span className={classes.AutoComplete__DisabledHint}>
                      <Icon.Error />
                      <Typography as="span" format="mc">
                        {item.disabled}
                      </Typography>
                    </span>
                  )}
                </li>
              )
            })}
          {isOpen && loading && (
            <div
              className={classnames(
                classes.AutoComplete__Item,
                optionClassName
              )}
            >
              <div
                className={classnames(
                  classes.AutoComplete,
                  classes['AutoComplete--Loading'],
                  className
                )}
                data-testid="autocomplete-loading"
              >
                <LoadingSpinner />
              </div>
            </div>
          )}
          {noMatchingResults && (
            <div
              className={classnames(
                classes.AutoComplete__Item,
                classes['AutoComplete__Item--Disabled'],
                classes['AutoComplete__Item--No-Results'],
                optionClassName
              )}
            >
              <Typography as="span" format="bcs">
                {t('common.noItemsMatchSearch')}
              </Typography>
            </div>
          )}
        </ul>
        {action && (
          <div className={classes.AutoComplete__ActionBox}>{action}</div>
        )}
      </div>
    </div>
  )
}

export const AutoCompleteAction: FunctionComponent<
  React.PropsWithChildren<AutoCompleteActionProps>
> = ({ icon, onClick, className = '', children, ...otherProps }) => {
  return (
    <button
      data-testid="autocomplete-action"
      type="button"
      className={classnames(classes.AutoCompleteAction, className)}
      onClick={onClick}
      {...otherProps}
    >
      {icon}
      <Typography
        className={classes.AutoCompleteAction__Text}
        as="span"
        weight="normal"
        format="bcs"
      >
        {children}
      </Typography>
    </button>
  )
}
