import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { ItemSelect } from 'components/ItemSelect'

import classes from '../MultiOptionSelector.module.scss'

export const OptionSelector = ({
  parameterName,
  updatedValues,
  setUpdatedValues,
  editorColumns
}: {
  parameterName: string
  updatedValues: string[]
  setUpdatedValues: Dispatch<string[]>
  editorColumns: EditorColumn[]
}) => {
  const { t } = useTranslation()

  const options = editorColumns[0]?.options ?? []
  const invalidItems = updatedValues.filter((item) => !options.includes(item))
  const items = [...invalidItems, ...options]

  const labels = {
    allItemsLabel: t('itemSelector.allItemsLabel', {
      label: parameterName
    }),
    selectedItemsLabel: t('itemSelector.selectedItemsLabel', {
      label: parameterName
    }),
    searchLabel: t('itemSelector.searchLabel'),
    noDataAvailable: t('itemSelector.noDataAvailable'),
    controlAddAll: t('itemSelector.controlAddAll'),
    controlAddOne: t('itemSelector.controlAddOne'),
    controlRemoveAll: t('itemSelector.controlRemoveAll'),
    controlRemoveOne: t('itemSelector.controlRemoveOne'),
    controlReset: t('itemSelector.controlReset'),
    controlMoveUp: t('itemSelector.controlMoveUp'),
    controlMoveDown: t('itemSelector.controlMoveDown')
  }

  return (
    <div className={classes.MultiOptionSelector} data-testid="option-selector">
      <ItemSelect
        invalidItems={invalidItems}
        items={items}
        selected={updatedValues}
        options={{
          listHeight: 400,
          itemSize: 32,
          showDragHandle: false,
          labels
        }}
        onSelect={(item) => {
          setUpdatedValues(item)
        }}
      />
    </div>
  )
}
