/* eslint-disable @typescript-eslint/no-dynamic-delete */
import type { ComponentName, Pipeline, Transition } from 'types/Pipeline'

type LinkType = 'source' | 'iteration' | keyof typeof Transition

interface DeleteLinkPayload {
  sourceName: ComponentName
  targetName: ComponentName
  linkType?: LinkType
}
/**
 * Delete the specified link to `linkName`, originating from `componentName`.
 * If `linkType` is provided, only the matching link type will be deleted. (eg. `sources`)
 * If no `linkType` is provided, any links to `linkName` will be deleted from `componentName`.
 */
export const deleteLink =
  (state: Pipeline) =>
  ({ sourceName, targetName, linkType }: DeleteLinkPayload) => {
    const component = state.pipeline.components[sourceName]

    for (const transitionType in component.transitions) {
      const transitionTypeKey = transitionType as keyof typeof Transition
      const transitions = component.transitions[transitionTypeKey]

      if (linkType === transitionType || !linkType) {
        component.transitions[transitionTypeKey] = transitions?.filter(
          (transition) => transition !== targetName
        )
      }
    }

    if (linkType === 'source' || !linkType) {
      if (component.sources) {
        component.sources = component.sources.filter(
          (source) => source !== targetName
        )
      }
    }

    if (linkType === 'iteration' || !linkType) {
      if (component.iterationTarget === targetName) {
        delete component.iterationTarget
      }
    }

    const hasTransitions =
      component.transitions &&
      Object.values(component.transitions).some(
        (transition) => transition.length > 0
      )

    if (!hasTransitions) {
      delete component.transitions
    }
  }
