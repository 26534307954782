import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useDeleteFile } from 'hooks/useDeleteFile'
import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import classes from './DeleteFolderModal.module.scss'

export interface DeleteFolderModalProps {
  pipelines: JobSummaryId[]
  path: string
  onClose: () => void
}

const DeleteFolderModal = ({
  path,
  pipelines,
  onClose
}: DeleteFolderModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'delete-folder.modal'
  })

  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { branchId, projectId } = useProjectInfo()
  const { deleteFile, isLoading } = useDeleteFile()
  const { enableSharedPipelines, enableHighCode } = useFlags()
  const { deletePipelinesFromSharedConfig, getSharedPipelinesInFolder } =
    useSharePipeline()

  const translationKeySuffix = enableHighCode ? 'file' : 'pipeline'

  const handleSubmit = useCallback(() => {
    deleteFile({ name: path }).then(async () => {
      if (enableSharedPipelines) {
        await deletePipelinesFromSharedConfig(pipelines)
      }

      onFileTreeCommand({
        type: FileTreeReducerType.REMOVE_FOLDER,
        branch: branchId,
        project: projectId,
        fileDetails: { path }
      })

      onClose()
    })
  }, [
    branchId,
    deleteFile,
    deletePipelinesFromSharedConfig,
    enableSharedPipelines,
    onClose,
    onFileTreeCommand,
    path,
    pipelines,
    projectId
  ])

  const sharedPipelines = getSharedPipelinesInFolder(path)

  return (
    <DesignerModal
      size="mid"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick
      id="delete-folder-dialog"
      data-testid="delete-folder-modal"
      ariaLabelledBy="delete-folder-title"
    >
      <Typography as="h2" format="tl" className={classes.Title}>
        {t('title')}
      </Typography>

      <div className={classes.Text}>
        <Trans
          values={{ folder: path }}
          components={{ bold: <strong /> }}
          i18nKey="delete-folder.modal.are-you-sure"
        />
      </div>

      <Typography format="bcm" className={classes.Text}>
        {t('sub-folders-warning')}
      </Typography>

      <Typography format="bcm" className={classes.Text}>
        {t(`schedules-warning.${translationKeySuffix}`)}
      </Typography>

      {enableSharedPipelines && sharedPipelines.length > 0 && (
        <>
          <Trans
            values={{ sharedPipelines: sharedPipelines.length }}
            components={{ bold: <strong /> }}
            i18nKey="delete-folder.modal.shared-pipelines-warning-title"
          />
          <Typography format="bcm" className={classes.Text}>
            {t('shared-pipelines-warning-info')}
          </Typography>
          <div className={classes.ScrollableSection}>
            <ul>
              {sharedPipelines.map((detail) => (
                <li key={detail.pipeline}>
                  <Typography format="bcm" weight="bold">
                    {detail.pipeline}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <Typography format="bcm" className={classes.Text}>
        {t('final-warning')}
      </Typography>

      <div className={classes.Buttons}>
        <Button
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          id="delete-folder-cancel"
          data-testid="delete-folder-cancel"
        />
        <Button
          alt="danger"
          type="submit"
          text={t('submit')}
          disabled={isLoading}
          onClick={handleSubmit}
          id="delete-folder-create"
          data-testid="delete-folder-submit"
        />
      </div>
    </DesignerModal>
  )
}

export default DeleteFolderModal
