import React from 'react'
import { useTranslation } from 'react-i18next'

import { type JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

import { getJobType, getRecordName } from './getRecordDetails'

interface JobNotConvertedRecordDetailsProps {
  jobsNotConverted: string[]
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  marginTop: 10,
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const rowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const tableCellStyle: React.CSSProperties = {
  textAlign: 'left',
  fontSize: '1rem',
  padding: 7,
  flexWrap: 'wrap',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const jobNameCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  width: '25%'
}

const jobTypeCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  width: '12%'
}

const reasonCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  width: '65%'
}

const getJobNotConvertedRecordDetails = (jobNotConvertedRecord: string) => {
  const jobRecordSegments = jobNotConvertedRecord.split(' - ')
  const jobName = getRecordName(jobRecordSegments[0])
  const jobRecord: JobConversionRecord = {
    name: jobRecordSegments[0],
    variablesConverted: [],
    gridVariablesConverted: [],
    variablesNotConverted: [],
    gridVariablesNotConverted: [],
    components: []
  }
  const jobType = getJobType(jobRecord)
  const reason = jobRecordSegments[1]
  return { jobName, jobType, reason }
}

const JobNotConvertedRecordDetails = ({
  jobsNotConverted
}: JobNotConvertedRecordDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <table style={tableStyle}>
      <thead>
        <tr style={rowStyle}>
          <th style={jobNameCellStyle}>{t('jobDetailsHeader')}</th>
          <th style={jobTypeCellStyle}>{t('jobTypeHeader')}</th>
          <th style={reasonCellStyle}>Reason</th>
        </tr>
      </thead>
      <tbody>
        {jobsNotConverted.map((jobNotConvertedRecord) => (
          <tr key={jobNotConvertedRecord} style={rowStyle}>
            <td style={jobNameCellStyle}>
              {getJobNotConvertedRecordDetails(jobNotConvertedRecord).jobName}
            </td>
            <td style={jobTypeCellStyle}>
              {getJobNotConvertedRecordDetails(jobNotConvertedRecord).jobType}
            </td>
            <td style={reasonCellStyle}>
              {getJobNotConvertedRecordDetails(jobNotConvertedRecord).reason}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default JobNotConvertedRecordDetails
