import { useMutation } from '@tanstack/react-query'

import { useCopilotApi } from '../useCopilotApi/useCopilotApi'

export interface PipelineMetadata {
  projectId: string
  branchId: string
  pipelineId: string
}

export interface SQLExpressionRequest {
  requestId: string
  warehouse: string
  message: string
  pipelineMetadata: PipelineMetadata
  columns: Array<{
    name: string
    type: string
  }>
}

export interface SQLExpressionResponse {
  sql: string
}

export const useGenerateSQLExpression = () => {
  const { generateSQLExpression } = useCopilotApi()

  return useMutation(async (request: SQLExpressionRequest) => {
    const response = await generateSQLExpression(request)
    return response.data
  })
}
