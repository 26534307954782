import { useTranslation } from 'react-i18next'

import { Icon, Tab, Tabs, Typography } from '@matillion/component-library'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { PipelineLogging } from './PipelineLogging/PipelineLogging'
import classes from './PipelineRunExplorer.module.scss'
import PipelineTree from './PipelineTree/PipelineTree'
import { SelectedTaskTab } from './types'
import { manageExpandedItems } from './utils/manageExpandedItems'

const tabIndexOrder = [SelectedTaskTab.TASKS, SelectedTaskTab.LOGGING]

const PipelineRunExplorer = () => {
  const { flowInstanceId } = useProjectInfo()
  const { t } = useTranslation()
  const { enableLoggingView } = useFlags()

  const TASK_DETAILS_POLL_INTERVAL = process.env
    .REACT_APP_TASK_DETAILS_POLL_INTERVAL
    ? Number(process.env.REACT_APP_TASK_DETAILS_POLL_INTERVAL)
    : 5000

  if (!flowInstanceId) {
    return (
      <div className={classes.Error} data-testid="pipeline-error-container">
        <Icon.InfoNoBg className={classes.Error__Icon} />
        <Typography className={classes.Error__Text}>
          {t('translation:taskExplorer.networkError')}
        </Typography>
      </div>
    )
  } else {
    manageExpandedItems.initialise(flowInstanceId)
  }

  if (!enableLoggingView) {
    return (
      <PipelineTree
        key={flowInstanceId}
        pipelineRunId={flowInstanceId}
        pollInterval={TASK_DETAILS_POLL_INTERVAL}
      />
    )
  }

  return (
    <div
      className={classes.PipelineRunExplorer}
      data-testid="pipeline-run-explorer"
    >
      <h2 className="u-visually-hidden" id="task-tabs-header">
        TODO: Add Header as part of DPC-23068
      </h2>
      <Tabs
        // Key is used to reset the tabs when navigating between tasks
        key={`pipeline-run-explorer-${flowInstanceId}`}
        aria-labelledby="task-tabs-header"
        listClassName={classes.PipelineRunExplorer__Tabs}
        contentClassName={classes.PipelineRunExplorer__TabContent}
        initialIndex={tabIndexOrder.indexOf(
          manageExpandedItems.getSelectedTab(flowInstanceId)
        )}
        onChange={({ value: index }) => {
          manageExpandedItems.setSelectedTab(
            flowInstanceId,
            tabIndexOrder[index]
          )
        }}
      >
        <Tab title={t('pipelineRunExplorer.tabTitles.tasks')}>
          <PipelineTree
            key={flowInstanceId}
            pipelineRunId={flowInstanceId}
            pollInterval={TASK_DETAILS_POLL_INTERVAL}
          />
        </Tab>
        <Tab title={t('pipelineRunExplorer.tabTitles.logging')}>
          <PipelineLogging
            pipelineRunId={flowInstanceId}
            pollInterval={TASK_DETAILS_POLL_INTERVAL}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default PipelineRunExplorer
