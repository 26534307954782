import type {
  GridVariable,
  Pipeline,
  ScalarVariable,
  VariableName
} from 'types/Pipeline'

export interface UpdateVariablePayload {
  name: VariableName
  oldName?: VariableName
  variable: ScalarVariable | GridVariable
}

export const updateVariable =
  (state: Pipeline) =>
  ({ name, oldName, variable }: UpdateVariablePayload) => {
    if (oldName) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.pipeline.variables[oldName]
    }

    state.pipeline.variables[name] = variable
  }
