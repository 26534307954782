import { cloneDeep, toUpper } from 'lodash'

import type {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { parseLookupDependencyKey } from 'hooks/useParameterOptions/parseLookupDependencyKey'

const concat = (parentKey: string | undefined, childKey: string) =>
  parentKey ? `${parentKey}.${childKey}` : childKey

function filterSupportedAndPutRemovedInSet(
  componentParameters: ComponentParameter[],
  projectWarehouse: string,
  supportedParameters: Set<string>,
  unsupportedParameters: Set<string>,
  parentDplId?: string
) {
  return componentParameters.filter((parameter) => {
    // Get the state of the parent parameter (if present).
    let isSupported = isParentSupported(parentDplId, supportedParameters)

    const childDplId = concat(parentDplId, parameter.dplID)

    // If the parent parameter is supported, then lets check the current child parameter and its warehouse overrides.
    // If the parent parameter is not supported, then the child parameter is also not supported.
    if (isSupported && parameter.warehouses) {
      const paramOverride = parameter.warehouses[toUpper(projectWarehouse)]
      if (paramOverride?.metlSlot) {
        parameter.metlSlot = paramOverride?.metlSlot
      }
      isSupported = !!paramOverride
    }

    if (isSupported) {
      supportedParameters.add(childDplId)
    } else {
      unsupportedParameters.add(childDplId)
    }

    if (parameter.childProperties) {
      parameter.childProperties = filterSupportedAndPutRemovedInSet(
        parameter.childProperties,
        projectWarehouse,
        supportedParameters,
        unsupportedParameters,
        childDplId
      )
    }

    return isSupported
  })
}

function isParentSupported(
  parentDplId: string | undefined,
  supportedParameters: Set<string>
) {
  return !parentDplId || supportedParameters.has(parentDplId)
}

// parameter should be removed from visibleWhen and lookupDependencies if it refers to some unsupported parameter.
function removeUnsupportedParametersProperties(
  parameters: ComponentParameter[],
  supportedParameters: Set<string>,
  unsupportedParameters: Set<string>,
  parentDplId?: string
) {
  parameters.forEach((parameter) => {
    const childDplId = concat(parentDplId, parameter.dplID)

    // recursively remove unsupported parameters from childProperties.
    if (parameter.childProperties) {
      removeUnsupportedParametersProperties(
        parameter.childProperties,
        supportedParameters,
        unsupportedParameters,
        childDplId
      )
    }

    // remove unsupported parameters from visibleWhen.
    if (parameter.visibleWhen) {
      parameter.visibleWhen = parameter.visibleWhen.filter(({ param }) => {
        return param === null || !unsupportedParameters.has(param)
      })
    }

    // remove unsupported parameters from staticOptions lookupDependencies.
    if (parameter.staticOptions) {
      parameter.staticOptions
        .filter((ec) => ec.lookupDependencies)
        .forEach((ec) => {
          ec.lookupDependencies = getStaticOptionsLookupDependencies(
            ec,
            supportedParameters
          )
        })
    }

    // remove unsupported parameters from lookupDependencies.
    if (parameter.lookupDependencies) {
      parameter.lookupDependencies = parameter.lookupDependencies.filter((ld) =>
        isSupportedLookup(ld, supportedParameters)
      )
    }
  })
}

function getStaticOptionsLookupDependencies(
  editorColumn: EditorColumn,
  supportedParameters: Set<string>
) {
  // @ts-expect-error lookupDependencies never null
  return editorColumn.lookupDependencies.filter((ld) =>
    isSupportedLookup(ld, supportedParameters)
  )
}

function isSupportedLookup(
  dependency: string,
  /**
   * A set of parameter paths that are supported by the current environment.
   */
  supportedParameters: Set<string>
) {
  const key = parseLookupDependencyKey('param', dependency)
  if (!key) {
    // The lookup dependency key is not in the expected format, then we assume it is supported.
    // This covers the cases where the lookup dependency is not another parameter (transform.sql, etc).
    return true
  }

  // Check full path is part of the known supported parameters.
  const fullPath = key?.parameterPath.join('.')

  return supportedParameters.has(fullPath)
}

export const removeUnsupportedParametersAndReplaceSlot = (
  projectWarehouse: string,
  metadata: ComponentMetadata
) => {
  const output = cloneDeep(metadata)
  const supportedParameters = new Set<string>()
  const unsupportedParameters = new Set<string>()

  output.parameters = filterSupportedAndPutRemovedInSet(
    output.parameters,
    projectWarehouse,
    supportedParameters,
    unsupportedParameters
  )

  removeUnsupportedParametersProperties(
    output.parameters,
    supportedParameters,
    unsupportedParameters
  )

  return output
}
