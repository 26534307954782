import { type ComponentLayout } from './types'

export const Dynamics365QueryConfig: ComponentLayout = {
  componentId: 'dynamics-365-query',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          { dplId: 'authentication' },
          { dplId: 'connectionOptions' }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          { dplId: 'basicAdvancedMode' },
          { dplId: 'dataSchema' },
          { dplId: 'dataSource' },
          { dplId: 'dataSelection' },
          { dplId: 'dataSourceFilter' },
          { dplId: 'combineFilters' },
          { dplId: 'sqlQuery' },
          { dplId: 'limit' }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: null,
        parameters: [
          { dplId: 'type' },
          { dplId: 'primaryKeys' },
          { dplId: 'warehouse1' },
          { dplId: 'database' },
          { dplId: 'schema' },
          { dplId: 'targetTable' },
          { dplId: 'stage1' },
          { dplId: 'catalog' },
          { dplId: 'database2' },
          { dplId: 'table' },
          { dplId: 'stagePlatform' },
          { dplId: 'stagePlatform1' },
          { dplId: 'stageVolume' },
          { dplId: 'stageAuthentication' },
          { dplId: 'storageIntegration' },
          { dplId: 's3StagingArea' },
          { dplId: 'schema2' },
          { dplId: 'targetTable1' },
          { dplId: 's3StagingArea1' },
          { dplId: 'useAcceleratedEndpoint1' },
          { dplId: 'distributionStyle' },
          { dplId: 'distributionKey' },
          { dplId: 'sortKey' },
          { dplId: 'primaryKey' },
          { dplId: 'sortKeyOptions' },
          { dplId: 'stageAuthentication1' },
          { dplId: 'storageIntegration1' },
          { dplId: 'storageAccount' },
          { dplId: 'blobContainer' },
          { dplId: 'storageIntegration2' },
          { dplId: 'gcsStagingArea' },
          { dplId: 'warehouse' },
          { dplId: 'database1' },
          { dplId: 'schema1' },
          { dplId: 'newTableName' },
          { dplId: 'stageDatabase' },
          { dplId: 'stageSchema' },
          { dplId: 'stage' },
          { dplId: 'useAcceleratedEndpoint' },
          { dplId: 'schema3' },
          { dplId: 'targetTable2' },
          { dplId: 'location' },
          { dplId: 'useAcceleratedEndpoint2' }
        ]
      },
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [
          { dplId: 'loadOptions' },
          { dplId: 'encryption' },
          { dplId: 'kmsKeyId' },
          { dplId: 'autoDebug' },
          { dplId: 'debugLevel' }
        ]
      }
    ]
  }
}
