import { useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Tree,
  Typography,
  type TreeExpandedState
} from '@matillion/component-library'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { useParameterOptionsAutofill } from 'hooks/useParameterOptions/useParameterOptionsAutofill'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { Alerts } from './Alerts'
import { SchemaElement } from './SchemaElement'
import classes from './SemiStructuredNestedDataPickerEditor.module.scss'
import {
  type DataStructure,
  type SemiStructuredNestedDataPickerConfig,
  type SemiStructuredNestedDataPickerEditorProps
} from './types'
import {
  autofill,
  convertFromDataStructureToRootValue,
  convertFromRootValueToDataStructure,
  flattenDataStructure,
  getParameterMetadataField,
  modifyAllElements,
  parameterMetadataFieldPresent,
  updateDataStructure
} from './utils'

export const SemiStructuredNestedDataPickerEditor: FC<
  SemiStructuredNestedDataPickerEditorProps
> = ({
  editorColumns,
  onDone,
  elements,
  componentId,
  metadata,
  parameter,
  parameterName
}) => {
  const { isUnvalidated: isPipelineUnvalidated } =
    useComponentValidationProvider()

  const {
    error: autofillError,
    refetch,
    isFetching: isLoadingAutofill
  } = useParameterOptionsAutofill({
    componentMetaData: metadata,
    componentSummaryId: componentId,
    parameter,
    isEnabled: false,
    requestType: 'autofill-nested'
  })
  const columns = getParameterMetadataField(
    editorColumns,
    'sourceOfTheColumn',
    'options',
    []
  )

  // Defaults match the original snowflake-only implementation, older versions of the agent
  // may not send the parameter metadata.
  const editorConfig: SemiStructuredNestedDataPickerConfig = useMemo(
    () => ({
      requireAlias: parameterMetadataFieldPresent(editorColumns, 'alias'),
      requireSize: parameterMetadataFieldPresent(editorColumns, 'size'),
      requireScale: parameterMetadataFieldPresent(editorColumns, 'scale'),
      requireSelected: parameterMetadataFieldPresent(editorColumns, 'selected'),
      semiStructuredType: getParameterMetadataField(
        editorColumns,
        'semiStructuredDataType',
        'defaultValue',
        'VARIANT'
      ),
      types: [
        ...getParameterMetadataField(editorColumns, 'dataType', 'options', [
          'VARCHAR',
          'NUMBER',
          'FLOAT',
          'BOOLEAN',
          'DATE',
          'TIMESTAMP',
          'TIME',
          'VARIANT'
        ]),
        // Arrays are typically just the DWH's SemiStructured Type, but
        // we differentiate between struct and arrays in the UI.
        'ARRAY'
      ]
    }),
    [editorColumns]
  )
  const { semiStructuredType, requireSelected } = editorConfig

  const componentHasAutofill = !!parameter?.autoFill?.lookupType

  const { t } = useTranslation()
  const [dataStructure, setDataStructure] = useState<DataStructure[]>(() =>
    convertFromRootValueToDataStructure(elements, columns, semiStructuredType)
  )
  const flattened = useMemo(
    () => dataStructure.map((structure) => flattenDataStructure(structure)),
    [dataStructure]
  )

  const [allSelected, noneSelected] = useMemo(() => {
    const all = flattened.every((structure) => {
      const [, ...descendants] = Object.values(structure)

      return descendants.every((d) => {
        return d.selected
      })
    })

    const none = all
      ? false
      : flattened.every((structure) => {
          const [, ...descendants] = Object.values(structure)
          return descendants.every((d) => {
            return !d.selected
          })
        })
    return [all, none]
  }, [flattened])

  const expanded = useMemo<TreeExpandedState>(() => {
    return flattened.reduce<Record<string, boolean>>((flattenedRoot, node) => {
      return {
        ...flattenedRoot,
        ...Object.keys(node).reduce(
          (acc, key) => ({
            ...acc,
            [key]: true
          }),
          {}
        )
      }
    }, {})
    // We only want this run run once and using useState here upsets sonar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isEmptyAutofillResponse, setIsEmptyAutofillResponse] = useState(false)

  const updateTree = (oldRoot: DataStructure, newRoot: DataStructure) => {
    setDataStructure((previousState) =>
      updateDataStructure(previousState, oldRoot, newRoot)
    )
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <Alerts
        isPipelineValidated={!isPipelineUnvalidated}
        numberOfColumnsSelected={columns.length}
        autofillError={autofillError}
        isEmptyAutofillResponse={isEmptyAutofillResponse}
      />
      <ParameterOverlayContent>
        <div className={classes.Box}>
          <div className={classes.Box__Title}>
            <Typography format="bcs" weight="bold">
              {t('parameterEditor.NESTED_DATA_PICKER_EDITOR.tagline')}
            </Typography>
          </div>

          <div className={classes.Box__Content}>
            <Tree expandedItems={expanded}>
              {dataStructure.map((structure) => (
                <SchemaElement
                  editorConfig={editorConfig}
                  key={structure.key}
                  path={[structure.key]}
                  element={structure}
                  root={structure}
                  onChange={(newRoot) => {
                    updateTree(structure, newRoot)
                  }}
                />
              ))}
            </Tree>
          </div>
          <div className={classes.Box__Actions}>
            <Button
              onClick={() => {
                setDataStructure(
                  convertFromRootValueToDataStructure(
                    {},
                    columns,
                    semiStructuredType
                  )
                )
              }}
              size="sm"
              text={t('common.reset')}
              alt="secondary"
            />
            {requireSelected && (
              <Button
                disabled={allSelected}
                onClick={() => {
                  setDataStructure(
                    modifyAllElements(dataStructure, { selected: true })
                  )
                }}
                size="sm"
                text={t('common.selectAll')}
                alt="secondary"
              />
            )}
            {requireSelected && (
              <Button
                disabled={noneSelected}
                onClick={() => {
                  setDataStructure(
                    modifyAllElements(dataStructure, { selected: false })
                  )
                }}
                size="sm"
                text={t('common.clearAll')}
                alt="secondary"
              />
            )}
            {componentHasAutofill && (
              <Button
                disabled={isPipelineUnvalidated || columns.length === 0}
                onClick={() => {
                  refetch().then(({ data }) => {
                    const hasResults = data?.length

                    if (hasResults) {
                      setDataStructure(
                        autofill(data, dataStructure, semiStructuredType)
                      )
                    }
                    setIsEmptyAutofillResponse(hasResults === 0)
                  })
                }}
                size="sm"
                text={t('parameterEditor.NESTED_DATA_PICKER_EDITOR.autofill')}
                alt="secondary"
                waiting={isLoadingAutofill}
              />
            )}
          </div>
        </div>
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          text={t('common.save')}
          onClick={() => {
            onDone(convertFromDataStructureToRootValue(dataStructure))
          }}
        />
      </ParameterOverlayFooter>
    </>
  )
}
