import { useCallback } from 'react'

import { type MetlVariableScope } from 'api/hooks/useMigrationMetlJob'

import { type VariableScope } from 'job-lib/types/Variables'

export interface UseConvertVariableScopeResponse {
  convertVariableScope: (scope: MetlVariableScope) => VariableScope
}

const useConvertVariableScope = () => {
  const convertVariableScope = useCallback((scope: MetlVariableScope) => {
    switch (scope) {
      case 'BRANCH':
        return 'COPIED'
      case 'TASKBATCH':
        return 'SHARED'
    }
  }, [])

  return {
    convertVariableScope
  }
}

export default useConvertVariableScope
