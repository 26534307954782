import {
  FileStatus,
  type Files,
  type FileType
} from '@matillion/git-component-library'

/* A valid name has the following criteria:
- any number of trailing spaces
- any number of starting spaces
- includes letters, normal brackets or dashes
- must include at least one valid character
- words can only be separated by one space
*/
export const VALID_NAME_REGEX = /^ ?[\w()-]+(?: [\w()-]+)* ?$/

/**
 * Checks if the specified pipeline exists in the provided list of pipelines
 *
 * @param fileRuntimeName the full folder path + file name (no extension)
 * @param pipelineType The type of file
 * @param allFiles a list of file summaries
 * @returns true if exists, else false
 */
export const isFileExistingFile = (
  fileRuntimeName: string,
  fileType: FileType,
  existingFiles: Files
) => {
  const matchedFile = Object.values(existingFiles).find((existing) => {
    const hasSameRuntime =
      existing.runtimeName.toLowerCase() === fileRuntimeName.toLowerCase()
    const hasSameType = existing.type === fileType
    const hasNotBeenDeleted = existing.status !== FileStatus.DELETED
    return hasSameRuntime && hasSameType && hasNotBeenDeleted
  })

  return Boolean(matchedFile)
}
