import { type ComponentLayout } from './types'

export const SpcsPrompt: ComponentLayout = {
  componentId: 'spcs-ai-prompt',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'source',
        parameters: [
          {
            dplId: 'spcs'
          }
        ]
      },
      {
        stepId: 'source',
        displayName: 'Source',
        previousStepId: 'connect',
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'source'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'source',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'promptContext'
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: 'ragConfig',
        parameters: [
          {
            dplId: 'destination'
          }
        ]
      },
      {
        stepId: 'ragConfig',
        displayName: 'RAG Config',
        previousStepId: 'destination',
        nextStepId: null,
        parameters: [
          {
            dplId: 'ragConfig'
          }
        ]
      }
    ]
  }
}
