import { type ComponentLayout } from './types'

export const SurveyMonkeyConfig: ComponentLayout = {
  componentId: 'modular-survey-monkey-input-v0',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'connection'
          },
          {
            dplId: 'connectionOptions'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'survey-monkey-input-v0',
            omitParameters: [
              'connection',
              'connectionOptions',
              'autoDebug',
              'debugLevel'
            ]
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: 'incrementalLoading',
        parameters: [
          {
            dplId: 'outputId'
          },
          {
            dplId: 'databricks-output-connector-v0'
          },
          {
            dplId: 'storage-only-output-v0'
          },
          {
            dplId: 'snowflake-output-connector-v0'
          },
          {
            dplId: 'redshift-output-connector-v0'
          }
        ]
      },
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [
          {
            dplId: 'autoDebug'
          },
          {
            dplId: 'debugLevel'
          }
        ]
      }
    ]
  }
}
