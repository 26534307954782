import { type TransformationJob } from 'job-lib/types/Job'

export const deleteTransformationLink = (
  linkId: number,
  job: TransformationJob
) => {
  if (job.connectors[linkId] === undefined) {
    console.error(`Unable to find linkId: ${linkId}`)
    return
  }

  const { id, sourceID, targetID } = job.connectors[linkId]

  job.components[sourceID].outputConnectorIDs = job.components[
    sourceID
  ].outputConnectorIDs.filter((i) => i !== id)

  job.components[targetID].inputConnectorIDs = job.components[
    targetID
  ].inputConnectorIDs.filter((i) => i !== id)

  const { [linkId]: _, ...rest } = job.connectors

  job.connectors = { ...rest }
}
