import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  type CSSProperties,
  type KeyboardEvent,
  type PropsWithChildren
} from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { DataSelectionModal } from 'file-editors/canvas/modules/Canvas/components/AddNextComponent/components/DataSelectionModal'

import { ComponentDragSource } from 'components/ComponentDrag/ComponentDragSource'
import { useStackingModal } from 'components/StackingModalProvider'

import { type ExtendedProps } from 'hooks/useAvailableComponents/useAvailableComponents'
import { useFlags } from 'hooks/useFlags'

import { isCustomOrFlexConnector } from 'job-lib/cisIds/idType'
import { OutputPortType } from 'job-lib/types/Components'

import { isTopSourcesCookieTrue } from 'utils/isTopSourcesCookieTrue'

import { ComingSoonLabel } from '../ComingSoonLabel/ComingSoonLabel'
import { ComponentName } from '../ComponentName'
import ComponentReleaseStatus from './ComponentReleaseStatus'
import classes from './ComponentSummaryListItem.module.scss'

interface ComponentSummaryListItemProps {
  component: ExtendedProps
  displayName: string
  searchTerm: string
  isDraggable: boolean
  componentIndex: number
  selectedComponentIndex: number | null
  onSelectComponent?: (id: string) => Promise<void>
  onKeyDown: (e: KeyboardEvent) => void
  description?: string
  style?: CSSProperties
}

interface WrapperProps extends PropsWithChildren {
  component: ExtendedProps
  isDraggable: boolean
  icon: string
}

const shouldOpenDemoDialog = (
  component: ExtendedProps,
  enableUploadFilePaintedDoorExperiment: boolean
): boolean => {
  return (
    enableUploadFilePaintedDoorExperiment &&
    !isTopSourcesCookieTrue() &&
    (component.tags?.includes('Connector') ||
      component.tags?.includes('Source') ||
      component.tags?.includes('Custom')) &&
    localStorage.getItem('isDemoDialogDismissed') !== 'true'
  )
}

const Wrapper = ({ children, isDraggable, component, icon }: WrapperProps) => {
  const isIterator =
    component.outputPorts.filter((o) => o.portId === OutputPortType.ITERATION)
      .length > 0

  return isDraggable ? (
    <ComponentDragSource
      data-testid="component-drag-source"
      isIterator={isIterator}
      componentId={component.componentId}
      name={component.displayName}
      imageUrl={icon}
      dragOrigin="component-browser"
    >
      {children}
    </ComponentDragSource>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

const ComponentSummaryListItem = ({
  component,
  displayName,
  searchTerm,
  isDraggable,
  componentIndex,
  selectedComponentIndex,
  onSelectComponent,
  onKeyDown,
  description,
  style
}: ComponentSummaryListItemProps) => {
  const componentRef = useRef<HTMLButtonElement | null>(null)

  const showDescription = !isDraggable

  const { pushNewModalContent } = useStackingModal()
  const { enableUploadFilePaintedDoorExperiment } = useFlags()
  const { t } = useTranslation()

  useEffect(() => {
    if (componentIndex === selectedComponentIndex) {
      componentRef?.current?.focus()
    }
  }, [displayName, componentIndex, selectedComponentIndex])

  const handleClick = useCallback(() => {
    if (!onSelectComponent || !component.isAvailableForAgent) {
      return
    }

    onSelectComponent(component.componentId)
  }, [onSelectComponent, component])

  const canBeDragged = component.isAvailableForAgent ? isDraggable : false

  const getTabIndex = () => {
    if (selectedComponentIndex === null) {
      return componentIndex === 0 ? 0 : -1
    }

    return selectedComponentIndex === componentIndex ? 0 : -1
  }

  const showDemoDialog = shouldOpenDemoDialog(
    component,
    enableUploadFilePaintedDoorExperiment
  )

  const openDemoDialog = () => {
    pushNewModalContent(
      {
        className: classes.ModalContainer,
        setFocus: false,
        title: 'Select Type of Data',
        id: 'data-selection-modal',
        ariaLabelledBy: 'modal-title'
      },
      <DataSelectionModal
        componentId={component.componentId}
        displayName={component.displayName}
        onSelectComponent={onSelectComponent}
      />
    )
  }
  return (
    <div
      className={classes.ComponentSummaryListItem__Row}
      style={style}
      role="row"
    >
      <div role="gridcell" data-testid="component-list-item">
        <Wrapper
          component={component}
          isDraggable={canBeDragged}
          icon={component.icon}
        >
          <button
            data-testid={`row-${displayName}`}
            ref={componentRef}
            tabIndex={getTabIndex()}
            type="button"
            onClick={showDemoDialog ? openDemoDialog : handleClick}
            onKeyDown={onKeyDown}
            data-tracker-id="designer-use-own-data-button-control"
            className={classNames(
              classes.ComponentSummaryListItem,
              isDraggable && classes.ComponentSummaryListItem__Draggable,
              !component.isAvailableForAgent &&
                classes.ComponentSummaryListItem__Unselectable,
              showDescription &&
                classes.ComponentSummaryListItem__WithDescription
            )}
          >
            {isDraggable && (
              <Icon.DragHandle
                className={classNames(
                  classes.DragHandle,
                  !component.isAvailableForAgent &&
                    classes.DragHandle__Invisible
                )}
                data-testid={`drag-icon-${component.componentId}`}
              />
            )}

            <div
              className={classNames(
                classes.ComponentSummaryListItem__Icon,
                showDescription &&
                  classes['ComponentSummaryListItem__Icon--WithDescription']
              )}
            >
              <img
                role="presentation"
                src={component.icon}
                alt={displayName}
                onError={({ currentTarget }) =>
                  isCustomOrFlexConnector(component.componentId) &&
                  (currentTarget.src = '/components/icons/custom-connector.svg')
                }
              />
            </div>

            <ComponentName
              displayName={displayName}
              searchTerm={searchTerm}
              isDraggable={isDraggable}
            >
              <ComponentReleaseStatus
                component={component}
                extendedLabel={!isDraggable}
              />
            </ComponentName>

            <div className={classes.ComponentSummaryListItem__Badge}>
              {!component.isAvailableForAgent ? (
                <ComingSoonLabel labelText={t('componentBrowser.comingSoon')} />
              ) : (
                <Typography
                  format={showDescription ? 'mc' : 'bcs'}
                  as={showDescription ? 'span' : 'p'}
                  className={classNames(
                    classes.ComponentSummaryListItem__Tag,
                    showDescription &&
                      classes['ComponentSummaryListItem__Tag--WithDescription']
                  )}
                >
                  {component.tags[0]}
                </Typography>
              )}
            </div>
            {showDescription && description && (
              <Typography
                format="bcs"
                as="span"
                className={classNames(
                  classes[
                    'ComponentSummaryListItem__WithDescription--Description'
                  ]
                )}
              >
                {description}
              </Typography>
            )}
          </button>
        </Wrapper>
      </div>
    </div>
  )
}

export { ComponentSummaryListItem }
