import { useEffect, type FunctionComponent } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Routes } from 'react-router-dom'

import { datadogLogs } from '@datadog/browser-logs'
import { Layout } from '@matillion/component-library'
import {
  heap,
  HubHeader,
  RoleGuard,
  useAuth,
  usePendo,
  useSendLDExperimentAnalytics,
  useUser
} from '@matillion/hub-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FileEditor } from 'file-editors/FileEditor'

import {
  AGENT_ID,
  BRANCH_ID,
  ENVIRONMENT_ID,
  PROJECT_ID
} from '__test-utils__/ids'

import { Login, Logout } from 'modules/core/Auth'
import ErrorBoundaryFallback from 'modules/core/ErrorBoundaryFallback'
import EtlDesigner from 'modules/core/EtlDesigner'
import { ProjectGuard } from 'modules/core/ProjectGuard'
import { GettingStarted } from 'modules/GettingStarted'
import PipelineRunExplorer from 'modules/observability/PipelineRunExplorer/PipelineRunExplorer'

import { useFlags } from './hooks/useFlags'

// we're lazy loading here to preload the component, this
// ensures the chunk will always be available, even after a deploy clearing
// down the bucket
import('components/CodeEditor')

/* istanbul ignore next */
const DevRoutes = () => (
  <ul data-testid="test-routes" style={{ margin: '24px' }}>
    <li>
      <a
        href={`/project/${PROJECT_ID}/branch/${BRANCH_ID}?agentId=${AGENT_ID}&environmentId=${ENVIRONMENT_ID}`}
      >
        DEV THING
      </a>
    </li>
    <li>
      <a href="/empty/empty">EMPTY</a>
    </li>
    <li>
      <a href="/500/500">Error response</a>
    </li>
  </ul>
)

const App: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()
  const { user, profile, organisation } = useUser()
  const pendo = usePendo()
  const {
    enableSampleDataComponentConfigExperiment,
    enableS3StorageUrlEditorErrorExperiment
  } = useFlags()

  useEffect(() => {
    heap.identify(user.email)
  }, [user])

  useEffect(() => {
    if (isLoggedIn) pendo.initialize(user.sub, organisation.id)
  }, [isLoggedIn, user, organisation, pendo])

  useEffect(() => {
    if (isLoggedIn) {
      pendo.updateOptions(
        {
          email: user.email,
          full_name: profile.name
        },
        {
          name: organisation.name,
          subdomain: organisation.subdomain,
          region: organisation.region
        }
      )
    }
  }, [isLoggedIn, user, profile, organisation, pendo])

  useEffect(() => {
    if (!window.__MICROVERSE__) {
      heap.clearEventProperties()
    }
  }, [])

  useSendLDExperimentAnalytics([
    {
      experimentId: 'EXP0013',
      flagKey: 'enable-sample-data-component-config-experiment',
      variationName: enableSampleDataComponentConfigExperiment
        ? 'Variation'
        : 'Control'
    },
    {
      experimentId: 'EXP0016',
      flagKey: 'enable-s3-storage-url-editor-error-experiment',
      variationName: enableS3StorageUrlEditorErrorExperiment
        ? 'Variation'
        : 'Control'
    }
  ])

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <RoleGuard role="saas-etl:user">
      <Layout header={<HubHeader />}>
        <ErrorBoundary
          onError={(err) => {
            datadogLogs.logger.error(
              `Error caught by react error boundary (${err.message})`,
              {
                message: err.message,
                stack: err.stack,
                name: err.name
              }
            )
          }}
          fallback={<ErrorBoundaryFallback />}
        >
          <Routes>
            <Route path="/logout" element={<Logout />} />

            {process.env.REACT_APP_USE_MOCKS === 'true' &&
              !('Cypress' in window) && (
                <Route path="/" element={<DevRoutes />} />
              )}

            <Route path="project/:projectId" element={<ProjectGuard />}>
              <Route path="branch/:branchId" element={<EtlDesigner />}>
                <Route index element={<GettingStarted />} />

                <Route path="task/:taskId" element={<PipelineRunExplorer />} />

                <Route path="job/:jobSummaryId" element={<FileEditor />}>
                  <Route path="component/:componentId" element={null} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<ProjectGuard />} />
          </Routes>

          {process.env.REACT_APP_REACT_QUERY_DEVTOOL === 'true' && (
            <section aria-label="React Query Devtools">
              <ReactQueryDevtools />
            </section>
          )}
        </ErrorBoundary>
      </Layout>
    </RoleGuard>
  )
}

export default App
