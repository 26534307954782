import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  SearchBar,
  type SortOpts
} from '@matillion/component-library'
import { debounce, orderBy } from 'lodash'

import { SEARCH_DEBOUNCE_TIME_MS } from 'modules/ManageVariables/ManageVariables'
import {
  type ProjectVariable,
  type ProjectVariableType,
  type SelectedProjectVariable
} from 'modules/ManageVariables/types'

import classes from '../Manage.module.scss'
import { TruncatedCell } from '../TruncatedCell'
import { ActionsCell } from './ActionsCell'

interface ProjectVariablesListProps {
  variables: ProjectVariable[]
  onDelete: (arg: SelectedProjectVariable) => void
  onEdit: (arg: SelectedProjectVariable) => void
}

interface DataGridRow {
  id: string
  name: string
  type: ProjectVariableType
  description?: string
  dataType: string
  defaultValue: string
}

type SortOrder = 'asc' | 'desc'

export const ProjectVariablesList = ({
  variables,
  onDelete,
  onEdit
}: ProjectVariablesListProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortedColumn, setSortedColumn] = useState<Record<string, SortOpts>>({
    name: 'ASC'
  })

  const columns = [
    {
      key: 'name',
      title: t('manageVariables.jobVariables.fields.name'),
      sortable: true,
      as: TruncatedCell,
      mapValues: (row: DataGridRow) => ({
        text: row.name
      }),
      style: {
        paddingLeft: '8px'
      }
    },
    {
      key: 'dataType',
      title: t('manageVariables.jobVariables.fields.dataType'),
      sortable: true,
      style: {
        maxWidth: '108px'
      }
    },
    {
      key: 'defaultValue',
      title: t('manageVariables.jobVariables.fields.defaultValueColumn'),
      sortable: true,
      as: TruncatedCell,
      mapValues: (row: DataGridRow) => ({
        text: row.defaultValue,
        tooltipPosition: 'left'
      }),
      style: {
        maxWidth: '48px'
      }
    },
    {
      key: 'actions',
      title: t('manageVariables.manage.actions'),
      className: classes.ManageVariables__ActionsColumn,
      as: ActionsCell,
      mapValues: (row: DataGridRow) => ({
        name: row.name,
        onDelete: () => {
          onDelete({ id: row.id, name: row.name, type: row.type })
        },
        onEdit: () => {
          onEdit({ id: row.id, name: row.name, type: row.type })
        }
      })
    }
  ]

  const rows = useMemo(() => {
    const allRows: DataGridRow[] = variables.map((variable) => {
      const { name, description, value, type, id } = variable

      return {
        id,
        name,
        type,
        description,
        dataType: t(`manageVariables.types.${type}`),
        defaultValue: value ? value.toString() : ''
      }
    })

    return allRows.filter((row) => {
      return Object.values(row).some((content) => {
        return content?.toLowerCase().includes(searchTerm.toLowerCase())
      })
    })
  }, [variables, searchTerm, t])

  const sortedRows = useMemo(() => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    const column = selectedColumn as keyof DataGridRow
    const order = sortOrder.toLowerCase() as SortOrder

    return orderBy(rows, column, order)
  }, [rows, sortedColumn])

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearchTerm(e.target.value)
    }, SEARCH_DEBOUNCE_TIME_MS)
  }, [])

  return (
    <>
      <p className="u-visually-hidden" id="project-variables-table-caption">
        {t('manageVariables.manage.projectVariables')}
      </p>
      <div className={classes.ManageVariables__Search}>
        <SearchBar
          data-testid="search-for-project-variable-input"
          placeholder={t('common.search')}
          onChange={debouncedSearch}
        />
      </div>
      <DataGrid
        data-testid="project-variables-list"
        hasFixedHeader
        className={classes.ManageVariables__List}
        rowClassName={classes.ManageVariables__Row}
        columns={columns}
        rows={sortedRows}
        aria-labelledby="project-variables-table-caption"
        defaultSort={{ name: 'ASC' }}
        onSort={setSortedColumn}
        compact
      />
    </>
  )
}
