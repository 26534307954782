import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const TextractConfig: ComponentLayout = {
  componentId: 'modular-textract-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'configure',
        nextStepId: null,
        parameters: [
          {
            dplId: 'textract-input-v1',
            omitParameters: ['connection']
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null)
    ]
  }
}
