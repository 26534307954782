import {
  EditorType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

const ADVANCED_FILTERING_EDITORS = [EditorType.GRID_EDITOR]

const COMBINE_FILTERS_IDS = ['combineFilters']

const TABLE_SELECTION_EDITORS = [
  EditorType.TABLE_SELECTION,
  EditorType.SHEET_SELECTION
]

const COLUMN_CONFIG_EDITOR = [
  EditorType.COLUMN_CONFIGURATION,
  EditorType.SPREADSHEET_CONFIG
]

export interface GetParameterBag {
  parameter?: ComponentParameter
  slot?: number
}

export const getParameterByEditorType = (
  permittedEditors: EditorType[],
  childProperties: ComponentParameter[]
): GetParameterBag => {
  const idx = childProperties.findIndex((child) =>
    permittedEditors.includes(child.editorType)
  )

  return idx >= 0
    ? {
        parameter: childProperties?.[idx],
        slot: idx + 1
      }
    : {}
}

export const getParameterById = (
  permittedEditors: string[],
  childProperties: ComponentParameter[]
): GetParameterBag => {
  const idx = childProperties.findIndex((child) => {
    const lastDelimiter = child.dplID.lastIndexOf('/')
    const subParameterName = child.dplID.substring(lastDelimiter + 1)
    return permittedEditors.includes(subParameterName)
  })

  return idx >= 0
    ? {
        parameter: childProperties?.[idx],
        slot: idx + 1
      }
    : {}
}

export const getAdvancedFilteringParameter = (
  childProperties: ComponentParameter[]
): GetParameterBag =>
  getParameterByEditorType(ADVANCED_FILTERING_EDITORS, childProperties)

export const getTableSelectionParameter = (
  childProperties: ComponentParameter[]
): GetParameterBag =>
  getParameterByEditorType(TABLE_SELECTION_EDITORS, childProperties)

export const getConfigureColumnsParameter = (
  childProperties: ComponentParameter[]
): GetParameterBag =>
  getParameterByEditorType(COLUMN_CONFIG_EDITOR, childProperties)

export const getCombineFiltersParameter = (
  childProperties: ComponentParameter[]
): GetParameterBag => getParameterById(COMBINE_FILTERS_IDS, childProperties)
