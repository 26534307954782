import { ErrorStatuses } from 'modules/ManageVariables/types'

export const manageVariableNameErrors = {
  [ErrorStatuses.RESERVED_WORD]:
    'manageVariables.jobVariables.fields.errors.restrictedField',
  [ErrorStatuses.DUPLICATE]:
    'manageVariables.jobVariables.fields.errors.duplicate',
  [ErrorStatuses.EMPTY]:
    'manageVariables.jobVariables.fields.errors.emptyField',
  [ErrorStatuses.SELECT_OPTION]:
    'manageVariables.jobVariables.fields.errors.pleaseSelectAnOption',
  [ErrorStatuses.INCORRECT_NAME_FORMAT]:
    'manageVariables.jobVariables.fields.errors.variableNameFormat',
  [ErrorStatuses.INCORRECT_NUMBER_FORMAT]:
    'manageVariables.jobVariables.fields.errors.numberFormat',
  [ErrorStatuses.SINGLE_$]:
    'manageVariables.jobVariables.fields.errors.single$',
  [ErrorStatuses.TOO_LONG]:
    'manageVariables.jobVariables.fields.errors.tooLong',
  [ErrorStatuses.NO_ERROR]: ''
}
