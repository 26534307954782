import { Button, Typography } from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import classes from './UpgradeModal.module.scss'

export interface ModalBodyParagraph {
  key: number
  content: string
}
export interface UpgradeModalProps {
  onClose: () => void
  title: string
  subtitle: string
  bodyContents: ModalBodyParagraph[]
  image: React.ReactNode
}
const UpgradeModal = ({
  onClose,
  title,
  subtitle,
  bodyContents,
  image
}: UpgradeModalProps) => {
  return (
    <DesignerModal
      size="large"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick={false}
      data-testid="base-upgrade-modal"
      ariaLabelledBy="upgrade-modal-title"
      className={classes.Modal}
    >
      <div className={classes.Modal__Container}>
        <div className={classes.Modal__Cta}>
          <div className={classes.Modal__Cta__Text}>
            <Typography
              as={'h2'}
              id={'upgrade-modal-title'}
              format="tl"
              className={classes.Modal__Cta__Text__Title}
            >
              {title}
            </Typography>
            <Typography
              as={'h3'}
              format="tm"
              className={classes.Modal__Cta__Text__Subtitle}
            >
              {subtitle}
            </Typography>
            {bodyContents.map((paragraph) => (
              <Typography key={paragraph.key}>{paragraph.content}</Typography>
            ))}
          </div>
          <div className={classes.Modal__FooterButtons}>
            <a
              href="https://app.matillion.com/billing/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className={classes.Modal__FooterButtons__TalkToSales}
                size="lg"
              >
                <Typography format="bcm" weight={'bold'}>
                  Talk to sales
                </Typography>
              </Button>
            </a>
          </div>
        </div>
        <div className={classes.Modal__Image}>{image}</div>
      </div>
    </DesignerModal>
  )
}

export default UpgradeModal
