import { type JobState } from 'job-lib/store/jobSlice/job.types'
import { JobType } from 'job-lib/types/JobType'

import { type ComponentQueryKeys } from '../types'
import { useGenerateOrchestrationQueries } from './useGenerateOrchestrationQueries'
import { useGenerateTransformationQueries } from './useGenerateTransformationQueries'

export const useGetValidationQueries = ({
  jobState,
  lastValidated,
  componentQueryKeys
}: {
  jobState: JobState
  lastValidated: number
  componentQueryKeys: ComponentQueryKeys | null
}) => {
  const { jobType } = jobState
  const transformationQueries = useGenerateTransformationQueries({
    jobState,
    lastValidated,
    componentQueryKeys
  })

  const orchestrationQueries = useGenerateOrchestrationQueries({
    jobState,
    lastValidated,
    componentQueryKeys
  })

  if (!componentQueryKeys) return null

  if (jobType === JobType.Orchestration) {
    return orchestrationQueries
  }

  if (jobType === JobType.Transformation) {
    return transformationQueries
  }

  return null
}
