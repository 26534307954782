import omit from 'lodash/omit'

import { type TransformationComponentInstance } from 'job-lib/types/Job'

import { createNameParameter } from './createNameParameter'
import { createParameters } from './createParameters'
import { type CreateComponentProps } from './types'

export const createEmptyTransformationComponent = (
  name: string
): Omit<TransformationComponentInstance, 'id' | 'implementationID'> => ({
  x: 0,
  y: 0,
  width: 32,
  height: 32,
  parameters: {
    1: createNameParameter(name)
  },
  validationStatus: {},
  compStatus: {},
  inputConnectorIDs: [],
  outputConnectorIDs: [],
  exportMappings: {},
  expectedFailure: null
})

export const createTransformationComponent = ({
  t,
  initialValues,
  metadata,
  name,
  x,
  y
}: CreateComponentProps): Omit<TransformationComponentInstance, 'id'> => ({
  ...createEmptyTransformationComponent(name),
  implementationID: parseInt(metadata.emeraldId, 10),
  x,
  y,
  parameters: {
    ...createEmptyTransformationComponent(name).parameters,
    ...omit(createParameters(t, metadata, initialValues), '1')
  }
})
