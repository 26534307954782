import type { DeploymentEnvironment } from '@matillion/git-component-library'
import { type AuthProviderEnvironment } from '@matillion/hub-client'

import { type ProjectType } from '../api/hooks/useGetProject/types'
import componentsEnabledWarehouses from './components-enabled-warehouses.json'

export enum Environment {
  local = 'local',
  localProxy = 'local-proxy',
  test = 'test',
  dev = 'dev',
  preprod = 'preprod',
  prod = 'prod',
  // Office of the CTO
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  cto = 'dev'
}

export type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  WEBSOCKET_ENDPOINT: string
  DATADOG?: Record<string, string>
  API_BASE_URL: string
  EOS_API_BASE_URL: string
  COPILOT_API_BASE_URL: string
  WORKING_TREE_API_BASE_URL: string
  PROJECT_EXPLORER_API_BASE_URL: string
  PERMISSIONS_API_BASE_URL: string
  TEMP_AGENT_ID: string
  TEMP_ENVIRONMENT_ID: string
  PROPERTIES_ALERT_TIMEOUT: number
  AGENT_REFETCH_INTERVAL_MS: number
  PROJECT_EXPLORER_DOMAIN: string
  LAUNCHDARKLY: {
    CLIENT_ID?: string
  }
  CREATE_CONNECTOR: {
    BASE_URL: string
    EDIT_CONNECTOR_PATH: string
  }
} & Record<string, string | undefined>

/**
 * makeReplacer is a function factory for replacing the region placeholder in the api URLs
 * @param url the url to replace the region placeholder in
 */
const makeReplacer =
  (url: string) =>
  (region: string = defaultRegion) =>
    url.replace('{{region}}', region)

export const getEnvFromUrl = (raw: string) => {
  const hostname = new URL(raw).hostname
    .replace('.eu1.', '.')
    .replace('.us1.', '.')
  const currentEnv = hostname
    .split('.')
    ?.reverse()?.[2]
    ?.split('-')
    ?.reverse()?.[0]

  const currentEnvKey = Environment[currentEnv as keyof typeof Environment]
  return currentEnvKey ?? Environment.prod
}

const getEnvConfig = (env: Environment): EnvironmentConfig => {
  if (env === Environment.localProxy) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const localEnvConfig = require(`./${Environment.local}.json`)
    localEnvConfig.API_BASE_URL = '/etl-designer-spring/api'
    return localEnvConfig
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require(`./${environment}.json`)
}

const environment =
  (process.env.REACT_APP_ENVIRONMENT as Environment) ??
  getEnvFromUrl(window.location.href)

const envConfig = getEnvConfig(environment)
const defaultRegion = 'eu1'

const config = {
  environment,
  version: process.env.REACT_APP_VERSION ?? '0.0.0-local',
  componentsEnabledWarehouses: new Map<string, ProjectType[]>(
    Object.entries(componentsEnabledWarehouses) as [[string, ProjectType[]]]
  ),
  sourceApp: 'etl_designer',
  redirectOnMissingParamsURL:
    envConfig.PROJECT_EXPLORER_DOMAIN || 'project-explorer-dev.matillion.com',
  webSocketEndpoint: envConfig.WEBSOCKET_ENDPOINT,
  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  apiTokenAudience: envConfig.API_TOKEN_AUDIENCE,
  getApiBaseUrl: makeReplacer(envConfig.API_BASE_URL),
  permissionsApiBaseUrl: envConfig.PERMISSIONS_API_BASE_URL,
  getEosApiBaseUrl: makeReplacer(envConfig.EOS_API_BASE_URL),
  getCopilotApiBaseUrl: makeReplacer(envConfig.COPILOT_API_BASE_URL),
  getProjectExplorerApiBaseUrl: makeReplacer(
    envConfig.PROJECT_EXPLORER_API_BASE_URL
  ),
  getWorkingTreeStoreApiBaseUrl: makeReplacer(
    envConfig.WORKING_TREE_API_BASE_URL
  ),
  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: envConfig.DATADOG.CLIENT_TOKEN,
    service: envConfig.DATADOG.SERVICE,
    rumService: envConfig.DATADOG.RUM_SERVICE,
    applicationId: envConfig.DATADOG.APPLICATION_ID
  },

  propertiesAlertTimeout: envConfig.PROPERTIES_ALERT_TIMEOUT,
  agentRefetchIntervalMs: envConfig.AGENT_REFETCH_INTERVAL_MS,
  projectExplorerDomain: envConfig.PROJECT_EXPLORER_DOMAIN,

  launchDarkly: {
    clientId: envConfig.LAUNCHDARKLY.CLIENT_ID
  },
  browserStoreKeys: {
    userPreference: 'designer.userPreference'
  },
  docs: {
    designerOverviewUrl:
      'https://docs.matillion.com/data-productivity-cloud/designer/docs/designer-overview/',
    orchestrationVideoUrl: 'https://fast.wistia.net/embed/iframe/hma4na1cl9',
    transformationVideoUrl: 'https://fast.wistia.net/embed/iframe/8qibe9apu8'
  },
  editConnectorUrlTemplate:
    envConfig.CREATE_CONNECTOR.BASE_URL +
    envConfig.CREATE_CONNECTOR.EDIT_CONNECTOR_PATH
}

/**
 * The git-component-library behaves unexpectedly when the environment is set to local-proxy,
 * so we map it to behave as it does with 'dev' set as the environment
 * @param env the environment we are running designer frontend in
 */
export const getMappedGitEnvironment = (env: Environment) => {
  if (env === Environment.localProxy) {
    return Environment.dev as DeploymentEnvironment
  }
  return env as DeploymentEnvironment
}

export default config
