import { get } from 'lodash'

import {
  componentUiConfigs,
  type ComponentUiConfig,
  type ComponentUiConfigKey
} from 'config/componentUiConfigs'

import { allowCypressMocking } from './allowCypressMocking'

allowCypressMocking('componentUiConfigs', componentUiConfigs)

const getComponentUiConfig = (componentId: string): ComponentUiConfig => {
  return componentUiConfigs[componentId] || {}
}

export const getComponentUiConfigValue = (
  componentId: string,
  key: ComponentUiConfigKey
) => {
  return get(getComponentUiConfig(componentId), key)
}
