import React from 'react'
import { useTranslation } from 'react-i18next'

import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'

import { useCheckboxColumn } from 'components/MatillionTable/Columns/Checkbox'
import { useDragHandleColumn } from 'components/MatillionTable/Columns/DragHandle'

import { useGridEditorContext } from '../../GridEditorContext'
import { type GridRow } from '../Grid/types'
import { translateColumnName } from '../Grid/utils'
import {
  DynamicCell,
  DynamicHeader,
  type DynamicMeta
} from './Columns/DynamicColumn'

interface UseTableEditorColumnsProps {
  setRows: React.Dispatch<React.SetStateAction<GridRow[]>>
}

type Columns = Array<ColumnDef<GridRow, unknown>>

export const useTableEditorColumns = ({
  setRows
}: UseTableEditorColumnsProps): Columns => {
  const context = useGridEditorContext()
  const columnHelper = createColumnHelper<GridRow>()
  const { t } = useTranslation()
  const checkboxColumn = useCheckboxColumn(columnHelper)
  const dragHandleColumn = useDragHandleColumn(columnHelper)
  const columns = React.useMemo(() => {
    const dynamicColumns: Columns = context.editorColumns.map(
      (column, index) => {
        const stringIndex = index.toString()
        const columnName = translateColumnName(column, t)
        const columnSlot = index + 1
        const meta: DynamicMeta = {
          column,
          columnSlot,
          context,
          setRows,
          columnName
        }

        return {
          accessorFn: (originalRow: GridRow) => originalRow.cells[index + 1],
          id: stringIndex,
          header: DynamicHeader,
          cell: DynamicCell,
          meta
        }
      }
    )
    return [dragHandleColumn, checkboxColumn, ...dynamicColumns]
  }, [t, dragHandleColumn, checkboxColumn, context, setRows])

  return columns
}
