import { type Step } from 'api/hooks/useEosApi/types/eos'

export interface PipelineTree extends Step {
  depth: string
  loader?: DelayedLoader
  pipelineName: string
  isInFilter?: boolean
}

export interface PipelineTreeController extends PipelineTree {
  isInFilter?: boolean
  expandItems?: (parentDepth: string) => void
  collapseItem?: (parentDepth: string) => void
}

export interface DelayedLoader {
  pipelineId: string
  injectDynamicItems?: (parent: PipelineTreeController, items: Step[]) => void
}

export enum SelectedTaskTab {
  TASKS = 'Tasks',
  LOGGING = 'Logging'
}
