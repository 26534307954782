import React, { type Dispatch, type FunctionComponent } from 'react'

import { type ModalProps } from '@matillion/component-library'
import { heap } from '@matillion/hub-client'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { Loading } from 'components/Loading/Loading'

import { type EditorType } from '../../api/hooks/useGetComponentMetadata/types'
import { ErrorContextWrapper } from './components/ErrorContextWrapper'
import classes from './ParameterOverlay.module.scss'

export interface ParameterOverlayProps extends Pick<ModalProps, 'size'> {
  isLoading: boolean
  setShowEditor: Dispatch<boolean>
  componentName: string
  editorType: EditorType
  parameterName: string
}

export const ParameterOverlay: FunctionComponent<
  React.PropsWithChildren<ParameterOverlayProps>
> = ({
  size = 'default',
  setShowEditor,
  isLoading,
  children,
  componentName,
  editorType,
  parameterName
}) => {
  const onCancel = () => {
    heap.track('Parameter Editor Modal Closed', {
      componentName,
      editorType,
      parameterName
    })
    setShowEditor(false)
  }

  return (
    <DesignerModal
      id="parameter-overlay"
      className={classes.ParameterOverlay}
      size={size}
      onCancel={onCancel}
      ariaLabelledBy="parameter-overlay-title"
      disableBackdropClick={true}
      data-tracker-id="parameter-overlay-modal"
    >
      <ErrorContextWrapper>
        {isLoading && (
          <div className={classes.MultiOptionSelector__Loading}>
            <Loading />
          </div>
        )}
        {!isLoading && children}
      </ErrorContextWrapper>
    </DesignerModal>
  )
}
