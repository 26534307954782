import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { useMutation } from '@tanstack/react-query'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useWorkingTreeStoreClient } from '../useWorkingTreeStoreClient/useWorkingTreeStoreClient'
import { exportPipelinesApi } from './exportPipelinesApi'

const handleDownloadFromBlob = (responseBlob: Blob, fileName: string) => {
  /* istanbul ignore next */
  if (window?.URL?.createObjectURL) {
    const url = window.URL.createObjectURL(responseBlob)
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = fileName
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
}

export const useExportPipelines = (type: 'pipeline' | 'folder') => {
  const { t } = useTranslation()
  const { projectId, branchId } = useProjectInfo()
  const client = useWorkingTreeStoreClient()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { enableHighCode } = useFlags()

  const translationKeySuffix = enableHighCode ? 'file' : 'pipeline'

  const toastMessages = {
    folder: {
      success: t('export-folder.success'),
      error: t('export-folder.error')
    },
    pipeline: {
      success: t(
        `translation:jobContextMenu.exportPipeline.success.${translationKeySuffix}`
      ),
      error: t('translation:jobContextMenu.exportPipeline.error')
    }
  }

  return useMutation(
    async ({
      paths,
      fileName,
      includeTypes
    }: {
      paths: string[]
      fileName: string
      includeTypes?: string[]
    }) => {
      makeToast({
        type: 'info',
        title: t('export-folder.export-in-progress'),
        message: fileName
      })

      const types = enableHighCode ? [] : undefined

      const response = await exportPipelinesApi({
        client,
        projectId,
        branchName: branchId,
        paths,
        fileName,
        includeTypes: includeTypes ?? types
      })

      clearToasts()

      try {
        handleDownloadFromBlob(response.data, fileName)

        makeToast({
          type: 'success',
          title: toastMessages[type].success,
          message: ''
        })
      } catch (e) {
        makeToast({
          type: 'error',
          title: toastMessages[type].error,
          message: (e as Error).message
        })
      }
    },
    {
      onError() {
        makeToast({
          type: 'error',
          title: toastMessages[type].error,
          message: ''
        })
      }
    }
  )
}
