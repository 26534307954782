import type {
  ComponentParameter,
  LookUpType
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { StorageEditorType } from 'modules/ParameterEditors/types'

import {
  CloudStorageExplorer,
  type CloudStorageExplorerProps
} from './components/CloudStorageExplorer/CloudStorageExplorer'

export interface UrlEditorProps {
  storageEditorType: StorageEditorType
  editorColumns: EditorColumn[]
  parameterName: string
  parameter: ComponentParameter
  value: string[]
  lookupType?: LookUpType | null
  fileType?: string | null
  onDone: (editedValue: string[]) => void
}

const config: Record<
  StorageEditorType,
  Pick<CloudStorageExplorerProps, 'name' | 'translationKey' | 'uriSchema'>
> = {
  [StorageEditorType.S3]: {
    name: 's3',
    translationKey: 'parameterEditor.S3_URL',
    uriSchema: 's3://'
  },
  [StorageEditorType.AZURE]: {
    name: 'azure',
    translationKey: 'parameterEditor.AZURE_URL',
    uriSchema: 'azure://'
  },
  [StorageEditorType.GCS]: {
    name: 'gcs',
    translationKey: 'parameterEditor.GCS_URL',
    uriSchema: 'gs://'
  }
}

export const UrlEditor = ({
  storageEditorType,
  parameterName,
  parameter,
  value,
  lookupType,
  fileType,
  onDone
}: UrlEditorProps) => {
  const defaultLookupType = lookupType ?? parameter.lookupType
  return (
    <CloudStorageExplorer
      {...config[storageEditorType]}
      storageEditorType={storageEditorType}
      parameterName={parameterName}
      value={value}
      lookupType={defaultLookupType}
      fileType={fileType}
      onDone={onDone}
    />
  )
}
