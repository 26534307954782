import {
  type ComponentInstance,
  type ComponentInstanceCollection,
  type OrchestrationComponentInstanceCollection,
  type TransformationComponentInstanceCollection
} from 'job-lib/types/Job'

import { getComponentName } from './getComponentName'

export const generateUniqueName = (
  name: string,
  components:
    | OrchestrationComponentInstanceCollection
    | TransformationComponentInstanceCollection
    | ComponentInstanceCollection
): string => {
  const allNames: Record<string, boolean> = {}

  Object.entries(components).forEach(
    ([id, component]: [string, ComponentInstance]) => {
      allNames[getComponentName(component)] = true
    }
  )

  if (!allNames[name]) {
    return name
  }

  let count = 2

  while (allNames[`${name} ${count}`]) {
    count++
  }

  return `${name} ${count}`
}
