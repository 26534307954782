import { type Dispatch } from 'react'

import { type FileType } from '@matillion/git-component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { type FileTreeAction } from '../reducers/fileTreeReducer/types'

export enum FileBrowserCommand {
  RESET,
  DELETE_FILE,
  CREATE_PIPELINE,
  CREATE_FOLDER,
  RENAME_FILE,
  SHARE_PIPELINE,
  UNSHARE_PIPELINE,
  IMPORT_METL_PIPELINES
}

export interface FileBrowserCommandContextBag {
  onCommand: Dispatch<FileBrowserCommandAction>
  onFileTreeCommand: Dispatch<FileTreeAction>
}

export interface FileBrowserCommandState {
  fileSummary: JobSummary | null
  fileType: FileType | null
  isDeletingFile: boolean
  isCreatingFile: boolean
  isRenamingFile: boolean
  isSharingPipeline: boolean
  isUnsharingPipeline: boolean
  metlExportFile: File | null
  isImportingFromMetl: boolean
}

export interface FileBrowserCommandAction {
  type: FileBrowserCommand
  payload: JobSummary | JobSummary[] | FileType | null | File
}
