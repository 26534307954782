import { type FC } from 'react'

import {
  EditorType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { ColumnConfigEditor } from '../../editors/ColumnConfigEditor/ColumnConfigEditor'
import {
  type ConfigData,
  type DataSelectionConfig,
  type LoadStrategy,
  type TableDef,
  type UpdateTableConfigHandler
} from '../../types'
import {
  getConfigureColumnsParameter,
  getTableSelectionParameter
} from '../../utils/getEditorParameter'

interface ConfigureColumnsProps {
  childProperties: ComponentParameter[]
  activeTable: TableDef
  activeTableConfig: DataSelectionConfig
  internalData: ConfigData
  loadStrategy?: LoadStrategy
  fetchCompletion: number
  onSelectAllColumnsChange: (
    isAllSelectedChecked: boolean,
    selectedColumnsList: string[]
  ) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onBack: () => void
  onOpenAdvancedFiltering: () => void
  onApplyToAll: (saveData: ConfigData) => void
}

export const ConfigureColumns: FC<ConfigureColumnsProps> = ({
  childProperties,
  activeTable,
  activeTableConfig,
  internalData,
  loadStrategy,
  fetchCompletion,
  onSelectAllColumnsChange,
  onUpdateTableConfig,
  onBack,
  onOpenAdvancedFiltering,
  onApplyToAll
}) => {
  const { parameter } = getConfigureColumnsParameter(childProperties)

  const { parameter: tableSelectionParameter } =
    getTableSelectionParameter(childProperties)

  return (
    <>
      {tableSelectionParameter &&
        parameter?.editorType === EditorType.COLUMN_CONFIGURATION && (
          <ColumnConfigEditor
            activeTable={activeTable}
            activeTableConfig={activeTableConfig}
            internalData={internalData}
            loadStrategy={loadStrategy}
            fetchCompletion={fetchCompletion}
            onSelectAllColumnsChange={onSelectAllColumnsChange}
            onUpdateTableConfig={onUpdateTableConfig}
            onBack={onBack}
            onOpenAdvancedFiltering={onOpenAdvancedFiltering}
            onApplyToAll={onApplyToAll}
          />
        )}
    </>
  )
}
