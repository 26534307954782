import { type RootState } from 'job-lib/store'
import type {
  OrchestrationComponentInstanceCollection,
  TransformationComponentInstanceCollection
} from 'job-lib/types/Job'

export const getComponentsSelector = (
  state: RootState
):
  | OrchestrationComponentInstanceCollection
  | TransformationComponentInstanceCollection
  | null => {
  if (!state.job.job?.components) return null

  return state.job.job.components
}
