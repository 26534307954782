import { type ElementCollection } from 'job-lib/types/Parameters'

export const createGridElementCollection = (
  gridVarName: string,
  gridVarColumns: string[]
): ElementCollection => {
  const newElements: ElementCollection = {}

  newElements[1] = {
    slot: 1,
    values: {
      1: {
        slot: 1,
        value: gridVarName,
        type: 'GRID'
      }
    }
  }
  newElements[2] = {
    slot: 2,
    values: {}
  }

  gridVarColumns.forEach((column, index) => {
    const paramSlot = index + 1
    newElements[2].values[paramSlot] = {
      slot: paramSlot,
      value: column,
      type: 'GRID'
    }
  })

  return newElements
}
