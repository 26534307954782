import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { ReactComponent as OrchestrationSvg } from 'assets/OrchestrationJob.svg'
import { ReactComponent as TransformationSvg } from 'assets/TransformationJob.svg'

import { FileBrowserCommand } from '../../context/types'
import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import classes from '../../FileBrowser.module.scss'

export interface EmptyFileBrowserListCTAProps {
  onClick?: () => void
  type: FileType
}

const EmptyJobBrowserListCTA: FC<EmptyFileBrowserListCTAProps> = ({
  onClick,
  type
}) => {
  const { t } = useTranslation()

  const buttonText = t('sideBar.jobPanel.createNewJob', {
    context: type
  })

  return (
    <Button
      alt="text"
      aria-label={buttonText}
      type="button"
      className={classes.EmptyPipelineBrowserList__Button}
      onClick={onClick}
    >
      <div className={classes.EmptyPipelineBrowserList__Button__Content}>
        {type === FileType.ORCHESTRATION_PIPELINE ? (
          <OrchestrationSvg />
        ) : (
          <TransformationSvg />
        )}
        <Typography
          className={classes.EmptyPipelineBrowserList__Button__Text}
          as={'span'}
        >
          {buttonText}
        </Typography>
      </div>
    </Button>
  )
}

export interface Props {
  jobs: JobSummary[]
}

export const EmptyFileBrowserList: FC = () => {
  const { t } = useTranslation()
  const { onCommand } = usePipelineBrowserCommandProvider()
  const { hasPermission, isLoading } = useProjectPermission(
    'create_pipelines',
    PermissionType.PROJECT
  )

  const hasPermissionNotLoading = hasPermission && !isLoading

  return (
    <div className={classes.EmptyPipelineBrowserList}>
      <Icon.File />
      <Typography className={classes.EmptyPipelineBrowserList__Text}>
        {t(
          hasPermissionNotLoading
            ? 'sideBar.jobPanel.emptyJobList'
            : 'sideBar.jobPanel.emptyJobListUnauthorised'
        )}
      </Typography>

      {hasPermissionNotLoading && (
        <div className={classes.EmptyPipelineBrowserList__CTAContainer}>
          <EmptyJobBrowserListCTA
            type={FileType.ORCHESTRATION_PIPELINE}
            onClick={() => {
              onCommand({
                type: FileBrowserCommand.CREATE_PIPELINE,
                payload: FileType.ORCHESTRATION_PIPELINE
              })
            }}
          />
          <EmptyJobBrowserListCTA
            type={FileType.TRANSFORMATION_PIPELINE}
            onClick={() => {
              onCommand({
                type: FileBrowserCommand.CREATE_PIPELINE,
                payload: FileType.TRANSFORMATION_PIPELINE
              })
            }}
          />
        </div>
      )}
    </div>
  )
}
