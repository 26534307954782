import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import {
  ComponentEditorTabType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'

import { IconButton } from 'components/IconButton/IconButton'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import type { ComponentInstance } from 'job-lib/types/Job'

import { getDocumentationLink } from 'modules/ComponentProperties/getDocumentationLink'
import { useComponentValidationResult } from 'modules/core/ComponentValidation'
import { TaskbarTabKeys } from 'modules/core/EtlDesigner/Layouts'
import { useLayoutProvider } from 'modules/core/EtlDesigner/Layouts/LayoutProvider'

import classes from '../../ComponentToolbar.module.scss'

const ComponentToolbarButtons: FC<{
  componentSummaryId: string
  componentMetadata: ComponentMetadata
  componentInstance: ComponentInstance
  className?: string
  sampleButton?: React.ReactElement
  helpButton?: React.ReactElement
  buttonClickCallback?: () => void
}> = ({
  componentMetadata,
  componentSummaryId,
  componentInstance,
  className,
  sampleButton,
  helpButton,
  buttonClickCallback = () => {}
}) => {
  const { t } = useTranslation()
  const { getDisplayName } = useComponentInfo()
  const displayName = getDisplayName(componentSummaryId)
  const { isSuccess: isValidationSuccess } = useComponentValidationResult(
    componentInstance.id
  )
  const {
    setActiveTabKey,
    setIsSampleFetchRequested,
    setIsSampleRowCountFetchRequested
  } = useLayoutProvider()

  const { hasPermission: hasSamplePermission } = useProjectPermission(
    'sampling_pipelines',
    PermissionType.ENVIRONMENT
  )

  const showSampleButton =
    hasSamplePermission &&
    componentMetadata.editorTabs.includes(ComponentEditorTabType.SAMPLE)

  const { track } = useLDClient()

  return (
    <div {...{ className }}>
      {showSampleButton && (
        <IconButton
          className={classes.ComponentToolbar__Row__Buttons__Button}
          data-testid="component-toolbar-sample"
          data-tracker-id="component-toolbar-sample"
          onClick={() => {
            track('sample-data-in-config-panel')
            setActiveTabKey(TaskbarTabKeys.SAMPLE_DATA)
            setIsSampleFetchRequested(true)
            setIsSampleRowCountFetchRequested(true)
            buttonClickCallback()
          }}
          label={t('componentProperties.toolbar.actions.sampleDataButton')}
          disabled={!isValidationSuccess}
        >
          <Icon.Sample className={classes.ComponentToolbar__SampleIcon} />
          {sampleButton}
        </IconButton>
      )}

      <a
        href={getDocumentationLink(componentSummaryId)}
        rel="noopener noreferrer"
        target="_blank"
        data-testid="component-toolbar-help"
        onClick={() => {
          buttonClickCallback()
        }}
      >
        <IconButton
          className={classes.ComponentToolbar__Row__Buttons__Button}
          label={t('componentProperties.toolbar.actions.componentHelpButton', {
            componentName: displayName
          })}
          align="end"
        >
          <Icon.Help />
          {helpButton}
        </IconButton>
      </a>
    </div>
  )
}

export default ComponentToolbarButtons
