import { useMemo } from 'react'

import { useListEnvironments } from 'api/hooks/useListEnvironments'
import { type ListEnvironmentSummary } from 'api/hooks/useListEnvironments/types'
import { useListProjects } from 'api/hooks/useListProjects'
import { type ProjectSummary } from 'api/hooks/useListProjects/types'

import { useProjectInfo } from './useProjectInfo'

export interface ProjectInfoNames {
  projectName: string
  environmentName: string
  warehouse: string
  isError: boolean
}

export const safeNameFromList = (
  list: ListEnvironmentSummary[],
  id: string
): string => {
  const item = list.find((prj) => prj.id === id)
  return item?.name || ''
}

export const safeWarehouseFromList = (
  list: ProjectSummary[],
  id: string
): string => {
  const item = list.find((prj) => prj.id === id)
  return item?.warehouse.toLowerCase() ?? ''
}

export const useProjectNames: () => ProjectInfoNames = () => {
  const { environmentId, projectId } = useProjectInfo()
  const { data: projectList, isError: isProjectError } = useListProjects()
  const { data: environmentList, isError: isEnvError } = useListEnvironments()

  const { projectName, environmentName, warehouse, isError } =
    useMemo<ProjectInfoNames>(() => {
      if (!projectList || !environmentList) {
        return {
          projectName: '',
          environmentName: '',
          warehouse: '',
          isError: true
        }
      }

      const projectDefault = safeNameFromList(projectList.results, projectId)
      const warehouseDefault = safeWarehouseFromList(
        projectList.results,
        projectId
      )
      const envDefault = safeNameFromList(
        environmentList.results,
        environmentId
      )

      return {
        projectName: projectDefault,
        environmentName: envDefault,
        warehouse: warehouseDefault.toLowerCase(),
        isError: isEnvError || isProjectError
      }
    }, [
      projectList,
      environmentList,
      projectId,
      environmentId,
      isEnvError,
      isProjectError
    ])

  return {
    projectName,
    environmentName,
    warehouse,
    isError
  }
}
