import { useTranslation } from 'react-i18next'

import { Tab, Tabs } from '@matillion/component-library'

import {
  type JobConversionInfo,
  type JobConversionReport,
  type VariableConversionInfo as VariableConversionInfoType,
  type VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import JobConversionDetails from 'modules/migration/ConversionTool/components/JobConversionDetails/JobConversionDetails'
import VariableConversionDetails from 'modules/migration/ConversionTool/components/VariableConversionDetails/VariableConversionDetails'

import classes from './ImportConversionDetails.module.scss'

export interface ImportConversionDetailsProps {
  jobConversionReport: JobConversionReport
  variableConversionReport: VariableConversionReport
  jobs: JobConversionInfo | undefined
  variables: VariableConversionInfoType | undefined
  hasJobsThatCannotBeConverted: boolean
  hasVariablesThatCannotBeConverted: boolean
  openMappings: () => void
}

const ImportConversionDetails = ({
  jobConversionReport,
  variableConversionReport,
  jobs,
  variables,
  hasJobsThatCannotBeConverted,
  hasVariablesThatCannotBeConverted,
  openMappings
}: ImportConversionDetailsProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'conversion.modal' })

  const jobsQuantity =
    (jobs?.converted.length ?? 0) + (jobs?.notConverted.length ?? 0)
  const variablesQuantity =
    (variables?.converted.length ?? 0) + (variables?.notConverted.length ?? 0)

  return (
    <div className={classes.wrapper} data-testid="conversion-tool-details">
      <h2 className="u-visually-hidden" id="conversion-tabs-header">
        {t('tabs.header')}
      </h2>
      <Tabs
        aria-labelledby="conversion-tabs-header"
        contentClassName={classes.contents}
        data-testid="conversion-tool-tabs"
      >
        <Tab
          title={t('tabs.jobs', { quantity: jobsQuantity })}
          key="jobs"
          data-testid="conversion-tool-jobs-tab"
        >
          <JobConversionDetails
            jobConversionReport={jobConversionReport}
            jobs={jobs}
            hasJobsThatCannotBeConverted={hasJobsThatCannotBeConverted}
            openMappings={openMappings}
          />
        </Tab>

        <Tab
          title={t('tabs.variables', { quantity: variablesQuantity })}
          key="variables"
          data-testid="conversion-tool-variables-tab"
        >
          <VariableConversionDetails
            variableConversionReport={variableConversionReport}
            variables={variables}
            hasVariablesThatCannotBeConverted={
              hasVariablesThatCannotBeConverted
            }
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ImportConversionDetails
