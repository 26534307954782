import { cloneDeep } from 'lodash'
import { type ParameterValue } from 'types/Pipeline'

import { isElementCollection } from 'job-lib/job-functions/elements'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { isScalarParameterValue } from 'modules/core/WorkingCopyProvider/utils/parameters'

export const getTableInputName = (
  uniqueName: string,
  editedValue: ElementCollection | ParameterValue
): ElementCollection | ParameterValue | undefined => {
  if (!isElementCollection(editedValue)) {
    return isScalarParameterValue(editedValue) ? uniqueName : undefined
  }
  if (!editedValue[1]?.values[1]?.value) {
    return
  }

  const result = cloneDeep(editedValue)
  result[1].values[1].value = uniqueName
  return result
}
