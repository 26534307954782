import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ComponentEditorTabType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import { ComponentParametersOrSql } from 'modules/ComponentParameters/ComponentParametersOrSql'
import { ComponentPostProcessingContainer } from 'modules/ComponentParameters/components/ComponentPostProcessingContainer/ComponentPostProcessingContainer'

interface ComponentTabContentProps {
  tabType: ComponentEditorTabType
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
  onBlur: (params: EditedParameter) => void
}

export const ComponentTabContent: FC<ComponentTabContentProps> = ({
  tabType,
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'componentProperties.tabs'
  })

  if (tabType === ComponentEditorTabType.PROPERTIES) {
    return (
      <ComponentParametersOrSql
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
        onEdit={onEdit}
        onBlur={onBlur}
      />
    )
  }

  if (tabType === ComponentEditorTabType.POSTPROCESSING) {
    return (
      <ComponentPostProcessingContainer
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
      />
    )
  }

  return <EmptyPanel> {t('notAvailable')} </EmptyPanel>
}
