import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@matillion/component-library'
import { type StructList } from 'types/Pipeline'

import { EditorType } from 'api/hooks/useGetComponentMetadata/types'

import { type DataSelectionConfig } from '../../types'
import classes from './ExpandedRow.module.scss'

function formatDataSourceFilter(dataSourceFilters: StructList) {
  return dataSourceFilters
    .map((filter) => {
      return Object.values(filter).join(' ')
    })
    .join('; ')
}
export interface ExpandedRowTableProps {
  data?: DataSelectionConfig
  editorType: EditorType
}

export const ExpandedRow: FC<ExpandedRowTableProps> = ({
  data,
  editorType
}) => {
  const { t } = useTranslation()

  if (!data) return null

  const primaryKeys = data.primaryKeys.length
    ? data.primaryKeys.join(', ')
    : '-'
  const incrementalColumn = data.incrementalColumn ?? '-'
  const dataSourceFilter = data.dataSourceFilter.length
    ? formatDataSourceFilter(data.dataSourceFilter)
    : '-'
  return (
    <div
      data-testid={`${data.dataSource}-expanded-row`}
      className={classes.ExpandedRow}
    >
      {editorType === EditorType.TABLE_SELECTION && (
        <>
          <div className={classes.ExpandedRow__Source}>
            <Typography format="bcs" weight="bold">
              {t('multiTableConfig.tableSelection.source')}
            </Typography>
            <Typography format="bcs">
              <Tooltip content={data.dataSource}>
                <span data-testid="dataSource">{data.dataSource}</span>
              </Tooltip>
            </Typography>
          </div>
          <div className={classes.ExpandedRow__PrimaryKeys}>
            <Typography format="bcs" weight="bold">
              {t('multiTableConfig.tableSelection.primaryKeys')}
            </Typography>
            <Typography format="bcs">
              <Tooltip content={primaryKeys}>
                <span data-testid="primaryKeys">{primaryKeys}</span>
              </Tooltip>
            </Typography>
          </div>
          <div className={classes.ExpandedRow__IncrementalLoad}>
            <Typography format="bcs" weight="bold">
              {t('multiTableConfig.tableSelection.incrementalLoad')}
            </Typography>
            <Typography format="bcs">
              <Tooltip content={incrementalColumn}>
                <span data-testid="incrementalColumn">{incrementalColumn}</span>
              </Tooltip>
            </Typography>
          </div>
          <div className={classes.ExpandedRow__DataSourceFilters}>
            <Typography format="bcs" weight="bold">
              {t('multiTableConfig.tableSelection.dataSourceFilters')}
            </Typography>
            <Tooltip content={dataSourceFilter} positions={['top', 'left']}>
              <div data-testid="dataSourceFilter">
                <Typography format="bcs">{dataSourceFilter}</Typography>
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}
