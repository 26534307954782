import { type FC } from 'react'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type ComponentInstance } from 'job-lib/types/Job'

import { ComponentParameterItem } from 'modules/ComponentParameters/components/ComponentParameterItem/ComponentParameterItem'

import classes from './ComponentPostProcessingContainer.module.scss'

interface ComponentPostProcessingContainerProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
}

export const ComponentPostProcessingContainer: FC<
  ComponentPostProcessingContainerProps
> = ({ componentInstance, componentMetadata, componentSummaryId }) => {
  return (
    <div
      id="post-processing-parameters"
      data-testid="post-processing-parameters"
      className={classes.ComponentPostProcessingContainer}
    >
      {componentMetadata.postProcessing?.map((parameter) => (
        <ComponentParameterItem
          key={parameter.dplID}
          parameter={parameter}
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          onEdit={() => {}}
          onBlur={() => {}}
          scrollableContainerSelector="#post-processing-parameters"
        />
      ))}
    </div>
  )
}
