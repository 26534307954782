import { useQuery } from '@tanstack/react-query'

import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'
import { useDesignerApi } from 'api/hooks/useDesignerApi/useDesignerApi'

import type { JobType } from 'job-lib/types/JobType'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'

import useGetProject from '../../useGetProject/useGetProject'

export interface AutoDocsRequest {
  pipeline: PipelineContext
  warehouse: string
  selectedNodes: string[]
  verbosity: DocsVerbosity
}

export interface AutoDocsResponse {
  description: string
}

export enum DocsVerbosity {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}
export interface PipelineContext {
  type: JobType | null
  source: string
}

export const useGeneratePipelineDocs = (
  id: number,
  selectedNodes: string[],
  verbosity: DocsVerbosity
) => {
  const { job, jobType } = useFlaggedWorkingCopy()

  const { data: project } = useGetProject()
  const { data: agentDetails } = useAgentDetails()
  const { generatePipelineDocs } = useDesignerApi()

  return useQuery<AutoDocsResponse, Error>(
    ['generatePipelineDocs', id, selectedNodes],
    async () => {
      const warehouse = project?.warehouse?.toUpperCase() ?? ''
      const agentVersion = agentDetails?.agentVersion ?? ''

      const request: AutoDocsRequest = {
        selectedNodes,
        warehouse,
        pipeline: {
          type: jobType,
          source: JSON.stringify(job)
        },
        verbosity
      }

      const response = await generatePipelineDocs(agentVersion, request)

      return response.data
    },
    {
      enabled: false, // Disables automatic fetching of data, to manually fetch data use refetch()
      staleTime: 0 // Query data is immediately stale, so refetch always makes a new API call
    }
  )
}
