/**
 * Allows mocking of a module in Cypress tests when cy.stub() doesn't work because we need to grab the instance.
 * This is achieved by simply setting it on the window object at runtime, where Cypress can grab it.
 */
export const allowCypressMocking = (propertyName: string, module: object) => {
  // @ts-expect-error Cypress might exist
  if (window.Cypress) {
    // @ts-expect-error we are setting this, so we know it doesn't exist
    window[propertyName] = module
  }
}
