import { type QueryKey } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

export const getDefaultQueryKeys = (jobSummaryId: string) => [
  queryKeys.validateComponent,
  'jobId',
  jobSummaryId,
  'components'
]

/**
 * Gets a list of dependent components names by the given query key
 * @param queryKey The validation query key
 * @param jobSummaryId The job summary Id
 * @returns A list of dependent component names. If a component has a single input, this array
 *  will always contain a single array containing one component name. If the component
 *  accepts multiple inputs (e.g. Unite), this will contain an array for each set of dependent components.
 */
export const getDependentComponentNames = (
  queryKey: QueryKey,
  jobSummaryId: string
): string[][] => {
  const defaultQueryKeys = getDefaultQueryKeys(jobSummaryId)

  return queryKey[defaultQueryKeys.length] as string[][]
}
