import { useTranslation } from 'react-i18next'

import { Alert, Button, Typography } from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { ModalTitle } from 'components/ModalTitle/ModalTitle'

import classes from './ScheduleModal.module.scss'

export interface SubmitModalProps {
  onCancel: () => void
  onInitiate: () => void
}

const ScheduleModal = ({ onCancel, onInitiate }: SubmitModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'schedule.modal'
  })

  const alertContent = () => (
    <Typography format="bcs">
      <Typography as="span" weight="bold" format="bcs">
        {t('alert.title')}
      </Typography>{' '}
      {t('alert.description')}
    </Typography>
  )

  return (
    <DesignerModal
      size="mid"
      disableBackdropClick
      onCancel={onCancel}
      ariaLabelledBy="Schedule Modal"
      aria-label="Schedule Modal"
      className={classes.ScheduleModal}
      data-testid="schedule-modal"
    >
      <ModalTitle
        title={t('title')}
        description={t('subtitle')}
        titleId={'schedule-modal-title'}
        className={classes.ScheduleModal__Title}
      />

      <Alert
        type="info"
        theme="dark"
        className={classes.ScheduleModal__Alert}
        message={alertContent()}
        aria-label="Schedule Alert Region"
        data-testid="add-schedule-alert"
      />

      <div className={classes.ScheduleModal__Footer}>
        <Button
          type="reset"
          alt="secondary"
          text={t('cancel')}
          onClick={onCancel}
          className={classes.ScheduleModal__Button}
          data-testid="schedule-button-cancel"
        />

        <Button
          type="submit"
          text={t('initiate')}
          className={classes.ScheduleModal__Button}
          onClick={onInitiate}
          data-testid="schedule-button-action"
        />
      </div>
    </DesignerModal>
  )
}

export default ScheduleModal
