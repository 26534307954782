import { type FC, type HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import {
  type SourceColumn,
  type SourceColumns
} from 'modules/ParameterEditors/components/MultiExpressionsEditor'

import Fields from './Fields/Fields'
import classes from './FieldsAndVariablesPanel.module.scss'

export interface FieldsAndVariablesPanelProps
  extends HTMLProps<HTMLDivElement> {
  sourceColumns: SourceColumns
  onColumnClick: (column: SourceColumn) => unknown
}

const FieldsAndVariablesPanel: FC<FieldsAndVariablesPanelProps> = ({
  sourceColumns,
  onColumnClick,
  className,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(classes.FieldsAndVariablesPanel, className)}
      tabIndex={0}
      {...props}
    >
      <div className={classes.FieldsAndVariablesPanel__Tabs}>
        <div className={classes.FieldsAndVariablesPanel__Tabs__Item}>
          <Typography
            format="bcs"
            className={classNames(
              classes.FieldsAndVariablesPanel__Tabs__Item__Title,
              classes['FieldsAndVariablesPanel__Tabs__Item__Title--active']
            )}
            weight={'bold'}
          >
            {t('fieldsAndVariablesPanel.fields')}
          </Typography>
        </div>
      </div>

      <div className={classes.FieldsAndVariablesPanel__Content}>
        {sourceColumns.length ? (
          <Fields sourceColumns={sourceColumns} onColumnClick={onColumnClick} />
        ) : (
          <EmptyPanel>{t('fieldsAndVariablesPanel.noFieldsError')}</EmptyPanel>
        )}
      </div>
    </div>
  )
}

export default FieldsAndVariablesPanel
