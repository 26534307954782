import React from 'react'
import { useTranslation } from 'react-i18next'

interface ConversionReportSummaryTableProps {
  convertedCount: number
  autoconvertedCount: number
  refactorCount: number
  notConvertedCount: number
  entity: string
}

const summaryTableStyle: React.CSSProperties = {
  width: '100%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const summaryRowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const summaryCellStyle: React.CSSProperties = {
  textAlign: 'center',
  fontSize: '1.6rem',
  padding: 5,
  flexWrap: 'wrap',
  width: '25%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const summaryHeaderCellStyle: React.CSSProperties = {
  ...summaryCellStyle,
  backgroundColor: 'darkgray',
  fontWeight: 'bold',
  fontSize: '1.2rem'
}

const superscriptStyle: React.CSSProperties = {
  fontSize: '0.8rem'
}

const ConversionReportSummaryTable = ({
  convertedCount,
  autoconvertedCount,
  refactorCount,
  notConvertedCount,
  entity
}: ConversionReportSummaryTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <table style={summaryTableStyle}>
      <thead>
        <tr style={summaryRowStyle}>
          <th style={summaryHeaderCellStyle}>{t('total', { entity })}</th>
          <th style={summaryHeaderCellStyle}>{t('converted')}</th>
          <th style={summaryHeaderCellStyle}>
            {t('autoconverted')}
            <sup style={superscriptStyle}>1</sup>
          </th>
          <th style={summaryHeaderCellStyle}>
            {t('manualRefactor')}
            <sup style={superscriptStyle}>2</sup>
          </th>
          <th style={summaryHeaderCellStyle}>
            {t('notConverted')}
            <sup style={superscriptStyle}>3</sup>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={summaryRowStyle}>
          <td style={summaryCellStyle}>
            {convertedCount +
              autoconvertedCount +
              refactorCount +
              notConvertedCount}
          </td>
          <td style={summaryCellStyle}>{convertedCount}</td>
          <td style={summaryCellStyle}>{autoconvertedCount}</td>
          <td style={summaryCellStyle}>{refactorCount}</td>
          <td style={summaryCellStyle}>{notConvertedCount}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default ConversionReportSummaryTable
