import { type FC } from 'react'

import { type SVGIconProps } from '../types'

export const DragHandle: FC<SVGIconProps> = (props) => {
  return (
    <svg {...props} width="6" height="10" viewBox="0 0 6 10" fill="none">
      <mask id="path-1-inside-1" fill="white">
        <ellipse cx="0.999964" cy="1" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="0.999964" cy="1" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M0.999927 1H0.999964V3C2.10455 3 2.99993 2.10454 2.99993 1H0.999927ZM0.999964 1H1H-1C-1 2.10454 -0.10462 3 0.999964 3V1ZM1 1H0.999964V-1C-0.10462 -1 -1 -0.104535 -1 1H1ZM0.999964 1H0.999927H2.99993C2.99993 -0.104535 2.10455 -1 0.999964 -1V1Z"
        fill="#AAAAAA"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-3-inside-2" fill="white">
        <ellipse cx="4.99996" cy="1" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="4.99996" cy="1" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M4.99993 1H4.99996V3C6.10455 3 6.99993 2.10454 6.99993 1H4.99993ZM4.99996 1H5H3C3 2.10454 3.89538 3 4.99996 3V1ZM5 1H4.99996V-1C3.89538 -1 3 -0.104535 3 1H5ZM4.99996 1H4.99993H6.99993C6.99993 -0.104535 6.10455 -1 4.99996 -1V1Z"
        fill="#AAAAAA"
        mask="url(#path-3-inside-2)"
      />
      <mask id="path-5-inside-3" fill="white">
        <ellipse cx="0.999964" cy="5" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="0.999964" cy="5" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M0.999927 5H0.999964V7C2.10455 7 2.99993 6.10454 2.99993 5H0.999927ZM0.999964 5H1H-1C-1 6.10454 -0.10462 7 0.999964 7V5ZM1 5H0.999964V3C-0.10462 3 -1 3.89546 -1 5H1ZM0.999964 5H0.999927H2.99993C2.99993 3.89546 2.10455 3 0.999964 3V5Z"
        fill="#AAAAAA"
        mask="url(#path-5-inside-3)"
      />
      <mask id="path-7-inside-4" fill="white">
        <ellipse cx="4.99996" cy="5" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="4.99996" cy="5" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M4.99993 5H4.99996V7C6.10455 7 6.99993 6.10454 6.99993 5H4.99993ZM4.99996 5H5H3C3 6.10454 3.89538 7 4.99996 7V5ZM5 5H4.99996V3C3.89538 3 3 3.89546 3 5H5ZM4.99996 5H4.99993H6.99993C6.99993 3.89546 6.10455 3 4.99996 3V5Z"
        fill="#AAAAAA"
        mask="url(#path-7-inside-4)"
      />
      <mask id="path-9-inside-5" fill="white">
        <ellipse cx="0.999964" cy="9" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="0.999964" cy="9" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M0.999927 9H0.999964V11C2.10455 11 2.99993 10.1045 2.99993 9H0.999927ZM0.999964 9H1H-1C-1 10.1045 -0.10462 11 0.999964 11V9ZM1 9H0.999964V7C-0.10462 7 -1 7.89546 -1 9H1ZM0.999964 9H0.999927H2.99993C2.99993 7.89546 2.10455 7 0.999964 7V9Z"
        fill="#AAAAAA"
        mask="url(#path-9-inside-5)"
      />
      <mask id="path-11-inside-6" fill="white">
        <ellipse cx="4.99996" cy="9" rx="0.999964" ry="1" />
      </mask>
      <ellipse cx="4.99996" cy="9" rx="0.999964" ry="1" fill="#D8D8D8" />
      <path
        d="M4.99993 9H4.99996V11C6.10455 11 6.99993 10.1045 6.99993 9H4.99993ZM4.99996 9H5H3C3 10.1045 3.89538 11 4.99996 11V9ZM5 9H4.99996V7C3.89538 7 3 7.89546 3 9H5ZM4.99996 9H4.99993H6.99993C6.99993 7.89546 6.10455 7 4.99996 7V9Z"
        fill="#AAAAAA"
        mask="url(#path-11-inside-6)"
      />
    </svg>
  )
}
