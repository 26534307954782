import { type ComponentLayout } from './types'

export const SpotifyConfig: ComponentLayout = {
  componentId: 'modular-spotify-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'connection'
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: null,
        parameters: [
          {
            dplId: 'outputId'
          },
          {
            dplId: 'databricks-output-connector-v0'
          },
          {
            dplId: 'storage-only-output-v0'
          },
          {
            dplId: 'snowflake-output-connector-v0'
          },
          {
            dplId: 'redshift-output-connector-v0'
          }
        ]
      }
    ]
  }
}
