import { type Pipeline } from 'types/Pipeline'

type Graph = Record<string, GraphComponent>

interface Position {
  x: number
  y: number
}

interface GraphComponent {
  name: string
  position: {
    x: number
    y: number
  }
  isSelected: boolean
  incomingEdges: string[]
}

const COMPONENT_WIDTH = 140
const COMPONENT_HEIGHT = 70
const X_OFFSET = 200
const MAX_COLLISION_ATTEMPTS = 10

/**
 * The world's most basic formatter for the selected components in a transformation pipeline. This should eventually
 * be replaced by a more sophisticated algorithm.
 *
 * Existing components are left exactly where they are, and selected components are positioned to the right of their
 * incoming components.
 *
 * @param pipeline The pipeline to format.
 * @param selectedComponents The selected components in the pipeline to format.
 */
export const formatTransformation = (
  pipeline: Pipeline,
  selectedComponents: Set<string>
): void => {
  const graph: Graph = {}

  for (const componentName in pipeline.pipeline.components) {
    const component = pipeline.pipeline.components[componentName]
    const design = pipeline.design.components[componentName]

    graph[componentName] = {
      name: componentName,
      position: { x: design.position.x, y: design.position.y },
      isSelected: selectedComponents.has(componentName),
      incomingEdges: component.sources || []
    }
  }

  updateSelectedComponentPositions(graph)

  for (const componentName in graph) {
    const component = graph[componentName]
    pipeline.design.components[componentName].position.x = component.position.x
    pipeline.design.components[componentName].position.y = component.position.y
  }
}

// Updates the positions of the selected components in a graph based on the positions of their incoming components.
const updateSelectedComponentPositions = (
  graph: Record<string, GraphComponent>
) => {
  const selectedComponents = Object.values(graph).filter(
    (component) => component.isSelected
  )

  // Sort the selected components in topological order
  const visited = new Set<string>()
  const components: GraphComponent[] = []
  selectedComponents.forEach((component) => {
    if (!visited.has(component.name)) {
      dfs(component, graph, visited, components)
    }
  })

  // Update the positions of the components in the sorted order
  components.forEach((component) => {
    component.position = calculateNewPosition(component, graph)
  })
}

// Performs a DFS on the graph from the given component, visiting all incoming components before the
// component itself.
const dfs = (
  component: GraphComponent,
  graph: Record<string, GraphComponent>,
  visited: Set<string>,
  components: GraphComponent[]
) => {
  visited.add(component.name)

  component.incomingEdges.forEach((edge) => {
    const incomingComponent = graph[edge]
    if (!visited.has(incomingComponent.name)) {
      dfs(incomingComponent, graph, visited, components)
    }
  })

  // Add the component after all incoming components have been visited.
  components.push(component)
}

// Calculates the new position of a component based on the positions of its incoming components. Positions the
// component to the right of the rightmost incoming component and averages the y positions of the incoming components.
const calculateNewPosition = (
  component: GraphComponent,
  graph: Record<string, GraphComponent>
): Position => {
  // At the moment this code doesn't support positioning of components with no incoming edges.
  if (!component.isSelected || component.incomingEdges.length === 0) {
    return component.position
  }

  let maxX: number = -Infinity
  let totalY = 0

  component.incomingEdges.forEach((edge) => {
    const incomingComponent = graph[edge]
    totalY += incomingComponent.position.y
    if (incomingComponent.position.x > maxX) {
      maxX = incomingComponent.position.x
    }
  })

  const newPosition = {
    x: maxX + X_OFFSET, // Position the component 200px to the right of the rightmost incoming component.
    y: totalY / component.incomingEdges.length // Average the y positions of the incoming components.
  }

  // Check for collisions against any existing components and the adjust position if necessary.
  let collisionAttempts = 0
  while (collisionAttempts <= MAX_COLLISION_ATTEMPTS) {
    const collidedComponent = Object.values(graph).find(
      (otherComponent) =>
        otherComponent.name !== component.name &&
        !otherComponent.isSelected &&
        isColliding(newPosition, otherComponent.position)
    )
    if (!collidedComponent) {
      break
    }
    newPosition.y = collidedComponent.position.y + COMPONENT_HEIGHT + 30
    collisionAttempts++
  }

  return roundToNearest(newPosition, 10)
}

// Rounds a position to the nearest multiple of a number.
// For example, the canvas increments work in multiples of 10.
const roundToNearest = (position: Position, nearest: number): Position => {
  return {
    x: Math.round(position.x / nearest) * nearest,
    y: Math.round(position.y / nearest) * nearest
  }
}

// Checks if two positions are colliding
const isColliding = (pos1: Position, pos2: Position): boolean => {
  const xOverlap = Math.abs(pos1.x - pos2.x) < COMPONENT_WIDTH
  const yOverlap = Math.abs(pos1.y - pos2.y) < COMPONENT_HEIGHT
  return xOverlap && yOverlap
}
