import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'

import { ReactComponent as AddIcon } from 'assets/add-plus-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/delete-minus-icon.svg'
import { ReactComponent as DisabledAddIcon } from 'assets/disabled-add-plus-icon.svg'
import { ReactComponent as DisabledDeleteIcon } from 'assets/disabled-delete-minus-icon.svg'

import classes from './DataGridAddRemoveButtons.module.scss'

interface DataGridFooterProps {
  add: {
    disabled: boolean
    onClick: () => void
  }
  remove: {
    disabled: boolean
    onClick: () => void
  }
}

export const DataGridAddRemoveButtons = ({
  add,
  remove
}: DataGridFooterProps) => {
  const { t } = useTranslation()
  return (
    <div className={classes.DataGridAddRemoveButtons}>
      <Button
        alt="text"
        type="button"
        disabled={add.disabled}
        onClick={add.onClick}
        data-testid="add-row"
        aria-label={t('parameterEditor.GRID_EDITOR.addEntry')}
      >
        {add.disabled ? <DisabledAddIcon /> : <AddIcon />}
      </Button>

      <Button
        alt="text"
        type="button"
        disabled={remove.disabled}
        onClick={remove.onClick}
        data-testid="delete-rows"
        aria-label={t('parameterEditor.GRID_EDITOR.deleteEntry')}
      >
        {remove.disabled ? <DisabledDeleteIcon /> : <DeleteIcon />}
      </Button>
    </div>
  )
}
