import {
  useEffect,
  useRef,
  type FunctionComponent,
  type PropsWithChildren
} from 'react'

import classes from './CommandPalette.module.scss'

interface BackdropProps extends PropsWithChildren {
  handleClose: () => void
}

export const Backdrop: FunctionComponent<BackdropProps> = ({
  handleClose,
  children
}) => {
  const backdropRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!backdropRef.current?.contains(event.target as Node)) {
        handleClose()
      }
    }

    window.addEventListener('click', handleClickOutside, true)

    return () => {
      window.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClose])

  return (
    <div ref={backdropRef} className={classes.Backdrop}>
      {children}
    </div>
  )
}
