import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import classes from './FolderLimitReachedModal.module.scss'

export interface FolderLimitReachedModalProps {
  onDismiss: () => void
}

const FolderLimitReachedModal = ({
  onDismiss
}: FolderLimitReachedModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create-folder.limit-modal'
  })

  return (
    <DesignerModal
      size="mid"
      setFocus={false}
      onCancel={onDismiss}
      disableBackdropClick
      id="folder-limit-dialog"
      className={classes.Modal}
      data-testid="folder-limit-modal"
      ariaLabelledBy="folder-limit-title"
    >
      <Typography as="h2" format="tl" className={classes.Title}>
        {t('title')}
      </Typography>

      <Typography className={classes.Description}>
        {t('description')}
      </Typography>

      <Button
        alt="primary"
        text={t('close')}
        onClick={onDismiss}
        className={classes.Dismiss}
        id="folder-limit-modal-dismiss"
        data-testid="folder-limit-modal-dismiss"
      />
    </DesignerModal>
  )
}

export default FolderLimitReachedModal
