import { type XYPosition } from 'reactflow'

import { cloneDeep } from 'lodash'
import {
  Transition,
  type Component,
  type ComponentDesign,
  type ComponentName,
  type Pipeline,
  type PipelineType
} from 'types/Pipeline'

import { getNextComponentName } from '../../utils/getNextComponentName'

type LinkType = 'source' | 'iteration' | keyof typeof Transition

export interface CopiedComponent {
  component: Component
  componentDesign: ComponentDesign
}

export interface Link {
  sourceComponentName: ComponentName
  targetComponentName: ComponentName
  linkType: LinkType
}

export interface ComponentOffset extends XYPosition {
  componentName: string
}
export interface CopyComponentPayload {
  components: CopiedComponent[]
  sourcePipelineType?: PipelineType
  links?: Link[]
  pastePosition?: XYPosition
  componentOffsets?: ComponentOffset[]
}

export const copyComponent =
  (state: Pipeline) =>
  ({ components, links }: CopyComponentPayload) => {
    const copiedComponents: Record<ComponentName, ComponentName> = {}

    components.forEach(({ component, componentDesign }) => {
      const currentComponentName = component.parameters.componentName as string

      const nextComponentName = getNextComponentName(
        state.pipeline.components,
        currentComponentName
      )

      state.pipeline.components[nextComponentName] = {
        ...component,
        parameters: {
          ...component.parameters,
          componentName: nextComponentName
        }
      }

      const newComponentDesign = cloneDeep(componentDesign)
      delete newComponentDesign.tempMetlId
      state.design.components[nextComponentName] = newComponentDesign

      // reset all component links. They will be readded if any links are specified.
      delete state.pipeline.components[nextComponentName].iterationTarget
      delete state.pipeline.components[nextComponentName].transitions
      delete state.pipeline.components[nextComponentName].sources

      // store this component as a copied component so that links can be connected to it later.
      copiedComponents[currentComponentName] = nextComponentName
    })

    links?.forEach(({ sourceComponentName, targetComponentName, linkType }) => {
      const sourceName = copiedComponents[sourceComponentName]
      const sourceComponent = state.pipeline.components[sourceName]

      const targetName = copiedComponents[targetComponentName]
      const targetComponent = state.pipeline.components[targetName]

      const transitionType = linkType as Transition

      if (
        Object.values(Transition).includes(transitionType) &&
        linkType !== 'iteration'
      ) {
        sourceComponent.transitions = {
          ...sourceComponent.transitions,
          [transitionType]: [
            ...(sourceComponent.transitions?.[transitionType] ?? []),
            copiedComponents[targetComponentName]
          ]
        }
      }

      if (linkType === 'source') {
        targetComponent.sources = [
          ...(targetComponent.sources ?? []),
          sourceName
        ]
      }

      if (linkType === 'iteration') {
        sourceComponent.iterationTarget = targetName
      }
    })
  }
