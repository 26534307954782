import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'

import { getComponentName } from '../../job-lib/job-functions/getComponentName'

export const useGetComponentsNameInCurrentPipeline = (): string[] => {
  const { job } = useFlaggedWorkingCopy()
  if (job === null || job === undefined) {
    return []
  }
  return Object.values(job.components).map(getComponentName)
}
