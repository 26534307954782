import { useCallback, useMemo } from 'react'

import { FileType } from '@matillion/git-component-library'

import { useFetchComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'

import { usePipelines } from 'hooks/usePipelines/usePipelines'

import {
  type EmeraldId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import type { ValidationDependencies } from '../../types'
import { getValidationDependencies } from '../../utils/getValidationDependencies'

export const useValidationDependencies = (
  job: TransformationJob | OrchestrationJob | null
) => {
  const { files: pipelines } = usePipelines()

  const { getByImplementationId } = useGetComponentSummary()

  // the validation endpoint expects pipeline summaries in the
  // old JobSummary format, rather than the new File format,
  // so we need to map them back here
  const jobSummaries = useMemo(
    () =>
      Object.values(pipelines).map((pipeline) => ({
        jobId: pipeline.name,
        name: pipeline.fileName,
        folder: pipeline.folder,
        runtimeName: pipeline.runtimeName,
        status: pipeline.status,
        type:
          pipeline.type === FileType.ORCHESTRATION_PIPELINE
            ? JobType.Orchestration
            : JobType.Transformation
      })),
    [pipelines]
  )

  const fetchComponentMetadata = useFetchComponentMetadata()

  const getDependencies = useCallback(
    async (implementationId: EmeraldId, parameters: ParameterCollection) => {
      const componentId = getByImplementationId(
        implementationId.toString(),
        parameters,
        job
      )?.componentId as string

      const componentMetadata = componentId
        ? await fetchComponentMetadata(componentId)
        : undefined

      const validationDependencies: ValidationDependencies = componentMetadata
        ?.metadata?.validationDependencies
        ? getValidationDependencies(
            componentMetadata?.metadata?.validationDependencies,
            jobSummaries,
            componentMetadata
          ) ?? {}
        : {}

      return validationDependencies
    },
    [getByImplementationId, jobSummaries, fetchComponentMetadata, job]
  )

  return getDependencies
}
