import {
  type ParameterValue,
  type StructList,
  type StructValue
} from 'types/Pipeline'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import { type ConfigData } from '../types'

const getValueOrDefaultValue = (
  value: ParameterValue,
  parameter: ComponentParameter
) => {
  return value === null && parameter.defaultValue
    ? parameter.defaultValue
    : value
}

const removeEmptyFilterRows = (filterRows: StructList) =>
  filterRows.filter((row) => Object.values(row).some((colValue) => !!colValue))

export const parseConfigDataToParameterValue = (
  data: ConfigData,
  childProperties: ComponentParameter[]
) => {
  const initValue: StructValue = {}

  return data
    .filter((tableConfig) => tableConfig.isSelected)
    .map((tableConfig) => {
      return childProperties.reduce((acc, parameter) => {
        const lastDelimiter = parameter.dplID.lastIndexOf('/')
        const subParameterName = parameter.dplID.substring(lastDelimiter + 1)

        const result: StructValue = {
          ...acc
        }

        let value: ParameterValue | undefined

        switch (subParameterName) {
          case 'dataSource':
            value = tableConfig.dataSource
            break
          case 'dataSelection':
            value = tableConfig.dataSelection
              .filter((column) => column.isSelected)
              .map((column) => column.name)
            break
          case 'excludedDataElements':
            value = tableConfig.dataSelection
              .filter((column) => !column.isSelected)
              .map((column) => column.name)
            break
          case 'primaryKeyColumns':
            value = tableConfig.primaryKeys
            break
          case 'incrementalColumn':
            value = tableConfig.incrementalColumn
            break
          case 'tableName':
            value = tableConfig.targetTableName
            break
          case 'table':
            // 'table' is a redshift output connector property and cannot accept capital letters
            value = tableConfig.targetTableName.toLowerCase()
            break
          case 'filePrefix':
            value = tableConfig.targetTableName
            break
          case 'targetTableName':
            value = tableConfig.targetTableName
            break
          case 'createTableMode':
            value = tableConfig.createTableMode
            break
          case 'dataSourceFilter':
            value = removeEmptyFilterRows(tableConfig.dataSourceFilter)
            break
          case 'combineFilters':
            value = tableConfig.combineFilters
            break
        }

        if (value !== undefined) {
          result[parameter.dplID] = getValueOrDefaultValue(value, parameter)
        }

        return result
      }, initValue)
    })
}
