import { useMemo, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Input } from '@matillion/component-library'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { DynamicDropdown } from 'components/DynamicDropdown/DynamicDropdown'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { elementsUseGridVariable } from 'job-lib/job-functions/elements'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { type ParameterOptions } from 'modules/ParameterEditors/types'

import { GridVariableEditor } from '../GridVariables/GridVariableEditor/GridVariableEditor'
import { useGridVariableEditor } from '../GridVariables/GridVariableEditor/useGridVariableEditor'
import {
  getDefaultColumnNames,
  getGridElements
} from '../GridVariables/GridVariableEditor/utils'
import { GridVariableSwitcher } from '../GridVariables/GridVariableSwitcher/GridVariableSwitcher'
import classes from './MultiPropertiesEditor.module.scss'
import { convertToElements, getColumns, getDefaultValues } from './utils'

export interface MultiPropertiesEditorProps extends ParameterOptions {
  inputId: string
  elements: ElementCollection
  parameter: ComponentParameter
  parameterName: string
  componentMetadata: ComponentMetadata
  componentInstanceId: number
  componentSummaryId: string
  onDone: (editedValue: ElementCollection) => void
}

export const MultiPropertiesEditor = ({
  editorColumns,
  inputId,
  elements,
  parameter,
  parameterName,
  componentMetadata,
  componentInstanceId,
  componentSummaryId,
  onDone
}: MultiPropertiesEditorProps) => {
  const { t } = useTranslation()
  const isUsingGridVariable = elementsUseGridVariable(elements)

  const columnNames = useMemo(
    () => getDefaultColumnNames(parameter.dataType, editorColumns, t),
    [parameter.dataType, editorColumns, t]
  )

  const {
    columnValues,
    setColumnValues,
    selectedGridVariable,
    setSelectedGridVariable
  } = useGridVariableEditor({
    isUsingGridVariable,
    elements,
    columnNames
  })

  const [useGridVariableChecked, setUseGridVariableChecked] =
    useState(isUsingGridVariable)

  const [values, setValues] = useState(() =>
    !isUsingGridVariable
      ? getDefaultValues(parameter.dataType, editorColumns, elements)
      : {}
  )

  const onSubmit = () => {
    if (useGridVariableChecked) {
      onDone(getGridElements(columnNames, columnValues, selectedGridVariable))
    } else {
      onDone(convertToElements(parameter.dataType, values))
    }
  }

  const columns = useMemo(
    () => getColumns(parameter.dataType, editorColumns),
    [parameter.dataType, editorColumns]
  )

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <ParameterOverlayContent>
        {useGridVariableChecked ? (
          <GridVariableEditor
            columnNames={columnNames}
            columnValues={columnValues}
            selectedGridVariable={selectedGridVariable}
            setColumnValues={setColumnValues}
            setSelectedGridVariable={setSelectedGridVariable}
          />
        ) : (
          <div className={classes.MultiPropertiesEditor__FieldContainer}>
            {columns.map((column) => {
              const name = column.name
                ? column.name
                : t(
                    `componentProperties.columnIds.${
                      column.resourceID as string
                    }`
                  )

              if (!column.name && !column.resourceID) return null

              const commonProps = {
                key: name,
                id: name,
                name,
                title: name,
                'aria-label': name,
                placeholder:
                  parameter.dataType === ParameterDataType.GRID ? '' : name
              }

              const fieldValue = values[name]?.value ?? ''

              switch (column.columnEditorType) {
                case ColumnEditorType.Dropdown:
                  return (
                    <Field
                      {...commonProps}
                      inputComponent={DynamicDropdown}
                      inputId={`${inputId}-${name}`}
                      data-testid={`${inputId}-${name}`}
                      componentMetadata={componentMetadata}
                      componentSummaryId={componentSummaryId}
                      parameter={{
                        ...column,
                        id: componentInstanceId.toString(),
                        metlSlot: parameter.metlSlot
                      }}
                      parameterName={parameterName}
                      value={fieldValue}
                      onEdit={(selectedValue: string) => {
                        setValues((existingValues) => ({
                          ...existingValues,
                          [name]: {
                            value: selectedValue ?? '',
                            type: column.type
                          }
                        }))
                      }}
                    />
                  )

                case ColumnEditorType.Freetext:
                  return (
                    <Field
                      {...commonProps}
                      inputComponent={Input}
                      value={fieldValue}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setValues((v) => ({
                          ...v,
                          [name]: {
                            value: e.target.value ?? '',
                            type: column.type
                          }
                        }))
                      }}
                    />
                  )

                default:
                  return null
              }
            })}
          </div>
        )}
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
        <GridVariableSwitcher
          checked={useGridVariableChecked}
          disabled={false}
          parameter={parameter}
          setChecked={setUseGridVariableChecked}
        />
      </ParameterOverlayFooter>
    </>
  )
}
