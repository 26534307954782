import { AmazonTranscribeConfig } from './AmazonTranscribe'
import { AnaplanConfig } from './Anaplan'
import { ApiExtractConfig } from './ApiExtract'
import { ApiExtractV2Config } from './ApiExtractV2'
import { AwsAIPromptConfig } from './AwsAIPrompt'
import { AzureDocumentIntelligenceConfig } from './azure-document-intelligence'
import { AzureAIPromptConfig } from './AzureAIPrompt'
import { AzureSpeechConfig } from './AzureSpeech'
import { AzureSQLConfig } from './AzureSQL'
import { BashPushdown } from './BashPushdown'
import { BingAdsQueryConfig } from './BingAdsQuery'
import { ChunkTextSFConfig } from './ChunkTextSF'
import { DatabaseQueryConfig } from './DatabaseQuery'
import { DatabricksConfig } from './Databricks'
import { Dynamics365QueryConfig } from './Dynamics365Query'
import { DynamoDBQueryConfig } from './DynamoDBQuery'
import { EmailQueryConfig } from './EmailQuery'
import { ExcelQueryConfig } from './ExcelQuery'
import { FacebookAdsQueryConfig } from './FacebookAdsQuery'
import { FacebookQueryConfig } from './FacebookQuery'
import { GmailConfig } from './Gmail'
import { GoogleAdsQueryConfig } from './GoogleAdsQuery'
import { GoogleAnalyticsConfig } from './GoogleAnalytics'
import { GoogleAnalyticsQueryConfig } from './GoogleAnalyticsQuery'
import { GoogleBigQuery } from './GoogleBigQuery'
import { GoogleSheetsConfig } from './GoogleSheets'
import { GoogleSheetsQuery } from './GoogleSheetsQuery'
import { HubspotQueryConfig } from './HubspotQuery'
import { JdbcConfig } from './Jdbc'
import { JiraConfig } from './Jira'
import { JiraQueryConfig } from './JiraQuery'
import { KafkaConfig } from './Kafka'
import { LDAPQueryConfig } from './LDAPQuery'
import { MailchimpConfig } from './Mailchimp'
import { MarketoQueryConfig } from './MarketoQuery'
import { MicrosoftExchangeConfig } from './MicrosoftExchange'
import { MongoDBQueryConfig } from './MongoDBQuery'
import { NetsuiteQueryConfig } from './NetsuiteQuery'
import { NetSuiteSuiteAnalyticsConfig } from './NetSuiteSuiteAnalytics'
import { ODataConfig } from './OData'
import { ODataQueryConfig } from './ODataQuery'
import { OpenAIAIPromptConfig } from './OpenAIAIPrompt'
import { PineconeVectorQuery } from './PineconeVectorQuery'
import { PineconeVectorUpsert } from './PineconeVectorUpsert'
import { PostgresVectorUpsertConfig } from './PostgresVectorUpsert'
import { RDSComponentQueryConfig } from './RDSQuery'
import { RedshiftConfig } from './Redshift'
import { RedshiftOracleUnloadConfig } from './RedshiftOracleUnload'
import { SalesforceConfig } from './Salesforce'
import { SalesforceLoadConfig } from './SalesforceLoad'
import { SalesforceMarketingCloudQueryConfig } from './SalesforceMarketingCloudQuery'
import { SalesforceQueryConfig } from './SalesforceQuery'
import { SapOdpConfig } from './SapOdp'
import { ServicenowQueryConfig } from './ServicenowQuery'
import { SharepointQueryConfig } from './SharepointQuery'
import { SnowflakeVectorUpsertConfig } from './SnowflakeVectorUpsert'
import { SpcsPrompt } from './SpcsPrompt'
import { SpotifyConfig } from './Spotify'
import { StripeQueryConfig } from './StripeQuery'
import { SurveyMonkeyConfig } from './SurveyMonkey'
import { TextractConfig } from './Textract'
import { type ComponentLayout } from './types'
import { WorkdayConfig } from './Workday'
import { WorkdayCustomReportsConfig } from './WorkdayCustomReports'

export interface WizardConfigs {
  [key: string]: ComponentLayout
}

export const wizardConfigs: WizardConfigs = {
  'aws-ai-prompt': AwsAIPromptConfig,
  'azure-ai-prompt': AzureAIPromptConfig,
  'openai-ai-prompt': OpenAIAIPromptConfig,
  'modular-aws-transcribe-input-v1': AmazonTranscribeConfig,
  'modular-azure-speech-input-v1': AzureSpeechConfig,
  'modular-anaplan-input-v1': AnaplanConfig,
  'modular-google-analytics-input-v0': GoogleAnalyticsConfig,
  'modular-jira-input-v0': JiraConfig,
  'modular-textract-input-v1': TextractConfig,
  'modular-api-extract-input-v1': ApiExtractConfig,
  'modular-api-extract-input-v2': ApiExtractV2Config,
  'modular-azure-sql-input-v0': AzureSQLConfig,
  'modular-azure-document-intelligence-input-v1':
    AzureDocumentIntelligenceConfig,
  'modular-databricks-input-v0': DatabricksConfig,
  'modular-redshift-input-v0': RedshiftConfig,
  'modular-gmail-input-v0': GmailConfig,
  'modular-jdbc-input-v1': JdbcConfig,
  'modular-kafka-input-v1': KafkaConfig,
  'modular-load-salesforce-input-v0': SalesforceLoadConfig,
  'modular-mailchimp-input-v0': MailchimpConfig,
  'modular-microsoft-exchange-input-v0': MicrosoftExchangeConfig,
  'modular-net-suite-input-v0': NetSuiteSuiteAnalyticsConfig,
  'modular-salesforce-input-v0': SalesforceConfig,
  'modular-sap-odp-input-v1': SapOdpConfig,
  'modular-spotify-input-v1': SpotifyConfig,
  'modular-survey-monkey-input-v0': SurveyMonkeyConfig,
  'modular-workday-custom-reports-input-v1': WorkdayCustomReportsConfig,
  'modular-workday-input-v1': WorkdayConfig,
  'modular-google-sheets-input-v0': GoogleSheetsConfig,
  'modular-odata-input-v0': ODataConfig,
  'modular-redshift-oracle-output-v1': RedshiftOracleUnloadConfig,
  'bing-ads-query': BingAdsQueryConfig,
  'database-query': DatabaseQueryConfig,
  'dynamics-365-query': Dynamics365QueryConfig,
  'dynamodb-query': DynamoDBQueryConfig,
  'email-query': EmailQueryConfig,
  'excel-query': ExcelQueryConfig,
  'facebook-query': FacebookQueryConfig,
  'facebook-ads-query': FacebookAdsQueryConfig,
  'google-ads-qry': GoogleAdsQueryConfig,
  'google-analytics-query': GoogleAnalyticsQueryConfig,
  'google-bigquery': GoogleBigQuery,
  'google-sheets-query': GoogleSheetsQuery,
  'hubspot-query': HubspotQueryConfig,
  'jira-query': JiraQueryConfig,
  'ldap-query': LDAPQueryConfig,
  'marketo-query': MarketoQueryConfig,
  'mongodb-query': MongoDBQueryConfig,
  'netsuite-query': NetsuiteQueryConfig,
  'odata-query': ODataQueryConfig,
  'pinecone-vector-upsert': PineconeVectorUpsert,
  'pinecone-vector-query': PineconeVectorQuery,
  'rds-query': RDSComponentQueryConfig,
  'salesforce-marketing-cloud-query': SalesforceMarketingCloudQueryConfig,
  'salesforce-query': SalesforceQueryConfig,
  'servicenow-query': ServicenowQueryConfig,
  'sharepoint-query': SharepointQueryConfig,
  'stripe-query': StripeQueryConfig,
  'postgres-vector-upsert': PostgresVectorUpsertConfig,
  'spcs-ai-prompt': SpcsPrompt,
  'bash-pushdown': BashPushdown,
  'chunk-text-sf': ChunkTextSFConfig,
  'snowflake-vector-upsert': SnowflakeVectorUpsertConfig
}
