import { type ChangeEvent, type Dispatch, type FocusEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Textarea } from '@matillion/component-library'
import classNames from 'classnames'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import { type JobVariable } from 'job-lib/types/Variables'

import { useJobVariables } from 'modules/core/EtlDesigner/hooks/useJobVariables'
import {
  Fields,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'
import { getVariableName } from 'modules/ManageVariables/utils'

import { JobSpecificFields } from '../../../Form/components/JobSpecificFields'
import classes from '../../../Form/Form.module.scss'
import { type ReducerAction } from '../../../Form/formReducer/formReducer'

interface VariableInfoStepProps {
  dispatch: Dispatch<ReducerAction>
  state: FormState
  variableToEdit: JobVariable
}

export const VariableInfoStep = ({
  state,
  dispatch,
  variableToEdit
}: VariableInfoStepProps) => {
  const { t } = useTranslation()
  const { data: projectVariables = [] } = useListProjectVariables()
  const jobVariables = useJobVariables()
  return (
    <>
      <Field
        name={Fields.NAME}
        className={classNames(classes.Field, classes['Field__Input--Compact'])}
        title={t('manageVariables.jobVariables.fields.variableName')}
        value={state[Fields.NAME].value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_NAME,
            value: e.target.value,
            jobVariables,
            projectVariables,
            originalName: getVariableName(variableToEdit)
          })
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_NAME,
            value: e.target.value,
            jobVariables,
            projectVariables,
            originalName: getVariableName(variableToEdit)
          })
        }}
        errorText={
          state[Fields.NAME].isValid === false && (
            <div role="alert">
              {t(state[Fields.NAME].error, {
                field: t('manageVariables.jobVariables.fields.variableName'),
                value: state[Fields.NAME].value.toLowerCase()
              })}
            </div>
          )
        }
      />
      <Field
        name={Fields.DESCRIPTION}
        className={classes.Field}
        title={t('manageVariables.jobVariables.fields.description')}
        value={state[Fields.DESCRIPTION].value}
        maxLength={255}
      >
        <Textarea
          className={classes.Form__Description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.DESCRIPTION,
              value: e.target.value
            })
          }}
        />
      </Field>
      <JobSpecificFields
        dispatch={dispatch}
        state={state}
        isGridVariable={true}
      />
    </>
  )
}
