import { type ComponentType } from 'react'

import {
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'
import { type StructList } from 'types/Pipeline'

export interface TableDef {
  name: string
  columns?: string[]
  isError?: boolean
}

export type TableDefMap = Map<string, TableDef>

export type TableColumns = Record<string, TableDef>

export interface ColumnConfig {
  name: string
  isSelected: boolean
}

export interface DataSelectionConfig {
  isSelected: boolean
  dataSource: string
  dataSelection: ColumnConfig[]
  createTableMode: string | null
  targetTableName: string
  incrementalColumn: string | null
  primaryKeys: string[]
  dataSourceFilter: StructList
  combineFilters: string | null
}

export const defaultDataSelectionConfig = (): DataSelectionConfig => {
  return {
    isSelected: false,
    dataSource: '',
    dataSelection: [],
    createTableMode: null,
    targetTableName: '',
    primaryKeys: [],
    incrementalColumn: null,
    dataSourceFilter: [],
    combineFilters: null
  }
}

export enum LoadStrategy {
  APPEND = 'APPEND',
  APPEND_WITH_HIGH_WATERMARK = 'APPEND_WITH_HIGH_WATERMARK',
  REPLACE = 'REPLACE',
  UPSERT = 'UPSERT'
}

export enum SchemaDrift {
  Yes = 'Yes',
  No = 'No'
}

export type ConfigData = DataSelectionConfig[]

type ExpCompProps = DataGridRow['expandedComponentProps']

export interface MultiTableGridRow<T = ExpCompProps> extends DataGridRow {
  id: string
  expandedComponent?: ComponentType<React.PropsWithChildren<T>>
  expandedComponentProps?: T
}

export interface MultiTableDataGridColumnProps<K extends string, T>
  extends DataGridColumnProps<T> {
  key: K
}

export type UpdateTableConfigHandler = (
  tableConfig: DataSelectionConfig
) => void

export type ColumnsFetchedHandler = (
  tableName: string,
  columns: string[],
  isError: boolean
) => void

export enum Screen {
  SelectTables = 'selectTables',
  ColumnConfiguration = 'columnConfiguration',
  AdvancedFiltering = 'advancedFiltering'
}

export type ColumnName = 'dataElement' | 'primaryKeys' | 'incrementalColumn'
