import { isEmpty } from 'lodash'
import type { Note, NoteId, Pipeline } from 'types/Pipeline'

export interface NotePayload {
  id: NoteId
  partialNote: Partial<Note>
}

export const updateNote =
  (state: Pipeline) =>
  ({ id, partialNote }: NotePayload) => {
    if (isEmpty(partialNote)) {
      return
    }

    state.design.notes[id] = {
      ...state.design.notes[id],
      ...partialNote
    }
  }
