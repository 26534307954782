import { useCallback, useEffect, useState } from 'react'

import { cloneDeep, isEqual } from 'lodash'

import {
  LoadStrategy,
  type ConfigData,
  type DataSelectionConfig
} from '../../../types'
import {
  type ApplyToAllCondition,
  type ConditionOptions,
  type ConflictGridRow,
  type ConflictsColumnName,
  type OnColumnsSelectionChange,
  type SetConflictRow
} from '../types'

const initConditions = (
  activeTableConfig: DataSelectionConfig,
  loadStrategy: LoadStrategy
): ApplyToAllCondition[] => {
  if (!activeTableConfig.dataSource || !activeTableConfig.incrementalColumn) {
    return []
  }

  return [
    {
      tableName: activeTableConfig.dataSource,
      incrementalColumn: activeTableConfig.incrementalColumn,
      availableColumns: selectedElementNames(activeTableConfig),
      primaryKeys: activeTableConfig.primaryKeys
    }
  ]
}

const initWorkingData = (
  data: ConfigData,
  isFetchColumnsComplete: boolean
): ConfigData | undefined => {
  if (!isFetchColumnsComplete) return
  return cloneDeep(data)
}

const selectedElementNames = (tableConfig: DataSelectionConfig) => {
  return tableConfig.dataSelection
    .filter((element) => element.isSelected)
    .map((element) => element.name)
}

const getConflictGridRow = (table: DataSelectionConfig): ConflictGridRow => {
  const options = selectedElementNames(table)
  return {
    id: table.dataSource ?? '',
    cells: {
      tableName: {
        value: table.dataSource
      },
      incrementalColumn: {
        value: table.incrementalColumn,
        options,
        readonly: !!table.incrementalColumn
      },
      primaryKeys: {
        value: table.primaryKeys,
        options,
        readonly: table.primaryKeys.length > 0
      }
    }
  }
}

interface ApplyToAllBag {
  conditions: ApplyToAllCondition[]
  conflictsData: ConflictGridRow[] | undefined
  applyToAll: () => void
  availableConditionTables: string[]
  addCondition: (tableName: string) => void
  setConditionTable: (currentTableName: string, newTableName: string) => void
  setConditionValue: OnColumnsSelectionChange
  setConflictValue: SetConflictRow
  getSaveData: () => DataSelectionConfig[]
}

export const useApplyToAll = (
  data: ConfigData,
  activeTableConfig: DataSelectionConfig,
  loadStrategy: LoadStrategy,
  isFetchColumnsComplete: boolean
): ApplyToAllBag => {
  const [workingData, setWorkingData] = useState<ConfigData | undefined>(
    initWorkingData(data, isFetchColumnsComplete)
  )

  const [conditions, setConditions] = useState<ApplyToAllCondition[]>(
    initConditions(activeTableConfig, loadStrategy)
  )

  const [conflictsData, setConflictsData] = useState<
    ConflictGridRow[] | undefined
  >()

  const [hasRun, setHasRun] = useState(false)

  const applyToAll = useCallback(() => {
    setWorkingData(() => {
      if (!data) {
        return undefined
      }

      return data.map((table) => {
        const result = { ...table }
        const selectedElements = selectedElementNames(table)

        if (table.isSelected) {
          if (!table.incrementalColumn) {
            for (const condition of conditions) {
              if (
                !!condition.incrementalColumn &&
                selectedElements.includes(condition.incrementalColumn)
              ) {
                result.incrementalColumn = condition.incrementalColumn
                break
              }
            }
          }
        }

        if (
          loadStrategy === LoadStrategy.UPSERT &&
          table.primaryKeys.length === 0
        ) {
          for (const condition of conditions) {
            if (
              !!condition.primaryKeys &&
              condition.primaryKeys.length > 0 &&
              condition.primaryKeys.every((pk) => selectedElements.includes(pk))
            ) {
              result.primaryKeys = condition.primaryKeys
            }
          }
        }

        return result
      })
    })
  }, [conditions, data, loadStrategy])

  useEffect(() => {
    if (isFetchColumnsComplete && !workingData) {
      setWorkingData(cloneDeep(data))
    }
  }, [data, isFetchColumnsComplete, workingData])

  useEffect(() => {
    if (!hasRun && workingData) {
      applyToAll()
      setHasRun(true)
    }
  }, [applyToAll, hasRun, workingData])

  useEffect(() => {
    setConflictsData(() => {
      if (!workingData || !hasRun) {
        return undefined
      }

      return workingData
        .filter(
          (table) =>
            table.isSelected &&
            (!table.incrementalColumn ||
              (loadStrategy === LoadStrategy.UPSERT &&
                table.primaryKeys.length === 0))
        )
        .map<ConflictGridRow>((table) => getConflictGridRow(table))
    })
  }, [hasRun, loadStrategy, workingData])

  const availableConditionTables = data
    .filter(
      (table) =>
        table.isSelected &&
        !conditions.find((c) => c.tableName === table.dataSource)
    )
    .map((table) => table.dataSource)

  const addCondition = (tableName: string) => {
    const tableConfig = data.find((t) => t.dataSource === tableName)

    if (!tableConfig) return

    const columns = tableConfig.dataSelection.map((column) => column.name)

    const newCondition: ApplyToAllCondition = {
      tableName,
      availableColumns: columns,
      incrementalColumn: null,
      primaryKeys: []
    }

    setConditions((c) => [...c, newCondition])
  }

  const setConditionTable = (
    currentTableName: string,
    newTableName: string
  ) => {
    const newTableConfig = data.find((t) => t.dataSource === newTableName)

    if (!newTableConfig) return

    const newColumns = newTableConfig.dataSelection.map((column) => column.name)

    setConditions((c) => {
      return c.map((condition) => {
        const result = { ...condition }
        if (result.tableName === currentTableName) {
          result.tableName = newTableName
          result.availableColumns = newColumns
          result.incrementalColumn = null
          result.primaryKeys = []
        }
        return result
      })
    })
  }

  const setConditionValue = (
    tableName: string,
    newValue: string | string[] | null,
    conditionField: keyof ConditionOptions
  ) => {
    const currCond = conditions.find((cond) => cond.tableName === tableName)

    if (!currCond) return

    if (
      conditionField === 'primaryKeys' &&
      Array.isArray(newValue) &&
      !isEqual(newValue, currCond.primaryKeys)
    ) {
      const newPkArr: string[] =
        newValue.length > 0 ? newValue : currCond.primaryKeys

      setConditions((ts) => {
        return ts.map((condition) => {
          const result = cloneDeep(condition)

          if (result.tableName === tableName) {
            result.primaryKeys = newPkArr
          }

          return result
        })
      })
    } else if (
      conditionField === 'incrementalColumn' &&
      typeof newValue === 'string' &&
      !!newValue &&
      newValue !== currCond?.incrementalColumn
    ) {
      setConditions((ts) => {
        return ts.map((condition) => {
          const result = cloneDeep(condition)

          if (result.tableName === tableName) {
            result.incrementalColumn = newValue
          }

          return result
        })
      })
    }
  }

  const setConflictValue = (
    colId: ConflictsColumnName,
    rowId: number,
    newValue: string | string[] | null
  ) => {
    if (!conflictsData) return
    const currRow = conflictsData[rowId]

    if (isEqual(currRow.cells[colId].value, newValue)) {
      return
    }

    setConflictsData((cd) => {
      const result = cloneDeep(cd)
      if (result && result.length > rowId) {
        const row = result[rowId]

        if (row.cells[colId]) {
          row.cells[colId].value = newValue
        }
      }
      return result
    })
  }

  const getSaveData = () =>
    workingData?.map((table) => {
      const result: DataSelectionConfig = { ...table }

      const tableConflict = conflictsData?.find(
        (conflict) => conflict.id === table.dataSource
      )

      if (tableConflict) {
        const newIncrementalColumn = tableConflict.cells.incrementalColumn.value
        if (
          !table.incrementalColumn &&
          typeof newIncrementalColumn === 'string'
        ) {
          result.incrementalColumn = newIncrementalColumn
        }

        const newPrimaryKeys = tableConflict.cells.primaryKeys.value
        if (Array.isArray(newPrimaryKeys) && table.primaryKeys.length === 0) {
          result.primaryKeys = newPrimaryKeys
        }
      }

      return result
    }) ?? []

  return {
    conditions,
    conflictsData,
    availableConditionTables,
    applyToAll,
    addCondition,
    setConditionTable,
    setConditionValue,
    setConflictValue,
    getSaveData
  }
}
