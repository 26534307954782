import { type DataSelectionConfig, type LoadStrategy } from '../types'

export const isTableConfigured = (
  loadStrategy?: LoadStrategy,
  tableConfig?: DataSelectionConfig
): boolean => {
  if (!tableConfig) return false
  const selectedColumns = tableConfig.dataSelection.filter(
    (column) => column.isSelected
  )
  const hasColumns = selectedColumns.length > 0

  if (loadStrategy === 'APPEND_WITH_HIGH_WATERMARK') {
    return hasColumns && !!tableConfig.incrementalColumn
  }
  if (loadStrategy === 'UPSERT') {
    return (
      hasColumns &&
      !!tableConfig.incrementalColumn &&
      tableConfig.primaryKeys.length !== 0
    )
  }

  // Fallthrough scenario is for APPEND strategy
  return hasColumns
}
