import React, { createElement } from 'react'

import { Typography } from '@matillion/component-library'
import { type CellContext, type HeaderContext } from '@tanstack/react-table'
import { cloneDeep } from 'lodash'

import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { type ElementValueType } from 'job-lib/types/Parameters'

import { type GridEditorContextValues } from '../../../GridEditorContext'
import { type GridDropdownProps } from '../../Grid/components/GridDropdown/GridDropdown'
import { type GridExpressionProps } from '../../Grid/components/GridExpressionEditor/GridExpressionEditor'
import {
  type GridCell,
  type GridInputHandler,
  type GridRow
} from '../../Grid/types'
import { getColumnMappingReactTable } from '../../Grid/utils'

export interface DynamicMeta {
  column: EditorColumn
  setRows: React.Dispatch<React.SetStateAction<GridRow[]>>
  columnSlot: number
  context: GridEditorContextValues
  columnName: string
}
export const DynamicHeader = (info: HeaderContext<GridRow, unknown>) => {
  const meta = info.column.columnDef.meta as DynamicMeta
  return <Typography>{meta.columnName}</Typography>
}

export const DynamicCell = (info: CellContext<GridRow, GridCell>) => {
  const cell = info.cell.getValue()
  const meta = info.column.columnDef.meta as DynamicMeta
  const {
    componentMetaData,
    parameter,
    componentInstanceId,
    parameterName,
    componentSummaryId,
    elements
  } = meta.context
  return createElement<
    GridDropdownProps & GridExpressionProps & GridInputHandler
  >(getColumnMappingReactTable(meta.column), {
    type: meta.column.type as ElementValueType,
    slot: cell.slot,
    value: cell.value,
    rowSlot: cell.rowSlot,
    dataType: cell.dataType,
    onChange: (newValue) => {
      meta.setRows((prevRows) => {
        const newRows = cloneDeep(prevRows)
        const rowIndex = newRows.findIndex((row) => row.id === info.row.id)
        newRows[rowIndex].cells[meta.columnSlot].value = newValue.value
        return newRows
      })
    },
    componentMetaData,
    editorColumn: meta.column,
    parameter,
    componentInstanceId,
    parameterName,
    componentSummaryId,
    elements
  })
}
