import { type ChangeEvent } from 'react'

import { Textarea } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './TextEditor.module.scss'

const REACT_FLOW_NO_DRAG_CLASS_NAME = 'nodrag'
const REACT_FLOW_NO_SCROLL_CLASS_NAME = 'nowheel'

export interface TextEditorProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  content: string
  onCancel: () => void
}

export const TextEditor = ({
  onChange,
  content,
  onCancel
}: TextEditorProps) => {
  return (
    <Textarea
      autoFocus
      onFocus={(e) => {
        e.currentTarget.setSelectionRange(content.length, content.length)
      }}
      name="note-textarea"
      className={classNames(
        classes.TextEditor__Textarea,
        REACT_FLOW_NO_DRAG_CLASS_NAME,
        REACT_FLOW_NO_SCROLL_CLASS_NAME
      )}
      onChange={onChange}
      onClick={(e) => {
        e.stopPropagation()
      }}
      value={content}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          onCancel()
        }
        // ensure that keyboard events such as undo/redo which we want to
        // handle natively don't end up in the canvas
        // event handlers
        e.stopPropagation()
      }}
    />
  )
}
