import { useContext, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { isCustomConnector } from 'job-lib/cisIds/idType'
import { type JobType } from 'job-lib/types/JobType'

import { type StatusLabelType } from 'modules/ComponentSummaries/components/ReleaseStatusBadge/ReleaseStatusBadge'

import camelToKebabCase from 'utils/camelToKebabCase'
import { isMacOs } from 'utils/isMacOs'

import { ContextMenuUpgradeModalContext } from '../UpgradeModalProvider/context'
import { CanvasContextMenu, type ContextMenuItem } from './CanvasContextMenu'

interface ContextMenuProps {
  jobName: string
  jobType?: JobType
  hasSelectedNodes: boolean
  hasSelectedComponents: boolean
  rightClickedComponentSummaryId: string | null
  onCommand: (id: string) => unknown
}

export const ContextMenu: FC<ContextMenuProps> = ({
  jobName,
  jobType,
  hasSelectedNodes,
  hasSelectedComponents,
  rightClickedComponentSummaryId,
  onCommand
}) => {
  const { t } = useTranslation()
  const isContextMacOS = {
    ...(isMacOs() && { context: 'macOS' })
  }

  const isRightClickedCustomConnector = Boolean(
    rightClickedComponentSummaryId &&
      isCustomConnector(rightClickedComponentSummaryId)
  )

  const { showUpgradeModal } = useContext(ContextMenuUpgradeModalContext)

  return (
    <>
      {CanvasContextMenu({
        jobName,
        jobType,
        hasSelectedNodes,
        hasSelectedComponents,
        isRightClickedCustomConnector
      }).map(({ id: sectionId, items }) => (
        <PopOverSection key={`canvas-context-menu-section-${sectionId}`}>
          {items.map(
            ({
              id,
              disabled,
              shortcut,
              context,
              subtext,
              preview = false,
              requiresUpgrade = false,
              upgradeModalType
            }: ContextMenuItem) => {
              const onClickHandler = () => {
                if (requiresUpgrade) {
                  // Show the upgrade modal if one exists for this item
                  upgradeModalType && showUpgradeModal(upgradeModalType)
                } else {
                  onCommand(id)
                }
              }
              let statusLabel: StatusLabelType | undefined
              if (requiresUpgrade) {
                statusLabel = 'upgrade'
              } else if (preview) {
                statusLabel = 'preview'
              }
              const isDisabled = disabled && !requiresUpgrade // Don't disable menu item if it's just a painted door to upgrade

              return (
                <PopOverItem
                  key={`canvas-context-menu-item-${id}`}
                  data-testid={`btn-context-menu-item-${id}`}
                  data-heapid={`etld_context-menu-${camelToKebabCase(
                    id
                  )}-cta_click`}
                  data-heap-prop-jobtype={jobType}
                  onClick={onClickHandler}
                  disabled={isDisabled}
                  statusLabel={statusLabel}
                  subtext={subtext}
                  shortcut={shortcut && t(shortcut, context ?? isContextMacOS)}
                >
                  {t(`translation:canvasContextMenu.${id}`)}
                </PopOverItem>
              )
            }
          )}
        </PopOverSection>
      ))}
    </>
  )
}
