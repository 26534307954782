import { useMemo } from 'react'

import { useGetRootPipelineActivities } from 'api/hooks/useGetRootPipelineActivities/useGetRootPipelineActivities'

import { mapActivityItemToRunTaskStatus } from 'utils/mapActivityItemToRunTaskStatus'

export const useSelectedTasksActivity = (pipelineIds: string[]) => {
  const activities = useGetRootPipelineActivities(pipelineIds)
  const tasks = useMemo(() => {
    const isDefined = <T>(item: T | undefined): item is T => item !== undefined

    return activities
      .map((activity) => activity.data)
      .filter(isDefined)
      .map((activity) => mapActivityItemToRunTaskStatus(activity))
  }, [activities])
  return tasks
}
