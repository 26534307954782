import { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NodeResizer, NodeToolbar } from 'reactflow'

import { Icon, Toaster } from '@matillion/component-library'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { type NoteNodeData } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import { useFlags } from 'hooks/useFlags'

import {
  DEFAULT_NOTE_HEIGHT,
  DEFAULT_NOTE_WIDTH
} from 'job-lib/hooks/useMakeNote/useMakeNote'
import { jobActions } from 'job-lib/store'

import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import { AiNoteGenerator } from './AiNoteGenerator/AiNoteGenerator'
import classes from './EtlNote.module.scss'
import { EtlNoteToolbar } from './EtlNoteToolbar/EtlNoteToolbar'
import { MarkdownTextViewer } from './MarkdownTextViewer/MarkdownTextViewer'
import { TextEditor } from './TextEditor/TextEditor'
import { useResizeNote } from './useResizeNote/useResizeNote'

export interface EtlNoteProps {
  id: number
  isSelected?: boolean
  data: NoteNodeData
}

export const EtlNote = memo(({ id, isSelected, data }: EtlNoteProps) => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)
  const { content, selection, isAIGenerated } = data
  const [aiGenerationActive, setAiGenerationActive] = useState(
    isAIGenerated && selection
  )
  const [editing, setEditing] = useState(false)
  const [prevContent, setPrevContent] = useState(content)
  const [text, setText] = useState(content)
  const [temporaryTheme, setTemporaryTheme] = useState<string>()
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { makeToast } = Toaster.useToaster()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const {
    onResizeStart,
    onResizeEnd,
    resizingHeight,
    resizingWidth,
    resizing
  } = useResizeNote({
    id
  })

  const width = resizing ? resizingWidth ?? data.width : data.width
  const height = resizing ? resizingHeight ?? data.height : data.height

  if (content !== prevContent) {
    setText(content)
    setPrevContent(content)
  }

  const cleanQueryData = () => {
    // Clean up the AI query data which is no longer required (Note ids are reused so the query key could be reused)
    queryClient.removeQueries({
      queryKey: ['generatePipelineDocs', id, selection]
    })
  }

  const dispatchFinishedNote = useCallback(
    (newText?: string) => {
      if (rolloutEnableWorkingCopyProvider) {
        update((state) => {
          state.updateNote({
            id: String(id),
            partialNote: {
              content: newText ?? text
            }
          })
        })
      } else {
        dispatch(
          jobActions.updateNote({
            id,
            partialNote: {
              content: newText ?? text,
              isAIGenerated: undefined,
              selection
            }
          })
        )
      }
    },
    [rolloutEnableWorkingCopyProvider, update, id, text, selection, dispatch]
  )

  const saveChangesHandler = () => {
    setEditing(false)
    dispatchFinishedNote()
  }

  const cancelEditHandler = () => {
    setEditing(false)
    setText(content)
  }

  const aiNoteGenerationFinishedHandler = (generatedText: string) => {
    setText(generatedText)
    setAiGenerationActive(false)
    dispatchFinishedNote(generatedText)
    cleanQueryData()
    makeToast({
      type: 'success',
      title: t('note.aiGenerator.toast.success.title'),
      message: t('note.aiGenerator.toast.success.message')
    })
  }

  return (
    <>
      <NodeToolbar
        data-testid="etl-note-toolbar"
        className={classes.EtlNote__Toolbar}
        align="start"
      >
        <EtlNoteToolbar
          id={id}
          selectedTheme={data.theme}
          selectTemporaryTheme={setTemporaryTheme}
          onDelete={cleanQueryData}
        />
      </NodeToolbar>

      <NodeResizer
        minWidth={DEFAULT_NOTE_WIDTH}
        minHeight={DEFAULT_NOTE_HEIGHT}
        onResizeStart={onResizeStart}
        onResizeEnd={onResizeEnd}
      />
      <div
        onBlur={(event) => {
          // Don't trigger when focus switches between children, only when focus leaves the note
          if (!event.currentTarget.contains(event.relatedTarget) && editing) {
            saveChangesHandler()
          }
        }}
        data-testid={`etl-note-${id}`}
        style={{ width, height }}
        ref={ref}
        className={classNames(classes.EtlNote, {
          [classes['EtlNote--isSelected']]: isSelected,
          [classes[`EtlNote__Themes--${temporaryTheme ?? data.theme}`]]:
            data.theme !== undefined
        })}
      >
        <div className={classes.EtlNote__DragBar}>
          <Icon.DragHandle />
        </div>
        {aiGenerationActive && (
          <AiNoteGenerator
            id={id}
            selection={selection ?? []}
            onComplete={aiNoteGenerationFinishedHandler}
          />
        )}
        {!aiGenerationActive &&
          (editing ? (
            <TextEditor
              onChange={(e) => {
                setText(e.target.value)
              }}
              content={text}
              onCancel={cancelEditHandler}
            />
          ) : (
            <MarkdownTextViewer
              onClick={() => {
                setEditing(true)
              }}
              content={text}
            />
          ))}
      </div>
    </>
  )
})

EtlNote.displayName = 'EtlNote'
