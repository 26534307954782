export type JobVariableCollection = Record<string, JobVariable>

export type GridVariableCollection = Record<string, GridVariable>

export enum VariableScope {
  PROJECT_VARIABLE = 'PROJECT_VARIABLE',
  JOB_VARIABLE = 'JOB_VARIABLE'
}

export enum JobVariableType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  GRID = 'GRID'
}

export enum JobVariableBehaviour {
  SHARED = 'SHARED',
  COPIED = 'COPIED'
}

export enum JobVariableVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export interface ScalarVariable {
  definition: JobVariableDefinition
  value: string
}

export interface GridVariable {
  definition: GridVariableDefinition
  values: Array<{
    values: string[]
  }>
}

export interface JobVariableDefinition {
  name: string
  description?: string
  type: JobVariableType
  behaviour: JobVariableBehaviour
  visibility: JobVariableVisibility
}

export interface GridVariableColumnDefinition {
  name: string
  type: Omit<JobVariableType, 'GRID'>
}

export interface GridVariableDefinition {
  name: string
  scope: JobVariableBehaviour
  definitions: GridVariableColumnDefinition[]
  description?: string
  visibility: JobVariableVisibility
}

export type JobVariable = ScalarVariable | GridVariable

export const isScalarVariable = (
  variable: ScalarVariable | GridVariable
): variable is ScalarVariable => 'value' in variable

export const isGridVariable = (
  variable: ScalarVariable | GridVariable
): variable is GridVariable => 'values' in variable
