import { singleJoiningSlash } from 'utils/singleJoiningSlash'

export enum ProjectExplorerPaths {
  SECRET_DEFINITIONS = 'secret-definitions',
  OAUTH = 'oauth',
  CREATE_SCHEDULE = 'schedules/add'
}

export const getProjectExplorerLink = (
  origin: string,
  projectId: string,
  path?: string | ProjectExplorerPaths
) => `${origin}/${path ? singleJoiningSlash(projectId, path) : projectId}`
