import { type PropsWithChildren } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'

import useDesignerQueryClient from './useDesignerQueryClient'

const DesignerQueryClientProvider = ({ children }: PropsWithChildren) => {
  const client = useDesignerQueryClient()

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

export default DesignerQueryClientProvider
