import { useEffect } from 'react'

import { FileStatus } from '@matillion/git-component-library'
import { useUser } from '@matillion/hub-client'

import config from 'config'

import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export interface PipelineItem {
  projectId: string
  branchId: string
  environmentId: string
  pipelineName: string
  agentId: string
}

const cookieDomain = window.location.hostname.split('.').slice(-2).join('.')
const maxNumOfCookies = 2

export const deleteLegacyCookie = () => {
  document.cookie = `recentlyAccessedPipelines=""; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${cookieDomain}; path=/`
}

export const deleteOldestCookies = () => {
  const cookieName = 'recentlyAccessedPipelines'
  const cookies = document.cookie.split(';')
  const filteredCookies = cookies.filter((cookie) =>
    cookie.trim().startsWith(cookieName)
  )

  if (filteredCookies.length > maxNumOfCookies) {
    filteredCookies.sort((a, b) => {
      const aExpiry = new Date(a.split('=')[1].split(';')[0]).getTime()
      const bExpiry = new Date(b.split('=')[1].split(';')[0]).getTime()
      return aExpiry - bExpiry
    })

    const cookiesToDelete = filteredCookies.slice(
      0,
      filteredCookies.length - maxNumOfCookies
    )

    for (const cookie of cookiesToDelete) {
      const cookieParts = cookie.split('=')
      const cookieToDeleteName = cookieParts[0].trim()
      document.cookie = `${cookieToDeleteName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${cookieDomain}; path=/`
    }
  }
}

const pipelineSeparator = '#'
const attributeSeparator = '|'

export const convertPipelineToString = ({
  projectId,
  branchId,
  pipelineName,
  environmentId,
  agentId
}: PipelineItem) => {
  return `${pipelineSeparator}PID:${projectId}${attributeSeparator}BID:${branchId}${attributeSeparator}JID:${pipelineName}${attributeSeparator}EID:${environmentId}${attributeSeparator}AID:${agentId}${attributeSeparator}${pipelineSeparator}`
}

const convertStringToPipeline = (pipelineString: string) => {
  /*
   * This regex matches against a string representation of a JSON-like pipeline object
   * e.g. "#PID:123|BID:456|JID:jobname.orch.yaml|EID:101112|AID:131415|#"
   * It will return an array of results, each containing a key-value pair (groups)
   */
  const regex = /(\w{3}):([^|]+)(?=\|)/g
  let match
  const attributes: { [key: string]: string } = {}

  while ((match = regex.exec(pipelineString)) !== null) {
    const [, key, value] = match
    attributes[key] = value
  }

  if (
    !attributes.PID ||
    !attributes.BID ||
    !attributes.JID ||
    !attributes.EID ||
    !attributes.AID
  ) {
    return undefined
  }

  return {
    projectId: attributes.PID,
    branchId: attributes.BID,
    pipelineName: attributes.JID,
    environmentId: attributes.EID,
    agentId: attributes.AID
  }
}

export const useRecentlyAccessedPipelines = () => {
  const {
    jobSummaryId: pipelineName,
    projectId,
    branchId,
    agentId,
    environmentId
  } = useProjectInfo()
  const { organisation } = useUser()
  const { files: allPipelines } = usePipelines()

  const accountId = organisation.id
  const cookieName = `recentlyAccessedPipelines.${accountId}.${config.environment}`

  useEffect(() => {
    const pipelinesFromCookie = getPipelinesFromCookie()

    if (pipelinesFromCookie.length <= 0) {
      return
    }

    if (!projectId || !branchId || !agentId || !environmentId) {
      return
    }

    const pipelines: PipelineItem[] = pipelinesFromCookie
      .map(convertStringToPipeline)
      .filter((pipeline): pipeline is PipelineItem => pipeline !== undefined)

    for (const p of pipelines) {
      const attributesMatch =
        p.projectId === projectId &&
        p.branchId === branchId &&
        p.environmentId === environmentId &&
        p.agentId === agentId

      const pipelineExists = Object.values(allPipelines).some(
        (pipeline) =>
          pipeline.name === p.pipelineName &&
          pipeline.status !== FileStatus.DELETED
      )

      if (!pipelineExists && attributesMatch) {
        const updatedPipelines = pipelines
          .filter((pipeline) => pipeline.pipelineName !== p.pipelineName)
          .map(convertPipelineToString)

        setCookie(updatedPipelines)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPipelines, projectId, branchId, environmentId, agentId, pipelineName])

  const getPipelinesFromCookie = () => {
    const cookieData = document.cookie
    if (cookieData) {
      const cookieArray = cookieData.split(';')
      for (const cookie of cookieArray) {
        const [name, value] = cookie.split('=').map((c) => c.trim())
        if (name === cookieName) {
          const regex = /#(.*?)#/g
          return decodeURIComponent(value).match(regex) ?? []
        }
      }
    }
    return []
  }

  const setCookie = (
    cookieContent: string[],
    currentDate: Date = new Date()
  ) => {
    const encodedCookieContent = encodeURIComponent(cookieContent.join(''))

    const expirationDate = new Date(currentDate.getTime())
    expirationDate.setDate(expirationDate.getDate() + 14)

    document.cookie = `${cookieName}=${encodedCookieContent}; expires=${expirationDate.toUTCString()}; domain=${cookieDomain}; path=/;`

    return expirationDate
  }

  const addRecentPipeline = (
    recentPipelines: string[],
    accessedPipeline: PipelineItem
  ) => {
    const accessedPipelineAsString = convertPipelineToString(accessedPipeline)
    const pipelineIndex = recentPipelines.findIndex(
      (item) => item === accessedPipelineAsString
    )

    if (pipelineIndex !== -1) {
      recentPipelines.splice(pipelineIndex, 1)
    }
    recentPipelines.unshift(accessedPipelineAsString)

    if (recentPipelines.length > 4) {
      recentPipelines.splice(4)
    }

    return recentPipelines
  }

  const storeRecentlyAccessedPipeline = () => {
    if (
      !projectId ||
      !branchId ||
      !pipelineName ||
      !environmentId ||
      !agentId
    ) {
      return
    }

    const recentPipelines = getPipelinesFromCookie()
    const updatedRecentPipelines = addRecentPipeline(recentPipelines, {
      projectId,
      branchId,
      pipelineName,
      environmentId,
      agentId
    })

    setCookie(updatedRecentPipelines)
    deleteOldestCookies()
  }

  const updateRecentlyAccessedPipeline = ({
    previousPipelineId,
    newPipelineId
  }: {
    previousPipelineId: string
    newPipelineId: string
  }) => {
    const pipelinesFromCookie = getPipelinesFromCookie()
    const pipelines: PipelineItem[] = pipelinesFromCookie
      .map(convertStringToPipeline)
      .filter((pipeline): pipeline is PipelineItem => pipeline !== undefined)

    const renamedPipelines = pipelines
      .map((pipeline) => {
        if (pipeline.pipelineName === previousPipelineId) {
          return {
            ...pipeline,
            pipelineName: newPipelineId
          }
        }

        return pipeline
      })
      .map(convertPipelineToString)

    setCookie(renamedPipelines)
  }

  return {
    storeRecentlyAccessedPipeline,
    updateRecentlyAccessedPipeline,
    deleteLegacyCookie,
    deleteOldestCookies,
    setCookie
  }
}
