import { useCallback } from 'react'

import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  useProjectInfo,
  type ProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type ComponentSummaryId } from '../useGetComponentSummaries'
import { type ComponentMetadataResponse } from './types'

export const getComponentMetadataQueryKey = (
  { projectId, branchId, agentId }: ProjectInfo,
  id: ComponentSummaryId
) => [projectId, branchId, queryKeys.componentMetadata, id, agentId]

const useComponentMetadataQueryDefinition = () => {
  const { getComponentMetadata } = useDesignerApi()
  const { data: agentDetails } = useAgentDetails()
  const projectInfo = useProjectInfo()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useCallback(
    (
      cisId?: ComponentSummaryId
    ): {
      enabled: boolean
      queryKey: string[]
      queryFn?: () => Promise<ComponentMetadataResponse>
    } => {
      if (!cisId) {
        return {
          enabled: false,
          queryKey: ['pending-component-metadata-query']
        }
      }

      return {
        enabled: !!agentVersion,
        queryKey: getComponentMetadataQueryKey(projectInfo, cisId),
        queryFn: async () => {
          const { data } = await getComponentMetadata(cisId, agentVersion)
          return data
        }
      }
    },
    [agentVersion, getComponentMetadata, projectInfo]
  )
}

export const useGetBulkComponentMetadata = (cisIds: ComponentSummaryId[]) => {
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useQueries({
    queries: cisIds.map(getComponentMetadataQueryDefinition)
  })
}

export const useFetchComponentMetadata = () => {
  const queryClient = useQueryClient()
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useCallback(
    async (componentId: string) => {
      return queryClient.fetchQuery(
        getComponentMetadataQueryDefinition(componentId)
      )
    },
    [getComponentMetadataQueryDefinition, queryClient]
  )
}

export const useGetComponentMetadata = (cisId?: ComponentSummaryId) => {
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useQuery(getComponentMetadataQueryDefinition(cisId))
}
