import clamp from 'lodash/clamp'

import { type FloatingPopoverPosition } from '../types'

interface DragManagerProps {
  updatePosition: (position: FloatingPopoverPosition) => void
  containerRef: React.RefObject<HTMLElement>
  dragBoundaryRef?: React.RefObject<HTMLElement>
}

export class DragManager {
  private readonly updatePosition: (position: FloatingPopoverPosition) => void
  private readonly containerRef: React.RefObject<HTMLElement>
  private readonly dragBoundaryRef?: React.RefObject<HTMLElement>
  private dragElement?: HTMLElement
  private initialMousePosition: {
    mouseX: number
    mouseY: number
    left: number
    top: number
  } | null = null

  constructor({
    updatePosition,
    containerRef,
    dragBoundaryRef
  }: DragManagerProps) {
    this.updatePosition = updatePosition
    this.containerRef = containerRef
    this.dragBoundaryRef = dragBoundaryRef
  }

  startDrag(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    const boundingBox = this.containerRef.current?.getBoundingClientRect()
    if (!boundingBox) return

    this.initialMousePosition = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      left: boundingBox.left,
      top: boundingBox.top
    }
    this.dragElement = e.currentTarget
    this.dragElement.style.cursor = 'grabbing'
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  private readonly handleMouseMove = (e: MouseEvent) => {
    if (!this.initialMousePosition) return

    let newLeft =
      e.clientX -
      this.initialMousePosition.mouseX +
      this.initialMousePosition.left
    let newTop =
      e.clientY -
      this.initialMousePosition.mouseY +
      this.initialMousePosition.top

    const elementBox = this.containerRef.current?.getBoundingClientRect()
    if (elementBox && this.dragBoundaryRef?.current) {
      const boundaryBox = this.dragBoundaryRef.current.getBoundingClientRect()
      newLeft = clamp(
        newLeft,
        boundaryBox.left,
        boundaryBox.right - elementBox.width
      )
      newTop = clamp(
        newTop,
        boundaryBox.top,
        boundaryBox.bottom - elementBox.height
      )
    }

    this.updatePosition({ left: newLeft, top: newTop })
  }

  private readonly handleMouseUp = () => {
    if (this.dragElement) {
      this.dragElement.style.cursor = 'grab'
    }

    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
    this.initialMousePosition = null
  }
}
