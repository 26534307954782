import { type TFunction } from 'react-i18next'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import type { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { type AutoCompleteItemStringId } from 'components/AutoComplete/types'

import { createGridElementCollection } from 'job-lib/builders/createGridElementsCollection'

import { translateColumnName } from '../../GridEditor/components/Grid/utils'

export const getGridElements = (
  columnNames: string[],
  columnValues: Record<string, AutoCompleteItemStringId>,
  selectedGridVariable: AutoCompleteItemStringId
) => {
  const values: string[] = columnNames.map((column) => {
    return columnValues[column]?.id ?? ''
  })

  return createGridElementCollection(selectedGridVariable.id, values)
}

export const getDefaultColumnName = (t: TFunction) => {
  const columnName = t('parameterEditor.MULTI_OPTION_SELECTOR.gridColumn')
  return [columnName]
}

export const getDefaultColumnNames = (
  parameterDatatype: ParameterDataType,
  editorColumns: EditorColumn[],
  t: TFunction
) => {
  switch (parameterDatatype) {
    case ParameterDataType.GRID:
      return editorColumns.map((col) => translateColumnName(col, t))
    default:
      return getDefaultColumnName(t)
  }
}
