import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const AzureSQLConfig: ComponentLayout = {
  componentId: 'modular-azure-sql-input-v0',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'serverName'
          },
          {
            dplId: 'databaseName'
          },
          {
            dplId: 'connection'
          },
          {
            dplId: 'connectionOptions'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'azure-sql-input-v0',
            omitParameters: [
              'serverName',
              'databaseName',
              'connection',
              'connectionOptions'
            ]
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null)
    ]
  }
}
