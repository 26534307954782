import { invert } from 'lodash'

import {
  OrchestrationComponentConnectors,
  OrchestrationJobConnectors
} from '../store/jobSlice/job.types'
import { OutputPortType } from '../types/Components'
import {
  type ComponentInstanceId,
  type Connector,
  type ConnectorCollection,
  type ConnectorId,
  type OrchestrationJob,
  type TransformationJob
} from '../types/Job'

export const componentOutputConnectorFields: Record<
  OutputPortType,
  OrchestrationComponentConnectors
> = {
  [OutputPortType.SUCCESS]: OrchestrationComponentConnectors.SUCCESS,
  [OutputPortType.FAILURE]: OrchestrationComponentConnectors.FAILURE,
  [OutputPortType.UNCONDITIONAL]:
    OrchestrationComponentConnectors.UNCONDITIONAL,
  [OutputPortType.TRUE]: OrchestrationComponentConnectors.OUTPUT_TRUE,
  [OutputPortType.FALSE]: OrchestrationComponentConnectors.OUTPUT_FALSE,
  [OutputPortType.ITERATION]: OrchestrationComponentConnectors.OUTPUT_ITERATION
}

export const jobConnectorFields: Record<
  OutputPortType,
  OrchestrationJobConnectors
> = {
  [OutputPortType.SUCCESS]: OrchestrationJobConnectors.SUCCESS,
  [OutputPortType.FAILURE]: OrchestrationJobConnectors.FAILURE,
  [OutputPortType.UNCONDITIONAL]: OrchestrationJobConnectors.UNCONDITIONAL,
  [OutputPortType.TRUE]: OrchestrationJobConnectors.TRUE,
  [OutputPortType.FALSE]: OrchestrationJobConnectors.FALSE,
  [OutputPortType.ITERATION]: OrchestrationJobConnectors.ITERATION
}

export const inputConnectorFields: Record<
  OutputPortType,
  OrchestrationComponentConnectors
> = {
  [OutputPortType.ITERATION]: OrchestrationComponentConnectors.INPUT_ITERATION,
  [OutputPortType.SUCCESS]: OrchestrationComponentConnectors.INPUT,
  [OutputPortType.FAILURE]: OrchestrationComponentConnectors.INPUT,
  [OutputPortType.UNCONDITIONAL]: OrchestrationComponentConnectors.INPUT,
  [OutputPortType.TRUE]: OrchestrationComponentConnectors.INPUT,
  [OutputPortType.FALSE]: OrchestrationComponentConnectors.INPUT
}

export interface ConnectorWithType extends Connector {
  outputType: OutputPortType
}

export const isOrchestrationJob = (
  job?: OrchestrationJob | TransformationJob | null
): job is OrchestrationJob => !!job && 'unconditionalConnectors' in job

export const isTransformationJob = (
  job?: OrchestrationJob | TransformationJob | null
): job is TransformationJob => !!job && 'connectors' in job

export const getAllJobConnections = (
  job:
    | Pick<OrchestrationJob, OrchestrationJobConnectors>
    | Pick<TransformationJob, 'connectors'>
): ConnectorWithType[] => {
  const allConnectors: ConnectorWithType[] = []

  if (!!job && 'unconditionalConnectors' in job) {
    Object.entries(jobConnectorFields).forEach(([portId, jobField]) => {
      if (!job[jobField]) return
      Object.entries(job[jobField]).forEach(([connectorId, connector]) =>
        allConnectors.push({
          ...connector,
          outputType: portId as OutputPortType
        })
      )
    })
  } else {
    Object.entries(job.connectors).forEach(([connectorId, connector]) => {
      allConnectors.push({
        ...connector,
        // all transformation connections are UNCONDITIONAL
        outputType: OutputPortType.UNCONDITIONAL
      })
    })
  }

  return allConnectors
}

export const getJobConnectors = (
  job: OrchestrationJob | TransformationJob | null
): Record<string, ConnectorCollection> => {
  if (isOrchestrationJob(job)) {
    return {
      successConnectors: job.successConnectors,
      failureConnectors: job.failureConnectors,
      unconditionalConnectors: job.unconditionalConnectors,
      trueConnectors: job.trueConnectors,
      falseConnectors: job.falseConnectors,
      iterationConnectors: job.iterationConnectors
    }
  } else if (isTransformationJob(job)) {
    return {
      connectors: job.connectors
    }
  } else {
    return {}
  }
}

export const makeConnectorId = (
  job: OrchestrationJob | TransformationJob | null
) => {
  const currentConnectors = getJobConnectors(job)
  const currentConnectorIds = Object.values(currentConnectors).flatMap(
    (connectorType) => Object.keys(connectorType).map((key) => parseInt(key))
  )
  if (currentConnectorIds.length === 0) {
    return 1
  }
  return Math.max(...currentConnectorIds) + 1
}

export const getAllNonIterationOutputConnections = (
  job: OrchestrationJob,
  componentId: ComponentInstanceId
): ConnectorWithType[] => {
  const allComponentConnectors: ConnectorWithType[] = []
  const component = job.components[componentId]

  if (!component) {
    return []
  }
  Object.entries(componentOutputConnectorFields)
    .filter(([key]) => {
      return key !== OutputPortType.ITERATION
    })
    .forEach(([portId, componentProperty]) => {
      component[componentProperty].forEach((connectorId) => {
        const connector =
          job[jobConnectorFields[portId as OutputPortType]][connectorId]
        allComponentConnectors.push({
          ...connector,
          outputType: portId as OutputPortType
        })
      })
    })

  return allComponentConnectors
}

export const isConnectorWithType = (
  x?: ConnectorWithType | null | undefined
): x is ConnectorWithType => x != null

export const getOrchestrationOutputConnector = (
  job: OrchestrationJob,
  id: ConnectorId,
  type: OutputPortType
): Connector | null => {
  const connectors = job[jobConnectorFields[type]]

  return connectors[id] || null
}

const outputPortLookup = invert(jobConnectorFields) as Record<
  OrchestrationJobConnectors,
  OutputPortType
>

export const getOutputPortTypeFromPipelineField = (
  connectorType: OrchestrationJobConnectors
) => {
  return outputPortLookup[connectorType]
}
