import { useEffect, useRef, type FC, type PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

const Portal: FC<PropsWithChildren> = ({ children }) => {
  const element = useRef<HTMLElement | null>(null)

  const mountRoot = document.getElementById(
    'component-parameters-editor-container'
  )

  if (!element.current) {
    element.current = document.createElement('div')
  }

  useEffect(() => {
    if (mountRoot && element.current) {
      mountRoot.appendChild(element.current)
    }

    return () => {
      if (mountRoot && element.current && mountRoot.contains(element.current)) {
        mountRoot.removeChild(element.current)
      }
    }
  }, [mountRoot])

  return createPortal(children, mountRoot as HTMLElement)
}

export default Portal
