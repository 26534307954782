import { useContext, useState, type FunctionComponent } from 'react'
import ReactFlow, { Background, BackgroundVariant } from 'reactflow'

import { EtlCanvasNodeType } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import usePopOverContext from 'components/PopOverMenu/usePopOverContext'
import { useUserPreference } from 'components/UserPreferenceProvider/useUserPreference'

import { useFlags } from 'hooks/useFlags'
import { useUpdateContextNodeSelection } from 'hooks/useUpdateContextNodeSelection'

import { JobType } from 'job-lib/types/JobType'

import { trackKeyboardShortcut } from 'utils/heap'

import { CanvasContext } from '../../CanvasProvider'
import { AddNextComponent } from '../AddNextComponent/AddNextComponent'
import { FlowConnectionLine } from './components/FlowConnectionLine'
import { FlowEdge } from './components/FlowEdge'
import { FlowMarkers } from './components/FlowMarkers'
import classes from './FlowCanvas.module.scss'
import { useCanvasHandlers } from './hooks/useCanvasHandlers'
import { useInitialiseCanvasHandlers } from './hooks/useInitialiseCanvasHandlers'
import { useSyncedCanvasModel } from './hooks/useSyncedCanvasModel'
import { EtlNode } from './nodes/EtlNode'
import { IteratorNode } from './nodes/IteratorNode'
import { NoteNode } from './nodes/NoteNode'
import { type FlowCanvasProps } from './types'

export const successFailNode = 'success-fail-node'
export const unconditionalNode = 'unconditional-node'
export const connectorNode = 'connect-node'
export const conditionalNode = 'conditional-node'

const EDGE_TYPES = {
  default: FlowEdge
}

const NODE_TYPES = {
  [EtlCanvasNodeType.NODE]: EtlNode,
  [EtlCanvasNodeType.ITERATOR]: IteratorNode,
  [EtlCanvasNodeType.NOTE]: NoteNode
}

const DELETE_KEYCODES = ['Backspace', 'Delete']

export const calcZoom = (canvasWidth: number): number => {
  const minWidth = 500
  const maxWidth = 1500
  const minZoom = 0.75
  const maxZoom = 1.5

  if (canvasWidth <= minWidth) return minZoom
  if (canvasWidth >= maxWidth) return maxZoom

  const zoom =
    minZoom +
    ((canvasWidth - minWidth) / (maxWidth - minWidth)) * (maxZoom - minZoom)

  return Math.round(zoom * 20) / 20
}

const FlowCanvas: FunctionComponent<FlowCanvasProps> = ({ job, jobType }) => {
  const { canvasModel, syncCanvasModel } = useSyncedCanvasModel(job)
  useInitialiseCanvasHandlers(job)
  const { nodes, edges } = canvasModel
  const canvasHandlers = useCanvasHandlers(nodes, syncCanvasModel)
  const { onContextMenu } = usePopOverContext()
  const { updateNodeSelection } = useUpdateContextNodeSelection()
  const { userPreference } = useUserPreference()
  const { enableRelativeStartPositionAndZoom } = useFlags()

  const currentNodes = [...nodes.values()]

  const [initialNodes] = useState(currentNodes)

  const canvasWrapperRef = useContext(CanvasContext)
  const getDefaultViewport = () => {
    if (
      canvasWrapperRef &&
      enableRelativeStartPositionAndZoom &&
      jobType === JobType.Orchestration &&
      currentNodes.length === 1
    ) {
      const canvasBounds = canvasWrapperRef.getBoundingClientRect()
      const shiftX = Math.round(canvasBounds.width / 7.5)
      const shiftY = Math.round(canvasBounds.height / 2)
      const zoomLevel = calcZoom(canvasBounds.width)
      return { x: shiftX, y: shiftY, zoom: zoomLevel }
    }

    return {
      x: 0,
      y: 0,
      zoom: 1
    }
  }

  const defaultViewport = getDefaultViewport()

  const shouldFitView =
    jobType === JobType.Transformation ||
    !enableRelativeStartPositionAndZoom ||
    (enableRelativeStartPositionAndZoom &&
      initialNodes.length > 1 &&
      currentNodes.length > 1)

  if (enableRelativeStartPositionAndZoom && !canvasWrapperRef) {
    return null
  }

  return (
    <ReactFlow
      defaultNodes={currentNodes}
      defaultEdges={[...edges.values()]}
      edgeTypes={EDGE_TYPES}
      nodeTypes={NODE_TYPES}
      connectionLineComponent={FlowConnectionLine}
      connectionRadius={0}
      elevateEdgesOnSelect
      selectNodesOnDrag={false}
      minZoom={0.25}
      maxZoom={4}
      zoomOnDoubleClick={false}
      deleteKeyCode={DELETE_KEYCODES}
      defaultViewport={defaultViewport}
      fitView={shouldFitView}
      fitViewOptions={{
        padding: 0.1,
        minZoom: 0.25,
        maxZoom: 1
      }}
      proOptions={{ hideAttribution: true }}
      snapToGrid={userPreference.snapToGridEnabled}
      snapGrid={[10, 10]}
      selectionKeyCode={['Meta', 'Shift']}
      multiSelectionKeyCode={['Meta', 'Shift']}
      nodeDragThreshold={1}
      onNodeContextMenu={(e, node) => {
        updateNodeSelection(node)
        onContextMenu(e, node)
      }}
      {...canvasHandlers}
      // these are here rather than in canvas handlers as canvasHandlers may be reused in the toolbar implementation
      // and the events should only be used for tracking deletes via keyboard shortcuts
      // as opposed to clicking the toolbars
      onNodesDelete={(ns) => {
        trackKeyboardShortcut('canvas', 'deleteNodes')
        canvasHandlers.onNodesDelete(ns)
      }}
      onEdgesDelete={(es) => {
        trackKeyboardShortcut('canvas', 'deleteEdge')
        canvasHandlers.onEdgesDelete(es)
      }}
    >
      {nodes.size === 0 && (
        <div className={classes.FlowContainer}>
          <AddNextComponent />
        </div>
      )}

      <FlowMarkers />

      <Background
        // id gets appended to the default id of the background in react flow, by default this is
        // pattern-1-undefined, this now becomes pattern-1-canvas-background
        id="-canvas-background"
        variant={BackgroundVariant.Dots}
        size={4}
        color="#ddd"
      />
    </ReactFlow>
  )
}

export { FlowCanvas }
