import { type ComponentLayout } from './types'

export const SnowflakeVectorUpsertConfig: ComponentLayout = {
  componentId: 'snowflake-vector-upsert',
  wizard: {
    steps: [
      {
        stepId: 'source',
        displayName: 'Source',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'source'
          },
          {
            dplId: 'writeMode'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'source',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'embeddingGenerator'
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: null,
        parameters: [
          {
            dplId: 'destination'
          }
        ]
      }
    ]
  }
}
