import { Icon } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getProjectExplorerLink } from 'utils/getProjectExplorerLink'

import classes from '../ProjectToolbar.module.scss'
import ProjectNameSelector from './ProjectNameSelector'
import { WarehouseIcon } from './WarehouseIcon'

const ProjectExplorerLink = () => {
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { projectId } = useProjectInfo()

  return (
    <div className={classes.ProjectExplorer__LinkContainer}>
      <a
        className={classes.ProjectExplorer__ManageLink}
        data-testid="project-toolbar-projectExplorer"
        href={getProjectExplorerLink(projectExplorerOrigin, projectId)}
        rel="noopener norefserrer noreferrer"
      >
        <span className={classes.ProjectExplorer__IconWrapper}>
          <Icon.ChevronFilled />
        </span>

        <WarehouseIcon />

        <ProjectNameSelector />
      </a>
    </div>
  )
}

export default ProjectExplorerLink
