import { useMemo, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import classnames from 'classnames'

import { useComponentSummaries } from 'api/hooks/useGetComponentSummaries'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { Loading } from 'components/Loading/Loading'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { JobType } from 'job-lib/types/JobType'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'

import classes from './Canvas.module.scss'
import { CanvasProvider } from './CanvasProvider'
import { FlowCanvas } from './components/FlowCanvas/FlowCanvas'
import { Toolbox } from './components/Toolbox/Toolbox'

const CanvasOverlay: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes.Canvas} data-testid="canvas-container">
    <div
      data-testid="canvas-overlay-container"
      className={classnames(classes.Canvas__Overlay, classes.Overlay)}
    >
      {children}
    </div>
  </div>
)

const useCanvasData = () => {
  const {
    pipelineSummary,
    isLoading: isPipelineSummaryLoading,
    isError: isPipelineSummaryError
  } = useActivePipelineSummary()
  const {
    job,
    isLoading: isWorkingCopyLoading,
    isError: isWorkingCopyError
  } = useFlaggedWorkingCopy()
  const {
    isLoading: isComponentSummariesLoading,
    isError: isComponentSummariesError
  } = useComponentSummaries()

  // temporary mapping code; remove when the entire Canvas module
  // can be converted to use FileSystemProvider native types
  const mappedPipelineSummary: JobSummary | null = useMemo(
    () =>
      pipelineSummary
        ? {
            jobId: pipelineSummary.name,
            name: pipelineSummary.fileName,
            folder: pipelineSummary.folder,
            runtimeName: pipelineSummary.runtimeName,
            type:
              pipelineSummary.type === FileType.ORCHESTRATION_PIPELINE
                ? JobType.Orchestration
                : JobType.Transformation,
            status: pipelineSummary.status
          }
        : null,
    [pipelineSummary]
  )

  return {
    pipelineSummary: mappedPipelineSummary,
    pipeline: job,
    isLoading:
      isPipelineSummaryLoading ||
      isWorkingCopyLoading ||
      isComponentSummariesLoading,
    isError:
      isPipelineSummaryError || isWorkingCopyError || isComponentSummariesError
  }
}

export const Canvas: FC = () => {
  const { t } = useTranslation()

  const { pipeline, pipelineSummary, isLoading, isError } = useCanvasData()

  if (isError) {
    return (
      <CanvasOverlay>
        <Icon.Error className={classes.Overlay__Icon} />

        <Typography className={classes.Overlay__Text}>
          {t('translation:canvas.noJobInfo')}
        </Typography>
      </CanvasOverlay>
    )
  }

  if (isLoading || !pipeline || !pipelineSummary) {
    return (
      <CanvasOverlay>
        <Loading />
      </CanvasOverlay>
    )
  }

  return (
    <CanvasProvider job={pipeline} jobSummary={pipelineSummary}>
      <div data-testid="canvas-toolbox" className={classes.Canvas__Toolbox}>
        <Toolbox />
      </div>
      <FlowCanvas job={pipeline} jobType={pipelineSummary.type} />
    </CanvasProvider>
  )
}
