import React from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

interface Props {
  refetch?: () => void
}

const SchemaRowContextMenu = ({ refetch }: Props) => {
  const { t } = useTranslation()

  return (
    <PopOverSection>
      <PopOverItem
        key="refetch"
        data-testid="schemas-action-refetch"
        onClick={(e) => {
          e.stopPropagation()
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          refetch && refetch()
        }}
      >
        {t('sideBar.contextMenu.refresh')}
      </PopOverItem>
    </PopOverSection>
  )
}

export { SchemaRowContextMenu }
