import './styles/base.scss'

import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { AuthProviderEnvironment } from '@matillion/hub-client'

import { MOCK_USER } from '__mocks__/@matillion/hub-client'

import App from './App'
import config from './config'
import Providers from './Providers'

import './locales/i18n'
import './styles/utilities.scss'

import { Route, Routes } from 'react-router-dom'

import {
  GIT_OAUTH_REDIRECT,
  GitInterstitialAuthRedirectPage
} from '@matillion/git-component-library'

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  })
  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    allowedTracingUrls: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ],
    // default settings from setting up the project in datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogRum.startSessionReplayRecording()
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

if (
  config.authEnvironment === AuthProviderEnvironment.test &&
  !('Cypress' in window)
) {
  window.AuthProvider = { mockProfile: MOCK_USER }
}

if (process.env.REACT_APP_USE_MOCKS === 'true' && !('Cypress' in window)) {
  import('msw').then(({ setupWorker }) => {
    import('api/mswHandlers').then(({ mswHandlers }) => {
      const worker = setupWorker(...mswHandlers)

      worker.start({
        quiet: Boolean(process.env.REACT_APP_MSW_LOG_OUTPUT)
      })

      worker.printHandlers()
    })
  })
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

container.setAttribute('data-app-environment', config.environment)
container.setAttribute('data-app-version', config.version)

root.render(
  <Providers>
    <Routes>
      <Route
        path={GIT_OAUTH_REDIRECT}
        element={<GitInterstitialAuthRedirectPage />}
      />
      <Route path="*" element={<App />} />
    </Routes>
  </Providers>
)
