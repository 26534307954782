import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  LoadingSpinner,
  Tooltip,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'

import useGetProject from 'api/hooks/useGetProject/useGetProject'

import classes from '../ProjectToolbar.module.scss'
import { ToolbarSelectorType } from './ToolbarSelector'

const ProjectNameSelector = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetProject()

  const tooltip = t('translation:projectToolbox.type', {
    context: ToolbarSelectorType.PROJECT,
    name: data?.name
  })

  return (
    <Tooltip content={tooltip} align="start">
      <div
        className={classnames(classes.Selector__Container, {
          [classes.Selector__Error]: isError
        })}
        data-testid="project-toolbar-projectName"
      >
        {isLoading && (
          <LoadingSpinner data-testid="project-toolbar-projectName-loading-spinner" />
        )}

        {!isLoading && (
          <Typography
            format="bcs"
            className={classnames(
              classes.Selector__TextWrapper,
              classes.Selector__ExtraMargin
            )}
          >
            {isError ? t('projectToolbox.projectExplorer') : data?.name}
          </Typography>
        )}
      </div>
    </Tooltip>
  )
}

export default ProjectNameSelector
