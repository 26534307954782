import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './Loading.module.scss'

interface LoadingProps {
  className?: string
  'data-testid'?: string
  text?: string
}

export const Loading: FC<LoadingProps> = ({ className, text, ...props }) => {
  const { t } = useTranslation()

  return (
    <div {...props} className={classNames([classes.Loading, className])}>
      <LoadingSpinner className={classes.Loading__Icon} />
      <Typography
        className={classes.Loading__Text}
        data-testid="loading-spinner"
      >
        {text ?? t('statuses.loading')}
      </Typography>
    </div>
  )
}
