import { useApiClientContext } from '../useApiClientContext/useApiClientContext'
import { type CreateProjectVariablesRequest } from '../useCreateProjectVariables/types'
import { type GetProjectResponse } from '../useGetProject/types'
import { type GetProjectEnvironmentResponse } from '../useGetProjectEnvironmentInfo/types'
import {
  type GETSecretReferencesQueryStringParams,
  type GETSecretReferencesResponse
} from '../useGetSecretReferences/types'
import { type ListProjectVariablesResponse } from '../useListProjectVariables/types'
import { type UpdateProjectVariablesRequest } from '../useUpdateProjectVariables/types'

export const useProjectExplorerApi = () => {
  const { projectExplorerClient: client } = useApiClientContext()

  const getProject = async (projectId: string) => {
    return client.get<GetProjectResponse>(`/v1/projects/${projectId}`)
  }

  const getProjectEnvironmentInfo = async (
    projectId: string,
    environmentId: string
  ) => {
    return client.get<GetProjectEnvironmentResponse>(
      `/v1/projects/${projectId}/environments/${environmentId}`
    )
  }

  const createProjectVariables = async (
    projectId: string,
    createProjectVariablesRequest: CreateProjectVariablesRequest
  ) => {
    return client.post(
      `/v1/projects/${projectId}/variables`,
      createProjectVariablesRequest
    )
  }

  const deleteProjectVariables = async (
    projectId: string,
    variableId: string
  ) => {
    return client.delete(`/v1/projects/${projectId}/variables/${variableId}`)
  }

  const listProjectVariables = async (projectId: string) => {
    return client.get<ListProjectVariablesResponse>(
      `/v1/projects/${projectId}/variables`
    )
  }

  const updateProjectVariables = async (
    projectId: string,
    variableId: string,
    updateProjectVariablesRequest: UpdateProjectVariablesRequest
  ) => {
    return client.patch(
      `/v1/projects/${projectId}/variables/${variableId}`,
      updateProjectVariablesRequest
    )
  }

  const getSecretReferences = async (
    params: GETSecretReferencesQueryStringParams
  ) => {
    const { projectId, secretType, providers } = params
    const path = '/v1/gateway/srs/secret-references'

    return client.get<GETSecretReferencesResponse>(path, {
      params: {
        projectId: encodeURIComponent(projectId),
        secretType: Array.isArray(secretType)
          ? secretType.map(encodeURIComponent).join(',')
          : encodeURIComponent(secretType),
        providers: providers?.map(encodeURIComponent).join(',')
      }
    })
  }

  return {
    getProject,
    getProjectEnvironmentInfo,
    createProjectVariables,
    deleteProjectVariables,
    listProjectVariables,
    updateProjectVariables,
    getSecretReferences
  }
}
