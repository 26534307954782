import { type FC } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './SelectionFraction.module.scss'

interface SelectionFractionProps {
  divisor: number
  dividend: number
}

export const SelectionFraction: FC<SelectionFractionProps> = ({
  divisor,
  dividend
}) => {
  return (
    <Typography format="bcs" className={classes.Fraction}>
      {`${divisor}/${dividend}`}
    </Typography>
  )
}
