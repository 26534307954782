import produce from 'immer'
import { v4 as uuid } from 'uuid'

import { Fields, type FormState } from 'modules/ManageVariables/types'

import {
  type EditDataGridAction,
  type OnAddRowDataGridAction,
  type OnChangeSelectAllDataGridAction,
  type OnChangeSelectedDataGridAction,
  type OnNextStepAction,
  type OnRemoveRowDataGridAction,
  type OnTextModeCloseAction as OnTextModeExitAction
} from './formReducer'
import { validateGrid } from './validateGrid'

export const editDataGridField = (
  state: FormState,
  action: EditDataGridAction
): FormState => {
  const { field, rowId, newValue, colIndex } = action

  return produce(state, (draft) => {
    const rows = state[field].value.rows
    const rowIndex = rows.findIndex((r) => r.id === rowId)
    const columnRows = draft[action.field].value.rows[rowIndex]
    columnRows.columns[colIndex] = newValue
    validateGrid(field, draft)
  })
}

export const onChangeSelectedDataGrid = (
  state: FormState,
  action: OnChangeSelectedDataGridAction
): FormState => {
  const { field, rowId, isSelected } = action

  return produce(state, (draft) => {
    if (isSelected) {
      draft[field].value.selectedRows.push(rowId)
    } else {
      draft[field].value.selectedRows = state[field].value.selectedRows.filter(
        (id) => id !== rowId
      )
    }
  })
}

export const onChangeSelectAllDataGrid = (
  state: FormState,
  action: OnChangeSelectAllDataGridAction
): FormState => {
  const { field, selectAllChecked } = action
  return produce(state, (draft) => {
    if (selectAllChecked) {
      draft[field].value.selectedRows = draft[field].value.rows.map(
        (row) => row.id
      )
    } else {
      draft[field].value.selectedRows = []
    }
  })
}

const createNewRow = (
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
) => {
  const id = uuid()
  if (field === 'COLUMN_GRID') {
    return {
      id,
      columns: ['', '']
    }
  } else {
    return {
      id,
      columns: []
    }
  }
}

export const onAddRowDataGrid = (
  state: FormState,
  action: OnAddRowDataGridAction
): FormState => {
  const { field } = action
  return produce(state, (draft) => {
    const newRow = createNewRow(field)
    draft[field].value.rows.push(newRow)

    validateGrid(field, draft)
  })
}

export const onRemoveRowDataGrid = (
  state: FormState,
  action: OnRemoveRowDataGridAction
): FormState => {
  const { field } = action
  return produce(state, (draft) => {
    const { rows, selectedRows } = draft[field].value

    draft[field].value.rows = rows.filter(
      (row) => !selectedRows.includes(row.id)
    )
    draft[field].value.selectedRows = []
    validateGrid(field, draft)
  })
}

export const onNextStep = (
  state: FormState,
  action: OnNextStepAction
): FormState => {
  return produce(state, (draft) => {
    if (action.newStep === 3) {
      validateGrid(Fields.DEFAULT_VALUE_GRID, draft)
    }
  })
}

export const onExitTextMode = (
  state: FormState,
  action: OnTextModeExitAction
): FormState => {
  const { field, newRows } = action

  return produce(state, (draft) => {
    draft[field].value.rows = newRows.map((row) => {
      const newRow = createNewRow(field)
      newRow.columns = row

      return newRow
    })

    validateGrid(field, draft)
  })
}
