import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'
import { DemoDataModal } from 'file-editors/canvas/modules/Canvas/components/AddNextComponent/components/DemoDataModal'

import { useStackingModal } from 'components/StackingModalProvider'

import classes from './ComponentDemoDataPaintedDoor.module.scss'

interface ComponentDemoDataPaintedDoorProps {
  componentId: string
}

export const ComponentDemoDataPaintedDoor = ({
  componentId
}: ComponentDemoDataPaintedDoorProps) => {
  const { pushNewModalContent } = useStackingModal()
  const { t } = useTranslation()
  const { track: trackLaunchDarklyMetric } = useLDClient()

  const handleDemoClick = useCallback(() => {
    trackLaunchDarklyMetric('designer-use-demo-data-cta-click')
    pushNewModalContent(
      {
        ariaLabelledBy: 'demo data painted door'
      },
      <DemoDataModal
        componentId={componentId}
        onSelectComponent={async () => {}}
      />
    )
  }, [componentId, pushNewModalContent, trackLaunchDarklyMetric])

  return (
    <div className={classes.ComponentDemoDataPaintedDoor}>
      <Typography format="bcl" weight="bold">
        {t('componentProperties.demoDataExperiment.title')}
      </Typography>
      <Button
        size="sm"
        alt="secondary"
        fontWeight="normal"
        onClick={handleDemoClick}
        data-testid="use-demo-data-btn"
        data-tracker-id="designer--components-panel--use-demo-data-cta"
      >
        <Typography
          format="bcs"
          as="span"
          className={classes.ComponentDemoDataPaintedDoor__ButtonText}
        >
          {t('componentProperties.demoDataExperiment.buttonText')}
        </Typography>
      </Button>
    </div>
  )
}
