import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type ComponentActivationStatus } from 'job-lib/types/Components'

import { type JobState } from '../../job.types'

interface SkipComponentPayload {
  componentInstanceId: number
  activationStatus: ComponentActivationStatus
}

export const skipComponent: CaseReducer<
  JobState,
  PayloadAction<SkipComponentPayload>
> = (state, { payload: { componentInstanceId, activationStatus } }) => {
  const { jobType, job } = state
  if (jobType === null || job === null) {
    return
  }

  if (!state.job.components[componentInstanceId]) {
    return
  }
  state.job.components[componentInstanceId].activationStatus = activationStatus
}
