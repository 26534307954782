import { useMemo } from 'react'

import { useGetActivity } from 'api/hooks/useGetActivity/useGetActivity'

import { mapActivityItemToRunTaskStatus } from 'utils/mapActivityItemToRunTaskStatus'

import { useProjectInfo } from '../useProjectInfo/useProjectInfo'
import { useWorkspaceId } from '../useWorkspaceId'

const ONE_HOUR_TIMEFRAME = 'PT1H'

export const useWorkspaceActivity = () => {
  const { projectId } = useProjectInfo()
  const workspaceId = useWorkspaceId()
  const completed = useGetActivity(
    projectId,
    workspaceId,
    ['SUCCESS', 'FAILED', 'CANCELLED', 'SKIPPED'],
    ONE_HOUR_TIMEFRAME
  )
  const running = useGetActivity(projectId, workspaceId, [
    'RUNNING',
    'CANCELLING'
  ])
  const tasks = useMemo(() => {
    return [...(completed.data ?? []), ...(running.data ?? [])].map((item) =>
      mapActivityItemToRunTaskStatus(item)
    )
  }, [completed.data, running.data])
  return {
    tasks,
    completed,
    running
  }
}
