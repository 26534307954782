import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import classes from './LoadingPanel.module.scss'

interface LoadingPanelProps {
  fetchCompletion: number
}

export const LoadingPanel: FC<LoadingPanelProps> = ({ fetchCompletion }) => {
  const { t } = useTranslation()

  const formatOptions: Intl.NumberFormatOptions = {
    style: 'percent'
  }

  const percentFormatter = Intl.NumberFormat(navigator.language, formatOptions)

  const completionPct = percentFormatter.format(fetchCompletion)

  return (
    <div className={classes.Spinner}>
      <LoadingSpinner />
      <Typography format="bcm">
        {`${t('multiTableConfig.applyToAll.fetchingData')} ${completionPct}`}
      </Typography>
    </div>
  )
}
