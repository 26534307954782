import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  JobConversionRecord,
  MetlConversionIssues,
  VariableConversionRecord
} from 'api/hooks/useMigrationMetlJob'

import { getJobType, getRecordName } from './getRecordDetails'
import getRecordPlan from './getRecordPlan'

interface ActionableRecordProps {
  conversionRecords: JobConversionRecord[] | VariableConversionRecord[]
  entityType: string
  conversionType: string
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  marginTop: 10,
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const tableRowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const tableCellStyle: React.CSSProperties = {
  textAlign: 'left',
  fontSize: '1rem',
  padding: 7,
  flexWrap: 'wrap',
  width: '25%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const reasonTableCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  width: '65%'
}

const typeTableCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  width: '12%'
}

const listItemStyle: React.CSSProperties = {
  marginTop: 5,
  marginBottom: 5,
  paddingLeft: 20
}

const BulletPointList = (
  conversionIssues: MetlConversionIssues[],
  type: string
) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <>
      {conversionIssues.map((issue, index) => (
        <ul key={`${issue}-${index}`} style={listItemStyle}>
          <li>{t(`conversionIssues.${issue}.${type}`)}</li>
        </ul>
      ))}
    </>
  )
}

const getRecordActionDetails = (
  entityType: string,
  record: JobConversionRecord | VariableConversionRecord
) => {
  let conversionIssues: MetlConversionIssues[]
  if (entityType === 'job') {
    conversionIssues = getRecordPlan(record as JobConversionRecord)
  } else {
    conversionIssues = (record as VariableConversionRecord).metlConversionIssues
  }
  const action = BulletPointList(conversionIssues, 'action')
  const reason = BulletPointList(conversionIssues, 'reason')
  return { action, reason }
}

const ActionableRecordRowDetails = (
  record: JobConversionRecord | VariableConversionRecord,
  entityType: string,
  conversionType: string,
  typeStyles: React.CSSProperties,
  reasonStyles: React.CSSProperties
) => {
  const { action, reason } = getRecordActionDetails(entityType, record)
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <>
      <td style={tableCellStyle}>{getRecordName(record.name)}</td>
      <td style={typeStyles}>
        {entityType === 'job'
          ? getJobType(record as JobConversionRecord)
          : t(`variables.${(record as VariableConversionRecord).type}`)}
      </td>
      <td style={reasonStyles}>{reason}</td>
      {conversionType !== 'notConverted' && (
        <td style={tableCellStyle}>{action}</td>
      )}
    </>
  )
}

const ActionableRecordDetails = ({
  conversionRecords,
  entityType,
  conversionType
}: ActionableRecordProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })
  let actionHeader = ''
  if (conversionType === 'autoconverted') {
    actionHeader = 'Auto-conversion plan'
  } else if (conversionType === 'manualRefactor') {
    actionHeader = 'Manual Action'
  }
  let reasonStyles = tableCellStyle
  let typeStyles = tableCellStyle
  if (conversionType === 'notConverted') {
    reasonStyles = reasonTableCellStyle
    typeStyles = typeTableCellStyle
  }

  return (
    <table style={tableStyle}>
      <tbody>
        <tr style={tableRowStyle}>
          <th style={tableCellStyle}>{t(`${entityType}DetailsHeader`)}</th>
          <th style={typeStyles}>{t(`${entityType}TypeHeader`)}</th>
          <th style={reasonStyles}>Reason</th>
          {conversionType !== 'notConverted' && (
            <th style={tableCellStyle}>{actionHeader}</th>
          )}
        </tr>
        {conversionRecords.map((record, index) => (
          <tr key={`${record.name}-${index}`} style={tableRowStyle}>
            {ActionableRecordRowDetails(
              record,
              entityType,
              conversionType,
              typeStyles,
              reasonStyles
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ActionableRecordDetails
