import type { FC, PropsWithChildren } from 'react'

import classes from './Guidelines.module.scss'

export interface GuidelineSvgProps {
  left: number
}

const GuidelineSvg: FC<PropsWithChildren<GuidelineSvgProps>> = ({
  children,
  left
}) => {
  return (
    <svg
      width="30"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        left
      }}
      className={classes.Guideline}
    >
      {children}
    </svg>
  )
}

export default GuidelineSvg
