import { type FC } from 'react'

export const AddIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#2D8825" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13V17H13V13H17V11H13V7H11V11H7V13H11Z"
      fill="white"
    />
  </svg>
)
