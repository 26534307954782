import { type FC } from 'react'

import { Skeleton } from '@matillion/component-library'
import classNames from 'classnames'

import { Cell, Row } from '../components/PipelineTable'
import classes from './StepRow.module.scss'

interface StepRowProps {
  rowClassName?: string
  cellClassName?: string
}

interface SkeletonStepProps {
  id: number
  width: '100%'
  height: 25
}

const NUMBER_OF_CELLS = 6

const steps: SkeletonStepProps[] = Array.from(
  { length: NUMBER_OF_CELLS },
  (_, index) => ({
    id: index,
    width: '100%',
    height: 25
  })
)

const SkeletonStepRow: FC<StepRowProps> = ({ rowClassName, cellClassName }) => {
  return (
    <Row
      className={classNames(rowClassName, classes.StepRow)}
      data-testid="skeleton-step-row"
    >
      {steps.map((step) => (
        <Cell key={`skeleton-step-cell-${step.id}`} className={cellClassName}>
          <Skeleton width={step.width} height={step.height} />
        </Cell>
      ))}
    </Row>
  )
}

export default SkeletonStepRow
