export interface Filterable {
  searchTerm?: string
  expanded?: boolean
}

export interface SimpleRefetchRef {
  refetch: () => void
}

export interface ContextMenuItem {
  id: string
  label: string
  onClick: (() => void) | undefined
}
export const getMenuItems = (enableNewFloatingFiles: boolean): MenuItem[] => {
  /* istanbul ignore if */ // this is purely temporary
  if (enableNewFloatingFiles) {
    return [...menuItems, 'Floating Files']
  }
  return [...menuItems]
}
export const menuItems = [
  'Files',
  'Components',
  'Schemas',
  'Variables',
  'Copilot'
] as const

export type MenuItem = (typeof menuItems)[number] | 'Floating Files'
