/* eslint-disable @typescript-eslint/no-dynamic-delete */

import type { Pipeline } from 'types/Pipeline'

import { deleteDanglingLinks } from '../deleteComponents/deleteComponents'
import { updateComponentPosition } from '../updateComponentPosition/updateComponentPosition'

export const detachIterator = (state: Pipeline) => (iteratorName: string) => {
  if (!Object.hasOwn(state.pipeline.components, iteratorName)) {
    return
  }

  const iterators: string[] = []

  const getIteratorStack = (name: string) => {
    const iterationTarget = state.pipeline.components[name].iterationTarget
    const targetComponent = state.pipeline.components[iterationTarget as string]

    if (iterationTarget === undefined || targetComponent === undefined) {
      return
    }

    iterators.push(name)
    getIteratorStack(iterationTarget)
  }

  getIteratorStack(iteratorName)

  if (iterators.length !== 0) {
    // We already check that the iterators array is not empty
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bottomMostIterator = iterators.at(-1)!
    const topMostIterator = iterators[0]
    const baseComponent =
      state.pipeline.components[bottomMostIterator].iterationTarget

    const { x, y } = state.design.components[topMostIterator].position

    const newBaseComponentX = x
    const newBaseComponentY = y + iterators.length * 20

    iterators.forEach((name, index) => {
      delete state.pipeline.components[name].iterationTarget
      delete state.pipeline.components[name].transitions
      deleteDanglingLinks(state, iteratorName)

      updateComponentPosition(state)({
        name,
        position: {
          x: newBaseComponentX + 60 * index,
          y: newBaseComponentY - 60
        }
      })
    })

    /* istanbul ignore else */
    if (baseComponent) {
      updateComponentPosition(state)({
        name: baseComponent,
        position: {
          x: newBaseComponentX,
          y: newBaseComponentY
        }
      })
    }
  }
}
