import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Popover, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { useFlags } from 'hooks/useFlags'

import { type ComponentInstance } from 'job-lib/types/Job'

import ComponentToolbarButtons from './components/ComponentToolbarButtons/ComponentToolbarButtons'
import { ValidationStatus } from './components/ValidationStatus/ValidationStatus'
import classes from './ComponentToolbar.module.scss'

interface ComponentToolbarProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
}

export const ComponentToolbar: FC<ComponentToolbarProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId
}: ComponentToolbarProps) => {
  const { t } = useTranslation()
  const { enableSampleDataComponentConfigExperiment } = useFlags()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const closePopup = () => {
    setIsPopoverOpen(false)
  }

  const togglePopup = () => {
    setIsPopoverOpen((previousState) => !previousState)
  }

  return (
    <div
      className={classNames(classes.ComponentToolbar, {
        [classes.ExperimentalComponentToolbar]:
          enableSampleDataComponentConfigExperiment,
        [classes.ExperimentalComponentToolbar__PopoverOpen]:
          enableSampleDataComponentConfigExperiment && isPopoverOpen
      })}
    >
      <div className={classes.ComponentToolbar__Row}>
        <div className={classes.ComponentToolbar__Row__Overview}>
          <ValidationStatus
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
          />
        </div>
        <ComponentToolbarButtons
          className={classes.ExperimentalComponentToolbar__RowContainer}
          componentSummaryId={componentSummaryId}
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          sampleButton={
            <>
              {enableSampleDataComponentConfigExperiment && (
                <Typography
                  className={
                    classes.ExperimentalComponentToolbar__Buttons__SampleText
                  }
                >
                  {t('componentProperties.toolbar.actions.sampleDataButton')}
                </Typography>
              )}
            </>
          }
        />
        {enableSampleDataComponentConfigExperiment && (
          <Popover
            isOpen={isPopoverOpen}
            onClickOutside={closePopup}
            anchor={
              <button
                data-testid="sample-data-popup-toggle"
                type="button"
                className={
                  classes.ExperimentalComponentToolbar__Popover__Anchor
                }
                onClick={togglePopup}
              >
                <Icon.Ellipsis />
              </button>
            }
            position={'bottom'}
            align={'start'}
            containerClassName={
              classes.ExperimentalComponentToolbar__Popover__ContentContainer
            }
          >
            <ComponentToolbarButtons
              buttonClickCallback={closePopup}
              componentSummaryId={componentSummaryId}
              componentInstance={componentInstance}
              componentMetadata={componentMetadata}
              sampleButton={
                <Typography data-testid="popup-sample-data-label">
                  {t('componentProperties.toolbar.actions.sampleDataButton')}
                </Typography>
              }
              helpButton={<Typography>Help</Typography>}
            />
          </Popover>
        )}
      </div>
    </div>
  )
}
