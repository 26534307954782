import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { COMPLETED_STATES } from 'api/hooks/useGetRunTasks'
import {
  HistoryTaskType,
  State,
  type HistoryTaskStatus,
  type RunTaskStatus,
  type TaskStatusId
} from 'api/hooks/useGetRunTasks/types'

import { useFlags } from 'hooks/useFlags'

import { customFormatDate } from 'modules/observability/PipelineRunExplorer/utils/statsFormatters'

import { completedTimeOrInterimState } from './history.utilities'
import RowCTA from './RowCTA'
import RowTypography, {
  type Props as RowTypographyProps
} from './RowTypography'
import classes from './TaskHistory.module.scss'
import {
  TaskStatusRow,
  type Props as TaskStatusRowProps
} from './TaskHistoryTaskStatus'

interface TaskRow {
  task: TaskStatusRowProps
  environment: RowTypographyProps & { taskKey: 'environment' }
  job: RowTypographyProps & { taskKey: 'job' }
  queued: RowTypographyProps & { taskKey: 'queued' }
  completed: RowTypographyProps & { taskKey: 'completed' }
  actions?: {
    taskKey: 'actions'
    id: TaskStatusId
    showOpenTaskDetailsCTA: boolean
    isExecuting: boolean
    isCancelling: boolean
    onNavigate: (id: string) => void
    onCancel: (force: boolean) => void
  }
}

type Row = TaskRow & DataGridRow

type Columns = DataGridColumnProps<Row> & { key: keyof TaskRow }

export interface Props {
  tasks: HistoryTaskStatus[]
  navigateToTask: (taskId: string) => void
  cancelTask: (flowInstanceId: string, force: boolean) => void
}

export const TaskHistory: FC<Props> = ({
  tasks,
  navigateToTask,
  cancelTask
}) => {
  const { t } = useTranslation()
  const { enableTaskDetailsPolling } = useFlags()

  const columns: Columns[] = [
    {
      key: 'task',
      title: t('taskHistory.tableTitleTask'),
      as: TaskStatusRow
    },
    {
      key: 'environment',
      title: t('taskHistory.tableTitleEnvironment'),
      as: RowTypography
    },
    {
      key: 'job',
      title: t('taskHistory.tableTitleJob'),
      as: RowTypography
    },
    {
      key: 'queued',
      title: t('taskHistory.tableTitleQueued'),
      as: RowTypography
    },
    {
      key: 'completed',
      title: t('taskHistory.tableTitleCompleted'),
      as: RowTypography
    },
    {
      key: 'actions',
      title: '',
      'aria-label': t('taskHistory.taskShowDetails'),
      as: RowCTA,
      className: enableTaskDetailsPolling ? undefined : classes.TaskHistory__CTA
    }
  ]

  function isRunTaskStatus(task: HistoryTaskStatus): task is RunTaskStatus {
    return (task as RunTaskStatus).flowInstanceId !== undefined
  }

  const rows: Row[] = tasks.map((task) => ({
    id: task.id,
    task: {
      state: task.state,
      taskKey: 'task',
      taskType: (task as RunTaskStatus).flowInstanceId
        ? HistoryTaskType.TASK_RUN
        : HistoryTaskType.TASK_VALIDATE
    },
    environment: { children: task.environmentName, taskKey: 'environment' },
    job: { children: task.jobName, taskKey: 'job' },
    queued: {
      children: customFormatDate(task?.enqueuedTime),
      taskKey: 'queued'
    },
    completed: {
      children: completedTimeOrInterimState(task, t),
      taskKey: 'completed'
    },
    actions: {
      taskKey: 'actions',
      id: task.id,
      showOpenTaskDetailsCTA:
        !enableTaskDetailsPolling &&
        COMPLETED_STATES.includes(task.state) &&
        isRunTaskStatus(task),
      isExecuting: task.state === State.Running && isRunTaskStatus(task),
      isCancelling: task.state === State.Cancelling && isRunTaskStatus(task),
      onNavigate: () => {
        navigateToTask(task.id.toString())
      },
      onCancel: (force: boolean) => {
        cancelTask((task as RunTaskStatus).flowInstanceId, force)
      }
    },
    isClickable: enableTaskDetailsPolling && isRunTaskStatus(task),
    onClick: () => {
      navigateToTask(task.id.toString())
    }
  }))

  return (
    <DataGrid
      data-testid="task-history"
      columns={columns}
      rows={rows}
      className={classes.TaskHistoryGrid__BottomPanel}
      compact
    />
  )
}
