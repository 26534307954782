import {
  type Element,
  type ElementCollection,
  type ValueCollection
} from 'job-lib/types/Parameters'

export const elementsUseGridVariable = (elements: ElementCollection) => {
  return elements?.[1]?.values?.[1]?.type === 'GRID'
}

export const getGridVariableName = (elements: ElementCollection) => {
  return elements[1]?.values[1].value ?? ''
}

export const getGridVariableValues = (elements: ElementCollection) => {
  return Object.values(elements[2]?.values ?? []).map(({ value }) => {
    return value
  })
}

export const isElement = (
  value: unknown
): value is Element<ValueCollection> => {
  if (
    value === null ||
    value === undefined ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }

  const el = value as Element<ValueCollection>
  return el.slot !== undefined && el.values !== undefined
}

export const isElementCollection = (
  value: unknown
): value is ElementCollection => {
  if (
    value === null ||
    value === undefined ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }

  const values: Array<Element<ValueCollection>> = Object.values(value)
  return values.length === 0 || isElement(values[0])
}
