import { type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import classes from './BannerContent.module.scss'

const { Warning } = Icon

const BannerContent: FC<PropsWithChildren> = ({ children }) => {
  const { hasPermission, isLoading } = useProjectPermission(
    'update_pipelines',
    PermissionType.PROJECT
  )
  const { t } = useTranslation('translation', {
    keyPrefix: 'projectToolbox.permissionBanner'
  })

  const shouldShowBanner = !hasPermission && !isLoading

  const tooltipContents = `${t('warningTitle')} - ${t('warningContent')}`

  return (
    <div
      className={classNames(
        classes.BannerContainer,
        shouldShowBanner && classes.BannerContainer__Warning
      )}
    >
      {shouldShowBanner && (
        <div className={classes.ReadOnly} data-testid="permission-banner">
          <Warning />
          <Tooltip content={tooltipContents}>
            <div className={classes.TooltipTextContainer}>
              <Typography weight="bold" format="mc">
                {t('warningTitle')}
              </Typography>

              <Typography
                format="mc"
                className={classes.BannerContainer__Spacer}
              >
                {'-'}
              </Typography>

              <Typography
                format="mc"
                className={classes.BannerContainer__Warning__Content}
              >
                {t('warningContent')}
              </Typography>
            </div>
          </Tooltip>
        </div>
      )}

      <div className={classes.BannerContainer__Children}>{children}</div>
    </div>
  )
}

export default BannerContent
