import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type ConversionRecord,
  type JobConversionInfo,
  type JobConversionRecord,
  type JobConversionReport,
  type MetlConversionType,
  type VariableConversionInfo,
  type VariableConversionRecord,
  type VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import useConvertVariableScope from './useConvertVariableScope'

export interface UseMetlConversionReportProps {
  fileName?: string
  jobs?: JobConversionInfo
  variables?: VariableConversionInfo
}

export interface UseMetlConversionReportResponse {
  metlJobConversionTextReport: string
  jobConversionReport: JobConversionReport
  variableConversionReport: VariableConversionReport
}

const useMetlConversionReport = ({
  fileName,
  jobs,
  variables
}: UseMetlConversionReportProps): UseMetlConversionReportResponse => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.modal.results'
  })

  const { convertVariableScope } = useConvertVariableScope()

  const computeConversionType = useCallback(
    (conversionTypes: MetlConversionType[]) => {
      if (conversionTypes.length > 0) {
        if (conversionTypes.some((type) => type === 'MANUAL_REFACTOR')) {
          return 'MANUAL_REFACTOR'
        } else if (conversionTypes.some((type) => type === 'AUTOCONVERTED')) {
          return 'AUTOCONVERTED'
        }
      }
      return 'CONVERTED'
    },
    []
  )

  const computeRecordsConversionType = useCallback(
    (records: ConversionRecord[]) => {
      const recordConversionTypes: MetlConversionType[] = records.map(
        ({ conversionType }) => conversionType
      )

      return computeConversionType(recordConversionTypes)
    },
    [computeConversionType]
  )

  const buildJobConversionType = useCallback(
    (jobToConvert: JobConversionRecord) => {
      const recordConversionTypes: MetlConversionType[] = [
        computeRecordsConversionType(jobToConvert.variablesConverted),
        computeRecordsConversionType(jobToConvert.variablesNotConverted),
        computeRecordsConversionType(jobToConvert.gridVariablesConverted),
        computeRecordsConversionType(jobToConvert.gridVariablesNotConverted),
        computeRecordsConversionType(jobToConvert.components)
      ]

      return computeConversionType(recordConversionTypes)
    },
    [computeConversionType, computeRecordsConversionType]
  )

  const buildJobsToConvertReport = useCallback(
    (toConvert: JobConversionRecord[], conversionType: string) => {
      const reportSectionTitle = t(`jobs.${conversionType}.title`)
      let report = `\n\n${reportSectionTitle} (${toConvert.length})\n`

      if (toConvert.length > 0) {
        toConvert.forEach(({ name: jobName, variablesNotConverted }, i) => {
          report += `\t- ${jobName}`

          if (variablesNotConverted.length > 0) {
            report += `\n\t\t${t('jobs.variablesNotConverted.title')}\n`

            variablesNotConverted.forEach(({ name, type, scope }, j) => {
              report += `\t\t- ${name} (${type}) (${convertVariableScope(
                scope
              )})`

              if (j < variablesNotConverted.length - 1) {
                report += '\n'
              }
            })
          }

          if (i < toConvert.length - 1) {
            report += '\n'
          }
        })
      } else {
        report += t(`jobs.${conversionType}.no-data`)
      }

      return report
    },
    [convertVariableScope, t]
  )

  const buildJobsNotToConvertReport = useCallback(
    (notToConvert: string[]) => {
      let report = `\n\n${t('jobs.notConverted.title')} (${
        notToConvert.length
      })\n`

      notToConvert.forEach((name, i) => {
        report += `\t- ${name}`

        if (i < notToConvert.length - 1) {
          report += '\n'
        }
      })

      return report
    },
    [t]
  )

  const buildVariablesToConvertReport = useCallback(
    (toConvert: VariableConversionRecord[], conversionType: string) => {
      const reportSectionTitle = t(`variables.${conversionType}.title`)
      let report = `\n\n${reportSectionTitle} (${toConvert.length})\n`

      if (toConvert.length > 0) {
        toConvert.forEach(({ name, type, scope }, i) => {
          report += `\t- ${name} (${type}) (${convertVariableScope(scope)})`
          if (i < toConvert.length - 1) {
            report += '\n'
          }
        })
      } else {
        report += t(`variables.${conversionType}.no-data`)
      }

      return report
    },
    [convertVariableScope, t]
  )

  const buildVariablesNotToConvertReport = useCallback(
    (notToConvert: VariableConversionRecord[]) => {
      let report = `\n\n${t('variables.notConverted.title')} (${
        notToConvert.length
      })\n`

      notToConvert.forEach(({ name, type, scope }, i) => {
        report += `\t- ${name} (${type}) (${convertVariableScope(scope)})`
        if (i < notToConvert.length - 1) {
          report += '\n'
        }
      })

      return report
    },
    [convertVariableScope, t]
  )

  const getJobsWithConversionType = useCallback(
    (
      jobsToConvert: JobConversionRecord[],
      conversionType: MetlConversionType
    ) => {
      const jobsConversionTypes: JobConversionRecord[] = []
      if (jobsToConvert.length > 0) {
        jobsToConvert.forEach((jobToConvert) => {
          if (buildJobConversionType(jobToConvert) === conversionType) {
            jobsConversionTypes.push(jobToConvert)
          }
        })
      }
      return jobsConversionTypes
    },
    [buildJobConversionType]
  )

  const getVariablesWithConversionType = useCallback(
    (
      variablesToConvert: VariableConversionRecord[],
      conversionType: MetlConversionType
    ) => {
      if (variablesToConvert.length > 0) {
        return variablesToConvert.filter(
          (variableToConvert) =>
            variableToConvert.conversionType === conversionType
        )
      }
      return []
    },
    []
  )

  return useMemo(() => {
    if (!jobs || !variables || !fileName) {
      return {
        metlJobConversionTextReport: t('report.error'),
        jobConversionReport: {
          convertedJobs: [],
          autoconvertedJobs: [],
          refactorJobs: []
        },
        variableConversionReport: {
          convertedVariables: [],
          autoconvertedVariables: [],
          refactorVariables: []
        }
      }
    }

    let report = `${t('report.title')}\n`
    report += `${t('report.subtitle', { fileName })}`

    const convertedJobs: JobConversionRecord[] = getJobsWithConversionType(
      jobs.converted,
      'CONVERTED'
    )
    const autoconvertedJobs: JobConversionRecord[] = getJobsWithConversionType(
      jobs.converted,
      'AUTOCONVERTED'
    )
    const refactorJobs: JobConversionRecord[] = getJobsWithConversionType(
      jobs.converted,
      'MANUAL_REFACTOR'
    )

    report += buildJobsToConvertReport(convertedJobs, 'converted')
    report += buildJobsToConvertReport(autoconvertedJobs, 'autoconverted')
    report += buildJobsToConvertReport(refactorJobs, 'manualRefactor')

    if (jobs.notConverted.length > 0) {
      report += buildJobsNotToConvertReport(jobs.notConverted)
    }

    const convertedVariables: VariableConversionRecord[] =
      getVariablesWithConversionType(variables.converted, 'CONVERTED')
    const autoconvertedVariables: VariableConversionRecord[] =
      getVariablesWithConversionType(variables.converted, 'AUTOCONVERTED')
    const refactorVariables: VariableConversionRecord[] =
      getVariablesWithConversionType(variables.converted, 'MANUAL_REFACTOR')

    report += buildVariablesToConvertReport(convertedVariables, 'converted')
    report += buildVariablesToConvertReport(
      autoconvertedVariables,
      'autoconverted'
    )
    report += buildVariablesToConvertReport(refactorVariables, 'manualRefactor')

    if (variables.notConverted.length > 0) {
      report += buildVariablesNotToConvertReport(variables.notConverted)
    }

    const response: UseMetlConversionReportResponse = {
      metlJobConversionTextReport: report,
      jobConversionReport: {
        convertedJobs,
        autoconvertedJobs,
        refactorJobs
      },
      variableConversionReport: {
        convertedVariables,
        autoconvertedVariables,
        refactorVariables
      }
    }

    return response
  }, [
    buildJobsNotToConvertReport,
    buildJobsToConvertReport,
    buildVariablesNotToConvertReport,
    buildVariablesToConvertReport,
    fileName,
    getJobsWithConversionType,
    getVariablesWithConversionType,
    jobs,
    t,
    variables
  ])
}

export default useMetlConversionReport
