import {
  useEffect,
  useMemo,
  useState,
  type FocusEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Field,
  type AutoCompleteItem,
  type AutoCompleteItemId
} from '@matillion/component-library'
import classnames from 'classnames'
import { debounce } from 'lodash'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { AutoComplete } from 'components/AutoComplete'
import { type AutoCompleteProps } from 'components/AutoComplete/types'

import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'
import { type Parameter } from 'hooks/useParameterOptions/useParameterOptionsHelpers'

import classes from './DynamicDropdown.module.scss'

export interface DynamicDropdownProps {
  inputId: string
  value: string
  componentMetadata: ComponentMetadata
  componentSummaryId: string
  parameter?: Parameter
  parameterName: string
  placeholder?: string
  className?: string
  inputClassName?: string
  onEdit: (selectedValue: string) => unknown
  onBlur?: (selectedValue: string) => unknown
  onEditorError?: (error?: ProblemDetails) => void
  hasError?: boolean
  errorText?: string
  displayLabel?: boolean
  optionalLabelText?: string
  scrollableContainerSelector?: string
  compact?: boolean
  staticLabelColor?: boolean
}

export const DynamicDropdown: FunctionComponent<DynamicDropdownProps> = ({
  inputId,
  value,
  componentMetadata,
  componentSummaryId,
  parameter,
  parameterName,
  placeholder,
  className,
  inputClassName,
  onEdit,
  onBlur,
  onEditorError,
  hasError,
  errorText,
  displayLabel,
  optionalLabelText,
  scrollableContainerSelector,
  compact,
  staticLabelColor = false
}) => {
  const { t } = useTranslation()
  const [dropdownValue, setDropdownValue] = useState<string>(value)
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false)
  useEffect(() => {
    setDropdownValue(value)
  }, [value])
  const {
    isLoading,
    isRefetching,
    data: items,
    isError,
    error
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData: componentMetadata,
    parameter,
    isEnabled: isQueryEnabled
  })

  const resourceIdParts = parameter?.resourceID?.split('/') ?? ['']

  const parameterValue = (option: string): string =>
    t(`${resourceIdParts[resourceIdParts.length - 1]}.${option}`, {
      ns: 'parametersValues',
      defaultValue: option
    })

  const selectedValue = {
    id: dropdownValue,
    name: parameterValue(dropdownValue)
  }

  const isLoadingOptions = isQueryEnabled && (isLoading || isRefetching)

  const getAvailableItems = (): AutoCompleteItemId[] => {
    if (isLoadingOptions) {
      return []
    }
    if (items.length > 0 && items[0].options && items[0].options.length > 0) {
      return items[0].options.map((option) => {
        return {
          id: option.toString(),
          name: parameterValue(option.toString())
        }
      })
    }

    return [
      {
        id: 'no_options_available',
        name: t('parameterEditor.DROPDOWN.noItemsFound', {
          parameter: parameterName
        }),
        disabled: true
      }
    ]
  }

  const handleEdit = useMemo(
    () =>
      debounce((updatedValue: string) => {
        onEdit(updatedValue)
      }, 500),
    [onEdit]
  )

  const handleChange = (selected: { target: AutoCompleteItem }) => {
    const selectedVal = (selected.target.value?.id as string) ?? ''
    setDropdownValue(selectedVal)
    handleEdit(selectedVal)
  }

  const handleClick = () => {
    setIsQueryEnabled(true)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsQueryEnabled(false)
    if (onBlur) {
      const selectedVal = e.target.value.trim()
      onBlur(selectedVal)
    }
  }

  useEffect(() => {
    onEditorError?.(isError && error ? error : undefined)
  }, [isError, error, onEditorError])

  const props: AutoCompleteProps = {
    allowFreetext: true,
    className: classnames(className, {
      [classes['DynamicDropdown--isLoading']]: isLoadingOptions
    }),
    'data-testid': `dynamic-dropdown-${inputId}`,
    'aria-label': parameterName,
    placeholder,
    availableItems: getAvailableItems(),
    inputId,
    inputClassName,
    loading: isLoadingOptions,
    value: selectedValue,
    onClick: handleClick,
    onChange: handleChange,
    onBlur: handleBlur,
    optionalLabelText,
    scrollableContainerSelectors: scrollableContainerSelector
      ? [scrollableContainerSelector]
      : undefined
  }

  return displayLabel ? (
    <Field
      title={parameterName}
      inputComponent={AutoComplete}
      hasError={hasError}
      errorText={errorText}
      compact={compact}
      staticLabelColor={staticLabelColor}
      {...props}
    />
  ) : (
    <AutoComplete {...props} />
  )
}
