import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  DataGrid,
  Typography,
  type DataGridColumnProps
} from '@matillion/component-library'

import { DataGridAddRemoveButtons } from 'components/DataGridAddRemoveButtons/DataGridAddRemoveButtons'

import {
  ReducerActions,
  type Fields,
  type GridError,
  type GridVariableDataGridRow
} from 'modules/ManageVariables/types'

import {
  type OnAddRowDataGridAction,
  type OnChangeSelectAllDataGridAction,
  type OnChangeSelectedDataGridAction,
  type OnRemoveRowDataGridAction,
  type ReducerAction
} from '../../../Form/formReducer/formReducer'
import {
  columnHeaderTranslations,
  gridColumnNameErrors
} from '../../../Form/gridTranslations'
import classes from './GridVariableDataGrid.module.scss'

interface GridVariableDataGridProps {
  rows: GridVariableDataGridRow[]
  selectedRows: Array<string | number>
  dispatch: Dispatch<ReducerAction>
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
  columns: Array<DataGridColumnProps<GridVariableDataGridRow>>
  errors: GridError[]
}

export const GridVariableDataGrid = ({
  field,
  dispatch,
  rows,
  selectedRows,
  columns,
  errors
}: GridVariableDataGridProps) => {
  const isSelectAll = selectedRows.length === rows.length && rows.length !== 0
  const { t } = useTranslation()
  const uniqueErrorMessages = new Set<string>(
    errors.map((ve) =>
      t(gridColumnNameErrors[ve.errorType], {
        field: t(columnHeaderTranslations[ve.columnName])
      })
    )
  )
  return (
    <div className={classes.GridStep}>
      {errors.length > 0 && (
        <Alert
          className={classes.GridAlert}
          type="error"
          message={
            <ul>
              {Array.from(uniqueErrorMessages).map((error) => {
                return (
                  <li className={classes.GridErrorList} key={error}>
                    <Typography
                      format="bcs"
                      className={classes.GridErrorDetail}
                    >
                      {error}
                    </Typography>
                  </li>
                )
              })}
            </ul>
          }
        />
      )}

      <DataGrid
        columns={columns}
        rows={rows}
        allSelectable={true}
        hasFixedHeader={true}
        isSelectable={true}
        className={classes.Grid}
        selectedRows={selectedRows}
        onSelectedChange={(rowId, isSelected) => {
          const action: OnChangeSelectedDataGridAction = {
            type: ReducerActions.ON_CHANGE_SELECTED_DATA_GRID,
            field,
            isSelected,
            rowId: rowId as string
          }
          dispatch(action)
        }}
        isSelectAll={isSelectAll}
        onSelectAllChange={(selectAllChecked: boolean) => {
          const action: OnChangeSelectAllDataGridAction = {
            type: ReducerActions.ON_CHANGE_SELECT_ALL_DATA_GRID,
            field,
            selectAllChecked
          }
          dispatch(action)
        }}
        compact
        rowClassName={classes.DataGridRow}
        data-testid={'grid-table'}
      />
      <div className={classes.GridFooter}>
        <DataGridAddRemoveButtons
          add={{
            disabled: false,
            onClick: () => {
              const action: OnAddRowDataGridAction = {
                type: ReducerActions.ON_ADD_ROW_DATA_GRID_ACTION,
                field
              }
              dispatch(action)
            }
          }}
          remove={{
            disabled: selectedRows.length === 0,
            onClick: () => {
              const action: OnRemoveRowDataGridAction = {
                type: ReducerActions.ON_REMOVE_ROW_DATA_GRID_ACTION,
                field
              }
              dispatch(action)
            }
          }}
        />
      </div>
    </div>
  )
}
