/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { type Pipeline, type Transition } from 'types/Pipeline'

export interface RenameComponentPayload {
  oldName: string
  newName: string
}

export const renameComponent =
  (state: Pipeline) =>
  ({ oldName, newName }: RenameComponentPayload) => {
    if (!state.pipeline.components[oldName]) {
      return state
    }

    const oldComponent = state.pipeline.components[oldName]
    const oldDesign = state.design.components[oldName]

    delete state.pipeline.components[oldName]
    delete state.design.components[oldName]

    state.pipeline.components[newName] = {
      ...oldComponent,
      parameters: {
        ...oldComponent.parameters,
        componentName: newName
      }
    }

    state.design.components[newName] = oldDesign

    Object.values(state.pipeline.components).forEach((component) => {
      if (component.sources?.includes(oldName)) {
        component.sources = [
          ...component.sources.filter((source) => source !== oldName),
          newName
        ]
      }

      if (component.transitions) {
        for (const transitionType in component.transitions) {
          const transition = transitionType as keyof typeof Transition

          if (component.transitions[transition]?.includes(oldName)) {
            component.transitions[transition] = [
              ...(component.transitions[transition] as Transition[]).filter(
                (source) => source !== oldName
              ),
              newName
            ]
          }
        }
      }

      if (component.iterationTarget === oldName) {
        component.iterationTarget = newName
      }
    })
  }
