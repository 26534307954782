import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import {
  type SampleRowCountRequest,
  type SampleRowCountRequestBody
} from './types'

export const useSampleRowCount = (jobType: JobType) => {
  const { projectId, agentId, environmentId } = useProjectInfo()
  const { sampleTransformationRowCount } = useDesignerApi()

  return useMutation(async ({ componentCache }: SampleRowCountRequest) => {
    const requestBody: SampleRowCountRequestBody = {
      projectId,
      agentId,
      environmentId
    }

    if (jobType === JobType.Orchestration) {
      // Row count sample endpoint for orchestration is not supported yet
      return undefined
    }

    if (jobType === JobType.Transformation) {
      requestBody.componentCache = componentCache

      try {
        const { data } = await sampleTransformationRowCount(requestBody)
        return data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err.response?.data
        }
        throw Error((err as Error).message)
      }
    }
  })
}
