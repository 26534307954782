import useEnvironment from '../effects/useEnvironment'

const HUB_PROJECT_ID = 'hub'

export function useAccountRequired() {
  const { hubUrl } = useEnvironment()

  const hubMetadata = window.__MICROVERSE__?.manifest[HUB_PROJECT_ID]
  const verticalHubMfe = Object.values(hubMetadata?.mfesByName ?? {}).find(
    (mfe) => !!mfe?.baseRouteName
  )

  if (verticalHubMfe?.baseRouteName) {
    return (
      location.pathname !== `/${verticalHubMfe.baseRouteName}` &&
      !location.pathname.startsWith(`/${verticalHubMfe.baseRouteName}/`) &&
      location.pathname !== '' &&
      location.pathname !== '/'
    )
  }

  return location.origin !== hubUrl
}

export function toAccountAuthScope(accountId?: string) {
  return accountId ? `org:${accountId}` : undefined
}
