import { useMemo, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  LoadingSpinner,
  Table as T,
  Tooltip,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'
import { type ParameterValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries/types'

import { IconButton } from 'components/IconButton/IconButton'

import { ComponentUiConfigKey } from 'config/componentUiConfigs'

import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'

import { isStructListParameterValue } from 'modules/core/WorkingCopyProvider/utils/parameters'

import { getComponentUiConfigValue } from 'utils/getComponentUiConfigValue'

import { type LoadStrategy } from '../../types'
import { getTableSelectionParameter } from '../../utils/getEditorParameter'
import { isTableConfigured } from '../../utils/isTableConfigured'
import { parseParameterValueToConfigData } from '../../utils/parseParameterValueToConfigData'
import classes from './MultiSelectParameterField.module.scss'

interface MultiTableParameterFieldProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  parameterName: string
  childProperties: ComponentParameter[]
  parameterValue?: ParameterValue
  loadStrategy?: LoadStrategy
  hasErrors: boolean
  isValid: boolean
  onClick: () => void
}

export const MultiTableParameterField: FC<MultiTableParameterFieldProps> = ({
  componentSummaryId,
  componentMetaData,
  childProperties,
  parameterName,
  parameterValue,
  loadStrategy,
  hasErrors,
  isValid,
  onClick
}) => {
  const isTableLookupEnabled =
    parameterValue !== undefined && isStructListParameterValue(parameterValue)

  const { t } = useTranslation()
  const { parameter } = getTableSelectionParameter(childProperties)

  const {
    isLoading: isParameterListLoading,
    isError: isParameterListError,
    data: parameterList
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: isTableLookupEnabled
  })

  const allTables = useMemo(() => {
    if (!isParameterListLoading && !isParameterListError && parameterList[0]) {
      return parameterList[0].options
    }
    return []
  }, [isParameterListError, isParameterListLoading, parameterList])

  const tableConfigs = parseParameterValueToConfigData(
    childProperties,
    parameterValue
  ).filter((tableConfig) => allTables?.includes(tableConfig.dataSource))

  const maxSelections = getComponentUiConfigValue(
    componentSummaryId,
    ComponentUiConfigKey.MULTI_TABLE_SELECTION_MAX_SELECTIONS
  )

  const getSelectedTablesLabel = () => {
    if (!allTables || allTables.length === 0) {
      return '-'
    }

    return t(
      maxSelections && maxSelections < allTables.length
        ? 'multiTableConfig.multiSelectField.tablesLabelWithLimit'
        : 'multiTableConfig.multiSelectField.tablesLabel',
      {
        selectedTablesNumber: tableConfigs.length,
        totalTables: allTables.length,
        maxSelections
      }
    )
  }

  const configuredTablesNumber = tableConfigs.filter((table) =>
    isTableConfigured(loadStrategy, table)
  ).length

  const getConfiguredTablesLabel = () => {
    if (!allTables || allTables.length === 0) {
      return '-'
    }

    return t('multiTableConfig.multiSelectField.tablesLabel', {
      selectedTablesNumber: configuredTablesNumber,
      totalTables: tableConfigs.length
    })
  }

  const hasUnconfigured =
    !!allTables &&
    allTables.length > 0 &&
    configuredTablesNumber < tableConfigs.length

  return (
    <div
      data-testid="multi-select-parameter-field"
      className={classes.MultiSelectParameterField}
    >
      <Typography
        weight="bold"
        format="bcs"
        className={classes.MultiSelectParameterField__Header}
      >
        {parameterName}
      </Typography>
      {isParameterListLoading && isTableLookupEnabled && (
        <div className={classes.MultiSelectParameterField__Spinner}>
          <LoadingSpinner />
        </div>
      )}
      {(!isParameterListLoading || !isTableLookupEnabled) && (
        <div className={classes.MultiSelectParameterField__DataTable}>
          <T.Table className={classes.MultiSelectParameterField__Table}>
            <T.TableHead>
              <T.TableRow
                className={classes.MultiSelectParameterField__TableRow}
              >
                <T.TableHeaderCell width="5">
                  <Typography format="bcs">
                    {t('multiTableConfig.multiSelectField.selectedTables')}
                  </Typography>
                </T.TableHeaderCell>
                <T.TableHeaderCell width="7" colSpan="2">
                  <Typography
                    data-testid="configured-tables-header"
                    format="bcs"
                  >
                    {t('multiTableConfig.multiSelectField.configured')}
                  </Typography>
                </T.TableHeaderCell>
              </T.TableRow>
            </T.TableHead>
            <T.TableBody>
              <T.TableRow
                className={classes.MultiSelectParameterField__TableRow}
              >
                <T.TableCell width="5">
                  <Typography format="bcs" data-testid="selected-tables">
                    {getSelectedTablesLabel()}
                  </Typography>
                </T.TableCell>
                <T.TableCell
                  className={classes.MultiSelectParameterField__Cell}
                  width="5"
                >
                  <Typography
                    className={classNames(
                      classes.MultiSelectParameterField__ConfiguredText,
                      {
                        [classes[
                          'MultiSelectParameterField__ConfiguredText--Error'
                        ]]: hasUnconfigured
                      }
                    )}
                    format="bcs"
                    data-testid="configured-tables"
                  >
                    {getConfiguredTablesLabel()}
                  </Typography>
                </T.TableCell>
                <T.TableCell
                  width="2"
                  className={classes.MultiSelectParameterField__Configure}
                >
                  {hasErrors && !isValid && (
                    <Tooltip
                      content={t(
                        'multiTableConfig.multiSelectField.containsErrors'
                      )}
                    >
                      <div
                        className={
                          classes.MultiSelectParameterField__ValidationStatusWrapper
                        }
                      >
                        <Icon.Error
                          className={
                            classes.MultiSelectParameterField__ValidationSatusIcon
                          }
                          data-testid={'multi-table-field-error-icon'}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {isValid && configuredTablesNumber > 0 && !hasErrors && (
                    <Tooltip
                      content={t('multiTableConfig.multiSelectField.validated')}
                    >
                      <div
                        className={
                          classes.MultiSelectParameterField__ValidationStatusWrapper
                        }
                      >
                        <Icon.Success
                          className={
                            classes.MultiSelectParameterField__ValidationSatusIcon
                          }
                          data-testid={'multi-table-field-success-icon'}
                        />
                      </div>
                    </Tooltip>
                  )}
                  <IconButton
                    className={
                      classes.MultiSelectParameterField__ConfigureButton
                    }
                    label="Configure"
                    onClick={onClick}
                    data-testid="open-multi-table-configuration"
                  >
                    <Icon.Cog />
                  </IconButton>
                </T.TableCell>
              </T.TableRow>
            </T.TableBody>
          </T.Table>
        </div>
      )}
    </div>
  )
}
