import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { useAvailableComponents } from 'hooks/useAvailableComponents'

import { OutputPortType } from 'job-lib/types/Components'

import { ComponentSummaryList } from 'modules/ComponentSummaries/components/ComponentSummaryList/ComponentSummaryList'

import classes from '../../AddNextComponent.module.scss'

interface AddComponentListProps {
  onSelectComponent: (id: ComponentSummaryId) => Promise<void>
}

const useAvailableNextComponents = () => {
  const { components, isLoading, isError } = useAvailableComponents()

  // Attachable components (which have both right-aligned outputs, and down-aligned iterator ports)
  // don't currently make much sense in the "add next component" flow; UX have asked us to filter
  // these components out of the list for now
  const filteredComponents = useMemo(() => {
    return components.filter(
      (component) =>
        !component.outputPorts.some(
          (value) => value.portId === OutputPortType.ITERATION
        )
    )
  }, [components])

  return { components: filteredComponents, isLoading, isError }
}

const AddComponentList = ({ onSelectComponent }: AddComponentListProps) => {
  const { t } = useTranslation()
  const { components, isLoading, isError } = useAvailableNextComponents()

  if (isLoading) {
    return <Loading className={classes.ComponentsListLoading} />
  }

  if (isError) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.networkError')}
      </EmptyPanel>
    )
  }

  return (
    <ComponentSummaryList
      isDraggable={false}
      componentSummaries={components}
      onSelectComponent={onSelectComponent}
    />
  )
}

export { AddComponentList }
