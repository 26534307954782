import { useMemo } from 'react'

import { type QueryClient } from '@tanstack/react-query'
import { cloneDeep, isEqual } from 'lodash'
import { PipelineType } from 'types/Pipeline'
import { temporal } from 'zundo'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'

import {
  type ComponentMetadata,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { type ProjectSummary } from 'api/hooks/useGetProject/types'

import config, { Environment } from 'config'

import { JobType } from 'job-lib/types/JobType'

import convertDPLPipelineToMETL from './effects/convertDPLPipelineToMETL'
import {
  generateComponentIdsByName,
  getMetlComponentIdsByName
} from './effects/useMetlConversionMetadata'
import * as mutations from './mutations'
import { type MappedPipelineMutations, type WorkingCopyStore } from './types'

export const DEFAULT_PIPELINE = {
  version: '0',
  type: PipelineType.Orchestration,
  pipeline: {
    components: {},
    variables: {}
  },
  design: {
    components: {},
    notes: {}
  }
}

export const EMPTY_METL_PIPELINE = {
  revision: 0,
  variables: {},
  grids: {},
  notes: {},
  components: {},
  failureConnectors: {},
  falseConnectors: {},
  iterationConnectors: {},
  successConnectors: {},
  trueConnectors: {},
  unconditionalConnectors: {}
}

const DEFAULT_METL_PIPELINE = {
  isLoading: false,
  isError: false,
  job: EMPTY_METL_PIPELINE,
  jobType: JobType.Orchestration
}

export const createWorkingCopyStore = (id: string, queryClient?: QueryClient) =>
  createStore<WorkingCopyStore>()(
    devtools(
      temporal(
        (set, get) => ({
          workingCopy: DEFAULT_PIPELINE,
          metlPipeline: DEFAULT_METL_PIPELINE,
          setMetlPipeline: (metlPipeline) => {
            set({ metlPipeline })
          },
          update: (mutator) => {
            const workingCopy = cloneDeep(get().workingCopy)

            const mappedMutations = Object.keys(mutations).reduce(
              (bindings, key) => {
                const mutationKey = key as keyof typeof mutations

                return {
                  ...bindings,
                  [mutationKey]: mutations[mutationKey](workingCopy)
                }
              },
              // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
              {} as MappedPipelineMutations
            )
            mutator(mappedMutations)

            set({ workingCopy })

            /**
             * Once we update the DPL pipeline we have to convert it to METL
             * and update the store with the new METL pipeline.
             *
             * We need component metadata for the conversion, but instead of
             * refetching it we access it from the queryClient cache.
             */

            const componentTypes = new Set(
              Object.values(workingCopy.pipeline.components).map(
                (component) => component.type
              )
            )

            /**
             * Get the component metadata from query cache,
             * filter out any metadata that doesn't belong to the components
             * in the working copy, and convert it to a map of componentId -> metadata.
             */
            // istanbul ignore next
            const componentMetadata:
              | Record<string, ComponentMetadata>
              | undefined = (
              queryClient?.getQueriesData<ComponentMetadataResponse>({
                predicate: (query) =>
                  query.queryKey.includes('componentMetadata')
              }) ?? []
            )
              // getQueriesData returns an array of [queryKey, metadata] tuples
              // query[0] is the full query key, query[1] is the metadata response which we need
              .map<ComponentMetadataResponse | undefined>((query) => query[1])
              .filter(
                (metadata): metadata is ComponentMetadataResponse =>
                  !!metadata && componentTypes.has(metadata?.componentId)
              )
              .reduce<Record<string, ComponentMetadata>>((acc, curr) => {
                acc[curr.componentId] = curr.metadata

                return acc
              }, {})

            if (
              !componentMetadata ||
              componentTypes.size !== Object.keys(componentMetadata).length
            ) {
              return
            }

            const warehouseType =
              queryClient?.getQueriesData<ProjectSummary>({
                queryKey: ['project']
              })?.[0]?.[1]?.warehouse ?? 'snowflake'

            const metlComponentIdsByName =
              getMetlComponentIdsByName(workingCopy) ??
              generateComponentIdsByName(workingCopy)

            const metlPipeline = convertDPLPipelineToMETL(workingCopy, {
              warehouseType,
              metlComponentIdsByName,
              componentMetadata
            })

            set({
              metlPipeline: {
                isLoading: false,
                isError: false,
                job: metlPipeline?.metlPipeline ?? null,
                jobType: metlPipeline?.pipelineType ?? null
              }
            })
          }
        }),
        {
          limit: 5,
          // scopes zundo to only store temporal states for the working copy
          partialize: (state) => {
            return { workingCopy: state.workingCopy }
          },
          wrapTemporal: (storeInitializer) =>
            persist(storeInitializer, {
              name: `pipeline-history-${id}`,
              storage: createJSONStorage(() => sessionStorage)
            }),
          equality: (a, b) => {
            return isEqual(a.workingCopy, b.workingCopy)
          }

          // TODO: implement diff using microdiff as in zundo docs
          // rather than storing full pipeline states
        }
      ),
      {
        name: `pipeline-store-${id}`,
        enabled: config.environment === Environment.dev
      }
    )
  )

/**
 * **This hook is only for internal use within the WorkingCopyProvider--if you want
 * access to the current pipeline, please call `useWorkingCopy` instead.**
 *
 * Creates a zustand store for the pipeline represented by the provided id.
 * If one has been created previously, a cached version will be returned.
 *
 * @private
 */
export const useWorkingCopyStore = (
  pipelineName: string,
  queryClient?: QueryClient
) =>
  useMemo(
    () => createWorkingCopyStore(pipelineName, queryClient),
    // We only want to create a new store when the pipeline name changes
    // not if the queryClient gets recreated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pipelineName]
  )
