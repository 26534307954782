import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { ReactComponent as ValidatingOrchestration } from 'assets/canvas/validating-orchestration.svg'
import { ReactComponent as ValidatingTransformation } from 'assets/canvas/validating-transformation.svg'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { ToolboxButton } from './ToolboxButton'

interface ValidateButtonProps {
  pipelineType: FileType
  context: {
    context: string
  }
}

export const ValidateButton: FC<ValidateButtonProps> = ({
  pipelineType,
  context
}) => {
  const { t } = useTranslation()
  const { setValidationEnabled, isValidatingJob } =
    useComponentValidationProvider()
  const [showInProgressSvg, setShowInProgressSvg] = useState(false)

  const ValidationInProgressIcon =
    pipelineType === FileType.TRANSFORMATION_PIPELINE
      ? ValidatingTransformation
      : ValidatingOrchestration

  const { hasPermission, isLoading } = useProjectPermission(
    'validate_pipelines',
    PermissionType.ENVIRONMENT
  )

  const triggerValidation = () => {
    setValidationEnabled()
    setShowInProgressSvg(true)
  }

  useEffect(() => {
    let timeout: number

    if (showInProgressSvg) {
      timeout = window.setTimeout(() => {
        setShowInProgressSvg(false)
      }, 1000)
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [showInProgressSvg])
  const buttonIcon = showInProgressSvg ? (
    <ValidationInProgressIcon title={t('statuses.loading')} />
  ) : (
    <Icon.ItemTickCircle />
  )
  const label = isValidatingJob
    ? t('statuses.validatingJob')
    : t('canvasToolbox.validateJob')
  const shortcut = isValidatingJob
    ? undefined
    : t('shortcuts.validateJob', context)
  const dataTestId = 'canvas-toolbox-validate-job'
  const disabled =
    isLoading || !hasPermission || isValidatingJob || showInProgressSvg

  return (
    <ToolboxButton
      buttonIcon={buttonIcon}
      text={t('canvasToolbox.buttons.validate')}
      onClick={triggerValidation}
      disabled={disabled}
      label={label}
      shortcut={shortcut}
      dataTestId={dataTestId}
    />
  )
}
