import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const ApiExtractV2Config: ComponentLayout = {
  componentId: 'modular-api-extract-input-v2',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'api-extract-input-v2',
            omitParameters: [
              'profile',
              'uriParams',
              'queryParams',
              'headerParams',
              'postBody',
              'logLevel',
              'pageLimit'
            ]
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'api-extract-input-v2',
            omitParameters: ['endpoint', 'connectionRef', 'logLevel']
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null),
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [{ dplId: 'logLevel' }]
      }
    ]
  }
}
