export const clamp = (
  value: number,
  lowerBound: number,
  upperBound: number
): number => {
  if (value < lowerBound) {
    return lowerBound
  }

  if (value > upperBound) {
    return upperBound
  }

  return value
}

export const isObject = (val: unknown): val is Record<string, unknown> =>
  Object.prototype.toString.call(val) === '[object Object]'

export const isArray = (val: unknown): val is unknown[] => Array.isArray(val)

export const isString = (val: unknown): val is string =>
  typeof val === 'string' || val instanceof String

export const isInteger = (val: unknown): val is number => Number.isInteger(val)
