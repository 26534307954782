import axios, { type AxiosError } from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type JobState } from 'job-lib/store/jobSlice/job.types'
import { JobType } from 'job-lib/types/JobType'

import { useValidationDependencies } from 'modules/core/ComponentValidation'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import {
  type ValidateComponentParsedResponse,
  type ValidateComponentRequest
} from './types'

interface UseValidateComponentProps {
  jobState: JobState
}

export const useValidateComponent = ({
  jobState
}: UseValidateComponentProps) => {
  const { environmentId, agentId, projectId } = useProjectInfo()
  const { job, jobType } = jobState
  const { validateComponent } = useDesignerApi()
  const getValidationDependencies = useValidationDependencies(job)

  return async ({ component, cache }: ValidateComponentRequest) => {
    if (component === undefined || job === null || jobType === null) {
      return
    }

    const transformationCache = { cache: cache ?? {} }
    const validationDependencies = await getValidationDependencies(
      component.implementationID,
      component.parameters
    )

    try {
      const { data } = await validateComponent(jobType, {
        component,
        agentId,
        environmentId,
        projectId,
        variables: job.variables,
        gridVariables: job.grids ?? {},
        ...(jobType === JobType.Transformation ? transformationCache : {}),
        dependencies: validationDependencies
      })

      const parsedData: ValidateComponentParsedResponse = {
        ...data,
        componentName:
          job.components[component.id].parameters[1].elements[1].values[1].value
      }

      return parsedData
    } catch (err) {
      const error = err as Error | AxiosError

      /* istanbul ignore else */
      if (axios.isAxiosError(error)) {
        throw error.response?.data
      }
    }
  }
}
