import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { isMacOs } from 'utils/isMacOs'

import { type ContextMenuSection } from '../CanvasContextMenu'

export const useHistorySection = (): ContextMenuSection => {
  const { undoManager } = useWorkingCopy()
  const canUndo = undoManager?.canUndo() ?? false
  const canRedo = undoManager?.canRedo() ?? false
  return {
    id: 'history',
    items: [
      {
        id: 'undo',
        disabled: !canUndo,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandZ_macOS'
          : 'translation:shortcuts.controlZ'
      },
      {
        id: 'redo',
        disabled: !canRedo,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandShiftZ_macOS'
          : 'translation:shortcuts.controlY'
      }
    ]
  }
}
