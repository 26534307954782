import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Alert, Button, Modal, Typography } from '@matillion/component-library'
import { useQueryClient } from '@tanstack/react-query'

import { useDeleteProjectVariables } from 'api/hooks/useDeleteProjectVariables/useDeleteProjectVariables'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'
import { queryKeys } from 'api/queryKeys'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'

import {
  type SelectedJobVariable,
  type SelectedProjectVariable
} from 'modules/ManageVariables/types'
import { isSelectedProjectVariable } from 'modules/ManageVariables/utils'

import classes from './DeleteModal.module.scss'

interface DeleteModalProps {
  variableToDelete: SelectedJobVariable | SelectedProjectVariable
  onClose: () => void
}

export const DeleteModal = ({
  variableToDelete,
  onClose
}: DeleteModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { jobSummaryId } = useProjectInfo()
  const { enableSharedPipelines } = useFlags()
  const { updateVariableInSharedPipeline } = useSharePipeline()
  const queryClient = useQueryClient()
  const { mutateAsync: deleteProjectVariable, isLoading } =
    useDeleteProjectVariables()

  const { name } = variableToDelete

  const onDeleteHandler = async () => {
    if (!isSelectedProjectVariable(variableToDelete)) {
      dispatch(
        jobActions.deleteJobVariable({
          variableName: name,
          variableType: variableToDelete.type
        })
      )

      enableSharedPipelines &&
        updateVariableInSharedPipeline({
          pipelineName: jobSummaryId,
          variable: {
            name
          },
          removeFromConfig: true
        })
    } else {
      await deleteProjectVariable(variableToDelete.id)
      await queryClient.invalidateQueries([queryKeys.listProjectVariables])
    }

    onClose()
  }

  return (
    <Modal ariaLabelledBy="modal-title" onCancel={onClose} size="mid">
      <Typography
        id="modal-title"
        format="ts"
        as="h2"
        className={classes.Delete__Title}
      >
        {t('manageVariables.delete.title')}
      </Typography>
      <Typography format="bcs">
        {t('manageVariables.delete.subtext')}
      </Typography>
      <Alert
        className={classes.Delete__Alert}
        message={t('manageVariables.delete.footerWarning', {
          name
        })}
        type="warning"
      />
      <div className={classes.Delete__Actions}>
        <Button
          disabled={isLoading}
          alt="secondary"
          text={t('common.cancel')}
          onClick={onClose}
        />
        <Button
          disabled={isLoading}
          alt="danger"
          data-testid="confirm-delete-variable"
          text={t('manageVariables.delete.delete')}
          onClick={() => {
            onDeleteHandler()
          }}
        />
      </div>
    </Modal>
  )
}
