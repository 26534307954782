import { useTranslation } from 'react-i18next'

import { ReactComponent as AiNotesExampleImg } from 'assets/ai-note-example.svg'

import UpgradeModal from './UpgradeModal'

export interface AINotesUpgradeModalProps {
  onClose: () => void
}

const AINotesUpgradeModal = ({ onClose }: AINotesUpgradeModalProps) => {
  const { t } = useTranslation()
  const bodyContents = [
    { key: 0, content: t('upgradeModal.aiNotes.description') },
    { key: 1, content: t('upgradeModal.aiNotes.cta') }
  ]

  return (
    <UpgradeModal
      onClose={onClose}
      title={t('upgradeModal.aiNotes.title')}
      subtitle={t('upgradeModal.aiNotes.subtitle')}
      bodyContents={bodyContents}
      image={<AiNotesExampleImg data-testid={'ai-notes-upgrade-img'} />}
    />
  )
}

export default AINotesUpgradeModal
