import { flexRender, type Table } from '@tanstack/react-table'
import classNames from 'classnames'

import { checkboxColumnId } from './Columns/Checkbox'
import { dragHandleColumnId } from './Columns/DragHandle'
import classes from './MatillionTable.module.scss'

interface MatillionTableHeadProp<T> {
  table: Table<T>
}

export const MatillionTableHead = <T,>({
  table
}: MatillionTableHeadProp<T>) => {
  return (
    <thead>
      <tr>
        {table.getFlatHeaders().map((header) => {
          return (
            <th
              className={classNames({
                [classes['th-checkbox']]: header.column.id === checkboxColumnId,
                [classes['th-draghandle']]:
                  header.column.id === dragHandleColumnId
              })}
              key={header.id}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
