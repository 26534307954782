import { isCustomConnector, isModularConnector } from 'job-lib/cisIds/idType'

import { translateIDForDocs } from './translateIDForDocs'

export const getDocumentationLink = (componentSummaryId: string) => {
  const isCustom = isCustomConnector(componentSummaryId)
  if (isCustom) {
    return 'https://docs.matillion.com/data-productivity-cloud/custom-connector/docs/custom-connector-overview/'
  }

  const isModular = isModularConnector(componentSummaryId)
  if (isModular) {
    const splitSummaryId = componentSummaryId.split('-')
    const unversionedSummaryId = splitSummaryId.slice(0, -1).join('-')
    return `https://docs.matillion.com/data-productivity-cloud/designer/docs/${unversionedSummaryId}?version=${
      splitSummaryId[splitSummaryId.length - 1]
    }`
  }
  return `https://docs.matillion.com/data-productivity-cloud/designer/docs/${translateIDForDocs(
    componentSummaryId
  )}`
}
