import { useReducer, type FC, type ReactNode } from 'react'

import { isPipeline } from '@matillion/git-component-library'

import ConversionToolModal from 'modules/migration/ConversionTool/components/ConversionToolModal'

import CreateNonPipelineFileModal from '../commands/CreateFileModal/CreateNonPipelineFileModal'
import CreatePipelineModal from '../commands/CreateFileModal/CreatePipelineModal'
import CreateFolderModal from '../commands/CreateFolderModal/CreateFolderModal'
import DeleteFolderModal from '../commands/DeleteFolderModal/DeleteFolderModal'
import { DeletePipelineModal } from '../commands/DeletePipelineModal/DeletePipelineModal'
import FolderLimitReachedModal from '../commands/FolderLimitReachedModal/FolderLimitReachedModal'
import { RenameFolderModal } from '../commands/RenameFolderModal/RenameFolderModal'
import { RenamePipelineModal } from '../commands/RenamePipelineModal/RenamePipelineModal'
import { SharePipelineModal } from '../commands/SharePipelineModal/SharePipelineModal'
import { UnsharePipelineModal } from '../commands/UnsharePipelineModal/UnsharePipelineModal'
import {
  commandReducer,
  initialCommandState
} from '../reducers/commandReducer/commandReducer'
import fileTreeReducer from '../reducers/fileTreeReducer/fileTreeReducer'
import {
  FileTreeReducerType,
  initialFileTreeReducerState
} from '../reducers/fileTreeReducer/types'
import { FileBrowserCommandContext, FileBrowserContext } from './context'
import { FileBrowserCommand } from './types'

interface PipelineBrowserCommandProps {
  children: (hasFolderTree: boolean) => ReactNode
}

export const PipelineBrowserCommandProvider: FC<
  PipelineBrowserCommandProps
> = ({ children }) => {
  const [state, onCommand] = useReducer(commandReducer, initialCommandState)
  const [fileTreeState, onFileTreeCommand] = useReducer(
    fileTreeReducer,
    initialFileTreeReducerState
  )

  const {
    isCreatingFile,
    isDeletingFile,
    isRenamingFile,
    isSharingPipeline,
    isUnsharingPipeline,
    isImportingFromMetl,
    fileSummary,
    fileType,
    metlExportFile
  } = state

  const {
    fileTree,
    hasReachedFolderLimit,
    isCreatingFolder,
    isDeletingFolder,
    isRenamingFolder,
    deletedFiles,
    folderPath,
    folderName,
    currentDirectory
  } = fileTreeState

  const hasFolderTree = Object.keys(fileTree).length > 0

  const shouldShowRenameFolderModal =
    isRenamingFolder && folderPath && currentDirectory && folderName

  const handleCloseModal = () => {
    onCommand({
      payload: null,
      type: FileBrowserCommand.RESET
    })
  }

  const resetPipelineFolderState = () => {
    onFileTreeCommand({
      type: FileTreeReducerType.RESET
    })
  }

  return (
    <FileBrowserCommandContext.Provider
      value={{
        onCommand,
        onFileTreeCommand
      }}
    >
      <FileBrowserContext.Provider value={fileTree}>
        {children(hasFolderTree)}

        {isCreatingFolder && folderPath !== null && !hasReachedFolderLimit && (
          <CreateFolderModal
            path={folderPath}
            onClose={resetPipelineFolderState}
          />
        )}

        {isCreatingFile &&
          fileType &&
          (isPipeline(fileType) ? (
            <CreatePipelineModal
              type={fileType}
              folderPath={folderPath}
              onClose={handleCloseModal}
            />
          ) : (
            <CreateNonPipelineFileModal
              type={fileType}
              folderPath={folderPath}
              onClose={handleCloseModal}
            />
          ))}

        {isRenamingFile && fileSummary && (
          <RenamePipelineModal
            jobSummary={fileSummary}
            folderPath={folderPath}
            onClose={handleCloseModal}
          />
        )}

        {isDeletingFile && fileSummary && (
          <DeletePipelineModal
            onClose={handleCloseModal}
            jobSummary={fileSummary}
          />
        )}

        {isSharingPipeline && fileSummary && (
          <SharePipelineModal
            onClose={handleCloseModal}
            jobSummary={fileSummary}
          />
        )}

        {isUnsharingPipeline && fileSummary && (
          <UnsharePipelineModal
            onClose={handleCloseModal}
            jobSummary={fileSummary}
          />
        )}

        {isImportingFromMetl && folderPath != null && metlExportFile && (
          <ConversionToolModal
            onClose={handleCloseModal}
            file={metlExportFile}
            path={folderPath}
          />
        )}

        {isDeletingFolder && folderPath && deletedFiles && (
          <DeleteFolderModal
            path={folderPath}
            pipelines={deletedFiles}
            onClose={resetPipelineFolderState}
          />
        )}

        {hasReachedFolderLimit && (
          <FolderLimitReachedModal onDismiss={resetPipelineFolderState} />
        )}

        {shouldShowRenameFolderModal && (
          <RenameFolderModal
            path={folderPath}
            folderName={folderName}
            currentDirectory={currentDirectory}
            onClose={resetPipelineFolderState}
          />
        )}
      </FileBrowserContext.Provider>
    </FileBrowserCommandContext.Provider>
  )
}
