import { type FC } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { t } from 'i18next'

import { IconButton } from 'components/IconButton/IconButton'

import classes from './ApplyToAllCTA.module.scss'

interface ApplyToAllCTAProps {
  onAction: () => void
  onCancel: () => void
}

export const ApplyToAllCTA: FC<ApplyToAllCTAProps> = ({
  onAction,
  onCancel
}) => {
  return (
    <div
      data-testid="apply-to-all-cta-banner"
      className={classes.ApplyToAllCTA__Container}
    >
      <div
        className={classNames(
          classes.ApplyToAllCTA__Column,
          classes['ApplyToAllCTA__Column--Before']
        )}
      >
        <Icon.Info className={classes.ApplyToAllCTA__Icon} />
        <Typography format="bcs" weight="bold">
          {t('multiTableConfig.applyToAllBanner.message')}
        </Typography>
      </div>
      <div
        className={classNames(
          classes.ApplyToAllCTA__Column,
          classes['ApplyToAllCTA__Column--After']
        )}
      >
        <button
          data-testid="apply-to-all-cta"
          type="button"
          className={classes.ApplyToAllCTA__ActionButton}
          onClick={onAction}
        >
          <Typography format="bcs">
            {t('multiTableConfig.applyToAllBanner.cta')}
          </Typography>
        </button>
        <IconButton
          className={classes.ApplyToAllCTA__CancelButton}
          label={t('multiTableConfig.applyToAllBanner.dismissLabel')}
          onClick={onCancel}
        >
          <Icon.Cross />
        </IconButton>
      </div>
    </div>
  )
}
