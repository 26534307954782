import { initReactI18next } from 'react-i18next'

import { gitTranslationsEn } from '@matillion/git-component-library'
import i18n from 'i18next'
import { merge } from 'lodash'

import compOverwrites from './en/component-overwrites.translations.json'
import componentSummaries from './en/component-summaries'
import componentSummariesTranslations from './en/component-summaries.translations.json'
import parametersValues from './en/parameterValues.json'
import en from './en/translation.json'
import overwrites from './en/warehouse-overwrites.translations.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: merge(en, overwrites, compOverwrites, gitTranslationsEn),
      componentSummaries: {
        components: componentSummaries,
        ...componentSummariesTranslations
      },
      parametersValues: { ...parametersValues }
    }
  },
  lng: 'en',
  interpolation: {
    escapeValue: false // React protects against XSS
  }
})

export default i18n
