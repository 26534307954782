import { startImplementationId } from 'job-lib/cisIds/knownComponentParameters'
import { type RootState } from 'job-lib/store'
import { type OrchestrationComponentInstance } from 'job-lib/types/Job'

export const singleStartComponentSelector = (state: RootState) => {
  return (
    Object.values(state.job.job?.components ?? []).filter(
      (x: OrchestrationComponentInstance) =>
        x.implementationID === startImplementationId
    ).length === 1
  )
}
