// duplicated from Project Explorer
export enum SecretReferenceTypes {
  API_KEY = 'API_KEY',
  PASSWORD = 'PASSWORD',
  OAUTH_USER_TOKENS = 'OAUTH_USER_TOKENS',
  OAUTH_AUTHORIZATION_CODE = 'OAUTH_AUTHORIZATION_CODE',
  OAUTH_CLIENT_CRED = 'OAUTH_CLIENT_CRED',
  OAUTH_CLIENT_CREDENTIALS = 'OAUTH_CLIENT_CREDENTIALS',
  DWH_PASSWORD = 'DWH_PASSWORD',
  COMPOSITE_TOKEN = 'COMPOSITE_TOKEN',
  CLOUD_PLATFORM_CREDENTIALS = 'CLOUD_PLATFORM_CREDENTIALS'
}

export interface GETSecretReferencesQueryStringParams {
  projectId: string
  secretType: SecretReferenceTypes | SecretReferenceTypes[]
  providers?: string[]
}

interface GETSecretReferenceMetadata {
  provider?: string
  cloudProviderID?: string
  [key: string]: string | undefined
}

export interface GETSecretReferencesResponseParams {
  id: string
  name: string
  description: string
  type: SecretReferenceTypes
  vaultSecretName: string
  vaultSecretKey: string
  metadata: GETSecretReferenceMetadata | null
  locationId: string
}

export interface GETSecretReferencesResponse {
  secrets: GETSecretReferencesResponseParams[]
}
