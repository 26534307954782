import { useEffect, useMemo, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster, type TreeExpandedState } from '@matillion/component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import BrowserTree from 'components/BrowserTree/BrowserTree'

import { useCopyFile } from 'hooks/useCopyFile'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import { MovePipelineModal } from 'modules/FileBrowser/commands/MovePipelineModal/MovePipelineModal'
import useMoveFile from 'modules/FileBrowser/hooks/useMoveFile'

import {
  usePipelineBrowserCommandProvider,
  usePipelineBrowserProvider
} from '../../effects/usePipelineBrowserCommand'
import {
  FileTreeReducerType,
  type FileNode
} from '../../reducers/fileTreeReducer/types'
import FileTree from '../FileTree/FileTree'
import RootFolder from '../RootFolder/RootFolder'
import { FileBrowserContext } from './context'
import classes from './FileBrowserTree.module.scss'

export interface Props {
  jobs: JobSummary[]
  onToggle?: (nodeId: string, isExpanded: boolean) => void
  expandedItems?: TreeExpandedState
}

export const FileBrowserTree: FC<Props> = ({
  jobs,
  onToggle,
  expandedItems
}) => {
  const { branchId, projectId } = useProjectInfo()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const fileTree = usePipelineBrowserProvider()
  const {
    moveFile,
    onConfirmMove,
    showWarningModal,
    setShowWarningModal,
    fileType
  } = useMoveFile()
  const [copiedFileNode, setCopiedFileNode] = useState<FileNode>()
  const { copyFile, isLoading } = useCopyFile()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  useEffect(() => {
    onFileTreeCommand({
      summaries: jobs.filter((job) => job.status !== FileStatus.DELETED),
      type: FileTreeReducerType.OVERWRITE_TREE,
      project: projectId,
      branch: branchId
    })
  }, [jobs, branchId, projectId, onFileTreeCommand])

  const fileBrowserContextValue = useMemo(() => {
    const handleCopyFile = async (
      currentFilePath: string,
      targetFolder: string
    ) => {
      if (isLoading) {
        makeToast({
          type: 'warning',
          title: t('translation:copy-paste.toast.is-loading.title'),
          message: t('translation:copy-paste.toast.is-loading.message')
        })
        return
      }

      copyFile({ currentFilePath, targetFolder })
    }

    return {
      copiedFileNode,
      setCopiedFileNode,
      handleCopyFile
    }
  }, [copiedFileNode, copyFile, isLoading, makeToast, t])

  return (
    <>
      <FileBrowserContext.Provider value={fileBrowserContextValue}>
        <RootFolder moveFile={moveFile}>
          <BrowserTree
            className={classes.PipelineBrowserTree}
            onToggle={onToggle}
            expandedItems={expandedItems}
          >
            <FileTree data={fileTree} moveFile={moveFile} />
          </BrowserTree>
        </RootFolder>
      </FileBrowserContext.Provider>

      {showWarningModal && !!fileType && (
        <MovePipelineModal
          fileType={fileType}
          onConfirm={onConfirmMove}
          onClose={() => {
            setShowWarningModal(false)
          }}
        />
      )}
    </>
  )
}
