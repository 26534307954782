import { useMemo, type FunctionComponent } from 'react'

import { HorizontalStack } from 'components/HorizontalStack/HorizontalStack'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { isMacOs } from 'utils/isMacOs'

import { RunButton } from './RunButton'
import { ScheduleButton } from './ScheduleButton'
import classes from './Toolbox.module.scss'
import { ValidateButton } from './ValidateButton'

const Toolbox: FunctionComponent = () => {
  const { pipelineSummary, isLoading, isError } = useActivePipelineSummary()

  const context = useMemo(
    () => ({
      context: isMacOs() ? 'macOS' : 'windows'
    }),
    []
  )

  if (!pipelineSummary?.type || isLoading || isError) {
    return null
  }

  return (
    <div className={classes.Toolbox}>
      <HorizontalStack>
        <div className={classes.ButtonGroup}>
          <HorizontalStack>
            <ValidateButton
              key="validate"
              context={context}
              pipelineType={pipelineSummary.type}
            />
            <ScheduleButton key="schedule" />
          </HorizontalStack>
        </div>
        <RunButton key="run" context={context} summary={pipelineSummary} />
      </HorizontalStack>
    </div>
  )
}

export { Toolbox }
