import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { ReactComponent as FolderBookmarkIcon } from 'assets/folder-bookmark.svg'

import { ErrorPage } from 'components/ErrorPage/ErrorPage'

interface ProjectValidationErrorProps {
  errorTextKey: string
}

export const ProjectValidationError: FunctionComponent<
  ProjectValidationErrorProps
> = ({ errorTextKey }) => {
  const { t } = useTranslation()
  const projectExplorerUrl = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )

  return (
    <ErrorPage
      icon={<FolderBookmarkIcon />}
      title={t('projectValidationError.title')}
      actions={
        <a href={projectExplorerUrl}>
          <Button text={t('projectValidationError.returnLink')} alt="primary" />
        </a>
      }
    >
      {t(errorTextKey)}
    </ErrorPage>
  )
}
