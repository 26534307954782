import { ErrorBoundary } from 'react-error-boundary'

import { datadogLogs } from '@datadog/browser-logs'

import { Canvas } from './modules/Canvas'
import CanvasErrorBoundaryFallback from './modules/CanvasErrorBoundaryFallback'
import { CopyPasteProvider } from './modules/CopyPasteComponent/CopyPasteContext'

export const CanvasFileEditor = () => (
  <ErrorBoundary
    onError={(err) => {
      /* istanbul ignore next */
      datadogLogs.logger.error(
        `Error caught by react canvas error boundary (${err.message})`,
        {
          message: err.message,
          stack: err.stack,
          name: err.name
        }
      )
    }}
    fallback={<CanvasErrorBoundaryFallback />}
  >
    <CopyPasteProvider>
      <Canvas />
    </CopyPasteProvider>
  </ErrorBoundary>
)
