import { VariableScope } from 'job-lib/types/Variables'

import { Fields, type FormState } from 'modules/ManageVariables/types'

const jobVariableFields = [
  Fields.NAME,
  Fields.DESCRIPTION,
  Fields.VARIABLE_TYPE,
  Fields.VISIBILITY,
  Fields.BEHAVIOUR,
  Fields.DEFAULT_VALUE
]

const projectVariableFields = [
  Fields.NAME,
  Fields.DESCRIPTION,
  Fields.VARIABLE_TYPE,
  Fields.DEFAULT_VALUE,
  Fields.BEHAVIOUR,
  Fields.PROJECT_DEFAULT_OVERRIDES
]

export const isFormValid = (state: FormState): boolean => {
  if (state.variableScope === VariableScope.JOB_VARIABLE) {
    return jobVariableFields.every((field) => state[field].isValid)
  }

  return projectVariableFields.every((field) => state[field].isValid)
}
