import { type ColumnMeta } from '@tanstack/react-table'

import { type LoadStrategy } from '../../types'
import {
  type ConflictGridCell,
  type ConflictGridColumnDef,
  type ConflictGridRow,
  type ConflictsColumnName,
  type ConflictsGridMeta
} from './types'

type LoadStrategyColumns = Record<LoadStrategy, ConflictsColumnName[]>

const loadStrategyColumns: LoadStrategyColumns = {
  APPEND: [],
  APPEND_WITH_HIGH_WATERMARK: ['incrementalColumn'],
  REPLACE: [],
  UPSERT: ['primaryKeys', 'incrementalColumn']
}

export const getLoadStrategyColumns = (
  loadStrategy: LoadStrategy,
  columns: ConflictGridColumnDef[]
) => {
  const columnNames: ConflictsColumnName[] = [
    'tableName',
    ...loadStrategyColumns[loadStrategy]
  ]
  return columns.filter((column) =>
    columnNames.includes(column.id as ConflictsColumnName)
  )
}

export const isConflictsMetaData = (
  value: ColumnMeta<ConflictGridRow, ConflictGridCell> | undefined
): value is ConflictsGridMeta => {
  if (!value) return false
  const metaValue = value as ConflictsGridMeta
  return !!metaValue.columnName && !!metaValue.columnTitle
}
