import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const WorkdayCustomReportsConfig: ComponentLayout = {
  componentId: 'modular-workday-custom-reports-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'host'
          },
          {
            dplId: 'tenant'
          },
          {
            dplId: 'domain'
          },
          {
            dplId: 'connection'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'workday-custom-reports-input-v1',
            omitParameters: ['connection', 'host', 'domain', 'tenant']
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null)
    ]
  }
}
