import { useContext } from 'react'

import StackingModalContext from './StackingModalContext'

const useStackingModal = () => {
  const context = useContext(StackingModalContext)

  if (context === undefined) {
    throw new Error(
      'useStackingModal must be used within the StackingModalContext'
    )
  }

  return context
}

export default useStackingModal
