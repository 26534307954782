import { Table as T, Tooltip, Typography } from '@matillion/component-library'

import { type VariableConversionRecord } from 'api/hooks/useMigrationMetlJob'

import useConvertVariableScope from 'modules/migration/ConversionTool/hooks/useConvertVariableScope'

import classes from './VariableReportRecord.module.scss'

export interface VariableReportRecordProps {
  variableRecord: VariableConversionRecord
  id: string
}

const VariableReportRecord = ({
  variableRecord,
  id
}: VariableReportRecordProps) => {
  const { convertVariableScope } = useConvertVariableScope()
  return (
    <T.TableRow
      key={variableRecord.name}
      className={classes.tableRow}
      data-testid={`${id}-row`}
    >
      <T.TableCell width="12">
        <div className={classes.variableInfo}>
          <Tooltip content={variableRecord.name} onlyShowOnOverflow>
            <div className="u-truncate-text">
              <Typography
                format="bcs"
                as="span"
                className={classes.variableName}
              >
                {variableRecord.name}
              </Typography>
            </div>
          </Tooltip>
          <div>
            <Typography format="mc" as="span" className={classes.variableType}>
              {'(' + variableRecord.type + ')'}
            </Typography>
          </div>
          <div>
            <Typography format="mc" as="span" className={classes.variableScope}>
              {'(' + convertVariableScope(variableRecord.scope) + ')'}
            </Typography>
          </div>
        </div>
      </T.TableCell>
    </T.TableRow>
  )
}

export default VariableReportRecord
