import { type FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Icon,
  Modal,
  Table as T,
  Typography
} from '@matillion/component-library'
import { FileIcon, FileType } from '@matillion/git-component-library'

import {
  ImportFailureType,
  type ImportFailureDescription,
  type ImportPipelinesResponse
} from 'api/hooks/useImportPipelines/importPipelinesApi'

import classes from './ImportInputField.module.scss'

interface ImportInputFieldProps {
  id: string
  importInputRef: React.RefObject<HTMLInputElement>
  handleImportInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isErrorModalOpen: boolean
  closeErrorModal: () => void
  response: ImportPipelinesResponse
}

const getFileNameAndType = (rawFileName: string) => {
  let fileType: FileType | null = null
  const fileName = rawFileName.replace(
    /(\.orch\.yaml|\.ORCHESTRATION|\.tran\.yaml|\.TRANSFORMATION)$/g,
    ''
  )

  if (
    rawFileName.endsWith('.orch.yaml') ||
    rawFileName.endsWith('.ORCHESTRATION')
  ) {
    fileType = FileType.ORCHESTRATION_PIPELINE
  }

  if (
    rawFileName.endsWith('.tran.yaml') ||
    rawFileName.endsWith('.TRANSFORMATION')
  ) {
    fileType = FileType.TRANSFORMATION_PIPELINE
  }

  return {
    fileType,
    fileName
  }
}

export const ImportInputField: FC<ImportInputFieldProps> = ({
  id,
  importInputRef,
  handleImportInputChange,
  isErrorModalOpen,
  closeErrorModal,
  response: { importedFiles, failures }
}) => {
  const { t } = useTranslation()

  const getFailureLabel = (failureReason: ImportFailureType) => {
    switch (failureReason) {
      case ImportFailureType.FILE_ALREADY_EXISTS:
        return t('import-to-folder.errors.file-already-exists')
      case ImportFailureType.FILE_TOO_LARGE:
        return t('import-to-folder.errors.file-too-big')
      case ImportFailureType.FILE_EXCLUDED:
        return t('import-to-folder.errors.file-excluded')
    }
  }

  const handleFileTypeAcceptance = '.zip, .json'
  const totalFiles = importedFiles.length + failures.length

  const failuresSortedByType = failures.reduce(
    (acc: Record<ImportFailureType, ImportFailureDescription[]>, failure) => {
      acc[failure.type].push(failure)

      return acc
    },
    {
      [ImportFailureType.FILE_ALREADY_EXISTS]: [],
      [ImportFailureType.FILE_TOO_LARGE]: [],
      [ImportFailureType.FILE_EXCLUDED]: []
    }
  )

  return (
    <>
      {isErrorModalOpen && (
        <Modal
          ariaLabelledBy="modal-title"
          onCancel={closeErrorModal}
          drawerModePosition="right"
          size="mid"
        >
          <Typography
            id="modal-title"
            format="tm"
            className={classes.ImportInputField__ModalHeader}
          >
            <Trans
              i18nKey={'import-to-folder.modal.header'}
              values={{ amount: importedFiles.length ? 'some' : 'any' }}
            />
          </Typography>
          {importedFiles.length > 0 ? (
            <Typography data-testid="successful-imports-count">
              <Trans
                i18nKey={'import-to-folder.modal.successful-imports-count'}
                components={{ bold: <strong /> }}
                values={{
                  filesImported: importedFiles.length,
                  totalFiles,
                  folderName: id === '' ? 'root' : id
                }}
              />
            </Typography>
          ) : null}
          <Typography
            className={classes.ImportInputField__ErrorCount}
            data-testid="imports-error-count"
          >
            <Icon.Error />
            <Trans
              i18nKey={'import-to-folder.modal.error-count'}
              values={{ filesFailed: failures.length }}
            />
          </Typography>

          <div>
            {Object.entries(failuresSortedByType).map(([type, fails]) => {
              if (fails.length === 0) return null

              return (
                <T.Table
                  key={type}
                  className={classes.ImportInputField__Table}
                  data-testid={`failed-type-${type}`}
                >
                  <T.TableHead data-testid={`${type}-header`}>
                    <T.TableRow>
                      <T.TableHeaderCell width="11">
                        <Typography format="bcs">
                          {getFailureLabel(type as ImportFailureType)}
                        </Typography>
                      </T.TableHeaderCell>
                      <T.TableHeaderCell width="1">
                        <Typography format="bcs">({fails.length})</Typography>
                      </T.TableHeaderCell>
                    </T.TableRow>
                  </T.TableHead>
                  <T.TableBody>
                    {fails.map((failure) => {
                      const { fileType, fileName } = getFileNameAndType(
                        failure.fileName
                      )

                      return (
                        <T.TableRow
                          key={failure.fileName}
                          data-testid={`failed-${type}-file`}
                          className={classes.ImportInputField__TableRow}
                        >
                          <T.TableCell
                            width="12"
                            className={classes.ImportInputField__TableCell}
                          >
                            {fileType && (
                              <div>
                                <FileIcon type={fileType} />
                              </div>
                            )}
                            <Typography
                              className={classes.ImportInputField__FileName}
                            >
                              {fileName}
                            </Typography>
                          </T.TableCell>
                        </T.TableRow>
                      )
                    })}
                  </T.TableBody>
                </T.Table>
              )
            })}
          </div>
        </Modal>
      )}
      <input
        data-testid={`import-pipelines-input-${id || 'root'}`}
        ref={importInputRef}
        style={{ display: 'none' }}
        type="file"
        accept={handleFileTypeAcceptance}
        multiple={false}
        onChange={handleImportInputChange}
      />
    </>
  )
}
