import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  JobConversionRecord,
  VariableConversionRecord
} from 'api/hooks/useMigrationMetlJob'

import { getJobType, getRecordName } from './getRecordDetails'

interface ConvertedRecordDetailsProps {
  conversionRecords: JobConversionRecord[] | VariableConversionRecord[]
  entityType: string
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  marginTop: 10,
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const tableRowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row'
}

const tableCellStyle: React.CSSProperties = {
  textAlign: 'left',
  fontSize: '1rem',
  padding: 5,
  flexWrap: 'wrap',
  width: '50%',
  border: '1px solid #000',
  borderCollapse: 'collapse'
}

const ConvertedRecordDetails = ({
  conversionRecords,
  entityType
}: ConvertedRecordDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <table style={tableStyle}>
      <thead>
        <tr style={tableRowStyle}>
          <th style={tableCellStyle}>{t(`${entityType}DetailsHeader`)}</th>
          <th style={tableCellStyle}>{t(`${entityType}TypeHeader`)}</th>
        </tr>
      </thead>
      <tbody>
        {conversionRecords.map((record) => (
          <tr key={record.name} style={tableRowStyle}>
            <td style={tableCellStyle}>{getRecordName(record.name)}</td>
            <td style={tableCellStyle}>
              {entityType === 'job'
                ? getJobType(record as JobConversionRecord)
                : t(`variables.${(record as VariableConversionRecord).type}`)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ConvertedRecordDetails
