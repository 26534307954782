import type { FC } from 'react'

export const SharedPipelineIcon: FC<{
  className?: string
  testId?: string
}> = ({ className, testId }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    data-testid={testId}
  >
    <path
      d="M4.3 5.99981C4.3 7.04893 3.4494 7.89951 2.4 7.89951C1.3506 7.89951 0.5 7.04893 0.5 5.99981C0.5 4.95068 1.3506 4.1001 2.4 4.1001C3.4494 4.1001 4.3 4.95068 4.3 5.99981Z"
      stroke="#222222"
    />
    <path
      d="M11.5002 2.39971C11.5002 3.44883 10.6496 4.29942 9.6002 4.29942C8.5508 4.29942 7.7002 3.44883 7.7002 2.39971C7.7002 1.35059 8.5508 0.5 9.6002 0.5C10.6496 0.5 11.5002 1.35059 11.5002 2.39971Z"
      stroke="#222222"
    />
    <path
      d="M11.5002 9.59942C11.5002 10.6485 10.6496 11.4991 9.6002 11.4991C8.5508 11.4991 7.7002 10.6485 7.7002 9.59942C7.7002 8.55029 8.5508 7.69971 9.6002 7.69971C10.6496 7.69971 11.5002 8.55029 11.5002 9.59942Z"
      stroke="#222222"
    />
    <path d="M4.20075 5.10012L7.80075 3.30012" stroke="#222222" />
    <path d="M4.2002 6.8999L7.8002 8.6999" stroke="#222222" />
  </svg>
)
