import { type FC } from 'react'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { DynamicDropdown } from 'components/DynamicDropdown/DynamicDropdown'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'

interface DropdownProps {
  inputId: string
  parameter?: ComponentParameter
  parameterName: string
  value: string
  hasError?: boolean
  errorText?: string
  optionalLabelText?: string
  onEdit: (s: string) => unknown
  onBlur: (s: string) => unknown
  onEditorError: (error?: ProblemDetails) => void
  scrollableContainerSelector: string
  compact?: boolean
  staticLabelColor?: boolean
}

export const Dropdown: FC<DropdownProps> = ({
  inputId,
  parameter,
  parameterName,
  value,
  hasError,
  errorText,
  optionalLabelText,
  scrollableContainerSelector,
  onEdit,
  onBlur,
  onEditorError,
  compact,
  staticLabelColor = false
}) => {
  const { componentMetadata, componentSummaryId } = useActiveComponentInfo()

  return (
    <DynamicDropdown
      inputId={inputId}
      value={value}
      componentMetadata={componentMetadata}
      componentSummaryId={componentSummaryId}
      parameter={parameter}
      parameterName={parameterName}
      onEdit={onEdit}
      onBlur={onBlur}
      onEditorError={onEditorError}
      hasError={hasError}
      errorText={errorText}
      displayLabel
      optionalLabelText={optionalLabelText}
      scrollableContainerSelector={scrollableContainerSelector}
      compact={compact}
      staticLabelColor={staticLabelColor}
    />
  )
}
