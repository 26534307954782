export type ParameterIdDependencies = Record<string, string> | null

export const getParameterIdDependencies = (
  lookupDependencies?: string[] | null
): ParameterIdDependencies => {
  const paramIdDependency = lookupDependencies?.find((dependency) =>
    dependency.startsWith('paramId.')
  )

  if (!paramIdDependency) {
    return null
  }

  const parameterId = paramIdDependency.split('paramId.').at(-1)

  if (!parameterId) {
    return null
  }

  return {
    paramId: parameterId
  }
}
