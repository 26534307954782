import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ScheduleModal from 'modules/core/EtlDesigner/components/HeaderBar/components/ScheduleModal'

import {
  getProjectExplorerLink,
  ProjectExplorerPaths
} from 'utils/getProjectExplorerLink'

import { ToolboxButton } from './ToolboxButton'

export const ScheduleButton: FC = () => {
  const { t } = useTranslation()
  const { projectId } = useProjectInfo()
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { hasPermission, isLoading } = useProjectPermission(
    'create_schedules',
    PermissionType.ENVIRONMENT
  )

  const handleScheduleRedirect = () => {
    window.location.assign(
      getProjectExplorerLink(
        projectExplorerOrigin,
        projectId,
        ProjectExplorerPaths.CREATE_SCHEDULE
      )
    )
  }

  const isEnabled = hasPermission && !isLoading

  const buttonIcon = <ClockIcon />
  const text = t('canvasToolbox.buttons.schedule')
  const label = t('canvasToolbox.scheduleJob')
  const dataTestId = 'canvas-toolbox-schedule-job'

  return (
    <>
      <ToolboxButton
        buttonIcon={buttonIcon}
        text={text}
        onClick={() => {
          setIsScheduleModalOpen(!isScheduleModalOpen)
        }}
        label={label}
        disabled={!isEnabled}
        dataTestId={dataTestId}
      />
      {isScheduleModalOpen && isEnabled && (
        <ScheduleModal
          onInitiate={handleScheduleRedirect}
          onCancel={() => {
            setIsScheduleModalOpen(false)
          }}
        />
      )}
    </>
  )
}
