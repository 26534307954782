import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type PublishJobsRequest } from './types'

export interface PublishArgs {
  commitId: string
  environmentId: string
  branchName: string
  version: string
  shouldPublishSharedPipelines: boolean
}

export const usePublishJobs = (warehouse: string) => {
  const { projectId } = useProjectInfo()
  const { publishJobs } = useDesignerApi()

  const publish = useCallback(
    async (args: PublishArgs) => {
      const request: PublishJobsRequest = {
        environmentId: args.environmentId,
        commitId: args.commitId,
        branchName: args.branchName,
        projectId,
        version: args.version,
        shouldPublishSharedPipelines: args.shouldPublishSharedPipelines
      }

      return publishJobs(warehouse, request)
    },
    [publishJobs, projectId, warehouse]
  )

  return useMutation([projectId], publish, {})
}
