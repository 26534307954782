import type { ComponentName } from 'types/Pipeline'

import {
  type ComponentInstanceId,
  type Connector,
  type ConnectorId
} from 'job-lib/types/Job'

import { type IdGenerator } from '../../utils/idGenerator'

/* Note that this function will set keys in the provided connectionMap;
 * this is to reduce the amount of iteration/spreading we need to do
 * when converting connections for an entire pipeline */
export const convertTransitionsToMetlConnectors = (
  metlComponentIdsByName: Record<string, number>,
  sourceID: ComponentInstanceId,
  transitionsToConvert: ComponentName[] | undefined,
  metlConnectorMap: Map<ConnectorId, Connector>,
  metlConnectorIdGenerator: IdGenerator
) => {
  transitionsToConvert?.forEach((connector) => {
    const targetID = metlComponentIdsByName[connector]

    if (!targetID) {
      return
    }

    const connectorId = Number(metlConnectorIdGenerator.next().value)

    metlConnectorMap.set(connectorId, {
      id: connectorId,
      sourceID,
      targetID
    })
  })

  return metlConnectorMap
}

/* Note that this function will set keys in the provided connectionMap;
 * this is to reduce the amount of iteration/spreading we need to do
 * when converting connections for an entire pipeline */
export const convertSourcesToMetlConnectors = (
  metlComponentIdsByName: Record<string, number>,
  targetID: ComponentInstanceId,
  sourcesToConvert: ComponentName[] | undefined,
  metlConnectorMap: Map<ConnectorId, Connector>,
  metlConnectorIdGenerator: IdGenerator
) => {
  sourcesToConvert?.forEach((connector) => {
    const sourceID = metlComponentIdsByName[connector]

    if (!sourceID) {
      return
    }

    const connectorId = Number(metlConnectorIdGenerator.next().value)

    metlConnectorMap.set(connectorId, {
      id: connectorId,
      sourceID,
      targetID
    })
  })

  return metlConnectorMap
}
