import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { elementsUseGridVariable } from 'job-lib/job-functions/elements'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { type ParameterOptions } from 'modules/ParameterEditors/types'

import { GridVariableEditor } from '../GridVariables/GridVariableEditor/GridVariableEditor'
import { useGridVariableEditor } from '../GridVariables/GridVariableEditor/useGridVariableEditor'
import {
  getDefaultColumnName,
  getGridElements
} from '../GridVariables/GridVariableEditor/utils'
import { GridVariableSwitcher } from '../GridVariables/GridVariableSwitcher/GridVariableSwitcher'
import { OptionSelector } from './components/OptionSelector'

export interface MultiOptionSelectorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  onDone: (editedValue: ElementCollection) => void
  onDoneSingleValue: (editedValue: string[]) => void
  elements: ElementCollection
  parameter: ComponentParameter
}

export const MultiOptionSelector: FC<MultiOptionSelectorProps> = ({
  editorColumns,
  value,
  parameterName,
  parameter,
  elements,
  onDone,
  onDoneSingleValue
}) => {
  const { t } = useTranslation()

  const isUsingGridVariable = elementsUseGridVariable(elements)
  const columnNames = getDefaultColumnName(t)
  const {
    columnValues,
    setColumnValues,
    selectedGridVariable,
    setSelectedGridVariable
  } = useGridVariableEditor({
    isUsingGridVariable,
    elements,
    columnNames
  })

  const [useGridVariableChecked, setUseGridVariableChecked] =
    useState(isUsingGridVariable)

  const [updatedValues, setUpdatedValues] = useState<string[]>(
    isUsingGridVariable ? [] : value
  )

  const onSubmit = () => {
    if (useGridVariableChecked) {
      onDone(getGridElements(columnNames, columnValues, selectedGridVariable))

      return
    }

    onDoneSingleValue(updatedValues)
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <ParameterOverlayContent>
        {!useGridVariableChecked && (
          <OptionSelector
            updatedValues={updatedValues}
            setUpdatedValues={setUpdatedValues}
            editorColumns={editorColumns}
            parameterName={parameterName}
          />
        )}

        {useGridVariableChecked && (
          <GridVariableEditor
            columnNames={columnNames}
            columnValues={columnValues}
            selectedGridVariable={selectedGridVariable}
            setColumnValues={setColumnValues}
            setSelectedGridVariable={setSelectedGridVariable}
          />
        )}
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
        <GridVariableSwitcher
          checked={useGridVariableChecked}
          disabled={false}
          parameter={parameter}
          setChecked={setUseGridVariableChecked}
        />
      </ParameterOverlayFooter>
    </>
  )
}

export default MultiOptionSelector
