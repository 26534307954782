import {
  OrchestrationComponentConnectors,
  OrchestrationJobConnectors
} from 'job-lib/store/jobSlice/job.types'
import { type OrchestrationJob } from 'job-lib/types/Job'

import { type LinkTypeUnknown } from '../types'

const jobConnectors = [
  OrchestrationJobConnectors.SUCCESS,
  OrchestrationJobConnectors.FAILURE,
  OrchestrationJobConnectors.UNCONDITIONAL,
  OrchestrationJobConnectors.ITERATION,
  OrchestrationJobConnectors.TRUE,
  OrchestrationJobConnectors.FALSE
]

const componentConnectors: OrchestrationComponentConnectors[] = [
  OrchestrationComponentConnectors.INPUT,
  OrchestrationComponentConnectors.UNCONDITIONAL,
  OrchestrationComponentConnectors.SUCCESS,
  OrchestrationComponentConnectors.FAILURE,
  OrchestrationComponentConnectors.OUTPUT_ITERATION,
  OrchestrationComponentConnectors.INPUT_ITERATION,
  OrchestrationComponentConnectors.OUTPUT_TRUE,
  OrchestrationComponentConnectors.OUTPUT_FALSE
]

const componentJobMap = {
  [OrchestrationComponentConnectors.UNCONDITIONAL]:
    OrchestrationJobConnectors.UNCONDITIONAL,
  [OrchestrationComponentConnectors.SUCCESS]:
    OrchestrationJobConnectors.SUCCESS,
  [OrchestrationComponentConnectors.FAILURE]:
    OrchestrationJobConnectors.FAILURE,
  [OrchestrationComponentConnectors.OUTPUT_ITERATION]:
    OrchestrationJobConnectors.ITERATION,
  [OrchestrationComponentConnectors.OUTPUT_TRUE]:
    OrchestrationJobConnectors.TRUE,
  [OrchestrationComponentConnectors.OUTPUT_FALSE]:
    OrchestrationJobConnectors.FALSE
}

export const removeOrchestrationLinks = ({
  job,
  componentInstanceIds
}: {
  job: OrchestrationJob
  componentInstanceIds: number[]
}) => {
  const connectorIds: Record<OrchestrationJobConnectors, number[]> = {
    [OrchestrationJobConnectors.SUCCESS]: [],
    [OrchestrationJobConnectors.FAILURE]: [],
    [OrchestrationJobConnectors.UNCONDITIONAL]: [],
    [OrchestrationJobConnectors.ITERATION]: [],
    [OrchestrationJobConnectors.TRUE]: [],
    [OrchestrationJobConnectors.FALSE]: []
  }

  const removeInstanceLink = (
    connectorId: number,
    componentPropertyName: OrchestrationComponentConnectors
  ) => {
    if (
      componentPropertyName === OrchestrationComponentConnectors.INPUT ||
      componentPropertyName === OrchestrationComponentConnectors.INPUT_ITERATION
    ) {
      return
    }

    const jobPropertyName = componentJobMap[componentPropertyName]
    const { id, sourceID, targetID } = job[jobPropertyName][connectorId]

    connectorIds[jobPropertyName].push(id)

    job.components[sourceID][componentPropertyName] = job.components[sourceID][
      componentPropertyName
    ].filter((outputId) => outputId !== id)

    job.components[targetID].inputConnectorIDs = job.components[
      targetID
    ].inputConnectorIDs.filter((inputId) => inputId !== id)

    job.components[targetID].inputIterationConnectorIDs = job.components[
      targetID
    ].inputIterationConnectorIDs.filter((inputId) => inputId !== id)
  }

  componentInstanceIds.forEach((componentInstanceId) => {
    const { inputConnectorIDs, inputIterationConnectorIDs } =
      job.components[componentInstanceId]

    componentConnectors.forEach((propertyName) => {
      job.components[componentInstanceId][propertyName].forEach(
        (connectorId) => {
          removeInstanceLink(connectorId, propertyName)
        }
      )
    })

    const removeInputLinks = ({
      propertyName,
      ids
    }: {
      propertyName: LinkTypeUnknown
      ids: number[]
    }) => {
      ids.forEach((id) => {
        const connector = jobConnectors.find((connetor) =>
          Object.keys(job[connetor]).find((_id) => parseInt(_id) === id)
        )

        if (connector) {
          connectorIds[connector].push(id)

          const { sourceID, targetID } = job[connector][id]
          const [connectorProperty] = componentConnectors.filter((_connector) =>
            job.components[sourceID][_connector].includes(id)
          )

          job.components[sourceID][connectorProperty] = job.components[
            sourceID
          ][connectorProperty].filter((_id) => _id !== id)

          job.components[targetID][propertyName] = job.components[targetID][
            propertyName
          ].filter((_id) => _id !== id)
        }
      })
    }

    const inputConnectors: Array<{
      propertyName: LinkTypeUnknown
      ids: number[]
    }> = [
      {
        propertyName: OrchestrationComponentConnectors.INPUT,
        ids: inputConnectorIDs
      },
      {
        propertyName: OrchestrationComponentConnectors.INPUT_ITERATION,
        ids: inputIterationConnectorIDs
      }
    ]

    inputConnectors.forEach(({ propertyName, ids }) => {
      removeInputLinks({ propertyName, ids })
    })
  })

  Object.entries(connectorIds).forEach((jobProperty) => {
    const [propertyName, ids] = jobProperty as [
      OrchestrationJobConnectors,
      number[]
    ]

    job[propertyName] = Object.keys(job[propertyName])
      .map(Number)
      .filter((connectorId) => !ids.includes(connectorId))
      .reduce(
        (acc, connectorId) => ({
          ...acc,
          [connectorId]: { ...job[propertyName][connectorId] }
        }),
        {}
      )
  })
}
