import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { DisplayType } from 'components/CodeEditor/types'
import { Loading } from 'components/Loading/Loading'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'

import { NoContentMessage } from '../NoContentMessage'
import classes from './SQLViewer.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export const SQLViewer = () => {
  const { t } = useTranslation()
  const { componentId } = useProjectInfo()
  const { isError, isSuccess, isLoading, sql } = useComponentValidationResult(
    componentId as number
  )

  if (isSuccess && !sql) {
    return <NoContentMessage text={t('sqlViewer.sqlNotEnabled')} />
  }

  if (isLoading) {
    return (
      <Loading
        className={classes.LoadingSQLViewer}
        data-testid="sql-viewer-loading"
      />
    )
  }

  if (isError) {
    return <NoContentMessage text={t('sqlViewer.validationError')} />
  }

  if (!sql) {
    return <NoContentMessage text={t('sqlViewer.validationNotTriggeredYet')} />
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className={classes.SQLViewer__Container}>
        <div className={classes.SQLViewer}>
          <CodeEditor
            language="sql"
            data-testid="component-properties-sql-panel-code"
            readOnly
            value={sql}
            onChange={() => null}
            displayType={DisplayType.SQL}
          />
        </div>
      </div>
    </Suspense>
  )
}
