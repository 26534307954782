import { useDispatch } from 'react-redux'

import { PipelineType } from 'types/Pipeline'

import { jobActions } from 'job-lib/store'
import { type TransformationJob } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { setMETLPositionFromPipeline } from 'modules/core/Copilot/formatMETL'
import { formatTransformation } from 'modules/core/Copilot/formatter'

import { type CopilotComponentUpdateConfig } from '../useChatWithSse/useChatWithSse'

export const useUpdateComponents = () => {
  const dispatch = useDispatch()

  const updateComponents = async (
    components: CopilotComponentUpdateConfig
  ): Promise<void> => {
    if (components) {
      // Only layout components if the job is a transformation for now.
      if (components.source.type === PipelineType.Transformation) {
        const newComponentNames = new Set<string>()
        Object.keys(components?.addedComponents).forEach((comp) =>
          newComponentNames.add(comp)
        )

        // We format the actual DPL and then apply that back to the METL job. This means that we can just remove the
        // setMETLPositionFromPipeline function in the future.
        formatTransformation(components.source, newComponentNames)
        setMETLPositionFromPipeline(
          components?.legacyMETL as TransformationJob,
          components.source
        )

        dispatch(
          jobActions.setJobAndSave({
            job: components?.legacyMETL,
            jobType: JobType.Transformation
          })
        )
      } else {
        dispatch(
          jobActions.setJobAndSave({
            job: components?.legacyMETL,
            jobType: JobType.Orchestration
          })
        )
      }
    }
  }

  return { updateComponents }
}
