import { useMemo } from 'react'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import { type ComponentInstance } from 'job-lib/types/Job'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'
import { isChildStructListParameter } from 'modules/core/ComponentValidation/utils/isChildStructListParameter'

import { type StepsConfig } from '../components/ComponentConfigurationPanel/types'
import type {
  ComponentLayout,
  WizardStep
} from '../components/ComponentConfigurationPanel/wizardConfigs/types'
import type { StepErrorCounts, StepPaths } from './types'

interface UseWizardStepParameterPathsProps {
  componentInstance: ComponentInstance
  wizardConfig: ComponentLayout
  stepsConfig: StepsConfig
}

const PARAMETER_PATH = 'parameters'
const REGEX_STRUCT_LIST_IDX = /^\[\d*\]$/

const getOmitIds = (step: WizardStep, parameter: ComponentParameter) => {
  return step.parameters.find(
    (stepParameter) => stepParameter.dplId === parameter?.dplID
  )?.omitParameters
}

const comparePaths = (pathA: string[], pathB: string[]): boolean =>
  pathA.length === pathB.length &&
  pathA.every(
    (stepPathItem, index) =>
      stepPathItem === pathB[index] ||
      (stepPathItem === '[]' && REGEX_STRUCT_LIST_IDX.test(pathB[index]))
  )

const getParameterPaths = (
  parameter: ComponentParameter,
  parentPath: string[],
  omitParameters?: string[]
): string[][] => {
  const path = isChildStructListParameter(parameter)
    ? [...parentPath, parameter.dplID, '[]']
    : [...parentPath, parameter.dplID]

  if (!parameter.childProperties) {
    return [path]
  } else {
    return parameter.childProperties
      ?.filter(
        (childProperty) => !omitParameters?.includes(childProperty.dplID)
      )
      ?.flatMap((childProperty) =>
        getParameterPaths(childProperty, path, omitParameters)
      )
  }
}

export const useWizardStepErrors = ({
  componentInstance,
  wizardConfig,
  stepsConfig
}: UseWizardStepParameterPathsProps): StepErrorCounts => {
  const { failures: validationFailures } = useComponentValidationResult(
    componentInstance.id
  )

  const stepsPaths = useMemo(() => {
    const result: StepPaths = wizardConfig.wizard.steps
      .map((step) => step.stepId)
      .reduce((acc: StepPaths, key: string) => {
        acc[key] = []
        return acc
      }, {})

    wizardConfig.wizard.steps.forEach((step) => {
      result[step.stepId] = stepsConfig[step.stepId].parameters?.flatMap(
        ([parameter, path]) => {
          return parameter
            ? getParameterPaths(
                parameter,
                [PARAMETER_PATH, ...path],
                getOmitIds(step, parameter)
              )
            : []
        }
      )
    })
    return result
  }, [stepsConfig, wizardConfig.wizard.steps])

  const stepErrorCounts = useMemo((): StepErrorCounts => {
    const result: StepErrorCounts = wizardConfig.wizard.steps
      .map((step) => step.stepId)
      .reduce((acc: StepErrorCounts, key: string) => {
        acc[key] = 0
        return acc
      }, {})

    const parameterFailures = validationFailures.filter(
      (failure) => failure.path[0] === PARAMETER_PATH
    )

    if (!parameterFailures.length) {
      return result
    }

    wizardConfig.wizard.steps.forEach((step) => {
      const stepPaths = stepsPaths[step.stepId]
      result[step.stepId] = parameterFailures.reduce((total, failure) => {
        const matchingPath = stepPaths.find((stepPath) =>
          comparePaths(stepPath, failure.path)
        )
        return matchingPath ? total + 1 : total
      }, 0)
    })

    return result
  }, [stepsPaths, validationFailures, wizardConfig.wizard.steps])

  return stepErrorCounts
}
