import {
  differenceInDays,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration
} from 'date-fns'
import format from 'date-fns/format'

export const DEFAULT_DURATION_NAN = ''
export const DEFAULT_DATE_NAN = ''

export const customFormatDuration = (
  start: number | undefined,
  end: number | undefined
): string => {
  if (start === undefined || end === undefined) return DEFAULT_DURATION_NAN
  if (isNaN(start) || isNaN(end)) return DEFAULT_DURATION_NAN

  const durations = intervalToDuration({ start, end })
  return formatDuration(durations)
}

export const customFormatDate = (date: number | undefined | null): string => {
  if (date === undefined || date === null) return DEFAULT_DATE_NAN
  if (isNaN(date)) return DEFAULT_DATE_NAN

  if (differenceInDays(date, Date.now()) !== 0) {
    return formatDistanceToNow(date, { addSuffix: true })
  }

  return format(date, 'HH:mm:ss')
}
