import React, { useMemo } from 'react'

import { Typography } from '@matillion/component-library'
import { FileIcon, FileType } from '@matillion/git-component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { PortalDiv } from 'components/ComponentDrag/PortalDiv'

import { JobType } from 'job-lib/types/JobType'

import classes from './FileItemPortal.module.scss'

export interface FileItemPortalProps {
  pipeline: JobSummary
  dragRef: React.Ref<HTMLDivElement>
}

const FileItemPortal = ({ pipeline, dragRef }: FileItemPortalProps) => {
  const fileType = useMemo(() => {
    if (pipeline.fileType) {
      return pipeline.fileType
    }

    return pipeline.type === JobType.Orchestration
      ? FileType.ORCHESTRATION_PIPELINE
      : FileType.TRANSFORMATION_PIPELINE
  }, [pipeline.fileType, pipeline.type])

  return (
    <PortalDiv
      childRef={dragRef}
      className={classes.Portal}
      childTestId={`pipeline-drag-portal-${pipeline.jobId}`}
    >
      <div className={classes.PortalContent}>
        <FileIcon type={fileType} />

        <Typography as="span" format="bcs" className={classes.Text}>
          {pipeline.displayName ?? pipeline.name}
        </Typography>
      </div>
    </PortalDiv>
  )
}

export default FileItemPortal
