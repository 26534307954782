import { LoadingSpinner, Typography } from '@matillion/component-library'

import { ReactComponent as AiIcon } from '../../../assets/ai-icon.svg'
import classes from './AiLoadingScreen.module.scss'

export interface AiLoadingScreenProps {
  message: string
}
export const AiLoadingScreen = ({ message }: AiLoadingScreenProps) => {
  return (
    <div className={classes.AiLoadingScreen__Container}>
      <AiIcon />
      <LoadingSpinner />
      <Typography className={classes.AiLoadingScreen__Message}>
        {message}
      </Typography>
    </div>
  )
}
