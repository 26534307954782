import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { ReactComponent as UpDownArrow } from 'assets/up-down-arrow.svg'

import classes from './CommandPalette.module.scss'

export const Footer = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'commandPalette' })

  return (
    <div className={classes.Footer}>
      <Typography format="bcs" className={classes.Footer__Action}>
        <span className={classes.Footer__ActionIcon} aria-hidden>
          <UpDownArrow />
        </span>
        {t('footer.actions.navigate')}
        <span className="u-visually-hidden">
          {t('footer.actions.navigateAccessibleText')}
        </span>
      </Typography>
    </div>
  )
}
