import { useRef, type PropsWithChildren } from 'react'

import { FileType } from '@matillion/git-component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import FileItemPortal from './FileItemPortal'

export interface FileItemDragSourceProps {
  path?: string
  pipeline: JobSummary
  onDragEnd?: () => void
  onDragStart?: () => void
}

const FileItemDragSource = ({
  path,
  pipeline,
  children,
  onDragEnd,
  onDragStart
}: PropsWithChildren<FileItemDragSourceProps>) => {
  const draggableElement = useRef<HTMLDivElement>(null)

  return (
    <>
      <FileItemPortal pipeline={pipeline} dragRef={draggableElement} />

      <div
        draggable
        tabIndex={-1}
        data-testid={`draggable-pipeline-${pipeline.jobId}`}
        onDragEnd={onDragEnd}
        onDragStart={(event) => {
          onDragStart?.()
          event.dataTransfer.setData('pipelineName', pipeline.name)
          event.dataTransfer.setData(
            'pipelineType',
            pipeline.fileType ?? FileType.UNKNOWN
          )
          event.dataTransfer.setData('existingPipelineId', pipeline.jobId)
          event.dataTransfer.setData('sourceFolderPath', path ?? '')
          event.dataTransfer.setData('extension', pipeline.extension ?? '')

          if (draggableElement.current) {
            event.dataTransfer.setDragImage(draggableElement.current, 40, 0)
          }
        }}
      >
        {children}
      </div>
    </>
  )
}

export default FileItemDragSource
