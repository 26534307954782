import { type FC } from 'react'

import { Typography } from '@matillion/component-library'

import type { ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import { useGetEnvironmentDefaultValue } from 'hooks/useGetEnvironmentDefaultValue/useGetEnvironmentDefaultValue'

import type { Parameters } from 'job-lib/types/Job'

import classes from './ParameterGroupTag.module.scss'

const ParameterGroupTag: FC<{
  parameterMetadata: ComponentParameter | undefined | null
  componentParamMap: Parameters
  dataTestId: string | null | undefined
}> = ({ componentParamMap, parameterMetadata, dataTestId }) => {
  const componentInstanceParameter = Object.values(componentParamMap).find(
    (v) => v.slot === parameterMetadata?.metlSlot
  )

  const parameterValue = componentInstanceParameter?.elements[1].values[1].value

  const computedEnvironmentDefaultValue = useGetEnvironmentDefaultValue({
    componentParameter: parameterMetadata ?? undefined,
    value: parameterValue
  })

  return (
    <Typography
      data-testid={dataTestId}
      format="mc"
      className={classes.ParameterGroupTag}
    >
      {parameterValue}
      {computedEnvironmentDefaultValue &&
        ` (${computedEnvironmentDefaultValue})`}
    </Typography>
  )
}
export default ParameterGroupTag
