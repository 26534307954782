import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import type { ActivityItem } from '../useEosApi/types/eos'
import { useEosApi } from '../useEosApi/useEosApi'

export const useGetRootPipelineActivity = (
  pipelineId: string,
  options?: UseQueryOptions<ActivityItem | undefined, AxiosError>
) => {
  const { getRootPipelineActivity } = useEosApi()

  return useQuery<ActivityItem | undefined, AxiosError>(
    ['rootPipelineActivity', pipelineId],
    async () => (await getRootPipelineActivity(pipelineId)).data,
    options
  )
}
