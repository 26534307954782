import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import {
  type JobConversionReport,
  type VariableConversionRecord,
  type VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import ConversionReportSummaryTable from './ConversionReportSummaryTable'

interface CoversionReportSummaryDetailsProps {
  jobConversionReport: JobConversionReport
  jobsNotConverted: string[]
  variableConversionReport: VariableConversionReport
  variablesNotConverted: VariableConversionRecord[]
}

const summaryStyle: React.CSSProperties = {
  marginTop: 40,
  pageBreakAfter: 'always'
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 12,
  marginBottom: 12
}

const textStyle: React.CSSProperties = {
  fontSize: '1rem'
}

const underNoteStyle: React.CSSProperties = {
  fontSize: '0.9rem'
}

const ConversionReportSummaryDetails = ({
  jobConversionReport,
  jobsNotConverted,
  variableConversionReport,
  variablesNotConverted
}: CoversionReportSummaryDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <div style={summaryStyle}>
      <div>
        <Typography style={textStyle}>{t('description')}</Typography>
      </div>
      <div>
        <div style={tableStyle}>
          <ConversionReportSummaryTable
            convertedCount={jobConversionReport.convertedJobs.length}
            autoconvertedCount={jobConversionReport.autoconvertedJobs.length}
            refactorCount={jobConversionReport.refactorJobs.length}
            notConvertedCount={jobsNotConverted.length}
            entity="jobs"
          />
        </div>
      </div>
      <div>
        <div style={tableStyle}>
          <ConversionReportSummaryTable
            convertedCount={variableConversionReport.convertedVariables.length}
            autoconvertedCount={
              variableConversionReport.autoconvertedVariables.length
            }
            refactorCount={variableConversionReport.refactorVariables.length}
            notConvertedCount={variablesNotConverted.length}
            entity="variables"
          />
        </div>
      </div>
      <div>
        <span style={underNoteStyle}>
          <sup>1</sup> {t('autoNote')}
        </span>
        <br />
        <span style={underNoteStyle}>
          <sup>2</sup> {t('manualNotePre')}
          <a
            href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-process/#after-import"
            target="_blank"
            rel="noopener, noreferrer"
          >
            {t('manualNotePost')}
          </a>
          {/* no space between the link and the end period
           */}
          .
        </span>
        <br />
        <span style={underNoteStyle}>
          <sup>3</sup> {t('unableNote')}
        </span>
      </div>
    </div>
  )
}

export default ConversionReportSummaryDetails
