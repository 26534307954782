import { type ReactNode } from 'react'

import { Badge, Typography } from '@matillion/component-library'

import classes from './StatusBadge.module.scss'

interface StatusBadgeProps {
  labelText: string
  icon?: ReactNode
  colour?: 'blue' | 'grey' | 'green' | 'yellow' | 'red'
}

export const StatusBadge = ({ labelText, icon, colour }: StatusBadgeProps) => (
  <div className={classes.Component__ComponentStatus}>
    <Badge
      data-testid="status-badge"
      theme="filled"
      colour={colour}
      weight="bold"
      format="mc"
    >
      {icon}
      <Typography format={'mc'}>{labelText}</Typography>
    </Badge>
  </div>
)
