import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './FilterGroup.module.scss'

interface FilterGroupProps {
  options: string[]
  selectedOptions: string[]
  onChange: (option: string) => void
}

export const FilterGroup: React.FC<FilterGroupProps> = ({
  options,
  selectedOptions,
  onChange
}) => {
  return (
    <div className={classes.FilterGroup} data-testid="filter-group">
      {options.map((option) => (
        <div key={option} className={classes.FilterGroup__item}>
          <label
            className={classes.FilterGroup__label}
            data-testid={`filter-group-${option}`}
          >
            <input
              type="checkbox"
              id={option}
              name={option}
              className={classes.FilterGroup__input}
              checked={selectedOptions.includes(option)}
              onChange={() => {
                onChange(option)
              }}
            />
            <Typography
              format="bcs"
              className={classNames(classes.FilterGroup__pill, {
                [classes['FilterGroup__pill--checked']]:
                  selectedOptions.includes(option)
              })}
            >
              {option}
            </Typography>
          </label>
        </div>
      ))}
    </div>
  )
}
