import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { ComponentParametersContainer } from 'modules/ComponentParameters/ComponentParametersContainer'

import { SampleProvider } from './components/SampleComponent/SampleProvider'
import classes from './PropertiesPanel.module.scss'

const ComponentPropertiesPanel = () => {
  const { t } = useTranslation()
  const { componentId: componentInstanceId } = useProjectInfo()
  const {
    componentInstance,
    componentSummaryId,
    metadata,
    isLoading,
    isError
  } = useComponentInstanceMetadataQuery(componentInstanceId)

  if (isLoading) {
    return (
      <Loading
        data-testid="footer-left-loading"
        className={classes.ComponentLoader}
      />
    )
  }

  if (!componentInstance || !componentSummaryId || !metadata) {
    return (
      <EmptyPanel data-testid="panel-not-loaded">
        {isError
          ? t('translation:sideBar.componentPanel.networkError')
          : t('translation:sideBar.componentPanel.noComponentSelected')}
      </EmptyPanel>
    )
  }

  return (
    <section
      // this key ensures that the panel is remounted when the component changes;
      // without this, the selected tab state is maintained between components
      // in the same canvas node
      key={componentInstanceId}
      aria-label={t('translation:componentProperties.panelTitle')}
      className={classnames(
        classes.ComponentPropertiesWrapper,
        classes.ComponentPropertiesWrapper__ScrollContainer
      )}
      id="properties-panel"
    >
      <SampleProvider>
        <ComponentParametersContainer
          componentInstance={componentInstance}
          componentSummaryId={componentSummaryId}
          componentMetadata={metadata}
        />
      </SampleProvider>
    </section>
  )
}

export default ComponentPropertiesPanel
