import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { type ComponentInstanceId } from 'job-lib/types/Job'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { useComponentValidationProvider } from './useComponentValidationProvider'

export const useComponentValidationResult = (
  componentInstanceId: ComponentInstanceId
) => {
  const { getValidationResult } = useComponentValidationProvider()
  const {
    metadata: componentMetadata,
    componentSummaryId: componentId,
    componentInstance
  } = useComponentInstanceMetadataQuery(componentInstanceId)

  const validationResult = getValidationResult({
    componentInstanceId,
    componentMetadata,
    componentSummaryId: componentId
  })

  const componentParameters = componentInstance?.parameters ?? {}

  const visibleFailures = (validationResult.failures ?? []).filter(
    (failure) => {
      const failureSlot = failure.path?.at(-1)

      if (!failureSlot) {
        return true
      }
      return isMetlParameterVisible(
        failureSlot,
        componentParameters,
        componentMetadata,
        failure.path.slice(1, -1)
      )
    }
  )

  return {
    ...validationResult,
    failures: visibleFailures
  }
}
