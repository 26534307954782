import { useQueries, type UseQueryOptions } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import type { ActivityItem } from '../useEosApi/types/eos'
import { useEosApi } from '../useEosApi/useEosApi'

export const useGetRootPipelineActivities = (
  pipelineIds: string[],
  options?: UseQueryOptions<ActivityItem | undefined, AxiosError>
) => {
  const { getRootPipelineActivity } = useEosApi()

  const queryResults = useQueries({
    queries: pipelineIds.map((pipelineId) => ({
      queryKey: ['rootPipelineActivities', pipelineId],
      queryFn: async () => (await getRootPipelineActivity(pipelineId)).data,
      ...options
    }))
  })

  return queryResults
}
