import {
  FileSystemProvider,
  GitApiContextProvider
} from '@matillion/git-component-library'
import { GitLibraryProvider } from 'GitLibraryProvider'

import { StackingModalProvider } from 'components/StackingModalProvider'

import config, { getMappedGitEnvironment } from 'config'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { CommandPalette } from 'modules/CommandPalette/CommandPalette'
import { ComponentValidationProvider } from 'modules/core/ComponentValidation'
import { LayoutProvider } from 'modules/core/EtlDesigner/Layouts/LayoutProvider'
import { PipelineAccessHistoryProvider } from 'modules/hub/PipelineAccessHistory/PipelineAccessHistoryProvider'

import { EntitlementProvider } from '../Entitlements/EntitlementProvider'
import { WorkingCopyProvider as DPLWorkingCopyProvider } from '../WorkingCopyProvider/WorkingCopyProvider'
import WorkingCopyProvider from './components/WorkingCopyProvider'
import { MainLayout } from './Layouts'

const EtlDesigner = () => {
  const { enableRecentlyAccessedPipelines, enableCloningInProgressBlocking } =
    useFlags()
  const { branchId, projectId } = useProjectInfo()

  const layout = (
    <LayoutProvider>
      <StackingModalProvider>
        <MainLayout />
      </StackingModalProvider>
    </LayoutProvider>
  )

  return (
    <GitApiContextProvider
      branch={branchId}
      project={projectId}
      environment={getMappedGitEnvironment(config.environment)}
      enableCloning={enableCloningInProgressBlocking}
    >
      <GitLibraryProvider>
        <FileSystemProvider>
          <DPLWorkingCopyProvider>
            <WorkingCopyProvider>
              <ComponentValidationProvider>
                <CommandPalette />
                <EntitlementProvider>
                  {enableRecentlyAccessedPipelines ? (
                    <PipelineAccessHistoryProvider>
                      {layout}
                    </PipelineAccessHistoryProvider>
                  ) : (
                    <>{layout}</>
                  )}
                </EntitlementProvider>
              </ComponentValidationProvider>
            </WorkingCopyProvider>
          </DPLWorkingCopyProvider>
        </FileSystemProvider>
      </GitLibraryProvider>
    </GitApiContextProvider>
  )
}

export default EtlDesigner
