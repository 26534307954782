import clamp from 'lodash/clamp'

import {
  type FloatingPopoverPosition,
  type FloatingPopoverSize
} from '../types'

interface ResizeManagerProps {
  updateSize: (size: FloatingPopoverSize) => void
  updatePosition: (position: FloatingPopoverPosition) => void
  containerRef: React.RefObject<HTMLElement>
  dragBoundaryRef?: React.RefObject<HTMLElement>
  minWidth: number
  minHeight: number
  maxWidth: number
  maxHeight: number
}

export class ResizeManager {
  private readonly updateSize: (size: FloatingPopoverSize) => void
  private readonly updatePosition: (position: FloatingPopoverPosition) => void
  private readonly containerRef: React.RefObject<HTMLElement>
  private readonly dragBoundaryRef?: React.RefObject<HTMLElement>
  private initialMousePosition: {
    mouseX: number
    mouseY: number
    width: number
    height: number
    left: number
    top: number
  } | null = null

  private boundaryBox: DOMRect | null = null
  private resizing: string | null = null
  private readonly minWidth: number
  private readonly minHeight: number
  private readonly maxWidth: number
  private readonly maxHeight: number

  constructor({
    updateSize,
    updatePosition,
    containerRef,
    dragBoundaryRef,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight
  }: ResizeManagerProps) {
    this.updateSize = updateSize
    this.updatePosition = updatePosition
    this.containerRef = containerRef
    this.dragBoundaryRef = dragBoundaryRef
    this.minWidth = minWidth
    this.minHeight = minHeight
    this.maxWidth = maxWidth
    this.maxHeight = maxHeight
  }

  startResize(e: React.MouseEvent<HTMLElement>, direction: string) {
    e.preventDefault()
    const boundingBox = this.containerRef.current?.getBoundingClientRect()
    if (!boundingBox) return

    this.resizing = direction
    this.initialMousePosition = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      width: boundingBox.width,
      height: boundingBox.height,
      left: boundingBox.left,
      top: boundingBox.top
    }

    if (this.dragBoundaryRef?.current) {
      this.boundaryBox = this.dragBoundaryRef.current.getBoundingClientRect()
    }

    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  private readonly handleMouseMove = (e: MouseEvent) => {
    if (!this.initialMousePosition || !this.resizing) return

    const deltaX = e.clientX - this.initialMousePosition.mouseX
    const deltaY = e.clientY - this.initialMousePosition.mouseY

    let newWidth = this.initialMousePosition.width
    let newHeight = this.initialMousePosition.height
    let newLeft = this.initialMousePosition.left
    let newTop = this.initialMousePosition.top

    if (this.resizing.includes('n')) {
      newHeight = clamp(
        this.initialMousePosition.height - deltaY,
        this.minHeight,
        this.maxHeight
      )
      newTop =
        this.initialMousePosition.top +
        (this.initialMousePosition.height - newHeight)
    } else if (this.resizing.includes('s')) {
      newHeight = clamp(
        this.initialMousePosition.height + deltaY,
        this.minHeight,
        this.maxHeight
      )
    }

    if (this.resizing.includes('w')) {
      newWidth = clamp(
        this.initialMousePosition.width - deltaX,
        this.minWidth,
        this.maxWidth
      )
      newLeft =
        this.initialMousePosition.left +
        (this.initialMousePosition.width - newWidth)
    } else if (this.resizing.includes('e')) {
      newWidth = clamp(
        this.initialMousePosition.width + deltaX,
        this.minWidth,
        this.maxWidth
      )
    }

    // Ensure newLeft and newTop remain within boundary box
    if (this.boundaryBox) {
      const rightBoundary = this.boundaryBox.right - newWidth
      const bottomBoundary = this.boundaryBox.bottom - newHeight

      newLeft = clamp(newLeft, this.boundaryBox.left, rightBoundary)
      newTop = clamp(newTop, this.boundaryBox.top, bottomBoundary)
    }

    this.updateSize({ width: newWidth, height: newHeight })
    this.updatePosition({ left: newLeft, top: newTop })
  }

  private readonly handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
    this.initialMousePosition = null
    this.resizing = null
    this.boundaryBox = null
  }
}
