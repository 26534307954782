import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import {
  type ProjectVariable,
  type SelectedProjectVariable
} from 'modules/ManageVariables/types'

import classes from '../Manage.module.scss'
import { ProjectVariablesList } from './ProjectVariablesList'

interface ProjectVariablesListProps {
  isLoading: boolean
  isError: boolean
  variables?: ProjectVariable[]
  onDelete: (variable: SelectedProjectVariable) => void
  onEdit: (variable: SelectedProjectVariable) => void
}

export const ProjectVariables: FC<ProjectVariablesListProps> = ({
  isLoading,
  isError,
  variables,
  onDelete,
  onEdit
}) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Loading
        className={classes.ManageVariables__Loading}
        text={t('manageVariables.projectVariables.fetchingVariables')}
      />
    )
  }

  if (isError) {
    return (
      <EmptyPanel className={classes.ManageVariables__InfoPanel}>
        {t('manageVariables.projectVariables.errorFetchingVariables')}
      </EmptyPanel>
    )
  }

  if (!variables || variables.length === 0) {
    return (
      <EmptyPanel className={classes.ManageVariables__EmptyPanel}>
        {t('manageVariables.empty.infoTextProjectVariable')}
      </EmptyPanel>
    )
  }

  return (
    <ProjectVariablesList
      variables={variables}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  )
}
