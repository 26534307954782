import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Icon } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import { useCopyComponent } from 'file-editors/canvas/modules/CopyPasteComponent/useCopyComponent'

import { ReactComponent as CopyIcon } from 'assets/copy.svg'
import { ReactComponent as DetachIcon } from 'assets/detach.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import useCanRunComponent from 'hooks/useCanRunComponent/useCanRunComponent'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useFlags } from 'hooks/useFlags'

import {
  startImplementationId,
  unknownOrchestrationImplementationId,
  unknownTransformationImplementationId
} from 'job-lib/cisIds/knownComponentParameters'
import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { useDetachIterator } from 'job-lib/hooks/useDetachIterator/useDetachIterator'
import { type RootState } from 'job-lib/store'
import { ConnectionPortType } from 'job-lib/types/Components'
import {
  type ComponentInstance,
  type OrchestrationComponentInstance
} from 'job-lib/types/Job'

import { getComponentSelector } from 'modules/core/EtlDesigner/selectors/componentSelector'
import { singleStartComponentSelector } from 'modules/core/EtlDesigner/selectors/singleStartComponentSelector'

import classes from './ComponentNodeToolbar.module.scss'
import { IterateButton } from './components/IterateButton'
import RunFromButton from './components/RunFromComponentButton'
import RunOnlyButton from './components/RunOnlyComponentButton'
import SkipRunButton from './components/SkipComponentButton'

interface ComponentNodeToolbarProps {
  /**
   * In this case of an iterator stack, this is the id of the top level iterator. Otherwise, it's the
   * id of the component
   */
  summaryId: string
  selectedComponentInstanceId: number
  /**
   * If the component is part of an iterator stack,
   * this defines the instanceId of the top level iterator
   */
  topLevelIterator?: {
    instanceId: number
  }
}

export const ComponentNodeToolbar = ({
  summaryId,
  selectedComponentInstanceId,
  topLevelIterator
}: ComponentNodeToolbarProps) => {
  const { metadata } = useComponentInstanceMetadataQuery(
    selectedComponentInstanceId
  )
  const { pipelineSummary } = useActivePipelineSummary()
  const jobId = pipelineSummary?.name ?? ''
  const fileType = pipelineSummary?.type ?? undefined
  const { copyComponent } = useCopyComponent()
  const { deleteNodes } = useDeleteNodes()
  const { detachIterator: detach } = useDetachIterator()
  const hasSingleStartComponent = useSelector<RootState, boolean>(
    singleStartComponentSelector
  )
  const component = useSelector<RootState, ComponentInstance | null>(
    getComponentSelector(
      topLevelIterator?.instanceId ?? selectedComponentInstanceId
    )
  )

  const {
    canRunFromComponent,
    canRunOnlyComponent,
    executableAsSingleComponent
  } = useCanRunComponent(selectedComponentInstanceId, summaryId)
  const { t } = useTranslation()
  const { enableDetachIterator, enableSkipComponent } = useFlags()

  if (!component) {
    return null
  }

  const isIterator = metadata?.outputPorts?.some(
    (port) => port.portId === ConnectionPortType.ITERATION
  )

  const isNotAnUnconnectedIterator =
    !isIterator ||
    (isIterator &&
      (component as OrchestrationComponentInstance).outputIterationConnectorIDs
        .length > 0)

  const isUnknown =
    component.implementationID === unknownOrchestrationImplementationId ||
    component.implementationID === unknownTransformationImplementationId

  const isSingleStartComponent =
    component.implementationID === startImplementationId &&
    hasSingleStartComponent

  const hasInputPorts = !!metadata?.inputPorts?.length
  const isOrchestration = fileType === FileType.ORCHESTRATION_PIPELINE

  return (
    <div
      data-testid={`component-${selectedComponentInstanceId}-toolbar`}
      className={classes.Toolbar}
    >
      {isOrchestration && canRunOnlyComponent && (
        <RunOnlyButton jobId={jobId} />
      )}
      {isOrchestration && (
        <RunFromButton
          jobId={jobId}
          summaryId={summaryId}
          canRunFrom={canRunFromComponent}
        />
      )}
      {enableSkipComponent &&
        isOrchestration &&
        executableAsSingleComponent && (
          <SkipRunButton
            componentInstanceId={
              topLevelIterator
                ? topLevelIterator.instanceId
                : selectedComponentInstanceId
            }
          />
        )}
      <IconButton
        disabled={isUnknown}
        onClick={() => {
          copyComponent()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.copyComponent')}
      >
        <CopyIcon />
      </IconButton>
      {enableDetachIterator && topLevelIterator && (
        <IconButton
          data-testid="btn-detach"
          onClick={() => {
            detach(topLevelIterator.instanceId)
          }}
          className={classes.Toolbar__Button}
          label={t('componentToolbox.detachComponent')}
        >
          <DetachIcon />
        </IconButton>
      )}
      {hasInputPorts &&
        !isUnknown &&
        isOrchestration &&
        isNotAnUnconnectedIterator &&
        enableDetachIterator && <IterateButton componentInstance={component} />}
      <IconButton
        disabled={isSingleStartComponent}
        onClick={() => {
          deleteNodes()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.deleteComponent')}
      >
        <Icon.Trash />
      </IconButton>
    </div>
  )
}
