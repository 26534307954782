import { type FC } from 'react'

import { format, formatISO } from 'date-fns'

interface Props {
  timestamp?: string
}

export const DateTime: FC<Props> = ({ timestamp }) => {
  if (!timestamp) {
    return null
  }
  const date = new Date(timestamp)
  return <time dateTime={formatISO(date)}>{format(date, 'HH:mm:ss')}</time>
}
