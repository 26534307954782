import { useRef } from 'react'
import { Panel, type ImperativePanelHandle } from 'react-resizable-panels'
import { useLocation } from 'react-router-dom'

import { draggingRightPanelEventName } from 'events/dragging-right-panel'

import { ResizeableHandler } from 'components/ResizeableHandler/ResizeableHandler'
import { Orientation } from 'components/ResizeableHandler/types'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ComponentPropertiesPanel from 'modules/ComponentProperties/ComponentPropertiesPanel'

import classes from './RightPanel.module.scss'

const RightPanel = () => {
  const { componentId } = useProjectInfo()

  const componentPropertiesPanelRef = useRef<ImperativePanelHandle>(null)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const closePanelParam = params.get('closeRightPanel') === 'true'

  return componentId !== null &&
    componentId !== undefined &&
    !closePanelParam ? (
    <>
      <ResizeableHandler
        orientation={Orientation.VERTICAL}
        id="right-panel"
        collapseButtonClassName={classes.RightPanel__Collapse}
        onDragging={() => {
          document.dispatchEvent(new CustomEvent(draggingRightPanelEventName))
        }}
      />
      <Panel
        ref={componentPropertiesPanelRef}
        defaultSize={35}
        minSize={15}
        maxSize={75}
        order={2}
        id="component-properties"
      >
        <ComponentPropertiesPanel />
      </Panel>
    </>
  ) : null
}

export default RightPanel
