import classnames from 'classnames'

import classes from './SelectableCard.module.scss'

export interface SelectableCardProps {
  selected?: boolean
  onSelection: () => void
  children: React.ReactNode
}

export function SelectableCard({
  selected,
  onSelection,
  children
}: Readonly<SelectableCardProps>) {
  return (
    <div
      onClick={onSelection}
      className={classnames(classes.SelectableCard, {
        [classes['SelectableCard--selected']]: selected
      })}
    >
      {children}
    </div>
  )
}
