export interface SseEvent {
  event: string
  data: string
}

/**
 * Parses a raw SSE message string into an SseEvent object.
 *
 * A single event can contain multiple data lines
 * which will be concatenated into a single data string.
 * @Example message: "event:demoEvent\ndata:Evaluating request\ndata:Doing something else\n\n"
 * @Returns { event: 'demoEvent', data: 'Evaluating request\nDoing something else' }
 */
export const parseSseMessage = (message: string): SseEvent => {
  const lines = message.split('\n')
  let eventType = 'message' // Event field is not required per spec, default to 'message'
  let data = ''

  for (const line of lines) {
    const trimmedLine = line.trim()
    if (trimmedLine.startsWith('event:')) {
      // If more than one event type is specified, the last one will be used
      eventType = trimmedLine.slice('event:'.length).trim()
    } else if (trimmedLine.startsWith('data:')) {
      // Concatenate data lines
      data += trimmedLine.slice('data:'.length).trim() + '\n'
    }
  }

  return { event: eventType, data: data.trim() }
}

/**
 * Processes an SSE stream, reading events and handling them using the provided callback.
 */
export const processSseStream = async (
  reader: ReadableStreamDefaultReader,
  handleEvent: (event: SseEvent) => void
): Promise<void> => {
  const decoder = new TextDecoder('utf-8')
  let buffer = ''

  try {
    let done = false
    while (!done) {
      const result = await reader.read()
      done = result.done
      // Decode the chunk and append to the buffer
      buffer += decoder.decode(result.value || new Uint8Array(), {
        stream: !done
      })

      // Split the buffer into messages
      const parts = buffer.split('\n\n')
      buffer = parts.pop() || '' // Keep the last part in the buffer as it may be incomplete

      // Process each message
      for (const part of parts) {
        if (part.trim() !== '') {
          const event = parseSseMessage(part)
          handleEvent(event)
        }
      }
    }

    if (buffer.trim() !== '') {
      console.warn('Discarding incomplete message at SSE stream end:', buffer)
    }
  } catch (error) {
    console.error('Error reading SSE stream:', error)
    throw error
  }
}
