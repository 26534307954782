import { useCallback, useMemo, type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FileType, type File } from '@matillion/git-component-library'
import classnames from 'classnames'
import { type TFunction } from 'i18next'

import { type RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

import { InteractiveTab, InteractiveTabs } from 'components/InteractiveTabs'

import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs, type SelectedTab } from 'hooks/useSelectedJobs'
import { useSelectedTasksActivity } from 'hooks/useSelectedTasksActivity/useSelectedTasksActivity'

import { EtlDocumentTabIcon } from './components/EtlDocumentTabIcon'
import classes from './FileTabs.module.scss'
import { TaskType, type EtlDocument } from './types'

const formatSelectedTask = (
  task: RunTaskStatus,
  activeTaskId: string | undefined,
  closeTab: (tab: SelectedTab) => void,
  navigateToTask: (name: string) => void,
  t: TFunction
): EtlDocument => {
  return {
    key: `${TaskType.FlowSummary}-${task.id}`,
    id: task.id.toString(),
    title: `${t('taskHistory.tabNamePrefix')} ${task.jobName}`,
    type: TaskType.FlowSummary,
    isActive: task.id.toString() === activeTaskId,
    closeDocument: () => {
      closeTab({ id: task.id.toString(), type: 'task' })
    },
    openDocument: () => {
      navigateToTask(task.id.toString())
    }
  }
}
const formatSelectedFile = (
  file: File,
  activeJobId: string,
  closeTab: (tab: SelectedTab) => void,
  navigateToJob: (name: string) => void
): EtlDocument => {
  return {
    key: `${file.type}-${file.name}`,
    id: file.name,
    title: file.fileName,
    type: file.type ?? FileType.UNKNOWN,
    isActive: file.name === activeJobId,
    closeDocument: () => {
      closeTab({ id: file.name, type: 'job' })
    },
    openDocument: () => {
      navigateToJob(file.name)
    }
  }
}

export const FileTabs = () => {
  const { files, isError } = usePipelines()
  const { t } = useTranslation()

  const { jobSummaryId: activeJobId, flowInstanceId: activeTaskId } =
    useProjectInfo()
  const {
    selectedTasks,
    selectedTabs,
    navigateToJob,
    navigateToTask,
    closeTab,
    closeAll,
    closeOthers
  } = useSelectedJobs()
  const selectedTaskIds = useMemo(
    () => selectedTasks.map((task) => task.id),
    [selectedTasks]
  )
  const tasks = useSelectedTasksActivity(selectedTaskIds)

  const selectedDocuments = useMemo(() => {
    const documents: EtlDocument[] = []
    for (const { id, type } of selectedTabs) {
      if (type === 'job') {
        const file = Object.values<File>(files).find((f) => f.name === id)
        if (file) {
          documents.push(
            formatSelectedFile(file, activeJobId, closeTab, navigateToJob)
          )
        }
      } else {
        const task = tasks.find((_t) => _t.id.toString() === id)
        if (task) {
          documents.push(
            formatSelectedTask(task, activeTaskId, closeTab, navigateToTask, t)
          )
        }
      }
    }
    return documents
  }, [
    files,
    tasks,
    selectedTabs,
    activeJobId,
    activeTaskId,
    closeTab,
    navigateToJob,
    navigateToTask,
    t
  ])

  const activeTabIndex = useMemo(
    () => selectedDocuments.findIndex((document) => document.isActive),
    [selectedDocuments]
  )

  const onTabChange = useCallback(
    (tab: { value: number }) => {
      const document = selectedDocuments[tab.value]
      document.openDocument()
    },
    [selectedDocuments]
  )

  const onTabClose = useCallback((event: MouseEvent, document: EtlDocument) => {
    event.preventDefault()
    event.stopPropagation()
    document.closeDocument()
  }, [])

  const onCloseAllTabs = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      closeAll()
    },
    [closeAll]
  )

  const onCloseOthers = useCallback(
    (event: MouseEvent, index: number) => {
      event.preventDefault()
      event.stopPropagation()

      closeOthers(index)
    },
    [closeOthers]
  )

  if (isError) {
    return null
  }

  if (selectedDocuments.length <= 0) {
    return null
  }

  return (
    <InteractiveTabs
      className={classes.FileTabs__Tabs}
      contentClassName={classes.FileTabs__TabContent}
      listClassName={classes.FileTabs__TabList}
      activeIndex={activeTabIndex}
      onChange={onTabChange}
      onCloseAll={onCloseAllTabs}
    >
      {selectedDocuments.map((doc: EtlDocument, index) => (
        <InteractiveTab
          key={doc.key}
          data-testid={`tab-${doc.key}`}
          className={classnames(classes.FileTabs__Tab, {
            [classes['FileTabs__Tab--Orchestration']]:
              doc.type === FileType.ORCHESTRATION_PIPELINE,
            [classes['FileTabs__Tab--Transformation']]:
              doc.type === FileType.TRANSFORMATION_PIPELINE,
            [classes['FileTabs__Tab--ActiveTab']]: index === activeTabIndex
          })}
          internalKey={doc.key}
          title={doc.title}
          tooltip={doc.title}
          iconBefore={<EtlDocumentTabIcon documentType={doc.type} />}
          onTabClose={(event: MouseEvent) => {
            onTabClose(event, doc)
          }}
          onCloseOthers={(event: MouseEvent) => {
            onCloseOthers(event, index)
          }}
        />
      ))}
    </InteractiveTabs>
  )
}
