import { useDispatch } from 'react-redux'

import { useFlags } from '../../../hooks/useFlags'
import { useWorkingCopy as useDPLWorkingCopy } from '../../../modules/core/WorkingCopyProvider'
import { useFlaggedWorkingCopy } from '../../../modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { getComponentName } from '../../job-functions/getComponentName'
import { jobActions } from '../../store'
import { ComponentActivationStatus } from '../../types/Components'

export const useSkipComponent = () => {
  const dispatch = useDispatch()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)
  const { job } = useFlaggedWorkingCopy()

  const skipComponent = (componentInstanceId: number, skipped: boolean) => {
    if (componentInstanceId === undefined) {
      return
    }

    if (rolloutEnableWorkingCopyProvider) {
      if (!job) {
        return
      }
      const componentName = getComponentName(
        job.components[componentInstanceId]
      )
      update((state) => {
        state.skipComponent({ componentName, skipped })
      })
    } else {
      const activationStatus = skipped
        ? ComponentActivationStatus.DISABLED
        : ComponentActivationStatus.ENABLED
      dispatch(
        jobActions.skipComponent({ componentInstanceId, activationStatus })
      )
    }
  }

  return { skipComponent }
}
