import { type FC, type PropsWithChildren, type ReactNode } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './EmptyPlaceholder.module.scss'

export interface EmptyPlaceholderProps {
  className?: string
  type?: 'error' | 'info'
}

const typeIcons: Record<Required<EmptyPlaceholderProps>['type'], ReactNode> = {
  error: <Icon.Error />,
  info: <Icon.InfoNoBg />
}

export const EmptyPlaceholder: FC<PropsWithChildren<EmptyPlaceholderProps>> = ({
  className,
  type = 'info',
  children
}) => {
  const icon = typeIcons[type]
  return (
    <div className={classnames(classes.EmptyPlaceholder, className)}>
      {icon}
      <Typography format="bcs">{children}</Typography>
    </div>
  )
}
