import { type PanelResizeHandleOnDragging } from 'react-resizable-panels'

export interface Props {
  orientation: Orientation
  hideDragHandle?: boolean
  dragHandlePosition?: DragHandlePosition
  disabled?: boolean
  hidden?: boolean
  id?: string
  showCollapseButton?: boolean
  collapseButtonClassName?: string
  onCollapseButtonClick?: () => void
  onDragging?: PanelResizeHandleOnDragging
  showIndicatorBar?: boolean
}

export enum Orientation {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical'
}

export enum DragHandlePosition {
  BEFORE = 'Before',
  AFTER = 'After'
}
