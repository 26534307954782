import {
  State,
  type GenericTaskStatus,
  type HistoryTaskStatus
} from 'api/hooks/useGetRunTasks/types'

import { customFormatDate } from '../../../observability/PipelineRunExplorer/utils/statsFormatters'

export const MAX_HISTORY_PERSISTENCE = 1000 * 60 * 60
const VERBATIM_STATUSES = [State.Running, State.Cancelling, State.Cancelled]

export const historyComparer = (
  a: GenericTaskStatus,
  b: GenericTaskStatus
): number => {
  return b.enqueuedTime - a.enqueuedTime
}

export const completedTimeOrInterimState = (
  task: HistoryTaskStatus,
  t: (key: string) => string
) => {
  if (VERBATIM_STATUSES.includes(task.state)) {
    return t(`taskExplorer.status.${task.state}`)
  }
  return customFormatDate(task.endTime)
}
