import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, TreeItem, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useSchemaTables } from 'api/hooks/useSchemaTables/useSchemaTables'

import { ReactComponent as TableIcon } from 'assets/SchemaTable.svg'

import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import type { SimpleRefetchRef } from 'modules/ui/SchemaBrowser/types'

import classes from '../../SchemaRow.module.scss'
import { RowEmpty } from '../RowEmpty/RowEmpty'
import { RowError } from '../RowError/RowError'
import { RowLoading } from '../RowLoading/RowLoading'

interface Props {
  schemaName: string
}

const TablesTree = forwardRef<SimpleRefetchRef, Props>(
  ({ schemaName }, ref) => {
    const { warehouse } = useProjectNames()
    const { t } = useTranslation()
    const {
      isLoading,
      isRefetching,
      isError,
      refetch,
      data: tables
    } = useSchemaTables(warehouse, schemaName)

    useImperativeHandle(
      ref,
      () => ({
        refetch
      }),
      [refetch]
    )

    if (isLoading || isRefetching) {
      return (
        <TreeItem
          nodeId={`${schemaName}-table-loading`}
          label="loading"
          leaf={<RowLoading data-testid="schema-tables-loading" />}
        />
      )
    }

    if (isError) {
      return (
        <TreeItem
          nodeId={`${schemaName}-table-error`}
          label="error"
          leaf={
            <RowError
              data-testid="schema-tables-error"
              text={t('sideBar.schemaPanel.rowTablesError')}
            />
          }
        />
      )
    }

    if (!tables?.length) {
      return (
        <TreeItem
          nodeId={`${schemaName}-table-no-data`}
          label="no tables"
          leaf={
            <RowEmpty
              data-testid="schema-tables-empty"
              text={t('sideBar.schemaPanel.noTables')}
            />
          }
        />
      )
    }

    return (
      <>
        {tables.map((table) => (
          <TreeItem
            key={`${schemaName}-${table.name}`}
            nodeId={table.name}
            label={table.name}
            leaf={
              <div
                className={classNames(classes.Folder, classes.DefaultCursor)}
                data-testid={`tree-schema-table-${schemaName}-${table.name}`}
              >
                <TableIcon className={classes.Folder__Icon} aria-hidden />
                <Tooltip content={table.name} onlyShowOnOverflow>
                  <div className={classes.SchemaTooltip}>
                    <Typography
                      format="bcs"
                      className={classes.SchemaTooltip__Text}
                      as="span"
                    >
                      {table.name}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            }
          />
        ))}
      </>
    )
  }
)

TablesTree.displayName = 'TablesTree'

export { TablesTree }
