import { useCallback, useMemo } from 'react'

import {
  type ComponentMetadataParameterId,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import {
  orchestrationJobNameParameter,
  runOrchestrationComponentId
} from 'job-lib/cisIds/knownComponentParameters'

import { useFlags } from '../useFlags'

export interface ClientDataFilteringProps {
  componentSummaryId?: ComponentSummaryId
  componentParameters: ComponentParameter[]
  parameterId?: ComponentMetadataParameterId
  lookupType?: string | null
}

export type FilterOptionsFn = (column: EditorColumn[]) => EditorColumn[]

export interface Filters {
  [key: ComponentMetadataParameterId]: FilterOptionsFn
}

const useFilterRunOrchestrationParameter = (): Filters => {
  const { pipelineSummary } = useActivePipelineSummary()

  return useMemo<Filters>(
    () => ({
      [orchestrationJobNameParameter]: (columns) =>
        columns.map((column) => {
          if (!pipelineSummary || !column.options) {
            return column
          }

          return {
            ...column,
            options: column.options.filter(
              (option) => option !== pipelineSummary.runtimeName
            )
          }
        })
    }),
    [pipelineSummary]
  )
}

export type InputFilterOptionsFn = (
  modularOutputAllowLists: Record<string, string[]>,
  columns: EditorColumn[],
  inputId?: string
) => EditorColumn[]

const modularOutputFilter: InputFilterOptionsFn = (
  modularOutputAllowList,
  columns,
  inputId
) => {
  if (!inputId || Object.keys(modularOutputAllowList).length === 0) {
    return columns
  }
  return columns.map((column) => {
    return {
      ...column,
      options:
        column.options?.filter((option) => {
          if (Object.keys(modularOutputAllowList).includes(option)) {
            /* If an allow-list is defined for the output, we only retain it if the input is in the allow-list. */
            return modularOutputAllowList[option].includes(inputId)
          }
          /* If no allow-list is defined for the output, retain it. */
          return true
        }) ?? []
    }
  })
}

export const useClientDataFiltering = ({
  parameterId,
  componentParameters,
  componentSummaryId,
  lookupType
}: ClientDataFilteringProps) => {
  const runOrchestrationFilters = useFilterRunOrchestrationParameter()
  const { modularOutputAllowList } = useFlags()

  const filterColumns = useCallback(
    (editorColumns: EditorColumn[]) => {
      let filteredColumns = editorColumns

      if (lookupType === 'DYNAMIC_MODULAR_OUTPUT_TYPES') {
        const inputId =
          componentParameters.find((param) => param.dplID === 'inputId')
            ?.defaultValue ?? undefined
        filteredColumns = modularOutputFilter(
          modularOutputAllowList,
          filteredColumns,
          inputId
        )
      }

      if (componentSummaryId === runOrchestrationComponentId) {
        const filter = parameterId ? runOrchestrationFilters[parameterId] : null

        if (filter) {
          return filter(filteredColumns)
        }
      }

      return filteredColumns
    },
    [
      lookupType,
      modularOutputAllowList,
      componentSummaryId,
      componentParameters,
      parameterId,
      runOrchestrationFilters
    ]
  )

  return {
    filterColumns
  }
}
