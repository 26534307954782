import {
  type OrchestrationComponentInstanceCollection,
  type OrchestrationJob,
  type TransformationComponentInstanceCollection,
  type TransformationJob
} from 'job-lib/types/Job'

export const removeComponent = ({
  job,
  componentInstanceIds
}: {
  job: OrchestrationJob | TransformationJob
  componentInstanceIds: number[]
}) => {
  const newComponentsObject:
    | TransformationComponentInstanceCollection
    | OrchestrationComponentInstanceCollection = Object.keys(job.components)
    .map((e) => Number(e))
    .filter((id) => !componentInstanceIds.includes(id))
    .reduce(
      (acc, instanceId) => ({
        ...acc,
        [instanceId]: job.components[instanceId]
      }),
      {}
    )

  job.components = newComponentsObject
}
