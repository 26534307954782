import { FileType } from '@matillion/git-component-library'

import type { JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

const getFileDetails = (dataItem: JobConversionRecord) => {
  const pipelineType = dataItem.name.includes('orch')
    ? FileType.ORCHESTRATION_PIPELINE
    : FileType.TRANSFORMATION_PIPELINE

  const fullFilePath = dataItem.name.replace(
    /(\.orch\.yaml|\.ORCHESTRATION|\.tran\.yaml|\.TRANSFORMATION)$/g,
    ''
  )
  const fullPath = fullFilePath
  const path = fullFilePath.split('/')

  const pipelineName = path[path.length - 1]
  const filePath = fullFilePath.replace('/' + pipelineName, '')

  return {
    pipelineType,
    pipelineName,
    fullPath,
    filePath
  }
}

export default getFileDetails
