import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Toaster, Typography } from '@matillion/component-library'

import classes from './CopyConversionReportButton.module.scss'

export interface CopyConversionReportButtonProps {
  contents: string
}

const CopyConversionReportButton = ({
  contents
}: CopyConversionReportButtonProps) => {
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation('translation', { keyPrefix: 'conversion.modal' })

  const handleCopyReport = useCallback(async () => {
    await window.navigator.clipboard.writeText(contents)

    makeToast({
      type: 'info',
      title: t('results.report.copied-toast.title'),
      message: t('results.report.copied-toast.message')
    })
  }, [makeToast, contents, t])

  return (
    <Button
      alt="text"
      size="md"
      className={classes.button}
      onClick={() => {
        void handleCopyReport()
      }}
      data-testid="copy-conversion-report-button"
    >
      <Typography as="span" format="bcs">
        {t('results.report.copy')}
      </Typography>
      <Icon.Export />
    </Button>
  )
}

export default CopyConversionReportButton
