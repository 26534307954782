import {
  useContext,
  useMemo,
  useState,
  type FC,
  type Key,
  type PropsWithChildren
} from 'react'

import { LayoutContext } from './LayoutContext'

export const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSampleRowCountFetchRequested, setIsSampleRowCountFetchRequested] =
    useState(false)
  const [isSampleFetchRequested, setIsSampleFetchRequested] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState<Key | null>(null)

  const obj = useMemo(
    () => ({
      isSampleFetchRequested,
      setIsSampleFetchRequested,
      isSampleRowCountFetchRequested,
      setIsSampleRowCountFetchRequested,
      activeTabKey,
      setActiveTabKey
    }),
    [activeTabKey, isSampleFetchRequested, isSampleRowCountFetchRequested]
  )

  return <LayoutContext.Provider value={obj}>{children}</LayoutContext.Provider>
}

export const useLayoutProvider = () => useContext(LayoutContext)
