import { useContext } from 'react'

import { ParameterOverlayErrorContext } from '../parameterOverlayErrorContext'

export const useParameterOverlayErrors = () => {
  const context = useContext(ParameterOverlayErrorContext)

  if (context === undefined) {
    throw new Error(
      'You cannot use the hook useParameterOverlayErrors outside of the ParameterOverlayErrorContext'
    )
  }

  return context
}
