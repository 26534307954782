import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import { useParameterOptionsRequest } from './useParameterOptionsRequest'
import {
  type AutofillRequestType,
  type UseParameterOptionsProps
} from './useParameterOptionsTypes'

type UseParameterOptionsAutofillProps<T extends AutofillRequestType> =
  UseParameterOptionsProps & { requestType: T }

/**
 *
 * Gets the lookupDependencies from parameter.autofill and sends a lookup request
 * to /autofill.
 * Setting the request type to autofill returns a string[][] for data grids.
 * Setting the request type to autofill-nested returns a nested response
 * for the NestedDataPicker
 */
export const useParameterOptionsAutofill = <T extends AutofillRequestType>({
  componentMetaData,
  parameter,
  isEnabled = false,
  requestType
}: UseParameterOptionsAutofillProps<T>) => {
  const request = useParameterOptionsRequest(
    isEnabled,
    componentMetaData,
    parameter,
    requestType
  )
  return useGetParameterOptions(request)
}
