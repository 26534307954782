import { lazy, Suspense, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CollapsiblePanel, Typography } from '@matillion/component-library'

import { type StepMessage } from 'api/hooks/useEosApi/types/eos'

import { Loading } from 'components/Loading/Loading'

import classes from './AgentStackTrace.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface AgentStackTraceProps {
  messages: StepMessage[]
}

export const AgentStackTrace: FC<AgentStackTraceProps> = ({ messages }) => {
  const { t } = useTranslation()

  const stackTraceTitle = (
    <div className={classes.AgentStackTrace__Title}>
      <Typography as="h2" format="bcm">
        {t('taskExplorer.cellMessage.agentStackTraceTitle')}
      </Typography>
    </div>
  )

  return (
    <div
      className={classes.AgentStackTrace}
      data-testid="agent-stack-trace-container"
    >
      <CollapsiblePanel
        title={stackTraceTitle}
        subtitle=""
        className={classes.AgentStackTrace}
        contentClassName={classes.AgentStackTrace__Content}
      >
        {messages.map(({ description }) => (
          <div key={description} className={classes.AgentStackTrace__Editor}>
            <Suspense fallback={<Loading />}>
              <CodeEditor value={description} readOnly />
            </Suspense>
          </div>
        ))}
      </CollapsiblePanel>
    </div>
  )
}
