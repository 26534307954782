import { ErrorStatuses } from 'modules/ManageVariables/types'

export const columnHeaderTranslations: Record<string, string> = {
  columnName: 'manageVariables.createGridVar.grids.columnNameHeader',
  columnType: 'manageVariables.createGridVar.grids.columnTypeHeader',
  defaultValue: 'manageVariables.createGridVar.grids.defaultValueHeader'
}

export const gridColumnNameErrors: Record<string, string> = {
  [ErrorStatuses.RESERVED_WORD]:
    'manageVariables.createGridVar.grids.errors.restrictedField',
  [ErrorStatuses.DUPLICATE]:
    'manageVariables.createGridVar.grids.errors.duplicate',
  [ErrorStatuses.EMPTY]:
    'manageVariables.createGridVar.grids.errors.emptyField',
  [ErrorStatuses.SELECT_OPTION]:
    'manageVariables.createGridVar.grids.errors.pleaseSelectAnOption',
  [ErrorStatuses.INCORRECT_NAME_FORMAT]:
    'manageVariables.createGridVar.grids.errors.variableNameFormat',
  [ErrorStatuses.INCORRECT_NUMBER_FORMAT]:
    'manageVariables.createGridVar.grids.errors.numberFormat',
  [ErrorStatuses.SINGLE_$]:
    'manageVariables.createGridVar.grids.errors.single$',
  [ErrorStatuses.TOO_LONG]:
    'manageVariables.createGridVar.grids.errors.tooLong',
  [ErrorStatuses.NO_ERROR]: ''
}
