import { useUser } from '@matillion/hub-client'
import axios from 'axios'

import config from 'config'

import useAuthInterceptor from '../../useAuthInterceptor/useAuthInterceptor'

export const useCopilotApiClient = () => {
  const { organisation } = useUser()

  const client = axios.create({
    baseURL: config.getCopilotApiBaseUrl(organisation.region),
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 120000
  })

  useAuthInterceptor(client)

  return client
}
