import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import {
  type ComponentNodeData,
  type NodeInfo
} from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import {
  getNodeInfo,
  getSelectedNodeInfo
} from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useFlags } from 'hooks/useFlags'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const useDeleteNodes = () => {
  const dispatch = useDispatch()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const { invalidateComponent } = useComponentValidationProvider()
  const update = useDPLWorkingCopy((state) => state.update)
  const { job } = useFlaggedWorkingCopy()
  const etlFlow = useEtlFlow()

  const deleteNodes = useCallback(
    (selectedNodeInfos: NodeInfo[] = getSelectedNodeInfo(etlFlow)) => {
      const withAttachedNodes = [...selectedNodeInfos]
      for (const node of selectedNodeInfos) {
        const attachedNodes = (node.data as ComponentNodeData).attachedNodes
        if (attachedNodes) {
          withAttachedNodes.push(...attachedNodes.map(getNodeInfo))
        }
      }
      if (rolloutEnableWorkingCopyProvider) {
        const componentsToDelete: string[] = withAttachedNodes
          .map((info) => job?.components[info.id])
          .map((component) => {
            return component ? getComponentName(component) : ''
          })
          .filter(Boolean)

        update((state) => {
          const notesToDelete = selectedNodeInfos.filter(
            (info) => info.type === 'note'
          )
          if (notesToDelete.length > 0) {
            state.deleteNotes(notesToDelete.map((note) => String(note.id)))
          }

          state.deleteComponents(componentsToDelete)
        })
      } else {
        dispatch(jobActions.deleteNodes(withAttachedNodes))
      }
      withAttachedNodes.forEach((info) => {
        if (info.type === 'component') {
          invalidateComponent(info.id)
        }
      })
    },
    [
      etlFlow,
      rolloutEnableWorkingCopyProvider,
      update,
      job,
      dispatch,
      invalidateComponent
    ]
  )

  return { deleteNodes }
}
