import { useEffect, useState } from 'react'

import { useFlags } from 'hooks/useFlags'

import type { PipelineStatus } from '../useEosApi/types/eos'
import { useGetStepsInfinite } from '../useGetSteps/useGetSteps'

const COMPLETED_STATUSES: PipelineStatus[] = [
  'SUCCESS',
  'FAILED',
  'CANCELLED',
  'SKIPPED'
]

export const useGetPollableStepsInfinite = (
  pollInterval: number | undefined,
  pipelineId: string
) => {
  const { enableTaskDetailsPolling } = useFlags()

  const [shouldPoll, setShouldPoll] = useState<boolean>(
    enableTaskDetailsPolling
  )

  // We let React Query handle the polling for us by configuring the query with a refetch interval if
  // we are still waiting for the root pipeline to complete, or leaving it undefined if we are not
  const refetchInterval = shouldPoll ? pollInterval : undefined

  const useGetStepsInfiniteResponse = useGetStepsInfinite(
    pipelineId,
    refetchInterval,
    'always'
  )

  useEffect(() => {
    const { steps, hasMore } = useGetStepsInfiniteResponse

    const allStepsComplete = steps
      ?.map((step) => step.result.status)
      .every((status) => COMPLETED_STATUSES.includes(status))

    const allNestedStepsComplete = steps
      ?.map((step) => step.nestedSteps)
      .every((nestedSteps) =>
        nestedSteps.every((nestedStep) =>
          COMPLETED_STATUSES.includes(nestedStep.result.status)
        )
      )

    if (allStepsComplete && allNestedStepsComplete && !hasMore) {
      setShouldPoll(false)
    }
  }, [useGetStepsInfiniteResponse])

  return {
    ...useGetStepsInfiniteResponse,
    isPolling: shouldPoll
  }
}
