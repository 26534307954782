import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { type GridVariable } from 'job-lib/types/Variables'

import { type FormState } from 'modules/ManageVariables/types'

import { type ReducerAction } from '../../../Form/formReducer/formReducer'
import { type StepDetail } from '../types'
import { VariableInfoStep } from './VariableInfoStep'

export const useVariableInfoStep = (
  dispatch: Dispatch<ReducerAction>,
  state: FormState,
  isEditing: boolean,
  variableToEdit: GridVariable
): StepDetail => {
  const { t } = useTranslation()
  return {
    content: (
      <VariableInfoStep
        dispatch={dispatch}
        state={state}
        variableToEdit={variableToEdit}
      />
    ),
    isInvalid: (s: FormState) => !s.isFormValid,
    title: t(
      isEditing
        ? 'manageVariables.editGridVar.variableInfoTitle'
        : 'manageVariables.createGridVar.variableInfoTitle'
    )
  }
}
