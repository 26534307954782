import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  LoadingSpinner,
  Section,
  Typography
} from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import classnames from 'classnames'

import config from 'config'

import { usePipelines } from 'hooks/usePipelines/usePipelines'

import {
  FileBrowserCommand,
  PipelineBrowserCommandProvider,
  usePipelineBrowserCommandProvider
} from 'modules/FileBrowser'

import { useFlags } from '../../hooks/useFlags'
import {
  EXPERIMENT_LOCAL_STORAGE_KEY,
  INITIAL_PIPELINE_IDS
} from '../FileBrowser/hooks/useExperimentMyFirstPipeline'
import { PipelineCard } from './components/PipelineCard'
import classes from './GettingStarted.module.scss'

export function GettingStarted() {
  const { t } = useTranslation()
  const { files: pipelines, isLoading } = usePipelines()
  const [waitingForMyFirstPipeline, setWaitingForMyFirstPipeline] =
    useState(false)
  const { enableMyFirstPipelineExperiment } = useFlags()
  const myFirstPipelineLocalStoragePresent =
    localStorage.getItem(EXPERIMENT_LOCAL_STORAGE_KEY) === 'true'

  useEffect(() => {
    const hasInitialPipelines = INITIAL_PIPELINE_IDS.every((id) =>
      Object.values(pipelines).some(
        (pipeline) => pipeline.name === id && pipeline.status === 'UNMODIFIED'
      )
    )

    const onlyInitialPipelines =
      Object.values(pipelines).length === 3 && hasInitialPipelines

    const myFirstPipelineNotYetCreated =
      enableMyFirstPipelineExperiment &&
      !myFirstPipelineLocalStoragePresent &&
      onlyInitialPipelines

    if (
      myFirstPipelineNotYetCreated ||
      (isLoading &&
        enableMyFirstPipelineExperiment &&
        !myFirstPipelineLocalStoragePresent)
    ) {
      setWaitingForMyFirstPipeline(true)
    } else {
      setTimeout(() => {
        setWaitingForMyFirstPipeline(false)
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableMyFirstPipelineExperiment, pipelines])

  if (waitingForMyFirstPipeline) {
    return (
      <div
        className={classnames(classes.GettingStarted__Loading)}
        data-testid="getting-started-loading"
      >
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className={classes.GettingStarted}>
      <div className={classes.GettingStarted__Title}>
        <Typography as="h2" format="tl">
          {t('translation:canvas.noJobSelectedTitle')}
        </Typography>
      </div>
      <PipelineBrowserCommandProvider>
        {() => <CreatePipelineWizard />}
      </PipelineBrowserCommandProvider>
    </div>
  )
}

const sectionSize = {
  small: 12,
  medium: 10,
  large: 8
}

const sectionOffset = {
  small: 0,
  medium: 1,
  large: 2
}

function CreatePipelineWizard() {
  const { t } = useTranslation()
  const { onCommand } = usePipelineBrowserCommandProvider()

  return (
    <Grid>
      <Section size={sectionSize} offset={sectionOffset}>
        <div className={classes.GettingStarted__CardsContainer}>
          <PipelineCard
            data-testid="orchestration-card"
            data-tracker-id="orchestration-card"
            videoLinkTrackerId="getting-started-orchestration-video-link"
            videoGuideURL={config.docs.orchestrationVideoUrl}
            positiveTag={t('translation:canvas.jobRecommendation')}
            onClick={() => {
              onCommand({
                type: FileBrowserCommand.CREATE_PIPELINE,
                payload: FileType.ORCHESTRATION_PIPELINE
              })
            }}
            title={t('translation:canvas.orchestrationJob')}
            description={t('translation:canvas.orchestrationDescription')}
          />
          <PipelineCard
            data-testid="transformation-card"
            data-tracker-id="transformation-card"
            videoLinkTrackerId="getting-started-transformation-video-link"
            videoGuideURL={config.docs.transformationVideoUrl}
            onClick={() => {
              onCommand({
                type: FileBrowserCommand.CREATE_PIPELINE,
                payload: FileType.TRANSFORMATION_PIPELINE
              })
            }}
            title={t('translation:canvas.transformationJob')}
            description={t('translation:canvas.transformationDescription')}
          />
        </div>
      </Section>
    </Grid>
  )
}
