import {
  type DragLeftToRightProps,
  type DragRightToLeftProps,
  type DragRightToRightProps,
  type UnselectOppositeColumnProps
} from './types'

export const unselectOppositeColumn = (props: UnselectOppositeColumnProps) => {
  let {
    droppableId,
    draggableId,
    rightColumn,
    leftColumn,
    source,
    leftColumnSelected,
    rightColumnSelected,
    setSelectedItems
  } = props

  if (droppableId === 'leftColumn' && leftColumnSelected.length < 2) {
    leftColumnSelected = [draggableId]
  } else if (droppableId === 'rightColumn' && rightColumnSelected.length < 2) {
    rightColumnSelected = [draggableId]
  }

  if (source.droppableId === 'rightColumn') {
    setSelectedItems(filterSelectedItems(rightColumnSelected, rightColumn))
  } else if (source.droppableId === 'leftColumn') {
    setSelectedItems(filterSelectedItems(leftColumnSelected, leftColumn))
  } else {
    setSelectedItems([])
  }
}

const filterSelectedItems = (
  selectedItems: string[],
  column: string[]
): string[] => {
  const result = []
  for (const element of selectedItems) {
    if (column.includes(element)) {
      result.push(element)
    }
  }
  return result
}

export const reorderRightColumn = (props: DragRightToRightProps): void => {
  const {
    rightColumn,
    source,
    destination,
    filteredItems,
    selectedItems,
    setRightColumn,
    onSelect
  } = props

  const selectedItemsWithFilter = selectedItems.flatMap((item) =>
    filteredItems.includes(item) ? [item] : []
  )

  let rightColumnClone = [...rightColumn]
  rightColumnClone = rightColumnClone.filter(
    (item) => !selectedItemsWithFilter.includes(item)
  )
  rightColumnClone.splice(
    destination.index -
      adjustForSelectedItems(
        source.index,
        destination.index,
        selectedItemsWithFilter,
        rightColumn
      ),
    0,
    ...selectedItemsWithFilter
  )
  setRightColumn(rightColumnClone)
  onSelect(rightColumnClone.filter(Boolean))
}

export const dragLeftToRight = (props: DragLeftToRightProps): void => {
  const {
    rightColumn,
    leftColumn,
    destination,
    filteredItems,
    selectedItems,
    setLeftColumn,
    setRightColumn,
    onSelect
  } = props

  const selectedItemsWithFilter = selectedItems.flatMap((item) =>
    filteredItems.includes(item) ? [item] : []
  )

  const rightColumnClone = [...rightColumn]
  let leftColumnClone = [...leftColumn]
  leftColumnClone = leftColumnClone.flatMap((item) => {
    if (selectedItemsWithFilter.includes(item)) {
      return []
    } else {
      return [item]
    }
  })
  rightColumnClone.splice(destination.index, 0, ...selectedItemsWithFilter)
  setRightColumn(rightColumnClone)
  setLeftColumn(leftColumnClone)
  onSelect(rightColumnClone.filter(Boolean))
}

export const dragRightToLeft = (props: DragRightToLeftProps): void => {
  const {
    items,
    rightColumn,
    leftColumn,
    filteredItems,
    selectedItems,
    setLeftColumn,
    setRightColumn,
    onSelect
  } = props

  const selectedItemsWithFilter = selectedItems.flatMap((item) =>
    filteredItems.includes(item) ? [item] : []
  )

  const rightColumnClone = [...rightColumn]
  const leftColumnClone = [...leftColumn]
  const newLeftColumn: string[] = []
  for (const element of items) {
    if (
      leftColumnClone.includes(element) ||
      selectedItemsWithFilter.includes(element)
    ) {
      newLeftColumn.push(element)
    }
  }
  const newRightColumn = rightColumnClone.filter(
    (item) => !selectedItemsWithFilter.includes(item)
  )
  setRightColumn(newRightColumn)
  setLeftColumn(newLeftColumn)

  onSelect(newRightColumn.filter(Boolean))
}

export const adjustForSelectedItems = (
  source: number,
  position: number,
  selectedItems: string[],
  rightColumn: string[]
): number => {
  let counter = source < position ? -1 : 0
  for (let i = 0; i < position; i++) {
    if (rightColumn[i] !== null && selectedItems.includes(rightColumn[i])) {
      counter++
    }
  }
  return counter
}
