import { useState } from 'react'

import { t } from 'i18next'
import type { Components, Pipeline } from 'types/Pipeline'
import { v4 as uuid } from 'uuid'

import type { OrchestrationJob, TransformationJob } from 'job-lib/types/Job'

import { type CopilotChatMessage } from 'modules/core/Copilot/types'

import type { PipelineContext } from '../model'
import { useCopilotApi } from '../useCopilotApi/useCopilotApi'
import { processSseStream } from './sseUtils'

export interface CopilotComponentUpdateConfig {
  id: string
  summary: string
  addedComponents: Components
  source: Pipeline
  legacyMETL: TransformationJob | OrchestrationJob
}

export interface ChatInput {
  requestId: string
  pipeline: PipelineContext
  warehouse: string
  message: string
}

interface SseEvent {
  event: string
  data: string
}

type SseEventType = 'status' | 'message' | 'updatePipeline'

export const useChatWithSse = (
  setStatus: (value: string) => void,
  addInfoMessage: (message: CopilotChatMessage) => void,
  updateComponents: (components: CopilotComponentUpdateConfig) => Promise<void>
) => {
  const { performChat: callPerformChatApi } = useCopilotApi()

  const [isLoading, setIsLoading] = useState(false)

  const handleUpdatePipelineEvent = async (event: SseEvent) => {
    const updatePipelineConfig: CopilotComponentUpdateConfig = JSON.parse(
      event.data
    )
    updateComponents(updatePipelineConfig).catch((e) => {
      console.error('Error updating components', e)
    })
  }

  const handleStatusEvent = (event: SseEvent) => {
    setStatus(event.data)
  }

  const handleMessageEvent = (event: SseEvent) => {
    const response: { chatResponse: string } = JSON.parse(event.data)
    addInfoMessage({
      id: uuid(),
      content: response.chatResponse,
      type: 'bot',
      service: 'PIPELINE_GENERATION'
    })
  }

  const eventHandlerMap = {
    status: handleStatusEvent,
    message: handleMessageEvent,
    updatePipeline: handleUpdatePipelineEvent
  }

  const handleSseMessage = (event: SseEvent) => {
    const eventType = event.event as SseEventType
    const handler = eventHandlerMap[eventType]
    if (handler) {
      handler(event)
    } else {
      console.warn(`No handler for SSE event type: ${eventType}`)
    }
  }

  /**
   * Send an error message to the chat
   */
  const handleUnexpectedChatError = () => {
    addInfoMessage({
      id: uuid(),
      content: t('copilot.chat.errorTryAgainLater'),
      type: 'bot',
      service: 'PIPELINE_GENERATION'
    })
  }

  const processChatResponse = async (response: Response) => {
    if (!response.ok) {
      throw new Error(
        `Copilot response was not successful - status ${response.status}`
      )
    }
    if (!response.body) {
      throw new Error('Copilot response body was empty')
    }
    const reader = response.body.getReader()
    await processSseStream(reader, handleSseMessage)
    // Clean up reader asynchronously
    reader?.cancel().catch(() => {
      console.error('Error cancelling SSE stream reader')
    })
  }

  const performChat = async (input: ChatInput) => {
    if (isLoading) {
      throw new Error('Chat is already in progress')
    }
    setIsLoading(true)

    const abortController = new AbortController()

    try {
      const response = await callPerformChatApi(input, abortController)

      await processChatResponse(response)
    } catch (error) {
      console.error(error)
      handleUnexpectedChatError()
      abortController.abort()
    } finally {
      setIsLoading(false)
    }
  }

  return { performChat, isLoading }
}
