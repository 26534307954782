import { useMemo } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { type AxiosRequestConfig } from 'axios'

import config from 'config'

export enum WorkingTreeStoreClientVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export const useWorkingTreeStoreClient = (
  version: WorkingTreeStoreClientVersion = WorkingTreeStoreClientVersion.V1,
  axiosConfig?: AxiosRequestConfig
) => {
  const { getToken } = useAuth()
  const { organisation: account } = useUser()

  return useMemo(() => {
    const instance = axios.create(axiosConfig ?? {})

    instance.defaults.baseURL = `${config.getWorkingTreeStoreApiBaseUrl(
      account.region
    )}/working-tree-service/${version}`

    instance.interceptors.request.use(async (interceptedConfig) => {
      const authHeader = `Bearer ${await getToken({
        audience: config.apiTokenAudience
      })}`
      interceptedConfig.headers['account-id'] = account.id
      interceptedConfig.headers.Authorization = authHeader

      return interceptedConfig
    })

    return instance
  }, [axiosConfig, account.id, account.region, version, getToken])
}
