import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export const useActivePipelineSummary = () => {
  const { jobSummaryId: activePipelineId } = useProjectInfo()
  const { files, isLoading, isError } = usePipelines()

  // this is pulled out into a separate return so that typescript
  // infers this type as `File | null`--there may not always be an
  // available pipeline for the current activePipelineId

  const pipelineSummary = files[activePipelineId] ?? null

  return {
    pipelineSummary,
    isLoading,
    isError
  }
}
