import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { queryKeys, type ParameterOptionsQueryKey } from 'api/queryKeys'

import { type ParameterOptionsRequestType } from 'hooks/useParameterOptions/useParameterOptionsTypes'
import {
  useProjectInfo,
  type ProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

import { type ComponentInstanceId } from 'job-lib/types/Job'
import {
  type GridVariableCollection,
  type JobVariableCollection
} from 'job-lib/types/Variables'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type LookUpType } from '../useGetComponentMetadata/types'
import useGetProject from '../useGetProject/useGetProject'
import {
  type APIError,
  type GetParameterOptionsResponse,
  type LookupDefinition
} from './types'

export const getParameterOptionsQueryKey = (
  {
    projectId,
    branchId,
    jobSummaryId
  }: Pick<ProjectInfo, 'projectId' | 'jobSummaryId' | 'branchId'>,
  lookupType: LookUpType | null,
  componentId: ComponentInstanceId | null,
  parameterId: string | undefined,
  requestType: ParameterOptionsRequestType,
  dependencies: Record<string, unknown>
) => {
  const keys: ParameterOptionsQueryKey = [
    queryKeys.parameterOptions,
    projectId,
    branchId,
    jobSummaryId,
    lookupType,
    componentId,
    parameterId,
    requestType,
    dependencies
  ]
  return keys
}

export interface UseGetParameterOptionsRequest<
  T extends ParameterOptionsRequestType
> {
  lookupDefinition?: LookupDefinition
  parameterId?: string
  lookupType: LookUpType | null
  isEnabled?: boolean
  variables: JobVariableCollection
  gridVariables: GridVariableCollection
  requestType: T
}

export const getRequestMethod = (type: ParameterOptionsRequestType) => {
  switch (type) {
    case 'autofill':
    case 'autofill-nested':
    case 'autofill-nested-read-only':
      return 'autofill'
    default:
      return 'parameter-options'
  }
}

export const useGetParameterOptions = <T extends ParameterOptionsRequestType>({
  lookupDefinition,
  lookupType,
  parameterId,
  gridVariables,
  variables,
  isEnabled = false,
  requestType
}: UseGetParameterOptionsRequest<T>): UseQueryResult<
  GetParameterOptionsResponse<T> | undefined,
  APIError
> => {
  const {
    projectId,
    branchId,
    jobSummaryId,
    componentId,
    environmentId,
    agentId
  } = useProjectInfo()
  const { getParameterOptions } = useDesignerApi()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''

  const {
    component,
    'transform.sql': transform,
    parameters,
    parameterMetadataDependencies,
    parameterIdDependencies,
    customConnectorProfileDependencies,
    scriptsToEvaluate
  } = lookupDefinition ?? {}

  const dependencies: Record<string, unknown> = {
    ...component,
    ...parameters,
    ...parameterIdDependencies,
    ...(transform ? { 'transform.sql': transform } : {}),
    ...customConnectorProfileDependencies,
    ...(parameterMetadataDependencies ?? {})
  }

  if (scriptsToEvaluate) {
    dependencies.scripts = scriptsToEvaluate
  }

  return useQuery<GetParameterOptionsResponse<T> | undefined, APIError>(
    getParameterOptionsQueryKey(
      { projectId, branchId, jobSummaryId },
      lookupType,
      componentId,
      parameterId,
      requestType,
      dependencies
    ),
    async () => {
      try {
        const { data } = await getParameterOptions<T>({
          requestType: getRequestMethod(requestType),
          lookupType,
          projectId,
          branchId,
          warehouse,
          environmentId,
          agentId,
          requestBody: {
            // keeping for backwards compatibility. Will be removed in a subsequent ticket.
            // https://matillion.atlassian.net/browse/DPCD-735
            ...dependencies,
            variables,
            gridVariables,
            dependencies
          }
        })

        return data
      } catch (error) {
        /* istanbul ignore else */
        if (axios.isAxiosError(error)) {
          throw error.response?.data
        }
      }
    },
    { enabled: isEnabled && !!lookupType, staleTime: 10000 }
  )
}
