import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { type DataGridColumnProps } from '@matillion/component-library'

import {
  Fields,
  ReducerActions,
  type FormState,
  type GridVariableDataGridRow
} from 'modules/ManageVariables/types'
import { FreeTextInputEditor } from 'modules/ParameterEditors/components'

import {
  type EditDataGridAction,
  type ReducerAction
} from '../../../Form/formReducer/formReducer'
import { GridVariableDataGrid } from '../GridVariableDataGrid/GridVariableDataGrid'
import classes from '../Steps.module.scss'

interface DefaultValueGridProps {
  dispatch: Dispatch<ReducerAction>
  state: FormState
}

export const DefaultValueGrid = ({
  dispatch,
  state
}: DefaultValueGridProps) => {
  const { t } = useTranslation()
  const field = Fields.DEFAULT_VALUE_GRID
  const { errors } = state.DEFAULT_VALUE_GRID.value
  const tableColumns: Array<DataGridColumnProps<GridVariableDataGridRow>> =
    state.COLUMN_GRID.value.rows.map((columnGridRows, index) => {
      const columnGridName = columnGridRows.columns[0]
      return {
        key: columnGridName,
        title: columnGridName,
        as: FreeTextInputEditor,
        mapValues: (row) => {
          const id = `${row.id}-${columnGridName}`
          const inputProps: React.ComponentProps<typeof FreeTextInputEditor> = {
            containerClassName: classes.Freetext,
            placeholder: t(
              'manageVariables.createGridVar.grids.textEditorPlaceholder'
            ),
            dataTestId: `default-value-${columnGridName}`,
            inputId: id,
            labelId: id,
            hasError: errors.some(
              (err) =>
                err.rowId === row.id &&
                err.columnName ===
                  state.COLUMN_GRID.value.rows[index].columns[0]
            ),
            value: row.columns[index],
            onEdit: (newValue) => {
              const action: EditDataGridAction = {
                type: ReducerActions.EDIT_DATA_GRID_VALUE,
                field,
                newValue,
                rowId: row.id,
                colIndex: index
              }

              dispatch(action)
            }
          }

          return inputProps
        }
      }
    })

  return (
    <GridVariableDataGrid
      columns={tableColumns}
      dispatch={dispatch}
      rows={state.DEFAULT_VALUE_GRID.value.rows}
      selectedRows={state.DEFAULT_VALUE_GRID.value.selectedRows}
      field={field}
      errors={errors.filter((e) => e.errorType !== 'EMPTY')}
    />
  )
}
