import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Typography } from '@matillion/component-library'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import { TextLink } from 'components/TextLink/TextLink'

import { useSupersededDetails } from 'modules/ComponentParameters/hooks'
import { getDocumentationLink } from 'modules/ComponentProperties/getDocumentationLink'

import classes from './ComponentSupersededWarning.module.scss'

interface ComponentSupersededWarningProps {
  componentMetadata: ComponentMetadata
  componentSummaryId: string
}

export const ComponentSupersededWarning: FC<
  ComponentSupersededWarningProps
> = ({ componentMetadata, componentSummaryId }) => {
  const { t } = useTranslation()
  const { isSuperseded } = useSupersededDetails({ componentMetadata })

  if (!isSuperseded) {
    return null
  }

  return (
    <Alert
      className={classes.ComponentSupersededWarning__Alert}
      title={t('componentProperties.supersededWarning.title')}
      data-testid="superseded-warning"
      message={
        <>
          <Typography type="bcs">
            {t('componentProperties.supersededWarning.message')}
          </Typography>
          <TextLink
            text={t('componentProperties.supersededWarning.learnMore')}
            link={getDocumentationLink(componentSummaryId)}
            dataTestId="superseded-warning-learn-more"
            className={classes.ComponentSupersededWarning__Link}
          />
        </>
      }
      type="warning"
    />
  )
}
