import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import classes from './Actions.module.scss'

interface ActionsProps {
  onSave: () => void
  onCancel: () => void
  disableSave?: boolean
}

export const Actions: FC<ActionsProps> = ({
  onSave,
  onCancel,
  disableSave = false
}) => {
  const { t } = useTranslation()
  return (
    <div className={classes.NavigationContainer}>
      <Button
        data-testid="multi-table-config-cancel"
        alt="secondary"
        onClick={onCancel}
      >
        <Typography format="bcs" weight="bold">
          {t('multiTableConfig.common.cancel')}
        </Typography>
      </Button>
      <Button
        data-testid="multi-table-config-save"
        onClick={onSave}
        disabled={disableSave}
      >
        <Typography format="bcs" weight="bold">
          {t('multiTableConfig.common.save')}
        </Typography>
      </Button>
    </div>
  )
}
