import { type FC } from 'react'
import { type FieldValues } from 'react-hook-form'

import { FileType } from '@matillion/git-component-library'
import { useLDClient } from '@matillion/hub-client'
import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'

import { JobType } from 'job-lib/types/JobType'

import { useCreateJobAction } from '../../hooks/useCreateJobAction'
import { CreateFileBaseModal } from './CreateFileBaseModal'

export interface CreatePipelineModalProps {
  type: FileType
  folderPath: string | null
  onClose: () => void
}

const CreatePipelineModal: FC<CreatePipelineModalProps> = ({
  type,
  onClose,
  folderPath
}) => {
  const launchDarkly = useLDClient()
  const { setCenter } = useEtlFlow()

  const onSubmit = (data: FieldValues) => {
    launchDarkly.track('designer-pipelines-add-new-pipeline')
    const jobName: string = data.jobName.trim()
    const runtimeName = folderPath ? `${folderPath}/${jobName}` : jobName

    create(runtimeName)
    setCenter(0, 0, {
      zoom: 1
    })
  }

  const { isLoading, create } = useCreateJobAction({
    type:
      type === FileType.ORCHESTRATION_PIPELINE
        ? JobType.Orchestration
        : JobType.Transformation,
    onComplete: onClose
  })

  return (
    <CreateFileBaseModal
      onSubmit={onSubmit}
      onClose={onClose}
      type={type}
      folderPath={folderPath}
      isLoading={isLoading}
    />
  )
}

export default CreatePipelineModal
