import { useMutation } from '@tanstack/react-query'

import { type CopilotService, type FeedbackSentiment } from '../types'
import { useCopilotApi } from '../useCopilotApi/useCopilotApi'

export interface SubmitFeedbackRequest {
  requestId: string
  response: FeedbackSentiment
  type: CopilotService
  comments: string
}

export const useSubmitFeedback = () => {
  const { submitFeedback } = useCopilotApi()

  return useMutation(async (request: SubmitFeedbackRequest) => {
    await submitFeedback(request)
  })
}
