import { useMemo } from 'react'

import { FileType } from '@matillion/git-component-library'

import { useActivePipelineSummary } from '../../hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useFlags } from '../../hooks/useFlags'
import { useGetSelectedComponentNames } from '../../hooks/useGetSelectedComponentNames'
import { Copilot } from './Copilot'
import CopilotPlaceholder from './Copilot/CopilotPlaceholder/CopilotPlaceholder'
import classes from './CopilotChat.module.scss'

export const CopilotChat = () => {
  const { selectedComponentNames } = useGetSelectedComponentNames()
  const { enableOrchestrationCopilot, enableTransformationCopilot } = useFlags()
  const { pipelineSummary } = useActivePipelineSummary()

  const isCopilotEnabled = useMemo(() => {
    switch (pipelineSummary?.type) {
      case FileType.TRANSFORMATION_PIPELINE:
        return enableTransformationCopilot
      case FileType.ORCHESTRATION_PIPELINE:
        return enableOrchestrationCopilot
      default:
        return false
    }
  }, [enableOrchestrationCopilot, enableTransformationCopilot, pipelineSummary])

  return (
    <div
      className={classes.CopilotChat__Container}
      data-testid="copilot-container"
    >
      {isCopilotEnabled ? (
        <Copilot
          sourceComponentNames={selectedComponentNames}
          data-testid="copilot"
        />
      ) : (
        <CopilotPlaceholder
          isActiveJob={!!pipelineSummary}
          isOrchEnabled={enableOrchestrationCopilot}
        />
      )}
    </div>
  )
}
