import { v4 as uuid } from 'uuid'

import { type ListProjectVariablesResponse } from 'api/hooks/useListProjectVariables/types'

import {
  JobVariableType,
  JobVariableVisibility,
  type GridVariable,
  type JobVariable,
  type ScalarVariable,
  type VariableScope
} from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  ReducerActions,
  type FormState,
  type ProjectDefaultOverrides,
  type ProjectVariable,
  type ProjectVariableOverride,
  type VariableBehaviour
} from 'modules/ManageVariables/types'
import { isJobVariable } from 'modules/ManageVariables/utils'

import { manageVariableNameErrors } from '../errorMessages'
import {
  editDataGridField,
  onAddRowDataGrid,
  onChangeSelectAllDataGrid,
  onChangeSelectedDataGrid,
  onExitTextMode,
  onNextStep,
  onRemoveRowDataGrid
} from './grid'
import { updateDefaultValue } from './updateDefaultValue'
import { updateName } from './updateName'
import { updateProjectOverride } from './updateProjectOverride'
import { updateValue } from './updateValue'
import { updateVariableType } from './updateVariableType'
import { validateForm } from './validateForm'
import { validateGrid } from './validateGrid'

export interface EditDataGridAction {
  type: ReducerActions.EDIT_DATA_GRID_VALUE
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
  rowId: string
  newValue: string
  colIndex: number
}

export interface OnChangeSelectedDataGridAction {
  type: ReducerActions.ON_CHANGE_SELECTED_DATA_GRID
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
  rowId: string
  isSelected: boolean
}

export interface OnChangeSelectAllDataGridAction {
  type: ReducerActions.ON_CHANGE_SELECT_ALL_DATA_GRID
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
  selectAllChecked: boolean
}

export interface OnAddRowDataGridAction {
  type: ReducerActions.ON_ADD_ROW_DATA_GRID_ACTION
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
}

export interface OnRemoveRowDataGridAction {
  type: ReducerActions.ON_REMOVE_ROW_DATA_GRID_ACTION
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
}

export interface OnNextStepAction {
  type: ReducerActions.ON_NEXT_STEP
  newStep: number
}

export interface OnTextModeCloseAction {
  type: ReducerActions.ON_EXIT_TEXT_MODE
  newRows: string[][]
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID
}

export type ReducerAction =
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.NAME
      value: string
    }
  | {
      type: ReducerActions.UPDATE_VARIABLE_TYPE
      field: Fields.VARIABLE_TYPE
      value: JobVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.VISIBILITY
      value: JobVariableVisibility | null
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.BEHAVIOUR
      value: VariableBehaviour | null
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.DEFAULT_VALUE | Fields.DESCRIPTION
      value: string
    }
  | {
      type: ReducerActions.VALIDATE_FORM
    }
  | {
      type: ReducerActions.UPDATE_NAME
      value: string
      jobVariables: JobVariable[]
      projectVariables: ListProjectVariablesResponse
      originalName?: string
    }
  | {
      type: ReducerActions.UPDATE_PROJECT_OVERRIDE
      field: Fields.PROJECT_DEFAULT_OVERRIDES
      value: ProjectVariableOverride['value']
      environmentId: ProjectVariableOverride['environmentId']
    }
  | {
      type: ReducerActions.UPDATE_DEFAULT_VALUE
      field: Fields.DEFAULT_VALUE
      value: string
    }
  | EditDataGridAction
  | OnChangeSelectedDataGridAction
  | OnChangeSelectAllDataGridAction
  | OnAddRowDataGridAction
  | OnRemoveRowDataGridAction
  | OnNextStepAction
  | OnTextModeCloseAction

export const formReducer = (
  state: FormState,
  action: ReducerAction
): FormState => {
  switch (action.type) {
    case ReducerActions.UPDATE_FIELD:
      return updateValue(state, action)
    case ReducerActions.UPDATE_NAME:
      return updateName(state, action)
    case ReducerActions.VALIDATE_FORM:
      return validateForm(state)
    case ReducerActions.UPDATE_PROJECT_OVERRIDE:
      return updateProjectOverride(state, action)
    case ReducerActions.UPDATE_DEFAULT_VALUE:
      return updateDefaultValue(state, action)
    case ReducerActions.UPDATE_VARIABLE_TYPE:
      return updateVariableType(state, action)
    case ReducerActions.EDIT_DATA_GRID_VALUE:
      return editDataGridField(state, action)
    case ReducerActions.ON_CHANGE_SELECTED_DATA_GRID:
      return onChangeSelectedDataGrid(state, action)
    case ReducerActions.ON_CHANGE_SELECT_ALL_DATA_GRID:
      return onChangeSelectAllDataGrid(state, action)
    case ReducerActions.ON_ADD_ROW_DATA_GRID_ACTION:
      return onAddRowDataGrid(state, action)
    case ReducerActions.ON_REMOVE_ROW_DATA_GRID_ACTION:
      return onRemoveRowDataGrid(state, action)
    case ReducerActions.ON_NEXT_STEP:
      return onNextStep(state, action)
    case ReducerActions.ON_EXIT_TEXT_MODE:
      return onExitTextMode(state, action)
    default:
      throw new Error('Please provide a valid action to formReducer')
  }
}

const getInitState = (
  variableType: JobVariableType,
  variableScope: VariableScope,
  variableBehaviour: VariableBehaviour
): FormState => {
  return {
    variableScope,
    [Fields.DEFAULT_VALUE]: {
      value: '',
      isValid: true,
      error: ''
    },
    [Fields.VARIABLE_TYPE]: {
      value: variableType,
      isValid: true,
      error: ''
    },
    [Fields.DESCRIPTION]: {
      value: '',
      isValid: true,
      error: ''
    },
    [Fields.NAME]: {
      value: '',
      isValid: null,
      error: manageVariableNameErrors[ErrorStatuses.EMPTY]
    },
    [Fields.VISIBILITY]: {
      value: JobVariableVisibility.PUBLIC,
      isValid: true,
      error: ''
    },
    [Fields.BEHAVIOUR]: {
      value: variableBehaviour,
      isValid: true,
      error: ''
    },
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      value: {},
      isValid: true,
      error: ''
    },
    isFormValid: false,
    [Fields.COLUMN_GRID]: {
      value: {
        rows: [{ id: uuid(), columns: ['', ''] }],
        selectedRows: [],
        errors: []
      },
      isValid: true,
      error: ''
    },
    [Fields.DEFAULT_VALUE_GRID]: {
      value: {
        rows: [],
        selectedRows: [],
        errors: []
      },
      isValid: true,
      error: ''
    }
  }
}

export const getInitialState = ({
  variableScope,
  variableType,
  variableBehaviour,
  variableToEdit
}: {
  variableType: JobVariableType
  variableScope: VariableScope
  variableToEdit?: JobVariable | ProjectVariable
  variableBehaviour: VariableBehaviour
}): FormState => {
  let state: FormState
  if (!variableToEdit) {
    state = getInitState(variableType, variableScope, variableBehaviour)
  } else if (isJobVariable(variableToEdit)) {
    state = getInitialStateForJobVariable({
      variable: variableToEdit,
      variableType,
      variableScope,
      variableBehaviour
    })
  } else {
    state = getInitialStateForProjectVariable({
      variable: variableToEdit,
      variableType,
      variableScope,
      variableBehaviour
    })
  }

  validateGrid(Fields.COLUMN_GRID, state)
  validateGrid(Fields.DEFAULT_VALUE_GRID, state)

  return state
}

export function getInitialStateForJobVariable({
  variable,
  variableScope,
  variableType,
  variableBehaviour
}: {
  variable: JobVariable | GridVariable
  variableType: JobVariableType
  variableScope: VariableScope
  variableBehaviour: VariableBehaviour
}): FormState {
  const { definition, value } = variable as ScalarVariable
  const { name, visibility, behaviour, description } = definition
  const initState = getInitState(variableType, variableScope, variableBehaviour)

  let state = {
    ...initState,
    isFormValid: true,
    [Fields.DEFAULT_VALUE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value,
      isValid: true
    },
    [Fields.VARIABLE_TYPE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value: variableType,
      isValid: true
    },
    [Fields.DESCRIPTION]: {
      ...initState[Fields.DESCRIPTION],
      value: description ?? '',
      isValid: true
    },
    [Fields.NAME]: { ...initState[Fields.NAME], value: name, isValid: true },
    [Fields.VISIBILITY]: {
      ...initState[Fields.VISIBILITY],
      value: visibility,
      isValid: true
    },
    [Fields.BEHAVIOUR]: {
      ...initState[Fields.BEHAVIOUR],
      value: behaviour,
      isValid: true
    }
  }
  if (variableType === JobVariableType.GRID) {
    const gridVariable = variable as GridVariable
    state = {
      ...state,
      [Fields.COLUMN_GRID]: {
        ...initState[Fields.COLUMN_GRID],
        value: {
          rows: gridVariable.definition.definitions.map((column) => ({
            id: uuid(),

            columns: [column.name, column.type as 'TEXT' | 'NUMBER']
          })),
          selectedRows: [],
          errors: []
        },
        isValid: true
      },
      [Fields.DEFAULT_VALUE_GRID]: {
        ...initState[Fields.DEFAULT_VALUE_GRID],
        value: {
          rows: gridVariable.values.map(({ values }) => {
            return {
              id: uuid(),
              columns: values
            }
          }),
          selectedRows: [],
          errors: []
        },
        isValid: true
      },
      [Fields.BEHAVIOUR]: {
        ...initState[Fields.BEHAVIOUR],
        value: gridVariable.definition.scope,
        isValid: true
      }
    }
  }

  return state
}
export function getInitialStateForProjectVariable({
  variable,
  variableScope,
  variableType,
  variableBehaviour
}: {
  variable: ProjectVariable
  variableType: JobVariableType
  variableScope: VariableScope
  variableBehaviour: VariableBehaviour
}): FormState {
  const { description, name, overrides, behaviour, value } = variable
  const initState = getInitState(variableType, variableScope, variableBehaviour)

  return {
    ...initState,
    isFormValid: true,
    [Fields.DEFAULT_VALUE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value: value ? value.toString() : '',
      isValid: true
    },
    [Fields.VARIABLE_TYPE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value: variableType,
      isValid: true
    },
    [Fields.DESCRIPTION]: {
      ...initState[Fields.DESCRIPTION],
      value: description,
      isValid: true
    },
    [Fields.NAME]: { ...initState[Fields.NAME], value: name, isValid: true },
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      ...initState[Fields.PROJECT_DEFAULT_OVERRIDES],
      value: overrides.reduce<ProjectDefaultOverrides>((accumulator, next) => {
        return {
          ...accumulator,
          [next.environmentId]: {
            environmentId: next.environmentId,
            value: next.value,
            isValid: true,
            error: ''
          }
        }
      }, {}),
      isValid: true
    },
    [Fields.BEHAVIOUR]: {
      ...initState[Fields.BEHAVIOUR],
      value: behaviour,
      isValid: true
    }
  }
}
