export const INITIAL_COMPONENT_ID = 1

type WithKey<K extends string> = Record<
  K,
  {
    [id: number]: { id: number }
  }
>

export const generateNextSequenceId =
  <K extends string>(key: K) =>
  (job: WithKey<K>) => {
    const existingIds = Object.keys(job[key])
    const newId =
      existingIds.length > 0
        ? Math.max(...existingIds.map(Number)) + 1
        : INITIAL_COMPONENT_ID
    return newId
  }

export const generateNextComponentId = generateNextSequenceId('components')
export const generateNextNoteId = generateNextSequenceId('notes')
