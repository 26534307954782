import { AuthProviderEnvironment } from '../../types'

const envs: AuthProviderEnvironment[] = [
  AuthProviderEnvironment.local,
  AuthProviderEnvironment.dev,
  AuthProviderEnvironment.preprod,
  AuthProviderEnvironment.prod
]

interface ParseProductResult {
  env: AuthProviderEnvironment
  basename: string
}

const exceptionBasenamesLookup: Record<string, string | undefined> = {
  'project-explorer': 'projects'
}

export function parseProduct(product: string): ParseProductResult {
  const result = doParseProduct(product)

  return {
    env: result.env,
    basename: exceptionBasenamesLookup[result.basename] ?? result.basename
  }
}

function doParseProduct(product: string): ParseProductResult {
  const parts = product.split('-')

  if (envs.includes(parts[0] as AuthProviderEnvironment)) {
    return {
      env: parts[0] as AuthProviderEnvironment,
      basename: parts.slice(1).join('-')
    }
  } else if (
    envs.includes(parts[parts.length - 1] as AuthProviderEnvironment)
  ) {
    return {
      env: parts[parts.length - 1] as AuthProviderEnvironment,
      basename: parts.slice(0, -1).join('-')
    }
  }

  return { env: AuthProviderEnvironment.prod, basename: product }
}
