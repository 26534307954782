import { useMutation } from '@tanstack/react-query'

import { type PipelineContext } from 'api/hooks/copilot/model'

import { useCopilotApi } from '../useCopilotApi/useCopilotApi'

export interface OptimiseQueryInput {
  requestId: string
  pipeline: PipelineContext
  warehouse: string
  message: string
}

export const useOptimiseQuery = () => {
  const { optimiseQuery } = useCopilotApi()

  return useMutation(async (input: OptimiseQueryInput) => {
    const { data } = await optimiseQuery(input)

    return data
  })
}
