import { type ChangeEvent, type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Icon, Radio, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import {
  JobVariableBehaviour,
  JobVariableVisibility
} from 'job-lib/types/Variables'

import {
  Fields,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'

import classes from '../Form.module.scss'
import { type ReducerAction } from '../formReducer/formReducer'

export const JobSpecificFields = ({
  state,
  dispatch,
  isGridVariable = false
}: {
  state: FormState
  dispatch: Dispatch<ReducerAction>
  isGridVariable?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <>
      <fieldset className={classes.Form__ScopeFieldset}>
        <legend className="u-visually-hidden">
          {t('manageVariables.jobVariables.fields.variableVisibilityScope')}
        </legend>

        <Typography
          format="bcs"
          weight="bold"
          className={classes.Form__CustomLabel}
        >
          {t('manageVariables.jobVariables.fields.visibility')}
        </Typography>

        <Field
          className={classNames(classes.Field, classes.RadioField)}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.public'
          )}
          supportText={t(
            'manageVariables.jobVariables.fields.variableVisibility.publicRadioSupportMessage'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PUBLIC}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PUBLIC
          }
        />

        <Field
          className={classes.Field}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.private'
          )}
          supportText={t(
            'manageVariables.jobVariables.fields.variableVisibility.privateRadioSupportMessage'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PRIVATE}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PRIVATE
          }
        />

        <Typography
          format="bcs"
          weight="bold"
          className={classes.Form__CustomLabel}
        >
          {t('manageVariables.jobVariables.fields.behaviour')}
        </Typography>

        <Typography format="bcs" className={classes.Form__Behaviour_Message}>
          {t('manageVariables.jobVariables.fields.behaviourVisibility.message')}{' '}
          <a
            href="https://docs.matillion.com/data-productivity-cloud/designer/docs/variables/#variable-behavior-copied-or-shared"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              'manageVariables.jobVariables.fields.behaviourVisibility.learnMore'
            )}
          </a>
        </Typography>

        <Field
          className={classNames(classes.Field, classes.RadioField)}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.BEHAVIOUR,
              value: e.target.value as JobVariableBehaviour
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.behaviourVisibility.shared'
          )}
          hasError={state[Fields.BEHAVIOUR].isValid === false}
          value={JobVariableBehaviour.SHARED}
          checked={
            state[Fields.BEHAVIOUR].value === JobVariableBehaviour.SHARED
          }
        />

        <Field
          className={classNames(classes.Field, classes.RadioField)}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.BEHAVIOUR,
              value: e.target.value as JobVariableBehaviour
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.behaviourVisibility.copied'
          )}
          hasError={state[Fields.BEHAVIOUR].isValid === false}
          value={JobVariableBehaviour.COPIED}
          checked={
            state[Fields.BEHAVIOUR].value === JobVariableBehaviour.COPIED
          }
        />

        <div aria-live="polite">
          {state[Fields.VISIBILITY].isValid === false && (
            <div className={classes.FieldError}>
              <Icon.Error className={classes.FieldError__Icon} />
              <Typography
                className={classes.FieldError__Text}
                as="span"
                format="bcs"
              >
                {t(
                  'manageVariables.jobVariables.fields.errors.pleaseSelectAnOption'
                )}
              </Typography>
            </div>
          )}
        </div>
      </fieldset>

      {!isGridVariable && (
        <Field
          className={classNames(
            classes.Field,
            classes['Field__Input--Compact']
          )}
          title={t('manageVariables.jobVariables.fields.defaultValue')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_DEFAULT_VALUE,
              field: Fields.DEFAULT_VALUE,
              value: e.target.value
            })
          }}
          value={state[Fields.DEFAULT_VALUE].value}
          hasError={state[Fields.DEFAULT_VALUE].isValid === false}
          errorText={
            state[Fields.DEFAULT_VALUE].isValid === false &&
            t(state[Fields.DEFAULT_VALUE].error, {
              field: t('manageVariables.jobVariables.fields.defaultValue')
            })
          }
        />
      )}
    </>
  )
}
