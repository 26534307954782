import { forwardRef, type HTMLProps, type Ref } from 'react'

import classNames from 'classnames'

import classes from './PipelineTable.module.scss'

export interface Props extends HTMLProps<HTMLTableRowElement> {
  ref?: Ref<HTMLTableRowElement>
}

export const PipelineTableRow = forwardRef<HTMLTableRowElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <tr
        {...props}
        ref={ref}
        className={classNames(classes.PipelineTableRow, className)}
      />
    )
  }
)

PipelineTableRow.displayName = 'PipelineTableRow'
