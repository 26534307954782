/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import { getSelectedComponents } from 'file-editors/canvas/util/componentSelection'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useGetComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'

import {
  unknownOrchestrationSummaryId,
  unknownTransformationSummaryId
} from 'job-lib/cisIds/knownComponentParameters'
import type { RootState } from 'job-lib/store'
import type {
  ComponentInstance,
  OrchestrationComponentInstanceCollection,
  TransformationComponentInstanceCollection
} from 'job-lib/types/Job'

import { getComponentSelector } from 'modules/core/EtlDesigner/selectors/componentSelector'
import { getComponentsSelector } from 'modules/core/EtlDesigner/selectors/getComponentsSelector'
import {
  getConnectorsSelector,
  type ConnectorsCollection
} from 'modules/core/EtlDesigner/selectors/getConnectorsSelector'

import { isConnectedToStart } from './isConnectedToStart'

const useCanRunComponent = (
  componentInstanceId: number | null,
  summaryId: string | undefined
) => {
  const { hasPermission } = useProjectPermission(
    'run_pipelines',
    PermissionType.ENVIRONMENT
  )
  const { data } = useGetComponentMetadata(summaryId)
  const pipelineComponents = useSelector<
    RootState,
    | OrchestrationComponentInstanceCollection
    | TransformationComponentInstanceCollection
    | null
  >(getComponentsSelector)
  const connectors = useSelector<RootState, ConnectorsCollection | null>(
    getConnectorsSelector
  )
  const component = useSelector<RootState, ComponentInstance | null>(
    getComponentSelector(componentInstanceId ?? 0)
  )
  const isConnectedToStartComponent = useMemo(() => {
    return isConnectedToStart(component, pipelineComponents, connectors)
  }, [component, connectors, pipelineComponents])

  const etlFlow = useEtlFlow()
  const components = getSelectedComponents(etlFlow)
  if (components.length === 1) {
    const selectedComponent = components[0]
    const isUnknown =
      selectedComponent.summaryId === unknownOrchestrationSummaryId ||
      selectedComponent.summaryId === unknownTransformationSummaryId

    const canRunFromComponent =
      hasPermission && !isUnknown && isConnectedToStartComponent
    const executableAsSingleComponent =
      data?.metadata.executableAsSingleComponent ?? false
    const canRunOnlyComponent =
      canRunFromComponent &&
      executableAsSingleComponent &&
      !selectedComponent.skipped
    const componentLabel = selectedComponent.label

    return {
      canRunFromComponent,
      canRunOnlyComponent,
      componentLabel,
      executableAsSingleComponent
    }
  }

  return {
    canRunFromComponent: false,
    canRunOnlyComponent: false,
    componentLabel: undefined,
    executableAsSingleComponent: false
  }
}

export default useCanRunComponent
