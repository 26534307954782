import { type TreeExpandedState } from '@matillion/component-library'
import { cloneDeep } from 'lodash'
import { create } from 'zustand'

interface FileBrowserStateStore {
  fileTreeState: TreeExpandedState
  setFileTreeExpandedState: (nodeId: string, isExpanded: boolean) => void
  renameFileTreeExpandedState: (
    oldTreeState: string,
    newTreeState: string
  ) => void
  expandFoldersToNode: (nodeId: string) => void
}

export const useFileTreeExpandedStateStore = create<FileBrowserStateStore>()(
  (set, get) => ({
    fileTreeState: {},
    setFileTreeExpandedState: (nodeId: string, isExpanded: boolean) => {
      set((state) => ({
        ...state,
        fileTreeState: {
          ...state.fileTreeState,
          [nodeId]: isExpanded
        }
      }))
    },
    renameFileTreeExpandedState: (
      oldTreeState: string,
      newTreeState: string
    ) => {
      const currentTreeState = cloneDeep(get().fileTreeState)
      const updatedFileTreeState = Object.keys(currentTreeState).reduce(
        (acc: TreeExpandedState, value: string) => {
          if (value.startsWith(oldTreeState)) {
            const newPath = value.replace(oldTreeState, newTreeState)
            acc[newPath] = currentTreeState[value]
            // this is ok because it's a local object
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete currentTreeState[value]
          }
          return acc
        },
        {}
      )

      set(() => ({ fileTreeState: updatedFileTreeState }))
    },

    /**
     * automatically expands all folders in the tree to the specified nodeId
     * @param nodeId the id of the node to expand e.g. folder/sub-folder
     */
    expandFoldersToNode: (nodeId: string) => {
      const nodes = nodeId.split('/')
      const treeState = nodes
        .slice(0, nodes.length - 1)
        .reduce((acc: TreeExpandedState, value: string, idx: number) => {
          const path = nodes.slice(0, idx).join('/')
          acc[idx === 0 ? value : `${path}/${value}`] = true
          return acc
        }, {})

      set((state) => ({
        ...state,
        fileTreeState: {
          ...state.fileTreeState,
          ...treeState
        }
      }))
    }
  })
)
