import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { getIdFromReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'
import { MELTtoDPLTransition } from 'types/Pipeline'

import { useFlags } from 'hooks/useFlags'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const useDeleteEdge = () => {
  const dispatch = useDispatch()
  const update = useDPLWorkingCopy((state) => state.update)
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const { job } = useFlaggedWorkingCopy()
  const { invalidateComponent } = useComponentValidationProvider()

  const deleteEdge = useCallback(
    (source: string, target: string, edgeId: string, sourceHandle: string) => {
      const sourceId = parseInt(getIdFromReactFlowId(source))
      const targetId = parseInt(getIdFromReactFlowId(target))

      if (rolloutEnableWorkingCopyProvider) {
        if (!job) return

        const sourceName = getComponentName(job.components[sourceId])
        const targetName = getComponentName(job.components[targetId])
        const linkType =
          MELTtoDPLTransition[sourceHandle as keyof typeof MELTtoDPLTransition]

        update((state) => {
          state.deleteLink({ sourceName, targetName, linkType })
        })
      } else {
        dispatch(jobActions.deleteLink(parseInt(edgeId)))
      }

      invalidateComponent(targetId)
    },
    [
      dispatch,
      update,
      job,
      rolloutEnableWorkingCopyProvider,
      invalidateComponent
    ]
  )

  return { deleteEdge }
}
