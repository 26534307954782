import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'

export const useListEnvironments = () => {
  const { projectId } = useProjectInfo()
  const { getEnvironments } = useDesignerApi()

  return useQuery([projectId, queryKeys.listEnv], async () => {
    const { data } = await getEnvironments(projectId)
    return data
  })
}
