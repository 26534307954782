import { type JobType } from 'job-lib/types/JobType'

import { type UpgradeModalType } from '../UpgradeModalProvider/types'
import { useActionsSection } from './sections/useActionsSection'
import { useConfigurationSection } from './sections/useConfigurationSection'
import { useHistorySection } from './sections/useHistorySection'
import { useNotesSection } from './sections/useNotesSection'
import { useRunComponentSection } from './sections/useRunComponentSection'

export interface ContextMenuSection {
  id: string
  items: ContextMenuItem[]
}

export interface ContextMenuItem {
  id: string
  disabled?: boolean
  shortcut?: string
  subtext?: string
  context?: 'macOS' | 'windows'
  preview?: boolean
  requiresUpgrade?: boolean
  upgradeModalType?: UpgradeModalType
}

export interface CanvasContext {
  hasSelectedNodes: boolean
  hasSelectedComponents: boolean
  jobName: string
  jobType: JobType | undefined
  isRightClickedCustomConnector: boolean
}

export const CanvasContextMenu = (
  props: CanvasContext
): ContextMenuSection[] => {
  const sections: ContextMenuSection[] = [
    useConfigurationSection({ jobName: props.jobName }),
    useRunComponentSection({
      jobType: props.jobType
    }),
    useNotesSection({ canMakeAiNote: props.hasSelectedComponents }),
    useHistorySection(),
    useActionsSection({
      hasSelectedNodes: props.hasSelectedNodes,
      isRightClickedCustomConnector: props.isRightClickedCustomConnector
    })
  ]

  return sections.filter((s) => s.items.length > 0)
}
