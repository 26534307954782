import { useCallback, useState, type FC, type PropsWithChildren } from 'react'
import { Handle, Position, useReactFlow } from 'reactflow'

import { FileType } from '@matillion/git-component-library'
import classNames from 'classnames'
import { useCanvasState } from 'file-editors/canvas/hooks/useCanvasState'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  ConnectionPortType,
  OutputPortType,
  type ConnectionPortTypeT,
  type Port
} from 'job-lib/types/Components'

import { getIdFromReactFlowId } from '../../../hooks/useCanvasModel/utils'
import { AddNextComponent } from '../../AddNextComponent/AddNextComponent'
import classes from '../FlowCanvas.module.scss'
import { FlowPort } from './FlowPort'

export interface FlowNodeWrapperProps {
  id: string
  showPorts?: boolean
  inputPorts: Port[]
  outputPorts: Port[]
  iteratorPorts?: Port[]
  hasOutputConnection?: boolean
  isUnattachedIterator?: boolean
  isSelected?: boolean
  showOnlyConnectedOutputPorts: boolean
  outputPortsConnected: ConnectionPortTypeT[]
}

export const useAddComponentVisibility = (
  componentId: string,
  outputPorts: Port[],
  hasOutputConnection?: boolean
) => {
  const { componentId: selectedComponentId } = useProjectInfo()

  if (Number(componentId) !== selectedComponentId) {
    return false
  }

  if (outputPorts.length === 0) {
    return false
  }

  if (hasOutputConnection) {
    return false
  }

  return true
}

const usePortVisibility = (
  componentId: string,
  isUnattachedIterator?: boolean,
  showPortsDefault?: boolean
) => {
  const { isConnecting, connectionNodeId, connectionHandleId } =
    useCanvasState()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  /*
   * If a component is selected or is being hovered we show all ports
   * except for when making a connection
   */
  const showAllPorts = !isConnecting && (showPortsDefault || isHovered)

  /*
   * We want ports to be visible whilst we're forming connections
   * These control visibility of ports the user is currently dragging from
   */
  const isConnectionSourceComponent =
    getIdFromReactFlowId(connectionNodeId ?? '') === componentId
  const isConnectionPortInput = connectionHandleId === ConnectionPortType.INPUT

  /*
   * An iteration port cannot be connected to the input of another attachable component
   */
  const isIteratorToIteratorConnection =
    isUnattachedIterator && connectionHandleId === ConnectionPortType.ITERATION

  const isValidConnectionTarget =
    isConnecting &&
    !isConnectionSourceComponent &&
    !isIteratorToIteratorConnection

  /*
   * When you're forming a connection from an output port
   * we want to make it easier to connect to components
   * so we create a full size temporary input port over each valid connection target
   * so the connection can be dropped anywhere on the component rather than just the visible port
   */
  const showInputPortOverlay =
    isValidConnectionTarget && isConnecting && !isConnectionPortInput

  const showInputPorts =
    (isConnectionSourceComponent && isConnectionPortInput) ||
    (isValidConnectionTarget && !isConnectionPortInput)
  const showOutputPorts =
    (isConnectionSourceComponent && !isConnectionPortInput) ||
    (isValidConnectionTarget && isConnectionPortInput)

  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const onBlur = useCallback(() => {
    setIsHovered(false)
  }, [])

  return {
    onMouseEnter,
    onMouseLeave,
    onBlur,
    isConnecting,
    isConnectionSourceComponent,
    connectionSourceHandle: connectionHandleId,
    showInputPorts: showAllPorts || showInputPorts,
    showOutputPorts: showAllPorts || showOutputPorts,
    showInputPortOverlay
  }
}

export const FlowNodeWrapper: FC<PropsWithChildren<FlowNodeWrapperProps>> = ({
  id,
  showPorts,
  inputPorts,
  outputPorts,
  iteratorPorts = [],
  hasOutputConnection,
  isUnattachedIterator,
  isSelected,
  showOnlyConnectedOutputPorts,
  outputPortsConnected,
  children
}) => {
  const {
    onMouseEnter,
    onMouseLeave,
    onBlur,
    showInputPorts,
    showOutputPorts,
    isConnectionSourceComponent,
    connectionSourceHandle,
    showInputPortOverlay
  } = usePortVisibility(id, isUnattachedIterator, showPorts)

  const { enableRelativeStartPositionAndZoom } = useFlags()
  const reactFlowInstance = useReactFlow()
  const nodes = reactFlowInstance.getNodes()
  const { pipelineSummary } = useActivePipelineSummary()
  const jobType = pipelineSummary?.type
  const isNewOrchestrationPipeline =
    jobType === FileType.ORCHESTRATION_PIPELINE && nodes.length === 1

  const showAddNextComponent =
    useAddComponentVisibility(id, outputPorts, hasOutputConnection) ||
    (enableRelativeStartPositionAndZoom && isNewOrchestrationPipeline)

  const firstInput = inputPorts[0]

  return (
    <div
      className={classes.FlowNodeWrapper}
      data-testid="component-node"
      data-selected={isSelected}
      data-nodeid={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onBlur}
    >
      {children}

      {/*
       * handles are where edges connect to nodes on the canvas.
       * this terminology comes from react-flow itself
       */}
      <Handle
        className={classNames(
          classes.FlowNodeWrapper__Handle,
          classes['FlowNodeWrapper__Handle--input']
        )}
        type="target"
        position={Position.Left}
        isConnectable={false}
      />

      <Handle
        className={classNames(
          classes.FlowNodeWrapper__Handle,
          classes['FlowNodeWrapper__Handle--output']
        )}
        type="source"
        position={Position.Right}
        isConnectable={false}
      />

      {/* add a full sized input port overlay to the component to make connecting components easier */}
      {showInputPortOverlay && firstInput && (
        <div className={classes.FlowNodeWrapper__FullInput}>
          <Handle
            type="target"
            id={firstInput.portId}
            position={Position.Left}
            className={classes.FlowNodeWrapper__FullInput__Handle}
          />
        </div>
      )}

      {/*
       * ports are where connections are formed, and are
       * displayed contextually when users interact with nodes.
       * this terminology is our own, and is based on METL
       */}
      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__InputPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showInputPorts
          }
        )}
      >
        {inputPorts.map((port) => (
          <FlowPort
            key={`input-port-widget-${port.portId}`}
            type="target"
            position={Position.Left}
            id={port.portId}
            isConnectable={showInputPorts}
            active={
              isConnectionSourceComponent &&
              port.portId === connectionSourceHandle
            }
            data-testid={`component-${id}-input-port-${port.portId}`}
          />
        ))}
      </div>

      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__OutputPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showOutputPorts
          }
        )}
      >
        {outputPorts
          .filter(
            (p) =>
              !showOnlyConnectedOutputPorts ||
              outputPortsConnected.includes(p.portId) ||
              p.portId === OutputPortType.UNCONDITIONAL
          )
          .map((port) => (
            <FlowPort
              key={`output-port-widget-${port.portId}`}
              type="source"
              position={Position.Right}
              id={port.portId}
              isConnectable={showOutputPorts}
              active={
                isConnectionSourceComponent &&
                port.portId === connectionSourceHandle
              }
            />
          ))}
      </div>

      <div
        className={classNames(
          classes.FlowNodeWrapper__Ports,
          classes.FlowNodeWrapper__IteratorPorts,
          {
            [classes['FlowNodeWrapper__Ports--hidden']]: !showOutputPorts
          }
        )}
      >
        {iteratorPorts.map((port) => (
          <FlowPort
            key={`iteration-port-widget-${port.portId}`}
            type="source"
            position={Position.Bottom}
            id={port.portId}
            isConnectable={showOutputPorts}
            active={
              isConnectionSourceComponent &&
              port.portId === connectionSourceHandle
            }
          />
        ))}
      </div>
      {showAddNextComponent && (
        <div className={classes.FlowNodeWrapper__AddNextOffset}>
          <AddNextComponent sourceComponentId={parseInt(id)} />
        </div>
      )}
    </div>
  )
}
