import type { NoteId, Pipeline, Position } from 'types/Pipeline'

export interface UpdateNotePositionPayload {
  noteId: NoteId
  position: Position
}

export const updateNotePosition =
  (state: Pipeline) =>
  ({ noteId, position }: UpdateNotePositionPayload) => {
    if (!state.design.notes[noteId]) {
      return
    }

    state.design.notes[noteId].position = position
  }
