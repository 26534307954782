import { useMemo } from 'react'

import { useFlags as useHubFlags } from '@matillion/hub-client'

export interface EtlFlags {
  [key: string]: boolean | string[] | Record<string, string[]>
  enableAgentStackTrace: boolean
  enableAgentAvailabilityModal: boolean
  /** Enables both custom and flex connectors */
  enableCustomConnectors: boolean
  enableCopyPastePipelines: boolean
  enableExperimentalAiAutoDocs: boolean
  enableEntitlementCheckAiCopilot: boolean
  enableEntitlementCheckAiComponents: boolean
  enableRefineAiAutoDocs: boolean
  enableImportExportPipelines: boolean
  enableSharedPipelines: boolean
  enableRecentlyAccessedPipelines: boolean
  enableTransformationCopilot: boolean
  enableOrchestrationCopilot: boolean
  enableCopilotAgents: boolean
  enableCopilotDatabricks: boolean
  enableCopilotRedshift: boolean
  enableViewFullConversionReportButton: boolean
  enableAddCustomConnectorCta: boolean
  enableVirtualisedTable: boolean
  enableMyFirstPipelineExperiment: boolean
  rolloutEnableWorkingCopyProvider: boolean
  enableHighCode: boolean
  enableCopilotSQLExpressionEditor: boolean
  enableMoveEditorsToPanelBatch1: boolean
  enableHidingSupersededComponents: boolean
  enableRelativeStartPositionAndZoom: boolean
  /** Enables both the File Upload and Demo Data painted doors */
  enableUploadFilePaintedDoorExperiment: boolean
  enableTaskDetailsPolling: boolean
  enableSampleDataComponentConfigExperiment: boolean
  modularOutputAllowList: Record<string, string[]>
  enablePostProcessingTab: boolean
  enableFloatingFilesPanelButton: boolean
  enableDetachIterator: boolean
  enableCloningInProgressBlocking: boolean
  enableS3StorageUrlEditorErrorExperiment: boolean
  enableSkipComponent: boolean
  enableRenameFolders: boolean
  enableCommandPaletteCommands: boolean
  enableGlobalNavLayout: boolean
  enableLoggingView: boolean
}

export const useFlags = (): EtlFlags => {
  const hubFlags = useHubFlags()

  return useMemo(
    () => ({
      enableAgentStackTrace: Boolean(hubFlags['enable-agent-stack-trace']),
      enableAgentAvailabilityModal: Boolean(
        hubFlags['enable-agent-availability-modal']
      ),
      /** Enables both custom and flex connectors */
      enableCustomConnectors: Boolean(hubFlags['enable-custom-connectors']),
      enableCopyPastePipelines: Boolean(
        hubFlags['enable-copy-paste-pipelines']
      ),
      enableExperimentalAiAutoDocs: Boolean(
        hubFlags['enable-experimental-ai-auto-docs']
      ),
      enableRefineAiAutoDocs: Boolean(hubFlags['enable-refine-ai-auto-docs']),
      enableImportExportPipelines: Boolean(
        hubFlags['enable-import-export-pipelines']
      ),
      enableSharedPipelines: Boolean(hubFlags['enable-shared-pipelines']),
      enableRecentlyAccessedPipelines: Boolean(
        hubFlags['enable-recently-accessed-pipelines']
      ),
      enableTransformationCopilot: Boolean(
        hubFlags['enable-transformation-copilot']
      ),
      enableOrchestrationCopilot: Boolean(
        hubFlags['enable-orchestration-copilot']
      ),
      enableCopilotAgents: Boolean(hubFlags['enable-copilot-agents']),
      enableEntitlementCheckAiCopilot: Boolean(
        hubFlags['enable-entitlement-check-ai-copilot']
      ),
      enableEntitlementCheckAiComponents: Boolean(
        hubFlags['enable-entitlement-check-ai-components']
      ),
      enableViewFullConversionReportButton: Boolean(
        hubFlags['enable-view-full-conversion-report']
      ),
      enableAddCustomConnectorCta: Boolean(
        hubFlags['enable-add-custom-connector-cta']
      ),
      enableVirtualisedTable: Boolean(hubFlags['enable-virtualised-table']),
      rolloutEnableWorkingCopyProvider: Boolean(
        hubFlags['rollout-enable-working-copy-provider']
      ),
      /** Enables both the File Upload and Demo Data painted doors */
      enableUploadFilePaintedDoorExperiment: Boolean(
        hubFlags['enable-upload-file-painted-door-experiment']
      ),
      enableMyFirstPipelineExperiment: Boolean(
        hubFlags['enable-my-first-pipeline-experiment']
      ),
      enableSampleDataComponentConfigExperiment: Boolean(
        hubFlags['enable-sample-data-component-config-experiment']
      ),
      enableHighCode: Boolean(hubFlags['enable-high-code']),
      enableCopilotSQLExpressionEditor: Boolean(
        hubFlags['enable-copilot-sql-expression-editor']
      ),
      enableCopilotDatabricks: Boolean(hubFlags['enable-copilot-databricks']),
      enableCopilotRedshift: Boolean(hubFlags['enable-copilot-redshift']),
      enableMoveEditorsToPanelBatch1: Boolean(
        hubFlags['enable-move-editors-to-panel-batch1']
      ),
      enableConnectMyEnvironmentCta: Boolean(
        hubFlags['enable-connect-my-environment-cta']
      ),
      enableHidingSupersededComponents: Boolean(
        hubFlags['enable-hiding-superseded-components']
      ),
      enableRelativeStartPositionAndZoom: Boolean(
        hubFlags['enable-relative-start-position-and-zoom']
      ),
      enableTaskDetailsPolling: Boolean(
        hubFlags['enable-task-details-polling']
      ),
      enableMigrationToolMappings: Boolean(
        hubFlags['enable-migration-tool-mappings']
      ),
      enableImprovedCanvasInteractions: Boolean(
        hubFlags['enable-improved-canvas-interactions']
      ),
      modularOutputAllowList: hubFlags['modular-output-allow-list'] as Record<
        string,
        string[]
      >,
      enablePostProcessingTab: Boolean(hubFlags['enable-post-processing-tab']),
      enableFloatingFilesPanelButton: Boolean(
        hubFlags['enable-floating-files-panel-button']
      ),
      enableDetachIterator: Boolean(hubFlags['enable-detach-iterator']),
      enableSkipComponent: Boolean(hubFlags['enable-skip-component']),
      enableCloningInProgressBlocking: Boolean(
        hubFlags['enable-cloning-in-progress-blocking']
      ),
      enableS3StorageUrlEditorErrorExperiment: Boolean(
        hubFlags['enable-s3-storage-url-editor-error-experiment']
      ),
      enableRenameFolders: Boolean(hubFlags['enable-rename-folders']),
      enableCommandPaletteCommands: Boolean(
        hubFlags['enable-command-palette-commands']
      ),
      enableGlobalNavLayout: Boolean(hubFlags['enable-global-nav-layout']),
      enableLoggingView: Boolean(hubFlags['enable-logging-view']),
      ...hubFlags
    }),
    [hubFlags]
  )
}
