import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { type ReadOnlyNestedDataPickerResponse } from 'hooks/useParameterOptions/useParameterOptionsTypes'

import { type ElementCollection } from 'job-lib/types/Parameters'

export interface TreeNode {
  name: string
  path: string
  type: string
  children: TreeNode[]
  selected: boolean
}

/**
 * Converts the Autofill response to our internal TreeNode representation,
 */
export const autoFillResponseToTreeNode = (
  autoFillData: ReadOnlyNestedDataPickerResponse[],
  parentName = ''
): TreeNode[] => {
  return autoFillData.map(({ name, children, type }) => ({
    name,
    path: `${parentName}.${name}`,
    children: autoFillResponseToTreeNode(children, `${parentName}.${name}`),
    type,
    selected: false
  }))
}

/**
 * Filters a tree to include only selected elements and their descendants.
 * This function recursively processes each node in the tree, keeping nodes that
 * are selected or have selected descendants and then applies the same logic to their children.
 *
 * @param {TreeNode[]} tree - The tree of TreeNode objects to filter.
 * @returns {TreeNode[]} - A new tree array containing only selected nodes and their descendants.
 */
export const toOnlySelectedElementsTree = (tree: TreeNode[]): TreeNode[] =>
  tree.filter(isSelectedOrHasSelectedDescendant).map((node: TreeNode) => ({
    ...node,
    children: toOnlySelectedElementsTree(node.children)
  }))

const isSelectedOrHasSelectedDescendant = (node: TreeNode): boolean =>
  node.selected || !!node.children?.find(isSelectedOrHasSelectedDescendant)

/**
 * Transforms a {@link TreeNode} array into the METL format {@link ElementCollection}.
 *
 * @param {TreeNode[]} treeState - The array of TreeNode objects to transform.
 * @returns {ElementCollection} - The transformed array in the METL ElementCollection format.
 *
 * @example
 * // TreeNode array:
 * const treeNodes = [{ name: 'SomeNode', type: 'SomeType', children: [...] }, ...];
 *
 * // Resulting ElementCollection:
 * const elements = {
 *   1: {
 *     slot: 1,
 *     elements: { ... }, // converted children
 *     values: {
 *       1: { value: 'SomeNode', slot: 1, type: 'STRING', dataType: 'TEXT' },
 *       2: { value: 'SomeType', slot: 2, type: 'STRING', dataType: 'TEXT' }
 *     }
 *   },
 *   ...
 * };
 *
 * const elementCollection = transformToElementCollection(treeNodes);
 */
export const transformToElementCollection = (
  treeState: TreeNode[]
): ElementCollection => {
  return treeState.reduce<ElementCollection>(
    (elements, { name, children, type, selected }, index) => {
      const offsetIndex = index + 1
      elements[offsetIndex] = {
        slot: offsetIndex,
        values: {
          1: {
            value: name,
            slot: 1,
            type: 'STRING',
            dataType: ParameterDataType.TEXT
          },
          2: {
            value: type,
            slot: 2,
            type: 'STRING',
            dataType: ParameterDataType.TEXT
          },
          3: {
            value: selected ? 'true' : 'false',
            slot: 3,
            type: 'STRING',
            dataType: ParameterDataType.BOOLEAN
          }
        }
      }
      if (children.length) {
        elements[offsetIndex].elements = transformToElementCollection(children)
      }
      return elements
    },
    {}
  )
}

/**
 * Convert saved parameter data into a list of strings representing the
 * path in the TreeNode of selected fields.
 */
export const elementCollectionToSelectedPaths = (
  elementCollection: ElementCollection,
  parentPath: string = ''
): string[] => {
  return Object.values(elementCollection).flatMap((element) => {
    const name = element.values[1]?.value ?? ''
    const path = `${parentPath}.${name}`
    const selected = (element.values[3]?.value ?? '') === 'true'
    const children = elementCollectionToSelectedPaths(
      element.elements ?? {},
      path
    )
    if (selected) {
      return [path, ...children]
    } else {
      return children
    }
  })
}

const selectAllPathsFromSet = (
  nodes: TreeNode[],
  selected: boolean,
  paths: Set<string>
): TreeNode[] =>
  nodes.map((node) => ({
    ...node,
    selected: paths.has(node.path) ? selected : node.selected,
    children: selectAllPathsFromSet(node.children, selected, paths)
  }))

/**
 * Creates a deep copy of the tree, finds the nodes with the specified
 * paths and toggles their selected state in the returned copy.
 * @param paths the paths of the nodes that should have their stated updated
 * @param selected the new value for the selected field
 * @param roots the root of the tree structure.
 */
export const selectElements = (
  nodes: TreeNode[],
  paths: string[],
  selected: boolean
): TreeNode[] => {
  const pathsSet = new Set<string>(paths)
  return selectAllPathsFromSet(nodes, selected, pathsSet)
}

/**
 * Creates a deep copy of the tree, finds the node with the specified
 * path and toggles its selected state in the returned copy.
 * @param path the path of the node that should have its stated updated
 * @param selected the new value for the selected field
 * @param roots the root of the tree structure.
 */
export const selectElement = (
  nodes: TreeNode[],
  path: string,
  selected: boolean
): TreeNode[] => selectElements(nodes, [path], selected)

/**
 * Returns a copy of the tree with the provided paths set to the selected value and all other paths set to
 * the inverse
 * @param paths the paths of the nodes that should have their stated updated
 * @param selected the new value for the selected field
 * @param roots the root of the tree structure.
 */
const setSelectedElements = (
  nodes: TreeNode[],
  paths: Set<string>,
  selected: boolean
): TreeNode[] =>
  nodes.map((node) => ({
    ...node,
    selected: paths.has(node.path) ? selected : !selected,
    children: setSelectedElements(node.children, paths, selected)
  }))

/**
 * Returns a copy of the provided tree with every element's selected member set to true
 */
export const selectAllElements = (nodes: TreeNode[]) =>
  setSelectedElements(nodes, new Set(), false)

/**
 * Returns a copy of the provided tree with every element's selected member set to false
 */
export const selectNoElements = (nodes: TreeNode[]) =>
  setSelectedElements(nodes, new Set(), true)

/**
 * Returns a copy of the provided tree with every element's selected selected and all other members deselected
 */
export const selectOnly = (nodes: TreeNode[], paths: string[]) =>
  setSelectedElements(nodes, new Set(paths), true)
