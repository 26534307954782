import { useMutation } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type MigrationMetlJobArgs } from './types'

export const useGetMigrationMetlJobQueryKey = () => {
  const { warehouse } = useProjectNames()
  const { projectId, branchId } = useProjectInfo()
  return [queryKeys.convertMetlJobToDpcPipeline, projectId, branchId, warehouse]
}

const useMigrationMetlJob = () => {
  const { migrateMetlJob } = useDesignerApi()
  const queryKey = useGetMigrationMetlJobQueryKey()

  const { warehouse } = useProjectNames()
  const { projectId, branchId } = useProjectInfo()

  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useMutation({
    mutationKey: queryKey,
    mutationFn: async ({ path, file, mappings }: MigrationMetlJobArgs) => {
      const { data } = await migrateMetlJob({
        projectId,
        branchId,
        warehouse,
        agentVersion,
        dryRun: false,
        path,
        file,
        mappings
      })

      return data
    }
  })
}

export default useMigrationMetlJob
