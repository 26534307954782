import { useSelector } from 'react-redux'

import { sortBy } from 'lodash'

import { type RootState } from 'job-lib/store'
import {
  type GridVariableCollection,
  type JobVariable,
  type JobVariableCollection
} from 'job-lib/types/Variables'

export const useJobVariables = (): JobVariable[] => {
  const variables = useSelector<RootState, JobVariableCollection | undefined>(
    (state) => state.job.job?.variables
  )
  const gridVariables = useSelector<
    RootState,
    GridVariableCollection | undefined
  >((state) => state.job.job?.grids)
  const scalarVariablesList = Object.values(variables ?? {})
  const gridVariablesList = Object.values(gridVariables ?? {})
  const list = scalarVariablesList.concat(gridVariablesList)

  return sortBy(list, (v) => v.definition.name)
}
