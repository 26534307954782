import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { JobType } from 'job-lib/types/JobType'

import { type JobState, type JobStateOrchestration } from '../../job.types'
import { removeOrchestrationLinks } from '../deleteNodes/utils/removeOrchestrationLinks'

interface DetachIteratorPayload {
  iteratorInstanceId: number
}

export const detachIterator: CaseReducer<
  JobState,
  PayloadAction<DetachIteratorPayload>
> = (state, { payload: { iteratorInstanceId } }) => {
  if (jobIsOrchestration(state)) {
    if (!state.job.components[iteratorInstanceId]) {
      return
    }
    const iteratorIds: number[] = []

    const getIteratorStack = (instanceId: number) => {
      const connector =
        state.job.components[instanceId].outputIterationConnectorIDs.at(0)

      if (connector === undefined) {
        return
      }

      const targetId = state.job.iterationConnectors[connector]?.targetID
      const targetComponent = state.job.components[targetId]

      if (!targetId || !targetComponent) {
        return
      }

      iteratorIds.push(instanceId)
      getIteratorStack(targetId)
    }

    getIteratorStack(iteratorInstanceId)

    // In theory, this should never happen as we return early if either
    // the outputIterationConnectorIDs, targetId or targetComponent is undefined
    /* istanbul ignore next */
    if (iteratorIds.length === 0) {
      return
    }

    // We already return early if the iteratorIds array is empty
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bottomMostIterator = iteratorIds.at(-1)!
    const topMostIterator = iteratorIds[0]

    const connectorId =
      state.job.components[bottomMostIterator].outputIterationConnectorIDs.at(0)
    const baseComponentInstanceId =
      state.job.iterationConnectors[connectorId as number]?.targetID

    removeOrchestrationLinks({
      job: state.job,
      componentInstanceIds: iteratorIds
    })

    const { x, y } = state.job.components[topMostIterator]

    // 20 is the iterator height
    const newBaseComponentY = y + iteratorIds.length * 20
    const newBaseComponentX = x

    state.job.components[baseComponentInstanceId].x = newBaseComponentX
    state.job.components[baseComponentInstanceId].y = newBaseComponentY

    iteratorIds.forEach((id, index) => {
      // 60 is 1.5x the height of the component
      state.job.components[id].x = newBaseComponentX + 60 * index
      state.job.components[id].y = newBaseComponentY - 60
    })
  }
}

function jobIsOrchestration(job: JobState): job is JobStateOrchestration {
  return job?.jobType === JobType.Orchestration
}
