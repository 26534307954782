import { SelectedTaskTab } from '../types'

const sessionStorageKey = 'pipelineTreeExpandedItems'

interface ExpandedItems {
  [key: string]: {
    expanded: Record<string, boolean>
    selectedTab: SelectedTaskTab
  }
}

const getParsedStorage = (): ExpandedItems | null => {
  const sessionStorageValue = sessionStorage.getItem(sessionStorageKey)

  if (!sessionStorageValue) {
    return null
  }

  return JSON.parse(sessionStorageValue) as ExpandedItems
}

const initialise = (flowInstanceId?: string) => {
  if (!flowInstanceId) {
    return
  }

  const parsedValue = getParsedStorage()

  if (!parsedValue) {
    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        [flowInstanceId]: {
          expanded: {},
          selectedTab: SelectedTaskTab.TASKS
        }
      })
    )

    return
  }

  /* istanbul ignore else */
  if (!parsedValue?.[flowInstanceId as keyof ExpandedItems]) {
    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        ...parsedValue,
        [flowInstanceId]: {
          expanded: {},
          selectedTab: SelectedTaskTab.TASKS
        }
      })
    )
  }
}

const initialiseRow = ({
  flowInstanceId,
  stepId,
  isExpanded
}: {
  flowInstanceId: string
  stepId: string
  isExpanded: boolean
}) => {
  const parsedValue = getParsedStorage()

  if (
    !parsedValue ||
    parsedValue?.[flowInstanceId]?.expanded?.[stepId] !== undefined
  ) {
    return
  }

  parsedValue[flowInstanceId].expanded[stepId] = isExpanded

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedValue))
}

const setExpanded = (flowInstanceId: string, step: string) => {
  const parsedValue = getParsedStorage()

  if (!parsedValue) {
    return
  }

  parsedValue[flowInstanceId].expanded[step] = true

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedValue))
}

const setCollapsed = (flowInstanceId: string, step: string) => {
  const parsedValue = getParsedStorage()

  if (!parsedValue) {
    return
  }

  parsedValue[flowInstanceId].expanded[step] = false

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedValue))
}

const getExpandedState = (flowInstanceId: string, stepId: string) => {
  const parsedValue = getParsedStorage()

  if (!parsedValue?.[flowInstanceId]?.expanded?.[stepId]) {
    return false
  }

  return parsedValue[flowInstanceId].expanded[stepId]
}

const setSelectedTab = (flowInstanceId: string, tab: SelectedTaskTab) => {
  const parsedValue = getParsedStorage()

  if (!parsedValue?.[flowInstanceId]) {
    return
  }

  parsedValue[flowInstanceId].selectedTab = tab

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedValue))
}

const getSelectedTab = (flowInstanceId: string | undefined) => {
  const parsedValue = getParsedStorage()

  if (!flowInstanceId || !parsedValue?.[flowInstanceId]?.selectedTab) {
    return SelectedTaskTab.TASKS
  }

  return parsedValue[flowInstanceId].selectedTab
}

export const manageExpandedItems = {
  _getStorageKey: () => sessionStorageKey,
  getSelectedTab,
  getExpandedState,
  initialise,
  initialiseRow,
  setSelectedTab,
  setExpanded,
  setCollapsed
}
