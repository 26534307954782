import { cloneDeep } from 'lodash'

import {
  type ConnectorCollection,
  type OrchestrationComponentInstance,
  type OrchestrationJob,
  type TransformationComponentInstance,
  type TransformationJob
} from 'job-lib/types/Job'

export const addConnectedComponents = (
  sourceId: number,
  iterationConnectors: ConnectorCollection,
  job: OrchestrationJob | TransformationJob,
  selectedComponentInstances: Array<
    OrchestrationComponentInstance | TransformationComponentInstance
  >
): void => {
  Object.values(iterationConnectors)
    .filter((connector) => connector.sourceID === sourceId)
    .forEach((connector) => {
      const targetId = connector.targetID

      /* istanbul ignore else */
      if (
        !selectedComponentInstances.some(
          (component) => component.id === targetId
        )
      ) {
        const component = cloneDeep(job?.components[targetId])
        selectedComponentInstances.push(component)

        addConnectedComponents(
          targetId,
          iterationConnectors,
          job,
          selectedComponentInstances
        )
      }
    })
}
