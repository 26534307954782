import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  MicroCta,
  Typography
} from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useFlags } from 'hooks/useFlags'

import classes from './RowTypography.module.scss'

export interface Props {
  showOpenTaskDetailsCTA: boolean
  isExecuting: boolean
  isCancelling: boolean
  id: string
  onNavigate: () => void
  onCancel: (force: boolean) => void
}

const RowCTA: FC<Props> = ({
  id,
  showOpenTaskDetailsCTA,
  isExecuting,
  isCancelling,
  onNavigate,
  onCancel
}) => {
  const { t } = useTranslation()
  const { enableTaskDetailsPolling } = useFlags()
  const [showForceCancelDialog, setShowForceCancelDialog] = useState(false)

  if (showForceCancelDialog) {
    return (
      <DesignerModal
        size="mid"
        onCancel={() => {
          setShowForceCancelDialog(false)
        }}
        ariaLabelledBy="force-cancel-title"
      >
        <div className={classes.CancelDialog}>
          <Typography
            className={classes.CancelDialog__Title}
            as="h2"
            format="tl"
            id="force-cancel-title"
          >
            {t('taskHistory.forceCancel')}
          </Typography>
          <p>{t('taskHistory.forceCancelExplainer')}</p>
          <div className={classes.CancelDialog__Actions}>
            <Button
              alt="secondary"
              text={t('taskHistory.forceCancelAbort')}
              onClick={() => {
                setShowForceCancelDialog(false)
              }}
            />
            <Button
              alt="danger"
              text={t('taskHistory.forceCancelConfirm')}
              onClick={() => {
                setShowForceCancelDialog(false)
                onCancel(true)
              }}
            />
          </div>
        </div>
      </DesignerModal>
    )
  }
  if (showOpenTaskDetailsCTA) {
    return (
      <MicroCta
        aria-label={t('taskHistory.taskShowDetails')}
        alt="transparent"
        size="sm"
        onClick={onNavigate}
        className={classes.RowDetailTrigger}
        data-testid={`task-trigger-details-${id}`}
      >
        <Icon.ArrowLeft className={classes.RightArrow} />
      </MicroCta>
    )
  }
  if (isExecuting || isCancelling) {
    return enableTaskDetailsPolling ? (
      <Typography
        format="sm"
        className={classes.CancelText}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation()
          if (isCancelling) {
            setShowForceCancelDialog(true)
          } else {
            onCancel(false)
          }
        }}
      >
        {isCancelling ? t('taskHistory.forceCancel') : t('common.cancel')}
      </Typography>
    ) : (
      <MicroCta
        aria-label={
          isCancelling
            ? t('taskHistory.forceCancel')
            : t('taskHistory.cancelTask')
        }
        alt="transparent"
        size="sm"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation()
          if (isCancelling) {
            setShowForceCancelDialog(true)
          } else {
            onCancel(false)
          }
        }}
        className={classes.RowDetailTrigger}
        data-testid={`task-cancel-${id}`}
      >
        <Icon.Cross className={classes.CancelIcon} />
      </MicroCta>
    )
  }
  return null
}

export default RowCTA
