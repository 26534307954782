import { isEmpty } from 'lodash'

import type { ProjectType } from 'api/hooks/useGetProject/types'

import { getEnabledComponents } from 'hooks/useComponentEnabledWarehouses'

interface IsComponentSupersededProps {
  supersededBy?: string
  flagEnabled?: boolean
  componentEnabledWarehouses?: Map<string, ProjectType[]>
  projectType?: ProjectType
}

export const isComponentSuperseded = ({
  supersededBy,
  flagEnabled,
  componentEnabledWarehouses,
  projectType
}: IsComponentSupersededProps) => {
  // if required information is not present, or if the feature flag is disabled, then we return early
  if (
    isEmpty(supersededBy) ||
    !flagEnabled ||
    !componentEnabledWarehouses ||
    !projectType
  ) {
    return false
  }

  const enabledComponents = getEnabledComponents(
    componentEnabledWarehouses,
    projectType
  )

  // @ts-expect-error - we know that supersededBy is not empty here
  return enabledComponents.includes(supersededBy)
}

interface GetSupersededFilterProps {
  flagEnabled: boolean
  componentEnabledWarehouses: Map<string, ProjectType[]> | undefined
  projectType: ProjectType
}

export const getSupersededFilter = ({
  flagEnabled,
  componentEnabledWarehouses,
  projectType
}: GetSupersededFilterProps) => {
  return ({ supersededBy }: { supersededBy?: string }) =>
    !isComponentSuperseded({
      supersededBy,
      flagEnabled,
      componentEnabledWarehouses,
      projectType
    })
}
