import { type ChangeEvent, type FC } from 'react'

import { Field, Radio } from '@matillion/component-library'

interface RadioButtonProps {
  id: string
  column: string
  checked: boolean
  disabled: boolean
  onChange: (column: string, checked: boolean) => void
}

export const RadioButton: FC<RadioButtonProps> = ({
  id,
  column,
  checked,
  disabled,
  onChange
}) => {
  return (
    <Field
      inputComponent={Radio}
      data-testid={`radio-${id}-${column}`}
      checked={checked}
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(id, e.target.checked)
      }}
    />
  )
}
