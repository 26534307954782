import { Icon } from '@matillion/component-library'
import classNames from 'classnames'

import { IconButton } from 'components/IconButton/IconButton'

import classes from './ConfigureTable.module.scss'

export const ConfigureTable = ({
  onEdit,
  onExpand,
  isSelected,
  isError,
  hasColumns,
  isExpanded,
  tableName
}: {
  onEdit: () => void
  onExpand: () => void
  isExpanded: boolean
  isSelected: boolean
  isError: boolean
  hasColumns: boolean
  tableName: string
}) => {
  return (
    <div className={classes.ConfigureTable}>
      <IconButton
        data-testid={`configure-${tableName}`}
        label="Configure"
        onClick={onEdit}
        disabled={!isSelected || isError || !hasColumns}
      >
        <Icon.Cog />
      </IconButton>
      <IconButton
        data-testid={`${isExpanded ? 'collapse' : 'expand'}-${tableName}`}
        label={isExpanded ? 'Collapse' : 'Expand'}
        onClick={onExpand}
      >
        <Icon.ChevronDown
          className={classNames({
            [classes['MultiTableConfigEditor__ConfigureTable--expanded']]:
              isExpanded
          })}
        />
      </IconButton>
    </div>
  )
}
