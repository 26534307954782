import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  type PublicationVersions,
  type PublicationVersionsMutationResult
} from '@matillion/git-component-library'
import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useDesignerApiClient } from '../useDesignerApiClient/useDesignerApiClient'

const useGetPublicationVersions = (): PublicationVersionsMutationResult => {
  const { request } = useDesignerApiClient()
  const { projectId } = useProjectInfo()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (environmentId: string) => {
      return request<PublicationVersions>({
        method: 'GET',
        url: 'v1/job-publications/versions',
        params: { projectId, environmentId }
      })
    },
    onError: () => {
      makeToast({
        type: 'error',
        title: t('publishedVersions.error.title'),
        message: t('publishedVersions.error.message')
      })
    }
  })
}

export default useGetPublicationVersions
