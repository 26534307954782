import { useMemo, useState, type ChangeEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CollapsiblePanel,
  Field,
  Toggle,
  Typography,
  type ToggleProps
} from '@matillion/component-library'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { useFlags } from 'hooks/useFlags'

import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { useWizardStepErrors } from '../../hooks'
import { ComponentParameterItem } from '../ComponentParameterItem/ComponentParameterItem'
import classes from './ComponentConfigurationPanel.module.scss'
import { ComponentDemoDataPaintedDoor } from './ComponentDemoDataPaintedDoor'
import ParameterGroupTag from './ParameterGroupTag/ParameterGroupTag'
import type { AccordionSteps, StepsConfig } from './types'
import { getWizardStepsConfigs } from './utils'
import type { ComponentLayout } from './wizardConfigs/types'

interface ComponentConfigurationPanelProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
  onBlur: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
  wizardConfig: ComponentLayout
}

const ExpandAllToggle: FC<ToggleProps> = (props) => {
  return (
    <Toggle {...props} data-testid="expand-all-wizard-steps" small={true} />
  )
}

export const ComponentConfigurationPanel: FC<
  ComponentConfigurationPanelProps
> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur,
  wizardConfig
}) => {
  const { setValidationEnabled } = useComponentValidationProvider()
  const { t } = useTranslation()
  const { enableUploadFilePaintedDoorExperiment } = useFlags()

  const { isUnvalidated, hasFinishedValidating } =
    useComponentValidationProvider()

  const showStepLevelValidation = !isUnvalidated && hasFinishedValidating

  const [accordionSteps, setAccordionSteps] = useState<AccordionSteps>(
    wizardConfig.wizard.steps.reduce((acc: AccordionSteps, step, index) => {
      acc[step.stepId] = {
        stepId: step.stepId,
        isOpen: index === 0,
        isCurrentStep: index === 0
      }
      return acc
    }, {})
  )

  const areAllStepsOpen = Object.values(accordionSteps).every(
    (step) => step.isOpen
  )

  const shouldDisplayDemoDataButton =
    enableUploadFilePaintedDoorExperiment &&
    localStorage.getItem('isDemoDialogDismissed') !== 'true'

  const stepsConfig: StepsConfig = useMemo(() => {
    return getWizardStepsConfigs(
      wizardConfig.wizard.steps,
      componentMetadata,
      componentInstance
    )
  }, [wizardConfig.wizard.steps, componentMetadata, componentInstance])

  const stepErrorCounts = useWizardStepErrors({
    componentInstance,
    wizardConfig,
    stepsConfig
  })

  const getValidationText = (errorCount: number) => {
    if (errorCount > 0) {
      return (
        <Typography
          className={classes.ComponentConfigurationPanel__ValidationText}
          format="bcs"
        >
          {errorCount === 1
            ? t('componentProperties.errorCount_one')
            : t('componentProperties.errorCount', {
                count: errorCount
              })}
        </Typography>
      )
    }
  }

  return (
    <div
      className={classes.ComponentConfigurationPanel__Container}
      data-testid="wizard-configuration-container"
    >
      {shouldDisplayDemoDataButton && (
        <ComponentDemoDataPaintedDoor componentId={componentSummaryId} />
      )}
      <div className={classes.ComponentConfigurationPanel__Accordions}>
        <div className={classes.ComponentConfigurationPanel__ExpandControls}>
          <Field
            inputComponent={ExpandAllToggle}
            checked={areAllStepsOpen}
            name={t('componentProperties.expandAll')}
            text={t('componentProperties.expandAll')}
            value={areAllStepsOpen}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setAccordionSteps((v) =>
                Object.keys(v).reduce(
                  (acc, stepId) => ({
                    ...acc,
                    [stepId]: { ...v[stepId], isOpen: event.target.checked }
                  }),
                  {}
                )
              )
            }}
          />
        </div>
        {wizardConfig.wizard.steps.map((step) => {
          const stepErrorCount = stepErrorCounts[step.stepId]

          return (
            <div
              data-testid={`wizard-step-${step.stepId}`}
              aria-label={`${step.displayName}`}
              key={step.stepId}
            >
              <CollapsiblePanel
                theme="grey-white"
                title={
                  <Typography format="bcs" weight="bold">
                    {step.displayName}
                  </Typography>
                }
                subtitle={
                  <ParameterGroupTag
                    dataTestId={`parameter-group-${step.stepId}`}
                    parameterMetadata={
                      stepsConfig[step.stepId].parameters.find(
                        ([v, _path]) => v?.dplID === step.tagParameterId
                      )?.[0]
                    }
                    componentParamMap={componentInstance.parameters}
                  />
                }
                showValidationStatus={showStepLevelValidation}
                error={showStepLevelValidation && stepErrorCount > 0}
                validationText={getValidationText(stepErrorCount)}
                open={areAllStepsOpen || accordionSteps[step.stepId].isOpen}
                onClick={() => {
                  setAccordionSteps((v) => ({
                    ...v,
                    [step.stepId]: {
                      ...v[step.stepId],
                      isOpen: !v[step.stepId].isOpen
                    }
                  }))
                }}
                className={classes.ComponentConfigurationPanel__Accordion}
              >
                {stepsConfig[step.stepId].parameters.map(
                  ([parameter, path]) => {
                    const omitParameters = wizardConfig.wizard.steps
                      .find((s) => s.stepId === step.stepId)
                      ?.parameters.find(
                        (p) => p.dplId === parameter?.dplID
                      )?.omitParameters

                    return (
                      parameter && (
                        <ComponentParameterItem
                          key={`parameter-${parameter.dplID}`}
                          parameter={parameter}
                          componentSummaryId={componentSummaryId}
                          componentInstance={componentInstance}
                          componentMetadata={componentMetadata}
                          onEdit={onEdit}
                          onBlur={onBlur}
                          omitParameters={omitParameters}
                          parameterPath={path}
                          scrollableContainerSelector='[data-testid="wizard-configuration-container"]'
                        />
                      )
                    )
                  }
                )}

                <div className={classes.ComponentConfigurationPanel__Controls}>
                  {accordionSteps[step.stepId].isCurrentStep &&
                    (step.nextStepId ? (
                      <Button
                        className={classes.ComponentConfigurationPanel__Button}
                        text={t('componentProperties.next')}
                        onClick={() => {
                          setAccordionSteps((v) => {
                            const newSteps = { ...v }
                            newSteps[step.stepId] = {
                              ...v[step.stepId],
                              isOpen: false,
                              isCurrentStep: false
                            }

                            newSteps[step.nextStepId as string] = {
                              ...v[step.nextStepId as string],
                              isOpen: true,
                              isCurrentStep: true
                            }

                            return newSteps
                          })
                        }}
                      />
                    ) : (
                      <Button
                        className={classes.ComponentConfigurationPanel__Button}
                        text={t('componentProperties.finish')}
                        onClick={() => {
                          setValidationEnabled()
                        }}
                      />
                    ))}
                </div>
              </CollapsiblePanel>
            </div>
          )
        })}
      </div>
    </div>
  )
}
