import { useContext } from 'react'

import { ApiClientContext } from './ApiClientContext'

export const useApiClientContext = () => {
  const context = useContext(ApiClientContext)

  if (context === undefined) {
    throw new Error(
      'useApiClientContext must be used within a ApiClientProvider'
    )
  }

  return context
}
