import { useFlags } from 'hooks/useFlags'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { useWorkingCopy as useDplWorkingCopy } from '..'

export const useFlaggedWorkingCopy = () => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()

  const metlWorkingCopy = useWorkingCopy()
  const metlPipeline = useDplWorkingCopy((state) => state.metlPipeline)

  return rolloutEnableWorkingCopyProvider ? metlPipeline : metlWorkingCopy
}
