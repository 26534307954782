import { useCallback, useState, type FormEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Field,
  Typography
} from '@matillion/component-library'
import { heap, useLDClient } from '@matillion/hub-client'

import { useStackingModal } from 'components/StackingModalProvider'
import { TextLink } from 'components/TextLink/TextLink'

import classes from '../FileUploadPaintedDoor.module.scss'

export const FAKE_FORM_TIMEOUT = 300

const FileUploadModal = () => {
  const { t } = useTranslation()
  const { track } = useLDClient()
  const { closeAll } = useStackingModal()

  const [fileFormatCheckboxes, setFileFormatCheckboxes] = useState<{
    [key: string]: boolean
  }>({
    csv: false,
    json: false,
    xml: false,
    other: false
  })

  const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      track('designer-file-upload-form-submission')

      heap.track(
        'Designer - file upload form submission',
        Object.fromEntries(
          Object.entries(fileFormatCheckboxes).map(([key, value]) => [
            `File Upload Experiment Form Option: ${key}`,
            value
          ])
        )
      )
      /**
       * without the timeout ui feels *too* responsive, this is added to give
       * the feeling as though the form is actually doing something
       */
      setTimeout(closeAll, FAKE_FORM_TIMEOUT)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileFormatCheckboxes]
  )

  return (
    <form onSubmit={handleFormSubmit}>
      <Typography
        as="h2"
        format="ts"
        weight="bold"
        className={classes.FileUploadPaintedDoor__Modal__Title}
      >
        {t('fileUpload.modal.comingSoon')}
      </Typography>
      <Typography as="h3" format="bcm" weight="bold">
        {t('fileUpload.modal.whatType')}
      </Typography>

      <div className={classes.FileUploadPaintedDoor__Modal__CheckboxContainer}>
        {Object.entries(fileFormatCheckboxes).map(([displayName, checked]) => (
          <Field
            key={displayName}
            name={displayName}
            text={t(`fileUpload.modal.dataTypes.${displayName}`)}
            checked={checked}
            inputComponent={Checkbox}
            onChange={() => {
              setFileFormatCheckboxes((previousValue) => ({
                ...previousValue,
                [displayName]: !previousValue[displayName]
              }))
            }}
          />
        ))}
      </div>

      <Typography className={classes.FileUploadPaintedDoor__Modal__FooterText}>
        {t('fileUpload.modal.guidance')} <TextLink link="" text="Learn more" />
      </Typography>
      <Button
        className={classes.FileUploadPaintedDoor__Modal__SubmitButton}
        type="submit"
        size="md"
        iconAlign="right"
        text={t('fileUpload.modal.submit')}
        data-testid="upload-file-form-submission"
      />
    </form>
  )
}

export default FileUploadModal
