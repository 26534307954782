import { useState } from 'react'

import classNames from 'classnames'

import { useIsPipelineOpen } from 'hooks/useIsPipelineOpen'

import { VariableScope } from 'job-lib/types/Variables'

import { Create } from './components/Create/Create'
import { Edit } from './components/Edit/Edit'
import { GridContent } from './components/GridContent/GridContent'
import { Manage } from './components/Manage/Manage'
import { StageHeader } from './components/StageHeader/StageHeader'
import classes from './ManageVariables.module.scss'
import {
  Stages,
  type ContentProps,
  type CreateFormProps,
  type EditFormProps,
  type GridFormProps
} from './types'

export const SEARCH_DEBOUNCE_TIME_MS = 500

interface StagePropsMap {
  [Stages.MANAGE]: { variableScope: VariableScope }
  [Stages.CREATE]: CreateFormProps
  [Stages.CREATE_GRID]: GridFormProps
  [Stages.EDIT_GRID]: GridFormProps
  [Stages.EDIT_JOB_VARIABLE]: EditFormProps
  [Stages.EDIT_PROJECT_VARIABLE]: EditFormProps
}

export type ActiveStageState = {
  [K in keyof StagePropsMap]: { stage: K } & Omit<
    StagePropsMap[K],
    keyof ContentProps
  >
}[keyof StagePropsMap]

interface VariableStageConfig<T extends keyof StagePropsMap> {
  title?: string
  Content: (props: ContentProps<StagePropsMap[T]>) => JSX.Element
}

export const stages: {
  [K in keyof StagePropsMap]: VariableStageConfig<K>
} = {
  [Stages.MANAGE]: {
    title: 'manageVariables.common.manageYourVars',
    Content: Manage
  },
  [Stages.CREATE]: {
    title: 'manageVariables.create.title',
    Content: Create
  },
  [Stages.CREATE_GRID]: {
    Content: GridContent
  },
  [Stages.EDIT_GRID]: {
    Content: GridContent
  },
  [Stages.EDIT_JOB_VARIABLE]: {
    title: 'manageVariables.edit.jobVariableTitle',
    Content: Edit
  },
  [Stages.EDIT_PROJECT_VARIABLE]: {
    title: 'manageVariables.edit.projectVariableTitle',
    Content: Edit
  }
}

export const ManageVariables = ({ onCancel }: { onCancel?: () => void }) => {
  const isPipelineOpen = useIsPipelineOpen()

  const [stage, setStage] = useState<ActiveStageState>({
    stage: Stages.MANAGE,
    variableScope: isPipelineOpen
      ? VariableScope.JOB_VARIABLE
      : VariableScope.PROJECT_VARIABLE
  })

  const { stage: activeStage, ...rest } = stage
  const { title = '', Content } = stages[activeStage]

  const ActiveStageContent = Content as (
    props: ContentProps<StagePropsMap[typeof activeStage]>
  ) => JSX.Element

  if (activeStage === Stages.CREATE_GRID || activeStage === Stages.EDIT_GRID) {
    return <ActiveStageContent {...rest} setStage={setStage} />
  }

  const hideHeader = activeStage === Stages.MANAGE

  return (
    <div
      data-testid="variables-container"
      className={classNames(classes.ManageVariablesPanel, classes.Wizard)}
    >
      {!hideHeader && <StageHeader onCancel={onCancel} title={title} />}
      <ActiveStageContent {...rest} setStage={setStage} />
    </div>
  )
}
