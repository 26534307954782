import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { Actions } from '../../components'
import {
  type ConfigData,
  type DataSelectionConfig,
  type LoadStrategy
} from '../../types'
import classes from './ApplyAll.module.scss'
import { Conditions, Conflicts, LoadingPanel } from './components'
import { useApplyToAll } from './hooks/useApplyToAll'

interface ApplyAllProps {
  activeTableConfig: DataSelectionConfig
  data: ConfigData
  fetchCompletion: number
  loadStrategy: LoadStrategy
  onSave: (saveData: ConfigData) => void
  onCancel: () => void
}

export const ApplyAll: FC<ApplyAllProps> = ({
  activeTableConfig,
  data,
  fetchCompletion,
  loadStrategy,
  onSave,
  onCancel
}) => {
  const { t } = useTranslation()

  const isFetchColumnsComplete = fetchCompletion === 1

  const {
    conditions,
    conflictsData,
    availableConditionTables,
    addCondition,
    setConditionTable,
    setConditionValue,
    setConflictValue,
    getSaveData,
    applyToAll
  } = useApplyToAll(
    data,
    activeTableConfig,
    loadStrategy,
    isFetchColumnsComplete
  )

  const handleSave = () => {
    onSave(getSaveData())
  }

  return (
    <>
      <div
        data-testid="multi-select-apply-all-screen"
        className={classNames(classes.ApplyAll, classes.ApplyAll__Header)}
      >
        <Typography format="ts" weight="bold">
          {t('multiTableConfig.applyToAll.title')}
        </Typography>
        <Typography format="bcm">
          {t('multiTableConfig.applyToAll.subTitle')}
        </Typography>
      </div>
      <div className={classes.ApplyAll__ContentContainer}>
        <div
          className={classNames(classes.ApplyAll, classes.ApplyAll__Content)}
        >
          {!isFetchColumnsComplete && (
            <LoadingPanel fetchCompletion={fetchCompletion} />
          )}
          {isFetchColumnsComplete && (
            <>
              <Conditions
                conditions={conditions}
                tableName={activeTableConfig.dataSource}
                loadStrategy={loadStrategy}
                availableConditionTables={availableConditionTables}
                addCondition={addCondition}
                onColumnsSelectionChange={setConditionValue}
                onApplyToAll={applyToAll}
                onColumnTableChange={setConditionTable}
              />
              {conflictsData && conflictsData.length > 0 && (
                <Conflicts
                  conflictsData={conflictsData}
                  loadStrategy={loadStrategy}
                  onConfigDataChange={setConflictValue}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className={classNames(classes.ApplyAll, classes.ApplyAll__Footer)}>
        <Actions onSave={handleSave} onCancel={onCancel} />
      </div>
    </>
  )
}
