import { useCallback, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Field } from '@matillion/component-library'
import { type ParameterValue } from 'types/Pipeline'

import {
  EditorType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { type Failure } from 'api/hooks/useValidateComponent/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { TextInput } from 'components/TextInput'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'
import { Dropdown } from 'modules/ParameterEditors/components/Dropdown/Dropdown'
import { ModalTriggerEditor } from 'modules/ParameterEditors/components/ModalTriggerEditor/ModalTriggerEditor'
import { PropertyLabel } from 'modules/ParameterEditors/components/MultiPropertiesEditor/PropertyLabels/PropertyLabel'
import { MultiTableWrapper } from 'modules/ParameterEditors/components/MultiTableConfigurationEditor'

import classes from './ValueRenderer.module.scss'

export interface ValueRendererProps {
  labelId: string
  inputId: string
  value: string[]
  hasError: boolean
  errorMessage?: string
  validationError?: Failure
  elements?: ElementCollection
  parameterValue?: ParameterValue
  showAsOptional?: boolean

  parameter?: ComponentParameter
  parameterName: string
  path: string[]

  onEdit: (
    editedValue: ElementCollection | ParameterValue,
    editorColumns?: EditorColumn[]
  ) => void
  onBlur: (
    editedValue: ElementCollection,
    editorColumns?: EditorColumn[]
  ) => void
  onEditorError: (error?: ProblemDetails) => void
  scrollableContainerSelector: string
}

export const ValueRenderer: FC<ValueRendererProps> = ({
  labelId,
  inputId,
  value,
  hasError,
  errorMessage,
  validationError,
  elements,
  parameterValue,
  showAsOptional,
  parameter,
  parameterName,
  path,
  scrollableContainerSelector,
  onEdit,
  onBlur,
  onEditorError
}) => {
  const { t } = useTranslation()
  const { componentInstance } = useActiveComponentInfo()

  const optionalLabelText = showAsOptional ? `(${t('common.optional')})` : ''

  const onEditSingleValue = useCallback(
    (val: string | string[]) => {
      onEdit(createHomogeneousElements(val, parameter?.dataType))
    },
    [onEdit, parameter]
  )

  const onBlurSingleValue = useCallback(
    (val: string | string[]) => {
      onBlur(createHomogeneousElements(val, parameter?.dataType))
    },
    [onBlur, parameter]
  )

  const onEditDplValue = useCallback(
    (editedValue: ParameterValue) => {
      onEdit(editedValue)
    },
    [onEdit]
  )

  if (!parameter) {
    return <div className={classes.ValueRenderer}>{value}</div>
  }

  switch (parameter.editorType) {
    case EditorType.FREETEXT:
      return (
        <Field
          inputComponent={TextInput}
          title={parameterName}
          id={inputId}
          hasError={hasError}
          value={value[0] || ''}
          onChange={onEditSingleValue}
          onBlur={onBlurSingleValue}
          errorText={errorMessage}
          className={classes.ValueRenderer__InputField}
          optionalLabelText={optionalLabelText}
          showWizardStyling
          compact
          staticLabelColor
        />
      )
    case EditorType.DROPDOWN:
      return (
        <div className={classes.ValueRenderer}>
          <Dropdown
            inputId={inputId}
            hasError={hasError}
            errorText={errorMessage}
            value={value[0] || ''}
            onEdit={onEditSingleValue}
            onBlur={onBlurSingleValue}
            onEditorError={onEditorError}
            parameter={parameter}
            parameterName={parameterName}
            optionalLabelText={optionalLabelText}
            scrollableContainerSelector={scrollableContainerSelector}
            compact
            staticLabelColor
          />
          <PropertyLabel parameter={parameter} value={value.at(0)} />
        </div>
      )
    case EditorType.MULTI_TABLE_CONFIGURATION_EDITOR: {
      return parameter.childProperties && componentInstance ? (
        <MultiTableWrapper
          parameterName={parameterName}
          path={path}
          parameterValue={parameterValue ?? null}
          childProperties={parameter.childProperties}
          onSave={onEditDplValue}
        />
      ) : null
    }
    default:
      return (
        <div className={classes.ValueRenderer}>
          <div className={classes.ValueRenderer__InputContainer}>
            <ModalTriggerEditor
              inputId={inputId}
              value={value}
              elements={elements}
              hasError={hasError}
              errorText={errorMessage}
              onEdit={onEdit}
              onBlur={onBlur}
              onEditorError={onEditorError}
              editorType={parameter.editorType}
              parameter={parameter}
              parameterName={parameterName}
              showLabel
              optionalLabelText={optionalLabelText}
              compact
              staticLabelColor
            />
          </div>
        </div>
      )
  }
}
