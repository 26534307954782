import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './EditorError.module.scss'

export interface EditorErrorBoundaryFallbackProps {
  fileName: string
  language: string
}

export const EditorErrorBoundaryFallback = ({
  fileName,
  language
}: EditorErrorBoundaryFallbackProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'textEditorErrorBoundary'
  })

  return (
    <div
      className={classes.EditorError}
      data-testid="file-text-editor-error-fallback"
    >
      <div className={classes.EditorError__Container}>
        <Typography as="h2" format="tl" className={classes.EditorError__Title}>
          {t('title')}
        </Typography>

        <Typography className={classes.EditorError__Description}>
          {t('description', { language, fileName })}
        </Typography>
      </div>
    </div>
  )
}
