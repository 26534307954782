import { type FC } from 'react'

import {
  EditorType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries/types'

import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'

import { SelectTablesEditor } from '../../editors'
import {
  type ConfigData,
  type LoadStrategy,
  type TableDefMap,
  type UpdateTableConfigHandler
} from '../../types'
import { getTableSelectionParameter } from '../../utils/getEditorParameter'

interface TableSelectionProps {
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  componentMetadata: ComponentMetadata
  componentInstance: ComponentInstance
  childProperties: ComponentParameter[]
  data: ConfigData
  allTables: TableDefMap
  loadStrategy?: LoadStrategy
  onFetchTables: (data: TableDefMap) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onResetTableError: (tableName: string) => void
  onCancel: () => void
  onSave: () => void
  onConfigureTable: (tableName: string) => void
}

export const TableSelection: FC<TableSelectionProps> = ({
  componentInstanceId,
  componentSummaryId,
  componentMetadata,
  componentInstance,
  childProperties,
  data,
  allTables,
  loadStrategy,
  onFetchTables,
  onUpdateTableConfig,
  onResetTableError,
  onCancel,
  onSave,
  onConfigureTable
}) => {
  const { parameter } = getTableSelectionParameter(childProperties)

  if (parameter?.editorType === EditorType.TABLE_SELECTION) {
    return (
      <SelectTablesEditor
        componentInstanceId={componentInstanceId}
        componentSummaryId={componentSummaryId}
        componentMetaData={componentMetadata}
        componentInstance={componentInstance}
        parameter={parameter}
        data={data}
        allTables={allTables}
        loadStrategy={loadStrategy}
        onFetchTables={onFetchTables}
        onUpdateTableConfig={onUpdateTableConfig}
        onResetTableError={onResetTableError}
        onCancel={onCancel}
        onSave={onSave}
        onConfigureTable={onConfigureTable}
      />
    )
  }

  return null
}
