import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'
import { type CreateProjectVariablesRequest } from './types'

export const useCreateProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const { createProjectVariables } = useProjectExplorerApi()

  return useMutation(
    async (createProjectVariablesRequest: CreateProjectVariablesRequest) => {
      const response = await createProjectVariables(
        projectId,
        createProjectVariablesRequest
      )
      return response.status
    }
  )
}
