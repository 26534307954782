import { useCallback, useMemo, useState, type ReactNode } from 'react'

import {
  ParameterOverlayErrorContext,
  type OverlayError
} from '../parameterOverlayErrorContext'

export const ErrorContextWrapper = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<OverlayError[]>([])

  const clearError = useCallback(
    () => (id: string) => errors.filter((error) => error.id === id),
    [errors]
  )

  const value = useMemo(
    () => ({ errors, setErrors, clearError }),
    [clearError, errors]
  )

  return (
    <ParameterOverlayErrorContext.Provider value={value}>
      {children}
    </ParameterOverlayErrorContext.Provider>
  )
}
