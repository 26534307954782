import { useEffect } from 'react'

export const useWindowClick = (callback: () => void) => {
  const onWindowKeyDown = (event: KeyboardEvent) => {
    /* istanbul ignore next */
    if (event.defaultPrevented) {
      return
    }

    /* istanbul ignore next */
    if (event.key === 'Escape') {
      callback()
    }
  }

  const onWindowClick = (event: MouseEvent | TouchEvent) => {
    if (event.defaultPrevented) {
      return
    }

    callback()
  }

  useEffect(() => {
    window.addEventListener('click', onWindowClick)
    window.addEventListener('keydown', onWindowKeyDown)
    window.addEventListener('touchend', onWindowClick)

    return () => {
      window.removeEventListener('click', onWindowClick)
      window.removeEventListener('keydown', onWindowKeyDown)
      window.removeEventListener('touchend', onWindowClick)
    }
  })
}
