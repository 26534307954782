import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Alert } from '@matillion/component-library'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import {
  ParameterOverlayButton,
  ParameterOverlayErrors,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { type RootState } from 'job-lib/store'
import { JobType } from 'job-lib/types/JobType'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { type ParameterOptions } from '../../types'
import { GridVariableEditor } from '../GridVariables/GridVariableEditor/GridVariableEditor'
import { useGridVariableEditor } from '../GridVariables/GridVariableEditor/useGridVariableEditor'
import { getGridElements } from '../GridVariables/GridVariableEditor/utils'
import { GridVariableSwitcher } from '../GridVariables/GridVariableSwitcher/GridVariableSwitcher'
import { TableEditor } from './components/Table/TableEditor'
import {
  TableVariableEditorContextProvider,
  useTableVariableEditorContext
} from './components/Table/TableVariableEditorContext'
import classes from './GridEditor.module.scss'
import {
  GridEditorContextProvider,
  useGridEditorContext
} from './GridEditorContext'

export interface GridEditorProps extends ParameterOptions {
  elements: ElementCollection
  parameterName: string
  parameter: ComponentParameter
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
  disallowDuplicates?: boolean
}

export const GridEditor = (props: GridEditorProps) => {
  return (
    <GridEditorContextProvider {...props}>
      <TableVariableEditorContextProvider>
        <GridEditorDetails />
      </TableVariableEditorContextProvider>
    </GridEditorContextProvider>
  )
}
export const GridEditorDetails = () => {
  const { t } = useTranslation()
  const {
    editorColumns,
    parameterName,
    problemDetail,
    isUsingGridVariable,
    parameter,
    elements,
    columnNames,
    onDone
  } = useGridEditorContext()
  const {
    columnValues,
    setColumnValues,
    selectedGridVariable,
    setSelectedGridVariable
  } = useGridVariableEditor({
    isUsingGridVariable,
    elements,
    columnNames
  })

  const {
    onTableVariableSubmit,
    duplicateField,
    autofillNoData,
    addAll: { error: addAllError },
    textMode: { textModeOpen },
    table
  } = useTableVariableEditorContext()

  const [useGridVariableChecked, setUseGridVariableChecked] =
    useState(isUsingGridVariable)

  const hasColumns = editorColumns.length > 0
  const { isUnvalidated, setValidationEnabled } =
    useComponentValidationProvider()

  const handleSubmit = useCallback(() => {
    if (useGridVariableChecked) {
      onDone(getGridElements(columnNames, columnValues, selectedGridVariable))
    } else {
      onTableVariableSubmit()
    }
  }, [
    onTableVariableSubmit,
    useGridVariableChecked,
    onDone,
    columnValues,
    columnNames,
    selectedGridVariable
  ])

  const jobType = useSelector<RootState>((state) => state.job.jobType)
  const requiresValidation = isUnvalidated && jobType === JobType.Transformation

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      {problemDetail?.detail && (
        <Alert
          aria-label={problemDetail.detail}
          className={classes.Alert}
          type="error"
          message={problemDetail.detail}
        />
      )}
      {duplicateField && (
        <Alert
          aria-label={t('parameterEditor.GRID_EDITOR.duplicateFieldError', {
            duplicateField
          })}
          className={classes.Alert}
          type="error"
          message={t('parameterEditor.GRID_EDITOR.duplicateFieldError', {
            duplicateField
          })}
        />
      )}
      {requiresValidation && (
        <Alert
          aria-label={t('parameterEditor.warnings.validationWarning')}
          className={classes.Alert}
          type="warning"
          title={t('parameterEditor.warnings.validationWarning')}
          message=""
          action={{
            text: t('parameterEditor.warnings.validationAction'),
            onClick: setValidationEnabled
          }}
        />
      )}
      {addAllError && (
        <Alert
          aria-label={addAllError.detail}
          className={classes.Alert}
          type="error"
          message={addAllError.detail}
        />
      )}
      {autofillNoData && (
        <Alert
          aria-label={t('parameterEditor.GRID_EDITOR.addAllNoDataError')}
          className={classes.Alert}
          type="error"
          title={t('parameterEditor.GRID_EDITOR.addAllNoDataError')}
          message=""
        />
      )}
      <ParameterOverlayErrors />
      <ParameterOverlayContent>
        {useGridVariableChecked ? (
          <GridVariableEditor
            columnNames={columnNames}
            columnValues={columnValues}
            selectedGridVariable={selectedGridVariable}
            setColumnValues={setColumnValues}
            setSelectedGridVariable={setSelectedGridVariable}
          />
        ) : (
          <TableEditor />
        )}
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={!hasColumns || Boolean(duplicateField) || textModeOpen}
          data-testid="grid-editor-done-button"
          onClick={handleSubmit}
          text={t('common.save')}
        />
        <GridVariableSwitcher
          checked={useGridVariableChecked}
          setChecked={(checked) => {
            table.resetRowSelection(true)
            setUseGridVariableChecked(checked)
          }}
          disabled={!hasColumns || textModeOpen}
          parameter={parameter}
        />
      </ParameterOverlayFooter>
    </>
  )
}
