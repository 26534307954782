import { DocsVerbosity } from 'api/hooks/sidekick/useGeneratePipelineDocs/useGeneratePipelineDocs'

export function increaseVerbosity(
  currentVerbosity: DocsVerbosity
): DocsVerbosity {
  switch (currentVerbosity) {
    case DocsVerbosity.LOW:
      return DocsVerbosity.MEDIUM
    case DocsVerbosity.MEDIUM:
      return DocsVerbosity.HIGH
    default:
      return currentVerbosity
  }
}

export function decreaseVerbosity(
  currentVerbosity: DocsVerbosity
): DocsVerbosity {
  switch (currentVerbosity) {
    case DocsVerbosity.HIGH:
      return DocsVerbosity.MEDIUM
    case DocsVerbosity.MEDIUM:
      return DocsVerbosity.LOW
    default:
      return currentVerbosity
  }
}
