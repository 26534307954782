import { type FunctionComponent } from 'react'

import { Typography, type TypographyProps } from '@matillion/component-library'

import classes from './ContentWithShortcut.module.scss'

interface ContentWithShortcutProps {
  content: string
  shortcut: string
  format?: TypographyProps['format']
}

export const ContentWithShortcut: FunctionComponent<
  ContentWithShortcutProps
> = ({ content, shortcut, format = 'bcs' }) => {
  return (
    <Typography format={format}>
      {content} <span className={classes.Shortcut_Keys}>{shortcut}</span>
    </Typography>
  )
}
