import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import { type MigrationMetlJobArgs } from './types'
import { useGetMigrationMetlJobQueryKey } from './useMigrationMetlJob'

const useGetMetlJobMigrationReport = ({
  file,
  path,
  mappings
}: MigrationMetlJobArgs) => {
  const { migrateMetlJob } = useDesignerApi()
  const queryKey = useGetMigrationMetlJobQueryKey()

  const { warehouse } = useProjectNames()
  const { projectId, branchId } = useProjectInfo()

  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useQuery({
    cacheTime: 0,
    queryKey: [...queryKey, file.name],
    enabled: Boolean(file),
    queryFn: async () => {
      const { data } = await migrateMetlJob({
        projectId,
        branchId,
        warehouse,
        agentVersion,
        dryRun: true,
        path,
        file,
        mappings
      })

      return data
    }
  })
}

export default useGetMetlJobMigrationReport
