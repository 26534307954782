import { memo, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, type HandleProps } from 'reactflow'

import { Tooltip } from '@matillion/component-library'
import classnames from 'classnames'

import { type ConnectionPortTypeT } from 'job-lib/types/Components'

import classes from '../FlowCanvas.module.scss'

export type PortProps = HandleProps & {
  id: ConnectionPortTypeT
  active?: boolean
}

export const FlowPort: FC<PortProps> = memo(({ id, active, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={classes.FlowPort__Container}>
      <Tooltip content={t(`canvas.ports.${id}`)}>
        <Handle
          className={classnames(classes.FlowPort, classes[`FlowPort--${id}`], {
            [classes['FlowPort--active']]: active
          })}
          id={id}
          data-port={id}
          {...props}
        >
          <svg
            className={classes.FlowPort__Icon}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            data-testid={`flow-port-${id}`}
          >
            <circle cx="12" cy="12" r="8" stroke="currentColor" />

            {active && (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 8H11V11H8V13H11V16H13V13H16V11H13V8Z"
                fill="currentColor"
              />
            )}
          </svg>
        </Handle>
      </Tooltip>
    </div>
  )
})

FlowPort.displayName = 'FlowPort'
