import { useDispatch } from 'react-redux'

import { useFlags } from 'hooks/useFlags'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { jobActions } from 'job-lib/store'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const useDetachIterator = () => {
  const dispatch = useDispatch()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)
  const { job } = useFlaggedWorkingCopy()

  const detachIterator = (iteratorInstanceId: number) => {
    if (iteratorInstanceId === undefined) {
      return
    }

    if (rolloutEnableWorkingCopyProvider) {
      if (!job) {
        return
      }
      const componentName = getComponentName(job.components[iteratorInstanceId])

      update((state) => {
        state.detachIterator(componentName)
      })
    } else {
      dispatch(jobActions.detachIterator({ iteratorInstanceId }))
    }
  }

  return { detachIterator }
}
