import { useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'
import { isEqual } from 'lodash'
import { type ParameterValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentMetadataParameterId
} from 'api/hooks/useGetComponentMetadata/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'
import {
  headerParamsParameter,
  queryParamsParameter,
  uriParamsParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { getComponentName } from 'job-lib/job-functions/getComponentName'
import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'

import classes from '../../ComponentParameters.module.scss'
import { type ComponentParameterBag } from '../../types'
import { ActiveComponentInfoProvider } from '../ActiveComponentInfoProvider/ActiveComponentInfoProvider'
import { ValueRenderer } from '../ValueRenderer/ValueRenderer'

interface ComponentParameterProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  parameter: ComponentParameterBag
  onEdit: (editedValue: ElementCollection | ParameterValue) => void
  onBlur: (editedValue: ElementCollection) => void
  path: string[]
  scrollableContainerSelector: string
}

const useComponentParameterValidationError = ({
  componentInstanceId,
  parameterSlot,
  dplId,
  path
}: {
  componentInstanceId: ComponentInstanceId
  parameterSlot: number
  dplId: ComponentMetadataParameterId | undefined
  path: string[]
}) => {
  const { failures } = useComponentValidationResult(componentInstanceId)

  return useMemo(
    () =>
      failures?.find(
        (failure) => failure.path.at(1) === String(parameterSlot)
      ) ?? failures?.find((failure) => isEqual(path, failure.path.slice(1))),
    [failures, parameterSlot, path]
  )
}

export const ComponentParameter: FunctionComponent<ComponentParameterProps> = ({
  componentInstance,
  componentMetadata,
  parameter,
  onEdit,
  onBlur,
  path,
  scrollableContainerSelector
}) => {
  const { t } = useTranslation()
  const [editorError, setEditorError] = useState<ProblemDetails>()

  const validationError = useComponentParameterValidationError({
    componentInstanceId: componentInstance.id,
    parameterSlot: parameter.parameterSlot,
    dplId: parameter.parameterMetadata?.dplID,
    path
  })

  const parameterLabelId =
    parameter.parameterMetadata?.dplID ?? parameter.parameterSlot.toString()

  const componentSummaryId = parameter.componentSummaryId
  const labelId = `param-label-${parameterLabelId}`
  const inputId = `param-input-${parameterLabelId}`

  const visuallyHideOptionalLabel =
    isModularFlexOrCustom(componentSummaryId) &&
    [queryParamsParameter, headerParamsParameter, uriParamsParameter].includes(
      parameterLabelId
    )

  const errorMessage =
    (editorError &&
      t('componentProperties.status.lookupFailed', {
        parameterName: parameter.parameterName,
        errorMessage: editorError.detail
      })) ||
    validationError?.message

  return (
    <ActiveComponentInfoProvider
      componentInstance={componentInstance}
      componentMetadata={componentMetadata}
      componentName={getComponentName(componentInstance)}
      componentSummaryId={componentSummaryId}
    >
      <div
        className={classnames(classes.ComponentParameter__WizardValueRenderer, {
          'u-visually-hidden': parameter.isVisuallyHidden
        })}
      >
        <ValueRenderer
          labelId={labelId}
          inputId={inputId}
          value={parameter.value}
          hasError={Boolean(editorError || validationError)}
          errorMessage={errorMessage}
          validationError={validationError}
          elements={parameter.elements}
          parameterValue={parameter.parameterValue}
          showAsOptional={parameter.isOptional && !visuallyHideOptionalLabel}
          parameter={parameter.parameterMetadata}
          parameterName={parameter.parameterName}
          path={path}
          onEdit={onEdit}
          onBlur={onBlur}
          onEditorError={setEditorError}
          scrollableContainerSelector={scrollableContainerSelector}
        />
      </div>
    </ActiveComponentInfoProvider>
  )
}
