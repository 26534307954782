import {
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'
import { type TFunction } from 'i18next'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { type GridCell } from 'modules/ParameterEditors/components/GridEditor/components/Grid/types'

import { GridDropdown } from '../GridDropdown/GridDropdown'
import { GridFreeText } from '../GridFreeText/GridFreeText'

const inputMap = {
  FREETEXT: GridFreeText,
  DROPDOWN: GridDropdown
}

export const makeEmptyRow = (cols: Array<DataGridColumnProps<DataGridRow>>) => {
  return cols.reduce((acc, col) => {
    return {
      ...acc,
      [col.key]: ''
    }
  }, {})
}

export const translateColumnName = (column: EditorColumn, tf: TFunction) => {
  const { resourceID, name } = column
  const columnName = name ?? ''
  const resourceName = resourceID
    ? tf(`componentProperties.columnIds.${resourceID}`)
    : ''
  return columnName || resourceName
}

export const getCols = (
  filterEditorColumns: EditorColumn[],
  componentSummaryId: ComponentSummaryId,
  componentMetaData: ComponentMetadata,
  onChange: (gc: GridCell) => void,
  tf: TFunction,
  replaceLookupParamId?: string,
  replaceLookupParamValue?: string
) => {
  return filterEditorColumns
    .filter((col) => col.type === 'TEXT')
    .map<DataGridColumnProps<DataGridRow>>((col) => {
      const editorType = inputMap[col.columnEditorType as keyof object]

      const name = translateColumnName(col, tf)

      const result: DataGridColumnProps<DataGridRow> = {
        key: '',
        title: name
      }

      if (editorType && col.resourceID) {
        result.key = col.resourceID
        result.as = editorType
        result.mapValues = (filterRow) => ({
          componentSummaryId,
          componentMetadata: componentMetaData,
          editorColumn: col,
          dataSourceDplID: replaceLookupParamId,
          dataSourceValue: replaceLookupParamValue,
          parameter: {
            ...col
          },
          slot: filterRow.id,
          rowSlot: col.resourceID,
          dataType: col.type,
          value: filterRow[col.resourceID as keyof typeof filterRow],
          parameterName: name,
          onChange
        })
      }

      return result
    })
    .filter((col) => col.key && col.as)
}
