import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type AutoCompleteItemId,
  type DataGridColumnProps
} from '@matillion/component-library'
import classNames from 'classnames'

import { AutoComplete } from 'components/AutoComplete'

import {
  Fields,
  ReducerActions,
  type FormState,
  type GridVariableDataGridRow
} from 'modules/ManageVariables/types'
import { FreeTextInputEditor } from 'modules/ParameterEditors/components'

import {
  type EditDataGridAction,
  type ReducerAction
} from '../../../Form/formReducer/formReducer'
import { columnHeaderTranslations } from '../../../Form/gridTranslations'
import { GridVariableDataGrid } from '../GridVariableDataGrid/GridVariableDataGrid'
import classes from '../Steps.module.scss'

interface GridColumnsGridProps {
  dispatch: Dispatch<ReducerAction>
  state: FormState
  typeOptions: AutoCompleteItemId[]
}

export const GridColumnsGrid = ({
  dispatch,
  state,
  typeOptions
}: GridColumnsGridProps) => {
  const { t } = useTranslation()
  const field = Fields.COLUMN_GRID

  const { errors } = state.COLUMN_GRID.value
  const colNameIndex = 0
  const colTypeIndex = 1
  const tableColumns: Array<DataGridColumnProps<GridVariableDataGridRow>> = [
    {
      key: 'column_name',
      title: t(columnHeaderTranslations.columnName),
      as: FreeTextInputEditor,
      mapValues: (row) => {
        const hasError = errors.some(
          (err) => err.rowId === row.id && err.columnName === 'columnName'
        )

        const inputProps: React.ComponentProps<typeof FreeTextInputEditor> = {
          containerClassName: classes.Freetext,
          required: true,
          dataTestId: 'row-column-value',
          placeholder: t(
            'manageVariables.createGridVar.grids.textEditorPlaceholder'
          ),
          hasError,
          inputId: row.id,
          labelId: row.id,

          value: row.columns[colNameIndex],
          onEdit: (newValue: string) => {
            const action: EditDataGridAction = {
              type: ReducerActions.EDIT_DATA_GRID_VALUE,
              field,
              newValue,
              rowId: row.id,
              colIndex: colNameIndex
            }

            dispatch(action)
          }
        }

        return inputProps
      }
    },
    {
      key: 'column_type',
      title: t(columnHeaderTranslations.columnType),
      as: AutoComplete,
      mapValues: (row) => {
        const hasError = errors.some(
          (err) => err.rowId === row.id && err.columnName === 'columnType'
        )

        const inputProps: React.ComponentProps<typeof AutoComplete> = {
          inputClassName: classes.Dropdown__Input,
          className: classNames(classes.Dropdown, {
            [classes.Dropdown__Error]: hasError
          }),
          required: true,
          placeholder: t(
            'manageVariables.createGridVar.grids.dropdownPlaceholder'
          ),
          error: hasError,

          availableItems: typeOptions,
          value: {
            id: row.columns[colTypeIndex],
            name:
              typeOptions.find((type) => type.id === row.columns[colTypeIndex])
                ?.name ?? row.columns[colTypeIndex]
          },
          onKeyDown: (e, isMenuOpen) => {
            if (e.key === 'Enter' && isMenuOpen) {
              e.preventDefault()
            }
          },
          onChange: (newValue) => {
            const action: EditDataGridAction = {
              type: ReducerActions.EDIT_DATA_GRID_VALUE,
              field,
              newValue: newValue.target.value?.id as string,
              rowId: row.id,
              colIndex: colTypeIndex
            }

            dispatch(action)
          }
        }

        return inputProps
      }
    }
  ]

  return (
    <GridVariableDataGrid
      columns={tableColumns}
      dispatch={dispatch}
      rows={state.COLUMN_GRID.value.rows}
      selectedRows={state.COLUMN_GRID.value.selectedRows}
      field={Fields.COLUMN_GRID}
      errors={errors.filter((e) => e.errorType !== 'EMPTY')}
    />
  )
}
