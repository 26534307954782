import { Tooltip } from '@matillion/component-library'
import { capitalize } from 'lodash'

import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { getProjectIcon } from '../utils'
import classes from './WarehouseIcon.module.scss'

export const WarehouseIcon = () => {
  const { data, isLoading, isError } = useGetProject()

  if (isError || isLoading) {
    return null
  }

  const SelectorIcon = getProjectIcon(data?.warehouse)
  const warehouse = data?.warehouse

  return (
    <Tooltip content={capitalize(warehouse)} align="start">
      <span className={classes.Icon}>
        <SelectorIcon
          data-testid={`project-toolbar-projectName-icon-${warehouse}`}
        />
      </span>
    </Tooltip>
  )
}
