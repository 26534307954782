import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const AmazonTranscribeConfig: ComponentLayout = {
  componentId: 'modular-aws-transcribe-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: null,
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'aws-transcribe-input-v1',
            omitParameters: ['connection']
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null)
    ]
  }
}
