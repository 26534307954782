import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Tooltip } from '@matillion/component-library'

import { type DataSelectionConfig, type TableDef } from '../../types'
import { SelectionFraction } from '../Fraction/SelectionFraction'
import classes from './Elements.module.scss'

interface ElementsProps {
  tableDef?: TableDef
  tableConfig?: DataSelectionConfig
  isSelected: boolean
  isTableNameSelectionConflict: string | null
}

export const Elements: FC<ElementsProps> = ({
  isSelected,
  tableDef,
  tableConfig,
  isTableNameSelectionConflict
}) => {
  const { t } = useTranslation()
  if (
    isTableNameSelectionConflict !== null &&
    isTableNameSelectionConflict === tableConfig?.targetTableName
  ) {
    return (
      <Tooltip
        content={t(
          'multiTableConfig.tableSelection.tableSelectionNameConflict'
        )}
      >
        <div
          className={classes.Elements__ErrorIconWrapper}
          data-testid="table-selection-error-tooltip"
        >
          <Icon.Error className={classes.Elements__ErrorIcon} />
        </div>
      </Tooltip>
    )
  }

  if (!tableDef) {
    return null
  }

  if (tableDef.isError) {
    return (
      <Tooltip
        content={t('multiTableConfig.tableSelection.fetchElementsError')}
      >
        <div className={classes.Elements__ErrorIconWrapper}>
          <Icon.Error className={classes.Elements__ErrorIcon} />
        </div>
      </Tooltip>
    )
  }

  if (!isSelected || !tableConfig) {
    return null
  }

  const selectedColumns = tableConfig.dataSelection.filter(
    (column) => column.isSelected
  ).length

  if (tableDef.columns) {
    return (
      <div data-testid={`${tableDef.name}-selected-column-split`}>
        <SelectionFraction
          divisor={selectedColumns}
          dividend={tableDef.columns.length}
        />
      </div>
    )
  }

  const totalColumns = tableConfig.dataSelection.length

  if (totalColumns > 0) {
    return (
      <div data-testid={`${tableDef.name}-selected-column-split`}>
        <SelectionFraction divisor={selectedColumns} dividend={totalColumns} />
      </div>
    )
  }

  return null
}
