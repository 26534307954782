import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  useGetFileSummariesQueryKey,
  useGitQueryClient
} from '@matillion/git-component-library'
import { useQueryClient } from '@tanstack/react-query'

import { useListProjectVariableQueryKey } from 'api/hooks/useListProjectVariables/useListProjectVariables'
import useMigrationMetlJob from 'api/hooks/useMigrationMetlJob'

export interface UseImportMetlResponse {
  isImportLoading: boolean
  migrateMetlJobsToPipelines: (
    file: File,
    path: string,
    mappings: string
  ) => Promise<void>
  importError: unknown
}

const useImportMetlJobs = (): UseImportMetlResponse => {
  const queryClient = useQueryClient()
  const gitQueryClient = useGitQueryClient()
  const fileSummariesQueryKey = useGetFileSummariesQueryKey()
  const variableQueryKey = useListProjectVariableQueryKey()

  const {
    mutateAsync: migrationMetlJob,
    isLoading,
    error
  } = useMigrationMetlJob()

  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation('translation', { keyPrefix: 'conversion.modal' })

  const migrateMetlJobsToPipelines = useCallback(
    async (file: File, path: string, mappings: string) => {
      try {
        await migrationMetlJob({ path, file, mappings })

        makeToast({
          type: 'success',
          title: t('toast.success.title'),
          message: t('toast.success.message')
        })

        await gitQueryClient.invalidateQueries(fileSummariesQueryKey)
        await queryClient.invalidateQueries(variableQueryKey)
      } catch {
        makeToast({
          type: 'error',
          title: t('toast.failure.title'),
          message: t('toast.failure.message')
        })
      }
    },
    [
      migrationMetlJob,
      fileSummariesQueryKey,
      makeToast,
      queryClient,
      gitQueryClient,
      t,
      variableQueryKey
    ]
  )

  return {
    migrateMetlJobsToPipelines,
    isImportLoading: isLoading,
    importError: error
  }
}

export default useImportMetlJobs
