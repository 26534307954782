import { useParams, useSearchParams } from 'react-router-dom'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries'
import { type FlowInstanceId } from 'api/hooks/useGetRunTasks/types'

import { type ComponentInstanceId } from 'job-lib/types/Job'

export interface ProjectInfo {
  projectId: string
  branchId: string
  componentId: ComponentInstanceId | null
  jobSummaryId: JobSummaryId
  flowInstanceId?: FlowInstanceId
  environmentId: string
  agentId: string
}

/**
 * Returns the current state of the project from the URL
 * @returns {ProjectInfo}
 */
export const useProjectInfo = (): ProjectInfo => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const environmentId = searchParams.get('environmentId')
  const agentId = searchParams.get('agentId')

  const componentId =
    Number(params.componentId) >= 0 ? Number(params.componentId) : null

  return {
    projectId: params.projectId ?? '',
    branchId: params.branchId ?? '',
    componentId,
    flowInstanceId: params.taskId,
    jobSummaryId: params.jobSummaryId ?? '',
    environmentId: environmentId ?? '',
    agentId: agentId ?? ''
  }
}
