import { type RootState } from 'job-lib/store'
import { type ComponentInstance } from 'job-lib/types/Job'

export const getComponentSelector =
  (componentInstanceId: number) =>
  (state: RootState): ComponentInstance | null => {
    if (!state.job.job?.components) return null

    return state.job.job.components[componentInstanceId]
  }
