import { type FC, type PropsWithChildren } from 'react'

import { Tree, type TreeProps } from '@matillion/component-library'

import classes from './BrowserTree.module.scss'

interface BrowserTreeProps extends PropsWithChildren<TreeProps> {
  expanded?: boolean
  expandedItems?: Record<string, boolean>
  onToggle?: (nodeId: string, isExpanded: boolean) => void
}

const BrowserTree: FC<BrowserTreeProps> = ({
  children,
  expanded,
  expandedItems = { Orchestration: true, Data: true },
  onToggle
}) => {
  return (
    <div className={classes.BrowserTree} tabIndex={0}>
      <Tree
        expandedItems={expandedItems}
        isAllFoldersExpanded={expanded}
        compact
        onToggle={onToggle}
      >
        {children}
      </Tree>
    </div>
  )
}

export default BrowserTree
