import { type Job } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import type { GridValueFromVariable, ParameterValue } from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import { convertElementCollectionToValue } from 'modules/ComponentParameters/utils/convertElementCollectionToValue'
import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'

import { parseLookupDependencyKey } from './parseLookupDependencyKey'

export interface GetParameterDependencyOptions {
  lookupDependencies: string[]
  componentMetaData: ComponentMetadata
  componentId: ComponentInstanceId | null
  job: Job
}

export interface GetParameterValueParams {
  parameterPath: string[]
  parameterId: string
  parameterPathIndex?: number
  componentParameters: ComponentParameter[]
  componentParameterCollection: ParameterCollection
  alias: string
  job: Job
}

const isGridVariableValue = (
  value: ParameterValue
): value is GridValueFromVariable => {
  return (
    value !== null &&
    value !== undefined &&
    typeof value === 'object' &&
    Object.hasOwn(value, 'fromGrid')
  )
}

const resolveDefaultValuesFromGridValue = (
  job: Job,
  value: GridValueFromVariable
): string[][] => {
  /* istanbul ignore next */
  const jobGridVariable = (job?.grids ?? {})?.[value.fromGrid.variable]
  if (!jobGridVariable) {
    return []
  }
  return jobGridVariable.values.map(({ values }) => {
    return value.fromGrid.columns.map((column) => {
      const index = jobGridVariable.definition.definitions.findIndex(
        (col) => col.name === column
      )
      return values[index] ?? ''
    })
  })
}

const getMETLParameterValue = (
  parameterCollection: ParameterCollection,
  parameterDataType: ParameterDataType,
  metlSlot: number
) => {
  switch (parameterDataType) {
    case ParameterDataType.LIST:
    case ParameterDataType.GRID: {
      return convertElementCollectionToValue(
        parameterCollection[metlSlot]?.elements ?? {},
        parameterDataType
      )
    }
    default: {
      return parameterCollection[metlSlot]?.elements[1]?.values[1]?.value
    }
  }
}

export const getParameterValue = ({
  parameterPath,
  parameterId,
  parameterPathIndex = 0,
  componentParameters,
  componentParameterCollection,
  alias,
  job
}: GetParameterValueParams): ParameterValue => {
  const parameter = componentParameters.find(
    (param) => param.dplID === parameterId
  )

  if (!parameter) {
    return null
  }

  const isLastItemInPath = parameterPathIndex === parameterPath.length - 1

  // we only want to look in child properties if the parameter has them
  // and we're not at the end of the parameter path
  if (parameter.childProperties && !isLastItemInPath) {
    return getParameterValue({
      parameterPath,
      parameterId: parameterPath[parameterPathIndex + 1],
      parameterPathIndex: parameterPathIndex + 1,
      componentParameters: parameter.childProperties,
      componentParameterCollection,
      alias,
      job
    })
  }

  const isDPLParameters = isDPLParameterCollection(componentParameterCollection)

  let parameterValue = isDPLParameters
    ? getParameterDPLValue(
        parameterPath,
        getDPLParameters(null, componentParameterCollection)
      )
    : getMETLParameterValue(
        componentParameterCollection,
        parameter.dataType,
        parameter.metlSlot
      )
  if (isGridVariableValue(parameterValue)) {
    parameterValue = resolveDefaultValuesFromGridValue(job, parameterValue)
  }

  // specially treat fields aliased as inputConnector, we need to wrap in "parameters" in this case
  if (alias === 'param.inputConnector') {
    return {
      parameters: parameterValue
    }
  }

  return parameterValue
}

export const getParameterDependencies = ({
  lookupDependencies,
  componentMetaData,
  componentId,
  job
}: GetParameterDependencyOptions) => {
  return lookupDependencies?.reduce((definition, dependency) => {
    const parsedDependencyKey = parseLookupDependencyKey('param', dependency)
    if (!parsedDependencyKey) {
      return definition
    }
    const { alias, parameterPath } = parsedDependencyKey
    const componentParameterCollection =
      job?.components[componentId as number]?.parameters ?? {}

    const parameterValue = getParameterValue({
      parameterPath,
      componentParameterCollection,
      componentParameters: componentMetaData.parameters,
      parameterId: String(parameterPath.at(0)),
      alias,
      job
    })

    return {
      ...definition,
      [alias]: parameterValue
    }
  }, {})
}
