import { type ComponentLayout } from '../../types'
import { getConditionalAdvancedParameters } from '../getConditionalAdvancedParameters'
import { createModularConnectorDestinationStep } from '../steps/ModularConnectorDestinationStep'

interface ModularConnectorConfigOptions {
  componentId?: string
  showAdvanced?: boolean
  showConnect?: boolean
  overrideDefaultDestinationParametersList?: string[]
}

export function createModularConnectorConfig(
  dplId: string,
  {
    componentId = `modular-${dplId}`,
    showAdvanced = true,
    showConnect = true,
    overrideDefaultDestinationParametersList
  }: ModularConnectorConfigOptions = {}
): ComponentLayout {
  return {
    componentId,
    wizard: {
      steps: [
        ...(showConnect
          ? [
              {
                stepId: 'connect',
                displayName: 'Connect',
                previousStepId: null,
                nextStepId: 'configure',
                parameters: [
                  {
                    dplId: 'connection'
                  },
                  {
                    dplId: 'connectionOptions'
                  }
                ]
              }
            ]
          : []),
        {
          stepId: 'configure',
          displayName: 'Configure',
          previousStepId: 'connect',
          nextStepId: 'destination',
          parameters: [
            {
              dplId,
              omitParameters: [
                'connection',
                'connectionOptions',
                'autoDebug',
                'debugLevel'
              ]
            }
          ]
        },
        createModularConnectorDestinationStep(
          'configure',
          'advanced',
          overrideDefaultDestinationParametersList
        ),
        ...(showAdvanced
          ? [
              {
                stepId: 'advanced',
                displayName: 'Advanced Settings',
                previousStepId: null,
                nextStepId: null,
                parameters: [
                  {
                    dplId: 'autoDebug'
                  },
                  {
                    dplId: 'debugLevel'
                  }
                ],
                conditionalParameters: getConditionalAdvancedParameters
              }
            ]
          : [])
      ]
    }
  }
}
