import { useCallback, useEffect, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { heap, useLDClient } from '@matillion/hub-client'

import { EtlComponent } from 'components/EtlComponent/EtlComponent'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import {
  dataTransferObjectComponentId,
  excelQueryComponentId,
  orchestrationJobNameParameter,
  runOrchestrationComponentId,
  runTransformationComponentId,
  s3LoadComponentId,
  transformationJobNameParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'

import { useSupersededDetails } from 'modules/ComponentParameters/hooks'
import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { useComponentValidationResult } from 'modules/core/ComponentValidation'

export interface ComponentNodeProps {
  id: string
  isAttached?: boolean
  imageUrl?: string
  isSelected?: boolean
  isIterator?: boolean
  summaryId: string
  isSkipped?: boolean
}

export enum ValidationStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  VALIDATING = 'validating',
  UNVALIDATED = 'unvalidated'
}

const getValidationStatus = ({
  isError,
  isSuccess,
  isLoading
}: {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}) => {
  if (isError) return ValidationStatus.INVALID
  if (isSuccess) return ValidationStatus.VALID
  if (isLoading) return ValidationStatus.VALIDATING

  return ValidationStatus.UNVALIDATED
}

export const ComponentNode: FC<PropsWithChildren<ComponentNodeProps>> = ({
  id,
  imageUrl,
  isSelected,
  isAttached,
  isIterator,
  summaryId,
  children,
  isSkipped
}) => {
  const { navigateToComponent, navigateToJob } = useSelectedJobs()
  const { files: pipelines } = usePipelines()
  const { isError, isSuccess, isLoading } = useComponentValidationResult(
    Number(id)
  )
  const validationStatus = getValidationStatus({
    isError,
    isSuccess,
    isLoading
  })
  const componentInfo = useComponentInfo()
  const { track: trackLDMetric } = useLDClient()

  useEffect(() => {
    if (
      validationStatus === ValidationStatus.VALID ||
      validationStatus === ValidationStatus.INVALID
    ) {
      heap.track('Component Validated', {
        componentId: summaryId,
        componentName: componentInfo.getDisplayName(summaryId),
        validationStatus
      })
    }

    if (
      (summaryId === excelQueryComponentId ||
        summaryId === dataTransferObjectComponentId ||
        summaryId === s3LoadComponentId) &&
      validationStatus === ValidationStatus.VALID
    ) {
      trackLDMetric(
        'designer-component-with-s3-storage-url-parameter-validated'
      )
    }
  }, [validationStatus, summaryId, componentInfo, trackLDMetric])

  const { componentInstance, metadata } = useComponentInstanceMetadataQuery(
    Number(id)
  )
  const { isSuperseded } = useSupersededDetails({ componentMetadata: metadata })
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const isRunnableComponent =
    summaryId === runOrchestrationComponentId ||
    summaryId === runTransformationComponentId

  const onDoubleClick = useCallback(() => {
    if (!isRunnableComponent || !componentInstance) {
      return
    }

    const params = componentInstance.parameters

    const isDPLInstance = isDPLParameterCollection(params)
    const paramLabels = {
      [runOrchestrationComponentId]: orchestrationJobNameParameter,
      [runTransformationComponentId]: transformationJobNameParameter
    }
    const paramLabel = paramLabels[summaryId]
    const jobRuntimeName = isDPLInstance
      ? (getParameterValue(params, [paramLabel]) as string)
      : params[2].elements[1]?.values[1].value

    const jobName = Object.values(pipelines).find(
      (pipeline) => pipeline.runtimeName === jobRuntimeName
    )?.name

    if (jobName) {
      navigateToJob(jobName)
    } else {
      makeToast({
        type: 'error',
        title: t('canvas.pipelineNavigationErrorTitle', {
          pipelineName: jobRuntimeName
        }),
        message: t('canvas.pipelineNavigationErrorMessage')
      })
    }
  }, [
    componentInstance,
    isRunnableComponent,
    makeToast,
    navigateToJob,
    pipelines,
    summaryId,
    t
  ])

  return (
    <EtlComponent
      data-testid={`component-${id}`}
      imageUrl={imageUrl}
      isSelected={isSelected}
      isSkipped={isSkipped}
      isIterator={isIterator}
      isAttached={isAttached}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isSuperseded={isSuperseded}
      hasDoubleClickNavigation={isRunnableComponent}
      showLabel={!isIterator}
      data-status={validationStatus}
      onClick={(e) => {
        if (!e.shiftKey) {
          navigateToComponent(id)
        }
      }}
      onDoubleClick={onDoubleClick}
      tooltipContent={componentInfo.getDisplayName(summaryId)}
    >
      {children}
    </EtlComponent>
  )
}
