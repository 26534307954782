import { type Pipeline } from 'types/Pipeline'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { type TransformationJob } from 'job-lib/types/Job'

/**
 * Sets and overwrites the position of the METL components based on the DPL pipeline positions.
 *
 * @param job The METL job to set the position on.
 * @param pipeline The pipeline to set the position from.
 */
export const setMETLPositionFromPipeline = (
  job: TransformationJob,
  pipeline: Pipeline
) => {
  const nameToIdMap = new Map<string, string>()
  for (const componentId in job.components) {
    const component = job.components[componentId]
    const name = getComponentName(component)
    nameToIdMap.set(name, componentId)
  }

  for (const componentName in pipeline.design.components) {
    const metlId = nameToIdMap.get(componentName)
    if (metlId) {
      const dplDesign = pipeline.design.components[componentName]
      const metlComponent = job.components[+metlId]
      metlComponent.x = dplDesign.position.x
      metlComponent.y = dplDesign.position.y
    }
  }
}
