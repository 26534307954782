export const parseLookupDependencyKey = (
  prefix: string,
  lookupDependencyKey: string
) => {
  // this regex captures a parameter path followed by an optional alias
  const regex = new RegExp(`${prefix}\\.([.#/-\\w]+)(?::([.-\\w]+))?`)
  // the match contains the parameter path and an optional alias
  // e.g. param.schema:param.alias => ["schema", "param.alias"]
  // e.g. param.group1.group2.param1 => ["group1.group2.param1", undefined"]
  const match = regex.exec(lookupDependencyKey)
  if (!match) {
    return null
  }
  const [lookupPrefix, lookupPath, lookupAlias] = match
  // a dependency is aliased when it includes the ":" character
  // e.g. param.abc:param.xyz means that the value of "abc" should be sent in the payload as "param.xyz"
  // the dependency alias is always the last item in the regex match
  const hasAlias = lookupAlias !== undefined
  // if it's an aliased dependency then take the last item in the regex match
  // otherwise take the input to the regex at index 0
  return {
    alias: hasAlias ? String(lookupAlias) : String(lookupPrefix),
    parameterPath: lookupPath.split('.')
  }
}
