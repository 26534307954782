import { useEffect, type FC, type ReactNode } from 'react'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

interface PipelineAccessHistoryProviderProps {
  children: ReactNode
}

export const PipelineAccessHistoryProvider: FC<
  PipelineAccessHistoryProviderProps
> = ({ children }) => {
  const {
    storeRecentlyAccessedPipeline,
    deleteLegacyCookie,
    deleteOldestCookies
  } = useRecentlyAccessedPipelines()
  const { pipelineSummary: activePipeline } = useActivePipelineSummary()

  useEffect(() => {
    deleteLegacyCookie()
    deleteOldestCookies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    storeRecentlyAccessedPipeline()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePipeline])

  return <>{children}</>
}
