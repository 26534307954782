import { type RefObject } from 'react'

/**
 * Scrolls the provided ref object's current element to the bottom.
 * @param ref - A ref object pointing to an HTMLDivElement.
 */
export function scrollToBottom(ref: RefObject<HTMLDivElement>): void {
  if (ref.current) {
    ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight
  }
}
