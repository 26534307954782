import { useFlags, type EtlFlags } from 'hooks/useFlags'

import { type ProjectType } from '../../api/hooks/useGetProject/types'
import excludedFromPreviewTags from '../../config/excluded-from-preview-tags.json'
import { getConfig } from './getConfig'

export type WarehouseFlagCollection = Map<string, ProjectType[]>

interface PreviewTagExclusions {
  [componentId: string]: ProjectType[]
}

const previewTagExclusions: PreviewTagExclusions =
  excludedFromPreviewTags as PreviewTagExclusions

const getWarehouseComponentFlags = (
  allFlags: EtlFlags
): WarehouseFlagCollection => {
  const flagPrefix = 'warehouses-'
  const warehouseFlags = new Map<string, ProjectType[]>(
    Object.entries(allFlags)
      .filter(([k]) => k.startsWith(flagPrefix))
      .map(([k, v]) => [k.replace(flagPrefix, ''), v as ProjectType[]])
  )

  return warehouseFlags
}

/**
 * Returns a map of component ID -> list of enabled warehouse types.
 */
export const useComponentEnabledWarehouses = (): Map<string, ProjectType[]> => {
  const hubFlags = useFlags()
  const warehouseFlags = getWarehouseComponentFlags(hubFlags)

  const result = new Map(getConfig().componentsEnabledWarehouses)
  warehouseFlags.forEach((warehouses, component) => {
    const enabledWarehouses = result.get(component) ?? ([] as ProjectType[])
    const combinedWarehouses = enabledWarehouses.concat(warehouses)
    result.set(component, combinedWarehouses)
  })

  return result
}

/**
 * Given a map of component ID -> list of enabled warehouses, return a list of components that are enabled for the
 * supplied warehouse.
 * @param componentEnabledWarehouses A map of component ID -> list of supported warehouses
 * @param warehouse The warehouse to return the enabled components for
 */
export const getEnabledComponents = (
  componentEnabledWarehouses: Map<string, ProjectType[]>,
  warehouse: ProjectType
) => {
  return Array.from(componentEnabledWarehouses.keys()).filter((component) =>
    componentEnabledWarehouses.get(component)?.includes(warehouse)
  )
}

/**
 * Determines if the provided component ID is a preview component. A
 * A component is under preview when it exists as a feature flag under the provided warehouse
 * and the component is not excluded from preview in the excluded-from-preview-tags config file.
 * @param hubFlags
 * @param componentId
 * @param warehouse
 */
export const isPreviewComponent = (
  hubFlags: EtlFlags,
  componentId: string,
  warehouse: ProjectType
): boolean => {
  if (previewTagExclusions[componentId]?.includes(warehouse)) {
    return false
  }

  const warehouseFlags = getWarehouseComponentFlags(hubFlags)

  const flag = warehouseFlags.get(componentId)
  if (!flag) {
    return false
  }

  return flag.includes(warehouse)
}
