import {
  EditorType,
  ParameterDataType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import {
  type ColumnType,
  type EditorColumn
} from 'api/hooks/useGetParameterOptions/types'

import {
  type ElementCollection,
  type ElementValueType
} from 'job-lib/types/Parameters'

import { ModalTriggerEditor } from 'modules/ParameterEditors/components/ModalTriggerEditor/ModalTriggerEditor'

import { type GridCell } from '../../types'
import classes from './GridExpressionEditor.module.scss'

export interface GridExpressionProps {
  elements: ElementCollection
  parameter: ComponentParameter
  type: ElementValueType
  value: string | null
  slot: number
  rowSlot: number
  dataType: ColumnType
  parameterName: string
  editorColumn: EditorColumn
  onChange: (cell: GridCell) => void
}

export const GridExpressionEditor = ({
  elements,
  parameter,
  value: _value,
  slot,
  rowSlot,
  dataType,
  type,
  parameterName,
  editorColumn,
  onChange
}: GridExpressionProps) => {
  const value = _value ?? ''

  return (
    <div className={classes.GridExpressionEditor}>
      <ModalTriggerEditor
        value={[value]}
        editorType={EditorType.JOIN_EDITOR}
        elements={elements}
        updateElementsWithEditorColumnData={(editorColumns) => {
          return {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  value,
                  dataType: ParameterDataType.TEXT
                },
                2: {
                  slot: 2,
                  type: 'STRING',
                  value: editorColumns?.[1]?.options?.[0] ?? '',
                  dataType: ParameterDataType.TEXT
                }
              }
            }
          }
        }}
        onEdit={(args) => {
          onChange({
            type,
            slot,
            rowSlot,
            dataType,
            value: args[1].values[1].value
          })
        }}
        inputId={`nested-row-${rowSlot}-slot-${slot}`}
        parameter={{
          ...parameter,
          staticOptions: null,
          options: null,
          lookupDependencies: editorColumn.lookupDependencies,
          lookupType: editorColumn.lookupType
        }}
        parameterName={parameterName}
      />
    </div>
  )
}
