import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { StorageEditorType } from 'modules/ParameterEditors/types'

import classes from './ErrorMessage.module.scss'

export const MAX_CHARACTER_COUNT = 160
// This will grab a substring in length from 1 character to the max character count.
// If the string length is within the max character range it will consume use the full string.
// If the string length is less than the max character range it uses a positive look ahead
// to continue up until it reaches a space or full stop without consuming them.
export const errorSubstringRegex = new RegExp(
  `.{1,${MAX_CHARACTER_COUNT}}(?=[.\\s]|$)`
)
interface ErrorMessageProps {
  storageEditorType?: StorageEditorType
  text?: string
}

export const ErrorMessage = ({
  storageEditorType = StorageEditorType.S3,
  text
}: ErrorMessageProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: `parameterEditor.${storageEditorType as string}_URL.error`
  })
  const [isExpanded, setIsExpanded] = useState(false)

  if (!text) return null

  const handleClick = () => {
    setIsExpanded(!isExpanded)
  }

  const substring = errorSubstringRegex.exec(text)
  const displayExpandControls =
    substring && substring[0].length < text.trim().length

  return (
    <p
      className={classes.Error}
      data-testid={`${(
        storageEditorType as string
      ).toLowerCase()}-error-message`}
    >
      <span id="error-message">{isExpanded ? text : substring?.[0]}</span>
      {displayExpandControls && (
        <>
          {!isExpanded && <span className={classes.Error__Ellipsis}>...</span>}
          <button
            type="button"
            className={classes.Error__Button}
            aria-controls="error-message"
            aria-expanded={isExpanded}
            onClick={handleClick}
          >
            <Typography as="span" format="bcs">
              {isExpanded ? t('showLess') : t('showMore')}
            </Typography>
          </button>
        </>
      )}
    </p>
  )
}
