import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useViewport } from 'reactflow'

import { Icon, Typography } from '@matillion/component-library'
import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'

import { ReactComponent as MinusSvg } from 'assets/minus-small.svg'

import { HorizontalStack } from 'components/HorizontalStack/HorizontalStack'
import { IconButton } from 'components/IconButton/IconButton'
import { useUserPreference } from 'components/UserPreferenceProvider/useUserPreference'

import config from 'config'

import classes from './ZoomToolbar.module.scss'

export const ZoomToolbar = () => {
  const { zoomTo, fitView } = useEtlFlow()
  const { zoom } = useViewport()
  const { t } = useTranslation()

  const { userPreference, setSpecificUserPreference } = useUserPreference()

  const onZoomOut = useCallback(() => {
    zoomTo(zoom - 0.25, { duration: 400 })
  }, [zoomTo, zoom])

  const onZoomIn = useCallback(() => {
    zoomTo(zoom + 0.25, { duration: 400 })
  }, [zoomTo, zoom])

  return (
    <div className={classes.ZoomToolbar}>
      <HorizontalStack withSpacer>
        <HorizontalStack>
          <IconButton
            className={classes.ZoomToolbar__Button}
            label={t('canvasZoomControls.zoomOut')}
            onClick={onZoomOut}
            data-testid="canvas-zoom-controls-zoom-out"
          >
            <MinusSvg />
          </IconButton>

          <div
            aria-live="polite"
            data-testid="canvas-zoom-controls-percentage"
            className={classes.ZoomToolbar__Percentage}
          >
            <Typography>{(zoom * 100).toFixed(0)}%</Typography>
          </div>

          <IconButton
            className={classes.ZoomToolbar__Button}
            label={t('canvasZoomControls.zoomIn')}
            onClick={onZoomIn}
            data-testid="canvas-zoom-controls-zoom-in"
          >
            <Icon.Add />
          </IconButton>
          <IconButton
            className={classes.ZoomToolbar__Button}
            label={t('canvasZoomControls.fitAll')}
            onClick={() =>
              fitView({
                maxZoom: 1,
                duration: 400
              })
            }
            data-testid="canvas-zoom-controls-fit-all"
          >
            <Icon.FitAll />
          </IconButton>
          {userPreference.snapToGridEnabled ? (
            <IconButton
              className={classes.ZoomToolbar__Button}
              data-testid="canvas-zoom-controls-snap2grid-enabled"
              onClick={() => {
                setSpecificUserPreference('snapToGridEnabled', false)
              }}
              label={t('canvasZoomControls.gridOff')}
            >
              <Icon.Grid />
            </IconButton>
          ) : (
            <IconButton
              className={classes.ZoomToolbar__Button}
              data-testid="canvas-zoom-controls-snap2grid-disabled"
              onClick={() => {
                setSpecificUserPreference('snapToGridEnabled', true)
              }}
              label={t('canvasZoomControls.gridOn')}
            >
              <Icon.GridDisabled />
            </IconButton>
          )}
        </HorizontalStack>

        <a
          href={config.docs.designerOverviewUrl}
          rel="noopener noreferrer"
          target="_blank"
          data-testid="canvas-zoom-controls-help"
        >
          <IconButton
            label={t('common.help')}
            className={classes.ZoomToolbar__Button}
          >
            <Icon.InfoNoBg />
          </IconButton>
        </a>
      </HorizontalStack>
    </div>
  )
}
