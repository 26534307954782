export type UnixTimestampMS = number

export type FlowInstanceId = string

export interface RunAJobResponse {
  messages: Message[]
  flowInstanceId: FlowInstanceId
}

export enum HistoryTaskType {
  TASK_RUN = 'TASK_RUN',
  TASK_VALIDATE = 'TASK_VALIDATE'
}

export type TaskStatusId = number | string

export interface GenericTaskStatus {
  id: TaskStatusId
  jobName: string
  environmentName: string
  enqueuedTime: UnixTimestampMS
  startTime: UnixTimestampMS | null
  endTime: UnixTimestampMS | null
  state: State
}

export interface RunTasksResponse {
  results: RunTaskStatus[]
}

export type ValidationTaskStatus = GenericTaskStatus

export interface RunTaskStatus extends GenericTaskStatus {
  versionName: string
  flowInstanceId: FlowInstanceId
}

export type HistoryTaskStatus = RunTaskStatus | ValidationTaskStatus

export enum State {
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Running = 'RUNNING',
  Queued = 'QUEUED',
  Cancelled = 'CANCELLED',
  Skipped = 'SKIPPED',
  Cancelling = 'CANCELLING'
}

export interface Message {
  /* the name of the field */
  fieldName: string
  description: string
  severity: 'ERROR'
}
