import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { useStackingModal } from 'components/StackingModalProvider'

import FileUploadModal from './components/FileUploadModal'
import classes from './FileUploadPaintedDoor.module.scss'

const FileUploadPaintedDoor = () => {
  const { pushNewModalContent } = useStackingModal()
  const { t } = useTranslation()

  return (
    <div className={classes.FileUploadPaintedDoor__Anchor}>
      <Typography as="span" weight="bold">
        {t('fileUpload.title')}
      </Typography>
      <Button
        data-testid="file-upload-painted-door-modal-trigger"
        data-tracker-id="file-upload-painted-door-modal-trigger"
        size="sm"
        fontWeight="normal"
        iconAlign="right"
        onClick={() => {
          pushNewModalContent(
            {
              'data-testid': 'file-upload-painted-door-modal',
              ariaLabelledBy: 'file upload painted door',
              className: classes.FileUploadPaintedDoor__Modal
            },
            <FileUploadModal />
          )
        }}
        text={t('fileUpload.paintedDoorTrigger')}
        alt="secondary"
      />
    </div>
  )
}
export default FileUploadPaintedDoor
