import { type Node } from 'reactflow'

import { type EtlFlowInstance } from 'file-editors/canvas/hooks/useEtlFlow'

import { type ComponentSummary } from 'api/hooks/useGetComponentSummaries'

import { ConnectionPortType, type Port } from 'job-lib/types/Components'
import {
  type OrchestrationComponentInstance,
  type TransformationComponentInstance
} from 'job-lib/types/Job'

import {
  EtlCanvasNodeType,
  type ComponentNode,
  type EtlCanvasNodeId,
  type NodeInfo,
  type NodeType
} from './useCanvasModel'

export const isNodeSupported = (
  component: TransformationComponentInstance | OrchestrationComponentInstance
) => {
  const isOrchestrationNode = Object.hasOwn(
    component,
    'outputSuccessConnectorIDs'
  )
  const isTransformationNode = Object.hasOwn(component, 'outputConnectorIDs')

  return isOrchestrationNode || isTransformationNode
}

const JOB_CONNECTORS_MAP: Record<string, string> = {
  successConnectors: ConnectionPortType.SUCCESS,
  failureConnectors: ConnectionPortType.FAILURE,
  unconditionalConnectors: ConnectionPortType.UNCONDITIONAL,
  trueConnectors: ConnectionPortType.TRUE,
  falseConnectors: ConnectionPortType.FALSE,
  iterationConnectors: ConnectionPortType.ITERATION,
  connectors: ConnectionPortType.UNCONDITIONAL
}

export const parseConnectorName = (jobConnectorName: string) =>
  JOB_CONNECTORS_MAP[jobConnectorName]

export const getComponentPorts = (componentSummary: ComponentSummary) => {
  const inputPorts = componentSummary.inputPorts
  const outputPorts: Port[] = []
  const iteratorPorts: Port[] = []

  componentSummary.outputPorts.forEach((port) => {
    if (port.portId === ConnectionPortType.ITERATION) {
      iteratorPorts.push(port)
    } else {
      outputPorts.push(port)
    }
  })

  return { inputPorts, outputPorts, iteratorPorts }
}

export const isComponentNode = (node?: Node): node is ComponentNode =>
  node?.type === EtlCanvasNodeType.ITERATOR ||
  node?.type === EtlCanvasNodeType.NODE

const getIdType = (id: string): NodeType =>
  id?.startsWith('component') ? 'component' : 'note'

const getIdFromType = (id: string) => (type: NodeType) =>
  id?.slice(type.length + 1)

export const getComponentReactFlowId = (id: number): EtlCanvasNodeId =>
  `component-${id}`

export const getNoteReactFlowId = (id: number): EtlCanvasNodeId => `note-${id}`

export const getIdFromReactFlowId = (id: string) => {
  const idType = getIdType(id)
  return getIdFromType(id)(idType) ?? ''
}

export const getNodeInfo = (node: Node): NodeInfo => {
  const nodeId = node.id as EtlCanvasNodeId
  const type = getIdType(node.id)

  return {
    type,
    id: Number(getIdFromType(nodeId)(type)),
    data: node.data
  }
}

export const getSelectedNodes = (reactFlowInstance: EtlFlowInstance) =>
  reactFlowInstance.getNodes().filter((node) => node.selected)

export const getSelectedNodeInfo = (
  reactFlowInstance: EtlFlowInstance
): NodeInfo[] => {
  return getSelectedNodes(reactFlowInstance).map(getNodeInfo)
}

export const getSelectedNodeIds = (reactFlowInstance: EtlFlowInstance) =>
  getSelectedNodes(reactFlowInstance).map((node) => node.id)
