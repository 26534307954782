import { getDefaultQueryKeys } from './getDefaultQueryKeys'

/**
 * Gets the query key for a job validation
 * @param jobSummaryId The job summary Id
 * @param dependentComponentNames A list of dependent component names. If a component has a single input, this array
 *  will always contain a single array containing the dependent component name. If the component
 *  accepts multiple inputs (e.g. Unite), this will contain an array for each set of dependent components.
 */
export const getQueryKey = (
  jobSummaryId: string,
  dependentComponentNames: string[][]
) => [...getDefaultQueryKeys(jobSummaryId), dependentComponentNames]
