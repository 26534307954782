import type { FC } from 'react'

import GuidelineSvg from './GuidelineSvg'
import HorizontalLine from './HorizontalLine'
import VerticalLine from './VerticalLine'

/* The increase in margin each time we go deeper in the tree.
 The number has been chosen based on what looks ok, any changes will need to be manually tested.
 We don't currently have formal designs for these guidelines. */
export const DEPTH_MARGIN = 18

export interface GuidelinesProps {
  depth: number
  isExpanded: boolean
  isLast: boolean
  isRoot: boolean
  treeLines: boolean[]
}

const Guidelines: FC<GuidelinesProps> = ({
  depth,
  isExpanded,
  isLast,
  isRoot,
  treeLines
}) => {
  // If the step is expanded, show half a vertical line
  const getContextForExpanded = isExpanded && (
    <GuidelineSvg left={depth * DEPTH_MARGIN}>
      <VerticalLine y1="50%" />
    </GuidelineSvg>
  )

  /* If the step is the last step in a pipeline, show a vertical line halfway and a horizontal line */
  const getContentForLastStep = isLast && !isRoot && (
    <GuidelineSvg left={(depth - 1) * DEPTH_MARGIN}>
      <VerticalLine y2="50%" />
      <HorizontalLine />
    </GuidelineSvg>
  )
  /* If the step is not the last step in a pipeline, show a full vertical line and a horizontal line */
  const getContentForStep = !isRoot && !isLast && (
    <GuidelineSvg left={(depth - 1) * DEPTH_MARGIN}>
      <VerticalLine />
      <HorizontalLine />
    </GuidelineSvg>
  )

  /* Check the treeLines array to see which parent lines should be shown */
  const getTreeLinesContent = treeLines.map((isLine, index) => {
    if (isLine && index !== 0) {
      return (
        <GuidelineSvg key={index} left={(index - 1) * DEPTH_MARGIN}>
          <VerticalLine />
        </GuidelineSvg>
      )
    }
    return null
  })

  return (
    <>
      {getContextForExpanded}
      {getContentForLastStep}
      {getContentForStep}
      {getTreeLinesContent}
    </>
  )
}

export default Guidelines
