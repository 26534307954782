import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tab, Tabs, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import { useIsPipelineOpen } from 'hooks/useIsPipelineOpen'

import { JobVariableType, VariableScope } from 'job-lib/types/Variables'

import { useJobVariables } from 'modules/core/EtlDesigner/hooks/useJobVariables'

import {
  Stages,
  type ContentProps,
  type SelectedJobVariable,
  type SelectedProjectVariable
} from '../../types'
import { CreateModal } from '../CreateModal/CreateModal'
import { DeleteModal } from '../DeleteModal/DeleteModal'
import { JobVariables } from './JobVariables'
import classes from './Manage.module.scss'
import { ProjectVariables } from './ProjectVariables'

enum ManageModals {
  CREATE = 'CREATE',
  DELETE = 'DELETE'
}

type ManageModalsDisplay = ManageModals | null
type VariableToAction = SelectedProjectVariable | SelectedJobVariable | null

export const Manage = ({
  variableScope,
  setStage
}: ContentProps<{ variableScope: VariableScope }>) => {
  const [variableToAction, setVariableToAction] =
    useState<VariableToAction>(null)
  const [modalToDisplay, setModalToDisplay] =
    useState<ManageModalsDisplay>(null)

  const isPipelineOpen = useIsPipelineOpen()

  const {
    data: projectVariables,
    isFetching: isLoading,
    isError
  } = useListProjectVariables({ staleTime: 0 })
  const { t } = useTranslation('translation', { keyPrefix: 'manageVariables' })

  const handleTabChange = ({ tab }: Record<'tab', unknown>) => {
    const scope: VariableScope = (
      tab as Record<'data-tab-scope', VariableScope>
    )['data-tab-scope']

    /* istanbul ignore else */
    if (scope) {
      setStage({ stage: Stages.MANAGE, variableScope: scope })
    }
  }

  const closeModal = () => {
    setModalToDisplay(null)
  }
  const jobVariables = useJobVariables()
  const numJobVariables = jobVariables.length
  const numProjectVariables = projectVariables?.length ?? 0

  return (
    <>
      <div
        className={classNames(
          classes.ManageVariables__ButtonWrapper,
          classes.Wizard
        )}
      >
        <Typography
          id="manage-variables-title"
          format="bcm"
          as="span"
          weight="bold"
        >
          {t('common.title')}
        </Typography>
        <Button
          className={classes.ManageVariables__CreateButton}
          onClick={() => {
            setModalToDisplay(ManageModals.CREATE)
          }}
          text={t('manage.addVariable')}
          data-testid="create-variable"
          alt={'secondary'}
          size="sm"
        />
      </div>
      <Tabs
        aria-labelledby="manage-variables-title"
        className={classes.ManageVariables__Tabs}
        listClassName={classes.ManageVariables__TabList}
        contentClassName={classes.ManageVariables__Content}
        activeIndex={variableScope === VariableScope.JOB_VARIABLE ? 0 : 1}
        onChange={handleTabChange}
      >
        <Tab
          data-tab-scope={VariableScope.JOB_VARIABLE}
          data-testid="tab-job-variables"
          title={t('manage.jobVariablesWithCount', {
            numJobVariables
          })}
          disabled={!isPipelineOpen}
        >
          <JobVariables
            jobVariables={jobVariables}
            onDelete={(variable) => {
              setVariableToAction(variable)
              setModalToDisplay(ManageModals.DELETE)
            }}
            onEdit={(variable) => {
              setStage({
                stage:
                  variable.type === JobVariableType.GRID
                    ? Stages.EDIT_GRID
                    : Stages.EDIT_JOB_VARIABLE,
                variable,
                variableScope: VariableScope.JOB_VARIABLE,
                variableType: variable.type
              })
            }}
          />
        </Tab>
        <Tab
          data-tab-scope={VariableScope.PROJECT_VARIABLE}
          data-testid="tab-project-variables"
          title={t('manage.projectVariablesWithCount', {
            numProjectVariables
          })}
        >
          <ProjectVariables
            isLoading={isLoading}
            isError={isError}
            variables={projectVariables}
            onDelete={(variable) => {
              setVariableToAction(variable)
              setModalToDisplay(ManageModals.DELETE)
            }}
            onEdit={(variable) => {
              setStage({
                stage: Stages.EDIT_PROJECT_VARIABLE,
                variable,
                variableScope: VariableScope.PROJECT_VARIABLE,
                variableType: variable.type
              })
            }}
          />
        </Tab>
      </Tabs>

      {modalToDisplay === ManageModals.CREATE && (
        <CreateModal
          setStage={setStage}
          variableScope={variableScope}
          onClose={closeModal}
        />
      )}

      {modalToDisplay === ManageModals.DELETE && variableToAction !== null && (
        <DeleteModal variableToDelete={variableToAction} onClose={closeModal} />
      )}
    </>
  )
}
