import { useTranslation } from 'react-i18next'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import { getSelectedComponentName } from 'file-editors/canvas/util/componentSelection'

import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import { ReactComponent as RunFromIcon } from 'assets/run from.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useTimer } from 'hooks/useTimer/useTimer'

import classes from '../ComponentNodeToolbar.module.scss'

interface RunFromButtonProps {
  jobId: string
  summaryId: string
  canRunFrom: boolean
}

const RunFromButton: React.FC<RunFromButtonProps> = ({
  jobId,
  summaryId,
  canRunFrom
}) => {
  const { t } = useTranslation()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const reactFlow = useEtlFlow()

  const { mutate: runFromComponent } = useRunJob({
    jobId,
    warehouse,
    componentName: getSelectedComponentName(summaryId, reactFlow)
  })

  const { hasElapsed, runTimer } = useTimer(1000)

  return (
    <IconButton
      disabled={!canRunFrom || !hasElapsed}
      onClick={() => {
        runFromComponent()
        runTimer()
      }}
      className={classes.Toolbar__Button}
      label={t('componentToolbox.runFromComponent')}
    >
      <RunFromIcon />
    </IconButton>
  )
}

export default RunFromButton
