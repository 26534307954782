import {
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type KeyboardEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Input,
  LoadingSpinner,
  Typography
} from '@matillion/component-library'

import { type FeedbackSentiment } from 'api/hooks/copilot/types'

import { ReactComponent as Cross } from 'assets/cross.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { focusInput } from '../../util/focusUtil'
import classes from './CopilotFeedbackForm.module.scss'

interface CopilotFeedbackFormProps {
  isLoading: boolean
  onSubmit: (feedback: string) => void
  feedbackType: FeedbackSentiment
  onClose: () => void
}

const CopilotFeedbackForm = ({
  isLoading,
  onSubmit,
  feedbackType,
  onClose
}: CopilotFeedbackFormProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [feedback, setFeedback] = useState('')
  const quickPositiveOptions = [
    t('copilot.chat.feedback.correct'),
    t('copilot.chat.feedback.easyToUnderstand')
  ]
  const quickNegativeOptions = [
    t('copilot.chat.feedback.dontLike'),
    t('copilot.chat.feedback.incorrect')
  ]

  useEffect(() => {
    focusInput(inputRef)
  }, [feedback])

  return (
    <div className={classes.Feedback}>
      <div className={classes.Feedback__Header}>
        <div className={classes.Feedback__Header__Label}>
          <Typography format={'bcs'}>
            {t('copilot.chat.feedback.title')}
          </Typography>
          <Typography
            format={'bcs'}
            className={classes.Feedback__Header__Label__Subtext}
          >
            {t('copilot.chat.feedback.optionalSubtext')}
          </Typography>
        </div>
        <IconButton
          data-testid={'close-feedback-form'}
          label={'Close'}
          className={classes.Feedback__Header__CloseButton}
          onClick={onClose}
        >
          <Cross />
        </IconButton>
      </div>
      <div className={classes.Feedback__QuickOptions}>
        {(feedbackType === 'GOOD'
          ? quickPositiveOptions
          : quickNegativeOptions
        ).map((option) => (
          <button
            type={'button'}
            className={classes.Feedback__QuickOptions__Button}
            key={option}
            onClick={() => {
              setFeedback(option)
            }}
          >
            <Typography format={'mc'} weight={'bold'}>
              {option}
            </Typography>
          </button>
        ))}
      </div>
      <div className={classes.Feedback__Form}>
        <Input
          data-testid={'feedback-input'}
          ref={inputRef}
          className={classes.Feedback__Form__Input}
          value={feedback}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFeedback(e.target.value)
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              if (feedback !== '') {
                onSubmit(feedback)
              }
            }
          }}
          placeholder={t('copilot.chat.feedback.inputPlaceholder')}
        />
        <Button
          data-testid={'submit-feedback'}
          alt={'text'}
          disabled={isLoading}
          className={classes.Feedback__Form__Submit}
          onClick={() => {
            onSubmit(feedback)
          }}
        >
          {isLoading ? (
            <LoadingSpinner
              className={classes.Feedback__Form__Submit__Loading}
            />
          ) : (
            <Typography
              className={classes.Feedback__Form__Submit__Text}
              format={'bcs'}
              weight={'bold'}
            >
              {t('copilot.chat.feedback.submit')}
            </Typography>
          )}
        </Button>
      </div>
    </div>
  )
}

export default CopilotFeedbackForm
