import { useCallback, type FC } from 'react'
import { useDispatch } from 'react-redux'

import { type ParameterValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { jobActions } from 'job-lib/store'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { type ComponentInstance } from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentParameterUpdateEffects } from 'modules/ComponentParameters/hooks'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import classes from './ComponentParameters.module.scss'
import { ComponentParametersWizard } from './ComponentParametersWizard'
import { convertElementCollectionToValue } from './utils/convertElementCollectionToValue'

interface ComponentParametersProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
}

export interface EditedParameter {
  parameterSlot: number
  parameterPath: string[]
  parameterDataType: ParameterDataType
  editedValue: ElementCollection | ParameterValue
  editorColumns?: EditorColumn[]
}

export const ComponentParametersContainer: FC<ComponentParametersProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId
}) => {
  const dispatch = useDispatch()
  const { invalidateComponent } = useComponentValidationProvider()

  const { runComponentUpdateEffectsOnBlur } =
    useComponentParameterUpdateEffects()

  const onEdit = useCallback(
    ({
      parameterSlot,
      parameterPath,
      parameterDataType,
      editedValue,
      editorColumns
    }: EditedParameter) => {
      const { parameters } = componentInstance

      const parameterValue = isDPLParameterCollection(parameters)
        ? convertElementCollectionToValue(
            editedValue,
            parameterDataType,
            editorColumns
          )
        : editedValue

      dispatch(
        jobActions.overrideComponentParameter({
          componentMetadata,
          parameterSlot,
          parameterPath,
          componentInstanceId: componentInstance.id,
          value: parameterValue
        })
      )

      invalidateComponent(componentInstance.id)
    },
    [componentInstance, componentMetadata, dispatch, invalidateComponent]
  )

  const onBlur = useCallback(
    ({
      parameterSlot,
      parameterPath,
      parameterDataType,
      editedValue,
      editorColumns
    }: EditedParameter) => {
      runComponentUpdateEffectsOnBlur(
        componentInstance,
        componentMetadata,
        componentSummaryId,
        {
          parameterSlot,
          parameterPath,
          parameterDataType,
          editedValue,
          editorColumns
        }
      )
    },
    [
      componentInstance,
      componentMetadata,
      componentSummaryId,
      runComponentUpdateEffectsOnBlur
    ]
  )

  return (
    <div
      className={classes.ComponentParameters}
      data-testid="component-parameter-editor"
    >
      <ComponentParametersWizard
        componentInstance={componentInstance}
        componentSummaryId={componentSummaryId}
        componentMetadata={componentMetadata}
        onEdit={onEdit}
        onBlur={onBlur}
      />
    </div>
  )
}
