import { getFileInfo } from '@matillion/git-component-library'
import {
  VariableType,
  type Column,
  type GridVariable,
  type ScalarVariable
} from 'types/Pipeline'
import YAML from 'yaml'

import { JobVariableBehaviour } from 'job-lib/types/Variables'

import { ParameterDataType } from '../useGetComponentMetadata/types'
import type {
  SharedPipelineDetails,
  SharedPipelinesConfig,
  VariablesList
} from './types'

export const pipelineIdCharactersRegex = /[^A-Za-z0-9\-_.!~*'()]/

export const isPipelineSharedAndEnabled = (
  sharedPipelines: SharedPipelineDetails[],
  pipelinePath: string
) => sharedPipelines.some((p) => p.pipeline === pipelinePath && p.enabled)

export const isPipelineShared = (
  sharedPipelines: SharedPipelineDetails[],
  pipelinePath: string
) => sharedPipelines.some((p) => p.pipeline === pipelinePath)

export interface Parameter {
  type: ParameterDataType
  exportAllowed: boolean
  description?: string
  columns?: Record<string, Column>
}

const isGridVariable = (
  variable: ScalarVariable | GridVariable
): variable is GridVariable => variable.metadata.type === VariableType.Grid

export const convertVariableTypeToParameterType = (
  variable: ScalarVariable | GridVariable
): ParameterDataType => {
  switch (variable.metadata.type) {
    case VariableType.Text:
      return ParameterDataType.TEXT
    case VariableType.Number:
      return ParameterDataType.NUMERIC
    case VariableType.Grid:
      return ParameterDataType.GRID
  }
}

export const convertVariablesToParameters = (variables: VariablesList) => {
  if (!variables) return undefined
  return Object.entries(variables)
    .filter(([_, variable]) => Boolean(variable))
    .filter(([_, variable]) => variable.metadata.visibility === 'PUBLIC')
    .reduce((acc, [name, variable]) => {
      const parameter: Parameter = {
        type: convertVariableTypeToParameterType(variable),
        exportAllowed: variable.metadata.scope === JobVariableBehaviour.SHARED,
        description: variable.metadata.description
      }

      if (isGridVariable(variable)) {
        parameter.columns = variable.metadata.columns
      }

      return {
        ...acc,
        [name]: parameter
      }
    }, {})
}

export const getDisplayName = (pipelineName: string) => {
  const { fileName } = getFileInfo(pipelineName)

  return fileName
}

export const getPipelineId = (pipelineName: string) => {
  /**
   * Converts the pipeline name to a valid pipeline ID.
   * It should be based on the file name and extension, skipping the folders.
   * All characters not in [A-Za-z0-9\-_.!~*'()] will be replaced by spaces
   * and then all spaces will get replaced by a single dash, to account for multiple disallowed characters in a row
   */

  const { fileName, extension } = getFileInfo(pipelineName)
  return `${fileName}${extension}`
    .replace(new RegExp(pipelineIdCharactersRegex.source, 'g'), ' ')
    .replace(/(\.tran|\.orch|\.TRANSFORMATION|\.ORCHESTRATION)/g, (s) =>
      s.replace('.', ' ')
    )
    .replace(/\s+/g, '-')
    .replace('.yaml', '')
}

export const getPipelineVariables = async (
  pipelineName: string,
  getFileAsync: (filePath: string) => Promise<string | null>
) => {
  const pipelineDpl = await getFileAsync(pipelineName)

  const pipelineVariables =
    YAML.parse(pipelineDpl ?? '')?.pipeline?.variables ?? []

  return pipelineVariables
}

export const getNewSharedPipelinesConfig = (
  pipelineName: string,
  pipelineVariables: VariablesList,
  existingSharedPipelinesConfig: SharedPipelinesConfig
) => {
  const parameters = convertVariablesToParameters(pipelineVariables)

  const sharedPipelinesConfigDraft = existingSharedPipelinesConfig

  sharedPipelinesConfigDraft.pipelines =
    sharedPipelinesConfigDraft.pipelines.map((p) => {
      if (p.pipeline === pipelineName) {
        return {
          ...p,
          parameters
        }
      }

      return p
    })

  const newSharedPipelinesConfig = stringifyConfigToYamlWithHeaderComment(
    sharedPipelinesConfigDraft
  )

  return newSharedPipelinesConfig
}

export const stringifyConfigToYamlWithHeaderComment = (
  config: SharedPipelinesConfig
) => {
  const headerComment = ` This file contains shared pipelines configuration which defines how they appear to consuming users.
 The configuration is automatically updated when changes are made to shared pipelines in the Designer UI.
 Please exercise caution when manually editing to avoid conflicts or data loss.
 For more information please see our documentation.`

  const yamlDocument = new YAML.Document(config, {
    aliasDuplicateObjects: false
  })
  yamlDocument.commentBefore = headerComment

  return yamlDocument.toString()
}

export const getExistingPipelineIds = (config: SharedPipelinesConfig) => {
  return config.pipelines.map((p) => p.id)
}

export const generateAutoincrementedPipelineId = (
  pipelineName: string,
  config: SharedPipelinesConfig | null
) => {
  const pipelineId = getPipelineId(pipelineName)

  if (!config) return pipelineId

  const existingPipelineIds = getExistingPipelineIds(config)

  let newPipelineId = pipelineId
  let increment = 1

  while (existingPipelineIds.includes(newPipelineId)) {
    newPipelineId = `${pipelineId}-${increment}`
    increment++
  }

  return newPipelineId
}
