import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '@matillion/hub-client'

import { ReactComponent as PlusInCircle } from 'assets/plus-in-circle.svg'

import { useStackingModal } from 'components/StackingModalProvider'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'
import type { Cardinality, OutputPortType } from 'job-lib/types/Components'
import { type ComponentInstanceId } from 'job-lib/types/Job'

import { isTopSourcesCookieTrue } from 'utils/isTopSourcesCookieTrue'

import classes from './AddNextComponent.module.scss'
import AddNextComponentModal from './components/AddNextComponentModal/AddNextComponentModal'

interface AddNextComponentProps {
  sourceComponentId?: ComponentInstanceId
}

export interface SourceComponentConnection {
  sourceComponentId: ComponentInstanceId
  sourceComponentName: string
  sourceType: OutputPortType
  sourceCardinality: Cardinality
}

export const AddNextComponent = ({
  sourceComponentId
}: AddNextComponentProps) => {
  const { t } = useTranslation()

  const sourceComponentMetadata =
    useComponentInstanceMetadataQuery(sourceComponentId)

  const componentSummaryId = sourceComponentMetadata.componentSummaryId

  const { pushNewModalContent } = useStackingModal()

  const { user } = useUser()
  const topSources = user[
    'https://hub.matillion.com/primary_data_sources'
  ] as unknown as string[]
  const { pipelineSummary: activePipeline } = useActivePipelineSummary()

  const openDialog = (showTopSources?: boolean) => {
    pushNewModalContent(
      {
        ariaLabelledBy: 'modal-title',
        className: classes.ModalContainer,
        setFocus: false,
        size: 'mid-large'
      },
      <AddNextComponentModal
        sourceComponentId={sourceComponentId}
        showTopSources={showTopSources}
      />
    )
  }

  useEffect(() => {
    const activePipelineIsMyFirstPipeline =
      activePipeline.name === 'My first pipeline.orch.yaml'
    if (
      activePipelineIsMyFirstPipeline &&
      isTopSourcesCookieTrue() &&
      topSources?.length
    ) {
      const hasDialogOpened =
        localStorage.getItem('dpcHasAddNextComponentAutoOpened') === 'true'
      if (!hasDialogOpened) {
        openDialog(true)
        localStorage.setItem('dpcHasAddNextComponentAutoOpened', 'true')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isUnknownComponent = unknownComponentIds.includes(
    componentSummaryId as string
  )

  if (!!sourceComponentId && isUnknownComponent) {
    return null
  }

  return (
    <button
      type="button"
      className={classes.AddNextComponentCTA}
      onClick={() => {
        openDialog(false)
      }}
      data-testid="add-next-component"
      aria-label={t('canvas.addComponent')}
    >
      <PlusInCircle />
    </button>
  )
}
