import { useDispatch } from 'react-redux'

import type { VariableVisibility } from 'types/Pipeline'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'
import { type UpdateProjectVariablesRequest } from 'api/hooks/useUpdateProjectVariables/types'
import { useUpdateProjectVariables } from 'api/hooks/useUpdateProjectVariables/useUpdateProjectVariables'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'
import {
  VariableScope,
  type JobVariableBehaviour,
  type JobVariableVisibility,
  type ScalarVariable
} from 'job-lib/types/Variables'

import { useJobVariables } from 'modules/core/EtlDesigner/hooks/useJobVariables'
import {
  Stages,
  type ContentProps,
  type EditFormProps,
  type FormState,
  type ProjectVariableBehaviour,
  type ProjectVariableType
} from 'modules/ManageVariables/types'
import { isSelectedProjectVariable } from 'modules/ManageVariables/utils'

import { Form } from '../Form/Form'

export const Edit = ({
  setStage,
  variable,
  variableScope,
  variableType
}: ContentProps<EditFormProps>) => {
  const jobDispatch = useDispatch()
  const jobVariables = useJobVariables()
  const { jobSummaryId } = useProjectInfo()
  const { enableSharedPipelines } = useFlags()
  const { updateVariableInSharedPipeline } = useSharePipeline()
  const { data: projectVariables, isLoading: projectVariablesLoading } =
    useListProjectVariables()
  const { mutateAsync: updateProjectVariables, isLoading } =
    useUpdateProjectVariables()

  const { name } = variable
  const isEditingJobVariableType = !isSelectedProjectVariable(variable)

  const variableToEdit = isEditingJobVariableType
    ? (jobVariables.find((jv) => jv.definition.name === name) as ScalarVariable)
    : projectVariables?.find((projVar) => {
        return projVar.id === variable?.id
      })

  if (!variableToEdit && !projectVariablesLoading) {
    setStage({ stage: Stages.MANAGE, variableScope })

    return <></>
  }

  const onSubmit = (state: FormState) => {
    if (isEditingJobVariableType && state.VARIABLE_TYPE.value !== null) {
      jobDispatch(
        jobActions.updateJobVariable({
          oldName: name,
          updatedVariable: {
            definition: {
              name: state.NAME.value,
              description: state.DESCRIPTION.value,
              type: state.VARIABLE_TYPE.value,
              behaviour: state.BEHAVIOUR.value as JobVariableBehaviour,
              visibility: state.VISIBILITY.value as JobVariableVisibility
            },
            value: state.DEFAULT_VALUE.value
          }
        })
      )

      enableSharedPipelines &&
        updateVariableInSharedPipeline({
          pipelineName: jobSummaryId,
          variable: {
            oldName: name,
            name: state.NAME.value,
            type: state.VARIABLE_TYPE.value,
            scope: state.BEHAVIOUR.value as JobVariableBehaviour,
            description: state.DESCRIPTION.value,
            visibility: state.VISIBILITY.value as VariableVisibility
          }
        })

      setStage({
        stage: Stages.MANAGE,
        variableScope: VariableScope.JOB_VARIABLE
      })
    }

    if (!isEditingJobVariableType) {
      const patchData: UpdateProjectVariablesRequest = {
        name: state.NAME.value,
        type: state.VARIABLE_TYPE.value as ProjectVariableType,
        behaviour: state.BEHAVIOUR.value as ProjectVariableBehaviour,
        description: state.DESCRIPTION.value,
        value:
          state.DEFAULT_VALUE.value !== '' ? state.DEFAULT_VALUE.value : null,
        overrides: Object.values(state.PROJECT_DEFAULT_OVERRIDES.value).map(
          ({ environmentId, value }) => ({
            environmentId,
            value: value !== '' ? value : null
          })
        )
      }

      updateProjectVariables({
        variableId: variable.id,
        updateProjectVariablesRequest: patchData
      }).then(() => {
        setStage({
          stage: Stages.MANAGE,
          variableScope: VariableScope.PROJECT_VARIABLE
        })
      })
    }
  }

  const onCancel = () => {
    setStage({ stage: Stages.MANAGE, variableScope })
  }

  return (
    <Form
      variableToEdit={variableToEdit}
      variableScope={variableScope}
      variableType={variableType}
      submitting={isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
