import { type FC } from 'react'

import { Typography } from '@matillion/component-library'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { type ComponentInstance } from 'job-lib/types/Job'

import classes from './SampleComponent.module.scss'

interface SampleIconAndLabelProps {
  componentInstance: ComponentInstance
}

export const SampleIconAndLabel: FC<SampleIconAndLabelProps> = ({
  componentInstance
}) => {
  const { componentSummaryId } = useComponentInstanceMetadataQuery(
    componentInstance.id
  )

  const { getIcon } = useComponentInfo()

  const label = getComponentName(componentInstance)
  return (
    <>
      {componentSummaryId && (
        <img
          data-testid="sample-component-icon"
          className={classes.SampleComponentForm__ComponentIcon}
          src={getIcon(componentSummaryId, componentInstance.parameters)}
          alt={label}
        />
      )}
      <Typography
        data-testid="sample-component-name"
        as="span"
        className={classes.SampleComponentForm__ComponentName}
        format="bcs"
      >
        {label}
      </Typography>
    </>
  )
}
