import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { useGetPollableRootPipelineActivity } from 'api/hooks/useGetPollableRootPipelineActivity/useGetPollableRootPipelineActivity'
import { useGetPollableStepsInfinite } from 'api/hooks/useGetPollableStepsInfinite/useGetPollableStepsInfinite'

import { useFlags } from 'hooks/useFlags'

import { CenteredSpinner } from '../components/CenteredSpinner'
import ErrorMessage from '../components/ErrorMessage'
import { InfiniteScrollAnchor } from '../components/InfiniteScrollAnchor'
import { Table, TBody, THead } from '../components/PipelineTable'
import classes from './PipelineTree.module.scss'
import { PipelineTreeHeadRow } from './PipelineTreeHeadRow'
import SkeletonStepRow from './SkeletonStepRow'
import StepRow from './StepRow'

interface PipelineTreeProps {
  pipelineRunId: string
  pollInterval: number
}

const PipelineTree: FC<PipelineTreeProps> = ({
  pipelineRunId,
  pollInterval
}) => {
  const { t } = useTranslation()

  const { enableTaskDetailsPolling, enableLoggingView } = useFlags()

  const {
    data,
    isLoading: isRootLoading,
    isError: isRootError,
    isPolling: isPipelineActivityPolling
  } = useGetPollableRootPipelineActivity(pollInterval, pipelineRunId)

  const {
    steps,
    isFetching,
    isError,
    hasMore,
    fetchNextPage,
    isPolling: isPipelineStepsPolling
  } = useGetPollableStepsInfinite(pollInterval, pipelineRunId)

  if (isError || isRootError) {
    return <ErrorMessage>{t('pipelineRunExplorer.networkError')}</ErrorMessage>
  }

  return (
    <div
      className={classes.PipelineTree__Container}
      data-testid="pipeline-tree-container"
    >
      <Table>
        <THead sticky>
          <PipelineTreeHeadRow
            cellClassName={classes.PipelineTree__Cell}
            className={classes.PipelineTree__Row}
          />
        </THead>
        <TBody>
          {steps?.map((step, index) => (
            <StepRow
              pipelineRunId={pipelineRunId}
              enableLoggingView={enableLoggingView}
              cellClassName={classes.PipelineTree__Cell}
              rowClassName={classes.PipelineTree__Row}
              step={step}
              depth={0}
              pipelineName={data?.definition.pipelineName}
              key={step.id}
              isRoot={true}
              isLast={index === steps.length - 1}
              treeLines={[]}
              pollInterval={pollInterval}
            />
          ))}
          {(isPipelineActivityPolling || isPipelineStepsPolling) && (
            <SkeletonStepRow
              cellClassName={classes.PipelineTree__Cell}
              rowClassName={classes.PipelineTree__Row}
              key={'skeleton-step-row'}
            />
          )}
        </TBody>
      </Table>

      {hasMore && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
      {!isFetching &&
        !hasMore &&
        !isPipelineActivityPolling &&
        !isPipelineStepsPolling && (
          <div className={classes.PipelineTree__EndOfResults}>
            <Typography type="bcl">
              {t('pipelineRunExplorer.endOfResults')}
            </Typography>
          </div>
        )}
      {!enableTaskDetailsPolling && (isFetching || isRootLoading) && (
        <CenteredSpinner />
      )}
      <div className={classes.PipelineTree__Spacer}></div>
    </div>
  )
}

export default PipelineTree
