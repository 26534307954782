import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, LoadingSpinner, Typography } from '@matillion/component-library'

import { type TableDef } from '../../types'
import classes from './LoadStatus.module.scss'

interface LoadStatusProps {
  isConfigured: string
  isSelected: string
  tableDef?: TableDef
}

export const LoadStatus: FC<LoadStatusProps> = ({
  isConfigured,
  isSelected,
  tableDef
}) => {
  const { t } = useTranslation()

  if (!isSelected || !tableDef || !!tableDef.isError) {
    return null
  }

  if (tableDef.columns) {
    return (
      <Badge
        theme="filled"
        colour={isConfigured ? 'green' : 'red'}
        format="mc"
        weight="bold"
        className={classes.LoadStatus}
      >
        {t(
          `multiTableConfig.tableSelection.${
            isConfigured ? 'configured' : 'toConfigure'
          }`
        )}
      </Badge>
    )
  }

  return (
    <div className={classes.LoadingStatus}>
      <LoadingSpinner className={classes.LoadingStatus__Spinner} />
      <Typography format="bcs" className={classes.LoadingStatus__Text}>
        {t('multiTableConfig.tableSelection.loading')}
      </Typography>
    </div>
  )
}
