import { type ChangeEvent, type FC } from 'react'

import {
  Checkbox,
  Field,
  TreeItem,
  Typography
} from '@matillion/component-library'
import { t } from 'i18next'

import classes from '../StructuredNestedDataPickerEditor.module.scss'
import { type TreeNode } from '../utils'

export const SchemaElement: FC<{
  node: TreeNode
  isRoot: boolean
  onSelectionChange: (node: TreeNode, selected: boolean) => void
}> = ({
  node,
  node: { name, path, children, type, selected },
  onSelectionChange,
  isRoot
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSelectionChange(node, e.target.checked)
  }

  const treeElement = (
    <div className={classes.Leaf}>
      <Field
        aria-label={t('common.select')}
        checked={selected}
        onChange={onChange}
        inputComponent={Checkbox}
      />
      <Typography as="span">{name}</Typography>
      <Typography as="span" className={classes.DataType}>
        {type}
      </Typography>
    </div>
  )

  return (
    <TreeItem root={isRoot} label={name} nodeId={path} leaf={treeElement}>
      {children.length
        ? children.map((child) => (
            <SchemaElement
              key={child.name}
              node={child}
              onSelectionChange={onSelectionChange}
              isRoot={false}
            ></SchemaElement>
          ))
        : undefined}
    </TreeItem>
  )
}
