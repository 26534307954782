import { useCallback, useEffect, useMemo, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { DynamicDropdown } from 'components/DynamicDropdown/DynamicDropdown'
import { useParameterOverlayErrors } from 'components/ParameterOverlay'

import { type ComponentInstanceId } from 'job-lib/types/Job'

import { type GridCell } from '../../types'
import classes from './GridDropdown.module.scss'

export interface GridDropdownProps extends GridCell {
  onChange: (gc: GridCell) => void
  componentMetaData: ComponentMetadata
  editorColumn: EditorColumn
  parameter: ComponentParameter
  componentInstanceId: ComponentInstanceId
  parameterName: string
  value: string
  componentSummaryId: ComponentSummaryId
}

export const GridDropdown: FC<GridDropdownProps> = ({
  componentMetaData,
  parameter,
  editorColumn,
  componentInstanceId,
  parameterName,
  componentSummaryId,
  value,
  type,
  slot,
  rowSlot,
  onChange,
  dataType
}) => {
  const { t } = useTranslation()
  const { errors, setErrors, clearError } = useParameterOverlayErrors()

  useEffect(() => {
    return () => {
      clearError(`GridDropdown-${slot}-${rowSlot}`)
    }
  }, [slot, rowSlot, clearError])

  const handleEditorError = useCallback(
    (error: ProblemDetails | undefined) => {
      const isErrorShown = errors.find(
        ({ message }) => message === error?.detail
      )

      if (error && !isErrorShown) {
        setErrors([
          ...errors,
          {
            id: `GridDropdown-${slot}-${rowSlot}`,
            message: error?.detail,
            type: 'error'
          }
        ])
      }
    },
    [errors, rowSlot, slot, setErrors]
  )

  const isError = useMemo(() => {
    return errors.find(({ id }) => id === `GridDropdown-${slot}-${rowSlot}`)
  }, [errors, rowSlot, slot])

  return (
    <DynamicDropdown
      placeholder={t('parameterEditor.GRID_EDITOR.dropdownPlaceholder')}
      inputId={`row-${rowSlot}-column-${slot}`}
      inputClassName={classnames(classes.Dropdown, {
        [classes['Dropdown__Container--Error']]: isError
      })}
      className={classnames(classes.Dropdown__Container)}
      componentMetadata={componentMetaData}
      componentSummaryId={componentSummaryId}
      parameter={{
        ...editorColumn,
        id: componentInstanceId.toString(),
        metlSlot: parameter.metlSlot
      }}
      parameterName={parameterName}
      value={value ?? ''}
      onEdit={(selectedValue: string) => {
        onChange({
          rowSlot,
          slot,
          type,
          value: selectedValue,
          dataType
        })
      }}
      onEditorError={handleEditorError}
    />
  )
}
