import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  FileDetail,
  Modal,
  Typography
} from '@matillion/component-library'

import { Loading } from 'components/Loading/Loading'
import { ModalTitle } from 'components/ModalTitle/ModalTitle'

import { useFlags } from 'hooks/useFlags'

import useImportMetlJobs from 'modules/migration/ConversionTool/hooks/useImportMetlJobs'
import useMetlConversionReport from 'modules/migration/ConversionTool/hooks/useMetlConversionReport'
import useMetlJobConversionInfo from 'modules/migration/ConversionTool/hooks/useMetlJobConversionInfo'

import CopyConversionReportButton from '../CopyConversionReportButton/CopyConversionReportButton'
import ImportConversionDetails from '../ImportConversionDetails/ImportConversionDetails'
import MappingModal from '../MappingModal/MappingModal'
import ViewFullConversionReportButton from '../ViewFullConversionReportButton/ViewFullConversionReportButton'
import ConversionError from './ConversionError/ConversionError'
import classes from './ConversionToolModal.module.scss'
import { type ConversionToolModalProps } from './types'

const ConversionToolModal = ({
  onClose,
  file,
  path
}: ConversionToolModalProps) => {
  const { enableViewFullConversionReportButton } = useFlags()

  const [mappings, setMappings] = useState<string>('')
  const [showMappings, setShowMappings] = useState<boolean>(false)

  const { t } = useTranslation('translation', { keyPrefix: 'conversion.modal' })
  const { isImportLoading, migrateMetlJobsToPipelines, importError } =
    useImportMetlJobs()

  const {
    isDryRunLoading,
    hasJobsThatCannotBeConverted,
    hasVariablesThatCannotBeConverted,
    hasNothingToImport,
    jobs,
    variables,
    dryRunError,
    refetch
  } = useMetlJobConversionInfo({ file, path, mappings })

  const {
    metlJobConversionTextReport,
    jobConversionReport,
    variableConversionReport
  } = useMetlConversionReport({
    fileName: file?.name,
    variables,
    jobs
  })

  const isLoading = isDryRunLoading || isImportLoading

  const handleConversion = useCallback(
    async (conversionFile: File, conversionPath: string) => {
      await migrateMetlJobsToPipelines(conversionFile, conversionPath, mappings)
      onClose()
    },
    [mappings, migrateMetlJobsToPipelines, onClose]
  )

  return (
    <Modal
      size="mid-large"
      drawerModePosition="right"
      onCancel={onClose}
      disableBackdropClick
      ariaLabelledBy="conversion-modal-title"
    >
      <ModalTitle
        title={
          t('title') + (isDryRunLoading || dryRunError ? '' : ' (Preview)')
        }
        description={isDryRunLoading || dryRunError ? '' : t('subtitle')}
        titleId={'conversion-modal-title'}
        data-testid={'conversion-modal-title'}
        className={classes.title}
      />

      {showMappings && (
        <MappingModal
          onClose={() => {
            setShowMappings(false)
          }}
          value={mappings}
          onValueChange={(value: string) => {
            setMappings(value)
          }}
          onRerun={() => {
            setShowMappings(false)
            refetch()
          }}
        />
      )}

      {file && (
        <div className={classes.fileDetails}>
          <Typography format="ts" as="span" className={classes.fileDetailName}>
            <FileDetail
              file={file}
              displayReplaceButton={false}
              displayDeleteButton={false}
            />
          </Typography>
        </div>
      )}

      {isDryRunLoading && !isImportLoading && (
        <Loading
          text={t('report-loading')}
          data-testid="conversion-tool-report-loading"
        />
      )}

      {!isLoading && Boolean(importError) && file && (
        <ConversionError
          error={importError}
          showMappings={() => {
            setShowMappings(true)
          }}
        />
      )}

      {!isLoading && Boolean(dryRunError) && file && (
        <ConversionError
          error={dryRunError}
          showMappings={() => {
            setShowMappings(true)
          }}
        />
      )}

      {hasNothingToImport && (
        <Alert
          type="error"
          title={t('error.empty-export.title')}
          message={t('error.empty-export.message')}
          data-testid="conversion-tool-empty-export-error-alert"
        />
      )}

      {!enableViewFullConversionReportButton &&
        (jobs || variables) &&
        !isDryRunLoading &&
        !hasNothingToImport && (
          <Typography format="ts" className={classes.copyReport}>
            <CopyConversionReportButton
              contents={metlJobConversionTextReport}
            />
          </Typography>
        )}

      {enableViewFullConversionReportButton &&
        (jobs || variables) &&
        !isDryRunLoading &&
        !hasNothingToImport && (
          <ViewFullConversionReportButton
            exportFileName={file.name}
            jobConversionReport={jobConversionReport}
            jobsNotConverted={jobs?.notConverted || []}
            variableConversionReport={variableConversionReport}
            variablesNotConverted={variables?.notConverted || []}
          />
        )}

      {!isDryRunLoading &&
        !hasNothingToImport &&
        !importError &&
        !dryRunError && (
          <div className={classes.resultDetails}>
            <ImportConversionDetails
              jobConversionReport={jobConversionReport}
              variableConversionReport={variableConversionReport}
              jobs={jobs}
              variables={variables}
              hasJobsThatCannotBeConverted={hasJobsThatCannotBeConverted}
              hasVariablesThatCannotBeConverted={
                hasVariablesThatCannotBeConverted
              }
              openMappings={() => {
                setShowMappings(true)
              }}
            />
          </div>
        )}

      <div className={classes.footer}>
        <Button
          type="reset"
          alt="secondary"
          text={t('cancel')}
          data-testid="conversion-modal-cancel"
          onClick={onClose}
        />

        <Button
          alt="primary"
          type="submit"
          text={t('submit')}
          disabled={hasNothingToImport || !!dryRunError || !!importError}
          data-testid="conversion-modal-submit"
          waiting={isImportLoading || isDryRunLoading}
          onClick={() => {
            handleConversion(file, path)
          }}
        />
      </div>
    </Modal>
  )
}

export default ConversionToolModal
