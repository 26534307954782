import type { ComponentLayout } from './types'

export const BashPushdown: ComponentLayout = {
  componentId: 'bash-pushdown',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connection',
        previousStepId: null,
        nextStepId: 'auth',
        parameters: [
          { dplId: 'sshHost' },
          { dplId: 'sshUser' },
          { dplId: 'connectionTimeout' },
          { dplId: 'sshPort' }
        ]
      },
      {
        stepId: 'auth',
        displayName: 'Authentication',
        previousStepId: 'connect',
        nextStepId: 'exec',
        parameters: [{ dplId: 'bashPushdownAuthenticationProvider' }]
      },
      {
        stepId: 'exec',
        displayName: 'Execution Details',
        previousStepId: 'auth',
        nextStepId: null,
        parameters: [{ dplId: 'script' }, { dplId: 'exitScriptOnFailure' }]
      }
    ]
  }
}
