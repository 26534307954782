import { useQueryClient } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { type ProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

interface UseRemoveParameterOptionsCacheBag {
  removeQueryCache: (
    projectInfo: Pick<ProjectInfo, 'projectId' | 'jobSummaryId' | 'branchId'>
  ) => void
}

export const useRemoveParameterOptionsCache =
  (): UseRemoveParameterOptionsCacheBag => {
    const queryClient = useQueryClient()

    const removeQueryCache = (
      projectInfo: Pick<ProjectInfo, 'projectId' | 'jobSummaryId' | 'branchId'>
    ) => {
      queryClient.removeQueries({
        queryKey: [
          queryKeys.parameterOptions,
          projectInfo.projectId,
          projectInfo.branchId,
          projectInfo.jobSummaryId
        ]
      })
    }

    return {
      removeQueryCache
    }
  }
