import { useMemo, type FC, type ReactNode } from 'react'

import { useEntitlements } from '@matillion/hub-client'

import { useFlags } from '../../../hooks/useFlags'
import { isAiComponent } from '../../../utils/componentSummaryFilter'
import { EntitlementsContext } from './context'

export interface EntitlementProviderProps {
  children: ReactNode
}

/**
 * This provider initializes entitlements when the app loads, avoiding delay when checking entitlements.
 */
export const EntitlementProvider: FC<EntitlementProviderProps> = ({
  children
}) => {
  const {
    enableEntitlementCheckAiCopilot,
    enableEntitlementCheckAiComponents
  } = useFlags()
  const { data: entitlements } = useEntitlements()

  const hasCopilotEntitlement = () => {
    if (!enableEntitlementCheckAiCopilot) {
      return true
    }
    return entitlements?.canAccessAiCopilot ?? false
  }

  const hasCopilot = hasCopilotEntitlement()

  const value = useMemo(
    () => ({
      hasCopilot,
      isLimitedUseComponent: (componentId: string) => {
        // A component is limited use if it requires the AI upgrade and the user does not have
        // the canAccessAiComponents entitlement.
        if (
          !enableEntitlementCheckAiComponents ||
          entitlements?.canAccessAiComponents
        ) {
          return false
        }
        return isAiComponent(componentId)
      }
    }),
    [
      hasCopilot,
      enableEntitlementCheckAiComponents,
      entitlements?.canAccessAiComponents
    ]
  )

  return (
    <EntitlementsContext.Provider value={value}>
      {children}
    </EntitlementsContext.Provider>
  )
}
