import { create } from 'zustand'

interface CommandPaletteConfigStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useCommandPaletteStore = create<CommandPaletteConfigStore>()(
  (set, get) => ({
    isOpen: false,
    setIsOpen: (isOpen) => {
      set({
        isOpen
      })
    }
  })
)
