import React from 'react'
import { useTranslation } from 'react-i18next'

import { FileType } from '@matillion/git-component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { PopOverItem } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'

import { FileBrowserCommand } from '../../context/types'
import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'

interface Props {
  folderPath?: string
  entryContext: 'folder' | 'addButton'
}

const CreatePipelineContextMenuEntries = ({
  folderPath,
  entryContext
}: Props) => {
  const { onCommand, onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { t } = useTranslation('translation', { keyPrefix: 'jobContextMenu' })
  const { hasPermission: canCreate } = useProjectPermission(
    'create_pipelines',
    PermissionType.PROJECT
  )
  const { enableHighCode } = useFlags()

  const handleCreatePipeline = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fileType: FileType
  ) => {
    e.stopPropagation()

    onCommand({
      type: FileBrowserCommand.CREATE_PIPELINE,
      payload: fileType
    })

    onFileTreeCommand({
      type: FileTreeReducerType.SET_FOLDER_PATH,
      fileDetails: { path: folderPath }
    })
  }

  const availableFileTypes: FileType[] = [
    FileType.ORCHESTRATION_PIPELINE,
    FileType.TRANSFORMATION_PIPELINE,
    ...(enableHighCode ? [FileType.PYTHON, FileType.SQL] : [])
  ]

  return (
    <>
      {availableFileTypes.map((fileType) => (
        <PopOverItem
          key={fileType}
          disabled={!canCreate}
          data-testid={`job-action-create-${fileType}`}
          onClick={(e) => {
            handleCreatePipeline(e, fileType)
          }}
        >
          {t(`addNewJob_${fileType}_${entryContext}`)}
        </PopOverItem>
      ))}
    </>
  )
}

export default CreatePipelineContextMenuEntries
