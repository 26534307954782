import type { ParameterValue } from 'types/Pipeline'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import { type Cardinality, type OutputPortType } from 'job-lib/types/Components'
import {
  type ComponentInstanceId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { type JobType } from 'job-lib/types/JobType'

import {
  type ElementCollection,
  type ParameterSlot
} from '../../types/Parameters'

export interface JobStateBase {
  job: null | OrchestrationJob | TransformationJob
  jobType: null | JobType
}

export interface JobStateOrchestration {
  job: OrchestrationJob
  jobType: JobType.Orchestration
}

export interface JobStateTransformation {
  job: TransformationJob
  jobType: JobType.Transformation
}

export interface JobStateNull {
  job: null
  jobType: null
}

export type JobState =
  | JobStateNull
  | JobStateTransformation
  | JobStateOrchestration

export enum OrchestrationComponentConnectors {
  INPUT = 'inputConnectorIDs',
  INPUT_ITERATION = 'inputIterationConnectorIDs',
  SUCCESS = 'outputSuccessConnectorIDs',
  FAILURE = 'outputFailureConnectorIDs',
  UNCONDITIONAL = 'outputUnconditionalConnectorIDs',
  OUTPUT_ITERATION = 'outputIterationConnectorIDs',
  OUTPUT_TRUE = 'outputTrueConnectorIDs',
  OUTPUT_FALSE = 'outputFalseConnectorIDs'
}

export enum OrchestrationJobConnectors {
  SUCCESS = 'successConnectors',
  FAILURE = 'failureConnectors',
  UNCONDITIONAL = 'unconditionalConnectors',
  ITERATION = 'iterationConnectors',
  TRUE = 'trueConnectors',
  FALSE = 'falseConnectors'
}

export interface OverrideComponentParam {
  value: ElementCollection | ParameterValue
  parameterSlot: ParameterSlot
  parameterPath: string[]
  componentInstanceId: ComponentInstanceId
  componentMetadata: ComponentMetadata
}

export interface UpdateComponentPosition {
  id: number
  x: number
  y: number
  type: 'note' | 'component'
}

export interface UpdateLinks {
  sourceComponentId: ComponentInstanceId
  targetComponentId: ComponentInstanceId
  sourceCardinality: Cardinality
  targetCardinality: Cardinality
  sourceType: OutputPortType
}
