import { type RefObject } from 'react'

/**
 * Focus the input element of the provided ref object.
 * @param ref - A ref object pointing to an HTMLDivElement.
 */
export function focusInput(ref: RefObject<HTMLInputElement>): void {
  if (ref.current) {
    ref.current.focus()
  }
}
