import { useEffect, useState } from 'react'

import { useFlags } from 'hooks/useFlags'

import type { PipelineStatus } from '../useEosApi/types/eos'
import { useGetRootPipelineActivity } from '../useGetRootPipelineActivity/useGetRootPipelineActivity'

const COMPLETED_STATUSES: PipelineStatus[] = [
  'SUCCESS',
  'FAILED',
  'CANCELLED',
  'SKIPPED'
]

export const useGetPollableRootPipelineActivity = (
  pollInterval: number | undefined,
  pipelineId: string
) => {
  const { enableTaskDetailsPolling } = useFlags()

  const [shouldPoll, setShouldPoll] = useState<boolean>(
    enableTaskDetailsPolling
  )

  // We let React Query handle the polling for us by configuring the query with a refetch interval if
  // we are still waiting for the root pipeline to complete, or leaving it undefined if we are not
  const refetchInterval = shouldPoll ? pollInterval : undefined

  const useGetRootPipelineActivityResponse = useGetRootPipelineActivity(
    pipelineId,
    {
      refetchInterval,
      refetchOnMount: 'always'
    }
  )

  useEffect(() => {
    const rootPipelineComplete = COMPLETED_STATUSES.includes(
      useGetRootPipelineActivityResponse?.data?.result?.status ?? 'UNKNOWN'
    )

    if (rootPipelineComplete) {
      setShouldPoll(false)
    }
  }, [useGetRootPipelineActivityResponse?.data?.result?.status])

  return {
    ...useGetRootPipelineActivityResponse,
    isPolling: shouldPoll
  }
}
