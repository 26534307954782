import { Fragment, useState, type FunctionComponent } from 'react'

import classnames from 'classnames'

import classes from './InteractiveTabs.module.scss'
import { TabList } from './TabList'
import { type TabsProps } from './types'

export const InteractiveTabs: FunctionComponent<TabsProps> = ({
  className,
  listClassName,
  contentClassName,
  tabFormat = 'compact',
  children,
  initialIndex = 0,
  activeIndex,
  onChange,
  listContainer,
  contentContainer,
  onCloseAll
}) => {
  const [internalValue, setInternalValue] = useState<number>(initialIndex)
  const currentIndex = activeIndex ?? internalValue
  const ListContainer = listContainer ?? Fragment
  const ContentContainer = contentContainer ?? Fragment

  return (
    <div
      className={classnames(
        classes.InteractiveTabs,
        classes[`InteractiveTabs--${tabFormat as string}`],
        className
      )}
    >
      <ListContainer>
        <TabList
          currentTabIndex={currentIndex}
          listClassName={listClassName}
          onChange={onChange}
          setInternalTabIndexValue={setInternalValue}
          onCloseAll={onCloseAll}
        >
          {children}
        </TabList>
      </ListContainer>

      <ContentContainer>
        <div className={contentClassName} role="tabpanel">
          {children[currentIndex]}
        </div>
      </ContentContainer>
    </div>
  )
}
