import { getComponentName } from 'job-lib/job-functions/getComponentName'
import { getAllJobConnections } from 'job-lib/job-functions/job-functions'
import {
  type ComponentInstanceId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import {
  type ComponentDependencyDataBag,
  type ValidationQueryCache
} from '../types'

export const getComponentDependencyData = (
  job: OrchestrationJob | TransformationJob,
  componentInstanceId: ComponentInstanceId,
  validationQueryCache: ValidationQueryCache
): ComponentDependencyDataBag => {
  // all connections that target this provided componentInstanceId
  const connections = getAllJobConnections(job).filter(
    ({ targetID }) => componentInstanceId === targetID
  )

  const dependencies = connections
    .map(({ sourceID }) => getComponentName(job.components[sourceID]))
    .filter(Boolean)

  const cache: ComponentDependencyDataBag['cache'] = {}

  dependencies.forEach((componentName) => {
    if (!validationQueryCache[componentName]) {
      return
    }

    cache[componentName] = validationQueryCache[componentName].onSuccess
  })

  return {
    cache
  }
}
