import { useState } from 'react'

import { type AutoCompleteItemStringId } from 'components/AutoComplete/types'

import { type ElementCollection } from 'job-lib/types/Parameters'

import {
  initGridColumns,
  initGridVariable
} from '../../GridEditor/components/Grid/utils'

interface UseGridVariableEditorProps {
  isUsingGridVariable: boolean
  elements: ElementCollection
  columnNames: string[]
}
export const useGridVariableEditor = ({
  isUsingGridVariable,
  elements,
  columnNames
}: UseGridVariableEditorProps) => {
  const [columnValues, setColumnValues] = useState<
    Record<string, AutoCompleteItemStringId>
  >(() => (isUsingGridVariable ? initGridColumns(elements, columnNames) : {}))
  const [selectedGridVariable, setSelectedGridVariable] =
    useState<AutoCompleteItemStringId>(() =>
      isUsingGridVariable ? initGridVariable(elements) : { id: '', name: '' }
    )

  return {
    columnValues,
    setColumnValues,
    selectedGridVariable,
    setSelectedGridVariable
  } as const
}
