import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { Cell, Row, type RowProps } from '../components/PipelineTable'
import classes from './PipelineTreeHeadRow.module.scss'

export interface Props extends Omit<RowProps, 'children'> {
  cellClassName?: string
}

export const PipelineTreeHeadRow = ({ cellClassName, ...props }: Props) => {
  const { t } = useTranslation()
  return (
    <Row {...props}>
      <Cell
        type="head"
        className={classNames(
          classes.PipelineTreeHead__PipelineColumn,
          cellClassName
        )}
      >
        {t('pipelineRunExplorer.tableHeaders.pipeline')}
      </Cell>
      <Cell className={cellClassName} type="head">
        {t('pipelineRunExplorer.tableHeaders.component')}
      </Cell>
      <Cell className={cellClassName} type="head">
        {t('pipelineRunExplorer.tableHeaders.started')}
      </Cell>
      <Cell className={cellClassName} type="head">
        {t('pipelineRunExplorer.tableHeaders.duration')}
      </Cell>
      <Cell className={cellClassName} type="head">
        {t('pipelineRunExplorer.tableHeaders.row')}
      </Cell>
      <Cell className={cellClassName} type="head">
        {t('pipelineRunExplorer.tableHeaders.message')}
      </Cell>
    </Row>
  )
}
