import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './CopilotIntroHeader.module.scss'

const CopilotIntroHeader = () => {
  const { t } = useTranslation()
  return (
    <>
      <Typography format="ts" weight="bold">
        {t('copilot.intro.title')}
      </Typography>
      <div className={classes.Intro__SubHeader}>
        <Typography className={classes.Intro__SubHeader__Text} format="bcl">
          {t('copilot.intro.subtitle')}
        </Typography>
      </div>
    </>
  )
}

export default CopilotIntroHeader
