import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import { type LoadStrategy } from 'modules/ParameterEditors/components/MultiTableConfigurationEditor/types'

import { type ConflictGridRow, type SetConflictRow } from '../../types'
import { ConflictsGrid } from '../ConflictsGrid/ConflictsGrid'

interface ConflictsProps {
  conflictsData: ConflictGridRow[]
  loadStrategy: LoadStrategy
  onConfigDataChange: SetConflictRow
}

export const Conflicts: FC<ConflictsProps> = ({
  conflictsData,
  loadStrategy,
  onConfigDataChange
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Alert
        data-testid="conflict-alert"
        title={t('multiTableConfig.applyToAll.conflictsAlert.title', {
          count: conflictsData.length
        })}
        message={t('multiTableConfig.applyToAll.conflictsAlert.message')}
        type="error"
      />
      <ConflictsGrid
        data={conflictsData}
        loadStrategy={loadStrategy}
        onChange={onConfigDataChange}
      />
    </>
  )
}
