import { type FC } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ReactComponent as ArrowBack } from 'assets/arrow-back.svg'

import classes from './NameCell.module.scss'

interface NameCellProps {
  containerName: string
  isParentNameFile: boolean
  parentName: string
  isFolder: boolean
  onClick: () => void
  upOneLevel: () => void
}

const NameCell: FC<NameCellProps> = ({
  containerName,
  isParentNameFile,
  parentName,
  isFolder,
  onClick,
  upOneLevel
}) => {
  return (
    <button
      type="button"
      className={classNames(classes.Cell, {
        [classes.FirstCell]: containerName === '... /'
      })}
      onClick={containerName === '... /' ? upOneLevel : onClick}
    >
      <div className={classes.Icon}>
        {containerName === '... /' ? (
          <>
            <ArrowBack data-testid={'arrow-back-icon'} />
            <Typography className={classes.FolderPath} format="bcs" as="span">
              {containerName}
            </Typography>
            {isParentNameFile ? (
              <Icon.File
                className={classes.FileIcon}
                data-testid={'file-icon'}
              />
            ) : (
              <Icon.FolderSmall data-testid={'folder-small-icon'} />
            )}
            <Typography format="bcs" as="span">
              {parentName}
            </Typography>
          </>
        ) : isFolder ? (
          <Icon.FolderSmall data-testid={'folder-small-icon'} />
        ) : (
          <Icon.File className={classes.FileIcon} data-testid={'file-icon'} />
        )}
      </div>
      {containerName !== '... /' && (
        <Typography className={classes.Name} format="bcs">
          {containerName}
        </Typography>
      )}
    </button>
  )
}

export default NameCell
