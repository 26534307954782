import { type FunctionComponent, type PropsWithChildren } from 'react'

import { Loader } from '@matillion/component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { ProjectValidationError } from './ProjectValidationError'

const ValidateProjectPermissions: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { error: projectError, isLoading: isLoadingProject } = useGetProject()
  const {
    error: permissionError,
    isLoading: isLoadingPermission,
    hasPermission
  } = useProjectPermission('view_pipelines', PermissionType.PROJECT)

  if (isLoadingProject || isLoadingPermission) {
    return <Loader />
  }

  if (projectError) {
    return (
      <ProjectValidationError errorTextKey="projectValidationError.missingProjectError" />
    )
  }

  if (!hasPermission || permissionError) {
    return (
      <ProjectValidationError errorTextKey="projectValidationError.missingPermissionError" />
    )
  }

  return <>{children}</>
}

export default ValidateProjectPermissions
