import { useCallback, useEffect, useState, type FormEventHandler } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Field,
  Typography
} from '@matillion/component-library'
import { heap } from '@matillion/hub-client'

import { useStackingModal } from 'components/StackingModalProvider'
import { TextLink } from 'components/TextLink/TextLink'

import { getDocumentationLink } from 'modules/ComponentProperties/getDocumentationLink'

import classes from './DemoDataModal.module.scss'

const FAKE_FORM_TIMEOUT = 300

interface DemoDataModalProps {
  componentId: string
  onSelectComponent?: (id: string) => Promise<void>
}

export const DemoDataModal = ({
  componentId,
  onSelectComponent
}: DemoDataModalProps) => {
  const { closeAll } = useStackingModal()
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean
  }>({
    permissions: false,
    time: false,
    knowledge: false,
    security: false,
    assessment: false
  })

  useEffect(() => {
    heap.track('Designer - Demo Data Form Modal Displayed')
  }, [])

  const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      localStorage.setItem('isDemoDialogDismissed', 'true')

      heap.track(
        'Designer - Use Demo Data Form Submission',
        Object.fromEntries(
          Object.entries(checkboxes).map(([key, value]) => [
            `Demo Data Experiment Form Option: ${key}`,
            value
          ])
        )
      )
      /**
       * without the timeout ui feels *too* responsive, this is added to give
       * the feeling as though the form is actually doing something
       */
      onSelectComponent?.(componentId)
      setTimeout(closeAll, FAKE_FORM_TIMEOUT)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkboxes]
  )
  const { t } = useTranslation()
  return (
    <form onSubmit={handleFormSubmit}>
      <Typography
        as="p"
        format="ts"
        className={classes.DemoDataModal__Modal__Title}
        data-testid="demo-data-painted-door-title"
      >
        {t('demoDataModal.title')}
      </Typography>
      <Typography format="bcs" weight="bold">
        {t('demoDataModal.description')}
      </Typography>

      <div className={classes.DemoDataModal__Modal__CheckboxContainer}>
        {Object.entries(checkboxes).map(([displayName, checked]) => (
          <Field
            key={displayName}
            name={displayName}
            text={t(`translation:demoDataModal.options.${displayName}`)}
            checked={checked}
            inputComponent={Checkbox}
            onChange={() => {
              setCheckboxes((previousValue) => ({
                ...previousValue,
                [displayName]: !previousValue[displayName]
              }))
            }}
          />
        ))}
      </div>

      <Typography className={classes.DemoDataModal__Modal__FooterText}>
        <Trans
          t={t}
          i18nKey="demoDataModal.footer.text"
          components={{
            HereLink: (
              <TextLink
                link={getDocumentationLink(componentId)}
                text={t('demoDataModal.footer.links.here')}
              />
            ),
            LearnMoreLink: (
              <TextLink
                link={getDocumentationLink(componentId)}
                text={t('demoDataModal.footer.links.learnMore')}
              />
            )
          }}
        />
      </Typography>
      <Button
        className={classes.DemoDataModal__Modal__Button}
        type="submit"
        size="md"
        iconAlign="right"
        text="Submit"
        data-testid="submit-btn"
        data-tracker-id="designer-modal-demo-data-form-submit-button"
      />
    </form>
  )
}
