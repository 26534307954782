import { useEffect, useMemo, useState } from 'react'

import { FileType, type File } from '@matillion/git-component-library'
import {
  configureStore,
  createListenerMiddleware,
  type Store
} from '@reduxjs/toolkit'
import * as Y from 'yjs'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries'

import { jobActions } from 'job-lib/store'
import rootReducer, { type RootState } from 'job-lib/store/store'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import {
  bind,
  ReduxYjsTransactionOrigin,
  ROOT_MAP_NAME
} from 'modules/redux-yjs-bindings/src'

import { useSaveJobListener } from '../useSaveJobListener'

const listenerMiddleware = createListenerMiddleware<RootState>()

const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware)
  })

const EMPTY_STORE = makeStore()

export interface UseStoreProps {
  job?: OrchestrationJob | TransformationJob | null
  summary?: File | null
  captureTimeout?: number
}

export interface UseStoreResult {
  store: Store
  undoManager: Y.UndoManager | null
  doc: Y.Doc | null
}

const INIT_RESULT = {
  store: EMPTY_STORE,
  doc: null,
  undoManager: null
}

/**
 * useStore hook creates and manages a redux store for a given job
 * it also manages the yjs document that backs it, and the connected undo manager
 *
 * the store is initialized with empty job data, and is updated when the job changes
 *
 * The store is also saved to a global store, so that if the same job is closed and reopened
 */
export const useStore = ({
  job,
  summary,
  captureTimeout
}: UseStoreProps = {}) => {
  const [result, setResult] = useState<UseStoreResult>(INIT_RESULT)
  const docStore: Record<JobSummaryId, [Store, Y.Doc, Y.UndoManager]> = useMemo(
    () => ({}),
    []
  )

  useSaveJobListener(listenerMiddleware)

  useEffect(() => {
    if (!job || !summary?.type) {
      setResult(INIT_RESULT)
      return
    }

    let doc: Y.Doc
    let _undoManager: Y.UndoManager
    let _store: Store
    let newDoc = false

    if (docStore[summary.name]) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[_store, doc, _undoManager] = docStore[summary.name]
    } else {
      newDoc = true
      doc = new Y.Doc()
      _undoManager = new Y.UndoManager(doc.getMap(ROOT_MAP_NAME), {
        trackedOrigins: new Set([ReduxYjsTransactionOrigin.SYNC]),
        captureTimeout,
        // experimental flag from here: https://github.com/yjs/yjs/issues/390#issuecomment-1079649112
        // this means that we can track multiple undo actions on the same key. Fix for bugs:
        // https://matillion.atlassian.net/browse/DPCD-823 and https://matillion.atlassian.net/browse/DPCD-899
        ignoreRemoteMapChanges: true
      })
      _store = makeStore()
      bind(doc, _store, 'job')
      docStore[summary.name] = [_store, doc, _undoManager]
    }

    _store.dispatch(
      jobActions.setJob({
        job,
        jobType:
          summary.type === FileType.ORCHESTRATION_PIPELINE
            ? JobType.Orchestration
            : JobType.Transformation
      })
    )

    // so we can't undo to an empty document
    if (newDoc) {
      _undoManager.clear()
    }

    setResult({
      store: _store,
      doc,
      undoManager: _undoManager
    })
    // we only need the jobId and the type so do not need to specify the whole job summary
    // specifying the whole summary means the store becomes out of sync when the job summaries are re-fetched
    // because the reference to the job summary changes.
  }, [job, summary?.name, summary?.type, docStore, captureTimeout])

  return result
}
