import { Table as T } from '@matillion/component-library'

import { type JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

import ImportRecordHeader, {
  type ImportType
} from 'modules/migration/ConversionTool/components/ImportRecordHeader/ImportRecordHeader'

import classes from './JobImportReport.module.scss'
import JobReportRecord from './JobReportRecord'

export interface JobImportReportProps {
  id: string
  importType: ImportType
  data: JobConversionRecord[]
  translationId: string
  openMappings?: () => void
}

const JobImportReport = ({
  id,
  importType,
  data,
  translationId,
  openMappings
}: JobImportReportProps) => {
  return (
    <T.Table key={id} className={classes.table} data-testid={`${id}-table`}>
      <T.TableHead data-testid={`${id}-header`}>
        <T.TableRow className={classes.header}>
          <ImportRecordHeader
            data={data}
            importType={importType}
            translationId={translationId}
            openMappings={openMappings}
          />
        </T.TableRow>
      </T.TableHead>

      <T.TableBody>
        {data.map((dataItem) => (
          <JobReportRecord
            key={`${id}-${dataItem.name}`}
            id={id}
            jobRecord={dataItem}
            hasVariablesNotConverted={data.some(
              (item) => item.variablesNotConverted.length > 0
            )}
          />
        ))}
      </T.TableBody>
    </T.Table>
  )
}

export default JobImportReport
