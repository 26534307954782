import { useMemo } from 'react'

import { type ComponentInstanceId } from 'job-lib/types/Job'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'

export const useComponentParameterValidationError = ({
  componentInstanceId,
  parameterSlot
}: {
  componentInstanceId: ComponentInstanceId
  parameterSlot: number
}) => {
  const { failures } = useComponentValidationResult(componentInstanceId)

  return useMemo(
    () =>
      failures?.find((failure) => failure.path.at(1) === String(parameterSlot)),

    [failures, parameterSlot]
  )
}
