import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EditableText, Icon, Typography } from '@matillion/component-library'

import { IconButton } from 'components/IconButton/IconButton'

import classes from './EditableEditorHeader.module.scss'

interface EditableEditorHeaderProps {
  title: string
  subtitle: string
  onBack?: () => void
  onUpdateTableName: (
    dataSourceName: string,
    newTargetTableName: string
  ) => void
  isTableRenameConflict: boolean
}

export const EditableEditorHeader: FC<EditableEditorHeaderProps> = ({
  title,
  subtitle,
  onBack,
  onUpdateTableName,
  isTableRenameConflict
}) => {
  const { t } = useTranslation()
  const handleTableNameChange = (newName: string) => {
    onUpdateTableName(subtitle, newName)
  }

  return (
    <div className={classes.EditableEditorHeader__HeaderRowContainer}>
      <div className={classes.EditableEditorHeader__HeaderRow}>
        {onBack && (
          <div className={classes.EditableEditorHeader__BackArrowContainer}>
            <IconButton
              data-testid="column-config-back-btn"
              label="Back"
              onClick={onBack}
            >
              <Icon.ChevronDown />
            </IconButton>
          </div>
        )}
        <div className={classes.EditableEditorHeader__HeaderTitle}>
          <div className={classes.EditableEditorHeader__HeaderTitleContainer}>
            {!!title && (
              <div className={classes.EditableEditorHeader__HeaderTableName}>
                <EditableText
                  className={classes.EditableEditorHeader_EditableText}
                  text={title}
                  onChange={handleTableNameChange}
                />
              </div>
            )}
            {isTableRenameConflict && (
              <div className={classes.EditableEditorHeader__HeaderTitleError}>
                <Icon.Error />
                <Typography format="bcs" weight="normal">
                  {t('multiTableConfig.configureColumns.renameTableConflict')}
                </Typography>
              </div>
            )}
          </div>
          {!!subtitle && (
            <div className={classes.EditableEditorHeader__HeaderTableSubtitle}>
              <Typography format="bcs" weight="normal">
                {subtitle}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
