import React from 'react'

import { Typography } from '@matillion/component-library'

const footerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'static',
  right: 25,
  bottom: 25,
  top: '95%',
  left: 25,
  justifyContent: 'space-between',
  height: 10
}

const footerTextStyle: React.CSSProperties = {
  fontSize: '0.8rem',
  textAlign: 'left'
}

const ConversionReportFooter = () => {
  return (
    <div style={footerStyle}>
      <Typography as="span" style={footerTextStyle}>
        version 1.0
      </Typography>
    </div>
  )
}

export default ConversionReportFooter
