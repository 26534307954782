import {
  useGetFileSummariesQueryKey,
  useGitQueryClient,
  type GetFileSummariesItem
} from '@matillion/git-component-library'
import { useMutation } from '@tanstack/react-query'
import { type Job } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import useGetProject from '../useGetProject/useGetProject'

export interface UseSaveJob {
  job: Job
}

export type SaveJobResponse = GetFileSummariesItem

export const fileSummariesQueryUpdater = (
  response: SaveJobResponse,
  summaries?: GetFileSummariesItem[]
) => {
  if (!summaries) {
    return undefined
  }

  const summaryIndex = summaries.findIndex((summary) => {
    return summary.name === response.name
  })

  if (summaries[summaryIndex].status === response.status) {
    return summaries
  }

  const updatedSummaries = [...summaries]

  updatedSummaries[summaryIndex] = response

  return updatedSummaries
}

export const useSaveJob = () => {
  const { projectId, branchId, jobSummaryId } = useProjectInfo()
  const { saveJob } = useDesignerApi()
  const gitQueryClient = useGitQueryClient()
  const fileSummariesQueryKey = useGetFileSummariesQueryKey()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  const { mutateAsync } = useMutation(
    async ({ job }: UseSaveJob) => {
      const { data } = await saveJob(
        warehouse,
        agentVersion,
        projectId,
        branchId,
        jobSummaryId,
        job
      )
      return data
    },
    {
      onSuccess: async (response) => {
        gitQueryClient.setQueryData<GetFileSummariesItem[]>(
          fileSummariesQueryKey,
          (summaries) => fileSummariesQueryUpdater(response, summaries)
        )
      }
    }
  )

  return mutateAsync
}
