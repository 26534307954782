import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type ParameterValue } from 'types/Pipeline'

import {
  EditorType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { useGetProject } from 'api/hooks/useGetProject/useGetProject'

import { useFlags } from 'hooks/useFlags'

import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'
import {
  componentIdParameter,
  inputIdParameter,
  profileParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { type ComponentInstance } from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import { type ComponentParameterBag } from 'modules/ComponentParameters/types'
import { getComponentParameterKey } from 'modules/ComponentParameters/utils/getComponentParameterKey'
import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { convertParameterValueToMetl } from 'modules/core/WorkingCopyProvider/effects/convertDPLPipelineToMETL/convertParametersToMetl'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { ComponentParameter as Parameter } from '../ComponentParameter/ComponentParameter'
import { ComponentParameterGroup } from '../ComponentParameterGroup/ComponentParameterGroup'
import { getParameterName } from './getParameterName'

interface HiddenParameterConfig {
  warehouse: string
  hiddenParamIds: string[]
}

interface ComponentParameterItemProps {
  allowGroupLabels?: boolean
  parameter: ComponentParameter
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  parameterPath?: string[]
  onEdit: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
  onBlur: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
  omitParameters?: string[]
  scrollableContainerSelector: string
}

export const ComponentParameterItem: FC<ComponentParameterItemProps> = ({
  allowGroupLabels,
  parameter,
  componentInstance,
  componentMetadata,
  componentSummaryId,
  parameterPath = [],
  onEdit,
  onBlur,
  omitParameters = [],
  scrollableContainerSelector
}) => {
  const { t } = useTranslation()

  const isDPLInstance = isDPLParameterCollection(componentInstance.parameters)
  const parameterSlot = parameter.metlSlot
  const parameterId = parameter.dplID
  const parameterDataType = parameter.dataType

  const isVisible = isMetlParameterVisible(
    isDPLInstance ? parameterId : parameterSlot,
    componentInstance.parameters,
    componentMetadata,
    parameterPath
  )

  const project = useGetProject()
  const warehouse = project?.data?.warehouse ?? ''
  const { 'operations-hidden-params': operationsHiddenParams } = useFlags()

  // we wouldn't normally handle visibility of a component inside of itself, it would be the responsibility of the parent
  // we have to include this check here due to the recursive nature of this component
  if (!isVisible) {
    return null
  }

  const { parameterName, shouldHideParameterGroupName } = getParameterName(
    componentSummaryId,
    parameter,
    parameterPath,
    t,
    warehouse
  )

  if (
    parameter.editorType === EditorType.PARAMETER_GROUP ||
    parameter.editorType === EditorType.CONNECTION_EDITOR ||
    parameter.editorType === EditorType.SHARED_PIPELINE_SELECTOR
  ) {
    return (
      <ComponentParameterGroup
        key={`parameter-group-${parameterId}`}
        label={parameterName}
        showLabel={!!allowGroupLabels && !shouldHideParameterGroupName}
      >
        {parameter.childProperties
          ?.filter((p) => !omitParameters.includes(p.dplID))
          .flatMap((childProperty) => {
            return renderComponentParameterItem(childProperty, [
              ...parameterPath,
              parameterId
            ])
          })}
      </ComponentParameterGroup>
    )
  }

  const parameterValue = isDPLInstance
    ? getParameterValue(componentInstance.parameters, [
        ...parameterPath,
        parameterId
      ])
    : undefined

  /*
   * editors use the METL element collection to know what values to render
   * DPL enabled components such as modular connectors are embedded into the METL model in a single parameter slot
   * to support DPL enabled components we need to convert the embedded DPL values into METL format before they get passed to the editor
   */
  const parameterElements =
    // Check specifically for undefined because null is a valid ParameterValue
    parameterValue !== undefined
      ? convertParameterValueToMetl(
          parameter,
          // the conversion from parameter value to METL expects a DPL parameter value
          // this is extracting the parameter value out of the embedded DPL in the METL
          parameterValue
        )
      : componentInstance.parameters?.[parameterSlot]?.elements ?? {}

  const parameterValues = Object.values(parameterElements).map(
    (el) => el.values[1]?.value
  )
  const isModular = isModularFlexOrCustom(componentSummaryId)

  const hiddenParamsConfig = operationsHiddenParams ?? [
    { warehouse: 'databricks', hiddenParamIds: [] },
    { warehouse: 'snowflake', hiddenParamIds: [] },
    { warehouse: 'redshift', hiddenParamIds: [] }
  ]
  const hiddenParamsByFlagPerWarehouse = (
    hiddenParamsConfig as unknown as HiddenParameterConfig[]
  ).find((elem) => elem.warehouse === warehouse)

  const isHiddenbyFlag =
    hiddenParamsByFlagPerWarehouse !== undefined
      ? hiddenParamsByFlagPerWarehouse.hiddenParamIds.includes(
          `${componentSummaryId}.${parameterId}`
        )
      : false

  /* HACK: these are two known parameter ID's specific to modular connectors that we need populated in the DPL but not displayed to the user
   * DPL does not store hidden fields so they cannot be visibleWhen rules. This is a temporary solution that will be fixed by
   * https://matillion.atlassian.net/browse/DPCD-1480
   */
  const isVisuallyHiddenParameterId =
    isDPLInstance &&
    ((isModular &&
      [componentIdParameter, inputIdParameter, profileParameter].includes(
        parameter.dplID
      )) ||
      isHiddenbyFlag)

  const parameterBag: ComponentParameterBag = {
    parameterSlot,
    componentSummaryId,
    parameterName,
    id: parameter.dplID,
    isOptional: parameter.optional,
    isVisuallyHidden: isVisuallyHiddenParameterId,
    parameterMetadata: parameter,
    value: parameterValues,
    visible: isVisible,
    elements: parameterElements,
    parameterValue
  }

  return (
    <Parameter
      key={getComponentParameterKey(componentInstance.id, parameterId)}
      parameter={parameterBag}
      componentInstance={componentInstance}
      componentMetadata={componentMetadata}
      path={[...parameterPath, parameterId]}
      onEdit={(
        editedValue: ElementCollection | ParameterValue,
        editorColumns?: EditorColumn[]
      ) => {
        onEdit({
          parameterSlot,
          parameterDataType,
          editedValue,
          editorColumns,
          parameterPath: [...parameterPath, parameterId]
        })
      }}
      onBlur={(
        editedValue: ElementCollection,
        editorColumns?: EditorColumn[]
      ) => {
        onBlur({
          parameterSlot,
          parameterDataType,
          editedValue,
          editorColumns,
          parameterPath: [...parameterPath, parameterId]
        })
      }}
      scrollableContainerSelector={scrollableContainerSelector}
    />
  )

  function renderComponentParameterItem(
    componentParameter: ComponentParameter,
    path: string[]
  ) {
    return (
      <ComponentParameterItem
        allowGroupLabels={allowGroupLabels}
        key={`parameter-${componentParameter.dplID}`}
        parameter={componentParameter}
        componentSummaryId={componentSummaryId}
        componentInstance={componentInstance}
        componentMetadata={componentMetadata}
        onEdit={onEdit}
        onBlur={onBlur}
        parameterPath={path}
        scrollableContainerSelector={scrollableContainerSelector}
      />
    )
  }
}
