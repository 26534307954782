import { type FunctionComponent } from 'react'

import { FileIcon } from '@matillion/git-component-library'
import { t } from 'i18next'

import { ReactComponent as TaskIconSvg } from 'assets/TaskIcon.svg'

import { TaskType, type EtlDocumentTypes } from '../types'

const TaskTypeIcon: FunctionComponent = () => (
  <TaskIconSvg title={t('fileIcons.taskDetails')} data-testid="task-job-icon" />
)

interface TabIconProps {
  documentType: EtlDocumentTypes
}

export const EtlDocumentTabIcon: FunctionComponent<TabIconProps> = ({
  documentType
}) =>
  documentType === TaskType.FlowSummary ? (
    <TaskTypeIcon />
  ) : (
    <FileIcon type={documentType} />
  )
