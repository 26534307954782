import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useStore, type OnResizeEnd, type OnResizeStart } from 'reactflow'

import { getNoteReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useFlags } from 'hooks/useFlags'

import { jobActions } from 'job-lib/store'

import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

export const useResizeNote = ({ id }: { id: number }) => {
  const dispatch = useDispatch()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)
  const [resizing, setResizing] = useState(false)
  const { resizingWidth, resizingHeight } = useStore(
    (state) => {
      const node = state.nodeInternals.get(getNoteReactFlowId(id))

      return {
        resizingWidth: node?.width,
        resizingHeight: node?.height
      }
    },
    (a, b) =>
      a.resizingHeight === b.resizingHeight &&
      a.resizingWidth === b.resizingWidth
  )

  const onResizeStart: OnResizeStart = () => {
    setResizing(true)
  }

  const onResizeEnd: OnResizeEnd = (_, params) => {
    const { x, y, width, height } = params

    if (rolloutEnableWorkingCopyProvider) {
      update((state) => {
        state.updateNote({
          id: String(id),
          partialNote: { position: { x, y }, size: { width, height } }
        })
      })
    } else {
      dispatch(
        jobActions.updateNote({
          id,
          partialNote: {
            position: {
              x,
              y
            },
            size: {
              width,
              height
            }
          }
        })
      )
    }
    setResizing(false)
  }

  return {
    resizingWidth,
    resizingHeight,
    onResizeStart,
    onResizeEnd,
    resizing
  }
}
