import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, MicroCta, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { EmptyPlaceholder } from 'components/EmptyPlaceholder'

import { AddIcon } from './AddIcon'
import classes from './EntriesList.module.scss'
import { RemoveIcon } from './RemoveIcon'

interface NamedEntry {
  name: string
  elementSlot: number
}

export interface EntriesListProps {
  value: NamedEntry[]
  activeIndex?: number
  onSelect: (index: number) => unknown
  onAdd?: () => void
  onRemove?: (index: number) => unknown
  emptyMessage: string
  readOnly?: boolean
}

export const EntriesList: FC<EntriesListProps> = ({
  value,
  activeIndex,
  onSelect,
  onAdd = () => undefined,
  onRemove = () => undefined,
  emptyMessage,
  readOnly = false
}) => {
  const { t } = useTranslation()
  return (
    <div className={classes.Wrapper}>
      {value.length ? (
        <ul
          className={classnames(classes.List, {
            [classes['List--ReadOnly']]: readOnly
          })}
        >
          {value.map((entry, index) => {
            return (
              <li
                key={`${entry.elementSlot}-${index.toString()}-${entry.name}`}
                className={classnames(classes.Item, {
                  [classes['Item--Active']]: activeIndex === index
                })}
                onClick={() => onSelect(index)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSelect(index)
                  }
                }}
                tabIndex={0}
              >
                <Typography
                  as="span"
                  format="bcs"
                  className={classnames({
                    [classes.NamePlaceholder]: !entry.name
                  })}
                >
                  {entry.name || t('common.unnamed')}
                </Typography>
                <Icon.ArrowLeft
                  aria-hidden="true"
                  className={classes.ItemArrow}
                />
              </li>
            )
          })}
        </ul>
      ) : (
        <EmptyPlaceholder className={classes.EmptyPlaceholder}>
          {emptyMessage}
        </EmptyPlaceholder>
      )}
      {!readOnly && (
        <div className={classes.Actions}>
          <MicroCta
            className={classes.Actions__Button}
            aria-label={t('common.add')}
            onClick={onAdd}
          >
            <AddIcon />
          </MicroCta>
          <MicroCta
            className={classes.Actions__Button}
            aria-label={t('common.remove')}
            disabled={!value.length}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick={() => onRemove(activeIndex!)}
          >
            <RemoveIcon />
          </MicroCta>
        </div>
      )}
    </div>
  )
}
