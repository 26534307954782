import { type FC, type HTMLProps } from 'react'

import { Icon, Typography } from '@matillion/component-library'

import classes from './RowError.module.scss'

const { Error: ErrorIcon } = Icon

interface Props extends HTMLProps<HTMLDivElement> {
  text: string
}

export const RowError: FC<Props> = ({ text, ...props }) => {
  return (
    <div className={classes.RowError} {...props}>
      <ErrorIcon className={classes.RowError__Icon} />
      <Typography className={classes.RowError__Text} format="mc">
        {text}
      </Typography>
    </div>
  )
}
