import { useContext } from 'react'

import { useStore } from 'zustand'

import { ActiveComponentInfoContext } from '../components/ActiveComponentInfoProvider/ActiveComponentInfoProvider'

export const useActiveComponentInfo = () => {
  const store = useContext(ActiveComponentInfoContext)

  if (!store) {
    throw new Error(
      'useActiveComponentInfo must be used within an ActiveComponentInfoProvider'
    )
  }

  return useStore(store)
}
