import { PipelineType, type Pipeline } from 'types/Pipeline'

const DEFAULT_PIPELINE: Pipeline = {
  type: PipelineType.Orchestration,
  version: '1.0',
  pipeline: {
    components: {},
    variables: {}
  },
  design: {
    components: {},
    notes: {}
  }
}

export const setPipeline =
  (state: Pipeline) =>
  (
    // newPipeline can either be a full Pipeline, or a Pipeline missing the key `design`
    newPipeline: Omit<Pipeline, 'design'> & Pick<Partial<Pipeline>, 'design'>
  ) => {
    /*
     * We need to directly overwrite keys on the state as the object we get here is mapped
     * directly onto a YJS doc by syncedstore using a Proxy. If we were to recreate the object,
     * this mapping would be lost
     */
    Object.assign(state, DEFAULT_PIPELINE, newPipeline)
  }
