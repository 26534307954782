import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'
import { heap, ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { ReactComponent as CloudCredentialsIcon } from 'assets/cloud-credentials.svg'

import { TextLink } from 'components/TextLink/TextLink'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { StorageEditorType } from 'modules/ParameterEditors/types'

import { getProjectExplorerLink } from 'utils/getProjectExplorerLink'

import classes from '../../UrlEditor.module.scss'
import { SortOrder, type GridColumns, type GridRow } from '../hooks/useGrid'
import ContainerGrid from './ContainerGrid'

interface ContainerListProps {
  isLoading: boolean
  columns: GridColumns
  rows: GridRow[]
  setSort: (sort: SortOrder) => void
  storageEditorType: StorageEditorType
  isError: boolean
}

const ContainerList = ({
  isLoading,
  columns,
  rows,
  setSort,
  storageEditorType,
  isError
}: ContainerListProps) => {
  const { enableS3StorageUrlEditorErrorExperiment } = useFlags()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { projectId } = useProjectInfo()
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setSort(SortOrder.ASC)
    }
  }, [setSort])

  const showS3Error =
    enableS3StorageUrlEditorErrorExperiment &&
    storageEditorType === StorageEditorType.S3 &&
    isError &&
    !isLoading

  return (
    <div className={classes.Buckets}>
      <div className={classes.Buckets__TableContainer}>
        {isLoading && (
          <div className={classes.Buckets__LoadingContainer}>
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && showS3Error && (
          <div className={classes.Buckets__LoadingContainer}>
            <div className={classes.Buckets__NoPreview}>
              <CloudCredentialsIcon
                className={classes.Buckets__NoPreviewElement}
              />
              <Typography
                format="bcl"
                className={classes.Buckets__NoPreviewElement}
              >
                {t('parameterEditor.S3_URL.noPreview.title')}
              </Typography>
              <Typography
                format="bcs"
                className={classes.Buckets__NoPreviewElement}
              >
                <Trans
                  t={t}
                  i18nKey="parameterEditor.S3_URL.noPreview.message"
                  components={{
                    HereLink: (
                      <TextLink
                        onClick={() => {
                          heap.track('S3 Error Credentials Link Clicked', {
                            linkLocation: 'Buckets List'
                          })
                          window.open(
                            `${getProjectExplorerLink(
                              projectExplorerOrigin,
                              projectId
                            )}/cloud-provider-credentials`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }}
                        text={t('parameterEditor.S3_URL.noPreview.linkText')}
                        className={classes.Buckets__NoPreviewCredentialsLink}
                      />
                    )
                  }}
                />
              </Typography>
              <Typography format="mc">
                {t('parameterEditor.S3_URL.noPreview.note')}
              </Typography>
            </div>
          </div>
        )}
        {!isLoading && !showS3Error && (
          <ContainerGrid columns={columns} rows={rows} setSort={setSort} />
        )}
      </div>
    </div>
  )
}

export default ContainerList
