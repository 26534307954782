import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries/types'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import * as heap from 'utils/heap'

export interface SelectedTab {
  type: 'job' | 'task'
  id: string
}
export interface LocationState {
  selectedTabs: SelectedTab[]
}

const getNewTabs = (
  targetTabId: string,
  type: 'job' | 'task',
  tabs: SelectedTab[]
): SelectedTab[] => {
  return tabs.some((t) => t.id === targetTabId && t.type === type)
    ? tabs
    : [...tabs, { id: targetTabId, type }]
}

export const useSelectedJobs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    jobSummaryId,
    flowInstanceId,
    projectId,
    branchId,
    environmentId,
    agentId
  } = useProjectInfo()
  const selectedTabs: SelectedTab[] = useMemo(() => {
    const tabs = location.state?.selectedTabs
    if (!tabs) {
      if (jobSummaryId) {
        return [{ type: 'job', id: jobSummaryId }]
      } else if (flowInstanceId) {
        return [{ type: 'task', id: flowInstanceId }]
      } else {
        return []
      }
    } else {
      return tabs
    }
  }, [location, flowInstanceId, jobSummaryId])
  const selectedJobs = useMemo(
    () => selectedTabs.filter((t) => t.type === 'job'),
    [selectedTabs]
  )
  const selectedTasks = useMemo(
    () => selectedTabs.filter((t) => t.type === 'task'),
    [selectedTabs]
  )

  const navigateToProject = useCallback(
    (newSelectedTabState: SelectedTab[] = selectedTabs) => {
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedTabs: newSelectedTabState
          }
        }
      )
    },
    [
      agentId,
      branchId,
      environmentId,
      navigate,
      projectId,
      location.state,
      selectedTabs
    ]
  )

  const navigateToJob = useCallback(
    (jobId: string, tabs: SelectedTab[] = selectedTabs) => {
      const newSelectedTabs: SelectedTab[] = getNewTabs(jobId, 'job', tabs)
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobId
          )}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedTabs: newSelectedTabs
          }
        }
      )
    },
    [
      agentId,
      branchId,
      environmentId,
      navigate,
      projectId,
      location.state,
      selectedTabs
    ]
  )

  const navigateToComponent = useCallback(
    (componentId?: string) => {
      const path = componentId ? `/component/${componentId}` : ''

      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobSummaryId
          )}${path}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: { ...location.state }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      jobSummaryId,
      environmentId,
      agentId,
      location.state
    ]
  )

  const navigateToJobWithSelectedComponent = useCallback(
    (jobId: JobSummaryId, componentId: string) => {
      const newSelectedTabs: SelectedTab[] = getNewTabs(
        jobId,
        'job',
        selectedTabs
      )
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/job/${encodeURIComponent(
            jobId
          )}/component/${encodeURIComponent(componentId)}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedTabs: newSelectedTabs
          }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      environmentId,
      agentId,
      location.state,
      selectedTabs
    ]
  )

  const navigateToTask = useCallback(
    (taskId: string, tabs: SelectedTab[] = selectedTabs) => {
      const newSelectedTabs: SelectedTab[] = getNewTabs(taskId, 'task', tabs)
      navigate(
        {
          pathname: `/project/${projectId}/branch/${branchId}/task/${taskId}`,
          search: `environmentId=${environmentId}&agentId=${agentId}`
        },
        {
          state: {
            ...location.state,
            selectedTabs: newSelectedTabs
          }
        }
      )
    },
    [
      navigate,
      projectId,
      branchId,
      environmentId,
      agentId,
      selectedTabs,
      location.state
    ]
  )
  const closeTab = useCallback(
    (tab: SelectedTab) => {
      const currentIndex = selectedTabs.findIndex(
        (t) => tab.id === t.id && tab.type === t.type
      )
      if (currentIndex === -1) {
        return
      }

      const newTabs = selectedTabs.filter((_t, index) => index !== currentIndex)
      if (newTabs.length === 0) {
        navigateToProject([])
        return
      }

      const onTab =
        (jobSummaryId === tab.id && tab.type === 'job') ||
        (flowInstanceId === tab.id && tab.type === 'task')

      if (!onTab) {
        navigate(location, {
          state: {
            ...location.state,
            selectedTabs: newTabs
          }
        })
      } else {
        const newTab = selectedTabs[currentIndex - 1] ?? newTabs[0]

        if (newTab.type === 'job') {
          navigateToJob(newTab.id, newTabs)
        } else {
          navigateToTask(newTab.id, newTabs)
        }
      }
    },
    [
      jobSummaryId,
      flowInstanceId,
      location,
      navigate,
      navigateToJob,
      navigateToTask,
      navigateToProject,
      selectedTabs
    ]
  )
  const closeAll = useCallback(() => {
    navigateToProject([])
  }, [navigateToProject])

  /**
   * Close all tabs except for the tab provided
   * @param selectedTabIndex The index of the tab to keep open
   */
  const closeOthers = useCallback(
    (selectedTabIndex: number) => {
      const selectedTab = selectedTabs.find(
        (_, index) => index === selectedTabIndex
      )

      if (!selectedTab) {
        return
      }

      const isActiveTab =
        (jobSummaryId === selectedTab.id && selectedTab.type === 'job') ||
        (flowInstanceId === selectedTab.id && selectedTab.type === 'task')

      const newTabs = [selectedTab]

      /**
       * If the selected tab is the active one just update the selected tab state
       */
      if (isActiveTab) {
        navigate(location, {
          state: {
            ...location.state,
            selectedTabs: [selectedTab]
          }
        })
        return
      }

      /**
       * If the selected tab is not the one selected then navigate to it
       */
      if (selectedTab.type === 'job') {
        navigateToJob(selectedTab.id, newTabs)
      } else {
        navigateToTask(selectedTab.id, newTabs)
      }
    },
    [
      jobSummaryId,
      flowInstanceId,
      selectedTabs,
      location,
      navigate,
      navigateToJob,
      navigateToTask
    ]
  )

  /**
   * Invalidates any stale selected jobs that no
   * longer exist on the current branch. E.g. after
   * pulling remote changes, some open jobs might have
   * been deleted.
   * @param newJobIds A list of all job ids on the branch
   */
  const invalidateStaleSelectedJobs = useCallback(
    (newJobIds: string[]) => {
      const areNoStaleJobs = selectedJobs.every((selected) =>
        newJobIds.includes(selected.id)
      )

      if (areNoStaleJobs || selectedJobs.length === 0) {
        return
      }

      const availableJobs = selectedJobs.filter((selected) =>
        newJobIds.includes(selected.id)
      )

      if (availableJobs.length === 0) {
        closeAll()
        return
      }
      const newTabs = selectedTabs.filter(
        (t) => t.type !== 'job' || newJobIds.includes(t.id)
      )
      if (availableJobs.map((x) => x.id).includes(jobSummaryId)) {
        navigateToJob(jobSummaryId, newTabs)
      } else {
        const activeJob = availableJobs[availableJobs.length - 1]
        navigateToJob(activeJob.id, newTabs)
      }
    },
    [closeAll, jobSummaryId, navigateToJob, selectedJobs, selectedTabs]
  )
  const reorderTabs = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      heap.track('etld_tab-reorder')
      const newTabs = [...selectedTabs]
      const [movedTab] = newTabs.splice(sourceIndex, 1)
      newTabs.splice(destinationIndex, 0, movedTab)

      navigate(location, {
        state: {
          ...location.state,
          selectedTabs: newTabs
        }
      })
    },
    [location, navigate, selectedTabs]
  )
  return {
    navigateToProject,
    navigateToJob,
    navigateToJobWithSelectedComponent,
    navigateToComponent,
    navigateToTask,
    closeTab,
    closeAll,
    closeOthers,
    selectedJobs,
    selectedTasks,
    selectedTabs,
    invalidateStaleSelectedJobs,
    reorderTabs
  }
}
