import {
  LookUpType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import type { EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { useDependentParameterValue } from 'hooks/useDependentParameterValue/useDependentParameterValue'

import type { ComponentInstanceId } from 'job-lib/types/Job'

import {
  DynamicUrlEditorKnownStorageTypes,
  StorageEditorType
} from 'modules/ParameterEditors/types'

import { UrlEditor } from '../UrlEditor/UrlEditor'

export interface DynamicUrlEditorProps {
  editorColumns: EditorColumn[]
  parameterName: string
  parameter: ComponentParameter
  value: string[]
  onDone: (editedValue: string[]) => void
  componentMetaData: ComponentMetadata
  componentId: ComponentInstanceId | null
}

const checkValueRelevantToEditor = (
  value: string[],
  editorType: StorageEditorType
): string[] => {
  if (value !== undefined && value.length !== 0) {
    switch (editorType) {
      case StorageEditorType.S3:
        return value[0].startsWith('s3://') ? value : []
      case StorageEditorType.AZURE:
        return value[0].startsWith('azure://') ? value : []
      case StorageEditorType.GCS:
        return value[0].startsWith('gs://') ? value : []
    }
  }
  return value
}

export const DynamicUrlEditor = ({
  editorColumns,
  parameterName,
  parameter,
  value,
  onDone,
  componentMetaData,
  componentId
}: DynamicUrlEditorProps) => {
  let editorType = StorageEditorType.S3
  const dependentParameter = 'storageType'
  // in dynamic url editor the lookup type is file type
  const fileType = parameter.lookupType
  let lookupType

  const dependentValue = useDependentParameterValue(
    componentMetaData,
    componentId,
    dependentParameter
  )

  switch (dependentValue) {
    case DynamicUrlEditorKnownStorageTypes.AMAZON_S3_STORAGE:
      editorType = StorageEditorType.S3
      lookupType = LookUpType.DYNAMIC_S3_FOLDERS_AND_OBJECTS
      break
    case DynamicUrlEditorKnownStorageTypes.AZURE_BLOB_STORAGE:
      editorType = StorageEditorType.AZURE
      lookupType = LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS
      break
    case DynamicUrlEditorKnownStorageTypes.GOOGLE_CLOUD_STORAGE:
      editorType = StorageEditorType.GCS
      lookupType = LookUpType.DYNAMIC_GCS_FOLDERS_AND_OBJECTS
      break
  }

  return (
    <UrlEditor
      storageEditorType={editorType}
      editorColumns={editorColumns}
      parameterName={parameterName}
      parameter={parameter}
      value={checkValueRelevantToEditor(value, editorType)}
      lookupType={lookupType}
      fileType={fileType}
      onDone={onDone}
    />
  )
}
