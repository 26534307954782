import { createModularConnectorConfig } from './common/layout/ModularConnectorConfig'
import { type ComponentLayout } from './types'

const destinationParametersList = [
  'outputId',
  'databricks-output-connector-v0',
  'redshift-output-connector-v0',
  'snowflake-output-connector-v0',
  'storage-only-output-v0',
  'oracle-output-connector-v0'
]
export const RedshiftConfig: ComponentLayout = createModularConnectorConfig(
  'redshift-input-v0',
  {
    showAdvanced: false,
    overrideDefaultDestinationParametersList: destinationParametersList
  }
)
