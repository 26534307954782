import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'

export const useGetProject = () => {
  const { projectId } = useProjectInfo()
  const { getProject } = useProjectExplorerApi()

  return useQuery(
    [queryKeys.project, projectId],
    async () => {
      const { data } = await getProject(projectId)

      return data
    },
    { retry: 0 }
  )
}

export default useGetProject
