import {
  useCanCopy,
  useCanPaste
} from 'file-editors/canvas/modules/CopyPasteComponent/CopyPasteContext'

import { isMacOs } from 'utils/isMacOs'

import {
  type ContextMenuItem,
  type ContextMenuSection
} from '../CanvasContextMenu'

interface UseActionsSectionProps {
  hasSelectedNodes: boolean
  isRightClickedCustomConnector: boolean
}

export const useActionsSection = ({
  hasSelectedNodes,
  isRightClickedCustomConnector
}: UseActionsSectionProps): ContextMenuSection => {
  const canCopy = useCanCopy()
  const canPaste = useCanPaste()
  const items: ContextMenuItem[] = [
    {
      id: 'copy',
      disabled: !canCopy,
      shortcut: isMacOs()
        ? 'translation:shortcuts.commandC_macOS'
        : 'translation:shortcuts.controlC'
    },
    {
      id: 'paste',
      disabled: !canPaste,
      shortcut: isMacOs()
        ? 'translation:shortcuts.commandV_macOS'
        : 'translation:shortcuts.controlV'
    },
    {
      id: 'delete',
      disabled: !hasSelectedNodes,
      shortcut: 'translation:shortcuts.delete'
    }
  ]

  if (isRightClickedCustomConnector) {
    items.unshift({ id: 'editCustomConnector' })
  }

  return {
    id: 'actions',
    items
  }
}
