import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  Table as T,
  Typography
} from '@matillion/component-library'

import {
  type JobConversionRecord,
  type VariableConversionRecord
} from 'api/hooks/useMigrationMetlJob'

import { useFlags } from 'hooks/useFlags'

import classes from './ImportRecordHeader.module.scss'

export type ImportType = 'Success' | 'Warning' | 'Error'

export interface RecordHeaderProps {
  data: JobConversionRecord[] | VariableConversionRecord[]
  importType: ImportType
  translationId: string
  openMappings?: () => void
}

const RecordHeader = ({
  data,
  importType,
  translationId,
  openMappings
}: RecordHeaderProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.modal.results'
  })

  const { enableMigrationToolMappings } = useFlags()

  const ImportIcon = useMemo(() => {
    switch (importType) {
      case 'Success':
        return <Icon.Success />
      case 'Warning':
        return <Icon.Warning />
      case 'Error':
        return <Icon.Error />
    }
  }, [importType])

  return (
    <>
      <T.TableHeaderCell width="1" align="left" className={classes.headerIcon}>
        {ImportIcon}
      </T.TableHeaderCell>
      <T.TableHeaderCell width="7" className={classes.headerContainer}>
        <span className={classes.headerCell}>
          <Typography
            format="bcs"
            weight="bold"
            className={classes.headerTitle}
          >
            {t(`${translationId}.title`)}
          </Typography>
          <Typography format="bcs">{t(`${translationId}.subtitle`)}</Typography>
        </span>
      </T.TableHeaderCell>
      <T.TableHeaderCell width="4" align="right">
        {enableMigrationToolMappings && openMappings && (
          <Button
            className={classes.mappingLink}
            alt="text"
            size="md"
            text={t('mappingsTriggerLinkText')}
            onClick={() => {
              openMappings()
            }}
            data-testid="conversion-tool-add-mappings"
          />
        )}
        <Typography format="bcs">({data.length})</Typography>
      </T.TableHeaderCell>
    </>
  )
}

export default RecordHeader
