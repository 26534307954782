import { type ParameterValue } from 'types/Pipeline'

import { isElementCollection } from 'job-lib/job-functions/elements'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { isScalarParameterValue } from 'modules/core/WorkingCopyProvider/utils/parameters'

export const getActualEditedValue = (
  editedValue: ElementCollection | ParameterValue
): string | undefined => {
  if (isElementCollection(editedValue)) {
    return editedValue[1]?.values[1]?.value
  }

  if (isScalarParameterValue(editedValue)) {
    return editedValue
  }
}
