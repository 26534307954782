import { useCreateFile } from '@matillion/git-component-library'
import { useMutation } from '@tanstack/react-query'
import { PipelineType, type Pipeline } from 'types/Pipeline'
import YAML from 'yaml'

import { useGetComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'

import { addComponent } from 'modules/core/WorkingCopyProvider/mutations'

const createEmptyPipeline = (pipelineType: PipelineType) => ({
  type: pipelineType,
  version: '1.0',
  pipeline: {
    components: {},
    variables: {}
  },
  design: {
    components: {},
    notes: {}
  }
})
interface CreatePipelineProps {
  type: PipelineType
  name: string
}
export const useCreatePipeline = () => {
  const { data: componentMetadata } = useGetComponentMetadata('start')
  const { createFile, isError, isSuccess, isLoading } = useCreateFile()

  const create = useMutation(async ({ type, name }: CreatePipelineProps) => {
    let pipeline: Pipeline

    if (type === PipelineType.Transformation) {
      pipeline = createEmptyPipeline(PipelineType.Transformation)
    } else {
      pipeline = createEmptyPipeline(PipelineType.Orchestration)

      if (componentMetadata) {
        addComponent(pipeline)({
          componentId: 'start',
          componentName: 'Start',
          componentMetadata: componentMetadata.metadata,
          componentDesign: {
            position: {
              x: 0,
              y: 0
            }
          }
        })
      }
    }
    const yamlDocument: string = new YAML.Document(pipeline).toString()

    await createFile({
      path: name,
      contents: yamlDocument
    })

    return pipeline
  })

  return {
    mutateAsync: create.mutateAsync,
    isLoading,
    isError,
    isSuccess
  }
}
