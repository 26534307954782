import { useTranslation } from 'react-i18next'

import { Checkbox, Field } from '@matillion/component-library'

import {
  UseVariable,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import classes from './GridVariableSwitcher.module.scss'

interface GridVariableSwitcherProps {
  parameter: ComponentParameter
  disabled: boolean
  checked: boolean
  setChecked: (checked: boolean) => void
}

export const GridVariableSwitcher = ({
  parameter,
  disabled,
  checked,
  setChecked
}: GridVariableSwitcherProps) => {
  const { t } = useTranslation()

  return parameter.useVariable === UseVariable.YES ? (
    <div className={classes.GridVariableSwitcher}>
      <Field
        disabled={disabled}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setChecked(e.target.checked)
        }}
        text={t('parameterOverlay.useGridVariable')}
        inputComponent={Checkbox}
      />
    </div>
  ) : null
}
