import { ParameterDataType } from '../api/hooks/useGetComponentMetadata/types'
import type { OrchestrationJob } from '../job-lib/types/Job'

export const newOrchestrationJob: OrchestrationJob = {
  revision: 0,
  components: {
    1: {
      id: 1,
      implementationID: 444132438,
      parameters: {
        1: {
          slot: 1,
          name: 'componentName',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Start'
                }
              }
            }
          },
          visible: true
        }
      },
      exportMappings: {},
      gridExportMappings: {},
      inputConnectorIDs: [],
      outputSuccessConnectorIDs: [],
      outputFailureConnectorIDs: [],
      outputUnconditionalConnectorIDs: [],
      outputTrueConnectorIDs: [],
      outputFalseConnectorIDs: [],
      outputIterationConnectorIDs: [],
      inputIterationConnectorIDs: [],
      x: 0,
      y: 0,
      width: 32,
      height: 32
    }
  },
  successConnectors: {},
  failureConnectors: {},
  unconditionalConnectors: {},
  trueConnectors: {},
  falseConnectors: {},
  iterationConnectors: {},
  variables: {},
  grids: {},
  notes: {}
}
