import { type ComponentWithMetadata } from '../../../api/hooks/copilot/model'
import { type ValidateComponentParsedResponse } from '../../../api/hooks/useValidateComponent/types'

export const toSelectedComponentWithColumnMetadata = (
  componentName: string,
  validationResponse: ValidateComponentParsedResponse | null
): ComponentWithMetadata => {
  if (!validationResponse) {
    return {
      componentName,
      columns: []
    }
  }
  if (!validationResponse.onSuccess) {
    return {
      componentName,
      columns: []
    }
  }
  const columns = validationResponse.onSuccess.columns.map((column) => {
    return {
      columnName: column.name,
      type: column.datatype.type,
      isIdentity: column.isIdentity
    }
  })
  return {
    componentName,
    columns
  }
}
