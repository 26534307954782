import { type FC } from 'react'

import { Icon, Typography } from '@matillion/component-library'

import { IconButton } from 'components/IconButton/IconButton'

import classes from './EditorHeader.module.scss'

interface EditorHeaderProps {
  title?: string
  subtitle?: string
  onBack?: () => void
}

export const EditorHeader: FC<EditorHeaderProps> = ({
  title,
  subtitle,
  onBack
}) => {
  return (
    <div className={classes.EditorHeader__HeaderRowContainer}>
      <div className={classes.EditorHeader__HeaderRow}>
        {onBack && (
          <div className={classes.EditorHeader__BackArrowContainer}>
            <IconButton
              data-testid="column-config-back-btn"
              label="Back"
              onClick={onBack}
            >
              <Icon.ChevronDown />
            </IconButton>
          </div>
        )}
        <div className={classes.EditorHeader__HeaderTitle}>
          {!!title && (
            <div className={classes.EditorHeader__HeaderTableName}>
              <Typography format="bcm" weight="bold">
                {title}
              </Typography>
            </div>
          )}
          {!!subtitle && (
            <div className={classes.EditorHeader__HeaderTableSubtitle}>
              <Typography format="bcs" weight="normal">
                {subtitle}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
