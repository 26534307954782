import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useDesignerApi } from '../useDesignerApi/useDesignerApi'

const useSchemas = (warehouse: string) => {
  const { projectId, environmentId } = useProjectInfo()
  const { getSchemas } = useDesignerApi()

  return useQuery(
    [projectId, environmentId, queryKeys.listSchemas],
    async () => {
      const { data } = await getSchemas(projectId, environmentId, warehouse)
      return data
    },
    {
      enabled: !!warehouse
    }
  )
}

export default useSchemas
