import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'

const useListBranches = () => {
  const { projectId } = useProjectInfo()
  const { getBranches } = useDesignerApi()

  return useQuery([projectId, queryKeys.branches], async () => {
    const { data } = await getBranches(projectId)

    return data
  })
}

export default useListBranches
