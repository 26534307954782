import { forwardRef, type HTMLProps, type Ref } from 'react'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './PipelineTable.module.scss'

export interface Props extends HTMLProps<HTMLTableCellElement> {
  ref?: Ref<HTMLTableCellElement>
  /** type controls what sort of table cell to use, i.e. head uses a th and cell uses a td  */
  type?: 'head' | 'cell'
  /** Protect against content wrapping in this cell. */
  noWrap?: boolean
  /** Make this cell full width */
  fullWidth?: boolean
}

export const PipelineTableCell = forwardRef<HTMLTableCellElement, Props>(
  (
    {
      type: semantics = 'cell',
      className,
      children,
      noWrap,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const Component = semantics === 'head' ? 'th' : 'td'
    const child =
      typeof children === 'string' ? (
        <Typography
          weight={semantics === 'head' ? 'bold' : 'normal'}
          format="mc"
        >
          {children}
        </Typography>
      ) : (
        children
      )

    return (
      <Component
        {...props}
        colSpan={fullWidth ? 1000 : undefined}
        className={classNames(
          {
            [classes.PipelineTableCell]: true,
            [classes['PipelineTableCell--head']]: semantics === 'head',
            [classes['PipelineTableCell--no-wrap']]: noWrap,
            [classes['PipelineTableCell--full-width']]: fullWidth
          },
          className
        )}
        ref={ref}
      >
        {child}
      </Component>
    )
  }
)

PipelineTableCell.displayName = 'PipelineTableCell'
