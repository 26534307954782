import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

interface CreateDPLParametersParams {
  componentName: string
  componentParameters?: ComponentParameter[]
  initialValueOverrides?: { [key: string]: string | null | undefined }
}

interface ParameterDPL {
  componentName: string
  [key: string]: { [key: string]: string } | string | null
}

export const createDPLParameters = ({
  componentName,
  componentParameters = [],
  initialValueOverrides = {}
}: CreateDPLParametersParams): ParameterDPL => {
  const createParameters = (
    parameterDPL: ParameterDPL,
    parameter: ComponentParameter,
    parentDplPath?: string[]
  ) => {
    parameterDPL[parameter.dplID] = parameterDPL[parameter.dplID] ?? {}
    const currentPath = [...(parentDplPath ?? []), parameter.dplID]
    if (parameter.childProperties) {
      parameter.childProperties?.forEach((childParameter) => {
        createParameters(
          parameterDPL[parameter.dplID] as keyof object,
          childParameter,
          currentPath
        )
      })
    } else {
      let newValue = parameter.defaultValue ?? null
      const fullPathKey = currentPath.join('.')

      if (fullPathKey in initialValueOverrides) {
        newValue = initialValueOverrides[fullPathKey] ?? null
      }

      parameterDPL[parameter.dplID] = newValue
    }

    return parameterDPL
  }

  return componentParameters
    .filter((param) => param.dplID !== 'componentName')
    .reduce(
      (parameterDPL, parameter) => createParameters(parameterDPL, parameter),
      {
        componentName
      }
    )
}
