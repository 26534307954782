import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type RootState } from 'job-lib/store'
import {
  type JobStateNull,
  type JobStateOrchestration,
  type JobStateTransformation
} from 'job-lib/store/jobSlice/job.types'

import {
  WorkingCopyContext,
  type WorkingCopyContextBag
} from '../components/WorkingCopyProvider/context'

export type JobStateExtend<E> =
  | (JobStateNull & E)
  | (JobStateTransformation & E)
  | (JobStateOrchestration & E)

export type WorkTreeDetails = JobStateExtend<WorkingCopyContextBag>

export const useWorkingCopy = (): WorkTreeDetails => {
  const jobState = useSelector<RootState, RootState['job']>(
    (state) => state.job
  )
  const workingCopyStatus = useContext(WorkingCopyContext)

  return useMemo(() => {
    const workingCopy: WorkTreeDetails = {
      ...jobState,
      ...workingCopyStatus
    }

    if (workingCopyStatus.isLoading) {
      workingCopy.job = null
      workingCopy.jobType = null
    }

    return workingCopy
  }, [jobState, workingCopyStatus])
}
