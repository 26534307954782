// When adding component summaries, please see here for more information
// on the recommended properties to add
// https://matillion.atlassian.net/wiki/spaces/SETL/pages/4208918567/Component+descriptions+filter+and+tags+guidance

interface ComponentSummary {
  displayName: string
  tags: string[]
  synonyms: string[]
  filter: string[]
  description: string
}

const componentSummaries: Record<string, ComponentSummary> = {
  'add-partition': {
    displayName: 'Add Partition',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.output)',
      '$t(synonyms.external)',
      '$t(synonyms.storage)'
    ],
    filter: ['$t(filter.ddl)'],
    description:
      'Define the S3 directory structure for partitioned external table data.'
  },
  aggregate: {
    displayName: 'Aggregate',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.group-by)',
      '$t(synonyms.clause)',
      '$t(synonyms.multiple-inputs)',
      '$t(synonyms.output)',
      '$t(synonyms.grouping)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Group together multiple input rows into a single output row.'
  },
  'alter-table': {
    displayName: 'Alter Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)',
      '$t(synonyms.new-column)'
    ],
    filter: ['$t(filter.ddl)'],
    description:
      'Enable or disable Snowflake Search Optimization Service on any table.'
  },
  'alter-warehouse': {
    displayName: 'Alter Warehouse',
    tags: ['$t(tags.snowflake)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description:
      "Edit the warehouse using Snowflake's Alter Warehouse function."
  },
  and: {
    displayName: 'And',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description:
      'Waits for all of its inputs to complete before continuing the pipeline'
  },
  'analyze-tables': {
    displayName: 'Analyze Tables',
    tags: ['$t(tags.ddl)'],
    synonyms: ['$t(synonyms.data-definition-language)'],
    filter: ['$t(filter.ddl)'],
    description: 'Analyze a list of tables in a single operation.'
  },
  'append-to-grid': {
    displayName: 'Append To Grid',
    tags: ['$t(tags.output)'],
    synonyms: [],
    filter: ['$t(filter.transform)'],
    description: 'Appends data to a pre-existing grid variable'
  },
  'assert-external-table': {
    displayName: 'Assert External Table',
    tags: ['$t(tags.testing)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Confirm that an external table has been correctly created.'
  },
  'assert-scalar-variables': {
    displayName: 'Assert Scalar Variables',
    tags: ['$t(tags.testing)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Verify that a variable value is as expected.'
  },
  'assert-table': {
    displayName: 'Assert Table',
    tags: ['$t(tags.testing)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Confirm that a target table has been correctly created.'
  },
  'assert-table-v2': {
    displayName: 'Assert Table',
    tags: ['$t(tags.testing)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description:
      'Confirms that a target table has been created with the correct metadata.'
  },
  'assert-view-dl': {
    displayName: 'Assert View',
    tags: ['$t(tags.conditional)'],
    synonyms: [
      '$t(synonyms.conditional)',
      '$t(synonyms.true)',
      '$t(synonyms.false)',
      '$t(synonyms.verify)',
      '$t(synonyms.stop-query)'
    ],
    filter: ['$t(filter.testing)'],
    description:
      'Verify that certain conditions are true of a view, or otherwise stop the query.'
  },
  'assert-view-rs': {
    displayName: 'Assert View',
    tags: ['$t(tags.conditional)'],
    synonyms: [
      '$t(synonyms.conditional)',
      '$t(synonyms.true)',
      '$t(synonyms.false)',
      '$t(synonyms.verify)',
      '$t(synonyms.stop-query)'
    ],
    filter: ['$t(filter.testing)'],
    description:
      'Verify that certain conditions are true of a view, or otherwise stop the query.'
  },
  'assert-view-sf': {
    displayName: 'Assert View',
    tags: ['$t(tags.conditional)'],
    synonyms: [
      '$t(synonyms.conditional)',
      '$t(synonyms.true)',
      '$t(synonyms.false)',
      '$t(synonyms.verify)',
      '$t(synonyms.stop-query)'
    ],
    filter: ['$t(filter.testing)'],
    description: 'Verify that certain conditions are true of a view.'
  },
  'aws-ai-prompt': {
    displayName: 'Amazon Bedrock Prompt',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.open-ai)'],
    filter: ['$t(filter.ai)'],
    description:
      'Query an AWS Bedrock LLM using source data and additional context'
  },
  'azure-ai-prompt': {
    displayName: 'Azure OpenAI Prompt',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.open-ai)'],
    filter: ['$t(filter.ai)'],
    description:
      'Query an Azure OpenAI LLM using source data and additional context'
  },
  'azure-blob-storage-load': {
    displayName: 'Azure Blob Storage',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Load data from Azure Blob Storage into an existing table.'
  },
  'azure-blob-storage-load-v2': {
    displayName: 'Azure Blob Storage',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Load data into an existing table from objects stored in Azure Blob Storage.'
  },
  'azure-queue-storage-message': {
    displayName: 'Azure Queue Storage Message',
    tags: ['$t(tags.azure)'],
    synonyms: ['$t(synonyms.queue)', '$t(synonyms.message)'],
    filter: ['$t(filter.action)'],
    description: 'Post a message to an Azure Queue Storage queue.'
  },
  'azure-unload-sf': {
    displayName: 'Azure Blob Storage Unload',
    tags: ['$t(tags.destination)'],
    synonyms: [
      '$t(synonyms.unload)',
      '$t(synonyms.output)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.blob)',
      '$t(synonyms.parquet)',
      '$t(synonyms.storage)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Create files in Blob Storage and load them with data.'
  },
  'bash-script': {
    displayName: 'Bash Script',
    tags: ['$t(tags.scripting)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Execute a script and write output into the task message.'
  },
  'bing-ads-query': {
    displayName: 'Bing Ads Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Bing Ads API.'
  },
  calculator: {
    displayName: 'Calculator',
    tags: ['$t(tags.calculate)'],
    synonyms: [
      '$t(synonyms.calculate)',
      '$t(synonyms.calculation)',
      '$t(synonyms.input)',
      '$t(synonyms.output)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Add new columns by performing calculations on input rows.'
  },
  'cloudwatch-publish': {
    displayName: 'CloudWatch Publish',
    tags: ['$t(tags.aws)'],
    synonyms: ['$t(synonyms.metrics)'],
    filter: ['$t(filter.action)'],
    description: 'Publish metrics to CloudWatch.'
  },
  'commands-for-dbt-core': {
    displayName: 'dbt Core',
    tags: ['$t(tags.scripting)'],
    synonyms: [],
    filter: [],
    description: 'Run dbt commands as part of an orchestration pipeline.'
  },
  'construct-variant': {
    displayName: 'Construct Variant',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.key)',
      '$t(synonyms.value)',
      '$t(synonyms.key-value-pairs)',
      '$t(synonyms.array)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Create a column that collapses rows into key:value pairs.'
  },
  'convert-type': {
    displayName: 'Convert Type',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.convert)', '$t(synonyms.cast)'],
    filter: ['$t(filter.transform)'],
    description: 'Convert the data types of the input flow.'
  },
  'convert-type-dl': {
    displayName: 'Convert Type',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.convert)', '$t(synonyms.cast)'],
    filter: ['$t(filter.transform)'],
    description:
      'Convert the data types of the input flow. Generates a select clause, casting column types.'
  },
  'convert-type-rs': {
    displayName: 'Convert Type',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.convert)', '$t(synonyms.cast)'],
    filter: ['$t(filter.transform)'],
    description:
      'Convert the data types of the input flow. Generates a select clause, casting column types.'
  },
  'cortex-completions': {
    displayName: 'Cortex Completions',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Cortex Completions stored procedure'
  },
  'cortex-parse-document': {
    displayName: 'Cortex Parse Document',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Parse Document stored procedure'
  },
  'cortex-extract-answer': {
    displayName: 'Cortex Extract Answer',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Cortex Extract Answer stored procedure'
  },
  'cortex-sentiment': {
    displayName: 'Cortex Sentiment',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Cortex Sentiment stored procedure'
  },
  'cortex-summarize': {
    displayName: 'Cortex Summarize',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Cortex Summarize stored procedure'
  },
  'cortex-translate': {
    displayName: 'Cortex Translate',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Cortex Translate stored procedure'
  },
  'cortex-embed': {
    displayName: 'Cortex Embed',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Create embeddings from text using Cortex'
  },
  'vector-search': {
    displayName: 'Vector Search',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use Snowflake Vectors to perform a RAG search'
  },
  'document-ai-predict': {
    displayName: 'Document AI Predict',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Snowflake Document AI predict stored procedure'
  },
  'create-external-table-sf': {
    displayName: 'Create External Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Create a table that references externally stored data.'
  },
  'create-external-table-rs': {
    displayName: 'Create External Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.redshift)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Create a table that references externally stored data.'
  },
  'create-file-format': {
    displayName: 'Create File Format',
    tags: ['$t(tags.snowflake)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description:
      'Create a file format for bulk loading/unloading of Snowflake data.'
  },
  'create-stream': {
    displayName: 'Create Stream',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Create a new Snowflake Stream.'
  },
  'create-table': {
    displayName: 'Create Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Create or replace a table.'
  },
  'create-table-v2': {
    displayName: 'Create Table',
    tags: ['$t(tags.ddl)'],
    synonyms: ['$t(synonyms.data-definition-language)'],
    filter: ['$t(filter.ddl)'],
    description: 'Create or replace a table.'
  },
  'create-view': {
    displayName: 'Create View',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)'],
    filter: ['$t(filter.write)'],
    description: 'Output a view definition to your cloud data warehouse.'
  },
  'databricks-ai-analyze-sentiment': {
    displayName: 'AI Analyze Sentiment',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Analyze Sentiment stored procedure'
  },
  'databricks-ai-classify': {
    displayName: 'AI Classify',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Classify stored procedure'
  },
  'databricks-ai-extract': {
    displayName: 'AI Extract',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Extract stored procedure'
  },
  'databricks-ai-fix-grammar': {
    displayName: 'AI Fix Grammar',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Fix Grammar stored procedure'
  },
  'databricks-ai-mask': {
    displayName: 'AI Mask',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Mask stored procedure'
  },
  'databricks-ai-query': {
    displayName: 'AI Query',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Query stored procedure'
  },
  'databricks-ai-similarity': {
    displayName: 'AI Similarity',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Similarity stored procedure'
  },
  'databricks-ai-summarize': {
    displayName: 'AI Summarize',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Summarize stored procedure'
  },
  'databricks-ai-translate': {
    displayName: 'AI Translate',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.transform)', '$t(filter.ai)'],
    description: 'Use the Databricks AI Translate stored procedure'
  },
  'databricks-vector-search': {
    displayName: 'Vector Search',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.ai)', '$t(filter.transform)'],
    description: 'Search a Databricks managed index against a query table'
  },
  'data-transfer-object': {
    displayName: 'Data Transfer',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.ftp)',
      '$t(synonyms.sftp)',
      '$t(synonyms.http)',
      '$t(synonyms.https)',
      '$t(synonyms.s3)',
      '$t(synonyms.blob)',
      '$t(synonyms.windows)',
      '$t(synonyms.samba)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Transfer files from a chosen source to a chosen target.'
  },
  'database-query': {
    displayName: 'Database Query',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.mysql)',
      '$t(synonyms.oracle)',
      '$t(synonyms.redshift)',
      '$t(synonyms.db2)',
      '$t(synonyms.mariadb)',
      '$t(synonyms.postgresql)',
      '$t(synonyms.snowflake)',
      '$t(synonyms.sybase)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Run SQL queries and copy the results to a table.'
  },
  'delete-tables': {
    displayName: 'Delete Tables',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)',
      '$t(synonyms.drop)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Delete a list of tables.'
  },
  'delete-tables-v2': {
    displayName: 'Delete Tables',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.databricks)',
      '$t(synonyms.drop)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Delete a list of tables in a single operation.'
  },
  'detect-changes': {
    displayName: 'Detect Changes',
    tags: ['$t(tags.compare)'],
    synonyms: [
      '$t(synonyms.compare)',
      '$t(synonyms.difference)',
      '$t(synonyms.inserted)',
      '$t(synonyms.deleted)',
      '$t(synonyms.changed)',
      '$t(synonyms.unchanged)'
    ],
    filter: ['$t(filter.transform)'],
    description:
      'Compare two similar tables and insert a column detailing changes.'
  },
  'dev-test-component': {
    displayName: 'Test Component',
    description: '',
    tags: [],
    synonyms: [],
    filter: []
  },
  distinct: {
    displayName: 'Distinct',
    tags: ['$t(tags.compare)'],
    synonyms: [
      '$t(synonyms.compare)',
      '$t(synonyms.unique)',
      '$t(synonyms.distinct)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Pass only unique input records to the next component.'
  },
  'dynamics-365-query': {
    displayName: 'Dynamics 365 Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Dynamics 365 API.'
  },
  'dynamodb-query': {
    displayName: 'DynamoDB Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the DynamoDB API.'
  },
  'email-query': {
    displayName: 'Email Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve email data using IMAP and load into a table.'
  },
  'end-failure': {
    displayName: 'End Failure',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description: 'Mark the pipeline as failed, even if successful.'
  },
  'end-success': {
    displayName: 'End Success',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description: 'Mark the pipeline as succeeded, even if certain parts failed.'
  },
  'excel-query': {
    displayName: 'Excel Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)', '$t(synonyms.xlsx)', '$t(synonyms.xls)'],
    filter: ['$t(filter.connector)'],
    description: 'Loads data from an Excel sheet into a table.'
  },
  except: {
    displayName: 'Except',
    tags: ['$t(tags.compare)'],
    synonyms: [
      '$t(synonyms.input)',
      '$t(synonyms.output)',
      '$t(synonyms.difference)',
      '$t(synonyms.comparison)'
    ],
    filter: ['$t(filter.join)'],
    description: "Output only rows that don't exist in a comparison table."
  },
  'extract-nested-data-dl': {
    displayName: 'Extract Structured Data',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.flattens)', '$t(synonyms.nested-data)'],
    filter: ['$t(filter.transform)'],
    description: 'Flattens structured data into rows.'
  },
  'convert-string-to-struct': {
    displayName: 'Convert String To Struct',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.convert)',
      '$t(synonyms.flattens)',
      '$t(synonyms.nested-data)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Converts a json string into a struct.'
  },
  'extract-nested-data-rs': {
    displayName: 'Extract Nested Data',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.flattens)', '$t(synonyms.nested-data)'],
    filter: ['$t(filter.transform)'],
    description: 'Flattens nested data into rows.'
  },
  'extract-nested-data-sf': {
    displayName: 'Extract Nested Data',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.flattens)', '$t(synonyms.nested-data)'],
    filter: ['$t(filter.transform)'],
    description: 'Flattens nested data into rows.'
  },
  'facebook-ads-query': {
    displayName: 'Facebook Ads Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Facebook Ads API.'
  },
  'facebook-query': {
    displayName: 'Facebook Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Facebook API.'
  },
  'file-iterator': {
    displayName: 'File Iterator',
    tags: ['$t(tags.iterate)'],
    synonyms: ['$t(synonyms.loop)'],
    filter: ['$t(filter.logic)'],
    description: 'Loop over matching files in a remote file system.'
  },
  filter: {
    displayName: 'Filter',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.filter)', '$t(synonyms.subset)'],
    filter: ['$t(filter.transform)'],
    description: 'Filter input rows based on a set of conditions.'
  },
  'first-last': {
    displayName: 'First/Last',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.first)', '$t(synonyms.last)'],
    filter: ['$t(filter.transform)'],
    description: 'Create groups of data and return the first/last rows'
  },
  'fixed-flow': {
    displayName: 'Fixed Flow',
    tags: ['$t(tags.input)'],
    synonyms: [
      '$t(synonyms.varchar)',
      '$t(synonyms.number)',
      '$t(synonyms.float)',
      '$t(synonyms.boolean)',
      '$t(synonyms.date)',
      '$t(synonyms.timestamp)',
      '$t(synonyms.time)',
      '$t(synonyms.variant)',
      '$t(synonyms.select)'
    ],
    filter: ['$t(filter.read)'],
    description: 'Generate lines of fixed input, or input from variables.'
  },
  'fixed-iterator': {
    displayName: 'Fixed Iterator',
    tags: ['$t(tags.iterate)'],
    synonyms: ['$t(synonyms.loop)'],
    filter: ['$t(filter.logic)'],
    description: 'Loop over rows of fixed data values.'
  },
  'flatten-variant': {
    displayName: 'Flatten Variant',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.explodes)',
      '$t(synonyms.columns)',
      '$t(synonyms.convert)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Flattens (explodes) compound values into multiple rows.'
  },
  'gcs-load': {
    displayName: 'Google Cloud Storage Load',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.gcs)',
      '$t(synonyms.load)',
      '$t(synonyms.source)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Loads data from Google Cloud Storage into an existing table.'
  },
  'gcs-unload': {
    displayName: 'Google Cloud Storage Unload',
    tags: ['$t(tags.destination)'],
    synonyms: [
      '$t(synonyms.gcs)',
      '$t(synonyms.unload)',
      '$t(synonyms.output)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.parquet)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Create files in GCS and load them with data.'
  },
  'generate-sequence': {
    displayName: 'Generate Sequence',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.sequence)', '$t(synonyms.increment)'],
    filter: ['$t(filter.read)'],
    description: 'Generate a sequence of empty rows.'
  },
  'google-ads-qry': {
    displayName: 'Google Ads Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Google Ads API.'
  },
  'google-ads-query': {
    displayName: 'Google Ads Query',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Google Ads API to retrieve data and load it into a table.'
  },
  'google-analytics-query': {
    displayName: 'Google Analytics Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Google Analytics API.'
  },
  'google-bigquery': {
    displayName: 'Google Big Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Google BigQuery API.'
  },
  'google-sheets-query': {
    displayName: 'Google Sheets Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Google Sheets API.'
  },
  'grid-iterator': {
    displayName: 'Grid Iterator',
    filter: ['$t(filter.logic)'],
    tags: ['$t(tags.iterate)'],
    synonyms: ['$t(synonyms.loop)'],
    description: 'Loop over values of a simple sequence'
  },
  'hubspot-query': {
    displayName: 'Hubspot Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the HubSpot REST API.'
  },
  if: {
    displayName: 'If',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description:
      'Evaluate a true/false experession and direct pipeline flow accordingly.'
  },
  intersect: {
    displayName: 'Intersect',
    tags: ['$t(tags.compare)'],
    synonyms: ['$t(synonyms.identical)', '$t(synonyms.comparison)'],
    filter: ['$t(filter.join)'],
    description: 'Output only rows that are common to two input tables.'
  },
  'jira-query': {
    displayName: 'Jira Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Jira API.'
  },
  join: {
    displayName: 'Join',
    tags: ['$t(tags.combine)'],
    synonyms: [
      '$t(synonyms.combine)',
      '$t(synonyms.tables)',
      '$t(synonyms.flows)'
    ],
    filter: ['$t(filter.join)'],
    description: 'Join rows from multiple tables based on common data.'
  },
  'ldap-query': {
    displayName: 'LDAP Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the LDAP REST API.'
  },
  'lead-lag': {
    displayName: 'Lead/Lag',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.offset)'],
    filter: ['$t(filter.transform)'],
    description:
      'Determine value of a preceding/following row at a given offset.'
  },
  'loop-iterator': {
    displayName: 'Loop Iterator',
    tags: ['$t(tags.iterate)'],
    synonyms: ['$t(synonyms.loop)'],
    filter: ['$t(filter.logic)'],
    description:
      'Run a component multiple times, iterating a variable each time.'
  },
  'map-values': {
    displayName: 'Map Values',
    tags: ['$t(tags.conditional)'],
    synonyms: ['$t(synonyms.replace)', '$t(synonyms.conditional)'],
    filter: ['$t(filter.transform)'],
    description:
      'Replaces one value with another based on a specified condition.'
  },
  'marketo-query': {
    displayName: 'Marketo Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Marketo REST API.'
  },
  'microsoft-sql-server-output': {
    displayName: 'Microsoft SQL Server Output',
    tags: ['$t(tags.destination)'],
    synonyms: ['$t(synonyms.unload)', '$t(synonyms.output)'],
    filter: ['$t(filter.connector)'],
    description: 'Output the contents of a table to SQL Server.'
  },
  'modular-anaplan-input-v1': {
    displayName: 'Anaplan',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)', '$t(synonyms.file)', '$t(synonyms.csv)'],
    filter: ['$t(filter.connector)'],
    description:
      'Retrieve forecasting model data from Anaplan and load it into a chosen target.'
  },
  'modular-textract-input-v1': {
    displayName: 'Amazon Textract',
    tags: ['$t(tags.source)', '$t(tags.ai)'],
    synonyms: ['$t(synonyms.load)', '$t(synonyms.file)', '$t(synonyms.csv)'],
    filter: ['$t(filter.ai)'],
    description: 'Apply Textract to your files to extract text from documents.'
  },
  'modular-azure-document-intelligence-input-v1': {
    displayName: 'Azure Document Intelligence',
    tags: ['$t(tags.source)', '$t(tags.ai)'],
    synonyms: ['$t(synonyms.load)', '$t(synonyms.file)', '$t(synonyms.csv)'],
    filter: ['$t(filter.ai)'],
    description:
      'Apply document intelligence to your files to extract text from documents and load it into a chosen target.'
  },
  'modular-azure-sql-input-v0': {
    displayName: 'Azure SQL',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to Azure SQL via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-databricks-input-v0': {
    displayName: 'Databricks',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to Databricks via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-redshift-input-v0': {
    displayName: 'Redshift',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to Redshift via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-gmail-input-v0': {
    displayName: 'Gmail',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Gmail API to retrieve data and load it into a chosen target.'
  },
  'modular-google-sheets-input-v0': {
    displayName: 'Google Sheets',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Google Sheets API to retrieve data and load it into a chosen target.'
  },
  'modular-jdbc-input-v1': {
    displayName: 'JDBC',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses respective JDBC driver to retrieve data and load it into a chosen target.'
  },
  'modular-mailchimp-input-v0': {
    displayName: 'Mailchimp',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Retrieve data from the Mailchimp Marketing API and load it into a chosen target.'
  },
  'modular-microsoft-exchange-input-v0': {
    displayName: 'Microsoft Exchange',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Microsoft Exchange REST API to retrieve data and load it into the chosen target.'
  },
  'modular-google-analytics-input-v0': {
    displayName: 'Google Analytics',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to Google Analytics via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-jira-input-v0': {
    displayName: 'Jira',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to Jira via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-odata-input-v0': {
    displayName: 'OData',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to OData via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-net-suite-input-v0': {
    displayName: 'NetSuite SuiteAnalytics',
    tags: ['$t(tags.connector)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to NetSuite via JDBC to retrieve data and load it into a chosen target.'
  },
  'modular-salesforce-input-v0': {
    displayName: 'Salesforce',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Salesforce REST API to retrieve data and load it into the chosen target.'
  },
  'modular-load-salesforce-input-v0': {
    displayName: 'Salesforce Load',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Salesforce REST API to retrieve data and load it into the chosen target.'
  },
  'modular-sap-odp-input-v1': {
    displayName: 'SAP ODP',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the SAP ODP framework to retrieve data and load it into the chosen target.'
  },
  'modular-spotify-input-v1': {
    displayName: 'Spotify (Demo only)',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the Spotify REST API to retrieve data and load it into the chosen target.'
  },
  'modular-stub-input-connector-v0': {
    displayName: 'Stub Input Connector',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description: ''
  },
  'modular-survey-monkey-input-v0': {
    displayName: 'SurveyMonkey',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description:
      'Uses the SurveyMonkey REST API to retrieve data and load it into a chosen target.'
  },
  'modular-kafka-input-v1': {
    displayName: 'Kafka',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description:
      'Connects to a Kafka cluster to retrieve data from a topic and load it into a chosen target.'
  },
  'modular-workday-custom-reports-input-v1': {
    displayName: 'Workday Custom Reports',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.soap)',
      '$t(synonyms.rest)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Load data in custom reports from Workday into a chosen target.'
  },
  'modular-aws-transcribe-input-v1': {
    displayName: 'Amazon Transcribe',
    tags: ['$t(tags.source)', '$t(tags.ai)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.ai)'],
    description: 'Convert audio to text using the AWS Transcribe service.'
  },
  'modular-azure-speech-input-v1': {
    displayName: 'Azure Speech Transcribe',
    tags: ['$t(tags.source)', '$t(tags.ai)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.ai)'],
    description:
      'Convert audio to text using the Azure Speech Transcription service.'
  },
  'modular-workday-input-v1': {
    displayName: 'Workday',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)', '$t(synonyms.soap)'],
    filter: ['$t(filter.connector)'],
    description: 'Load data from the Workday SOAP API into a chosen target.'
  },
  'modular-redshift-oracle-output-v1': {
    displayName: 'Oracle Unload',
    tags: ['$t(tags.connector)'],
    synonyms: [],
    filter: ['$t(filter.connector)'],
    description: 'Unload data from Redshift to Oracle.'
  },
  'mongodb-query': {
    displayName: 'MongoDB Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the MongoDB REST API.'
  },
  'multi-table-input': {
    displayName: 'Multi Table Input',
    tags: ['$t(tags.input)'],
    synonyms: [
      '$t(synonyms.matching)',
      '$t(synonyms.pattern)',
      '$t(synonyms.tables)'
    ],
    filter: ['$t(filter.read)'],
    description: 'Read columns from multiple input tables into the pipeline.'
  },
  'netsuite-query': {
    displayName: 'Netsuite Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the NetSuite API.'
  },
  'odata-query': {
    displayName: 'OData Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the OData API.'
  },
  'openai-ai-prompt': {
    displayName: 'OpenAI Prompt',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.open-ai)'],
    filter: ['$t(filter.ai)'],
    description: 'Query an OpenAI LLM using source data and additional context'
  },
  optimise: {
    displayName: 'Optimize',
    tags: ['$t(tags.utility)'],
    synonyms: ['$t(synonyms.optimise)'],
    filter: ['$t(filter.action)'],
    description: 'Optimizes the layout of data.'
  },
  or: {
    displayName: 'Or',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description: 'Wait for any one input to complete before pipeline continues.'
  },
  'pinecone-vector-query': {
    displayName: 'Pinecone Vector Query',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.pinecone)'],
    filter: ['$t(filter.ai)'],
    description:
      'Retrieve semantically similar results from a source table using an embeddings provider and Pinecone'
  },
  'pinecone-vector-upsert': {
    displayName: 'Pinecone Vector Upsert',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.pinecone)'],
    filter: ['$t(filter.ai)'],
    description:
      'Insert vector embeddings into Pinecode after retrieving them from an embeddings provider'
  },
  pivot: {
    displayName: 'Pivot',
    tags: ['$t(tags.rotate)'],
    synonyms: ['$t(synonyms.aggregate)'],
    filter: ['$t(filter.transform)'],
    description: 'Turn individual values from one column into several columns.'
  },
  'postgres-vector-upsert': {
    displayName: 'Postgres Vector Upsert',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.ai)'],
    description:
      'Insert vector embeddings into Postgres after retrieving them from an embeddings provider'
  },
  'print-variables': {
    displayName: 'Print Variables',
    tags: ['$t(tags.testing)'],
    synonyms: [
      '$t(synonyms.python)',
      '$t(synonyms.variable)',
      '$t(synonyms.vars)',
      '$t(synonyms.output)',
      '$t(synonyms.scalar)',
      '$t(synonyms.grid)',
      '$t(synonyms.log)',
      '$t(synonyms.debug)'
    ],
    filter: ['$t(filter.action)'],
    description: 'Write the values of variables into the task message.'
  },
  'prompt-component': {
    displayName: 'LLM Prompt Component',
    tags: ['$t(tags.ai)', '$t(tags.prompt)'],
    synonyms: [
      '$t(synonyms.chatgpt)',
      '$t(synonyms.openai)',
      '$t(synonyms.bedrock)'
    ],
    filter: ['$t(filter.ai)'],
    description: ''
  },
  pubsub: {
    displayName: 'Cloud Pub/Sub',
    tags: ['$t(tags.gcp)'],
    synonyms: ['$t(synonyms.queue)', '$t(synonyms.message)'],
    filter: ['$t(filter.action)'],
    description: 'Post a message to a Google Cloud Pub/Sub topic.'
  },
  'python-pushdown': {
    displayName: 'Python Pushdown',
    filter: ['$t(filter.action)'],
    tags: ['$t(tags.scripting)'],
    synonyms: [
      '$t(synonyms.variable)',
      '$t(synonyms.vars)',
      '$t(synonyms.output)',
      '$t(synonyms.scalar)',
      '$t(synonyms.log)',
      '$t(synonyms.debug)',
      '$t(synonyms.pushdown)',
      '$t(synonyms.snowparks)',
      '$t(synonyms.script)'
    ],
    description: 'Execute a script and write output into the task message.'
  },
  'python-script': {
    displayName: 'Python Script',
    tags: ['$t(tags.scripting)'],
    synonyms: ['$t(synonyms.jython)'],
    filter: ['$t(filter.action)'],
    description: 'Execute a script and write output into the task message.'
  },
  'query-to-grid': {
    displayName: 'Query Result To Grid',
    filter: ['$t(filter.transform)'],
    tags: ['$t(tags.output)'],
    synonyms: [],
    description:
      'Write a custom SQL query and store the results in a grid variable.'
  },
  'query-to-scalar': {
    displayName: 'Query Result To Scalar',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)', '$t(synonyms.sql)'],
    filter: ['$t(filter.transform)'],
    description:
      'Write a custom SQL query and store the results in a scalar variable.'
  },
  rank: {
    displayName: 'Rank',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)'],
    filter: ['$t(filter.transform)'],
    description: 'Determine the rank of a value in a group.'
  },
  'rds-bulk-output': {
    displayName: 'RDS Bulk output',
    tags: ['$t(tags.destination)'],
    synonyms: ['$t(synonyms.unload)', '$t(synonyms.output)'],
    filter: ['$t(filter.connector)'],
    description: 'Load the contents of a table into an RDS table.'
  },
  'rds-query': {
    displayName: 'RDS Query',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.mysql)',
      '$t(synonyms.mariadb)',
      '$t(synonyms.postgresql)',
      '$t(synonyms.oracle)',
      '$t(synonyms.sql-server)',
      '$t(synonyms.aurora)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Run SQL queries on an RDS database.'
  },
  'refresh-external-table': {
    displayName: 'Refresh External Tables',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Refresh an external table, re-syncing with the target data.'
  },
  'refresh-materialized-view': {
    displayName: 'Refresh Materialized View',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.redshift)'
    ],
    filter: ['$t(filter.ddl)'],
    description:
      'Refreshes a selected materialized view, identifying changes to an underlying table in a database and applying those changes to the materialized view.'
  },
  'refresh-table-v2': {
    displayName: 'Refresh Table',
    tags: ['$t(tags.ddl)'],
    synonyms: ['$t(synonyms.refresh-external-table)'],
    filter: ['$t(filter.ddl)'],
    description:
      'Refresh an external table, invalidating the cached entries of the given table or view.'
  },
  'remove-from-grid': {
    displayName: 'Remove From Grid',
    tags: ['$t(tags.output)'],
    synonyms: [],
    filter: ['$t(filter.transform)'],
    description:
      "Takes in grid variable data and removes data based on the user's definition"
  },
  rename: {
    displayName: 'Rename',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.replace)'],
    filter: ['$t(filter.transform)'],
    description: 'Change field names within the data flow.'
  },
  retry: {
    displayName: 'Retry',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description: 'Repeatedly run a connected component until it succeeds.'
  },
  'rewrite-table': {
    displayName: 'Rewrite Table',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)', '$t(synonyms.overwrite)'],
    filter: ['$t(filter.write)'],
    description: 'Write the input data flow out to a new table.'
  },
  'rewrite-table-dl': {
    displayName: 'Rewrite Table',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)', '$t(synonyms.overwrite)'],
    filter: ['$t(filter.write)'],
    description: 'Write the input data flow out to a new table.'
  },
  'rewrite-table-rs': {
    displayName: 'Rewrite Table',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)', '$t(synonyms.overwrite)'],
    filter: ['$t(filter.write)'],
    description: 'Write the input data flow out to a new table.'
  },
  'run-notebook': {
    displayName: 'Run Notebook',
    tags: ['$t(tags.scripting)'],
    synonyms: ['$t(synonyms.run-notebook)'],
    filter: ['$t(filter.action)'],
    description: 'Runs an existing notebook on Databricks.'
  },
  'run-orchestration': {
    displayName: 'Run Orchestration',
    tags: ['$t(tags.run-pipeline)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Run an orchestration pipeline to completion.'
  },
  'run-shared-pipeline': {
    displayName: 'Run Shared Pipeline',
    tags: ['$t(tags.run-pipeline)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Run a shared pipeline to completion.'
  },
  'run-transformation': {
    displayName: 'Run Transformation',
    tags: ['$t(tags.run-pipeline)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Run a transformation pipeline to completion.'
  },
  's3-load': {
    displayName: 'S3 Load',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Load data from Amazon S3 into an existing table.'
  },
  's3-load-rs': {
    displayName: 'S3 Load',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.source)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Loads data into an existing table from objects stored in Amazon Simple Storage Service (Amazon S3).'
  },
  's3-load-v2': {
    displayName: 'S3 Load',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.source)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Loads data into an existing table from objects stored in Amazon Simple Storage Service (Amazon S3).'
  },
  's3-unload': {
    displayName: 'S3 Unload',
    tags: ['$t(tags.destination)'],
    synonyms: [
      '$t(synonyms.unload)',
      '$t(synonyms.output)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.s3)',
      '$t(synonyms.parquet)',
      '$t(synonyms.storage)'
    ],
    filter: ['$t(filter.connector)'],
    description: 'Create files in S3 and load them with data.'
  },
  's3-unload-v2': {
    displayName: 'S3 Unload',
    tags: ['$t(tags.destination)'],
    synonyms: [
      '$t(synonyms.unload)',
      '$t(synonyms.destination)',
      '$t(synonyms.output)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.s3)',
      '$t(synonyms.parquet)',
      '$t(synonyms.storage)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Creates files on a specified S3 bucket, and loads the bucket with data from a table or view.'
  },
  'salesforce-marketing-cloud-query': {
    displayName: 'Salesforce Marketing Cloud Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Salesforce Marketing Cloud REST API.'
  },
  'salesforce-output': {
    displayName: 'Salesforce Output',
    tags: ['$t(tags.destination)'],
    synonyms: ['$t(synonyms.unload)', '$t(synonyms.output)'],
    filter: ['$t(filter.connector)'],
    description: 'Write data into a Saleforce table using the Salesforce API.'
  },
  'salesforce-query': {
    displayName: 'Salesforce Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Salesforce API.'
  },
  'schema-copy': {
    displayName: 'Schema Copy',
    tags: ['$t(tags.ddl)'],
    synonyms: ['$t(synonyms.redshift)'],
    filter: ['$t(filter.ddl)'],
    description:
      'Copies data from one or more tables from one schema to another.'
  },
  'send-email': {
    displayName: 'Send Email',
    tags: ['$t(tags.messaging)'],
    synonyms: [
      '$t(synonyms.alert)',
      '$t(synonyms.notification)',
      '$t(synonyms.smtp)',
      '$t(synonyms.notify)'
    ],
    filter: ['$t(filter.action)'],
    description: 'Send emails upon any pipeline failure or success.'
  },
  'servicenow-query': {
    displayName: 'ServiceNow Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the ServiceNow REST API.'
  },
  'sharepoint-query': {
    displayName: 'Sharepoint Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the SharePoint APIs.'
  },
  'snowflake-vector-upsert': {
    displayName: 'Snowflake Vector Upsert',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.ai)'],
    description:
      'Insert vector embeddings into a Snowflake table after retrieving them from an embeddings provider'
  },
  'sns-message': {
    displayName: 'SNS Message',
    tags: ['$t(tags.aws)'],
    synonyms: ['$t(synonyms.notifications)', '$t(synonyms.message)'],
    filter: ['$t(filter.action)'],
    description: 'Send a simple message to an SNS topic.'
  },
  'spcs-ai-prompt': {
    displayName: 'Snowpark Container Prompt',
    tags: ['$t(tags.ai)'],
    synonyms: [],
    filter: ['$t(filter.ai)'],
    description: ''
  },
  'split-field': {
    displayName: 'Split Field',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.input)', '$t(synonyms.delimiter)'],
    filter: ['$t(filter.transform)'],
    description: 'Split a column according to a specified delimiter.'
  },
  sql: {
    displayName: 'SQL',
    tags: ['$t(tags.input)'],
    synonyms: [
      '$t(synonyms.select)',
      '$t(synonyms.database)',
      '$t(synonyms.query)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Write your own complete SQL SELECT statement.'
  },
  'sql-script': {
    displayName: 'SQL Script',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Execute one or more SQL statements.'
  },
  'sql-executor': {
    displayName: 'SQL Script',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Execute one or more SQL statements.'
  },
  'sqs-message': {
    displayName: 'SQS Message',
    tags: ['$t(tags.aws)'],
    synonyms: ['$t(synonyms.queue)', '$t(synonyms.message)'],
    filter: ['$t(filter.action)'],
    description: 'Post a message to an Amazon Simple Queue Service queue.'
  },
  start: {
    displayName: 'Start',
    tags: ['$t(tags.flow)'],
    synonyms: ['$t(synonyms.logic)'],
    filter: ['$t(filter.logic)'],
    description: 'The starting point for an orchestration pipeline.'
  },
  'start-compute': {
    displayName: 'Start Compute',
    tags: ['$t(tags.utility)'],
    synonyms: ['$t(synonyms.start-cluster)'],
    filter: ['$t(filter.action)'],
    description: 'Ensures the compute selected in the environment is running.'
  },
  'stream-input-sf': {
    displayName: 'Stream Input',
    tags: ['$t(tags.input)'],
    synonyms: ['$t(synonyms.columns)'],
    filter: ['$t(filter.read)'],
    description: 'Read chosen columns from an existing stream.'
  },
  'stripe-query': {
    displayName: 'Stripe Query',
    tags: ['$t(tags.source)'],
    synonyms: ['$t(synonyms.load)'],
    filter: ['$t(filter.connector)'],
    description: 'Retrieve data using the Stripe REST API.'
  },
  'table-delete-rows': {
    displayName: 'Table Delete Rows',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)'],
    filter: ['$t(filter.write)'],
    description:
      'Deletes rows from a target table based on matching rows to the key values identified in an input flow.'
  },
  'table-input': {
    displayName: 'Table Input',
    tags: ['$t(tags.input)'],
    synonyms: [
      '$t(synonyms.input)',
      '$t(synonyms.tables)',
      '$t(synonyms.views)'
    ],
    filter: ['$t(filter.read)'],
    description: 'Read columns from an input table into the pipeline.'
  },
  'table-iterator': {
    displayName: 'Table Iterator',
    tags: ['$t(tags.iterate)'],
    synonyms: ['$t(synonyms.loop)'],
    filter: ['$t(filter.logic)'],
    description: 'Run a component on each row of data.'
  },
  'table-output': {
    displayName: 'Table Output',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)'],
    filter: ['$t(filter.write)'],
    description: 'Write an input data flow to an existing output table.'
  },
  'table-update': {
    displayName: 'Table Update',
    tags: ['$t(tags.output)'],
    synonyms: ['$t(synonyms.output)'],
    filter: ['$t(filter.write)'],
    description: 'Update a target table with a set of input rows.'
  },
  'transpose-columns': {
    displayName: 'Transpose Columns',
    tags: ['$t(tags.output)'],
    synonyms: [
      '$t(synonyms.input)',
      '$t(synonyms.output)',
      '$t(synonyms.unpivot)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Map input columns into new output columns (unpivot).'
  },
  'transpose-rows': {
    displayName: 'Transpose Rows',
    tags: ['$t(tags.combine)'],
    synonyms: [
      '$t(synonyms.input)',
      '$t(synonyms.output)',
      '$t(synonyms.concatenate)',
      '$t(synonyms.delimiter)'
    ],
    filter: ['$t(filter.transform)'],
    description: 'Combine multiple rows into a single output row.'
  },
  'truncate-table': {
    displayName: 'Truncate Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.snowflake)',
      '$t(synonyms.empty)',
      '$t(synonyms.clear)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Remove all rows from a table, keeping the table intact.'
  },
  'truncate-table-v2': {
    displayName: 'Truncate Table',
    tags: ['$t(tags.ddl)'],
    synonyms: [
      '$t(synonyms.data-definition-language)',
      '$t(synonyms.redshift)',
      '$t(synonyms.databricks)',
      '$t(synonyms.empty)',
      '$t(synonyms.clear)'
    ],
    filter: ['$t(filter.ddl)'],
    description: 'Removes all rows from a table, keeping the table intact.'
  },
  unite: {
    displayName: 'Unite',
    tags: ['$t(tags.combine)'],
    synonyms: ['$t(synonyms.combine)'],
    filter: ['$t(filter.join)'],
    description: 'Combine all the rows from multiple tables.'
  },
  unpivot: {
    displayName: 'Unpivot',
    tags: ['$t(tags.rotate)'],
    synonyms: ['$t(synonyms.operator)'],
    filter: ['$t(filter.transform)'],
    description: 'Rotate a table by transforming columns into rows.'
  },
  'update-scalar': {
    displayName: 'Update Scalar',
    tags: ['$t(tags.scripting)'],
    synonyms: [
      '$t(synonyms.python)',
      '$t(synonyms.variable)',
      '$t(synonyms.vars)',
      '$t(synonyms.input)',
      '$t(synonyms.scalar)'
    ],
    filter: ['$t(filter.action)'],
    description: 'Update the values of scalar variables'
  },
  'vacuum-tables': {
    displayName: 'Vacuum',
    tags: ['$t(tags.utility)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Removes unused files associated with a Delta table.'
  },
  'vector-upsert': {
    displayName: 'Vector Upsert',
    tags: ['$t(tags.ai)', '$t(tags.vector)'],
    synonyms: [],
    filter: ['$t(filter.ai)'],
    description: ''
  },
  'volume-to-delta-table': {
    displayName: 'Volume to Delta Table',
    tags: ['$t(tags.source)'],
    synonyms: [
      '$t(synonyms.load)',
      '$t(synonyms.source)',
      '$t(synonyms.file)',
      '$t(synonyms.storage)',
      '$t(synonyms.csv)',
      '$t(synonyms.json)',
      '$t(synonyms.avro)'
    ],
    filter: ['$t(filter.connector)'],
    description:
      'Loads data into an existing table from objects stored in a Unity Catalog volume.'
  },
  'webhook-post': {
    displayName: 'Webhook Post',
    tags: ['$t(tags.messaging)'],
    synonyms: [],
    filter: ['$t(filter.action)'],
    description: 'Send a payload or message to a unique URL.'
  },
  'window-calculation': {
    displayName: 'Window Calculation',
    tags: ['$t(tags.calculate)'],
    synonyms: ['$t(synonyms.function)'],
    filter: ['$t(filter.transform)'],
    description: 'Calculate using a Snowflake window function.'
  },
  'table-metadata-to-grid': {
    displayName: 'Table Metadata To Grid',
    tags: ['$t(tags.output)'],
    synonyms: [],
    filter: ['$t(filter.transform)'],
    description: 'Takes metadata from a table to populate a grid variable.'
  },
  'jdbc-table-metadata-to-grid': {
    displayName: 'JDBC Table Metadata To Grid',
    tags: ['$t(tags.output)'],
    synonyms: [],
    filter: ['$t(filter.transform)'],
    description:
      'Takes the metadata from a JDBC table to populate a grid variable.'
  },
  'jdbc-metadata-to-grid-v2': {
    displayName: 'JDBC Table Metadata To Grid',
    tags: ['$t(tags.output)'],
    synonyms: [],
    filter: ['$t(filter.transform)'],
    description:
      'Takes the metadata from a JDBC table to populate a grid variable.'
  },
  'bash-pushdown': {
    displayName: 'Bash Pushdown',
    description: 'Executes bash commands over ssh',
    tags: ['$t(tags.scripting)'],
    synonyms: [],
    filter: []
  },
  'chunk-text-sf': {
    displayName: 'Chunk Text',
    tags: ['$t(tags.ai)'],
    synonyms: ['$t(synonyms.chunking)', '$t(synonyms.text-chunking)'],
    filter: ['$t(filter.ai)'],
    description: 'Pushdown text chunking component'
  }
}

export default componentSummaries
