import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import { getPseudoComponents } from 'api/hooks/useGetPseudoComponents/useGetPseudoComponents'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { createOrchestrationComponent } from 'job-lib/builders/createComponent/createOrchestrationComponent'
import { createOrchestrationComponentWithDplParams } from 'job-lib/builders/createComponent/createOrchestrationComponentWithDplParams'
import { createTransformationComponent } from 'job-lib/builders/createComponent/createTransformationComponent'
import {
  type CreateComponentProps,
  type InitialParameterValues
} from 'job-lib/builders/createComponent/types'
import { isDplParamsRequired } from 'job-lib/cisIds/idType'
import { type AddComponentPayload } from 'job-lib/store/jobSlice/reducers/addComponent/addComponent'
import { type Cardinality, type OutputPortType } from 'job-lib/types/Components'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { removeUnsupportedParametersAndReplaceSlot } from 'utils/removeUnsupportedParametersAndReplaceSlot'

interface ConnectionWithExistingSource {
  sourceComponentId: ComponentInstanceId
  sourceComponentName: string
  sourceType: OutputPortType
  sourceCardinality: Cardinality
}

interface ConnectionWithoutExistingSource {
  targetComponentId: ComponentInstanceId
  targetComponentName: string
  targetCardinality: Cardinality
  sourceType: OutputPortType
  sourceCardinality: Cardinality
}

export type SourceComponentConnection =
  | ConnectionWithExistingSource
  | ConnectionWithoutExistingSource

export interface MakeComponentAtPositionOptions {
  id: ComponentSummaryId
  initialValues?: InitialParameterValues
  x: number
  y: number
  componentName?: string
  sourceComponentConnection?: SourceComponentConnection
}

// This is a type alias which is currently the same shape as the AddComponentPayload, it was created during the refactor of this file to make it easier to understand what the return type of this hook is
export type MakeComponentPayload = AddComponentPayload
export interface MakeComponentDetails {
  isLoading: boolean
  error: Error | null
}

export type MakeComponent = (
  opts: MakeComponentAtPositionOptions
) => Promise<MakeComponentPayload>
export type MakeComponentResponse = [MakeComponent, MakeComponentDetails]

export const useMakeComponent = (): MakeComponentResponse => {
  const componentInfo = useComponentInfo()
  const project = useGetProject()
  const projectWarehouse = project.data?.warehouse as string
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<MakeComponentDetails['error']>(null)
  const pseudoComponentsMetadata = useMemo(() => getPseudoComponents(), [])
  const fetchComponentMetadata = useFetchComponentMetadata()

  const getComponentMetadata = useCallback(
    async (id: ComponentSummaryId) => {
      setIsLoading(true)
      setError(null)
      try {
        const response = await fetchComponentMetadata(id)

        return removeUnsupportedParametersAndReplaceSlot(
          projectWarehouse,
          response.metadata
        )
      } catch (err) {
        setIsLoading(false)
        setError(err as Error)
        return null
      }
    },
    [fetchComponentMetadata, projectWarehouse]
  )

  const makeComponent: MakeComponent = useCallback(
    async ({
      id,
      x,
      y,
      initialValues: initialParameters,
      componentName,
      sourceComponentConnection
    }) => {
      const metadata = await getComponentMetadata(id)

      if (!metadata) {
        const err = new Error(
          `could not find metadata for component with id: ${id}`
        )
        setError(err)
        setIsLoading(false)
        throw err
      }

      const displayName = componentInfo.getDisplayName(id)

      const opts: CreateComponentProps = {
        t,
        metadata,
        initialValues: initialParameters,
        name: componentName || displayName,
        x,
        y
      }
      let payload: MakeComponentPayload
      // If this is a pseudo component, get the value overrides which are applied over the normal default values (Dpl params only)
      const pseudoComponent = pseudoComponentsMetadata
        .find((c) => c.componentId === id)
        ?.pseudoComponents.find((pc) => pc.name === componentName)

      if (metadata.componentType === JobType.Orchestration) {
        payload = {
          component: isDplParamsRequired(metadata.classification)
            ? createOrchestrationComponentWithDplParams({
                ...opts,
                initialValueOverrides:
                  pseudoComponent?.initialParameterValueOverrides ?? {}
              })
            : createOrchestrationComponent(opts),
          componentType: JobType.Orchestration
        }
      } else if (metadata.componentType === JobType.Transformation) {
        payload = {
          component: createTransformationComponent(opts),
          componentType: JobType.Transformation
        }
      } else {
        const err = new Error(
          `cannot create a component that has the type: ${
            metadata.componentType as string
          }`
        )
        setError(err)
        setIsLoading(false)
        throw err
      }

      // If there is a sourceComponentId then we're linking from an existing component
      // Otherwise we're creating a link out from the component being created.
      const targetCardinality =
        sourceComponentConnection &&
        'sourceComponentId' in sourceComponentConnection
          ? metadata.inputPorts[0]?.cardinality
          : sourceComponentConnection?.targetCardinality

      if (sourceComponentConnection && targetCardinality) {
        const connection = {
          targetCardinality,
          ...sourceComponentConnection
        }

        payload = {
          ...payload,
          connection
        }
      }

      setIsLoading(false)
      return payload
    },
    [getComponentMetadata, pseudoComponentsMetadata, componentInfo, t]
  )

  return [makeComponent, { isLoading, error }]
}
