import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from '@matillion/component-library'

import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'

import { isMacOs } from 'utils/isMacOs'

import classes from './CommandPalette.module.scss'
import { useCommandPaletteStore } from './store'

export const CommandPaletteTrigger = () => {
  const { t } = useTranslation()
  const setIsOpen = useCommandPaletteStore((state) => state.setIsOpen)

  return (
    <Tooltip
      content={`${t('commandPalette.uiTrigger.title')} (${
        isMacOs() ? t('shortcuts.commandK_macOS') : t('shortcuts.commandK')
      })`}
      position="left"
    >
      <Button
        data-testid="command-palette-trigger"
        className={classes.CommandPaletteTrigger}
        aria-label={t('commandPalette.uiTrigger.ariaLabel')}
        alt="ghost"
        size="sm"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <SearchIcon />
      </Button>
    </Tooltip>
  )
}
