import { type OrchestrationJob } from 'job-lib/types/Job'

import {
  OrchestrationComponentConnectors,
  OrchestrationJobConnectors
} from '../../job.types'

const jobConnectors = [
  OrchestrationJobConnectors.FAILURE,
  OrchestrationJobConnectors.FALSE,
  OrchestrationJobConnectors.ITERATION,
  OrchestrationJobConnectors.SUCCESS,
  OrchestrationJobConnectors.TRUE,
  OrchestrationJobConnectors.UNCONDITIONAL
]

const componentOutputs = [
  OrchestrationComponentConnectors.SUCCESS,
  OrchestrationComponentConnectors.FAILURE,
  OrchestrationComponentConnectors.UNCONDITIONAL,
  OrchestrationComponentConnectors.OUTPUT_ITERATION,
  OrchestrationComponentConnectors.OUTPUT_FALSE,
  OrchestrationComponentConnectors.OUTPUT_TRUE
]

const componentInputs = [
  OrchestrationComponentConnectors.INPUT,
  OrchestrationComponentConnectors.INPUT_ITERATION
]

export const deleteOrchestrationLink = (
  linkId: number,
  job: OrchestrationJob
) => {
  const propertyName = jobConnectors.find(
    (connector) => job[connector]?.[linkId] !== undefined
  )

  if (propertyName === undefined) {
    console.error(`Unable to find linkId: ${linkId}`)
    return
  }

  const { [linkId]: _, ...otherConnectors } = job[propertyName]
  const { id, sourceID, targetID } = job[propertyName][linkId]

  componentOutputs.forEach((output) => {
    job.components[sourceID][output] = job.components[sourceID][output].filter(
      (i) => i !== id
    )
  })

  componentInputs.forEach((input) => {
    job.components[targetID][input] = job.components[targetID][input].filter(
      (i) => i !== id
    )
  })

  job[propertyName] = otherConnectors
}
