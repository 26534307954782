import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './EditorError.module.scss'

export const EditorErrorLoadingFile = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'textEditorErrorLoadingFile'
  })

  return (
    <div
      className={classes.EditorError}
      data-testid="text-editor-file-loading-error"
    >
      <div className={classes.EditorError__Container}>
        <Typography as="h2" format="tl" className={classes.EditorError__Title}>
          {t('title')}
        </Typography>

        <Typography className={classes.EditorError__Description}>
          {t('description')}
        </Typography>
      </div>
    </div>
  )
}
