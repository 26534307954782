import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ContentWithShortcut } from 'components/ContentWithShortcut/ContentWithShortcut'

import classes from './ToolboxButton.module.scss'

interface ToolboxButtonProps {
  buttonIcon: JSX.Element
  text: string
  onClick: () => void
  disabled?: boolean
  label: string
  shortcut?: string
  dataTestId: string
  primary?: boolean
}

export const ToolboxButton = ({
  buttonIcon,
  text,
  onClick,
  disabled = false,
  label,
  shortcut,
  dataTestId,
  primary = false
}: ToolboxButtonProps) => {
  return (
    <Tooltip
      className={classes.Tooltip}
      content={
        shortcut ? (
          <ContentWithShortcut
            content={label}
            shortcut={shortcut}
            format="mc"
          />
        ) : (
          label
        )
      }
    >
      <button
        aria-label={label}
        data-testid={dataTestId}
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={classNames(classes.ButtonWithText, {
          [classes.ButtonPrimary]: primary
        })}
      >
        <Typography format="bcs">{text}</Typography>
        <div className={classes.ButtonIconWrapper}>{buttonIcon}</div>
      </button>
    </Tooltip>
  )
}
