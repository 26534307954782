import { useTranslation } from 'react-i18next'

import { Button, Icon, Tooltip, Typography } from '@matillion/component-library'
import { ServiceKey, useLDClient, useServiceUrl } from '@matillion/hub-client'

import classes from './ConnectEnvironmentCTAContent.module.scss'

const ConnectEnvironmentCTAContent = () => {
  const { t } = useTranslation()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const pendo = window.pendo
  const { track: sendLDMetric, flush: flushLDMetrics } = useLDClient()
  const url = projectExplorerOrigin.concat(pendo ? '' : '/add/new-project')
  const tooltipText = t('projectToolbox.connectMyEnvironmentCta.tooltip')

  return (
    <div className={classes.CTA_Container}>
      <Icon.Snowflake />
      <Tooltip content={tooltipText} align={'start'}>
        <div className={classes.CTA_Container__Text}>
          <Typography format="mc">
            {t('projectToolbox.connectMyEnvironmentCta.text')}
          </Typography>
        </div>
      </Tooltip>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        data-testid="header-bar-cta-connect-environment"
        data-tracker-id="header-bar-cta-connect-environment"
        onClick={(event) => {
          event.preventDefault()
          sendLDMetric('designer-header-bar-click-connect-my-environment-cta')
          flushLDMetrics()
          window.open(url, '_blank')?.focus()
        }}
      >
        <Button
          size="sm"
          alt="primary"
          className={classes.CTA_Container__Button}
        >
          <Typography format="mc" weight="bold">
            {t('projectToolbox.connectMyEnvironmentCta.buttonText')}
          </Typography>
        </Button>
      </a>
    </div>
  )
}

export default ConnectEnvironmentCTAContent
