import { useCallback } from 'react'
import { useReactFlow, type Node } from 'reactflow'

export const useUpdateContextNodeSelection = () => {
  const reactFlowInstance = useReactFlow()

  const updateNodeSelection = useCallback(
    (rightClickedNode?: Node) => {
      const nodes = reactFlowInstance.getNodes()
      const isNodeNotSelected =
        rightClickedNode &&
        !nodes.some((node) => node.id === rightClickedNode.id && node.selected)

      // if the node is in the selection then keep the selection
      // otherwise select only the right clicked node
      if (isNodeNotSelected) {
        for (const node of nodes) {
          node.selected = node.id === rightClickedNode.id
        }
        reactFlowInstance.setNodes(nodes)
      }
    },
    [reactFlowInstance]
  )

  return { updateNodeSelection }
}
