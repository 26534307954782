import { type FC } from 'react'

import classNames from 'classnames'

import classes from './TaskbarTabs.module.scss'
import { type TaskbarTabsContentProps } from './types'

export const TaskbarTabsContent: FC<TaskbarTabsContentProps> = ({
  children,
  activeTabId
}: TaskbarTabsContentProps) => {
  return (
    <>
      {children.map((tab) => {
        return (
          <div
            key={tab.key}
            className={classNames(classes.TaskbarTabsContent, {
              [classes['TaskbarTabsContent--Active']]: tab.key === activeTabId
            })}
          >
            {tab}
          </div>
        )
      })}
    </>
  )
}
