import { type FileType } from '@matillion/git-component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import {
  FileBrowserCommand,
  type FileBrowserCommandAction,
  type FileBrowserCommandState
} from '../../context/types'

export const initialCommandState: FileBrowserCommandState = {
  fileSummary: null,
  fileType: null,
  isDeletingFile: false,
  isCreatingFile: false,
  isRenamingFile: false,
  isImportingFromMetl: false,
  isSharingPipeline: false,
  isUnsharingPipeline: false,
  metlExportFile: null
}

export const commandReducer = (
  state: FileBrowserCommandState,
  { type, payload }: FileBrowserCommandAction
): FileBrowserCommandState => {
  switch (type) {
    case FileBrowserCommand.DELETE_FILE: {
      return {
        ...state,
        isDeletingFile: true,
        fileSummary: payload as JobSummary
      }
    }
    case FileBrowserCommand.CREATE_PIPELINE: {
      return {
        ...state,
        isCreatingFile: true,
        fileType: payload as FileType
      }
    }
    case FileBrowserCommand.RENAME_FILE: {
      return {
        ...state,
        isRenamingFile: true,
        fileSummary: payload as JobSummary
      }
    }
    case FileBrowserCommand.SHARE_PIPELINE: {
      return {
        ...state,
        isSharingPipeline: true,
        fileSummary: payload as JobSummary
      }
    }
    case FileBrowserCommand.UNSHARE_PIPELINE: {
      return {
        ...state,
        isUnsharingPipeline: true,
        fileSummary: payload as JobSummary
      }
    }
    case FileBrowserCommand.IMPORT_METL_PIPELINES: {
      return {
        ...state,
        metlExportFile: payload as File,
        isImportingFromMetl: true
      }
    }
    case FileBrowserCommand.RESET: {
      return initialCommandState
    }
    default:
      return state
  }
}
