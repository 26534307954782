import { type ChangeEvent } from 'react'

import { Checkbox, Label } from '@matillion/component-library'

interface LabelledCheckboxProps {
  hiddenLabel: string
  checked: boolean
  disabled: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const LabelledCheckbox = ({
  hiddenLabel,
  checked,
  disabled,
  onChange
}: LabelledCheckboxProps) => {
  return (
    <Label>
      <>
        <span className="u-visually-hidden">{hiddenLabel}</span>
        <Checkbox checked={checked} disabled={disabled} onChange={onChange} />
      </>
    </Label>
  )
}
