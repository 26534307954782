import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

export const EmptyTaskHistory: FC = () => {
  const { t } = useTranslation()

  return (
    <EmptyPanel data-testid="footer-empty-tasklist">
      {t('translation:taskHistory.noTaskHistory')}
    </EmptyPanel>
  )
}
