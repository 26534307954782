import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Toggle } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './TextMode.module.scss'

interface TextModeSwitcherProps {
  open: boolean
  onOpenTextMode: () => void
  onCloseTextMode: () => void
  className: string | undefined
}

export const TextModeSwitcher = ({
  open,
  onCloseTextMode,
  onOpenTextMode,
  className
}: TextModeSwitcherProps) => {
  const { t } = useTranslation()

  return (
    <Field
      className={classNames(classes.Switcher, className)}
      inputComponent={Toggle}
      checked={open}
      text={t('textMode.switcherLabel')}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          onOpenTextMode()
        } else {
          onCloseTextMode()
        }
      }}
    />
  )
}
