import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Fields,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'
import { type ColumnMode } from 'modules/TextMode/types'
import { useTextMode } from 'modules/TextMode/useTextMode'

import { type ReducerAction } from '../../../Form/formReducer/formReducer'
import classes from '../Steps.module.scss'
import { type StepDetail } from '../types'
import { DefaultValueGrid } from './DefaultValueGrid'

export const useDefaultValueStep = (
  dispatch: Dispatch<ReducerAction>,
  state: FormState,
  isEditing: boolean
): StepDetail => {
  const { t } = useTranslation()
  const textModeStyles = {
    textAreaClassName: classes.TextModeTextarea
  } as const
  const onExitStepMode = (newRows: string[][]) => {
    dispatch({
      type: ReducerActions.ON_EXIT_TEXT_MODE,
      field: Fields.DEFAULT_VALUE_GRID,
      newRows
    })
  }

  const modes: ColumnMode[] = state.COLUMN_GRID.value.rows.map((_) => ({
    type: 'freetext'
  }))

  const { textarea, switcher, textModeOpen } = useTextMode({
    columnModes: modes,
    onExitTextMode: onExitStepMode,
    rowValues: state.DEFAULT_VALUE_GRID.value.rows.map((r) => r.columns),
    style: textModeStyles
  })

  return {
    content: textModeOpen ? (
      textarea
    ) : (
      <DefaultValueGrid dispatch={dispatch} state={state} />
    ),
    isInvalid: (s: FormState) =>
      s.DEFAULT_VALUE_GRID.value.errors.length > 0 || textModeOpen,
    title: t(
      isEditing
        ? 'manageVariables.editGridVar.defaultValueTitle'
        : 'manageVariables.createGridVar.defaultValueTitle',
      {
        gridVarName: state.NAME.value
      }
    ),
    switcher
  }
}
