import { type ReactNode } from 'react'

import classes from '../ParameterOverlay.module.scss'

export const ParameterOverlayContent = ({
  children
}: {
  children: ReactNode
}) => {
  return <div className={classes.ParameterOverlay__Content}>{children}</div>
}
