import { type ComponentLayout } from './types'

export const SalesforceLoadConfig: ComponentLayout = {
  componentId: 'modular-load-salesforce-input-v0',
  wizard: {
    steps: [
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: null,
        nextStepId: 'connect',
        parameters: [
          {
            dplId: 'outputId'
          },
          {
            dplId: 'snowflake-output-connector-v0'
          },
          {
            dplId: 'redshift-output-connector-v0',
            omitParameters: ['createTableMode']
          },
          {
            dplId: 'databricks-output-connector-v0',
            omitParameters: ['loadStrategy']
          },
          {
            dplId: 'storage-only-output-v0',
            omitParameters: ['prepareStageStrategy']
          }
        ]
      },
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: 'destination',
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'connection'
          },
          {
            dplId: 'connectionOptions'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'advanced',
        parameters: [
          {
            dplId: 'snowflake-incremental-loader-v0'
          },
          {
            dplId: 'multiTableConfig'
          },
          {
            dplId: 'redshift-output-connector-v0',
            omitParameters: [
              'schema',
              'cleanStagedFiles',
              'folderPath',
              'filePrefix',
              's3#bucket'
            ]
          },
          {
            dplId: 'databricks-output-connector-v0',
            omitParameters: [
              'catalog',
              'schema',
              'cleanStagedFiles',
              'folderPath',
              'filePrefix',
              'stagePlatform',
              's3#bucket',
              'azure#account',
              'azure#container'
            ]
          },
          {
            dplId: 'storage-only-output-v0',
            omitParameters: [
              'folderPath',
              'storage',
              's3#bucket',
              'gcs#bucket',
              'azure#account',
              'gcs#overwriteAllowed',
              'azure#container'
            ]
          },
          {
            dplId: 'salesforce-input-v0',
            omitParameters: [
              'connection',
              'connectionOptions',
              'autoDebug',
              'debugLevel',
              'mode'
            ]
          }
        ]
      },
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [
          {
            dplId: 'autoDebug'
          },
          {
            dplId: 'debugLevel'
          }
        ]
      }
    ]
  }
}
