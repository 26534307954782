import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import * as componentLibrary from '@matillion/component-library'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import { type StructList } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import {
  ParameterOverlayErrors,
  useParameterOverlayErrors
} from 'components/ParameterOverlay'

import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'

import { Dropdown } from 'modules/ParameterEditors/components/Dropdown/Dropdown'

import { EditorHeader } from '../../components/EditorHeader/EditorHeader'
import { GridEditor } from '../../components/GridEditor/GridEditor'
import {
  type DataSelectionConfig,
  type UpdateTableConfigHandler
} from '../../types'
import classes from './AdvancedFilteringEditor.module.scss'

interface AdvancedFilteringEditorProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  parameter: ComponentParameter
  combineFiltersParameter: ComponentParameter
  replaceLookupParamId?: string
  replaceLookupParamValue?: string
  filterEditorColumns: EditorColumn[]
  activeTableConfig: DataSelectionConfig
  onFetchFilterColumns: (editorColumns: EditorColumn[]) => void
  onUpdateTableConfig: UpdateTableConfigHandler
  onBack: () => void
}

export const AdvancedFilteringEditor: FC<AdvancedFilteringEditorProps> = ({
  componentSummaryId,
  componentMetaData,
  parameter,
  combineFiltersParameter,
  replaceLookupParamId,
  replaceLookupParamValue,
  filterEditorColumns,
  activeTableConfig,
  onFetchFilterColumns,
  onUpdateTableConfig,
  onBack
}) => {
  const { t } = useTranslation()
  const [isQueryEnabled, setIsQueryEnabled] = useState(
    filterEditorColumns.length === 0
  )
  const { errors, setErrors } = useParameterOverlayErrors()
  const { data, isSuccess, isError, error } = useParameterOptions({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: isQueryEnabled
  })

  const isLookupDone = isQueryEnabled && isSuccess

  useEffect(() => {
    if (isLookupDone) {
      if (isLookupDone && Array.isArray(data) && data.length > 0) {
        onFetchFilterColumns(data)
      }
      setErrors([])
      setIsQueryEnabled(false)
    }
  }, [data, isLookupDone, onFetchFilterColumns, setErrors])

  useEffect(() => {
    if (isQueryEnabled) {
      if (isError && error) {
        const newId = `GridEditor-${parameter.dplID}-columns-lookup-error`
        const isErrorShown = errors.find(({ id }) => id === newId)

        if (!isErrorShown) {
          setErrors([
            ...errors,
            {
              id: newId,
              message: error?.detail,
              type: 'error'
            }
          ])
        }
      }
    } else if (!isError && filterEditorColumns.length === 0) {
      const newId = `GridEditor-${parameter.dplID}-columns-empty-warning`
      const isErrorShown = errors.find(({ id }) => id === newId)
      if (!isErrorShown) {
        setErrors([
          ...errors,
          {
            id: newId,
            message: t('multiTableConfig.common.emptyResponse.message'),
            type: 'warning'
          }
        ])
      }
    }
  }, [
    error,
    errors,
    filterEditorColumns.length,
    isError,
    isQueryEnabled,
    parameter.dplID,
    setErrors,
    t
  ])

  const getCombineFiltersValue = () => {
    if (activeTableConfig.combineFilters) {
      return activeTableConfig.combineFilters
    }
    return combineFiltersParameter.defaultValue ?? ''
  }

  const handleGridUpdated = (grid: StructList) => {
    const newConfig = cloneDeep(activeTableConfig)
    newConfig.dataSourceFilter = grid
    onUpdateTableConfig(newConfig)
  }

  const handleChangeCombineFilters = (combineFilters: string) => {
    const newConfig = cloneDeep(activeTableConfig)
    newConfig.combineFilters = combineFilters
    onUpdateTableConfig(newConfig)
  }

  const showNoResultsWarning =
    !isQueryEnabled && !isError && filterEditorColumns.length === 0

  return (
    <div
      className={classes.AdvancedFilteringEditor}
      data-testid="advanced-filter-editor"
    >
      <EditorHeader title="Data source filters" onBack={onBack} />
      <div
        className={classNames(
          classes.AdvancedFilteringEditor__GridEditorContainer,
          {
            [classes[
              'AdvancedFilteringEditor__GridEditorContainer--WithErrors'
            ]]: errors.length > 0 || showNoResultsWarning
          }
        )}
      >
        {isQueryEnabled && !isError && (
          <div className={classes.AdvancedFilteringEditor__Spinner}>
            <div>
              <componentLibrary.LoadingSpinner />
            </div>
          </div>
        )}
        {!isQueryEnabled && filterEditorColumns.length > 0 && (
          <div className={classes.AdvancedFilteringEditor__GridEditor}>
            <GridEditor
              componentSummaryId={componentSummaryId}
              componentMetaData={componentMetaData}
              filterEditorColumns={filterEditorColumns}
              replaceLookupParamId={replaceLookupParamId}
              replaceLookupParamValue={replaceLookupParamValue}
              gridValues={activeTableConfig.dataSourceFilter}
              onEdit={handleGridUpdated}
            />
            <div
              className={
                classes.AdvancedFilteringEditor__CombineFiltersDropdown
              }
            >
              <Dropdown
                inputId={'param-input-combine-filters'}
                value={getCombineFiltersValue()}
                onEdit={handleChangeCombineFilters}
                onBlur={handleChangeCombineFilters}
                onEditorError={() => {}}
                parameter={combineFiltersParameter}
                parameterName={combineFiltersParameter.displayName ?? ''}
                scrollableContainerSelector={''}
                compact
                staticLabelColor
              />
            </div>
          </div>
        )}
      </div>
      {(errors.length > 0 || showNoResultsWarning) && (
        <div className={classes.AdvancedFilteringEditor__ErrorsContainer}>
          <ParameterOverlayErrors />
        </div>
      )}
    </div>
  )
}
