import {
  ErrorStatuses,
  Fields,
  type FormState
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { validateName } from './validateName'

export const validateGrid = (
  field: Fields.COLUMN_GRID | Fields.DEFAULT_VALUE_GRID,
  draft: FormState
) => {
  if (field === Fields.COLUMN_GRID) {
    validateColumnGrid(draft)
  }
  if (field === Fields.DEFAULT_VALUE_GRID) {
    validateDefaultValueGrid(draft)
  }
}

function validateDefaultValueGrid(draft: FormState) {
  draft.DEFAULT_VALUE_GRID.value.errors = []
  const { rows } = draft.DEFAULT_VALUE_GRID.value
  for (const [
    index,
    {
      columns: [, columnType]
    }
  ] of draft.COLUMN_GRID.value.rows.entries()) {
    for (const row of rows) {
      const valid =
        columnType !== 'NUMBER' ||
        isNumeric(row.columns[index]) ||
        !row.columns[index]

      if (!valid) {
        draft.DEFAULT_VALUE_GRID.value.errors.push({
          columnName: draft.COLUMN_GRID.value.rows[index].columns[0],
          errorType: ErrorStatuses.INCORRECT_NUMBER_FORMAT,
          rowId: row.id
        })
      }
    }
  }
}

function validateColumnGrid(draft: FormState) {
  draft.COLUMN_GRID.value.errors = []
  const { rows } = draft.COLUMN_GRID.value
  for (const row of rows) {
    const rowId = row.id
    const [columnName, columnType] = row.columns
    if (!columnName) {
      draft.COLUMN_GRID.value.errors.push({
        columnName: 'columnName',
        errorType: ErrorStatuses.EMPTY,
        rowId
      })
    }
    if (!columnType) {
      draft.COLUMN_GRID.value.errors.push({
        columnName: 'columnType',
        errorType: ErrorStatuses.EMPTY,
        rowId
      })
    }
    const nameError = validateName(columnName)

    if (nameError) {
      draft.COLUMN_GRID.value.errors.push({
        columnName: 'columnName',
        errorType: nameError,
        rowId
      })
    }
    const duplicateName =
      rows.findIndex((r) => r.columns[0] === columnName) !== rows.indexOf(row)
    if (duplicateName && columnName !== '') {
      draft.COLUMN_GRID.value.errors.push({
        columnName: 'columnName',
        errorType: ErrorStatuses.DUPLICATE,
        rowId
      })
    }
  }
}
