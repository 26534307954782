import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

import { DataGridAddRemoveButtons } from 'components/DataGridAddRemoveButtons/DataGridAddRemoveButtons'
import { MatillionTable } from 'components/MatillionTable/MatillionTable'
import { Button } from 'components/ParameterOverlay/components/Button'

import { type RootState } from 'job-lib/store'
import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { useGridEditorContext } from '../../GridEditorContext'
import classes from './TableEditor.module.scss'
import { useTableVariableEditorContext } from './TableVariableEditorContext'

export const TableEditor = () => {
  const { editorColumns, parameter } = useGridEditorContext()
  const {
    nonEmptyRows,
    addAll: { isLoading },
    addAllRows,
    addRow,
    deleteRows,
    textMode: { switcher, textModeOpen, textarea },
    table,
    setRows
  } = useTableVariableEditorContext()
  const { t } = useTranslation()
  const hasColumns = editorColumns.length > 0
  const { isUnvalidated } = useComponentValidationProvider()

  const jobType = useSelector<RootState>((state) => state.job.jobType)
  const requiresValidation = isUnvalidated && jobType === JobType.Transformation
  const addAllButtonDisabled =
    !hasColumns || nonEmptyRows.length > 0 || requiresValidation

  return (
    <>
      <div
        id="parameter-overlay-grid"
        className={classNames({
          [classes.GridEditor__GridContainer]: true,
          [classes.GridEditor__TextModeWrapper]: textModeOpen
        })}
        style={textModeOpen ? { overflowY: 'hidden' } : {}}
      >
        {textModeOpen ? (
          textarea
        ) : (
          <form
            className={classes.GridContainer__Content}
            onSubmit={(evt) => {
              /* istanbul ignore next */
              evt.preventDefault()
            }}
          >
            <MatillionTable table={table} setRows={setRows} />
          </form>
        )}
      </div>
      <div
        className={classNames([
          classes.GridEditor__ButtonContainer,
          classes.FooterContainer
        ])}
      >
        <div className={classes.FooterContainerLHS}>
          {Boolean(parameter.autoFill) && (
            <div className={classes.GridEditor__AddAll}>
              <Button
                type="submit"
                onClick={addAllRows}
                data-testid="add-all"
                aria-label={t('parameterEditor.GRID_EDITOR.addAll')}
                disabled={addAllButtonDisabled || isLoading || textModeOpen}
                text={t('parameterEditor.GRID_EDITOR.addAll')}
                waiting={isLoading}
              />
            </div>
          )}
          <DataGridAddRemoveButtons
            add={{ disabled: !hasColumns || textModeOpen, onClick: addRow }}
            remove={{
              disabled:
                table.getSelectedRowModel().rows.length === 0 ||
                !hasColumns ||
                textModeOpen,
              onClick: deleteRows
            }}
          />
        </div>
        {switcher}
      </div>
    </>
  )
}
