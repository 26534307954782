import axios from 'axios'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import type { CopilotChatMessage } from 'modules/core/Copilot/types'

import type { ProblemDetails } from '../../../types/http-problem-details'
import type { ComponentWithMetadata } from '../model'
import { useGeneratePipeline } from '../useGeneratePipeline/useGeneratePipeline'
import { useUpdateComponents } from '../useUpdateComponents/useUpdateComponents'

interface UseUpdatePipelineV1Props {
  addInfoMessage: (message: CopilotChatMessage) => void
}

export const useUpdatePipelineV1 = ({
  addInfoMessage
}: UseUpdatePipelineV1Props) => {
  const { generatePipeline, isLoading: isLoadingUpdatePipeline } =
    useGeneratePipeline()
  const { updateComponents } = useUpdateComponents()

  const updatePipelineV1 = async (
    query: CopilotChatMessage,
    selectedComponents?: ComponentWithMetadata[]
  ): Promise<void> => {
    try {
      addInfoMessage(query)
      const response = await generatePipeline(query.content, selectedComponents)
      addInfoMessage({
        id: uuid(),
        type: 'bot',
        content: response.choices[0].summary,
        requestId: response.requestId,
        service: 'PIPELINE_GENERATION'
      })

      const choice = response?.choices[0]
      await updateComponents(choice)
    } catch (error: unknown) {
      if (
        axios.isAxiosError<ProblemDetails>(error) &&
        error.response?.data.type?.startsWith('copilot/message/')
      ) {
        addInfoMessage({
          id: uuid(),
          type: 'bot',
          content:
            error.response.data.detail ?? t('copilot.chat.cantHandleRequest'),
          errorType: error.response.data.type
        })
      } else {
        throw error
      }
    }
  }

  return { updatePipelineV1, isLoadingUpdatePipeline }
}
