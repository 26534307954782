import { memo, type FunctionComponent } from 'react'
import { getStraightPath, type ConnectionLineComponentProps } from 'reactflow'

import { getEdgeStyle } from './FlowEdge'

export const FlowConnectionLine: FunctionComponent<ConnectionLineComponentProps> =
  memo(({ fromX, fromY, toX, toY, fromHandle }) => {
    const [path] = getStraightPath({
      sourceX: fromX,
      sourceY: fromY,
      targetX: toX,
      targetY: toY
    })

    return (
      <g>
        <path
          fill="none"
          style={getEdgeStyle({ source: fromHandle?.id })}
          d={path}
        />
      </g>
    )
  })

FlowConnectionLine.displayName = 'FlowConnectionLine'
