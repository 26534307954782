import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'
import { SecretReferenceTypes } from './types'

export const useGetSecretReferences = (
  secretType:
    | SecretReferenceTypes
    | SecretReferenceTypes[] = SecretReferenceTypes.PASSWORD
) => {
  const { projectId } = useProjectInfo()
  const { getSecretReferences } = useProjectExplorerApi()

  return useQuery(
    [queryKeys.secretReferences, projectId, secretType],
    async () => {
      const { data } = await getSecretReferences({ projectId, secretType })
      return data
    },
    { retry: 0 }
  )
}

export default useGetSecretReferences
