import React from 'react'

import { Typography } from '@matillion/component-library'

const headerStyle: React.CSSProperties = {
  height: 65,
  top: 30,
  left: 30,
  right: 30,
  marginRight: 10,
  marginBottom: 10
}

const headerTitleStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  height: '95%',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const typographyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  fontSize: '1.5rem'
}

const logoStyle: React.CSSProperties = {
  width: 200,
  height: 50,
  right: 5
}

const lineStyle: React.CSSProperties = {
  width: '100%',
  height: 2,
  borderTop: '1px solid gray',
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0
}

const ConversionReportHeader = () => {
  return (
    <div style={headerStyle}>
      <div style={headerTitleStyle}>
        <Typography as="span" style={typographyStyle}>
          Data Productivity Cloud Migration Report
        </Typography>
        <img
          src="/matillion-logo-horizontal.png"
          alt="Matillion Logo"
          style={logoStyle}
        />
      </div>
      <hr style={lineStyle} />
    </div>
  )
}

export default ConversionReportHeader
