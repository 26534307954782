import type { ComponentName, Components } from 'types/Pipeline'

const INITIAL_NEXT_COMPONENT_NUMBER = 2

export const getNextComponentName = (
  components: Components,
  componentName: ComponentName
): ComponentName => {
  if (!components[componentName]) {
    return componentName
  }

  let componentCount = INITIAL_NEXT_COMPONENT_NUMBER

  while (components[`${componentName} ${componentCount}`]) {
    componentCount += 1
  }

  return `${componentName} ${componentCount}`
}
