import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import classes from './ErrorPanel.module.scss'

interface ErrorPanelProps {
  text: string
  includeLink?: boolean
}

const ErrorPanel: FC<ErrorPanelProps> = ({ text, includeLink = true }) => {
  const { t } = useTranslation()
  const url = useServiceUrl(ServiceKey.projectExplorerFrontend, true)

  return (
    <div className={classes.ErrorPanel}>
      <Icon.Error className={classes.ErrorPanel__Icon} />
      <Typography format="bcm" data-testid="panel-error-status">
        <span className={classes.ErrorPanel__Text}>{text}</span>
        {includeLink && (
          <>
            <Icon.ArrowLeft />
            <a className={classes.ErrorPanel__Link} target="_self" href={url}>
              {t('sideBar.goBackToProjectExplorer')}
            </a>
          </>
        )}
      </Typography>
    </div>
  )
}

export { ErrorPanel }
