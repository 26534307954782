import React, { createContext, useContext, useMemo, useState } from 'react'

import {
  getCoreRowModel,
  useReactTable,
  type Table
} from '@tanstack/react-table'
import { mapValues } from 'lodash'

import { type ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import { type APIError } from 'api/hooks/useGetParameterOptions/types'

import { useParameterOptionsAutofill } from 'hooks/useParameterOptions/useParameterOptionsAutofill'

import { type ElementCollection } from 'job-lib/types/Parameters'

import { useTextMode } from 'modules/TextMode/useTextMode'

import { useGridEditorContext } from '../../GridEditorContext'
import { type GridCell, type GridRow } from '../Grid/types'
import {
  generateBlobRow,
  getDuplicateField,
  getHighestRowSlot,
  init
} from '../Grid/utils'
import classes from './TableEditor.module.scss'
import { useTableEditorColumns } from './useTableEditorColumns'

type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>

interface TableVariableEditorContextValues {
  onTableVariableSubmit: () => void
  duplicateField: string | false | null | undefined
  addAll: {
    isLoading: boolean
    error: APIError | null
    refetch: () => Promise<{ data?: string[][] }>
  }
  addRow: () => void
  addAllRows: () => void
  deleteRows: () => void
  autofillNoData: boolean
  setAutofillNoData: SetStateAction<boolean>
  textMode: ReturnType<typeof useTextMode>
  table: Table<GridRow>
  nonEmptyRows: GridRow[]
  setRows: React.Dispatch<React.SetStateAction<GridRow[]>>
}

const TableVariableEditorContext =
  createContext<TableVariableEditorContextValues | null>(null)

interface TableVariableEditorContextProps {
  children: React.JSX.Element
}

export const TableVariableEditorContextProvider = ({
  children
}: TableVariableEditorContextProps) => {
  const context = useGridEditorContext()
  const {
    elements,
    isUsingGridVariable,
    disallowDuplicates,
    componentSummaryId,
    componentMetaData,
    parameter,
    editorColumns,
    onDone
  } = context
  const [rows, setRows] = useState<GridRow[]>(() =>
    isUsingGridVariable ? [] : init(elements, editorColumns)
  )
  const [autofillNoData, setAutofillNoData] = useState(false)
  const nonEmptyRows = useMemo(
    () =>
      rows.filter(({ cells }) =>
        Object.values(cells).some((cell) => cell.value !== '')
      ),
    [rows]
  )
  const duplicateField = disallowDuplicates && getDuplicateField(rows)
  const {
    isFetching: isLoadingAddAll,
    error: addAllError,
    refetch
  } = useParameterOptionsAutofill({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: false,
    requestType: 'autofill'
  })

  const addRows = React.useCallback(
    (newRows: string[][]) => {
      const rowsToAdd = newRows.map((rowValues, i) => {
        return generateBlobRow(i + 1, editorColumns, rowValues)
      })
      setRows(rowsToAdd)
    },
    [editorColumns]
  )

  const columns = useTableEditorColumns({ setRows })
  const table = useReactTable({
    data: rows,
    getRowId: (row) => row.id,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  const textMode = useTextMode({
    columnModes: editorColumns.map((_) => ({ type: 'freetext' })),
    onExitTextMode: addRows,
    rowValues: rows.map((r) =>
      Object.values(r.cells).map((cell) => cell.value)
    ),
    onEnterTextMode: () => {
      table.resetRowSelection(true)
    },
    style: {
      textAreaClassName: classes.TextModeTextarea
    }
  })
  const contextValue = useMemo(() => {
    const deleteRows = () => {
      const selectedRows = table.getSelectedRowModel()
      setRows((previousRows) => {
        const updatedRows = previousRows.filter(
          (row) => !selectedRows.rowsById[row.id]
        )
        const reindexedRows = updatedRows.map((row, index) => ({
          ...row,
          cells: mapValues(row.cells, (cell: GridCell) => ({
            ...cell,
            rowSlot: index + 1
          }))
        }))

        return reindexedRows
      })

      table.resetRowSelection(true)
    }

    const addRow = () => {
      const newRowSlot = getHighestRowSlot(rows)
      setRows((prevState) => [
        ...prevState,
        generateBlobRow(newRowSlot, editorColumns)
      ])
    }

    const addAllRows = () => {
      refetch().then(({ data }) => {
        const hasResults = data?.length
        if (hasResults) {
          addRows(data)
        }
        setAutofillNoData(hasResults === 0)
      })
    }

    return {
      onTableVariableSubmit: () => {
        const newElements: ElementCollection = {}
        nonEmptyRows.forEach(({ cells }, index) => {
          // paramSlot needs to be index based on submission
          // to account for the removal of rows in the job object
          const paramSlot = index + 1

          newElements[paramSlot] = {
            slot: paramSlot,
            values: {}
          }
          Object.values(cells).forEach((cell) => {
            const { type, value, slot, dataType } = cell

            newElements[paramSlot].values[slot] = {
              slot,
              value: String(value),
              type,
              dataType: dataType as ParameterDataType
            }
          })
        })

        onDone(newElements)
      },
      duplicateField,
      addAll: {
        error: addAllError,
        isLoading: isLoadingAddAll,
        refetch
      },
      addRow,
      addAllRows,
      deleteRows,
      autofillNoData,
      setAutofillNoData,
      nonEmptyRows,
      textMode,
      table,
      setRows
    }
  }, [
    addAllError,
    autofillNoData,
    duplicateField,
    isLoadingAddAll,
    onDone,
    refetch,
    editorColumns,
    addRows,
    textMode,
    table,
    nonEmptyRows,
    rows,
    setRows
  ])
  return (
    <TableVariableEditorContext.Provider value={contextValue}>
      {children}
    </TableVariableEditorContext.Provider>
  )
}

export const useTableVariableEditorContext = () => {
  const context = useContext(TableVariableEditorContext)
  /* istanbul ignore if */
  if (context === null) {
    throw new Error('table variable context must be used inside a provider')
  }
  return context
}
