import { useAuth, useUser } from '@matillion/hub-client'
import { type AxiosResponse } from 'axios'

import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'
import { useApiClientContext } from 'api/hooks/useApiClientContext/useApiClientContext'

import config from 'config'

import { type OptimisedQueryTask } from '../model'
import type { ChatInput } from '../useChatWithSse/useChatWithSse'
import {
  type SQLExpressionRequest,
  type SQLExpressionResponse
} from '../useGenerateSQLExpression/useGenerateSQLExpression'
import { type OptimiseQueryInput } from '../useOptimiseQuery/useOptimiseQuery'
import {
  type CopilotResponse,
  type PipelineUpdateInput
} from '../usePipelineUpdate/useUpdatePipeline'
import { type SubmitFeedbackRequest } from '../useSubmitFeedback/useSubmitFeedback'

export const useCopilotApi = () => {
  const { copilotClient: client } = useApiClientContext()

  const { data: agentDetails } = useAgentDetails()

  const { organisation } = useUser()
  const { getToken } = useAuth()

  const agentVersion = agentDetails?.agentVersion ?? ''

  const requestConfig = {
    headers: {
      'agent-version': agentVersion
    }
  }

  const updatePipeline = async (
    input: PipelineUpdateInput
  ): Promise<AxiosResponse<CopilotResponse>> => {
    const response = await client.post<CopilotResponse>(
      '/api/v1/sidekick/update-pipeline',
      input,
      requestConfig
    )

    return response
  }

  const submitFeedback = async (
    request: SubmitFeedbackRequest
  ): Promise<AxiosResponse> => {
    const response = await client.post(
      '/api/v1/feedback',
      request,
      requestConfig
    )

    return response
  }

  const generateSQLExpression = async (
    request: SQLExpressionRequest
  ): Promise<AxiosResponse<SQLExpressionResponse>> => {
    const response = await client.post<SQLExpressionResponse>(
      '/api/v1/sidekick/sql/expression',
      request,
      requestConfig
    )

    return response
  }

  const optimiseQuery = async (
    input: OptimiseQueryInput
  ): Promise<AxiosResponse<OptimisedQueryTask[]>> => {
    const response = await client.post<OptimisedQueryTask[]>(
      '/api/v1/sidekick/optimise-query',
      input,
      requestConfig
    )

    return response
  }

  const performChat = async (
    input: ChatInput,
    abortController: AbortController
  ) => {
    const token = await getToken({
      audience: config.apiTokenAudience
    })

    return fetch(`${client.getUri()}/api/v1/agents/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'account-id': organisation.id,
        'agent-version': agentVersion
      },
      body: JSON.stringify(input),
      signal: abortController.signal
    })
  }

  return {
    updatePipeline,
    submitFeedback,
    generateSQLExpression,
    optimiseQuery,
    performChat
  }
}
