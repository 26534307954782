import type { FunctionComponent } from 'react'

import classnames from 'classnames'

import classes from './Stack.module.scss'

interface Props {
  children: React.ReactNode
  isCentered?: boolean
}

export const Stack: FunctionComponent<Props> = ({ children, isCentered }) => {
  return (
    <div
      className={classnames(classes.Stack, {
        [classes['Stack--centered']]: isCentered
      })}
    >
      {children}
    </div>
  )
}
