import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { type ElementCollection } from 'job-lib/types/Parameters'

/**
 * Create a collection of elements containing one element made from a list of values.
 * Each value will get the same datatype
 */
export const createHomogeneousElements = (
  parameterValues: string | string[],
  dataType: ParameterDataType = ParameterDataType.TEXT
): ElementCollection => {
  if (!Array.isArray(parameterValues)) {
    parameterValues = [parameterValues]
  }

  const elements: ElementCollection = {}

  parameterValues.forEach((currentValue, index) => {
    const slot = index + 1 // this means we start to update our param value at index 1(path of component) not 0(name of component)
    const type = 'STRING'
    const value = currentValue

    elements[slot] = {
      slot,
      values: {
        1: { slot: 1, type, value, dataType }
      }
    }
  })

  return elements
}
