import { useTranslation } from 'react-i18next'

import { Alert, Icon, Typography } from '@matillion/component-library'

import CopilotIntroHeader from '../CopilotIntro/CopilotIntroHeader/CopilotIntroHeader'
import classes from './CopilotPlaceholder.module.scss'

interface CopilotPlaceholderProps {
  isActiveJob: boolean
  isOrchEnabled: boolean
}

const CopilotPlaceholder = ({
  isActiveJob,
  isOrchEnabled
}: CopilotPlaceholderProps) => {
  const { t } = useTranslation()
  const enabledJobTypes = isOrchEnabled ? 'pipeline' : 'transformation pipeline'

  return (
    <div className={classes.Placeholder} data-testid="copilot-chat-placeholder">
      {isActiveJob && <CopilotIntroHeader />}
      <div className={classes.Placeholder__Message}>
        {isActiveJob ? (
          <Alert
            className={classes.Placeholder__Alert}
            title={t('copilot.placeholder.notAvailableOrch.title')}
            message={t('copilot.placeholder.notAvailableOrch.message')}
            type="info"
          />
        ) : (
          <>
            <Icon.Info data-testid={'copilot-info-icon'} />
            <Typography>
              {t('copilot.placeholder.pipelineNotOpen', {
                enabledJobTypes
              })}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}

export default CopilotPlaceholder
