import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { ReactComponent as ErrorConnection } from 'assets/ErrorConnection.svg'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import classes from './CanvasErrorBoundaryFallback.module.scss'

const CanvasErrorBoundaryFallback: FC = () => {
  const { t } = useTranslation()
  const { navigateToProject } = useSelectedJobs()

  return (
    <div className={classes.ErrorBoundaryFallback}>
      <div className={classes.ErrorBoundaryFallback__Container}>
        <ErrorConnection />
        <Typography
          as="h2"
          format="tl"
          className={classes.ErrorBoundaryFallback__Title}
        >
          {t('canvasErrorboundary.title')}
        </Typography>
        <Typography className={classes.ErrorBoundaryFallback__Description}>
          {t('canvasErrorboundary.description')}
        </Typography>
        <a
          onClick={() => {
            navigateToProject()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigateToProject()
            }
          }}
          className={classes.ErrorBoundaryFallback__Link}
        >
          <Button className={classes.ErrorBoundaryFallback__LinkButton}>
            <Typography weight="bold">
              {t('canvasErrorboundary.escapeLinkText')}
            </Typography>
          </Button>
        </a>
      </div>
    </div>
  )
}

export default CanvasErrorBoundaryFallback
