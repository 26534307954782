import { ErrorStatuses } from 'modules/ManageVariables/types'

import { reservedWords } from '../reservedWords'

/**
 * Validates variable names and grid variable column names
 */
export const validateName = (name: string) => {
  const isReservedWord = reservedWords.includes(name.toLowerCase().trim())
  const isCorrectFormat = /^([a-zA-Z_$][\w$]+|[a-zA-Z_]*)$/.test(name)
  const isSingle$ = /^[$]$/.test(name)
  const isTooLong = name.length > 255

  if (!isCorrectFormat) {
    return ErrorStatuses.INCORRECT_NAME_FORMAT
  }

  if (isSingle$) {
    return ErrorStatuses.SINGLE_$
  }

  if (isReservedWord) {
    return ErrorStatuses.RESERVED_WORD
  }

  if (isTooLong) {
    return ErrorStatuses.TOO_LONG
  }
}
