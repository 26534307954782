import { useState } from 'react'

import type {
  JobConversionInfo,
  JobConversionReport
} from 'api/hooks/useMigrationMetlJob'

import { FilterGroup } from 'components/FilterGroup/FilterGroup'

import JobImportReport from 'modules/migration/ConversionTool/components/JobImportReport/JobImportReport'

import classes from './JobConversionDetails.module.scss'

interface JobConversionDetailsProps {
  jobConversionReport: JobConversionReport
  jobs: JobConversionInfo | undefined
  hasJobsThatCannotBeConverted: boolean
  openMappings: () => void
}

const JobConversionDetails = ({
  jobConversionReport,
  jobs,
  hasJobsThatCannotBeConverted,
  openMappings
}: JobConversionDetailsProps) => {
  const successfulJobs =
    jobConversionReport.convertedJobs.length +
    jobConversionReport.autoconvertedJobs.length
  const warningJobs = jobConversionReport.refactorJobs.length
  const unsuccessfulJobs = jobs?.notConverted.length
  const [jobsSelectedOptions, setJobsSelectedOptions] = useState<string[]>([])

  const getNotConvertedRecord = (jobsList: string[]) => {
    return jobsList.map((jobItem) => {
      const jobName = jobItem.split(' - ')[0]
      return {
        name: jobName,
        variablesConverted: [],
        gridVariablesConverted: [],
        variablesNotConverted: [],
        gridVariablesNotConverted: [],
        components: []
      }
    })
  }

  return (
    <>
      <div
        className={classes.filterGroupWrapper}
        data-testid="conversion-tool-jobs-filter"
      >
        <FilterGroup
          options={[
            `Successful (${successfulJobs})`,
            `Warning (${warningJobs})`,
            `Unsuccessful (${unsuccessfulJobs})`
          ]}
          selectedOptions={jobsSelectedOptions}
          onChange={(option) => {
            const newSelectedOptions = jobsSelectedOptions.includes(option)
              ? jobsSelectedOptions.filter((o) => o !== option)
              : [...jobsSelectedOptions, option]
            setJobsSelectedOptions(newSelectedOptions)
          }}
        />
      </div>

      <div
        className={classes.contentContainer}
        data-testid="conversion-tool-jobs-content-container"
      >
        {jobs &&
          jobConversionReport?.convertedJobs.length > 0 &&
          (jobsSelectedOptions.length === 0 ||
            jobsSelectedOptions.includes(`Successful (${successfulJobs})`)) && (
            <JobImportReport
              importType="Success"
              data={jobConversionReport.convertedJobs}
              translationId="jobs.converted"
              id="conversion-tool-jobs-to-convert"
            />
          )}

        {jobs &&
          jobConversionReport?.autoconvertedJobs.length > 0 &&
          (jobsSelectedOptions.length === 0 ||
            jobsSelectedOptions.includes(`Successful (${successfulJobs})`)) && (
            <JobImportReport
              importType="Success"
              data={jobConversionReport.autoconvertedJobs}
              translationId="jobs.autoconverted"
              id="conversion-tool-jobs-to-autoconvert"
            />
          )}

        {jobs &&
          jobConversionReport?.refactorJobs.length > 0 &&
          (jobsSelectedOptions.length === 0 ||
            jobsSelectedOptions.includes(`Warning (${warningJobs})`)) && (
            <JobImportReport
              importType="Warning"
              data={jobConversionReport.refactorJobs}
              translationId="jobs.manualRefactor"
              id="conversion-tool-jobs-to-refactor"
              openMappings={openMappings}
            />
          )}

        {jobs &&
          hasJobsThatCannotBeConverted &&
          (jobsSelectedOptions.length === 0 ||
            jobsSelectedOptions.includes(
              `Unsuccessful (${unsuccessfulJobs})`
            )) && (
            <JobImportReport
              importType="Error"
              data={getNotConvertedRecord(jobs.notConverted)}
              translationId="jobs.notConverted"
              id="conversion-tool-jobs-not-to-convert"
            />
          )}
      </div>
    </>
  )
}

export default JobConversionDetails
