import { pipelineFileTypes, useFiles } from '@matillion/git-component-library'

import { useFlags } from 'hooks/useFlags'

/**
 * A temporary hook, intended to replace `useGetJobSummaries` as part of the
 * migration to the FileSystemProvider. This hook should **not** be used for any
 * new code--please consider using `useFiles` from `@matillion/git-component-library`
 * directly!
 *
 * This hook returns a list of available pipelines in the user's current project from the FileSystemProvide.
 * As opposed to `useGetJobSummaries` itself, this hook returns pipelines in a FileSystemProvider
 * compatible type, and can be used to migrate to these new typings whilst still respecting
 * the feature flag.
 */
export const usePipelines = (includeDeleted?: boolean) => {
  const { enableHighCode } = useFlags()

  const supportedFileTypes = enableHighCode ? undefined : pipelineFileTypes

  return useFiles({
    type: supportedFileTypes,
    includeDeleted
  })
}
