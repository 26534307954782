import { type ReactNode } from 'react'

import { Alert } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './Footer.module.scss'

interface FooterProps {
  children: ReactNode
  warningText?: string
  className?: string
}

export const Footer = ({ children, warningText, className }: FooterProps) => {
  return (
    <div className={classNames(classes.Footer, classes.Wizard, className)}>
      {warningText && (
        <Alert
          className={classes.Footer__Warning}
          message={warningText}
          type="warning"
        />
      )}
      <div className={classes.Footer__Buttons}>{children}</div>
    </div>
  )
}
