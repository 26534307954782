import { useState } from 'react'

/**
 * runs a timer, and rererenders when it has elapsed.
 *
 * @param timeMs the length of the timer
 * @returns whether the timer has elapsed (defaults to true)
 */
export const useTimer = (timeMs: number) => {
  const [hasElapsed, setHasElapsed] = useState(true)

  return {
    runTimer: () => {
      setHasElapsed(false)
      setTimeout(() => {
        setHasElapsed(true)
      }, timeMs)
    },
    hasElapsed
  }
}
