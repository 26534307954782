import { forwardRef, type HTMLProps, type Ref } from 'react'

import classNames from 'classnames'

import classes from './PipelineTable.module.scss'

export interface Props extends HTMLProps<HTMLTableSectionElement> {
  ref?: Ref<HTMLTableSectionElement>
}

export const PipelineTableBody = forwardRef<HTMLTableSectionElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <tbody
        {...props}
        ref={ref}
        className={classNames(classes.PipelineTableBody, className)}
      />
    )
  }
)

PipelineTableBody.displayName = 'PipelineTableBody'
