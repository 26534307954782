import {
  type JobConversionRecord,
  type MetlConversionIssues
} from 'api/hooks/useMigrationMetlJob'

const getRecordPlan = (record: JobConversionRecord) => {
  const jobConversionIssues: MetlConversionIssues[] = []

  record.variablesConverted.forEach(({ metlConversionIssues }) => {
    jobConversionIssues.push(...metlConversionIssues)
  })

  record.variablesNotConverted.forEach(({ metlConversionIssues }) => {
    jobConversionIssues.push(...metlConversionIssues)
  })

  record.gridVariablesConverted.forEach(({ conversionIssues }) => {
    conversionIssues.push(...conversionIssues)
  })

  record.gridVariablesNotConverted.forEach(({ conversionIssues }) => {
    jobConversionIssues.push(...conversionIssues)
  })

  record.components.forEach(({ metlConversionIssues }) => {
    jobConversionIssues.push(...metlConversionIssues)
  })

  return jobConversionIssues.filter(
    (issue, index) => jobConversionIssues.indexOf(issue) === index
  )
}

export default getRecordPlan
