import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type Parameter } from 'hooks/useParameterOptions/useParameterOptionsHelpers'
import { useParameterOptionsRequest } from 'hooks/useParameterOptions/useParameterOptionsRequest'

import {
  useGetMultiParameterOptions,
  type MultiQueryItemOptions
} from './useGetMultiParameterOptions'

export interface UseMultiParameterOptionProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  parameter?: Parameter
  overrideDplId: string
  optionsList: MultiQueryItemOptions[]
}

export const useMultiParameterOptions = ({
  componentMetaData,
  parameter,
  overrideDplId,
  optionsList
}: UseMultiParameterOptionProps) => {
  const requestType = 'parameter-options' as const

  const request = useParameterOptionsRequest(
    true,
    componentMetaData,
    parameter,
    requestType
  )

  return useGetMultiParameterOptions({
    ...request,
    overrideDplId,
    optionsList
  })
}
