import { createContext } from 'react'

import type { ComponentWithMetadata } from '../../../api/hooks/copilot/model'
import type { FeedbackSentiment } from '../../../api/hooks/copilot/types'
import { type CopilotChatMessage, type CopilotContextBag } from './types'

export const CopilotContext = createContext<CopilotContextBag>({
  chatMessages: [],
  addInfoMessage: (message: CopilotChatMessage) => null,
  updatePipelineV1: async (
    query: CopilotChatMessage,
    selectedComponents?: ComponentWithMetadata[]
  ) => Promise.resolve(),
  submitFeedback: async (
    message: CopilotChatMessage,
    sentiment: FeedbackSentiment,
    content: string
  ) => Promise.resolve(),
  sendMessageV2: async (
    query: CopilotChatMessage,
    requestId: string,
    selectedComponents?: ComponentWithMetadata[]
  ) => Promise.resolve(),
  isLoadingChat: false,
  isLoadingSubmitFeedback: false,
  setStatus: (status: string) => null,
  status: '',
  newSession: () => null,
  sessionId: ''
})
