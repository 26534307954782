import { useCallback, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Field,
  Typography
} from '@matillion/component-library'
import {
  getFileTypeTranslationKeySuffix,
  type FileType
} from '@matillion/git-component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import classes from './MovePipelineModal.module.scss'

export interface MovePipelineModalProps {
  fileType: FileType
  onClose: () => void
  onConfirm: (hideWarning: boolean) => void
}

export const MovePipelineModal: FC<MovePipelineModalProps> = ({
  fileType,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'move-pipeline.modal'
  })
  const [hideWarning, setHideWarning] = useState(false)
  const translationKeySuffix = getFileTypeTranslationKeySuffix(fileType)

  const handleConsentChange = useCallback(() => {
    setHideWarning(!hideWarning)
  }, [hideWarning, setHideWarning])

  const handleConfirm = () => {
    onConfirm(hideWarning)
  }

  return (
    <DesignerModal
      size="mid"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick
      id="move-pipeline-modal"
      className={classes.MovePipelineModal}
      data-testid="move-pipeline-modal"
      ariaLabelledBy={'move-pipeline-modal'}
    >
      <Typography
        as="h2"
        format="tl"
        className={classes.MovePipelineModal__Title}
      >
        {t(`title.${translationKeySuffix}`)}
      </Typography>

      <div className={classes.MovePipelineModal__Container}>
        <Typography
          as="p"
          format="bcm"
          weight="bold"
          className={classes.MovePipelineModal__Typography}
        >
          {t(`alert-move.${translationKeySuffix}.title`)}
        </Typography>{' '}
        <Typography as="p" className={classes.MovePipelineModal__Typography}>
          {t(`alert-move.${translationKeySuffix}.description`)}
        </Typography>
      </div>

      <div className={classes.MovePipelineModal__Container}>
        <Typography
          as="p"
          format="bcm"
          weight="bold"
          className={classes.MovePipelineModal__Typography}
        >
          {t(`alert-schedule.${translationKeySuffix}.title`)}
        </Typography>{' '}
        <Typography as="p" className={classes.MovePipelineModal__Typography}>
          {t(`alert-schedule.${translationKeySuffix}.description`)}
        </Typography>
      </div>

      <Field
        text={t('showWarningAgain')}
        checked={hideWarning}
        inputComponent={Checkbox}
        onChange={handleConsentChange}
        className={classes.MovePipelineModal__ConsentCheckbox}
        data-testid="move-pipeline-consent-box"
      />

      <div className={classes.MovePipelineModal__Buttons}>
        <Button
          alt="secondary"
          text={t('cancel')}
          onClick={onClose}
          id="move-pipeline-modal-cancel"
          data-testid="move-pipeline-modal-cancel"
        />

        <Button
          alt="primary"
          text={t('submit')}
          onClick={handleConfirm}
          id="move-pipeline-modal-submit"
          data-testid="move-pipeline-modal-submit"
        />
      </div>
    </DesignerModal>
  )
}

export default MovePipelineModal
