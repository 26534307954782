import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import {
  type HistoryTaskType,
  type State
} from 'api/hooks/useGetRunTasks/types'

import StateIcon from 'components/StateIcon/StateIcon'

import classes from './TaskHistory.module.scss'

export interface Props {
  state: State
  taskType: HistoryTaskType
}

export const TaskStatusRow: FC<Props> = ({ state, taskType }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        classes.TaskHistory__TaskStatus,
        'u-truncate-text',
        classes[`TaskHistory__TaskStatus--${state}`]
      )}
      data-testid="task-history-row-task-status"
    >
      <Tooltip content={t(`taskExplorer.status.${state}`)}>
        <div>
          <StateIcon
            status={state}
            className={classes.TaskHistory__TaskStatus__Icon}
            aria-label={t(`taskExplorer.status.${state}`)}
          />
        </div>
      </Tooltip>
      <Tooltip
        content={t(`taskHistory.taskStatus.${taskType}`)}
        onlyShowOnOverflow
      >
        {/* We need this div because the tooltip uses cloneElement and typography breaks that contract */}
        <div className="u-truncate-text">
          <Typography
            className="u-truncate-text"
            format="bcs"
            data-task-type={taskType}
            as="span"
          >
            {t(`taskHistory.taskStatus.${taskType}`)}
          </Typography>
        </div>
      </Tooltip>
    </div>
  )
}
