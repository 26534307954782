import { type FC, type HTMLProps } from 'react'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './EmptyPanel.module.scss'

export const EmptyPanel: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames(classes.EmptyPanel, className)} {...props}>
    <Icon.InfoNoBg className={classes.EmptyPanel__Icon} />
    <Typography format="bcm">{children}</Typography>
  </div>
)
