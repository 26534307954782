import {
  EditorType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import {
  dataTransferObjectComponentId,
  excelQueryComponentId,
  s3LoadComponentId,
  s3UnloadComponentId
} from 'job-lib/cisIds/knownComponentParameters'
import { type ComponentInstance } from 'job-lib/types/Job'

import {
  DynamicUrlEditorKnownStorageTypes,
  StorageEditorType
} from '../../../types'

export const handleEditorSaveExperimentLogic = async (
  componentInstance: ComponentInstance,
  componentSummaryId: string,
  componentMetadata: ComponentMetadata,
  parameter: ComponentParameter,
  editorType: string,
  trackLDMetric: (metricName: string) => void,
  flushLDMetrics: () => Promise<void>
) => {
  const getParameterValueById = (parameterId: string) => {
    const param = componentMetadata.parameters.find(
      (p: ComponentParameter) => p.dplID === parameterId
    )

    if (!param) return

    const parameterElements =
      componentInstance.parameters?.[param.metlSlot]?.elements

    if (!parameterElements) return

    return Object.values(parameterElements)
      .map((el) => el.values[1]?.value)
      .toString()
  }

  const isExcelQuery =
    componentSummaryId === excelQueryComponentId &&
    getParameterValueById('storageType') ===
      DynamicUrlEditorKnownStorageTypes.AMAZON_S3_STORAGE &&
    parameter.resourceID === 'storageUrl' &&
    editorType === EditorType.DYNAMIC_URL_EDITOR

  const isS3Load =
    componentSummaryId === s3LoadComponentId &&
    parameter.resourceID === 's3ObjectPrefix' &&
    editorType === EditorType.S3_URL

  const isS3Unload =
    componentSummaryId === s3UnloadComponentId &&
    parameter.resourceID === 's3ObjectPrefix' &&
    editorType === EditorType.S3_URL

  const isDataTransferObject =
    componentSummaryId === dataTransferObjectComponentId &&
    getParameterValueById('sourceType') === StorageEditorType.S3 &&
    parameter.resourceID === 'sourceUrl' &&
    editorType === EditorType.S3_URL

  if (isExcelQuery || isS3Load || isS3Unload || isDataTransferObject) {
    trackLDMetric('designer-s3-storage-url-modal-saved')
    await flushLDMetrics()
  }
}
