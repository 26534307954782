import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './EtlNoteInfoTooltipContent.module.scss'

export const EtlNoteInfoTooltipContent = () => {
  // NB: the tooltip doesnt grow dynamically, this will likely
  // overflow if we add translations
  const { t } = useTranslation('translation', {
    keyPrefix: 'notes.markdownTooltip'
  })
  return (
    <div role="tooltip">
      <Typography className={classes.EtlNoteInfoTooltipContent__Heading}>
        {t('markdownSupportExplanation')}
      </Typography>

      <Typography>
        <span className={classes.EtlNoteInfoTooltipContent__Bold}>
          {t('boldLabel')}
        </span>{' '}
        {t('boldExampleMarkdown')}
      </Typography>
      <Typography>
        <span className={classes.EtlNoteInfoTooltipContent__Italic}>
          {t('italicLabel')}
        </span>{' '}
        {t('italicExampleMarkdown')}
      </Typography>
      <Typography>
        <span>{t('linksLabel')}</span>
        <span> {t('linkExampleMarkdown')}</span>
      </Typography>
      <Typography>
        <span>{t('blockquoteLabel')}</span> {t('blockquoteExampleMarkdown')}
      </Typography>
    </div>
  )
}
