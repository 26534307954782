import { createContext, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { elementsUseGridVariable } from 'job-lib/job-functions/elements'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'

import { translateColumnName } from './components/Grid/utils'

export interface GridEditorContextValues {
  componentMetaData: ComponentMetadata
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  editorColumns: EditorColumn[]
  elements: ElementCollection
  parameterName: string
  parameter: ComponentParameter
  isUsingGridVariable: boolean
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
  disallowDuplicates?: boolean
  columnNames: string[]
}

const GridEditorContext = createContext<GridEditorContextValues | null>(null)

interface GridEditorContextProps {
  elements: ElementCollection
  parameterName: string
  parameter: ComponentParameter
  editorColumns: EditorColumn[]
  children: React.JSX.Element
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
  disallowDuplicates?: boolean
}

export const GridEditorContextProvider = ({
  children,
  editorColumns,
  elements,
  parameter,
  parameterName,
  onDone,
  disallowDuplicates,
  problemDetail
}: GridEditorContextProps) => {
  const { t } = useTranslation()
  const isUsingGridVariable = elementsUseGridVariable(elements)
  const {
    componentInstanceId,
    componentMetadata: componentMetaData,
    componentSummaryId
  } = useActiveComponentInfo()
  const columnNames = useMemo(
    () => editorColumns.map((col) => translateColumnName(col, t)),
    [editorColumns, t]
  )

  return (
    <GridEditorContext.Provider
      value={useMemo(
        () => ({
          componentInstanceId,
          componentMetaData,
          componentSummaryId,
          editorColumns,
          elements,
          parameter,
          parameterName,
          isUsingGridVariable,
          columnNames,
          onDone,
          disallowDuplicates,
          problemDetail
        }),
        [
          componentInstanceId,
          componentMetaData,
          componentSummaryId,
          editorColumns,
          elements,
          parameter,
          parameterName,
          isUsingGridVariable,
          onDone,
          disallowDuplicates,
          problemDetail,
          columnNames
        ]
      )}
    >
      {children}
    </GridEditorContext.Provider>
  )
}

export const useGridEditorContext = () => {
  const context = useContext(GridEditorContext)
  /* istanbul ignore if */
  if (context === null) {
    throw new Error('grid editor context must be used inside a provider')
  }
  return context
}
