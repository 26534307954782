import { type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { type AutoCompleteItemStringId } from 'components/AutoComplete/types'

import { JobVariableType } from 'job-lib/types/Variables'

import {
  Fields,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'
import { type ColumnMode } from 'modules/TextMode/types'
import { useTextMode } from 'modules/TextMode/useTextMode'

import { type ReducerAction } from '../../../Form/formReducer/formReducer'
import classes from '../Steps.module.scss'
import { type StepDetail } from '../types'
import { GridColumnsGrid } from './GridColumnsGrid'

export const useGridColumnsStep = (
  dispatch: Dispatch<ReducerAction>,
  state: FormState,
  isEditing: boolean
): StepDetail => {
  const textModeStyles = {
    textAreaClassName: classes.TextModeTextarea
  } as const
  const { t } = useTranslation()
  const onExitTextMode = (newRows: string[][]) => {
    dispatch({
      type: ReducerActions.ON_EXIT_TEXT_MODE,
      field: Fields.COLUMN_GRID,
      newRows
    })
  }
  const typeOptions: AutoCompleteItemStringId[] = [
    {
      id: JobVariableType.TEXT,
      name: t('manageVariables.jobVariables.fields.variableType.text')
    },
    {
      id: JobVariableType.NUMBER,
      name: t('manageVariables.jobVariables.fields.variableType.number')
    }
  ]
  const modes: ColumnMode[] = [
    { type: 'freetext' },
    {
      type: 'dropdown',
      options: typeOptions
    }
  ]
  const { switcher, textModeOpen, textarea } = useTextMode({
    rowValues: state.COLUMN_GRID.value.rows.map((r) => r.columns),
    onExitTextMode,
    columnModes: modes,
    style: textModeStyles
  })

  return {
    content: textModeOpen ? (
      textarea
    ) : (
      <GridColumnsGrid
        dispatch={dispatch}
        state={state}
        typeOptions={typeOptions}
      />
    ),
    isInvalid: (s: FormState) =>
      s.COLUMN_GRID.value.errors.length > 0 ||
      s.COLUMN_GRID.value.rows.length === 0 ||
      textModeOpen,
    title: t(
      isEditing
        ? 'manageVariables.editGridVar.gridColumnsTitle'
        : 'manageVariables.createGridVar.gridColumnsTitle',
      {
        gridVarName: state.NAME.value
      }
    ),
    switcher
  }
}
