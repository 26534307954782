import { useMemo } from 'react'

import type { ParameterValue } from 'types/Pipeline'

import type { ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import { getParameterValue } from 'hooks/useParameterOptions/getParameterDependencies'

import type { ComponentInstanceId } from 'job-lib/types/Job'
import type { ElementCollection } from 'job-lib/types/Parameters'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'

export interface GetDependentParameterValueProps {
  componentMetaData: ComponentMetadata
  componentId: ComponentInstanceId | null
}

export const useDependentParameterValue = (
  componentMetaData: ComponentMetadata,
  componentId: ComponentInstanceId | null,
  dependentParameter: string
): ParameterValue | ElementCollection => {
  const { job } = useFlaggedWorkingCopy()

  return useMemo(() => {
    const componentParameterCollection =
      job?.components[componentId as number]?.parameters ?? {}

    const parameterPath = [dependentParameter]
    const alias = `param.${dependentParameter}`

    const parameterValue = getParameterValue({
      parameterPath,
      componentParameterCollection,
      componentParameters: componentMetaData.parameters,
      parameterId: String(parameterPath.at(0)),
      alias,
      job
    })

    return parameterValue
  }, [componentMetaData, componentId, dependentParameter, job])
}
