import {
  PipelineType,
  type ComponentName,
  type Pipeline,
  type Transition
} from 'types/Pipeline'

import { type Cardinality } from 'job-lib/types/Components'

import { updateSources } from './updateSources'
import { updateTransitions } from './updateTransitions'

export type LinkType = 'iteration' | keyof typeof Transition

export interface UpdateLinksPayload {
  sourceComponent: ComponentName
  targetComponent: ComponentName
  sourceCardinality: Cardinality
  targetCardinality: Cardinality
  linkType?: LinkType
}

export const updateLinks =
  (state: Pipeline) => (payload: UpdateLinksPayload) => {
    const source = state.pipeline.components[payload.sourceComponent]
    const target = state.pipeline.components[payload.targetComponent]

    if (!source || !target) {
      return
    }

    if (state.type === PipelineType.Transformation) {
      updateSources(state, payload)
    } else if (state.type === PipelineType.Orchestration && payload.linkType) {
      updateTransitions(state, {
        ...payload,
        linkType: payload.linkType as LinkType
      })
    }
  }
