import type { Parameters, ParameterValue, Pipeline } from 'types/Pipeline'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'

import {
  getParameterVisibility,
  removeHiddenParameters,
  setParameterDefaultValues
} from 'utils/isParameterVisible'

export interface SetParameterValuePayload {
  componentMetadata: ComponentMetadata
  componentName: string
  parameterPath: string[]
  parameterValue: ParameterValue
}

export const setComponentParameterValue =
  (state: Pipeline) =>
  ({
    componentName,
    parameterPath,
    parameterValue,
    componentMetadata
  }: SetParameterValuePayload) => {
    const pathToParameter = [...parameterPath]
    const parameterId = pathToParameter.pop()
    const parameters = state.pipeline.components[componentName]?.parameters

    if (!parameterId || !parameters) {
      return state
    }

    const { visibleParameters: originalVisibleParams } = getParameterVisibility(
      componentMetadata,
      parameters
    )

    // a parameter can be nested deeply within DPL
    // we take the parameterId we want to update by removing it from the end of the provided path
    // then we take the remaining path and navigate down the DPL parameter tree until we find the parameter we want to update
    let parameterNode = parameters

    for (const path of pathToParameter) {
      parameterNode = parameterNode[path] as Parameters
    }

    // parameterNode is our target parameter once we've gone through each item in the parameter path
    parameterNode[parameterId] = parameterValue

    const { visibleParameters, hiddenParameters } = getParameterVisibility(
      componentMetadata,
      parameters
    )

    // we only want to set values against parameters that have become visible as a result of updating the specified parameter
    // i.e. they were previously not in the DPL and changing a dependent parameter has caused them to become visible
    const newlyVisibleParams = visibleParameters.filter(
      (param) => !originalVisibleParams.includes(param)
    )

    // when a parameter becomes visible it's added to the DPL from scratch
    // which means we need to set its default value again
    setParameterDefaultValues(newlyVisibleParams, componentMetadata, parameters)

    // DPL only cares about visible parameters
    // so any parameters that should not be visible as a result of updating the specified parameter are removed from the DPL
    removeHiddenParameters(hiddenParameters, parameters)
  }
