import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  MicroCta,
  Table as T,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { ReadonlyModal } from 'components/ReadonlyModal/ReadonlyModal'

import classes from './SampleComponent.module.scss'
import virtualTableclasses from './VirtualisedSampleComponent.module.scss'

const { TableCell } = T

const SampleTableDataCell = ({
  value,
  isResizing = false,
  isVirtualised = false
}: {
  value: string
  isVirtualised?: boolean
  isResizing?: boolean
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [sampleModalOpen, setSampleModalOpen] = useState(false)
  const { t } = useTranslation()

  const styleClass = isVirtualised ? virtualTableclasses : classes

  return (
    <TableCell
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      className={classNames(styleClass.SampleComponentResults__Cell, {
        [styleClass.ColumnResizing]: isResizing
      })}
    >
      <div
        className={classNames(
          styleClass.SampleComponentResults__Cell__Text,
          'u-truncate-text-3-lines'
        )}
      >
        <Typography format="bcs" as="span">
          {value}
        </Typography>
      </div>
      {value && isHovered && (
        <MicroCta
          aria-label={t('sampleComponent.samplePreview.cta')}
          alt="transparent"
          onClick={() => {
            setSampleModalOpen(true)
          }}
          className={classNames(
            styleClass.SampleComponentResults__Cell__PreviewButton
          )}
          data-testid="cell-sample-preview-cta"
          tabIndex={-1}
        >
          <Icon.Ellipsis height={18} width={18} />
        </MicroCta>
      )}
      <ReadonlyModal
        open={sampleModalOpen && value !== undefined}
        closeModal={() => {
          setSampleModalOpen(false)
        }}
        value={value}
        title={t('sampleComponent.samplePreview.modalTitle')}
      />
    </TableCell>
  )
}

export default SampleTableDataCell
