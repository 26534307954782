import { type FC } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'

export interface TruncatedCellProps {
  text: string
  tooltipPosition: 'left' | 'top'
}

export const TruncatedCell: FC<TruncatedCellProps> = ({
  text,
  tooltipPosition = 'top'
}) => (
  <Tooltip content={text} position={tooltipPosition} onlyShowOnOverflow>
    <div className="u-truncate-text-2-lines">
      <Typography format="bcs" as="span">
        {text}
      </Typography>
    </div>
  </Tooltip>
)
