import { useUser } from '@matillion/hub-client'
import axios from 'axios'

import config from 'config'

import useAuthInterceptor from '../useAuthInterceptor/useAuthInterceptor'

export const useEosApiClient = () => {
  const { organisation } = useUser()

  const client = axios.create({
    baseURL: `${config.getEosApiBaseUrl(organisation.region)}`,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 50000
  })

  useAuthInterceptor(client)

  return client
}

export default useEosApiClient
