import { useApiClientContext } from '../useApiClientContext/useApiClientContext'
import type {
  ActivityItem,
  ActivityResponse,
  GetStepMessagesResponse,
  PipelineStatus,
  StepsResponse
} from './types/eos'

export const useEosApi = () => {
  const { eosClient: client } = useApiClientContext()

  const getSteps = async (
    pipelineId: string,
    offset?: number,
    limit?: number
  ) => {
    return client.get<StepsResponse>(
      `/v1/activity/pipeline/${pipelineId}/steps`,
      {
        params: {
          offset,
          limit
        }
      }
    )
  }

  const getActivity = async (
    projectId: string,
    workspaceId: string,
    status: PipelineStatus[],
    timeFrame?: string
  ) => {
    return client.get<ActivityResponse>('/v1/activity', {
      params: {
        projectId,
        workspaceId,
        status: status.join(','),
        timeFrame,
        limit: 50
      }
    })
  }

  const cancelTask = async (flowInstanceId: string, force: boolean) => {
    return client.patch(
      `/v1/activity/${flowInstanceId}/cancellation?force=${force}&source=saas-etl-designer`
    )
  }

  const getRootPipelineActivity = async (pipelineId: string) => {
    return client.get<ActivityItem>(`/v1/activity/pipeline/${pipelineId}`)
  }

  const getStepMessages = async (stepId: string) => {
    return client.get<GetStepMessagesResponse>(
      `/v1/activity/step/${stepId}/messages`
    )
  }

  return {
    getSteps,
    getActivity,
    cancelTask,
    getRootPipelineActivity,
    getStepMessages
  }
}
