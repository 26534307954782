import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'
import { FileType, type File } from '@matillion/git-component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import { ReactComponent as ExecutingOrchestrationSvg } from 'assets/canvas/executing-orchestration.svg'
import { ReactComponent as ExecutingTransformationSvg } from 'assets/canvas/executing-transformation.svg'

import { useTimer } from 'hooks/useTimer/useTimer'

import { ToolboxButton } from './ToolboxButton'

interface RunButtonProps {
  summary: File
  context: {
    context: string
  }
}

export const RunButton: FC<RunButtonProps> = ({ summary, context }) => {
  const { t } = useTranslation()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''

  const { mutate: onRunJob } = useRunJob({
    jobId: summary.name,
    warehouse
  })

  const { hasPermission: canRun } = useProjectPermission(
    'run_pipelines',
    PermissionType.ENVIRONMENT
  )

  const RunInProgressIcon =
    summary.type === FileType.TRANSFORMATION_PIPELINE
      ? ExecutingTransformationSvg
      : ExecutingOrchestrationSvg
  const { runTimer, hasElapsed } = useTimer(1000)
  const triggerJobExecution = () => {
    runTimer()
    onRunJob()
  }
  const buttonIcon = !hasElapsed ? (
    <RunInProgressIcon data-testid="executing-job-svg" aria-hidden />
  ) : (
    <Icon.Play />
  )
  const label = t('canvasToolbox.runJob')
  const shortcut = t('shortcuts.commandEnter', context)
  const disabled = !hasElapsed || !canRun
  const dataTestId = 'canvas-toolbox-run-job'

  return (
    <ToolboxButton
      onClick={triggerJobExecution}
      buttonIcon={buttonIcon}
      text={t('canvasToolbox.buttons.run')}
      label={label}
      shortcut={shortcut}
      disabled={disabled}
      dataTestId={dataTestId}
      primary
    />
  )
}
