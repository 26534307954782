import { useContext } from 'react'

import { ShortcutProviderContext } from './ShortcutProviderContext'

export const useShortcut = () => {
  const context = useContext(ShortcutProviderContext)

  if (context === undefined) {
    throw new Error(
      'useShortcut must be used within the ShortcutProviderContext'
    )
  }

  return context
}
