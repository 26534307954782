import { useTranslation } from 'react-i18next'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import { getSelectedComponentNames } from 'file-editors/canvas/util/componentSelection'

import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import { ReactComponent as RunOnlyIcon } from 'assets/run only.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useTimer } from 'hooks/useTimer/useTimer'

import classes from '../ComponentNodeToolbar.module.scss'

const executeAsSingleComponent = true

interface RunOnlyButtonProps {
  jobId: string
}

const RunOnlyButton: React.FC<RunOnlyButtonProps> = ({ jobId }) => {
  const { t } = useTranslation()
  const { hasElapsed, runTimer } = useTimer(1000)
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const reactFlow = useEtlFlow()
  const componentName = getSelectedComponentNames(reactFlow)[0]

  const { mutate: runOnlyComponent } = useRunJob({
    jobId,
    warehouse,
    componentName,
    executeAsSingleComponent
  })

  return (
    <IconButton
      disabled={!hasElapsed}
      className={classes.Toolbar__Button}
      label={t('componentToolbox.runOnlyComponent')}
      onClick={() => {
        runOnlyComponent()
        runTimer()
      }}
    >
      <RunOnlyIcon />
    </IconButton>
  )
}

export default RunOnlyButton
