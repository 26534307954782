import {
  ParameterDataType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'

import { type OrchestrationComponentInstance } from 'job-lib/types/Job'
import { dplParamsKey } from 'job-lib/types/Parameters'

import {
  getMetlParameterVisibility,
  removeMetlHiddenParameters
} from 'utils/isParameterVisible'

import { createDPLParameters } from './createDPLParameters'
import { createEmptyOrchestrationComponent } from './createOrchestrationComponent'
import { type CreateDplComponentProps } from './types'

const createComponentInstance = (
  name: string,
  metadata: ComponentMetadata,
  x: number,
  y: number,
  parameterDPL: unknown
): Omit<OrchestrationComponentInstance, 'id'> => {
  const emptyOrchestrationComponent = createEmptyOrchestrationComponent(name)

  return {
    ...emptyOrchestrationComponent,
    implementationID: parseInt(metadata.emeraldId, 10),
    x,
    y,
    parameters: {
      ...emptyOrchestrationComponent.parameters,
      2: {
        slot: 2,
        name: dplParamsKey,
        elements: {
          1: {
            slot: 1,
            values: {
              1: {
                slot: 1,
                type: 'DPL_PARAMS',
                dataType: ParameterDataType.TEXT,
                value: JSON.stringify(parameterDPL)
              }
            }
          }
        },
        visible: true
      }
    }
  }
}

export const createOrchestrationComponentWithDplParams = ({
  t,
  initialValueOverrides,
  metadata,
  name,
  x,
  y
}: CreateDplComponentProps): Omit<OrchestrationComponentInstance, 'id'> => {
  const parameterDPL = createDPLParameters({
    componentName: name,
    componentParameters: metadata?.parameters,
    initialValueOverrides
  })

  const componentInstance = createComponentInstance(
    name,
    metadata,
    x,
    y,
    parameterDPL
  )

  const { hiddenParameters } = getMetlParameterVisibility(
    metadata,
    componentInstance.parameters
  )
  const newParameterDpl = removeMetlHiddenParameters(
    hiddenParameters,
    componentInstance.parameters
  )

  componentInstance.parameters[2].elements[1].values[1].value =
    JSON.stringify(newParameterDpl)

  return componentInstance
}
