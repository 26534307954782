import {
  ParameterDataType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { useClientDataFiltering } from './useClientDataFiltering'
import { isGridParameter, type Parameter } from './useParameterOptionsHelpers'
import { useParameterOptionsRequest } from './useParameterOptionsRequest'
import { type UseGetParameterOptionsResponse } from './useParameterOptionsTypes'

interface UseParameterOptionsProps {
  componentSummaryId: ComponentSummaryId
  componentMetaData: ComponentMetadata
  parameter?: Parameter
  isEnabled?: boolean
}

/**
 *
 * Gets the lookupDependencies from the parameter and sends a lookup request
 * to /parameter-options. Used for looking up values from dropdowns.
 */
export const useParameterOptions = ({
  componentMetaData,
  parameter,
  componentSummaryId,
  isEnabled = false
}: UseParameterOptionsProps): UseGetParameterOptionsResponse => {
  const { filterColumns } = useClientDataFiltering({
    componentSummaryId,
    componentParameters: componentMetaData.parameters,
    parameterId: isGridParameter(parameter) ? parameter?.id : parameter?.dplID,
    lookupType: parameter?.lookupType
  })

  const requestType = 'parameter-options' as const

  const request = useParameterOptionsRequest(
    isEnabled,
    componentMetaData,
    parameter,
    requestType
  )
  const {
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error: unknownError,
    data: lookupResponseData,
    refetch
  } = useGetParameterOptions(request)

  const error = unknownError as ProblemDetails

  const defaultEmptyResponse: UseGetParameterOptionsResponse = {
    data: [],
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error,
    refetch
  }

  if (!isEnabled) {
    return defaultEmptyResponse
  }

  if (parameter && 'options' in parameter && parameter.options) {
    const options: EditorColumn[] = [
      {
        name: null,
        type: ParameterDataType.TEXT,
        options: parameter.options,
        columnEditorType: null,
        defaultValue: null,
        lookupType: null,
        lookupDependencies: null
      }
    ]

    return {
      data: filterColumns(options),
      isLoading: false,
      isRefetching: false,
      isSuccess: true,
      isError: false,
      error: null,
      refetch
    }
  }

  if (parameter && 'staticOptions' in parameter && parameter.staticOptions) {
    const response: UseGetParameterOptionsResponse = {
      data: filterColumns(parameter.staticOptions),
      isLoading: false,
      isRefetching: false,
      isSuccess: true,
      isError: false,
      error: null,
      refetch
    }
    return response
  }

  if (lookupResponseData) {
    const response: UseGetParameterOptionsResponse = {
      data: filterColumns(lookupResponseData.editorColumns),
      helperData: lookupResponseData.helperData,
      isLoading,
      isRefetching,
      isSuccess,
      isError,
      error,
      refetch
    }

    return response
  }

  return defaultEmptyResponse
}
