import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import type {
  VariableConversionRecord,
  VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import ConversionReportSection from './ConversionReportSection'

interface VariablesConversionReportProps {
  variableConversionReport: VariableConversionReport
  variablesNotConverted: VariableConversionRecord[]
}

const pageStyle: React.CSSProperties = {
  display: 'flex',
  marginTop: 40,
  flexDirection: 'column',
  pageBreakBefore: 'always'
}

const sectionStyle: React.CSSProperties = {
  marginBottom: 10
}

const subtitleStyle: React.CSSProperties = {
  fontSize: '1.2rem',
  fontWeight: 'bold'
}

const textStyle: React.CSSProperties = {
  fontSize: '1rem'
}

const VariablesConversionReport = ({
  variableConversionReport,
  variablesNotConverted
}: VariablesConversionReportProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conversion.report'
  })

  return (
    <div style={pageStyle}>
      <div style={sectionStyle}>
        <Typography as="span" style={subtitleStyle}>
          Variables
        </Typography>
        <Typography as="span" style={textStyle}>
          <br />
          {t('variablesDocs.description')}
          <a href={t('variablesDocs.docsLink')}>
            <span style={textStyle}>{t('variablesDocs.linkText')}</span>
          </a>
          {t('variablesDocs.endDesc')}
        </Typography>
      </div>
      <ConversionReportSection
        entityType="variable"
        conversionType="converted"
        conversionReport={variableConversionReport.convertedVariables}
      />
      <ConversionReportSection
        entityType="variable"
        conversionType="autoconverted"
        conversionReport={variableConversionReport.autoconvertedVariables}
      />
      <ConversionReportSection
        entityType="variable"
        conversionType="manualRefactor"
        conversionReport={variableConversionReport.refactorVariables}
      />
      <ConversionReportSection
        entityType="variable"
        conversionType="notConverted"
        conversionReport={variablesNotConverted}
      />
    </div>
  )
}

export default VariablesConversionReport
