import { useTranslation } from 'react-i18next'

import { ComponentEditorTabType } from 'api/hooks/useGetComponentMetadata/types'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getComponentName } from 'job-lib/job-functions/getComponentName'

import { SampleComponent } from 'modules/ComponentProperties/components/SampleComponent'

export const SampleComponentPanel = () => {
  const { t } = useTranslation()

  const { componentId: componentInstanceId } = useProjectInfo()
  const { componentInstance, componentMetadataId, metadata } =
    useComponentInstanceMetadataQuery(componentInstanceId)

  if (!componentInstance) {
    return (
      <EmptyPanel data-testid="empty-sample-no-component">
        {t('sampleComponent.sampleNoComponent')}
      </EmptyPanel>
    )
  }

  if (!metadata?.editorTabs.includes(ComponentEditorTabType.SAMPLE)) {
    return (
      <EmptyPanel data-testid="empty-sample-not-available">
        {t('sampleComponent.sampleNotAvailable', {
          componentName: getComponentName(componentInstance)
        })}
      </EmptyPanel>
    )
  }

  return (
    <SampleComponent
      componentInstance={componentInstance}
      componentName={getComponentName(componentInstance)}
      componentMetadataId={componentMetadataId}
      jobType={metadata.componentType}
    />
  )
}
