import { type ColumnMode } from '../types'
import {
  translateRowsFromTextMode,
  translateRowsToTextMode
} from './translateRows'

export const convertToTextMode = (
  rowValues: string[][],
  columnModes: ColumnMode[]
): string => {
  return translateRowsToTextMode(rowValues, columnModes)
    .map((r) => r.join('\t'))
    .join('\n')
}

export const convertFromTextMode = (
  textModeValue: string,
  columnModes: ColumnMode[]
): string[][] => {
  if (textModeValue.endsWith('\n')) {
    textModeValue = textModeValue.slice(0, -1)
  }
  const newValues = textModeValue.split('\n').map((s) => s.split('\t'))

  return translateRowsFromTextMode(newValues, columnModes)
}
