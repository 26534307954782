import { useEffect, type FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Field,
  Input,
  Toaster,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useRenameFolder } from 'hooks/useRenameFolder'

import {
  usePipelineBrowserCommandProvider,
  usePipelineBrowserProvider
} from 'modules/FileBrowser/effects/usePipelineBrowserCommand'
import { isEmptyFolderNode } from 'modules/FileBrowser/reducers/fileTreeReducer/fileTreeReducer'
import {
  FileTreeReducerType,
  type FileTree
} from 'modules/FileBrowser/reducers/fileTreeReducer/types'
import { extractFolderPathFromFilePath } from 'modules/FileBrowser/utils/extractFolderPathFromFilePath'
import { VALID_FOLDER_NAME_REGEX } from 'modules/FileBrowser/utils/validFolderNameRegex'

import classes from '../Modal.module.scss'

export interface RenameFolderModalProps {
  path: string
  folderName: string
  currentDirectory: FileTree
  onClose: () => void
}

export const RenameFolderModal: FC<RenameFolderModalProps> = ({
  path,
  folderName,
  currentDirectory,
  onClose
}) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  const { branchId, projectId } = useProjectInfo()
  const { isLoading: isRenamingFolder, renameFolder } = useRenameFolder()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const fileTree = usePipelineBrowserProvider()

  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    setFocus
  } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    setFocus('folderName')
  }, [setFocus])

  const handleRenameFolder = async () => {
    const isRootDirectory = currentDirectory.some((node) => node.root)
    const newFolderName = getValues('folderName')
    const newFolderPath = isRootDirectory
      ? newFolderName
      : `${extractFolderPathFromFilePath(path)}/${newFolderName}`
    const isEmptyFolder = isEmptyFolderNode(fileTree, path)

    try {
      if (isEmptyFolder) {
        onFileTreeCommand({
          type: FileTreeReducerType.RENAME_EMPTY_FOLDER,
          fileDetails: {
            path,
            name: newFolderName
          },
          branch: branchId,
          project: projectId
        })
      } else {
        await renameFolder({
          currentFilePath: path,
          newFilePath: newFolderPath
        })
      }

      makeToast({
        title: t('renameFolder.success'),
        type: 'success',
        message: ''
      })

      onClose()
    } catch {
      makeToast({
        title: t('renameFolder.failure.title'),
        type: 'error',
        message: t('renameFolder.failure.message')
      })
    }
  }

  return (
    <DesignerModal
      disableBackdropClick
      data-testid="rename-folder-modal"
      id="rename-folder-dialog"
      onCancel={onClose}
      ariaLabelledBy="rename-folder-modal-title"
      className={classes.Modal}
      size="mid"
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="rename-folder-title"
          data-testid="rename-folder-modal-title"
        >
          {t('translation:renameFolder.title')}
        </Typography>
      </div>

      <Alert
        className={classes.Modal__AlertInformation}
        data-testid="rename-folder-alert"
        theme="dark"
        type="warning"
        title=""
        message={
          <>
            <Typography format="bcs">
              <Trans i18nKey={'renameFolder.alert.line1'}>
                <strong />
              </Trans>
            </Typography>

            <Typography format="bcs">
              <Trans i18nKey={'renameFolder.alert.line2'}>
                <strong />
              </Trans>
            </Typography>
          </>
        }
      />

      <form
        onSubmit={(e) => {
          handleSubmit(handleRenameFolder)(e)
        }}
      >
        <div
          className={classnames(
            classes.Modal__Content,
            classes['Modal__Content--Input']
          )}
        >
          <Controller
            name="folderName"
            defaultValue={folderName}
            control={control}
            rules={{
              required: t('renameFolder.validation.no-empty-string'),
              pattern: {
                value: VALID_FOLDER_NAME_REGEX,
                message: t('renameFolder.validation.failed-regex')
              },
              validate: async (name: string) => {
                if (name.length > 50) {
                  return t('renameFolder.validation.name-too-long')
                }

                const folderExists = currentDirectory.find(
                  (node) => node.type === 'folder' && node.name === name.trim()
                )

                if (folderExists) {
                  return t('renameFolder.validation.already-exists')
                }
              }
            }}
            render={({
              field: { value, onChange, name, ref },
              fieldState: { error }
            }) => {
              return (
                <Field
                  data-testid="rename-folder-name-input"
                  title={t('translation:renameFolder.fields.name.title')}
                  aria-label={t('translation:renameFolder.fields.name.title')}
                  placeholder={t(
                    'translation:renameFolder.fields.name.placeholder'
                  )}
                  inputRef={ref}
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  hasError={!!error}
                  error={!!error}
                  errorText={error?.message}
                  disabled={isRenamingFolder}
                  inputComponent={Input}
                />
              )
            }}
          />
        </div>

        <div className={classes.Modal__Actions}>
          <Button
            data-testid="rename-folder-cancel"
            alt="secondary"
            text={t('translation:renameFolder.buttons.cancel')}
            onClick={onClose}
          />
          <Button
            data-testid="rename-folder-submit"
            type="submit"
            alt="primary"
            text={t('translation:renameFolder.buttons.update')}
            waiting={isRenamingFolder}
            disabled={!isDirty || !isValid}
          />
        </div>
      </form>
    </DesignerModal>
  )
}
