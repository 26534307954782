import { type ListProjectVariablesResponse } from 'api/hooks/useListProjectVariables/types'

import { VariableScope, type JobVariable } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  type FormState,
  type ReducerActions
} from 'modules/ManageVariables/types'

import { manageVariableNameErrors } from '../../Form/errorMessages'
import { isFormValid } from './isFormValid'
import { validateName } from './validateName'

export const updateName = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_NAME
    value: string
    jobVariables: JobVariable[]
    projectVariables: ListProjectVariablesResponse
    originalName?: string
  }
): FormState => {
  let errorStatus = ErrorStatuses.NO_ERROR

  const { variableScope } = state
  const { value, jobVariables, projectVariables, originalName } = action
  const nameError = validateName(value)
  if (nameError) {
    errorStatus = nameError
  }

  const isProjectVariable = variableScope === VariableScope.PROJECT_VARIABLE

  const isExistingPipelineVariable = jobVariables.some(
    (jv) => jv.definition.name === value
  )
  const isDuplicatePipelineVariable =
    isExistingPipelineVariable && !isProjectVariable

  const isExistingProjectVariable = projectVariables.some(
    (pv) => pv.name === value
  )
  const isDuplicateProjectVariable =
    isExistingProjectVariable && isProjectVariable

  if (
    value !== originalName &&
    (isDuplicatePipelineVariable || isDuplicateProjectVariable)
  ) {
    errorStatus = ErrorStatuses.DUPLICATE
  }

  if (!value) {
    errorStatus = ErrorStatuses.EMPTY
  }

  const isValid = errorStatus === ErrorStatuses.NO_ERROR

  const updatedState = {
    ...state,
    [Fields.NAME]: {
      isValid,
      value,
      error: manageVariableNameErrors[errorStatus]
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState)
  }
}
