import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster, Typography } from '@matillion/component-library'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

import { useGenerateSQLExpression } from 'api/hooks/copilot/useGenerateSQLExpression/useGenerateSQLExpression'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import type { ProblemDetails } from 'api/types/http-problem-details'

import { ReactComponent as CopilotIcon } from 'assets/copilot-icon.svg'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import CopilotPromptInput from 'modules/CopilotChat/Copilot/CopilotPromptInput/CopilotPromptInput'

import { type SourceColumns } from '../../types'
import classes from './CopilotExpressionPrompt.module.scss'

export interface CopilotExpressionPromptProps {
  columns: SourceColumns
  onSuccess: (sql: string) => void
}

const CopilotExpressionPrompt = ({
  columns,
  onSuccess
}: CopilotExpressionPromptProps) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const projectInfo = useProjectInfo()
  const project = useGetProject()
  const { mutateAsync: generateSQL, isLoading } = useGenerateSQLExpression()
  const [copilotMessage, setCopilotMessage] = useState<string>('')

  const handleErr = (error: unknown) => {
    if (
      axios.isAxiosError<ProblemDetails>(error) &&
      error.response?.data.title &&
      error.response?.data.detail
    ) {
      makeToast({
        type: 'error',
        title: error.response?.data.title,
        message: error.response?.data.detail
      })
    } else {
      makeToast({
        type: 'error',
        title: t('copilot.error.title'),
        message: t('copilot.error.unexpected')
      })
    }
  }

  const onSubmit = async () => {
    try {
      const resp = await generateSQL({
        requestId: uuid(),
        warehouse: project?.data?.warehouse?.toUpperCase() ?? '',
        pipelineMetadata: {
          projectId: projectInfo.projectId,
          branchId: projectInfo.branchId,
          pipelineId: projectInfo.jobSummaryId
        },
        message: copilotMessage,
        columns: columns.map((col) => ({
          type: col.type,
          name: col.alias || col.column
        }))
      })
      onSuccess(resp.sql)
      setCopilotMessage('')
    } catch (e) {
      handleErr(e)
    }
  }

  return (
    <div className={classes.CopilotExpressionPrompt__Container}>
      <div className={classes.CopilotExpressionPrompt__Logo}>
        <CopilotIcon height="40px" width="100px" />
      </div>
      <div className={classes.CopilotExpressionPrompt__InputContainer}>
        <CopilotPromptInput
          prompt={copilotMessage}
          setPrompt={setCopilotMessage}
          onComplete={onSubmit}
          isLoading={isLoading}
        />
        <Typography format="mc">
          {t('copilot.sqlExpressionPrompt.hint')}
        </Typography>
      </div>
    </div>
  )
}

export default CopilotExpressionPrompt
