import { type FC, type HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import classes from './RowLoading.module.scss'

type Props = HTMLProps<HTMLDivElement>

export const RowLoading: FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <div className={classes.RowLoading} {...props}>
      <LoadingSpinner className={classes.RowLoading__Icon} />
      <Typography className={classes.RowLoading__Text} format="mc">
        {t('statuses.loading')}
      </Typography>
    </div>
  )
}
