import type {
  GridVariable,
  Pipeline,
  ScalarVariable,
  VariableName
} from 'types/Pipeline'

export interface AddVariablePayload {
  name: VariableName
  variable: ScalarVariable | GridVariable
}

export const addVariable =
  (state: Pipeline) =>
  ({ name, variable }: AddVariablePayload) => {
    state.pipeline.variables[name] = variable
  }
