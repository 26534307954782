import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Field, Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'
import { z } from 'zod'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import { useSharePipelineStore } from 'api/hooks/useSharePipeline/store'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'
import {
  isPipelineShared,
  pipelineIdCharactersRegex
} from 'api/hooks/useSharePipeline/utils'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import classes from '../Modal.module.scss'
import sharedPipelineClasses from './SharedPipelineModal.module.scss'

export interface Props {
  onClose: () => void
  jobSummary: JobSummary
}

export const SharePipelineModal: FC<Props> = ({ onClose, jobSummary }) => {
  const { t } = useTranslation()
  const {
    sharePipeline,
    checkIfIdAlreadyExists,
    generateAutoincrementedPipelineId
  } = useSharePipeline()
  const { sharedPipelinesConfig } = useSharePipelineStore()
  const [pipelineId, setPipelineId] = useState<string>(
    generateAutoincrementedPipelineId(jobSummary.jobId, sharedPipelinesConfig)
  )
  const { projectName } = useProjectNames()

  const onShareJob = () => {
    sharePipeline(jobSummary.jobId, pipelineId)

    onClose()
  }

  const schema = z
    .string()
    .min(1, { message: t('translation:shareJob.modal.emptyIdError') })
    .max(255, { message: t('translation:shareJob.modal.tooLongError') })
    .refine((value) => !checkIfIdAlreadyExists(value), {
      message: t('translation:shareJob.modal.nonUniqueIdError')
    })
    .refine((value) => !pipelineIdCharactersRegex.test(value), {
      message: t('translation:shareJob.modal.invalidCharactersError')
    })

  const { success: isPipelineIdValid, error } = schema.safeParse(pipelineId)
  const validationError = error?.issues?.at(0)?.message

  const isPipelineIdDisabled =
    sharedPipelinesConfig &&
    isPipelineShared(sharedPipelinesConfig.pipelines, jobSummary.jobId)

  const pipelineIdValue = isPipelineIdDisabled
    ? sharedPipelinesConfig.pipelines.find(
        (p) => p.pipeline === jobSummary.jobId
      )?.id
    : pipelineId

  return (
    <DesignerModal
      onCancel={onClose}
      size="mid"
      ariaLabelledBy="share-job-modal-heading"
      data-testid="share-job-modal"
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="share-job-modal-heading"
          className={classes.Modal__Title}
        >
          {t('translation:shareJob.modal.heading')}
        </Typography>
      </div>
      <div>
        <Typography
          format="bcs"
          weight="bold"
          className={classNames({
            [sharedPipelineClasses.SharedPipelineModal__Error]:
              !pipelineId || validationError
          })}
        >
          {t('translation:shareJob.modal.pipelineIdLabel')}
        </Typography>
        <Typography
          className={classNames(
            sharedPipelineClasses.SharedPipelineModal__ProjectName,
            {
              [sharedPipelineClasses.SharedPipelineModal__Error]:
                !pipelineId || validationError
            }
          )}
        >
          {projectName} #
        </Typography>
        <Field
          disabled={isPipelineIdDisabled}
          value={pipelineIdValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPipelineId(e.target.value)
          }}
          hasError={!pipelineId}
          errorText={validationError}
          data-testid="share-job-modal-pipeline-id"
        />

        <div className={classes.Modal__Info}>
          <Icon.Info className={classes.Modal__Icon} />
          <Typography format="bcs">
            {t('translation:shareJob.modal.mustBePublished')}
          </Typography>
        </div>
      </div>
      <div className={classes.Modal__Actions}>
        <Button
          alt="secondary"
          text={t('translation:shareJob.modal.cancel')}
          onClick={onClose}
          data-testid="share-job-modal-cancel"
        />
        <Button
          alt="primary"
          text={t('translation:shareJob.modal.share')}
          onClick={() => {
            onShareJob()
          }}
          data-testid="share-job-modal-validate"
          disabled={!isPipelineIdValid}
        />
      </div>
    </DesignerModal>
  )
}
