import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Field,
  Typography,
  type CheckboxProps
} from '@matillion/component-library'
import { heap, useLDClient } from '@matillion/hub-client'
import classNames from 'classnames'

import { useStackingModal } from 'components/StackingModalProvider'

import { DemoDataModal } from '../DemoDataModal'
import classes from './DataSelectionModal.module.scss'

interface DataSelectionModalProps {
  componentId: string
  displayName: string
  onSelectComponent?: (id: string) => Promise<void>
}

const CheckboxInputComponent = (props: CheckboxProps) => <Checkbox {...props} />

export const DataSelectionModal = ({
  componentId,
  displayName,
  onSelectComponent
}: DataSelectionModalProps) => {
  const { t } = useTranslation()
  const { pushNewModalContent } = useStackingModal()
  const { track: trackLaunchDarklyMetric } = useLDClient()
  const [showAgain, setShowAgain] = useState(true)

  useEffect(() => {
    heap.track('Designer - Own Data or Demo Data Modal Displayed')
  }, [])

  useEffect(() => {
    localStorage.setItem('isDemoDialogDismissed', (!showAgain).toString())
  }, [showAgain])

  const handleOwnDataClick = useCallback(() => {
    if (!showAgain) {
      localStorage.setItem('isDemoDialogDismissed', 'true')
    }
    onSelectComponent?.(componentId)
  }, [onSelectComponent, componentId, showAgain])

  const handleDemoDataClick = useCallback(() => {
    if (!showAgain) {
      localStorage.setItem('isDemoDialogDismissed', 'true')
    }
    trackLaunchDarklyMetric('designer-use-demo-data-cta-click')
    pushNewModalContent(
      {
        ariaLabelledBy: 'demo data painted door'
      },
      <DemoDataModal
        componentId={componentId}
        onSelectComponent={onSelectComponent}
      />
    )
  }, [
    componentId,
    onSelectComponent,
    pushNewModalContent,
    trackLaunchDarklyMetric,
    showAgain
  ])

  const handleDontShowAgainClick = () => {
    setShowAgain(!showAgain)
  }

  return (
    <div className={classes.ModalContainer} data-testid="data-selection-modal">
      <Typography as="h2" format="ts" weight="bold">
        {displayName}
      </Typography>
      <div className={classes.ModalContainer__Content}>
        <div className={classes.ModalContainer__ContentOption}>
          <Typography
            format="bcs"
            weight="bold"
            className={classes.ModalContainer__Title}
            data-testid="designer-use-own-data-title"
          >
            {t('dataSelectionModal.ownData.title')}
          </Typography>
          <Typography
            format="bcs"
            className={classes.ModalContainer__Description}
          >
            {t('dataSelectionModal.ownData.description')}
          </Typography>
          <div className={classes.ModalContainer__Button}>
            <Button
              size="sm"
              alt="secondary"
              fontWeight="normal"
              onClick={handleOwnDataClick}
              className={classes.ModalContainer__ButtonPadding}
              data-tracker-id="designer-modal-use-own-data-button"
              data-testid="designer-use-own-data-button"
            >
              <Typography format="bcs" as="span">
                {t('dataSelectionModal.ownData.title')}
              </Typography>
            </Button>
          </div>
        </div>
        <div
          className={classNames(
            classes.ModalContainer__ContentOption,
            classes.ModalContainer__ContentOptionDemo
          )}
        >
          <Typography
            format="bcs"
            weight="bold"
            className={classes.ModalContainer__Title}
            data-testid="designer-use-demo-data-title"
          >
            {t('dataSelectionModal.demoData.title')}
          </Typography>
          <Typography
            format="bcs"
            className={classes.ModalContainer__Description}
          >
            {t('dataSelectionModal.demoData.description')}
          </Typography>
          <div className={classes.ModalContainer__Button}>
            <Button
              size="sm"
              alt="secondary"
              fontWeight="normal"
              onClick={handleDemoDataClick}
              className={classes.ModalContainer__ButtonPadding}
              data-tracker-id="designer-modal-use-demo-data-button"
              data-testid="designer-use-demo-data-button"
            >
              <Typography format="bcs" as="span">
                {t('dataSelectionModal.demoData.title')}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Field
        data-testid="data-selection-checkbox"
        text={t('dataSelectionModal.dontShowAgainText')}
        inputComponent={CheckboxInputComponent}
        checked={!showAgain}
        onChange={handleDontShowAgainClick}
      ></Field>
    </div>
  )
}
