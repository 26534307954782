import { useTranslation } from 'react-i18next'

import { Icon, Tooltip, Typography } from '@matillion/component-library'

import { IconButton } from 'components/IconButton/IconButton'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'

import classes from './InlineParameterHeader.module.scss'

interface InlineParameterHeaderProps {
  title: string
  closeInlineEditor: () => void
}

export const InlineParameterHeader = ({
  title,
  closeInlineEditor
}: InlineParameterHeaderProps) => {
  const { t } = useTranslation()
  const { getDisplayName, getIcon } = useComponentInfo()
  const { componentSummaryId, componentName } = useActiveComponentInfo()

  const componentType = getDisplayName(componentSummaryId)
  const btnText = t('componentProperties.configureComponent', {
    component: componentName
  })

  return (
    <>
      <div className={classes.InlineParameterHeader}>
        <div className={classes.Path}>
          <Tooltip content={componentType}>
            <img
              className={classes.Path__ComponentIcon}
              src={getIcon(componentSummaryId)}
              alt={componentType}
            />
          </Tooltip>
          <Tooltip content={btnText}>
            <button
              type="button"
              aria-label={btnText}
              className={classes.Path__ComponentButton}
              onClick={closeInlineEditor}
            >
              <Typography
                format="bcs"
                as="span"
                className={classes.Path__ComponentName}
              >
                {componentName}
              </Typography>
              <Typography
                format="mc"
                as="span"
                className={classes.Path__DisplayName}
              >
                {`(${componentType})`}
              </Typography>
            </button>
          </Tooltip>
        </div>

        <div className={classes.Header}>
          <IconButton
            className={classes.Header__IconButton}
            label={t('componentProperties.back')}
            data-testid="btn-back"
            onClick={closeInlineEditor}
          >
            <Icon.ChevronFilled className={classes.Header__Icon} />
          </IconButton>

          <Typography format="bcm" as="h2" weight="bold">
            {title}
          </Typography>
        </div>
      </div>
      <hr className={classes.Divider} />
    </>
  )
}
