import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Label,
  Typography,
  type AutoCompleteItem,
  type MultiSelectOnChangeProps
} from '@matillion/component-library'
import classNames from 'classnames'
import { uniq } from 'lodash'

import { AutoComplete } from 'components/AutoComplete'

import { LoadStrategy } from 'modules/ParameterEditors/components/MultiTableConfigurationEditor/types'

import {
  type ApplyToAllCondition,
  type ConditionOptions,
  type OnColumnsSelectionChange
} from '../../types'
import { ColumnSelectionDropdown } from '../ColumnSelectionDropdown/ColumnSelectionDropdown'
import classes from './ConditionRow.module.scss'

interface ConditionRowProps {
  conditionId: number
  condition: ApplyToAllCondition
  loadStrategy: LoadStrategy
  showTableName?: boolean
  availableConditionTables: string[]
  onChangeTable: (currentTableName: string, newTableName: string) => void
  onChange: OnColumnsSelectionChange
}

const onDropdownChange = (
  newValue: { target: AutoCompleteItem } | MultiSelectOnChangeProps,
  tableName: string,
  conditionField: keyof ConditionOptions,
  setRowData: OnColumnsSelectionChange
) => {
  const value = newValue.target.value

  if (!value) return

  if (Array.isArray(value)) {
    setRowData(tableName, uniq(value.map((v) => v.id)), conditionField)
  } else if (value.id === null || typeof value.id === 'string') {
    setRowData(tableName, value.id, conditionField)
  }
}

export const ConditionRow: FC<ConditionRowProps> = ({
  conditionId,
  condition,
  loadStrategy,
  showTableName = false,
  availableConditionTables,
  onChangeTable,
  onChange
}) => {
  const { t } = useTranslation()

  const primaryKeyText = t('multiTableConfig.applyToAll.primaryKeys')
  const incrementalColumnText = t(
    'multiTableConfig.applyToAll.incrementalColumn'
  )

  const defaultPrimaryKeyTitle = t(
    'multiTableConfig.applyToAll.conditions.defaultPkLabel'
  )
  const defaultIncrementalColumnTitle = t(
    'multiTableConfig.applyToAll.conditions.defaultIncColTitle'
  )

  const additionalDatasourceTitle = t(
    'multiTableConfig.applyToAll.conditions.additionalDatasourceTitle',
    {
      index: conditionId + 1
    }
  )

  const additionalPrimaryKeyTitle = t(
    'multiTableConfig.applyToAll.conditions.additionalPkTitle',
    {
      index: conditionId + 1
    }
  )
  const additionalIncrementalColumnTitle = t(
    'multiTableConfig.applyToAll.conditions.additionalIncColTitle',
    {
      index: conditionId + 1
    }
  )

  return (
    <div className={classes.ApplyToAllConditionRow__Container}>
      {showTableName && (
        <div className={classes.ApplyToAllConditionRow__RowContainer}>
          <Label>
            <Typography format="bcs" weight="bold">
              {t('multiTableConfig.applyToAll.conditions.table')}
            </Typography>
          </Label>
          <AutoComplete
            data-testid={`condition-datsource-${conditionId}`}
            aria-label={additionalDatasourceTitle}
            className={classNames({
              [classes['TableDropdown__Container--AppendHWM']]:
                loadStrategy === LoadStrategy.APPEND_WITH_HIGH_WATERMARK,
              [classes['TableDropdown__Container--AppendUpsert']]:
                loadStrategy === LoadStrategy.UPSERT
            })}
            inputClassName={classes.TableDropdown__Input}
            optionClassName={classes.TableDropdown__Options}
            onChange={(newValue) => {
              if (newValue.target.value) {
                const valueId = newValue.target.value.id
                if (typeof valueId === 'string' && !!valueId) {
                  onChangeTable(condition.tableName, valueId)
                }
              }
            }}
            availableItems={[
              condition.tableName,
              ...availableConditionTables
            ].map((item) => ({
              name: item,
              id: item
            }))}
            allowFreetext={false}
            value={condition.tableName}
          />
        </div>
      )}
      <div className={classes.ApplyToAllConditionRow__RowContainer}>
        <div className={classes.ApplyToAllConditionRow__Row}>
          {loadStrategy === LoadStrategy.UPSERT && (
            <div className={classes.ApplyToAllConditionRow__Column}>
              <Label>
                <Typography format="bcs" weight="bold">
                  {primaryKeyText}
                </Typography>
              </Label>
            </div>
          )}
          <div className={classes.ApplyToAllConditionRow__Column}>
            <Label>
              <Typography format="bcs" weight="bold">
                {incrementalColumnText}
              </Typography>
            </Label>
          </div>
        </div>
        <div className={classes.ApplyToAllConditionRow__Row}>
          {loadStrategy === LoadStrategy.UPSERT && (
            <div className={classes.ApplyToAllConditionRow__Column}>
              <ColumnSelectionDropdown
                dataTestId={`condition-selection-pk-${conditionId}`}
                title={
                  conditionId === 0
                    ? defaultPrimaryKeyTitle
                    : additionalPrimaryKeyTitle
                }
                tableName={condition.tableName}
                availableItems={condition.availableColumns}
                value={condition.primaryKeys}
                onChange={(tableName, newValue) => {
                  if (tableName) {
                    onDropdownChange(
                      newValue,
                      tableName,
                      'primaryKeys',
                      onChange
                    )
                  }
                }}
              />
            </div>
          )}
          <div className={classes.ApplyToAllConditionRow__Column}>
            <ColumnSelectionDropdown
              dataTestId={`condition-selection-incremental-${conditionId}`}
              title={
                conditionId === 0
                  ? defaultIncrementalColumnTitle
                  : additionalIncrementalColumnTitle
              }
              tableName={condition.tableName}
              availableItems={condition.availableColumns}
              value={condition.incrementalColumn}
              onChange={(tableName, newValue) => {
                if (tableName) {
                  onDropdownChange(
                    newValue,
                    tableName,
                    'incrementalColumn',
                    onChange
                  )
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
