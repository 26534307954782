import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction
} from 'react'

export interface OverlayError {
  id: string
  message: ReactNode
  type: 'error' | 'warning' | 'info' | 'success'
}

export interface OverlayErrorContext {
  errors: OverlayError[]
  setErrors: Dispatch<SetStateAction<OverlayError[]>>
  clearError: (id: string) => void
}

export const ParameterOverlayErrorContext = createContext<
  OverlayErrorContext | undefined
>(undefined)
