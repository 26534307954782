import { type ColumnMode } from '../types'
import {
  translateValueFromTextMode,
  translateValueToTextMode
} from './translateValues'

export const translateRowsFromTextMode = (
  rows: string[][],
  columnModes: ColumnMode[]
): string[][] => {
  const newRows: string[][] = []
  for (const row of rows) {
    const rowEntry: string[] = []
    for (const [index, value] of row.entries()) {
      const valueInBounds = index < columnModes.length
      if (!valueInBounds) {
        break
      }
      const columnMode = columnModes[index]
      rowEntry.push(translateValueFromTextMode(columnMode, value))
    }
    newRows.push(rowEntry)
  }
  return newRows
}

export const translateRowsToTextMode = (
  rowValues: string[][],
  columnModes: ColumnMode[]
): string[][] => {
  const newRows: string[][] = []
  for (const row of rowValues) {
    const rowEntry: string[] = []
    for (const [index, value] of row.entries()) {
      const columnMode = columnModes[index]
      rowEntry.push(translateValueToTextMode(columnMode, value))
    }
    newRows.push(rowEntry)
  }

  return newRows
}
