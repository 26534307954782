import { Table as T } from '@matillion/component-library'
import classNames from 'classnames'

import { type JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

import getFileDetails from './getFileDetails'
import classes from './JobReportRecord.module.scss'
import RecordContents from './RecordContents'

export interface JobReportRecordProps {
  id: string
  jobRecord: JobConversionRecord
  hasVariablesNotConverted: boolean
}

const JobReportRecord = ({
  id,
  jobRecord,
  hasVariablesNotConverted
}: JobReportRecordProps) => {
  const { pipelineName } = getFileDetails(jobRecord)
  return (
    <T.TableRow
      key={pipelineName}
      className={classes.tableRow}
      data-testid={`${id}-row`}
    >
      <T.TableCell
        width="12"
        className={classNames(classes.tableCell, {
          [classes.notExpanded]: hasVariablesNotConverted
        })}
      >
        <RecordContents jobRecord={jobRecord} />
      </T.TableCell>
    </T.TableRow>
  )
}

export default JobReportRecord
