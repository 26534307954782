import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type Note } from 'job-lib/types/Job'

import { type JobState } from '../../job.types'

interface UpdateNotePayload {
  id: number
  partialNote: Partial<Note>
}

export const updateNote: CaseReducer<
  JobState,
  PayloadAction<UpdateNotePayload>
> = (state, { payload: { partialNote, id } }) => {
  const { job } = state

  if (job?.notes) {
    job.notes[id] = { ...job.notes[id], ...partialNote }
  }
  return state
}
