import { Droppable } from '@hello-pangea/dnd'
import { type Table } from '@tanstack/react-table'

import { MatillionTableRow } from './MatillionTableRow'

interface MatillionTableBodyProps<TData> {
  table: Table<TData>
  tableId: string
  currentlyDraggingId: string | null
}

export const MatillionTableBody = <TData,>({
  table,
  tableId,
  currentlyDraggingId
}: MatillionTableBodyProps<TData>) => {
  return (
    <Droppable droppableId={`matillion-table-dropzone-${tableId}`}>
      {(droppableProvided) => {
        return (
          <tbody ref={droppableProvided.innerRef}>
            {table.getRowModel().rows.map((row) => (
              <MatillionTableRow
                key={row.id}
                row={row}
                selectedRowCount={table.getSelectedRowModel().rows.length}
                currentlyDraggingId={currentlyDraggingId}
              />
            ))}
            {droppableProvided.placeholder}
          </tbody>
        )
      }}
    </Droppable>
  )
}
