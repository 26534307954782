import { type Component } from 'types/Pipeline'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { componentNameParameter } from 'job-lib/cisIds/knownComponentParameters'
import { type Parameters } from 'job-lib/types/Job'
import { dplParamsKey } from 'job-lib/types/Parameters'

/*
 * Modular connectors and native orchestration components use a special parameter format
 * slot 1: reserved for the componentName parameter
 * slot 2: reserved for the DPL parameters as JSON
 */
export const convertDplParametersToMetl = (componentInstance: Component) => {
  const metlParameters: Parameters = {
    1: {
      slot: 1,
      name: componentNameParameter,
      elements: {
        1: {
          slot: 1,
          values: {
            1: {
              slot: 1,
              type: 'STRING',
              dataType: ParameterDataType.TEXT,
              value: componentInstance.parameters[
                componentNameParameter
              ] as string
            }
          }
        }
      },
      visible: true
    },
    2: {
      slot: 2,
      name: dplParamsKey,
      elements: {
        1: {
          slot: 1,
          values: {
            1: {
              slot: 1,
              type: 'DPL_PARAMS',
              dataType: ParameterDataType.TEXT,
              value: JSON.stringify(componentInstance.parameters)
            }
          }
        }
      },
      visible: true
    }
  }

  return metlParameters
}
