import React from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import useGetProject from 'api/hooks/useGetProject/useGetProject'
import type {
  JobConversionReport,
  VariableConversionRecord,
  VariableConversionReport
} from 'api/hooks/useMigrationMetlJob'

import ConversionReportSummary from './ConversionReportSummary'
import classes from './ViewFullConversionReportButton.module.scss'

interface ViewFullConversionReportButtonProps {
  exportFileName: string
  jobConversionReport: JobConversionReport
  jobsNotConverted: string[]
  variableConversionReport: VariableConversionReport
  variablesNotConverted: VariableConversionRecord[]
}

interface ConversionReportSummaryProps {
  accountId: string
  projectName: string
  dataPlatform: string
  exportFileName: string
  dryRunDate: string
  jobConversionReport: JobConversionReport
  jobsNotConverted: string[]
  variableConversionReport: VariableConversionReport
  variablesNotConverted: VariableConversionRecord[]
  language: string
}

const renderFullReport = async ({
  accountId,
  projectName,
  dataPlatform,
  exportFileName,
  dryRunDate,
  jobConversionReport,
  jobsNotConverted,
  variableConversionReport,
  variablesNotConverted,
  language
}: ConversionReportSummaryProps) => {
  const doc = (
    <ConversionReportSummary
      accountId={accountId}
      projectName={projectName}
      dataPlatform={dataPlatform}
      exportFileName={exportFileName}
      dryRunDate={dryRunDate}
      jobConversionReport={jobConversionReport}
      jobsNotConverted={jobsNotConverted}
      variableConversionReport={variableConversionReport}
      variablesNotConverted={variablesNotConverted}
    />
  )
  const secondaryWindow = window.open('', '_blank')
  if (secondaryWindow) {
    secondaryWindow.document.open()

    secondaryWindow.document.write(`
      <html lang="${language}">
        <head>
          <title>${exportFileName}</title>
          <link href='https://fonts.googleapis.com/css?family=Gabarito' rel='stylesheet'>
        </head>
        <body>
          <div id="full-conversion-report" style="font-family: Gabarito, sans-serif"/>
        </body>
      </html>`)
    const secondaryRoot = secondaryWindow.document.getElementById(
      'full-conversion-report'
    )

    if (secondaryRoot) {
      const secondaryRootInstance = createRoot(secondaryRoot)

      secondaryRootInstance.render(doc)
    }
  }
}

const ViewFullConversionReportButton = ({
  exportFileName,
  jobConversionReport,
  jobsNotConverted,
  variableConversionReport,
  variablesNotConverted
}: ViewFullConversionReportButtonProps) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'conversion.modal'
  })
  const { organisation } = useUser()
  const { data } = useGetProject()
  const dryRunDate = new Date().toLocaleString()
  const reportSummaryProps = {
    accountId: organisation.name,
    projectName: data?.name || 'Unknown Project',
    dataPlatform: data?.warehouse || 'Unknown Data Platform',
    exportFileName,
    dryRunDate,
    jobConversionReport,
    jobsNotConverted,
    variableConversionReport,
    variablesNotConverted,
    language: i18n.language
  }

  return (
    <Button
      alt="text"
      size="md"
      text={t('results.report.view')}
      className={classes.button}
      onClick={() => {
        renderFullReport(reportSummaryProps)
      }}
      data-testid="view-full-conversion-report-button"
    />
  )
}

export default ViewFullConversionReportButton
