import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { IconButton } from 'components/IconButton/IconButton'

import { useDeleteEdge } from '../hooks/useDeleteEdge/useDeleteEdge'
import classes from './ConnectorToolbar.module.scss'

interface ConnectorToolbarProps {
  edgeId: string
  sourceNodeId: string
  targetNodeId: string
  sourceHandle: string
}

export const ConnectorSubToolbar = ({
  edgeId,
  sourceNodeId,
  targetNodeId,
  sourceHandle
}: ConnectorToolbarProps) => {
  const { t } = useTranslation()
  const { deleteEdge } = useDeleteEdge()

  return (
    <div className={classes.Toolbar} data-testid="connector-sub-toolbar">
      <IconButton
        onClick={() => {
          deleteEdge(sourceNodeId, targetNodeId, edgeId, sourceHandle)
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.deleteComponent')}
      >
        <Icon.Trash />
      </IconButton>
    </div>
  )
}
