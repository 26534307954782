import FlagProvider from '../../FlagProvider'
import { Redirect } from './Redirect'
import { parseHost } from '../../utils/parseHost'
import { parseProduct } from './parseProduct'
import getConfig from '../../config'
import useFlags from '../../effects/useFlags'

interface LegacyGuardProps {
  /**
   * If `true`, the guard will check the `enable-microverse-<product-basename> flag, e.g. `enable-microverse-billing`, `enable-microverse-hub`, etc. It will redirect to the new app.matillion.com URL if the flag is enabled
   * If `false` or `undefined`, the guard will always redirect to the new app URL irrespective of the flag value. This should be used temporarily - eventually, this scenario should be handled at the infra level.
   */
  flagged?: boolean
  /**
   * If children are provided, they will be rendered if the guard does not redirect, i.e. if:
   * 1. the LD flag is disabled
   * 2. the `flagged` prop is `false` or `undefined`
   * 3. LD times out
   */
  children?: React.ReactNode
}

const FLAG_NAME_PREFIX = 'enable-microverse-'

/**
 * `LegacyGuard` is a component that redirects to the new app.matillion.com URL. It should be placed at the very top of the component tree, just below the `StrictMode` component.
 *
 * @param props See [[LegacyGuardProps]]
 * @category Components
 */
export function LegacyGuard({ flagged, children }: LegacyGuardProps) {
  if (flagged) {
    const { product } = parseHost(window.location.hostname)
    const { env, basename } = parseProduct(product)
    const { launchDarkly } = getConfig(env)

    return (
      <FlagProvider
        launchDarklyClientId={launchDarkly.defaultClientId}
        flags={{ [`${FLAG_NAME_PREFIX}${basename}`]: false }}
        useCamelCaseFlagKeys={false}
        // If not set, the FlagProvider derives the value from the AuthProvider, but the LegacyGuard should be placed above the AuthProvider, i.e. we want to redirect ASAP and not after the user authenticates
        offline={false}
        waitForFlags
        timeout={5}
      >
        <FlaggedLegacyGuard basename={basename}>{children}</FlaggedLegacyGuard>
      </FlagProvider>
    )
  }

  return <Redirect />
}

interface FlaggedLegacyGuardProps {
  basename: string
  children?: React.ReactNode
}

function FlaggedLegacyGuard({ basename, children }: FlaggedLegacyGuardProps) {
  const flags = useFlags()

  if (flags[`${FLAG_NAME_PREFIX}${basename}`]) {
    return <Redirect />
  }

  return <>{children}</>
}
