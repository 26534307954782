import { type XYPosition } from 'reactflow'

import type { Pipeline } from 'types/Pipeline'

import { getNextNoteId } from '../../utils/getNextNoteId'

export const DEFAULT_NOTE_WIDTH = 150
export const DEFAULT_NOTE_HEIGHT = 58

export interface AddNotePayload {
  position: XYPosition
  content?: string
  width?: number
  height?: number
}

export const addNote =
  (state: Pipeline) =>
  ({ position, content, width, height }: AddNotePayload) => {
    const uniqueId = getNextNoteId(state)

    if (!state.design.notes) {
      state.design.notes = {}
    }

    state.design.notes[uniqueId] = {
      position: {
        x: position.x,
        y: position.y
      },
      content: content ?? '',
      size: {
        width: width ?? DEFAULT_NOTE_WIDTH * 2,
        height: height ?? DEFAULT_NOTE_HEIGHT
      },
      theme: 'white'
    }
  }
