import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

import getConfig from '../config'
import { EnvironmentContext } from '../context'
import hubApi from '../api/hub'
import entitlementsApi from '../api/entitlements'
import permissionsApi from '../api/permissions'
import { AuthProviderEnvironment, Environment } from '../types'
import getAppURL from '../utils/getAppURL'

import UserInfoProvider from './UserInfoProvider'
import AuthHelpersProvider from './AuthHelpersProvider'
import AuthLoader from './AuthLoader'
import MockAuthProvider from './MockAuthProvider'
import { AccountIdProvider } from './AccountIdProvider'
import { toAccountAuthScope } from './selectedAccountUtils'

import type { Auth0ProviderOptions } from '@auth0/auth0-react'
import billingApi from '../api/billing'

export interface AuthProviderProps {
  /*
   * If set to `true`, the AuthProvider will launch in offline mode.
   * When in offline mode, Hub Client data will be mocked out,
   * and no real calls to external services will be made.
   * This is designed to make test environemnts as reliable as
   * possible with tools such as Cypress.
   *
   * This is set to `true` by default when running in either
   * `AuthProviderEnvironment.local` or `AuthProviderEnvironment.test`
   * environments. Any other environments will default to `false`.
   */
  offline?: boolean

  /**
   * Which environment to run the AuthProvider under. This controls which
   * instance of Auth0 you are authenticating with, as each environment
   * is separate from one another.
   */
  environment: AuthProviderEnvironment

  /**
   * Your application! Please note that all other Hub Client components,
   * such as the [[FlagProvider]] and [[HubHeader]] should be rendered
   * underneath the AuthProvider, and so should be provided here.
   */
  children: React.ReactNode
  /**
   * Auth0Provider option to handle the redirect callback https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html#onRedirectCallback
   * the callback is validated in the lower level auth0-spa-js library's Auth0Client https://community.auth0.com/t/redirecting-users-with-state-parameters/39520. Therefore, the documented state parameter validation https://auth0.com/docs/secure/attack-protection/state-parameters is not required.
   */
  onRedirectCallback?: Auth0ProviderOptions['onRedirectCallback']
  /**
   * Currently selected hub account id - intended to be used by the app shell only
   * */
  accountId?: string
  /**
   * Legacy account subdomain name used to enrich the auth0 JWT token with the current account. It should be used in tandem with the legacy guard, i.e. https://<subdomain>.<product>.matillion.com -> https://app.matillion.com/product?legacySubdomain=<subdomain>
   */
  legacySubdomain?: string
}

/**
 * The AuthProvider is responsible for logging the user in to Auth0,
 * and providing their details to your application. Any children of
 * this component will not be rendered until Auth0 has finished
 * checking whether or not the user is currently logged in.
 * Until that process has fininshed, a loader will be displayed.
 *
 * @param props See [[AuthProviderProps]].
 * @category Components
 */
const AuthProvider: FunctionComponent<
  React.PropsWithChildren<AuthProviderProps>
> = ({
  offline,
  environment,
  children,
  onRedirectCallback,
  accountId,
  legacySubdomain
}) => {
  const {
    version,
    auth0: auth0Options,
    hubUrl,
    hubApiUrl,
    entitlementsApiUrl,
    billingApiUrl,
    hubAssetsUrl,
    registryUrl,
    permissionsApiUrl,
    services
  } = useMemo(() => getConfig(environment), [environment])

  const isOfflineMode = offline ?? environment === AuthProviderEnvironment.test

  const envContext: Environment = useMemo(() => {
    const { organisation, rootOrigin } = getAppURL()
    return {
      environment,
      hubUrl,
      hubApiUrl,
      entitlementsApiUrl,
      billingApiUrl,
      hubAssetsUrl,
      registryUrl,
      permissionsApiUrl,
      appUrl: rootOrigin,
      subdomain: organisation,
      services,
      offline: isOfflineMode
    }
  }, [environment, hubUrl, services])

  useEffect(() => {
    /*
      Due to a weird interaction with in-memory storage in the auth0-spa-js
      library, and our auth flow via the hub, we need to force the `auth0.is.authenticated`
      cookie for now. This causes the Auth0Client to always check the authorize
      endpoint to see whether or not the user has a current session

      See: https://github.com/auth0/auth0-spa-js/issues/701
    */
    document.cookie = 'auth0.is.authenticated=true;'
  }, [])

  useEffect(() => {
    /*
      We don't know API urls ahead of time, as it's part of the config
      set by the `environment` prop to the AuthProvider. Therefore, we need to
      update our axios clients with the correct base URLs before any requests
    */
    hubApi.defaults.baseURL = hubApiUrl
    permissionsApi.defaults.baseURL = permissionsApiUrl
    entitlementsApi.defaults.baseURL = entitlementsApiUrl
    billingApi.defaults.baseURL = billingApiUrl
  }, [hubApiUrl, permissionsApiUrl, entitlementsApiUrl, billingApiUrl])

  useEffect(() => {
    /* Output the current library version to the window for debuggging */
    window.AuthProvider = {
      ...window.AuthProvider,
      version
    }
  }, [version])

  return (
    <EnvironmentContext.Provider value={envContext}>
      {isOfflineMode ? (
        <MockAuthProvider>{children}</MockAuthProvider>
      ) : (
        <AccountIdProvider accountId={accountId}>
          <Auth0Provider
            {...auth0Options}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            // NOTE: the initial props are included in every "/authorize" request to Auth0 - updating the props doesn't change the initial defaults
            scope={toAccountAuthScope(accountId)}
            legacySubdomain={legacySubdomain}
          >
            <AuthHelpersProvider>
              <UserInfoProvider>
                <AuthLoader>{children}</AuthLoader>
              </UserInfoProvider>
            </AuthHelpersProvider>
          </Auth0Provider>
        </AccountIdProvider>
      )}
    </EnvironmentContext.Provider>
  )
}

export default AuthProvider
