import { createContext } from 'react'

import { type FileTree } from '../reducers/fileTreeReducer/types'
import { type FileBrowserCommandContextBag } from './types'

export const FileBrowserContext = createContext<FileTree>([])

export const FileBrowserCommandContext =
  createContext<FileBrowserCommandContextBag>({
    onCommand: () => {
      console.debug('FileBrowserContext not initialised')
    },
    onFileTreeCommand: () => {
      console.debug('FileBrowserContext not initialised')
    }
  })
