import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'
import {
  type ListProjectVariablesFailResponse,
  type ListProjectVariablesResponse
} from './types'

interface UseListProjectVariablesProps {
  enabled?: boolean
  staleTime?: number
}

export const useListProjectVariableQueryKey = () => {
  const { projectId } = useProjectInfo()
  return [queryKeys.listProjectVariables, projectId]
}

export const useListProjectVariables = ({
  enabled = true,
  staleTime
}: UseListProjectVariablesProps = {}) => {
  const { projectId } = useProjectInfo()
  const queryKey = useListProjectVariableQueryKey()
  const { listProjectVariables } = useProjectExplorerApi()

  return useQuery<
    ListProjectVariablesResponse,
    ListProjectVariablesFailResponse
  >(
    queryKey,
    async () => {
      const { data } = await listProjectVariables(projectId)
      return data
    },
    { enabled, staleTime }
  )
}
