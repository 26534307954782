import { useOptimiseQuery } from 'api/hooks/copilot/useOptimiseQuery/useOptimiseQuery'
import { useUpdatePipeline } from 'api/hooks/copilot/usePipelineUpdate/useUpdatePipeline'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'

import { type ComponentWithMetadata } from '../model'

// This is a custom hook that aggregates the useOptimiseQuery and useUpdatePipeline hooks. Its done this way so that
// we can easily call them independently in the future if we need to decouple them for UX purposes, etc.
export const useGeneratePipeline = () => {
  const { jobSummaryId: jobId, projectId, branchId } = useProjectInfo()
  const { data } = useGetProject()

  const { job, jobType } = useFlaggedWorkingCopy()

  const { mutateAsync: optimiseQuery, isLoading: isLoadingOQ } =
    useOptimiseQuery()
  const { mutateAsync: updatePipeline, isLoading: isLoadingUP } =
    useUpdatePipeline()

  const generatePipeline = async (
    message: string,
    selectedComponents?: ComponentWithMetadata[]
  ) => {
    const requestId = crypto.randomUUID()

    const pipeline = {
      projectId,
      branchId,
      jobId,
      type: jobType,
      source: JSON.stringify(job),
      selectedComponentNames: selectedComponents?.map(
        (component) => component.componentName
      ),
      selectedComponents
    }

    const warehouse = data?.warehouse.toUpperCase() || 'UNKNOWN'

    const tasks = await optimiseQuery({
      requestId,
      pipeline,
      warehouse,
      message
    })

    const response = await updatePipeline({
      requestId,
      pipeline,
      warehouse,
      tasks,
      message
    })

    return response
  }

  return {
    generatePipeline,
    isLoading: isLoadingOQ || isLoadingUP
  }
}
