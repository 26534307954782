import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'

export const useGetProjectEnvironmentInfo = () => {
  const { projectId, environmentId } = useProjectInfo()
  const { getProjectEnvironmentInfo } = useProjectExplorerApi()

  return useQuery(
    [queryKeys.project, projectId, 'environment', environmentId],
    async () => {
      const { data } = await getProjectEnvironmentInfo(projectId, environmentId)
      return data
    },
    { retry: 0 }
  )
}

export default useGetProjectEnvironmentInfo
