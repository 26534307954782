import { useCallback, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { FreeTextInputEditor } from 'modules/ParameterEditors/components/FreeTextInputEditor/FreeTextInputEditor'

import classes from '../../Grid.module.scss'
import { type GridInputHandler } from '../../types'

export const GridFreeText: FunctionComponent<GridInputHandler> = ({
  type,
  value,
  slot,
  rowSlot,
  dataType,
  onChange
}) => {
  const { t } = useTranslation()
  const onEdit = useCallback(
    (newValue: string) => {
      onChange({ type, value: newValue, slot, rowSlot, dataType })
    },
    [onChange, type, slot, rowSlot, dataType]
  )

  return (
    <FreeTextInputEditor
      inputId={String(slot)}
      value={value ?? ''}
      onEdit={onEdit}
      hasError={false}
      labelId={String(value)}
      placeholder={t('parameterEditor.GRID_EDITOR.textEditorPlaceholder')}
      autocompleteDisabled
      containerClassName={classes.FreeText}
    />
  )
}
