import type { JobConversionRecord } from 'api/hooks/useMigrationMetlJob'

export const getRecordName = (recordName: string) => {
  return recordName.replace(
    /(\.orch\.yaml|\.ORCHESTRATION|\.tran\.yaml|\.TRANSFORMATION)$/g,
    ''
  )
}

export const getJobType = (record: JobConversionRecord) => {
  if (record.name.includes('.orch.')) {
    return 'Orchestration'
  } else if (record.name.includes('.tran.')) {
    return 'Transformation'
  }
}
