import { useCallback, useMemo, useState, type PropsWithChildren } from 'react'

import {
  type UserPreference,
  type UserPreferenceProviderContextInterface
} from 'components/UserPreferenceProvider/types'

import config from 'config'

import {
  userPreferenceInitialValue,
  UserPreferenceProviderContext
} from './UserPreferenceProviderContext'

const storeKey = config.browserStoreKeys.userPreference

export const UserPreferenceProvider = ({ children }: PropsWithChildren) => {
  const [prefValue, setPrefValue] = useState<UserPreference>(() => {
    const storedValue = window.localStorage.getItem(storeKey)
    return storedValue ? JSON.parse(storedValue) : userPreferenceInitialValue
  })

  const setSpecificUserPreference = useCallback(
    <T extends keyof UserPreference>(
      key: keyof UserPreference,
      value: UserPreference[T]
    ) => {
      setPrefValue((oldValue) => {
        const newValue = {
          ...oldValue,
          [key]: value
        }
        window.localStorage.setItem(storeKey, JSON.stringify(newValue))
        return newValue
      })
    },
    []
  )

  const values: UserPreferenceProviderContextInterface = useMemo(() => {
    return {
      userPreference: prefValue,
      setSpecificUserPreference
    }
  }, [prefValue, setSpecificUserPreference])
  return (
    <UserPreferenceProviderContext.Provider value={values}>
      {children}
    </UserPreferenceProviderContext.Provider>
  )
}
