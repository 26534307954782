import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { type ContextMenuSection } from '../CanvasContextMenu'

interface UseConfigurationSectionProps {
  jobName: string
}

export const useConfigurationSection = ({
  jobName
}: UseConfigurationSectionProps): ContextMenuSection => {
  const {
    hasPermission: hasRunPipelinePermission,
    isLoading: isRunPipelinePermissionLoading
  } = useProjectPermission('run_pipelines', PermissionType.ENVIRONMENT)

  const userCanRunPipeline =
    !isRunPipelinePermissionLoading && hasRunPipelinePermission

  const {
    hasPermission: hasValidatePipelinePermission,
    isLoading: isValidatePipelinePermissionLoading
  } = useProjectPermission('validate_pipelines', PermissionType.ENVIRONMENT)

  const userCanValidatePipeline =
    !isValidatePipelinePermissionLoading && hasValidatePipelinePermission

  const { isValidatingJob } = useComponentValidationProvider()

  return {
    id: 'configuration',
    items: [
      {
        id: 'validateJob',
        shortcut: 'translation:shortcuts.validateJob',
        disabled: !userCanValidatePipeline || isValidatingJob,
        subtext: ` (${jobName})`
      },
      {
        id: 'runJob',
        shortcut: 'translation:shortcuts.commandEnter',
        disabled: !userCanRunPipeline,
        subtext: ` (${jobName})`
      }
    ]
  }
}
