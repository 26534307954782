import { useState } from 'react'

import {
  DataGrid,
  type DataGridColumnProps
} from '@matillion/component-library'

import { DataGridAddRemoveButtons } from 'components/DataGridAddRemoveButtons/DataGridAddRemoveButtons'

import { FreeTextInputEditor } from '../../FreeTextInputEditor/FreeTextInputEditor'
import { type HelperData } from '../SetGridVariablesEditor'
import classes from '../SetGridVariablesEditor.module.scss'
import { type SetValuesValueCollection, type ValueRow } from '../utils'

export const SetValues = ({
  variableName,
  helperData,
  rows,
  updateRow
}: {
  helperData: HelperData | undefined
  variableName: string
  rows: SetValuesValueCollection[]
  updateRow: (row: ValueRow) => void
}) => {
  const [selectedRows, setSelectedRows] = useState<Array<string | number>>([])

  const selectedHelperData = helperData?.[variableName]

  if (!selectedHelperData) {
    return null
  }

  const updateRowValue = (updatedRows: SetValuesValueCollection[]) => {
    const newValues: ValueRow = {
      id: variableName,
      name: variableName,
      status: 'values',
      values: updatedRows.map((row, index) => {
        return {
          /**
           * The `slot` and `id` both represent the row number
           * `id` must be a string since we use that to represent the row `id` in the DataTable component
           * When we add or remove rows, we recalculate the `slot` and `id` based on the index.
           * This ensures that the row `id` is always unique.
           * */
          slot: index + 1,
          id: String(index + 1),
          cells: row.cells
        }
      })
    }

    updateRow(newValues)
  }

  const columns: Array<DataGridColumnProps<SetValuesValueCollection>> =
    selectedHelperData.definitions.map((column, index) => {
      return {
        key: column.name,
        title: column.name,
        as: FreeTextInputEditor,
        mapValues: (row) => {
          const slot = index + 1
          const { value } = row?.cells?.[slot] ?? {
            slot,
            type: 'STRING',
            value: ''
          }
          const rowId = `${column.name}-${row.id}`

          const inputProps: React.ComponentProps<typeof FreeTextInputEditor> = {
            inputId: rowId,
            labelId: rowId,
            value,

            onEdit: (newValue: string) => {
              const updatedRow = {
                id: row.id,
                cells: {
                  ...row.cells,
                  [slot]: { ...row.cells[slot], value: newValue }
                }
              }

              const updatedRows = rows.map((r) =>
                r.id === updatedRow.id ? updatedRow : r
              )

              updateRowValue(updatedRows)
            }
          }

          return inputProps
        }
      }
    })

  return (
    <div className={classes.SetValues} data-testid="SetValues">
      <DataGrid
        columns={columns}
        rows={rows}
        allSelectable={true}
        hasFixedHeader={true}
        isSelectable={true}
        className={classes.SetValues__Grid}
        selectedRows={selectedRows}
        onSelectedChange={(rowId, isSelected) => {
          if (isSelected) {
            setSelectedRows([...selectedRows, rowId])

            return
          }

          setSelectedRows(selectedRows.filter((id) => id !== rowId))
        }}
        isSelectAll={selectedRows.length === rows.length && rows.length > 0}
        onSelectAllChange={(selectAllChecked: boolean) => {
          if (selectAllChecked) {
            setSelectedRows(rows.map((row) => row.id))

            return
          }

          setSelectedRows([])
        }}
        compact
        rowClassName={classes.SetValues__DataGridRow}
      />
      <div className={classes.SetValues__DataGridFooter}>
        <DataGridAddRemoveButtons
          add={{
            disabled: false,
            onClick: () => {
              const newRows = [
                ...rows,
                {
                  id: (rows.length + 1).toString(),
                  cells: selectedHelperData.definitions.reduce(
                    (acc, _, index) => {
                      const slot = (index + 1).toString()

                      return {
                        ...acc,
                        [slot]: {
                          slot: Number(slot),
                          // We don't support the types returned from the
                          // lookup, so we just default to string
                          type: 'STRING',
                          value: ''
                        }
                      }
                    },
                    {}
                  )
                }
              ]

              updateRowValue(newRows)
            }
          }}
          remove={{
            disabled: selectedRows.length === 0,
            onClick: () => {
              updateRowValue(
                rows.filter((row) => !selectedRows.includes(row.id))
              )
              setSelectedRows([])
            }
          }}
        />
      </div>
    </div>
  )
}
