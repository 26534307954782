import { useEffect, useState, type FC, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type Failure } from 'api/hooks/useValidateComponent/types'

import { IconButton } from 'components/IconButton/IconButton'
import { TextLink } from 'components/TextLink/TextLink'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'
import { type ComponentInstance } from 'job-lib/types/Job'

import {
  useComponentValidationProvider,
  useComponentValidationResult
} from 'modules/core/ComponentValidation'

import { ValidationFailures } from '../ValidationFailures/ValidationFailures'
import classes from './ValidationStatus.module.scss'

interface ValidationStatusProps {
  componentInstance: ComponentInstance
  componentSummaryId: string
  open?: boolean
}

interface AlertProps {
  alertClass: 'Success' | 'Error' | 'Warning' | 'Info'
  alertTitle: string
  alertSubtitle?: string
  alertIcon: ReactNode
}

export const PARAMETER_PATH_IDENTIFIER = 'parameters'

const isAParameterFailure = (failure: Failure): boolean => {
  return failure.path.at(0)?.toLowerCase() === PARAMETER_PATH_IDENTIFIER
}

const getParameterFailures = (failures: Failure[]) => {
  return failures.filter((failure) => isAParameterFailure(failure))
}

const getComponentFailures = (
  failures: Failure[],
  failureMessage: string | null
) => {
  const componentFailures = failures.filter(
    (failure) => !isAParameterFailure(failure)
  )

  if (
    failureMessage &&
    !componentFailures.some(
      (failure) =>
        failure.message.toLowerCase() === failureMessage.toLowerCase()
    )
  ) {
    componentFailures.push({
      message: failureMessage,
      path: []
    })
  }

  return componentFailures
}

export const ValidationStatus: FC<ValidationStatusProps> = ({
  componentInstance,
  open = false,
  componentSummaryId
}) => {
  const {
    isSuccess: isValidationSuccess,
    isError: isValidationError,
    failures: validationFailures,
    failureMessage: validationFailureMessage,
    isLoading: isValidatingComponent
  } = useComponentValidationResult(componentInstance.id)
  const { setValidationEnabled } = useComponentValidationProvider()
  const { t } = useTranslation()

  const parameterFailures = getParameterFailures(validationFailures)
  const componentFailures = getComponentFailures(
    validationFailures,
    validationFailureMessage
  )
  const { hasPermission, isLoading: isLoadingPermissions } =
    useProjectPermission('validate_pipelines', PermissionType.ENVIRONMENT)
  const totalFailureCount = parameterFailures.length + componentFailures.length

  const [isErrorsPanelExpanded, setIsErrorsPanelExpanded] =
    useState<boolean>(open)

  useEffect(() => {
    if (isValidatingComponent && isErrorsPanelExpanded) {
      setIsErrorsPanelExpanded(false)
    }
  }, [isErrorsPanelExpanded, isValidatingComponent])

  const getAlertProps = (): AlertProps => {
    if (isValidatingComponent) {
      return {
        alertClass: 'Info',
        alertTitle: 'Validating...',
        alertIcon: <Icon.Info />
      }
    }
    if (unknownComponentIds.includes(componentSummaryId)) {
      return {
        alertClass: 'Error',
        alertTitle: t('componentProperties.toolbar.status.unknownComponent'),
        alertSubtitle: t('componentProperties.toolbar.status.readonly'),
        alertIcon: <Icon.Error />
      }
    }
    if (isValidationSuccess) {
      return {
        alertClass: 'Success',
        alertTitle: t('componentProperties.toolbar.status.validationSuccess'),
        alertIcon: <Icon.Success />
      }
    } else if (isValidationError) {
      return {
        alertClass: 'Error',
        alertTitle: t('componentProperties.toolbar.status.validationFailed'),
        alertIcon: <Icon.Error />
      }
    } else {
      return {
        alertClass: 'Warning',
        alertTitle: t('componentProperties.toolbar.status.validationRequired'),
        alertIcon: <Icon.Warning />
      }
    }
  }

  const { alertClass, alertIcon, alertTitle, alertSubtitle } = getAlertProps()

  return (
    <div data-testid="validation-status">
      <div
        className={classNames(
          classes.ValidationStatus,
          classes[`ValidationStatus--${alertClass}`]
        )}
      >
        <div
          className={classNames(
            classes.ValidationStatus__Alert,
            classes[`ValidationStatus__Alert--${alertClass}`]
          )}
        >
          {alertIcon}
          <Typography format="bcs" weight="bold">
            {alertTitle}
          </Typography>
          {alertSubtitle && (
            <Typography format="bcs">{alertSubtitle}</Typography>
          )}
          {totalFailureCount > 0 && (
            <Typography format="bcs">
              {totalFailureCount === 1
                ? t('componentProperties.toolbar.status.errorCount_one')
                : t('componentProperties.toolbar.status.errorCount', {
                    count: totalFailureCount
                  })}
            </Typography>
          )}
          {!isLoadingPermissions &&
            hasPermission &&
            alertClass === 'Warning' && (
              <TextLink
                text={t('componentProperties.toolbar.status.validateNow')}
                dataTestId="validation-status-validate-now"
                className={classes.ValidationStatus__Alert__ValidateNow}
                onClick={() => {
                  setValidationEnabled()
                }}
              />
            )}
        </div>
        <div>
          {componentFailures.length > 0 && (
            <IconButton
              data-testid="validation-status-toggle-more"
              className={classNames(classes.ValidationStatus__ErrorsToggle, {
                [classes['ValidationStatus__ErrorsToggle--Visible']]:
                  componentFailures.length > 0,
                [classes['ValidationStatus__ErrorsToggle--Expanded']]:
                  isErrorsPanelExpanded
              })}
              label={t(
                'componentProperties.status.' +
                  (isErrorsPanelExpanded ? 'showLess' : 'showMore')
              )}
              onClick={() => {
                setIsErrorsPanelExpanded(!isErrorsPanelExpanded)
              }}
            >
              <Icon.ChevronDown />
            </IconButton>
          )}
        </div>
      </div>
      {isErrorsPanelExpanded && alertClass === 'Error' && (
        <div
          data-testid="validation-status-accordion-content"
          className={classes.ValidationStatus__Accordion}
        >
          <div className={classes.ValidationStatus__Accordion__Panel}>
            <ValidationFailures
              componentFailures={componentFailures}
              parameterFailures={parameterFailures}
            />
          </div>
        </div>
      )}
    </div>
  )
}
