import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'

export const useDeleteProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const { deleteProjectVariables } = useProjectExplorerApi()

  return useMutation(async (variableId: string) => {
    const response = await deleteProjectVariables(projectId, variableId)
    return response.status
  })
}
