import { useCallback, useMemo, useState, type FC, type ReactNode } from 'react'

import { useSendAgenticCopilotChatMessage } from '../../../api/hooks/copilot/useSendAgenticCopilotChatMessage/useSendAgenticCopilotChatMessage'
import { useSubmitCopilotChatFeedback } from '../../../api/hooks/copilot/useSubmitCopilotChatFeedback/useSubmitCopilotChatFeedback'
import { useUpdatePipelineV1 } from '../../../api/hooks/copilot/useUpdatePipelineV1/useUpdatePipelineV1'
import { CopilotContext } from './context'
import { type CopilotChatMessage } from './types'

export interface CopilotProviderProps {
  children: ReactNode
}

export const CopilotProvider: FC<CopilotProviderProps> = ({ children }) => {
  const [chatMessages, setChatMessages] = useState<CopilotChatMessage[]>([])
  const [status, setStatus] = useState<string>('')
  const [sessionId, setSessionId] = useState<string>(crypto.randomUUID())

  const newSession = useCallback(() => {
    setSessionId(crypto.randomUUID())
    setChatMessages([])
  }, [setChatMessages])

  const { submitFeedback, isLoadingSubmitFeedback } =
    useSubmitCopilotChatFeedback()

  const addInfoMessage = useCallback((message: CopilotChatMessage) => {
    // Clear status when a new message arrives
    setStatus('')
    setChatMessages((existing) => [...existing, message])
  }, [])

  // The updatePipelineV1 function is deprecated and will be removed in the future
  const { updatePipelineV1, isLoadingUpdatePipeline } = useUpdatePipelineV1({
    addInfoMessage
  })

  const { sendMessageV2, isLoadingSseChat } = useSendAgenticCopilotChatMessage({
    addInfoMessage,
    setStatus
  })

  const value = useMemo(
    () => ({
      chatMessages,
      addInfoMessage,
      updatePipelineV1,
      submitFeedback,
      isLoadingSubmitFeedback,
      sendMessageV2,
      isLoadingChat: isLoadingUpdatePipeline || isLoadingSseChat,
      setStatus,
      status,
      sessionId,
      newSession
    }),
    [
      chatMessages,
      addInfoMessage,
      updatePipelineV1,
      submitFeedback,
      isLoadingSubmitFeedback,
      sendMessageV2,
      isLoadingUpdatePipeline,
      isLoadingSseChat,
      status,
      sessionId,
      newSession
    ]
  )

  return (
    <CopilotContext.Provider value={value}>{children}</CopilotContext.Provider>
  )
}
