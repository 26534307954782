import { useMemo, type PropsWithChildren } from 'react'

import { useCopilotApiClient } from '../copilot/useCopilotApiClient/useCopilotApiClient'
import { useDesignerApiClient } from '../useDesignerApiClient/useDesignerApiClient'
import useEosApiClient from '../useEosApiClient/useEosApiClient'
import { useProjectExplorerApiClient } from '../useProjectExplorerApiClient/useProjectExplorerApiClient'
import { ApiClientContext } from './ApiClientContext'

export const ApiClientProvider = ({ children }: PropsWithChildren) => {
  const designerClient = useDesignerApiClient()
  const copilotClient = useCopilotApiClient()
  const projectExplorerClient = useProjectExplorerApiClient()
  const eosClient = useEosApiClient()

  const value = useMemo(() => {
    return {
      designerClient,
      copilotClient,
      projectExplorerClient,
      eosClient
    }
  }, [designerClient, copilotClient, projectExplorerClient, eosClient])

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  )
}
