import { useState } from 'react'

import { TextModeSwitcher } from './TextModeSwitcher'
import { TextModeTextarea } from './TextModeTextarea'
import { type ColumnMode } from './types'
import { convertFromTextMode, convertToTextMode } from './utils/convert'

/**
 * A hook for working with text mode.
 * @param rowValues An array for each row of values in the grid
 * @param onExitTextMode A callback which is called with the new values set in text mode
 * this should be used to repopulate the grid.
 * @param columnModes Must contain each column in the grid. Specifies how to handle
 * values when exiting text mode
 * @param style Additional options to control the styling of the components
 * @returns
 * - open, openTextMode, closeTextMode, self explanatory
 * - The Textarea component, for rendering the textarea when in text mode
 * - The Switcher component, for toggling text mode on and off
 */
interface UseTextModeReturn {
  textModeOpen: boolean
  openTextMode: () => void
  closeTextMode: () => void
  textarea: JSX.Element
  switcher: JSX.Element
}

interface UseTextModeArgs {
  rowValues: string[][]
  onExitTextMode: (newValues: string[][]) => void
  onEnterTextMode?: () => void
  columnModes: ColumnMode[]
  style?: {
    textAreaClassName?: string
    switcherClassName?: string
  }
}

export const useTextMode = ({
  rowValues,
  columnModes,
  onExitTextMode,
  onEnterTextMode,
  style
}: UseTextModeArgs): UseTextModeReturn => {
  const [open, setOpen] = useState(false)
  const [textModeValue, setTextModeValue] = useState<string>('')
  const openTextMode = () => {
    setOpen(true)
    setTextModeValue(convertToTextMode(rowValues, columnModes))
    onEnterTextMode?.()
  }
  const closeTextMode = () => {
    setOpen(false)
    onExitTextMode(convertFromTextMode(textModeValue, columnModes))
  }
  return {
    textModeOpen: open,
    openTextMode,
    closeTextMode,
    textarea: (
      <TextModeTextarea
        textModeValue={textModeValue}
        setTextModeValue={setTextModeValue}
        className={style?.textAreaClassName}
      />
    ),
    switcher: (
      <TextModeSwitcher
        open={open}
        onOpenTextMode={openTextMode}
        onCloseTextMode={closeTextMode}
        className={style?.switcherClassName}
      />
    )
  }
}
