import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'
import { getSelectedComponents } from 'file-editors/canvas/util/componentSelection'

const useGetSelectedComponent = () => {
  const etlFlow = useEtlFlow()
  const components = getSelectedComponents(etlFlow)
  if (components.length === 1) {
    const selectedComponent = components[0]
    return {
      summaryId: selectedComponent.summaryId
    }
  }
  return {
    summaryId: undefined
  }
}

export default useGetSelectedComponent
