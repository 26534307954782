import { useEffect } from 'react'
import { parseHost } from '../../utils/parseHost'
import { parseProduct } from './parseProduct'
import redirectTo from '../../utils/locationRedirect'
import getConfig from '../../config'
import { FullPageLoader } from '../FullPageLoader/FullPageLoader'

export function Redirect() {
  useEffect(() => {
    const { hostname, pathname, search } = window.location
    const { product, subdomain } = parseHost(hostname)
    const { env, basename } = parseProduct(product)
    const { appShellUrl } = getConfig(env)

    redirectTo(
      `${appShellUrl}/${basename}${pathname}${toNewSearchSuffix({
        search,
        subdomain
      })}`
    )
  }, [])

  return <FullPageLoader />
}

interface ToNewSearchSuffixProps {
  search: string
  subdomain?: string
}

function toNewSearchSuffix({ search, subdomain }: ToNewSearchSuffixProps) {
  const searchParams = new URLSearchParams(search)
  if (subdomain) {
    searchParams.set('legacySubdomain', subdomain)
  }

  const newSearch = searchParams.toString()
  return newSearch ? `?${newSearch}` : ''
}
