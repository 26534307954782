import { type Node } from 'reactflow'

import {
  unknownOrchestrationSummaryId,
  unknownTransformationSummaryId
} from '../../../job-lib/cisIds/knownComponentParameters'
import type { EtlFlowInstance } from '../hooks/useEtlFlow'
import type {
  ComponentNodeData,
  NoteNodeData
} from '../modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import { getSelectedNodes } from '../modules/Canvas/hooks/useCanvasModel/utils'

const START_NODE_ID = 'start'

/**
 * Get the names of currently selected component nodes in the canvas
 * @param reactFlowInstance canvas react flow instance
 */
export const getSelectedComponentNames = (
  reactFlowInstance: EtlFlowInstance
) => {
  const selectedNodes = getSelectedNodes(reactFlowInstance)
  return mapNodesToComponentNames(selectedNodes)
}

/**
 * Map nodes to component names, filtering out non-component nodes
 * @param nodes nodes to map
 */
export const mapNodesToComponentNames = (
  nodes: Array<Node<ComponentNodeData | NoteNodeData>>
) => {
  return nodes
    .filter((node) => node.id.startsWith('component'))
    .map((node) => {
      return (node.data as ComponentNodeData).label
    })
}

/**
 * Get the list of selected components
 * @param reactFlowInstance canvas react flow instance
 */
export const getSelectedComponents = (reactFlowInstance: EtlFlowInstance) => {
  const selectedNodes = getSelectedNodes(reactFlowInstance)
  return selectedNodes
    .filter((node) => node.id.startsWith('component'))
    .map((node) => {
      return node.data as ComponentNodeData
    })
}

/**
 *
 * Get the first selected component name or start if the component summary id is start.
 *
 * @param id the component summary id
 * @param reactFlowInstance the canvas react flow instance
 */
export const getSelectedComponentName = (
  id: string | undefined,
  reactFlowInstance: EtlFlowInstance
): string | undefined => {
  // TODO https://matillion.atlassian.net/browse/DPCD-3175
  if (id === START_NODE_ID) {
    return START_NODE_ID
  }

  return getSelectedComponentNames(reactFlowInstance)[0]
}

/**
 * Checks if there are any selected unknown components
 *
 * @param reactFlowInstance canvas react flow instance
 */
export const hasUnknownComponents = (reactFlowInstance: EtlFlowInstance) => {
  const components = getSelectedComponents(reactFlowInstance)
  return components.some(
    (c: ComponentNodeData) =>
      c.summaryId === unknownOrchestrationSummaryId ||
      c.summaryId === unknownTransformationSummaryId
  )
}

/**
 * Get the skipped status of currently selected component nodes in the canvas
 * @param reactFlowInstance canvas react flow instance
 */
export const getSelectedComponentSkippedStatus = (
  reactFlowInstance: EtlFlowInstance
): boolean | undefined => {
  const components = getSelectedComponents(reactFlowInstance)
  return components[0]?.skipped
}
