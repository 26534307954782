import {
  type ListValue,
  type ParameterValue,
  type ScalarValue,
  type StructList
} from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import {
  isListValueParameterValue,
  isScalarParameterValue,
  isStructListParameterValue
} from 'modules/core/WorkingCopyProvider/utils/parameters'

import { defaultDataSelectionConfig } from '../types'

const safeGetValue = (
  property: ComponentParameter,
  parameterValue: ParameterValue
) => {
  switch (property.dataType) {
    case ParameterDataType.TEXT:
      if (isScalarParameterValue(parameterValue)) {
        return parameterValue
      }
      break
    case ParameterDataType.LIST:
      if (isListValueParameterValue(parameterValue)) {
        return parameterValue
      }
      break
    case ParameterDataType.STRUCT_LIST:
      if (isStructListParameterValue(parameterValue)) {
        return parameterValue
      }
      break
  }
}

export const parseParameterValueToConfigData = (
  childProperties: ComponentParameter[],
  parameterValue?: ParameterValue
) => {
  if (!parameterValue || !isStructListParameterValue(parameterValue)) {
    return []
  }
  return parameterValue
    .map((table) => {
      const configTable = defaultDataSelectionConfig()
      configTable.isSelected = true

      childProperties.forEach((childProperty, paramIdx) => {
        const paramName = childProperty.dplID
        const lastDelimiter = childProperty.dplID.lastIndexOf('/')
        const subParameterName = childProperty.dplID.substring(
          lastDelimiter + 1
        )

        const configValue = safeGetValue(childProperty, table[paramName])
        if (configValue === undefined) {
          return
        }

        switch (subParameterName) {
          case 'dataSource':
            configTable.dataSource = configValue as ScalarValue
            break
          case 'dataSelection':
            configTable.dataSelection = configTable.dataSelection.concat(
              (configValue as ListValue).map((columnName) => {
                return {
                  name: columnName,
                  isSelected: true
                }
              })
            )
            break
          case 'excludedDataElements':
            configTable.dataSelection = configTable.dataSelection.concat(
              (configValue as ListValue).map((columnName) => {
                return {
                  name: columnName,
                  isSelected: false
                }
              })
            )
            break
          case 'primaryKeyColumns':
            configTable.primaryKeys = configValue as ListValue
            break
          case 'incrementalColumn':
            configTable.incrementalColumn = configValue as ScalarValue
            break
          case 'tableName':
            configTable.targetTableName = configValue as ScalarValue
            break
          case 'table':
            configTable.targetTableName = configValue as ScalarValue
            break
          case 'filePrefix':
            configTable.targetTableName = configValue as ScalarValue
            break
          case 'targetTableName':
            configTable.targetTableName = configValue as ScalarValue
            break
          case 'createTableMode':
            configTable.createTableMode = configValue as ScalarValue
            break
          case 'dataSourceFilter':
            configTable.dataSourceFilter = configValue as StructList
            break
          case 'combineFilters':
            configTable.combineFilters = configValue as ScalarValue
            break
        }
      })

      return configTable
    })
    .filter((config) => !!config.dataSource)
}
