import type { LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import type { ComponentDependencyDataBag } from 'modules/core/ComponentValidation'

export interface ParameterOptions {
  editorColumns: EditorColumn[]
  componentInputDepBag?: ComponentDependencyDataBag
}

export interface UrlEditorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  lookupType?: LookUpType | null
  fileType?: string | null
  onDone: (editedValue: string[]) => unknown
}

export enum StorageEditorType {
  S3 = 'S3',
  AZURE = 'AZURE',
  GCS = 'GCS'
}

export enum DynamicUrlEditorKnownStorageTypes {
  AMAZON_S3_STORAGE = 'Amazon S3 Storage',
  AZURE_BLOB_STORAGE = 'Azure Blob Storage',
  GOOGLE_CLOUD_STORAGE = 'Google Cloud Storage'
}

export interface FullPath {
  path: string[]
  isFolder: boolean
}
