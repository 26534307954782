import { createContext } from 'react'

import { type WorkTreeDetails } from '../EtlDesigner/hooks/useWorkingCopy'
import { type WorkingCopyContextBag } from './types'

export const WorkingCopyContext =
  createContext<WorkingCopyContextBag>(undefined)

export const LegacyWorkingCopyContext = createContext<
  Omit<WorkTreeDetails, 'undoManager'>
>({
  job: null,
  jobType: null,
  isLoading: true,
  isError: false
})
