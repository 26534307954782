import { type FC } from 'react'

import { type ExtendedProps } from 'hooks/useAvailableComponents/useAvailableComponents'

import ReleaseStatusBadge from '../ReleaseStatusBadge/ReleaseStatusBadge'

interface ComponentReleaseStatusProps {
  component: ExtendedProps
  extendedLabel: boolean
}

type ReleaseStatus = 'limited' | 'preview' | undefined

const getStatus = (component: ExtendedProps): ReleaseStatus => {
  if (component.isLimitedUsage) {
    return 'limited'
  } else if (component.isPreview) {
    return 'preview'
  } else {
    return undefined
  }
}

const ComponentReleaseStatus: FC<ComponentReleaseStatusProps> = ({
  component,
  extendedLabel
}: ComponentReleaseStatusProps) => {
  const type = getStatus(component)
  if (!type) {
    return null
  }
  return <ReleaseStatusBadge type={type} extended={extendedLabel} />
}

export default ComponentReleaseStatus
