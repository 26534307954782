const VISUAL_BUFFER = 10

export interface DisplayDropDownAboveInput {
  componentRef?: HTMLElement | null
  dropdownRef?: HTMLDivElement | null
  isOpen: boolean
}

export const displayDropdownAboveInput = ({
  componentRef,
  dropdownRef,
  isOpen
}: DisplayDropDownAboveInput) => {
  // As much as I hate this, this proved the cleanest way of being able
  // to determine the boundary for the dropdown results.
  // 3rd party libraries didn't cope well with performance issues
  // we have. This avoided the need for prop drilling and avoiding
  // a context for a small piece of functionality that will work for
  // multiple dropdowns, without having to manage individual dropdowns in a context.
  // This also works for container re-sizing and when zooming for accessibility
  const boundaryContainer = document.body

  if (!boundaryContainer || !componentRef || !dropdownRef || !isOpen) {
    return false
  }

  const { bottom, top } = componentRef.getBoundingClientRect()
  const { bottom: boundaryBottom, top: BoundaryTop } =
    boundaryContainer.getBoundingClientRect()

  const { height } = dropdownRef.getBoundingClientRect()

  const collidesAtContainerTop = top - height <= BoundaryTop
  const collidesAtContainerBottom =
    bottom + height + VISUAL_BUFFER >= boundaryBottom

  return !collidesAtContainerTop && collidesAtContainerBottom
}
