import { ProjectType } from 'api/hooks/useGetProject/types'

export const isCopilotEnabled = (
  projectType: ProjectType | undefined,
  enableTransformationCopilot: boolean,
  enableOrchestrationCopilot: boolean,
  enableCopilotDatabricks: boolean,
  enableCopilotRedshift: boolean
): boolean => {
  if (!enableOrchestrationCopilot && !enableTransformationCopilot) {
    return false
  }

  // Databricks and Redshift are behind feature flags.
  const hasDatabricksAccess =
    projectType === ProjectType.DATABRICKS && enableCopilotDatabricks
  const hasRedshiftAccess =
    projectType === ProjectType.REDSHIFT && enableCopilotRedshift
  // Snowflake is 'GA', no feature flag required.
  const hasSnowflakeAccess = projectType === ProjectType.SNOWFLAKE

  return hasDatabricksAccess || hasRedshiftAccess || hasSnowflakeAccess
}
