import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { useParameterOptionsRequest } from 'hooks/useParameterOptions/useParameterOptionsRequest'
import {
  type UseGetParameterOptionsResponse,
  type UseParameterOptionsProps
} from 'hooks/useParameterOptions/useParameterOptionsTypes'

type UseMultiTableDataSelectionOptionsProps = UseParameterOptionsProps & {
  dataSourceDplID: string
  dataSourceValue: string
  isEnabled: boolean
}

export const useMultiTableDataSelectionOptions = ({
  componentSummaryId,
  componentMetaData,
  parameter,
  isEnabled,
  dataSourceDplID,
  dataSourceValue
}: UseMultiTableDataSelectionOptionsProps): UseGetParameterOptionsResponse => {
  const requestType = 'parameter-options'

  const request = useParameterOptionsRequest(
    isEnabled,
    componentMetaData,
    parameter,
    requestType
  )

  if (request.lookupDefinition?.parameters) {
    request.lookupDefinition.parameters = Object.entries(
      request.lookupDefinition.parameters
    ).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          dataSourceDplID && key.endsWith(dataSourceDplID)
            ? dataSourceValue
            : value
      }),
      {}
    )
  }

  const {
    data,
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error: unknownError,
    refetch
  } = useGetParameterOptions(request)

  const error = unknownError as ProblemDetails

  return {
    data: data?.editorColumns ?? [],
    isLoading,
    isRefetching,
    isSuccess,
    isError,
    error,
    refetch
  }
}
