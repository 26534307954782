import { type FC } from 'react'

import {
  AutoComplete,
  MultiSelect,
  type AutoCompleteItem,
  type MultiSelectOnChangeProps
} from '@matillion/component-library'
import classNames from 'classnames'

import classes from './ColumnSelectionDropdown.module.scss'

interface ColumnSelectionDropdownProps {
  dataTestId?: string
  title: string
  tableName: string
  availableItems: string[]
  value: string | string[] | null
  placeholder?: string
  readonly?: boolean
  compact?: boolean
  noBorders?: boolean
  onChange: (
    tableName: string | null,
    newValue: { target: AutoCompleteItem } | MultiSelectOnChangeProps
  ) => void
}

export const ColumnSelectionDropdown: FC<ColumnSelectionDropdownProps> = ({
  dataTestId,
  title,
  tableName,
  availableItems,
  value,
  placeholder,
  readonly,
  compact,
  noBorders,
  onChange
}) => {
  if (Array.isArray(value)) {
    return (
      <div className={classes.ConditionDropdown__Container}>
        <MultiSelect
          data-testid={dataTestId}
          aria-label={title}
          inputClassName={classNames(
            classes.ConditionDropdown__Input,
            classes.ConditionDropdown__BCS,
            {
              [classes['ConditionDropdown__Input--NoBorder']]: noBorders,
              [classes['ConditionDropdown__Input--Compact']]: compact
            }
          )}
          optionClassName={classNames(
            classes.ConditionDropdown__Options,
            classes.ConditionDropdown__BCS
          )}
          name={tableName}
          colorTheme="white"
          onChange={(newValue: MultiSelectOnChangeProps) => {
            onChange(tableName, newValue)
          }}
          options={availableItems.map((item) => ({
            name: item,
            id: item
          }))}
          placeholder={placeholder}
          value={value?.map((item) => ({
            id: item,
            name: item
          }))}
          disabled={!!readonly}
        />
      </div>
    )
  }

  return (
    <AutoComplete
      data-testid={dataTestId}
      aria-label={title}
      className={classes.ConditionDropdown__Container}
      inputClassName={classNames(
        classes.ConditionDropdown__Input,
        classes.ConditionDropdown__BCS,
        {
          [classes['ConditionDropdown__Input--NoBorder']]: noBorders,
          [classes['ConditionDropdown__Input--Compact']]: compact
        }
      )}
      optionClassName={classNames(
        classes.ConditionDropdown__Options,
        classes['ConditionDropdown__Options--Right'],
        classes.ConditionDropdown__BCS
      )}
      onChange={(newValue: { target: AutoCompleteItem }) => {
        onChange(tableName, newValue)
      }}
      availableItems={availableItems.map((item) => ({
        name: item,
        id: item
      }))}
      allowFreetext={false}
      value={value}
      placeholder={placeholder}
      disabled={!!readonly}
    />
  )
}
