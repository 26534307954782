import {
  useEffect,
  useMemo,
  type FunctionComponent,
  type PropsWithChildren
} from 'react'

import {
  useFile,
  useUpdateFileContents
} from '@matillion/git-component-library'
import { useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import type { Pipeline } from 'types/Pipeline'
import YAML from 'yaml'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type WorkTreeDetails } from '../EtlDesigner/hooks/useWorkingCopy'
import { WorkingCopyContext } from './context'
import convertDPLPipelineToMETL from './effects/convertDPLPipelineToMETL'
import { useMetlConversionMetadata } from './effects/useMetlConversionMetadata'
import {
  DEFAULT_PIPELINE,
  EMPTY_METL_PIPELINE,
  useWorkingCopyStore
} from './store'

/**
 * **This hook is only for internal use within the WorkingCopyProvider--if you want
 * access to the current pipeline, please call `useWorkingCopy` instead.**
 *
 * Converts the provided DPL pipeline back into a METL TransformationJob or OrchestrationJob.
 * This is an asynchronous process, as it requires loading component metadata from CIS, so
 * the returned object is a `useQuery`-style bag containing `isLoading`, `isError`, and
 * `metlPipeline`.
 *
 * @private
 */
export const useMetlPipelineConversion = ({
  pipeline,
  enabled
}: {
  pipeline: Pipeline
  enabled?: boolean
}): Omit<WorkTreeDetails, 'undoManager'> => {
  const { isLoading, isError, metadata } = useMetlConversionMetadata(pipeline)
  const metlConversion = convertDPLPipelineToMETL(
    pipeline,
    isLoading || isError ? undefined : metadata
  )

  if (isLoading) {
    return {
      isLoading: true,
      isError: false,
      job: null,
      jobType: null
    }
  }

  if (isError || !metlConversion || !enabled) {
    return {
      isLoading: false,
      isError: true,
      job: null,
      jobType: null
    }
  }

  return {
    isLoading: false,
    isError: false,
    job: metlConversion.metlPipeline,
    jobType: metlConversion.pipelineType
  }
}

/**
 * **This hook is only for internal use within the WorkingCopyProvider--if you want
 * access to the current pipeline, please call `useWorkingCopy` instead.**
 *
 * Loads the user's currently selected pipeline. This also converts that pipeline
 * into a METL version, used for compatibility across the application until we're
 * speaking DPL natively everywhere.
 * The returned object is a `useQuery`-style bag containing `isLoading`, `isError`,
 * and `pipeline`.
 *
 * @private
 */
export const usePipeline = (pipelineName: string, enabled = true) => {
  /**
   * The pipeline name can be undefined for a time whilst it is being retrieved from the URL using React Router.
   * The query is disabled until the pipeline name is set, so that we don't attempt to call the files endpoint without a file name specified.
   */
  const {
    data: pipeline,
    isLoading,
    isError
  } = useFile(
    {
      path: pipelineName
    },
    {
      enabled: enabled && !!pipelineName
    }
  )

  return useMemo(() => {
    if (!enabled) {
      return {
        pipeline: DEFAULT_PIPELINE,
        isLoading: false,
        isError: false
      }
    }

    try {
      const parsedPipeline = YAML.parse(pipeline as string)

      return {
        pipeline: parsedPipeline,
        isLoading,
        isError
      }
    } catch {
      return {
        pipeline: DEFAULT_PIPELINE,
        isLoading: false,
        isError: true
      }
    }
  }, [enabled, isError, isLoading, pipeline])
}

export const WorkingCopyProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const { jobSummaryId } = useProjectInfo()
  const queryClient = useQueryClient()
  const workingCopyStore = useWorkingCopyStore(jobSummaryId, queryClient)

  const { pipeline } = usePipeline(
    jobSummaryId,
    rolloutEnableWorkingCopyProvider
  )
  const metlPipeline = useMetlPipelineConversion({
    pipeline,
    enabled: rolloutEnableWorkingCopyProvider
  })

  const { updateFile } = useUpdateFileContents()

  /**
   * Create and assign an onSave handler which will be ran every time
   * working copy store changes. This will update the file contents
   * in Working Tree Store.
   */
  useEffect(() => {
    // Test will be implemented as part of the next story
    /* istanbul ignore next */
    workingCopyStore.temporal.getState().setOnSave((prevState, newState) => {
      /* istanbul ignore next */
      if (isEqual(prevState.workingCopy, DEFAULT_PIPELINE)) {
        return
      }

      /* istanbul ignore next */
      updateFile({
        name: jobSummaryId,
        contents: YAML.stringify(newState.workingCopy)
      })
    })
  }, [jobSummaryId, updateFile, workingCopyStore.temporal])

  /**
   * Populate working copy store with the DPL pipeline.
   */
  useEffect(() => {
    // istanbul ignore if
    if (!isEqual(workingCopyStore.getState().workingCopy, DEFAULT_PIPELINE)) {
      return
    }
    workingCopyStore.getState().update(({ setPipeline }) => {
      setPipeline(pipeline)
    })
  }, [jobSummaryId, pipeline, workingCopyStore])

  useEffect(() => {
    if (
      metlPipeline.job &&
      isEqual(workingCopyStore.getState().metlPipeline.job, EMPTY_METL_PIPELINE)
    ) {
      workingCopyStore.getState().setMetlPipeline(metlPipeline)
    }
  }, [metlPipeline, workingCopyStore])

  return (
    <WorkingCopyContext.Provider value={workingCopyStore}>
      {children}
    </WorkingCopyContext.Provider>
  )
}
