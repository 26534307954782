import { type FC } from 'react'

import classNames from 'classnames'

import classes from './CollapseIcon.module.scss'

export interface HorizontalCollapseIconProps {
  isCollapsed?: boolean
}

export const HorizontalCollapseIcon: FC<HorizontalCollapseIconProps> = ({
  isCollapsed
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        classes.HorizontalCollapseIcon,
        isCollapsed && classes['HorizontalCollapseIcon--isCollapsed']
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0H0V12H1V0ZM1.29291 5.99999L5.64646 1.64644L6.35357 2.35355L3.20711 5.5H12V6.5H3.20713L6.35357 9.64644L5.64646 10.3535L1.29291 5.99999Z"
        fill="currentColor"
      />
    </svg>
  )
}
