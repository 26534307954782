import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import {
  isGridVariable,
  isScalarVariable,
  type JobVariable
} from 'job-lib/types/Variables'

import { type JobState } from '../../job.types'

export const updateJobVariable: CaseReducer<
  JobState,
  PayloadAction<{
    oldName: string
    updatedVariable: JobVariable
  }>
> = (state, { payload: { oldName, updatedVariable } }) => {
  const { jobType, job } = state

  if (jobType === null || job == null) {
    return
  }

  if (isScalarVariable(updatedVariable)) {
    delete job.variables?.[oldName]

    job.variables = {
      ...job.variables,
      [updatedVariable.definition.name]: updatedVariable
    }
  }

  if (isGridVariable(updatedVariable)) {
    delete job.grids?.[oldName]

    job.grids = {
      ...job.grids,
      [updatedVariable.definition.name]: updatedVariable
    }
  }
}
