import { type FC } from 'react'

import { Icon } from '@matillion/component-library'

import classes from './NoContentMessage.module.scss'

interface NoContentMessageProps {
  text: string
}

export const NoContentMessage: FC<NoContentMessageProps> = ({ text }) => (
  <div
    className={classes.NoContentMessage}
    data-testid="component-properties-panel-no-content"
  >
    <Icon.InfoNoBg className={classes.NoContentMessage__Icon} />
    <span className={classes.NoContentMessage__Text}>{text}</span>
  </div>
)
