import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerApi } from '../useProjectExplorerApi/useProjectExplorerApi'
import { type UpdateProjectVariablesRequest } from './types'

export const useUpdateProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const { updateProjectVariables } = useProjectExplorerApi()

  return useMutation(
    async ({
      variableId,
      updateProjectVariablesRequest
    }: {
      variableId: string
      updateProjectVariablesRequest: UpdateProjectVariablesRequest
    }) => {
      const response = await updateProjectVariables(
        projectId,
        variableId,
        updateProjectVariablesRequest
      )
      return response.status
    }
  )
}
