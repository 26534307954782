import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import type { PipelineStatus } from '../useEosApi/types/eos'
import { useEosApi } from '../useEosApi/useEosApi'

export const useGetActivity = (
  projectId: string,
  workspaceId: string,
  status: PipelineStatus[],
  timeFrame?: string
) => {
  const { getActivity } = useEosApi()
  const refetchInterval = process.env.REACT_APP_STATUS_OVERRIDE_REFETCH
    ? Number(process.env.REACT_APP_STATUS_OVERRIDE_REFETCH)
    : 3000
  return useQuery(
    [queryKeys.activity, projectId, workspaceId, ...status],
    async () =>
      (await getActivity(projectId, workspaceId, status, timeFrame)).data
        .results,
    { refetchInterval }
  )
}
