import { type FC } from 'react'

import { Tree } from '@matillion/component-library'

import { type TreeNode } from '../utils'
import { SchemaElement } from './SchemaElement'

export const SchemaTree: FC<{
  nodes: TreeNode[]
  onSelectionChange: (node: TreeNode, selected: boolean) => void
}> = ({ nodes, onSelectionChange }) => {
  return (
    <Tree isAllFoldersExpanded={true}>
      {nodes.map((node) => (
        <SchemaElement
          key={node.name}
          node={node}
          onSelectionChange={onSelectionChange}
          isRoot={true}
        ></SchemaElement>
      ))}
    </Tree>
  )
}
