import { isPipeline } from '@matillion/git-component-library'

import { usePipelines } from '../hooks/usePipelines/usePipelines'
import { useProjectInfo } from '../hooks/useProjectInfo/useProjectInfo'
import { CanvasFileEditor } from './canvas'
import { FileTextEditor } from './high-code/components/FileTextEditor'

export const FileEditor = () => {
  const { files } = usePipelines()
  const { jobSummaryId } = useProjectInfo()

  const currentFile = files[jobSummaryId]

  if (currentFile?.type && !isPipeline(currentFile.type)) {
    return <FileTextEditor />
  }

  return <CanvasFileEditor />
}
