import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type JobVariableType } from 'job-lib/types/Variables'

import { type JobState } from '../../job.types'

export const deleteJobVariable: CaseReducer<
  JobState,
  PayloadAction<{
    variableName: string
    variableType: JobVariableType
  }>
> = (state, { payload: { variableName, variableType } }) => {
  const { jobType, job } = state

  if (jobType == null || job == null) {
    return
  }

  if (variableType === 'GRID') {
    delete job.grids?.[variableName]
  } else {
    delete job.variables?.[variableName]
  }
}
