import { LoadStrategy, type ColumnName } from '../types'

type LoadStrategyColumns = Record<LoadStrategy, ColumnName[]>

export const APPLY_TO_ALL_LOAD_STRATEGIES: LoadStrategy[] = [
  LoadStrategy.APPEND_WITH_HIGH_WATERMARK,
  LoadStrategy.UPSERT
]

export const loadStrategyColumns: LoadStrategyColumns = {
  APPEND: [],
  APPEND_WITH_HIGH_WATERMARK: ['incrementalColumn'],
  REPLACE: [],
  UPSERT: ['primaryKeys', 'incrementalColumn']
}
