import { type FC } from 'react'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import classes from './ComponentDisplayName.module.scss'

interface ComponentDisplayNameProps {
  summaryId: string
}
const ComponentDisplayName: FC<ComponentDisplayNameProps> = ({ summaryId }) => {
  const { getDisplayName } = useComponentInfo()
  const displayName = getDisplayName(summaryId)

  return <span className={classes.ComponentDisplayName}>{displayName}</span>
}

export default ComponentDisplayName
