import { useEffect } from 'react'

import { updateStyle } from '../utils/updateStyle'

export const useGlobalCursor = (enabled: boolean) => {
  useEffect(() => {
    if (enabled) {
      updateStyle('cursor', '*{cursor: col-resize!important;}')
    }

    return () => {
      updateStyle('cursor', '')
    }
  }, [enabled])
}
