import { Environment } from 'config'

import { flags } from './flags'

/**
 * Gets the flags to use in a particular environment, with localStorage overrides in the test environment
 * @param environment The environment to run in
 * @returns A collection of flags to fetch
 */
export const getFlags = (environment: Environment) => {
  if (environment === Environment.test) {
    return Object.entries(flags).reduce((obj, [key, defaultValue]) => {
      const override = window.localStorage.getItem(`flag.${key}`)

      return {
        ...obj,
        [key]: override ? override === 'true' : defaultValue
      }
    }, {})
  }

  return flags
}
