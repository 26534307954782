import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import { type APIError } from 'api/hooks/useGetParameterOptions/types'

import classes from '../StructuredNestedDataPickerEditor.module.scss'

export interface AlertProps {
  autofillError: APIError | null
  isUnvalidated: boolean
  setValidationEnabled: () => void
}
export const Alerts: FC<AlertProps> = ({
  autofillError,
  isUnvalidated,
  setValidationEnabled
}) => {
  const { t } = useTranslation()

  if (isUnvalidated) {
    return (
      <Alert
        aria-label={t('parameterEditor.warnings.validationWarning')}
        className={classes.Alert}
        type="warning"
        title={t('parameterEditor.warnings.validationWarning')}
        message=""
        action={{
          text: t('parameterEditor.warnings.validationAction'),
          onClick: setValidationEnabled
        }}
      />
    )
  }

  if (autofillError) {
    return (
      <Alert
        aria-label={autofillError.detail}
        className={classes.Alert}
        type="error"
        message={autofillError.detail}
      />
    )
  }

  return <></>
}
