import { type FC } from 'react'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './TextLink.module.scss'

interface TextLinkProps {
  text: string
  dataTestId?: string
  className?: string
  link?: string
  onClick?: () => void
}

export const TextLink: FC<TextLinkProps> = ({
  text,
  dataTestId,
  className,
  link,
  onClick
}) => {
  return (
    <button
      data-testid={dataTestId}
      className={classNames(classes.TextLink__Button, className)}
      type="button"
      onClick={
        onClick ?? (() => window.open(link, '_blank', 'noopener,noreferrer'))
      }
    >
      <Typography format="bcs">{text}</Typography>
    </button>
  )
}
