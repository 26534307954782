import { useCallback, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FreeTextInputEditor } from 'modules/ParameterEditors/components/FreeTextInputEditor/FreeTextInputEditor'
import { type GridInputHandler } from 'modules/ParameterEditors/components/GridEditor/components/Grid/types'

export const GridFreeText: FC<GridInputHandler> = ({
  type,
  value,
  slot,
  rowSlot,
  dataType,
  onChange
}) => {
  const { t } = useTranslation()

  const onEdit = useCallback(
    (newValue: string) => {
      onChange({ type, value: newValue, slot, rowSlot, dataType })
    },
    [onChange, type, slot, rowSlot, dataType]
  )

  return (
    <FreeTextInputEditor
      inputId={String(slot)}
      dataTestId={`dynamic-freetext-row-${slot}-slot-${rowSlot}`}
      value={value}
      onEdit={onEdit}
      hasError={false}
      labelId={String(value)}
      placeholder={t('parameterEditor.GRID_EDITOR.textEditorPlaceholder')}
      autocompleteDisabled
    />
  )
}
