import { create } from 'zustand'

interface ParameterEditorState {
  isInlineEditorOpen: boolean
  openInlineEditor: () => void
  closeInlineEditor: () => void
}

export const useParameterEditorState = create<ParameterEditorState>((set) => ({
  isInlineEditorOpen: false,
  openInlineEditor: () => {
    set((state) => ({ ...state, isInlineEditorOpen: true }))
  },
  closeInlineEditor: () => {
    set((state) => ({ ...state, isInlineEditorOpen: false }))
  }
}))
