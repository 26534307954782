import { useEffect, useMemo, useState, type FC } from 'react'

import { cloneDeep, isEqual } from 'lodash'
import { type ParameterValue, type StructValue } from 'types/Pipeline'

import { type ComponentParameter } from 'api/hooks/useGetComponentMetadata/types'

import Portal from 'components/Portal/Portal'

import { useParameterEditorState } from 'hooks/useParameterEditorState/useParameterEditorState'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'
import { useComponentValidationResult } from 'modules/core/ComponentValidation'
import {
  isScalarParameterValue,
  isStructListParameterValue
} from 'modules/core/WorkingCopyProvider/utils/parameters'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { MultiTableParameterField } from './components'
import { useIncrementalLoaderParameters } from './hooks/useIncrementalLoaderParameters'
import { MultiTableConfigurationEditor } from './MultiTableConfigurationEditor'
import { LoadStrategy, SchemaDrift } from './types'
import { destinationTableNameProperties } from './utils/destinationTableNamePropertyMapping'

interface MultiTableWrapperProps {
  parameterName: string
  path: string[]
  parameterValue: ParameterValue
  childProperties: ComponentParameter[]
  onSave: (value: ParameterValue) => void
}

export const MultiTableWrapper: FC<MultiTableWrapperProps> = ({
  parameterName,
  path,
  childProperties,
  parameterValue,
  onSave
}) => {
  const { componentSummaryId, componentInstance, componentMetadata } =
    useActiveComponentInfo()

  const { openInlineEditor, closeInlineEditor } = useParameterEditorState()
  const [showEditor, setShowEditor] = useState(false)
  const activeComponentInfo = useActiveComponentInfo()
  const {
    isSuccess: isValidationSuccess,
    isError: isValidationError,
    failures: validationFailures
  } = useComponentValidationResult(componentInstance.id)

  const parameterPath = useMemo(() => ['parameters', ...path], [path])

  const parameterFailures = useMemo(
    () =>
      validationFailures.filter((failure) =>
        isEqual(parameterPath, failure.path.slice(0, parameterPath.length))
      ),
    [parameterPath, validationFailures]
  )

  const {
    incrementalLoadStrategy: incrementalLoadParameter,
    schemaDriftHandlingEnabled: schemaDriftParameter
  } = useIncrementalLoaderParameters(componentMetadata, componentInstance)

  const loadStrategy: LoadStrategy | undefined = useMemo(() => {
    const value = incrementalLoadParameter?.at(0)?.value
    if (value && isScalarParameterValue(value)) {
      return LoadStrategy[value as keyof typeof LoadStrategy]
    }
  }, [incrementalLoadParameter])

  const schemaDrift: SchemaDrift | undefined = useMemo(() => {
    const value = schemaDriftParameter?.at(0)?.value
    if (value && isScalarParameterValue(value)) {
      return SchemaDrift[value as keyof typeof SchemaDrift]
    }
  }, [schemaDriftParameter])

  const hasErrors = parameterFailures.length > 0
  const isParameterValid =
    isValidationSuccess || (isValidationError && !hasErrors)
  const visibleParameters = childProperties.filter((parameter) =>
    isMetlParameterVisible(
      parameter.dplID,
      activeComponentInfo.componentInstance.parameters,
      componentMetadata,
      path
    )
  )

  /**
   * Used when user switches between destination on a configured Multi Table component.
   * This translates the dsetination table property between destinations, as each destinations uses different properties to represent the table name.
   * Otherwise, the table name (and other destination specific properties) will be lost every time the user switches destinations.
   */
  useEffect(() => {
    if (!parameterValue || !isStructListParameterValue(parameterValue)) {
      return
    }

    // Get the new table name property that we're switching to
    const correctTableNameProperty = visibleParameters.find((param) =>
      destinationTableNameProperties.includes(param.dplID)
    )?.dplID

    const editedParameterValue = cloneDeep(parameterValue)
    let hasUserSwitchedDestination = false
    parameterValue.forEach((param, index) => {
      // Check whether the current saved config and the "visible" config (after switching destination) are not aligned
      if (
        correctTableNameProperty &&
        param[correctTableNameProperty] === undefined
      ) {
        // Go through the current saved config and retrieve the table name property to preserve it, as well as other properties
        const { tableName, otherProperties } = Object.entries(
          visibleParameters
        ).reduce<{
          tableName: ParameterValue | null
          otherProperties: StructValue
        }>(
          (acc, [, visibleParam]) => {
            if (destinationTableNameProperties.includes(visibleParam.dplID)) {
              acc.tableName = getValueIfKeyExists(
                param,
                destinationTableNameProperties
              )
            } else {
              acc.otherProperties[visibleParam.dplID] =
                param[visibleParam.dplID]
            }
            return acc
          },
          { tableName: null, otherProperties: {} }
        )

        // Update the config with the correct property for the new destination and the old table name value
        editedParameterValue[index] = {
          ...otherProperties,
          [correctTableNameProperty]: tableName
        }
        hasUserSwitchedDestination = true
      }
    })
    if (hasUserSwitchedDestination) {
      onSave(editedParameterValue)
    }
  }, [parameterValue, visibleParameters, onSave])

  const getValueIfKeyExists = (obj: StructValue, keyList: string[]) => {
    for (const key of keyList) {
      if (Object.hasOwn(obj, key)) {
        return obj[key]
      }
    }
    return ''
  }

  return (
    <>
      <MultiTableParameterField
        componentSummaryId={componentSummaryId}
        componentMetaData={componentMetadata}
        childProperties={visibleParameters}
        parameterValue={parameterValue ?? null}
        parameterName={parameterName}
        loadStrategy={loadStrategy}
        hasErrors={hasErrors}
        isValid={isParameterValid}
        onClick={() => {
          setShowEditor(true)
          openInlineEditor()
        }}
      />
      {showEditor && (
        <Portal>
          <MultiTableConfigurationEditor
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
            componentMetadata={componentMetadata}
            parameterValue={parameterValue}
            childProperties={visibleParameters}
            loadStrategy={loadStrategy}
            schemaDrift={schemaDrift}
            onClose={() => {
              setShowEditor(false)
              closeInlineEditor()
            }}
            onSave={onSave}
          />
        </Portal>
      )}
    </>
  )
}
