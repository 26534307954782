import { getComponentIcon } from '../../../utils/component-icons/component-icons'
import { getConfig } from '../../../utils/pseudo-components/getPseudoComponentsConfig'
import { type PseudoComponentsMetadata } from './types'

export const getPseudoComponents = (): PseudoComponentsMetadata[] => {
  const pseudoComponentConfig = getConfig()
  // Map the pseudo component json with icon-id to new object containing icon path
  return pseudoComponentConfig.map((config) => {
    return {
      ...config,
      pseudoComponents: config.pseudoComponents.map((component) => {
        return {
          ...component,
          icon: getComponentIcon(component.iconId)
        }
      })
    }
  })
}
