import { createContext, useEffect, useRef, type ReactNode } from 'react'

import { createStore } from 'zustand'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'

interface ActiveComponentInfo {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentName: string
  componentSummaryId: ComponentSummaryId
  componentInstanceId: ComponentInstanceId
}

export interface ActiveComponentInfoStore extends ActiveComponentInfo {
  setComponentInfo: (info: ActiveComponentInfo) => void
}

export interface ActiveComponentInfoProviderProps
  extends Omit<ActiveComponentInfo, 'componentInstanceId'> {
  children: ReactNode
}

type StoreContext = ReturnType<typeof createActiveComponentInfoStore>

export const ActiveComponentInfoContext = createContext<StoreContext | null>(
  null
)

const createActiveComponentInfoStore = (componentInfo: ActiveComponentInfo) => {
  return createStore<ActiveComponentInfoStore>()((set) => ({
    ...componentInfo,
    setComponentInfo: (info) => {
      set(info)
    }
  }))
}

export const ActiveComponentInfoProvider = ({
  children,
  componentInstance,
  componentMetadata,
  componentName,
  componentSummaryId
}: ActiveComponentInfoProviderProps) => {
  const storeRef = useRef<StoreContext>(
    createActiveComponentInfoStore({
      componentInstance,
      componentMetadata,
      componentName,
      componentSummaryId,
      componentInstanceId: componentInstance.id
    })
  )

  useEffect(() => {
    if (storeRef.current) {
      storeRef.current.getState().setComponentInfo({
        componentInstance,
        componentMetadata,
        componentName,
        componentSummaryId,
        componentInstanceId: componentInstance.id
      })
    }
  }, [componentInstance, componentMetadata, componentName, componentSummaryId])

  return (
    <ActiveComponentInfoContext.Provider value={storeRef.current}>
      {children}
    </ActiveComponentInfoContext.Provider>
  )
}
