import type { ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import type { ProjectType } from 'api/hooks/useGetProject/types'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useComponentEnabledWarehouses } from 'hooks/useComponentEnabledWarehouses'
import { useFlags } from 'hooks/useFlags'

import { isComponentSuperseded } from '../utils/isComponentSuperseded'

export interface UseSupersededDetailsProps {
  componentMetadata?: ComponentMetadata
}

export interface SupersededDetails {
  isSuperseded: boolean
}

export const useSupersededDetails = ({
  componentMetadata
}: UseSupersededDetailsProps): SupersededDetails => {
  const { enableHidingSupersededComponents } = useFlags()
  const { data: project } = useGetProject()
  const componentEnabledWarehouses = useComponentEnabledWarehouses()

  return {
    isSuperseded: isComponentSuperseded({
      supersededBy: componentMetadata?.supersededBy,
      flagEnabled: enableHidingSupersededComponents,
      componentEnabledWarehouses,
      projectType: project?.warehouse as ProjectType
    })
  }
}
