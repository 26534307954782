import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'

import { MatillionTable } from 'components/MatillionTable/MatillionTable'

import { type LoadStrategy } from 'modules/ParameterEditors/components/MultiTableConfigurationEditor/types'

import {
  type ConflictGridColumnDef,
  type ConflictGridRow,
  type ConflictsGridMeta,
  type SetConflictRow
} from '../../types'
import { getLoadStrategyColumns } from '../../utils'
import {
  ConflictsGridHeader,
  ConflictsGridText,
  DynamicConflictsGridDropdown
} from './components/ConflictsGridColumn'
import classes from './ConflictsGrid.module.scss'

const textCellMetaData = (
  values: Pick<ConflictsGridMeta, 'columnName' | 'columnTitle'>
): ConflictsGridMeta => ({
  columnName: values.columnName,
  columnTitle: values.columnTitle
})

const dropdownCellMetaData = (
  values: Pick<ConflictsGridMeta, 'columnName' | 'columnTitle' | 'setRowData'>
): ConflictsGridMeta => ({
  columnName: values.columnName,
  columnTitle: values.columnTitle,
  setRowData: values.setRowData
})

interface ConflictsGridProps {
  data: ConflictGridRow[]
  loadStrategy: LoadStrategy
  onChange: SetConflictRow
}

export const ConflictsGrid: FC<ConflictsGridProps> = ({
  data,
  loadStrategy,
  onChange
}) => {
  const { t } = useTranslation()
  const columns: ConflictGridColumnDef[] = [
    {
      header: ConflictsGridHeader,
      cell: ConflictsGridText,
      id: 'tableName',
      accessorFn: (row) => row.cells.tableName,
      meta: textCellMetaData({
        columnName: 'tableName',
        columnTitle: t('multiTableConfig.applyToAll.dataSource')
      })
    },
    {
      header: ConflictsGridHeader,
      cell: DynamicConflictsGridDropdown,
      id: 'primaryKeys',
      accessorFn: (row) => row.cells.primaryKeys,
      meta: dropdownCellMetaData({
        columnName: 'primaryKeys',
        columnTitle: t('multiTableConfig.applyToAll.primaryKeys'),
        setRowData: onChange
      })
    },
    {
      header: ConflictsGridHeader,
      cell: DynamicConflictsGridDropdown,
      id: 'incrementalColumn',
      accessorFn: (row) => row.cells.incrementalColumn,
      meta: dropdownCellMetaData({
        columnName: 'incrementalColumn',
        columnTitle: t('multiTableConfig.applyToAll.incrementalColumn'),
        setRowData: onChange
      })
    }
  ]

  const filteredColumns = getLoadStrategyColumns(loadStrategy, columns)

  const table = useReactTable({
    data,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false
  })

  return (
    <div data-testid="conflict-grid" className={classes.ConflictGridContainer}>
      <MatillionTable
        containerClassName={classNames(classes.ConflictTable, {
          [classes['ConflictTable--Width2']]: filteredColumns.length === 2,
          [classes['ConflictTable--Width3']]: filteredColumns.length === 3
        })}
        table={table}
        setRows={() => {}}
      />
    </div>
  )
}
