export type IdGenerator = ReturnType<typeof idGenerator>

// Prettier and ESLint disagree on this rule
// eslint-disable-next-line generator-star-spacing
export function* idGenerator() {
  let idx = 1

  while (true) {
    yield idx++
  }
}
