import { type FC } from 'react'

import { Tooltip } from '@matillion/component-library'
import classNames from 'classnames'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { type ComponentInstance } from 'job-lib/types/Job'

import classes from './ComponentIcon.module.scss'

interface ComponentIconProps {
  componentSummaryId: string
  componentInstance: ComponentInstance
  isDisabled?: boolean
}

export const ComponentIcon: FC<ComponentIconProps> = ({
  componentSummaryId,
  componentInstance,
  isDisabled = false
}) => {
  const { getIcon } = useComponentInfo()
  const { getDisplayName } = useComponentInfo()
  const displayName = getDisplayName(componentSummaryId)

  return (
    <Tooltip content={displayName}>
      <img
        className={classNames(
          classes.ComponentIcon,
          isDisabled ? classes.ComponentIcon__Grayscale : undefined
        )}
        src={getIcon(componentSummaryId, componentInstance.parameters)}
        alt={displayName}
      />
    </Tooltip>
  )
}
