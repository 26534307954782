import { createContext } from 'react'

import type * as Y from 'yjs'

export interface WorkingCopyContextBag {
  isLoading: boolean
  isError: boolean
  undoManager: Y.UndoManager | null
}

export const WorkingCopyContext = createContext<WorkingCopyContextBag>({
  isLoading: false,
  isError: false,
  undoManager: null
})
