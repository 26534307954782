import { type ButtonHTMLAttributes, type FC } from 'react'

import { Tooltip } from '@matillion/component-library'
import classnames from 'classnames'

import { ContentWithShortcut } from 'components/ContentWithShortcut/ContentWithShortcut'

import classes from './IconButton.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export type Props = ButtonProps & {
  label: string
  shortcut?: string
  align?: 'start' | 'center' | 'end'
  autoSize?: boolean
  hideTooltip?: boolean
}

export const IconButton: FC<Props> = ({
  className,
  children,
  label,
  shortcut,
  align = 'center',
  autoSize = false,
  hideTooltip,
  ...props
}) => (
  <Tooltip
    content={
      hideTooltip ? null : shortcut ? (
        <ContentWithShortcut content={label} shortcut={shortcut} format="mc" />
      ) : (
        label
      )
    }
    align={align}
    className={classes.Tooltip__Container}
  >
    <button
      type="button"
      aria-label={label}
      className={classnames(
        classes.Tooltip__IconButton,
        !autoSize && classes.Tooltip__IconButton__FixedSize,
        className
      )}
      {...props}
    >
      {children}
    </button>
  </Tooltip>
)
