import { lazy, Suspense, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { DisplayType } from 'components/CodeEditor/types'
import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { Loading } from 'components/Loading/Loading'

import classes from './ReadonlyModal.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface ReadonlyModalProps {
  title: string
  open: boolean
  closeModal: () => void
  value: string | null
}

export const ReadonlyModal: FC<ReadonlyModalProps> = ({
  open,
  closeModal,
  value,
  title
}) => {
  const { t } = useTranslation()

  return open && value !== null ? (
    <DesignerModal
      onCancel={closeModal}
      ariaLabelledBy="modal-title"
      size="large"
      data-testid="readonly-modal"
    >
      <Typography
        as="h2"
        format="tm"
        id="modal-title"
        className={classes.Modal__Title}
      >
        {title}
      </Typography>
      <div className={classes.Modal__CodeEditorContainer}>
        <Suspense fallback={<Loading />}>
          <CodeEditor
            value={value}
            readOnly
            displayType={DisplayType.Message}
          />
        </Suspense>
      </div>
      <div className={classes.Modal__Controls}>
        <Button
          data-testid="close-readonly-modal"
          onClick={closeModal}
          text={t('common.close')}
        />
      </div>
    </DesignerModal>
  ) : null
}
