import type { NoteId, Pipeline, Position } from 'types/Pipeline'

import { getNextNoteId } from '../../utils/getNextNoteId'

export const DEFAULT_NOTE_X_OFFSET = 100
export const DEFAULT_NOTE_Y_OFFSET = 100

export type CopyNotesPayload = Array<{
  noteId: NoteId
  position?: Position
}>

export const copyNotes = (state: Pipeline) => (notes: CopyNotesPayload) => {
  notes.forEach((note) => {
    const nextNoteId = getNextNoteId(state)

    state.design.notes[nextNoteId] = {
      ...state.design.notes[note.noteId],
      position: note.position ?? {
        x: state.design.notes[note.noteId].position.x + DEFAULT_NOTE_X_OFFSET,
        y: state.design.notes[note.noteId].position.y + DEFAULT_NOTE_Y_OFFSET
      }
    }
  })
}
